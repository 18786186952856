// TOP LEVEL IMPORTS
import styled from 'styled-components'
import { Link, useLocation } from 'react-router-dom'
import queryString from 'query-string'
// COMPONENTS
import AccountAvatar from 'components/common/AccountAvatar'
import Popover from 'components/common/Popover'
import AccountDropdown from 'components/common/AccountDropdown'
import CloseOutlined from 'components/icons/CloseOutlined'
import MenuOutlined from 'components/icons/MenuOutlined'
import DashboardButton from 'components/common/DashboardButton'
// LIB
import logo from 'lib/media/logo-light-bg-horizontal.svg'
import useUrlChange from 'lib/hooks/useUrlChange'
import { UserProfile } from 'generated/graphql'

// STYLED-COMPONENTS
//-------------------------
const Logo = styled.img`
  height: 32px;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    display: none;
  }
`

const HeaderOuter = styled.div<{
  fixed: boolean
}>`
  background-color: #fff;
  top: 0px;
  right: 0px;
  left: 0px;
  display: flex;
  min-height: 72px;
  align-items: center;
  z-index: 10;
  border-bottom: 1px solid ${(p) => p.theme.colors.neutral9};
  position: ${({ fixed = true }) => {
    if (fixed) {
      return 'fixed'
    } else {
      return 'inherit'
    }
  }};
`

const HeaderContainer = styled.div`
  background: #fff;
  font-weight: 600;
  display: flex;
  margin: auto;
  justify-content: center;
  align-items: center;
  width: 1150px;
  min-height: 72px;
  max-width: 95%;
  position: relative;
  padding-bottom: 8px;
  padding-top: 8px;
`

const HeaderButtonContainer = styled.span`
  position: absolute;
  left: 5%;
`

const MenuButton = styled.button`
  border: 0px;
  background: transparent;
  cursor: pointer;
  padding: 8px;
  &:hover {
    background: ${({ theme }) => theme.colors.neutral10};
  }
  @media only screen and (min-width: ${({ theme }) => `${parseInt(theme.breakpoints.medium) + 1}px`}) {
    display: none;
  }
`

const MenuLabel = styled.p`
  margin: 0px;
  font-size: 12px;
`

const DashBtnContainer = styled.div`
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    display: none;
  }
`

interface HeaderProps {
  currentUser: UserProfile
}

export default function Header({ currentUser }: HeaderProps) {
  const location = useLocation()
  const { onUrlChange } = useUrlChange()
  const queryParams = queryString.parse(location.search)
  const handleMobileMenuClick = () => {
    if (queryParams.mobileMenuOpen) {
      // remove it
      onUrlChange({ mobileMenuOpen: undefined }, null)
    } else {
      // add it
      onUrlChange({ mobileMenuOpen: 'true' }, null)
    }
  }
  return (
    <>
      <HeaderOuter fixed={queryParams.mobileMenuOpen ? true : false}>
        <HeaderContainer>
          <HeaderButtonContainer>
            <MenuButton
              onClick={handleMobileMenuClick}
              aria-label={queryParams.mobileMenuOpen ? 'Close Menu' : 'Open Menu'}
            >
              {queryParams.mobileMenuOpen ? (
                <>
                  <CloseOutlined style={{ fontSize: 24 }} />
                </>
              ) : (
                <>
                  <MenuOutlined style={{ fontSize: 24 }} />
                  <MenuLabel>Menu</MenuLabel>
                </>
              )}
            </MenuButton>
            <DashBtnContainer>
              <DashboardButton />
            </DashBtnContainer>
          </HeaderButtonContainer>
          <div>
            <Link to="/app/home">
              <Logo src={currentUser?.clinic?.logo || logo} alt="Koda Health" style={{ height: 40 }} />
            </Link>
          </div>
          <div
            style={{
              position: 'absolute',
              right: 24,
              top: 12,
            }}
          >
            <div style={{ alignItems: 'center' }}>
              <Popover trigger={['hover', 'click']} content={<AccountDropdown currentUser={currentUser} />}>
                <AccountAvatar data-testid="accounts-dropdown" />
              </Popover>
            </div>
          </div>
        </HeaderContainer>
      </HeaderOuter>
    </>
  )
}
