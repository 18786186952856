import config from 'lib/config'
import apolloClient from 'ApolloClient/index'
import { get } from 'lodash'
import getClinicId from './getClinicId'
import { AnalyticEventTypeEnums } from 'lib/types/AnalyticEventTypeEnums'
import { LogEventDocument, LogEventMutationVariables } from 'generated/graphql'

interface LogEventMetaData {
  clinicId?: string
  /** from is the question the user is leaving */
  from?: string
  /** to is the question the user is moving to */
  to?: string
  /** content is a catch all for passing in extra data... so we don't need to create a new data field for every event we wantt o capture */
  content?: string
  question?: string
  stepName?: string
  patientId?: string
  extraEmailsSent?: number
  decisionMakerEmailSent?: boolean
  notaryInfo?: boolean
  videoName?: string
  endTime?: number | string | null
  timeVideoStarted?: number | null
  videoId?: string
  msWatched?: number | string | null
  durationWatched?: string | null
  i18nkey?: string
  extraContext?: string
}

// * This will log an event in mixpanel
// */
export default async function logEvent(name: AnalyticEventTypeEnums, meta: LogEventMetaData = {}) {
  // only log in production
  const clinicId = meta?.clinicId ? meta?.clinicId : await getClinicId()

  if (!name) {
    throw new Error('Name is required to call an analytics track event')
  }

  const FS = get(window, 'FS')
  const Sentry = get(window, 'Sentry')
  const mixpanel = get(window, 'mixpanel')
  const finalMetaData = { ...meta, clinicId }

  const logEventVariables: LogEventMutationVariables = {
    eventName: name,
    metaData: JSON.stringify(finalMetaData),
    mixpanelDistinctId: mixpanel && mixpanel?.get_distinct_id && mixpanel?.get_distinct_id(), // a mixpanel thing
  }
  apolloClient.mutate({
    mutation: LogEventDocument,
    variables: logEventVariables,
  })

  FS?.event(name, finalMetaData)

  Sentry?.addBreadcrumb({
    category: name,
    message: name,
    level: Sentry.Severity.Info,
    data: finalMetaData,
  })

  if (config.NODE_ENV !== 'production') {
    console.log(name, meta)
  }
}
