import { AnalyticEventTypeEnums } from 'lib/types/AnalyticEventTypeEnums'
import { ActivityLogKeyEnum, useLogActivityItemMutation as useLogActivityItemMutationDef } from 'generated/graphql'
import getClinicId from 'lib/helpers/getClinicId'
import logEvent from 'lib/helpers/logEvent'
import translateApolloError from 'lib/helpers/translateApolloError'

interface ILogActivityItemMutation {
  variables: {
    key: ActivityLogKeyEnum
    importance?: number
    userId: string
    patientId?: string
    content: string
    clinicId?: string
  }
}

export default function useLogActivityItemMutation() {
  const [logActivityItemMutation] = useLogActivityItemMutationDef()

  const logActivityItem = async (options: ILogActivityItemMutation, eventName?: AnalyticEventTypeEnums) => {
    try {
      const clinicId = options?.variables?.clinicId || (await getClinicId())
      await logActivityItemMutation({
        ...options,
        variables: {
          ...options?.variables,
          clinicId,
        },
      })
      // log it for third-party analytics as well
      logEvent(eventName || AnalyticEventTypeEnums.ACTIVITY_ITEM, options.variables)
    } catch (err: any) {
      return console.log(translateApolloError(err))
    }
  }

  return [logActivityItem]
}
