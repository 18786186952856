import React from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';

const AppNavContainer = styled.div`
  font-family: ${(p) => p.theme.fontFamily};
  border-bottom: 1px solid ${(p) => p.theme.colors.neutral8};
  background: #fff;
`;

interface WrapperProps {
  active?: boolean;
}

const NavItemContainer = styled.div<WrapperProps>`
  text-align: center;
  cursor: pointer;
  letter-spacing: 1px;
  font-size: 16px !important;
  text-decoration: none;
  transition: color 0.3s ease, background-color 0.3s ease,
    border-color 0.3s ease, width 0.3s ease, opacity 0.3s ease;
  a {
    color: ${(p) =>
      p.active ? p.theme.colors.primary3 : p.theme.colors.neutral5} !important;
  }
  a:hover {
    color: ${(p) => p.theme.colors.primary3} !important;
  }
`;

const ActiveBar = styled.div<WrapperProps>`
  height: 6px;
  width: 100%;
  position: relative;
  bottom: -11px;
  background: ${(p) => (p.active ? p.theme.colors.primary3 : 'transparent')};
`;

const NavItem = ({children, active, to}) => (
  <div>
    <NavItemContainer active={active}>
      <Link to={to} style={{fontSize: 18}}>
        {children}
      </Link>
    </NavItemContainer>
    <ActiveBar active={active} />
  </div>
);

const MobileCol = styled.div`
  display: inline-flex !important;
  margin-left: 32px;
`;

const RightContainer = styled.div`
  flex: 2;
  justify-content: flex-end;
  display: flex;
  margin-top: 19px;
  @media only screen and (max-width: ${({theme}) => theme.breakpoints.small}) {
    display: none !important;
  }
`;

const ClinicName = styled.h1`
  font-weight: 600;
  font-size: 24px;
  margin: 0px;
`;

interface AppNavProps {
  pathname: string;
  clinicName?: string | null;
}

export default function AppNav({pathname, clinicName = 'Clinic'}: AppNavProps) {
  return (
    <>
      <AppNavContainer>
        <div
          style={{
            height: 72,
            margin: 'auto',
            width: 1150,
            maxWidth: '90%',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <ClinicName>{clinicName}</ClinicName>
          <RightContainer>
            <MobileCol>
              <NavItem
                to={`/clinic/home`}
                active={pathname.includes('/clinic/home')}
              >
                PATIENTS
              </NavItem>
            </MobileCol>
            <MobileCol>
              <NavItem
                to={`/clinic/settings`}
                active={pathname && pathname.includes('/clinic/settings')}
              >
                SETTINGS
              </NavItem>
            </MobileCol>
          </RightContainer>
        </div>
        {/* */}
      </AppNavContainer>
    </>
  );
}
