import { PlanSettingPart } from './types'
import part1PNG from 'components/common/PlanDoc/imgs/part1.png'
import part2PNG from 'components/common/PlanDoc/imgs/part2.png'
import part3PNG from 'components/common/PlanDoc/imgs/part3.png'
import part4PNG from 'components/common/PlanDoc/imgs/part4.png'
// STATES
import getAK from './states/ak'
import getAR from './states/ar'
import getAZ from './states/az'
import getCA from './states/ca'
import getCO from './states/co'
import getDC from './states/dc'
import getDE from './states/de'
import getFL from './states/fl'
import getGA from './states/ga'
import getIL from './states/il'
import getMa from './states/ma'
import getMD from './states/md'
import getMi from './states/mi'
import getMN from './states/mn'
import getNC from './states/nc'
import getNJ from './states/nj'
import getNY from './states/ny'
import getOR from './states/or'
import getPA from './states/pa'
import getTX from './states/tx'
import getVA from './states/va'
import getWA from './states/wa'
import 'lib/helpers/i18n' // this will make it so that i18n.t() translates correclty
import i18n from 'lib/helpers/i18n'
import { Patient, PatientFragmentFragment } from 'generated/graphql'
import { DeepPartial } from 'utility-types'

export enum AdPartEnum {
  'health_care_choices' = 'health_care_choices',
  'medical_decision_maker' = 'medical_decision_maker',
  'sign_the_form' = 'sign_the_form',
  'faqs_and_legal' = 'faqs_and_legal',
}

export const getPartByPartId = (parts: PlanSettingPart[], partId: AdPartEnum): PlanSettingPart | undefined => {
  return parts?.find((part) => part.id === partId?.toString())
}

export interface PlanSetting {
  state: string
  disclaimer: string
  parts: PlanSettingPart[]
}

interface PlanSettings {
  [key: string]: PlanSetting
}

export const getPlanSettings = (patient: Patient | PatientFragmentFragment | DeepPartial<Patient>): PlanSettings => {
  const t = i18n.t.bind(i18n)
  const lang = i18n.language

  /**
   * These are the default settings for each part.
   * You can ovewrite them inside the actual planSettings object.
   */
  const defaultPartSettings = {
    [AdPartEnum.health_care_choices]: {
      id: AdPartEnum.health_care_choices,
      img: part1PNG,
      name: t('planDoc.health_care_choices.title', 'Part 1: Make your own health care choices'),
      description: t(
        'planDoc.health_care_choices.description',
        'This part is also known as a Living Will. It expresses the kind of treatment you would want in a medical crisis.'
      ),
    },
    [AdPartEnum.medical_decision_maker]: {
      id: AdPartEnum.medical_decision_maker,
      name: t('planDoc.medical_decision_maker.title', 'Part 2: Choose a medical decision maker'),
      img: part2PNG,
      description: t(
        'planDoc.medical_decision_maker.description',
        'This part is also known as a Medical Power of Attorney. It designates a person to make decisions for you when you are unable to speak for yourself.'
      ),
    },
    [AdPartEnum.sign_the_form]: {
      id: AdPartEnum.sign_the_form,
      name: t('planDoc.sign_the_form.title', 'Part 3: Sign the form'),
      description: t(
        'planDoc.sign_the_form.description',
        'You can fill out Part 1, Part 2, or both, but you need to sign the form in Part 3. To be legal, two witnesses also need to sign.'
      ),
      img: part3PNG,
    },
    [AdPartEnum.faqs_and_legal]: {
      id: AdPartEnum.faqs_and_legal,
      name: t('planDoc.faqs_and_legal.title', 'Part 4: FAQs and Legal'),
      description: t('planDoc.faqs_and_legal.description', ' Legal disclaimers are included in this part.'),
      img: part4PNG,
    },
  }

  const planSettings: PlanSettings = {
    /**
     * ARKANSAS
     */
    AK: getAK(t, defaultPartSettings),
    /**
     * ARKANSAS
     */
    AR: getAR(t, defaultPartSettings),
    /**
     * ARIZONA
     */
    AZ: getAZ(t, defaultPartSettings, patient),
    /**
     * CALIFORNIA
     */
    CA: getCA(t, defaultPartSettings),
    /**
     * COLORADO
     */
    CO: getCO(t, defaultPartSettings),
    /**
     * WASHINGTON DC
     */
    DC: getDC(t, defaultPartSettings),
    /**
     * DELAWARE
     */
    DE: getDE(t, defaultPartSettings, patient),
    /**
     * FLORIDA
     */
    FL: getFL(t, defaultPartSettings),
    /**
     * GEORGIA
     */
    GA: getGA(t, defaultPartSettings),
    /**
     * ILLINOIS
     */
    IL: getIL(t, defaultPartSettings, patient),
    /**
     * MASSACHUSETTS
     */
    MA: getMa(t, defaultPartSettings, patient),
    /**
     * MARYLAND
     */
    MD: getMD(t, defaultPartSettings),
    /**
     * MICHIGAN
     */
    MI: getMi(t, defaultPartSettings, patient),
    /**
     * MINNESOTA
     */
    MN: getMN(t, defaultPartSettings, patient),
    /**
     * NORTH CAROLINA
     */
    NC: getNC(t, defaultPartSettings),
    /**
     * NEW JERSEY
     */
    NJ: getNJ(t, defaultPartSettings, patient),
    /**
     * NEW YORK
     */
    NY: getNY(t, defaultPartSettings, patient),
    /**
     * OREGON
     */
    OR: getOR(t, defaultPartSettings),
    /**
     * PENNSYLVANIA
     */
    PA: getPA(t, defaultPartSettings),
    /**
     * TEXAS
     */
    TX: getTX(t, defaultPartSettings, lang),
    /**
     * VIRGINIA
     */
    VA: getVA(t, defaultPartSettings),
    /**
     * WASHINGTON
     */
    WA: getWA(t, defaultPartSettings, patient),
  }

  return planSettings
}
