import React from 'react'
import styled from 'styled-components'
import elephantSVG from 'lib/media/images/elephant.svg'
import Animated from 'components/common/Animated'

export const ElephantChatText = styled.div`
  font-size: 20px;
  text-align: left;
  line-height: 120%;
  font-weight: 500;
  color: ${(p) => p.theme.colors.neutral1};
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    font-size: 16px;
  }
`

const Container = styled.div`
  display: flex;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    padding-top: 16px;
  }
`

const Elephant = styled.img`
  height: 96px;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    height: 48px;
  }
`

const ElephanContainer = styled.div`
  margin-right: 24px;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.mediumSmall}) {
    margin-right: 16px;
    display: flex;
    align-items: flex-end;
  }
`

const Bubble = styled.div`
  .bubble {
    position: relative;
    width: 100%;
    background: #fff;
    border-radius: 5px;
    padding: 16px 24px;
    text-align: center;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.06);
    @media only screen and (max-width: ${({ theme }) => theme.breakpoints.mediumSmall}) {
      padding: 8px 16px;
    }
  }

  .bubble-bottom-left:before {
    content: '';
    width: 0px;
    height: 0px;
    border-radius: 5px;
    position: absolute;
    border-left: 24px solid transparent;
    border-right: 12px solid #fff;
    border-top: 12px solid transparent;
    border-bottom: 20px solid #fff;
    left: -24px;
    bottom: 24px;
    @media only screen and (max-width: ${({ theme }) => theme.breakpoints.mediumSmall}) {
      bottom: 8px;
    }
  }
`

type ElephantChatProps = {
  style?: React.CSSProperties
  bubleStyle?: React.CSSProperties
  text?: string | JSX.Element
}

export default function ElephantChat({ text, style, bubleStyle }: ElephantChatProps) {
  return (
    <Container style={style}>
      <ElephanContainer>
        <Elephant src={elephantSVG} alt="Koda Elephant" />
      </ElephanContainer>
      <Bubble style={bubleStyle}>
        <Animated animationIn="pulse">
          <div className="bubble bubble-bottom-left">
            <ElephantChatText data-testid="elephant-chat-text">{text}</ElephantChatText>
          </div>
        </Animated>
      </Bubble>
    </Container>
  )
}
