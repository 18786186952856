import s from '../../KodaContent/styles'
import { Text, View, StyleSheet } from '@react-pdf/renderer'
import BulletText from '../../KodaContent/BulletText'
import TextField from '../../KodaContent/TextField'
import Checkbox from 'components/common/PlanDoc/KodaContent/Checkbox'
import { PlanFontFamily } from '../../PlanFontFamily'
import moment from 'moment'
import { Patient } from 'generated/graphql'
import { AdPartEnum, PlanSetting } from '../plan-settings'
import { PlanSettingPart } from '../plan-settings/types'
import NotaryPage from '../NotaryPage'
import PlanSectionDocument from 'components/common/PlanDoc/PlanSectionDocument'
import i18n from 'lib/helpers/i18n'

const internalStyles = StyleSheet.create({
  cursiveText: {
    fontFamily: PlanFontFamily.cursive,
    position: 'relative',
    top: -2,
    lineHeight: 1.1,
  },
})

const getColumnWidth = (index: number, width?: string) => {
  if (width) return width
  return index % 2 ? '60%' : '40%'
}

const Requirements = ({ part, numOfReqs }: { part: PlanSettingPart; numOfReqs: number }) => {
  //if (numOfReqs > 3) {
  return (
    <View style={s.sectionContainer}>
      <Text style={{ ...s.sectionTitle, marginBottom: 0 }}>{part?.witnessRequirements?.title}</Text>
      <View style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
        {part?.witnessRequirements?.requirements?.map((req, index) => {
          return (
            <View
              key={req.title}
              // for states with one requirement we just do 100%, if there is more than one we make the first column smaller (40%) than the second (60%)
              style={{
                flexBasis: numOfReqs === 1 ? '100%' : getColumnWidth(index, req?.width),
              }}
            >
              <Text
                style={{
                  ...s.textBold,
                  marginBottom: 0,
                }}
              >
                {req.title}
              </Text>
              {req.phrases?.map((phrase, index) => (
                <BulletText key={index} fontSize={11} text={phrase} />
              ))}
            </View>
          )
        })}
      </View>
    </View>
  )
  //}

  // return (
  //   <View style={s.sectionContainer}>
  //     <Text style={{ ...s.sectionTitle, marginBottom: 0 }}>{part?.witnessRequirements?.title}</Text>
  //     <View style={{ display: 'flex', flexDirection: 'row' }}>
  //       <View style={{ flex: 1 }}>
  //         {part?.witnessRequirements?.requirements?.slice(0, 1)?.map((req) => {
  //           return (
  //             <View key={req.title}>
  //               <Text
  //                 style={{
  //                   ...s.textBold,
  //                   marginBottom: 0,
  //                 }}
  //               >
  //                 {req.title}
  //               </Text>
  //               {req.phrases?.map((phrase, index) => (
  //                 <BulletText key={index} fontSize={11} text={phrase} />
  //               ))}
  //             </View>
  //           )
  //         })}
  //       </View>
  //       <View style={{ flex: 1 }}>
  //         {part?.witnessRequirements?.requirements?.slice(1, 100)?.map((req) => {
  //           return (
  //             <View key={req.title}>
  //               <Text
  //                 style={{
  //                   ...s.textBold,
  //                   marginBottom: 0,
  //                 }}
  //               >
  //                 {req.title}
  //               </Text>
  //               {req.phrases?.map((phrase, index) => (
  //                 <BulletText key={index} fontSize={11} text={phrase} />
  //               ))}
  //             </View>
  //           )
  //         })}
  //       </View>
  //     </View>
  //   </View>
  // )
}

const Witness1Section = ({ part, data }) => {
  if (part?.witness1SignatureText) {
    return (
      <>
        <Text style={{ ...s.textBold, marginBottom: 0, marginTop: 0 }}>
          {i18n.t('MasterPlanDocSignaturePage.witness1', 'Witness #1:')}
        </Text>
        <View>{part?.witness1SignatureText}</View>
      </>
    )
  }

  return (
    <>
      <Text style={{ ...s.text, fontSize: 11 }}>
        {i18n.t('MasterPlanDocSignaturePage.witnessSignatureText1', 'By signing, I promise that ')}
        <Text style={{ ...s.text, fontFamily: 'Roboto Bold' }}>
          {data.firstName} {data.lastName}
        </Text>{' '}
        {i18n.t(
          'MasterPlanDocSignaturePage.witnessSignatureText2',
          'signed this form while I watched. They were thinking clearly and were not forced to sign it. I also promise that I meet the witness requirements listed above.'
        )}
      </Text>
      <View>
        <Text style={{ ...s.textBold, marginBottom: 0, marginTop: 0 }}>
          {i18n.t('MasterPlanDocSignaturePage.witness1', 'Witness #1:')}
        </Text>
      </View>
    </>
  )
}

const WitnessSignatures = ({ data, part }: { data: any; part: PlanSettingPart | undefined }) => {
  const textFieldStyle = {
    marginTop: 3,
    borderWidth: 4,
  }
  //
  return (
    <View style={s.sectionContainer} wrap={false}>
      <Text style={{ ...s.sectionTitle, marginBottom: 0 }}>
        {i18n.t('MasterPlanDocSignaturePage.witnessSignatureTitle', 'Witnesses Signature')}
      </Text>
      {part?.witnessSignatures?.preSignaturesContent ? (
        <>
          {part?.witnessSignatures?.preSignaturesContent}
          <Text style={{ ...s.textBold, marginBottom: 0, marginTop: 0 }}>
            {i18n.t('MasterPlanDocSignaturePage.witness1', 'Witness #1:')}
          </Text>
        </>
      ) : (
        <Witness1Section part={part} data={data} />
      )}

      <View style={{ display: 'flex', flexDirection: 'row' }}>
        <TextField
          label={i18n.t('MasterPlanDocSignaturePage.printFirstName', 'Print your first name')}
          style={textFieldStyle}
          value={data?.witness1SignatureFirstName || ''}
        />
        <TextField
          label={i18n.t('MasterPlanDocSignaturePage.printLastName', 'Print your last name')}
          style={textFieldStyle}
          value={data?.witness1SignatureLastName || ''}
        />
        <TextField
          label={i18n.t('MasterPlanDocSignaturePage.email', 'Email')}
          style={textFieldStyle}
          value={data?.witness1SignatureEmail || ''}
        />
      </View>

      <View style={{ display: 'flex', flexDirection: 'row' }}>
        <TextField
          flex={3}
          label={i18n.t('MasterPlanDocSignaturePage.signYourName', 'Sign your name')}
          style={textFieldStyle}
          valueStyle={internalStyles.cursiveText}
          value={
            data?.witness1SignatureFirstName && data?.witness1SignatureLastName
              ? `${data?.witness1SignatureFirstName} ${data?.witness1SignatureLastName}`
              : ''
          }
        />
        <TextField
          label={i18n.t('MasterPlanDocSignaturePage.date', 'Date')}
          style={textFieldStyle}
          value={data?.witness1SignatureDate ? moment(data?.witness1SignatureDate).format('MMM DD, YYYY') : ''}
        />
      </View>
      <Text style={{ ...s.textBold, marginBottom: 0 }}>
        {i18n.t('MasterPlanDocSignaturePage.witness2', 'Witness #2:')}
      </Text>
      {part?.witness2SignatureText && <Text style={{ ...s.text, fontSize: 11 }}>{part?.witness2SignatureText}</Text>}
      {part?.witness2InLongtermCareCheck && (
        <View style={{ paddingRight: 10 }}>
          <Checkbox checkboxStyle={{ marginTop: 3 }} checked={false}>
            <Text style={s.formLabel}>
              {i18n.t(
                'MasterPlanDocSignaturePage.longTermCareCheckbox',
                'Check the box if the declarant (person creating this document) lives in a long-term care facility AND you qualify to serve as a witness.'
              )}
            </Text>
          </Checkbox>
        </View>
      )}
      <View style={{ display: 'flex', flexDirection: 'row' }}>
        <TextField
          label={i18n.t('MasterPlanDocSignaturePage.printFirstName', 'Print your first name')}
          style={textFieldStyle}
          value={data?.witness2SignatureFirstName || ''}
        />
        <TextField
          label={i18n.t('MasterPlanDocSignaturePage.printLastName', 'Print your last name')}
          style={textFieldStyle}
          value={data?.witness2SignatureLastName || ''}
        />
        <TextField
          label={i18n.t('MasterPlanDocSignaturePage.email', 'Email')}
          style={textFieldStyle}
          value={data?.witness2SignatureEmail || ''}
        />
      </View>

      <View style={{ display: 'flex', flexDirection: 'row' }}>
        <TextField
          flex={3}
          label={i18n.t('MasterPlanDocSignaturePage.signYourName', 'Sign your name')}
          style={textFieldStyle}
          valueStyle={internalStyles.cursiveText}
          value={
            data?.witness2SignatureFirstName && data?.witness2SignatureLastName
              ? `${data?.witness2SignatureFirstName} ${data?.witness2SignatureLastName}`
              : ''
          }
        />
        <TextField
          label={i18n.t('MasterPlanDocSignaturePage.date', 'Date')}
          style={textFieldStyle}
          value={data?.witness2SignatureDate ? moment(data?.witness2SignatureDate).format('MMM DD, YYYY') : ''}
        />
      </View>
      {part?.witnessSignatures?.postSignaturesContent}
    </View>
  )
}

interface SignaturePageProps {
  data: Patient
  settings: PlanSetting
  numOfReqs: number
  showWitnessSignature: boolean
}

function SignaturePage({ data, settings, numOfReqs, showWitnessSignature }: SignaturePageProps) {
  const part = settings?.parts?.find(({ id }) => id === AdPartEnum.sign_the_form)

  return (
    <View>
      <View style={s.sectionContainer}>
        <>
          <Text style={{ ...s.sectionTitle, marginBottom: 0 }}>
            {i18n.t('MasterPlanDocSignaturePage.yourSignature')}
          </Text>
          <Text style={{ ...s.textBold, marginBottom: 2 }}>{i18n.t('MasterPlanDocSignaturePage.beforeThisForm')}</Text>
          {part?.beforeYouSign?.requirements?.map((text, index) => (
            <BulletText key={index} fontSize={11} text={text} />
          ))}
          {part?.beforeYouSign?.finalClause && typeof part?.beforeYouSign?.finalClause === 'string' ? (
            <Text style={{ ...s.textBold, marginBottom: 6, marginTop: 2 }}>{part?.beforeYouSign?.finalClause}</Text>
          ) : (
            part?.beforeYouSign?.finalClause
          )}
        </>
        <View style={{ display: 'flex', flexDirection: 'row' }}>
          <TextField
            flex={2}
            label={i18n.t('MasterPlanDocSignaturePage.printFirstName', 'Print your first name')}
            value={data.firstName || ''}
          />
          <TextField
            flex={2}
            label={i18n.t('MasterPlanDocSignaturePage.printLastName', 'Print your last name')}
            value={data.lastName || ''}
          />
          <TextField
            label={i18n.t('MasterPlanDocSignaturePage.dateOfBirth', 'Date of Birth')}
            value={data?.birthday || ''}
          />
        </View>
        <View style={{ display: 'flex', flexDirection: 'row' }}>
          <TextField
            flex={2}
            label={i18n.t('MasterPlanDocSignaturePage.address', 'Address')}
            value={data?.address || ''}
          />
          <TextField label={i18n.t('MasterPlanDocSignaturePage.city', 'City')} value={data?.city || ''} />
          <TextField
            label={i18n.t('MasterPlanDocSignaturePage.state', 'State')}
            value={data?.stateVersion?.name || ''}
          />
          <TextField label={i18n.t('MasterPlanDocSignaturePage.zipcode', 'Zip code')} value={data?.postal || ''} />
        </View>
        <View style={{ display: 'flex', flexDirection: 'row' }}>
          <TextField
            flex={3}
            label={i18n.t('MasterPlanDocSignaturePage.signYourName', 'Sign your name')}
            valueStyle={internalStyles.cursiveText}
            value={
              data?.patientSignatureFirstName && data?.patientSignatureLastName
                ? `${data?.patientSignatureFirstName} ${data?.patientSignatureLastName}`
                : ''
            }
          />
          <TextField
            label={i18n.t('MasterPlanDocSignaturePage.date', 'Date')}
            value={data?.patientSignatureDate ? moment(data?.patientSignatureDate).format('MMM DD, YYYY') : ''}
          />
        </View>
      </View>
      {part && <Requirements part={part} numOfReqs={numOfReqs} />}
      {showWitnessSignature && <WitnessSignatures data={data} part={part} />}
    </View>
  )
}

export default function SignTheForm({ data, name, pageTitle, pageFooterRight, settings, hasNextPart }) {
  const part = settings?.parts?.find(({ id }) => id === AdPartEnum.sign_the_form)
  const numOfReqs = part?.witnessRequirements?.requirements?.length || 0
  const finalClauseIsString = part?.beforeYouSign?.finalClause && typeof part?.beforeYouSign?.finalClause === 'string'

  const showWitnessSignature = finalClauseIsString && numOfReqs <= 3

  return (
    <>
      <PlanSectionDocument
        pageTitleLeft={pageTitle}
        pageTitleRight={name}
        showFooter
        pageFooterRight={pageFooterRight}
        content={
          <>
            <SignaturePage
              data={data}
              settings={settings}
              numOfReqs={numOfReqs}
              showWitnessSignature={showWitnessSignature}
            />
            {!showWitnessSignature && <WitnessSignatures data={data} part={part} />}
            {part?.postSignTheFormContent && part?.postSignTheFormContent}
            {part?.notary && (
              <>
                <NotaryPage settings={settings} />
                {hasNextPart && (
                  <View style={{ marginTop: 72, textAlign: 'center' }}>
                    <Text
                      style={{
                        ...s.textBold,
                        textAlign: 'center',
                        marginBottom: 6,
                        marginTop: 2,
                      }}
                    >
                      {i18n.t('MasterPlanDoc.notTheLastPage')}
                    </Text>
                  </View>
                )}
              </>
            )}
          </>
        }
      />
    </>
  )
}
