import styled from "styled-components";

// COMPONENTS
import Button from 'components/common/Button';

const ModalContentScrollable = styled.div`
  overflow-y: auto;
  margin-top: 40px;
  max-height: calc(96vh - 150px);
  margin-bottom: 50px;
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints.medium}) {
    max-height: calc(78vh - 150px);
  }
`;

const CongratsBlock = styled.div`
  border: 1px solid #CBD2D9;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 24px;
  margin-bottom: 24px;
  margin-top: 40px;
`;

const CongratsBlockTitle = styled.div`
  margin-bottom: 8px;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  letter-spacing: -0.03em;
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints.mediumSmall}) {
    font-size: 20px;
  }
`;
const CongratsBlockDescription = styled.div`
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: -0.03em;
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints.mediumSmall}) {
    font-size: 16px;
  }
`;

const CongratsOKButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 0 40px 20px 40px;
  z-index: 20;
  button {
    width: 140px;
  }
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints.mediumSmall}) {
    padding: 0 20px 20px 20px;
  }
`;

interface PostSurveyCongratsWfpath1NoProps {
  closeClick: () => void;
};

const PostSurveyCongratsWfpath1No = ({ closeClick }: PostSurveyCongratsWfpath1NoProps) => {
  return (
    <ModalContentScrollable>
      <CongratsBlock>
        <CongratsBlockTitle>Congratulations!</CongratsBlockTitle>
        <CongratsBlockDescription>
          You’ve completed the first part of the study. You will be sent a $25 gift 
          card as a thank you for your participation.
        </CongratsBlockDescription>
      </CongratsBlock>
      <CongratsOKButtonWrapper>
        <Button
          onClick={closeClick}
          style={{
            display: "block",
            visibility: "visible",
          }}
        >
          OK
        </Button>
      </CongratsOKButtonWrapper>
    </ModalContentScrollable>
  )
}

export default PostSurveyCongratsWfpath1No;
