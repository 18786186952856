import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
// IMAGES
import checklistSVG from './icons/checklist.svg'
import phoneSVG from './icons/phone.svg'
import valuesSVG from 'lib/media/images/values_section.svg'
import medicalDecisionSVG from 'lib/media/images/mdm_section.svg'
import qualitySVG from 'lib/media/images/quality_of_life_section.svg'
import medicalSVG from 'lib/media/images/medical_section.svg'
import supportiveCareSVG from 'lib/media/icons/Supportive_Care.svg'
import makePlanOfficialSvg from 'lib/media/images/make_plan_official.svg'
import unofficialPlanSvg from 'lib/media/images/unofficial_plan.svg'
import officialPlanSvg from 'lib/media/images/official_plan.svg'
import nextStepsSvg from './icons/next-steps.svg'
// COMPONENTS
import ElephantChat from 'components/common/ElephantChat'
import SectionBlock from './SectionBlock'
import PostSurveyAlert from 'components/common/PostSurveyAlert'
import { APP_PAGES_ENUMS } from '../index'
import { GuideTemplateSection, GuideTemplateSectionIdEnum, Patient } from 'generated/graphql'
import PlanConfiguratorHelper from './PlanConfiguratorHelper'
// HOOKs
import useUrlChange from 'lib/hooks/useUrlChange'
// LIB
import i18n from 'lib/helpers/i18n'
import { LocalStorageKeyEnum } from 'lib/types/LocalStorageKeyEnum'
import isWakeForest from 'lib/helpers/isWakeForest'
import getLocalStorage from 'lib/helpers/getLocalStorage'

const Container = styled.div`
  width: 700px;
  max-width: 100%;
  margin: auto;
`

const Card = styled.div`
  padding: 16px;
  background: #fff;
  border-radius: 5px;
  border: 1px solid ${(p) => p.theme.colors.neutral9};
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.06);
  margin-top: 32px;
`

const CardTitle = styled.h1`
  font-size: 28px;
  color: ${(p) => p.theme.colors.neutral1};
  font-weight: 800;
  text-align: center;
  margin-bottom: 24px;
`

type PlanConfiguratorProps = {
  plan: Patient
  guideComplete: boolean
}

const ICON_HASH_MAP = {
  [GuideTemplateSectionIdEnum.PreSurvey]: checklistSVG,
  [GuideTemplateSectionIdEnum.SchedulingAcp]: phoneSVG,
  [GuideTemplateSectionIdEnum.PostAcpSurvey]: checklistSVG,
  [GuideTemplateSectionIdEnum.Values]: valuesSVG,
  [GuideTemplateSectionIdEnum.QualityOfLife]: qualitySVG,
  [GuideTemplateSectionIdEnum.MedicalCare]: medicalSVG,
  [GuideTemplateSectionIdEnum.MedicalDecisionMaker]: medicalDecisionSVG,
  [GuideTemplateSectionIdEnum.SupportiveCare]: supportiveCareSVG,
}

export const ACP_POST_SURVEY_SECTIONS = [
  GuideTemplateSectionIdEnum.PostAcpSurvey,
  GuideTemplateSectionIdEnum.SchedulingAcp,
]

const planHelpers = new PlanConfiguratorHelper()

interface getActiveStepProps {
  plan: Patient
  sections?: GuideTemplateSection[]
  guideComplete: boolean
}

const getActiveStep = ({ plan, sections, guideComplete }: getActiveStepProps): GuideTemplateSection | undefined => {
  if (!sections) return undefined
  // if all steps are already complete, then there is no active step
  if (guideComplete) return undefined
  let activeStep
  // "some" is similar to forEach, but you'll be able to break from the loop if you want by returning true: https://stackoverflow.com/questions/2641347/short-circuit-array-foreach-like-calling-break
  sections.some((section, index) => {
    const lastIndex = index !== 0 ? index - 1 : 0
    const lastStep = sections[lastIndex]
    const lastStepIsComplete = planHelpers.isComplete(lastStep, plan)
    const isComplete = planHelpers.isComplete(section, plan)
    // if this is the first module and it's not complete, then it's the next active step.
    if (index === 0 && !isComplete) {
      activeStep = section
      return true // break from the loop
    }
    // if the last step is complete, but this step is not, then this step is the active step.
    if (lastStepIsComplete && !isComplete) {
      activeStep = section
      return true // break from the loop
    }
    return false
  })
  return activeStep
}

const getPageTitle = (guideComplete) => {
  if (guideComplete) return i18n.t('medicalDecisionMakerPage.editYourPlan', 'Edit your Plan')
  return i18n.t('medicalDecisionMakerPage.createYourPlan')
}

const getMakePlanOfficialActiveStep = (plan: Patient): boolean => {
  const isWFpath2Part2 = plan?.guideTemplate?.name === 'WF_Path2_Part2'
  if (!isWakeForest(plan) && plan.sharedPlan && !plan.hasOfficialPlan) return true
  if (!isWakeForest(plan)) return false
  if (!isWFpath2Part2 && plan?.hasPlanViewed) return true
  if (isWFpath2Part2 && plan?.sharedPlan) return true
  return false
}

const getViewPlanButtonActiveStep = (plan: Patient, hasViewedNextStepsLearnMore: string | null): boolean => {
  if (isWakeForest(plan)) return true
  if (hasViewedNextStepsLearnMore && plan.sharedPlan) return false
  return hasViewedNextStepsLearnMore ? true : false
}

const getViewPlanButtonType = (plan: Patient): 'primary' | 'secondary' => {
  const isViewedAndShared = plan.sharedPlan && plan.hasPlanViewed
  const isWFTpath1 = plan?.guideTemplate?.name === 'WF_Path1'
  const isWFTpath2 = plan?.guideTemplate?.name === 'WF_Path2'
  const isWFpath2Part2 = plan?.guideTemplate?.name === 'WF_Path2_Part2'
  if (isWFTpath1 && plan.hasPlanViewed) return 'secondary'
  if (isWFTpath1 || isWFTpath2) return 'primary'
  if (!isViewedAndShared || (!plan.sharedPlan && isWFpath2Part2)) return 'primary'
  return 'secondary'
  // old logic
  // buttonType={
  //   ((!plan.sharedPlan && !plan.hasPlanViewed) ||
  //     (!plan.sharedPlan && plan?.guideTemplate?.name === 'WF_Path2_Part2')) &&
  //   plan?.guideTemplate?.name !== 'WF_Path1'
  //     ? 'primary'
  //     : 'secondary'
  // }

  // if (plan.sharedPlan && plan.hasPlanViewed) return 'secondary'
  // const thing1 = (!plan.sharedPlan && !plan.hasPlanViewed)
  // const thing2 = (!plan.sharedPlan && plan?.guideTemplate?.name === 'WF_Path2_Part2')
  // if (plan?.guideTemplate?.name !== 'WF_Path1' && (thing1 || thing2)) return 'primary'
  // return 'secondary'
}

const getVeiwPlanPulse = (plan: Patient) => {
  const isWFTpath1 = plan?.guideTemplate?.name === 'WF_Path1'
  if (isWFTpath1 && plan.hasPlanViewed) return false
  return !plan.hasOfficialPlan && (!plan.sharedPlan || !plan.hasPlanViewed)
}

export default function PlanConfigurator({ guideComplete, plan }: PlanConfiguratorProps) {
  const { onUrlChange } = useUrlChange()
  const { t } = useTranslation()
  const history = useHistory()

  const planHelpers = new PlanConfiguratorHelper()

  const onGoToQuestions = (newValue) => {
    onUrlChange(newValue, '/app/questions')
  }

  const onSectionEdit = (section, goToQuestions = false) => {
    switch (section?.sectionId) {
      case GuideTemplateSectionIdEnum.PreSurvey: {
        onUrlChange({}, '/app/survey')
        break
      }
      case GuideTemplateSectionIdEnum.SchedulingAcp: {
        onUrlChange({}, '/app/acp-scheduling')
        break
      }
      case GuideTemplateSectionIdEnum.PostAcpSurvey: {
        onUrlChange({}, '/app/post-survey')
        break
      }
      default: {
        if (goToQuestions) {
          onGoToQuestions({
            editSection: section?.sectionId as string,
            step: section?.questionIds?.[0],
          })
        } else {
          onUrlChange(
            {
              mode: APP_PAGES_ENUMS.EDIT_PLAN,
              editSection: section?.sectionId,
            },
            null
          )
        }
        break
      }
    }
  }

  const getSections = () => {
    if (plan?.guideTemplate?.name === 'WF_Path1') {
      return sections?.filter((section) => !ACP_POST_SURVEY_SECTIONS.includes(section?.sectionId))
    }
    return sections
  }

  if (!plan?.clinic) return null

  let sections: GuideTemplateSection[] | undefined = plan?.guideTemplate?.sections

  const activeStep = getActiveStep({
    plan,
    sections,
    guideComplete,
  })
  const isPostSurveyComplete = planHelpers.getIsPostAcpSurveyCompleted(plan)

  const hasViewedNextStepsLearnMore = getLocalStorage(
    `${plan?.userId}-${LocalStorageKeyEnum.HasViewedNextStepsLearnMore}`
  )

  return (
    <>
      <Container>
        <PostSurveyAlert guideComplete={guideComplete} clinicId={plan?.clinic?.id} patient={plan} />
        <ElephantChat
          text={
            activeStep?.activeStepText
              ? i18n.t(activeStep?.activeStepText)
              : planHelpers.getElephantText({
                  activeStep: activeStep?.sectionId,
                  plan,
                  guideComplete,
                })
          }
        />
        {guideComplete && plan?.guideTemplate?.name !== 'WF_Path2' && (
          <Card>
            {/* only show next steps if this is not WF and they don't already have an offical plan*/}
            {!isWakeForest(plan) && !plan.hasOfficialPlan && (
              <SectionBlock
                showButton
                title={i18n.t('blocks.nextSteps', 'Next steps')}
                activeStep={!hasViewedNextStepsLearnMore ? true : false}
                pulse={!hasViewedNextStepsLearnMore}
                icon={nextStepsSvg}
                buttonText={i18n.t('button.learn', 'Learn')}
                buttonType={!hasViewedNextStepsLearnMore ? 'primary' : 'secondary'}
                onClick={() => history.push(`/app?mode=nextSteps`)}
              />
            )}
            {!plan.hasOfficialPlan && (
              <SectionBlock
                title={
                  !plan.hasOfficialPlan
                    ? i18n.t('blocks.viewUnofficialPlan', 'View Unofficial Plan')
                    : i18n.t('blocks.viewOfficialPlan', 'View Official Plan')
                }
                showButton={getMakePlanOfficialActiveStep(plan)}
                activeStep={getViewPlanButtonActiveStep(plan, hasViewedNextStepsLearnMore)}
                pulse={getVeiwPlanPulse(plan)}
                icon={unofficialPlanSvg}
                buttonText={t('button.view', 'View')}
                buttonType={getViewPlanButtonType(plan)}
                onClick={() => history.push(`/app?mode=viewPlan`)}
              />
            )}
            {plan?.guideTemplate?.name !== 'WF_Path1' && !plan.hasOfficialPlan && (
              <SectionBlock
                title={i18n.t('blocks.makePlanOfficial', 'Make Plan Official')}
                icon={makePlanOfficialSvg}
                pulse
                activeStep={getMakePlanOfficialActiveStep(plan)}
                buttonText={i18n.t('button.next', 'Next')}
                buttonType="primary"
                onClick={() => history.push(`/app?mode=notarize`)}
              />
            )}
            {plan?.guideTemplate?.name === 'WF_Path1' &&
              !plan.hasOfficialPlan &&
              isPostSurveyComplete &&
              plan.postAcpDigitalAdvanceCarePlanning === 'yes' && (
                <SectionBlock
                  title={i18n.t('blocks.cPScheduling', 'ACP Scheduling')}
                  icon={phoneSVG}
                  pulse={!plan?.schedulingACPinformation}
                  activeStep={true}
                  buttonText={i18n.t('button.next', 'Next')}
                  buttonType="primary"
                  onClick={() => history.push(`/app/acp-scheduling`)}
                />
              )}
            {plan?.guideTemplate?.name === 'WF_Path1' &&
              !plan.hasOfficialPlan &&
              isPostSurveyComplete &&
              plan.postAcpDigitalAdvanceCarePlanning === 'no' && (
                <SectionBlock
                  title={i18n.t('blocks.makePlanOfficial', 'Make Plan Official')}
                  icon={makePlanOfficialSvg}
                  pulse
                  activeStep={plan?.hasPlanViewed ? true : false}
                  buttonText={i18n.t('button.next', 'Next')}
                  buttonType="primary"
                  onClick={() => history.push(`/app?mode=notarize`)}
                />
              )}

            {plan.hasOfficialPlan && (
              <SectionBlock
                title={i18n.t('blocks.viewOfficialPlan', 'View Official Plan')}
                icon={officialPlanSvg}
                activeStep
                pulse
                buttonText={i18n.t('button.view', 'View')}
                buttonType="primary"
                onClick={() => history.push(`/app?mode=viewPlan`)}
              />
            )}
            {plan?.guideTemplate?.name === 'WF_Path1' &&
              sections
                ?.filter((section) => ACP_POST_SURVEY_SECTIONS.includes(section?.sectionId))
                .map((section) => {
                  const isComplete = planHelpers.isComplete(section, plan)
                  if (section.sectionId === GuideTemplateSectionIdEnum.PostAcpSurvey && isPostSurveyComplete) {
                    return null
                  }
                  return (
                    <SectionBlock
                      key={section?.sectionName}
                      title={section?.sectionName}
                      icon={ICON_HASH_MAP[section?.sectionId as string]}
                      complete={isComplete}
                      activeStep={!!plan?.hasPlanViewed}
                      pulse
                      buttonText={i18n.t('button.next', 'Next')}
                      buttonType="primary"
                      onEdit={() => onSectionEdit(section)}
                      onClick={() => onSectionEdit(section, true)}
                    />
                  )
                })}
          </Card>
        )}
        <Card>
          <CardTitle>{getPageTitle(guideComplete)}</CardTitle>
          {getSections()?.map((section) => {
            let isComplete = planHelpers.isComplete(section, plan)
            let pulse = true
            if (section.sectionId === GuideTemplateSectionIdEnum.PreSurvey) {
              if (plan?.guideTemplate?.name === 'WF_Path1' && isPostSurveyComplete) {
                return null
              }
            }
            if (section.sectionId === GuideTemplateSectionIdEnum.PostAcpSurvey) {
              isComplete = planHelpers.getIsPostAcpSurveyCompleted(plan)
            }
            if (section.sectionId === GuideTemplateSectionIdEnum.SchedulingAcp && plan?.schedulingACPinformation) {
              pulse = false
            }
            return (
              <SectionBlock
                key={section?.sectionId}
                title={t(`planConfigurator.${section?.sectionId}`, section.sectionName)}
                icon={ICON_HASH_MAP[section?.sectionId as string]}
                complete={isComplete}
                pulse={pulse}
                activeStep={activeStep?.sectionId === section?.sectionId}
                onEdit={() => onSectionEdit(section)}
                onClick={() => onSectionEdit(section, true)}
              />
            )
          })}
        </Card>
      </Container>
    </>
  )
}
