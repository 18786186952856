import { ModalOverlay, Modal, ModalBody, ButtonArea } from 'components/common/KodaModal'
import Button from 'components/common/Button'
import styled from 'styled-components'
import ElephantChat from '../ElephantChat'
import SelectLabel from 'components/text/SelectLabel'
import TextOption from 'components/common/SurveyForm/SelectMultiple/TextOption'
import { useState } from 'react'
import ExitButton from 'components/common/ExitButton'
import { AnalyticEventTypeEnums } from 'lib/types/AnalyticEventTypeEnums'
import useLogActivityItemMutation from 'lib/hooks/useLogActivityItemMutation'
import {
  ActivityLogKeyEnum,
  Patient,
  PatientActionHistoryItemEnum,
  SubmitReadinessMpoResponseEnum,
  useCreateOneActionHistoryItemMutation,
  useCreateOneReadinessMpoResponseMutation,
} from 'generated/graphql'
import 'lib/helpers/i18n'
import i18n from 'lib/helpers/i18n'
import en from 'lib/helpers/locales/en/translation.json'
import notification from 'components/common/notification'
import logEvent from 'lib/helpers/logEvent'

const ReadinessModal = styled(Modal)`
  width: 600px;
`

interface ReadinessSubmitValue {
  answer: SubmitReadinessMpoResponseEnum
  otherAnswer?: string
}

export default function ReadinessSurveyModal({
  patient,
  visible,
  onClose,
  onSubmitComplete,
}: {
  patient: Patient
  visible: boolean
  onClose: () => void
  /** Once the for is submitted, we can call this function to close the modal, etc */
  onSubmitComplete: (data: ReadinessSubmitValue) => void
}) {
  const [value, setValue] = useState<SubmitReadinessMpoResponseEnum | undefined>(undefined)
  const [otherValue, setOtherValue] = useState<string | undefined>(undefined)
  const [logActivityItem] = useLogActivityItemMutation()
  const [createOneActionHistoryItemMutation] = useCreateOneActionHistoryItemMutation()
  const [submitReadinessMpoResponseMutation, { loading: submittingReadiness }] =
    useCreateOneReadinessMpoResponseMutation()

  if (!visible) return null

  const OPTIONS = [
    {
      label: i18n.t('ReadinessSurveyModal.imReady', en.ReadinessSurveyModal.imReady),
      id: SubmitReadinessMpoResponseEnum.ImReady,
      isOther: false,
    },
    {
      label: i18n.t('ReadinessSurveyModal.imReadyButNeedMore', en.ReadinessSurveyModal.imReadyButNeedMore),
      id: SubmitReadinessMpoResponseEnum.ImReadyButNeedMore,
      isOther: true,
    },
    {
      label: i18n.t('ReadinessSurveyModal.needMoreTime', en.ReadinessSurveyModal.needMoreTime),
      id: SubmitReadinessMpoResponseEnum.NeedMoreTime,
      isOther: false,
    },
    {
      label: i18n.t('ReadinessSurveyModal.needToTalkToMDM', en.ReadinessSurveyModal.needToTalkToMDM),
      id: SubmitReadinessMpoResponseEnum.NeedToTalkToMdm,
      isOther: false,
    },
    {
      label: i18n.t('ReadinessSurveyModal.notReadyBecause', en.ReadinessSurveyModal.notReadyBecause),
      id: SubmitReadinessMpoResponseEnum.NotReadyBecause,
      isOther: true,
    },
  ]

  const handleSubmit = async () => {
    if (value) {
      await submitReadinessMpoResponseMutation({
        variables: {
          answer: value,
          otherAnswer: otherValue,
        },
      })
      notification.success({ message: i18n.t('ReadinessSurveyModal.success') })

      logActivityItem({
        variables: {
          key: ActivityLogKeyEnum.PatientSubmittedMpoReadinessSurvey,
          importance: 10,
          userId: patient?.userId || '',
          patientId: patient?.id,
          // we pull in the english translation, otherwise we would be logging spanish answer labels for spanish users
          content: `Patient completed MPO Readiness survey: ${en?.['ReadinessSurveyModal']?.[value as any]} ${
            otherValue ? `(${otherValue})` : ''
          }`,
          clinicId: patient?.clinicId || '',
        },
      })

      onSubmitComplete({
        answer: value,
        otherAnswer: otherValue,
      })
    }
  }

  const handleClose = () => {
    // log the event
    logEvent(AnalyticEventTypeEnums.PATIENT_CLOSED_MPO_READINESS_SURVEY)
    // run mutation, but don't await for it to finish
    createOneActionHistoryItemMutation({
      variables: {
        name: PatientActionHistoryItemEnum.ClosedReadinessSurveyModal,
        value: 'CLOSED',
      },
    })
    onClose()
  }

  return (
    <ModalOverlay>
      <ReadinessModal>
        <ExitButton onClick={handleClose} />
        <ModalBody>
          <ElephantChat
            text={i18n.t('ReadinessSurveyModal.elephantChat', 'How are you feeling about making your plan official?')}
          />
          <SelectLabel style={{ marginTop: 8, display: 'block' }}>
            {i18n.t('ReadinessSurveyModal.choose', 'Choose the answer that best describes you.')}
          </SelectLabel>
          {OPTIONS?.map(({ label, id, isOther }) => (
            <TextOption
              id={!isOther ? id : 'other'}
              key={id}
              onChange={(newValue) => {
                setOtherValue(newValue?.other)
                if (isOther && id !== value) {
                  setValue(id)
                }
              }}
              onClick={() => {
                if (id !== value) {
                  setValue(id)
                  setOtherValue(undefined)
                } else {
                  setValue(undefined)
                  setOtherValue(undefined)
                }
              }}
              active={value === id}
              item={{ id, label }}
              disabled={false}
              otherFieldName={isOther ? 'other' : undefined}
              isOther={isOther}
              isOtherNewLine={isOther ? true : false}
              otherFieldValue={id === value && otherValue ? otherValue : ''}
            />
          ))}
        </ModalBody>
        <ButtonArea>
          <Button disabled={submittingReadiness} grey style={{ marginRight: 16 }} onClick={handleClose}>
            {i18n.t('button.close', 'Close')}
          </Button>
          <Button disabled={submittingReadiness} loading={submittingReadiness} onClick={handleSubmit}>
            {i18n.t('button.submit', 'Submit')}
          </Button>
        </ButtonArea>
      </ReadinessModal>
    </ModalOverlay>
  )
}
