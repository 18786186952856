import { GuideQuestionIdEnum } from 'generated/graphql'
// import { TFunctionDetailedResult } from 'i18next'
import { QuestionItem, QuestionItemOption, PersonaQuestionOption } from 'lib/types/QuestionItem'

interface GetAnswerTextFromValueOpts {
  questions: QuestionItem[]
  /** the name of the field you're looking to get a label from */
  fieldName: GuideQuestionIdEnum
  /** this is the value you are trying to find the text label for */
  value: string | number
}
// TFunctionDetailedResult<object>
export default function getAnswerTextFromValue({
  questions,
  fieldName,
  value,
}: GetAnswerTextFromValueOpts): string | undefined | JSX.Element | any {
  const question: QuestionItem | undefined = questions.find((item) => item.fieldName === fieldName)
  const options: QuestionItemOption[] | PersonaQuestionOption[] | any[] = question?.options || []
  if (options.length === 0) return undefined
  console.log({ questions, fieldName, value, options })
  let option: QuestionItemOption | PersonaQuestionOption = options?.find((item) => {
    if (item?.value) return item?.value === value
    return item.id === value
  })
  return option?.label
}
