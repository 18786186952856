import apolloClient from 'ApolloClient/index'
import {
  CurrentUserDocument,
  CurrentUserQuery,
  GetOnePatientDocument,
  GetOnePatientQueryVariables,
  Patient,
} from 'generated/graphql'
import { ApolloQueryResult } from '@apollo/client'

export default async function getClinicId() {
  try {
    const result: ApolloQueryResult<CurrentUserQuery> = await apolloClient.query({
      query: CurrentUserDocument,
    })

    const user = result?.data?.currentUser

    // if the current user has a clinic ID, use that
    if (result?.data?.currentUser?.clinic?.id) {
      return result?.data?.currentUser?.clinic?.id
    }

    // otherwise, continue to trying to find the clinic ID other ways

    if (!user?.id) return null
    const getPatientVariables: GetOnePatientQueryVariables = {
      userId: user?.id,
    }
    const patientQuery: ApolloQueryResult<Patient> = await apolloClient.query({
      query: GetOnePatientDocument,
      variables: getPatientVariables,
    })
    return patientQuery?.data?.clinicId
  } catch (err) {
    return null
  }
}
