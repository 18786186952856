import { FC, ReactElement, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import Button from 'components/common/Button';
import Loading from 'components/common/Loading';
import SectionHeader from '../SectionHeader';
import SectionFooter from '../SectionFooter';
import SectionBlock from './SectionBlock';
import useSavePatient from 'lib/hooks/useSavePatient';
import { Patient } from 'generated/graphql';
import usePrevious from 'lib/hooks/usePrevious';
import { AfterSaveAction, Step1Continue } from '../step1';

const Step2Styled = styled.div`
  background-color: white;
  flex: 1;
  @media only screen and (max-width: ${({ theme }) =>
  theme.breakpoints.medium}) {
    padding-bottom: 100px;
  }
`;

const Step2Body = styled.div`
  margin: auto;
  max-width: 600px;
  padding: 48px 10px;
  @media only screen and (max-width: ${({ theme }) =>
  theme.breakpoints.medium}) {
    padding: 24px;
  }
`;

const Step2Title = styled.h1`
  margin-bottom: 32px;
  font-size: 18px;
`;

interface Step2Props {
  patient?: Patient | null;
};


const Step2: FC<Step2Props> = ({ patient }): ReactElement => {
  const history = useHistory();
  const { t } = useTranslation();

  const [afterSaveAction, setAfterSaveAction] = useState<AfterSaveAction>();
  const [knowWhatAdvanceCarePlanningIs, setKnowWhatAdvanceCarePlanningIs] = useState<number | null>(null);
  const [aboutWhoCanBeDecisionMaker, setAboutWhoCanBeDecisionMaker] = useState<number | null>(null);
  const [aboutWhatMakesGoodDecisionMaker, setAboutWhatMakesGoodDecisionMaker] = useState<number | null>(null);
  const [informedAboutFlexibilityDecisionMaker, setInformedAboutFlexibilityDecisionMaker] = useState<number | null>(null);

  const { onSavePatient, saving } = useSavePatient();

  const savingPrev = usePrevious(saving);

  const save = async () => {
    if (patient) {
      await onSavePatient({
        ...patient,
        knowWhatAdvanceCarePlanningIs,
        aboutWhoCanBeDecisionMaker,
        aboutWhatMakesGoodDecisionMaker,
        informedAboutFlexibilityDecisionMaker,
      });
    }
  };

  useEffect(() => {
    // set patient values every patient change
    if (patient) {
      setKnowWhatAdvanceCarePlanningIs(patient.knowWhatAdvanceCarePlanningIs as number);
      setAboutWhoCanBeDecisionMaker(patient.aboutWhoCanBeDecisionMaker as number);
      setAboutWhatMakesGoodDecisionMaker(patient.aboutWhatMakesGoodDecisionMaker as number);
      setInformedAboutFlexibilityDecisionMaker(patient.informedAboutFlexibilityDecisionMaker as number);
    }
  }, [patient]);

  const goNext = () => {
    setAfterSaveAction(AfterSaveAction.NEXT);
    save();
  };

  useEffect(() => {
    // Go to step 2 on saving end and if patient is truthy
    if (savingPrev && !saving && patient && afterSaveAction) {
      switch (afterSaveAction) {
        case AfterSaveAction.NEXT: {
          history.push('/app/survey?step=3');
          break;
        }
        case AfterSaveAction.HOME: {
          history.push('/app/home');
          break;
        }
        case AfterSaveAction.BACK: {
          history.push('/app/survey?step=1');
          break;
        }
        default: {
          return;
        }
      }
    }
  }, [
    history,
    savingPrev,
    saving,
    patient,
    afterSaveAction,
  ]);

  const disabled =
    !knowWhatAdvanceCarePlanningIs ||
    !aboutWhoCanBeDecisionMaker ||
    !aboutWhatMakesGoodDecisionMaker ||
    !informedAboutFlexibilityDecisionMaker;
  
  return (
    <>
      <Step2Styled>
        <SectionHeader
          sectionName={t("planConfigurator.PRE_SURVEY")}
          progress={"33"}
          onClick={() => {
            setAfterSaveAction(AfterSaveAction.HOME);
            save();
          }}
        />
        {saving && <Loading height={80} />}
        <Step2Body>
          <Step2Title>
            Advance Care Planning Knowledge Assessment
          </Step2Title>
          <SectionBlock
            title="6. Do you know what Advance Care Planning is?"
            subtitle="Please mark the circle that best describes you"
            value={knowWhatAdvanceCarePlanningIs}
            onSelect={(value: number) => setKnowWhatAdvanceCarePlanningIs(value)}
          />
          <SectionBlock
            title="7. How well informed are you about who can be a medical decision maker?"
            subtitle="Please mark the circle that best describes you"
            value={aboutWhoCanBeDecisionMaker}
            onSelect={(value: number) => setAboutWhoCanBeDecisionMaker(value)}
          />
          <SectionBlock
            title="8. How well informed are you about what makes a good medical decision maker?"
            subtitle="Please mark the circle that best describes you"
            value={aboutWhatMakesGoodDecisionMaker}
            onSelect={(value: number) => setAboutWhatMakesGoodDecisionMaker(value)}
          />
          <SectionBlock
            title="9. How well informed are you about the different amounts of
            flexibility a person can give their medical decision maker?"
            subtitle="Please mark the circle that best describes you"
            value={informedAboutFlexibilityDecisionMaker}
            onSelect={(value: number) => setInformedAboutFlexibilityDecisionMaker(value)}
          />
          {!disabled && (
            <Step1Continue>
              <Button
                loading={saving}
                disabled={saving}
                onClick={goNext}
                pulse
              >
                Continue
              </Button>
            </Step1Continue>
          )}
        </Step2Body>
      </Step2Styled>
      <SectionFooter
        goBack={() => {
          setAfterSaveAction(AfterSaveAction.BACK);
          save();
        }}
        goNext={goNext}
        loading={saving}
        disabled={disabled}
      />
    </>
  );
}

export default Step2;
