import message from 'components/common/message'
import client from 'ApolloClient/index'
import logout from 'ApolloClient/Mutations/logout'
import clearUsersLocalStorage from '../helpers/clearUsersLocalStorage'
import { CurrentUserDocument, CurrentUserFragmentFragment } from 'generated/graphql'
import { useHistory } from 'react-router-dom'
import translateApolloError from 'lib/helpers/translateApolloError'

/**
 *
 */
export default function useSignout() {
  const history = useHistory()

  const handlePostLogout = async (currentUser) => {
    // clear out the currentUser in the local cache
    await client.writeQuery({
      query: CurrentUserDocument,
      data: {
        currentUser: null,
      },
    })
    await client.clearStore() // clear out the entire local cache
    setTimeout(() => {
      message.success('You have been logged out successfully')
      history.push('/login')
    }, 400)

    return currentUser
  }
  const signOut = async (currentUser: CurrentUserFragmentFragment) => {
    try {
      // run logoout mutations
      await client.mutate({
        mutation: logout,
      })
      clearUsersLocalStorage()
      handlePostLogout(currentUser)
    } catch (err: any) {
      clearUsersLocalStorage()
      /** If there is an issue, remove their token and clear the storage anyway */
      handlePostLogout(currentUser)
      return console.log(translateApolloError(err))
    }
  }

  return [signOut]
}
