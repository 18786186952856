import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const Title = styled.h1`
  font-size: 24px;
  margin-bottom: 0px;
  color: ${(p) => p.theme.colors.neutral1};
`

export const Logo = styled.img`
  max-width: 75%;
  margin: auto;
  margin-bottom: 32px;
  display: block;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin-bottom: 16px;
  }
`

export const Caption = styled.h3`
  font-size: 18px;
  margin: 0px;
  color: ${(p) => p.theme.colors.neutral5};
`

export const HintLink = styled.span`
  font-family: ${(p) => p.theme.fontFamily};
  color: ${(p) => p.theme.colors.supportB3};
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  padding: 0px;
  margin: 0px;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0px;
  border: 0px;
  cursor: pointer;
  outline: 0;
  display: inline;
  background: transparent;
  &:hover {
    color: ${(p) => p.theme.colors.supportB3};
  }
`

export const BottomText = styled.label`
  margin: 0px;
  display: block;
  color: ${(p) => p.theme.colors.neutral1};
  font-size: 16px;
  margin-top: 8px;
  a {
    font-size: 16px;
  }
`

export const BottomBtn = styled(HintLink)`
  font-size: 16px;
  text-decoration: underline;
  &:hover {
    text-decoration: underline;
  }
`

export const BottomLink = styled(Link)`
  font-size: 16px;
  text-decoration: underline;
  &:hover {
    text-decoration: underline;
  }
`

export const Checkbox = styled.input``

// const HideButton = styled.button`
//   border: 0px;
//   background: transparent;
//   position: absolute;
//   right: 16px;
//   top: 36px;
//   color: ${(p) => p.theme.colors.supportB2};
//   cursor: pointer;
//   &:focus {
//     outline: 0;
//   }
// `;

export const UploadingOverlay = styled.div`
  z-index: 1000;
  background: rgba(0, 0, 0, 0.03);
  position: absolute;
  border-radius: 5px;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ButtonContainer = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: flex-start;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    div,
    button {
      width: 120px !important;
    }
  }
`
