import { BottomText, Caption, CaptionLink, Checkbox, PasswordText, Title, HeaderContainer, Logo } from '../styledSignup/signupFormV2.styled'
import Row from 'components/common/Row'
import Col from 'components/common/Col'
import i18n from 'lib/helpers/i18n'
import ErrorBlock from 'components/common/ErrorBlock'
import FormItem from 'components/common/FormItem'
import PasswordInput from 'components/inputs/PasswordInput'
import { useState } from 'react'
import useCheckPasswordErrors from 'lib/hooks/useCheckPasswordErrors'
import EmailDomainAdder from '../Helpers/emailDomainAdder'
import { Clinic } from 'generated/graphql'
import logo from 'lib/media/logo-light-bg-horizontal.svg'

interface SignupFormProps {
  user: any
  onBeforeChange: (e: { [x: string]: string | boolean }) => void
  onBlurInput: () => void
  handleUseAsGuest: () => void
  onChange: (e: { [x: string]: string | number | undefined }) => void
  onBeforeSubmit: (e: any) => void
  signupError: string
  passwordError?: string
  onErrorChange?: any
  errors?: any
  emailInput: any
  clinic?: Clinic
}

function Step2({
  user,
  onBlurInput,
  handleUseAsGuest,
  onChange,
  signupError,
  onBeforeSubmit,
  onErrorChange,
  errors,
  emailInput,
  clinic,
}: SignupFormProps) {
  const { checkPasswordErrors } = useCheckPasswordErrors()
  const [showPassword, setShowPassword] = useState(false)

  const onPasswordChange = (password) => {
    onChange({ password })
    onErrorChange({})
    const error = checkPasswordErrors(password)
    if (error) {
      onErrorChange({ password: error })
    } else {
      onErrorChange({ password: undefined })
    }
  }

  const handleShowCreatePasswordNow = ({ selectedCreatePassword }) => {
    // onBeforeChange(e)
    // if they do not want to make a password, make sure the password field/input/value is undefined
    if (!selectedCreatePassword) {
      onChange({ password: undefined, selectedCreatePassword })
      onErrorChange({ password: undefined })
    } else {
      onChange({
        selectedCreatePassword,
      })
    }
  }

  return (
    <>
    <HeaderContainer>
        <Logo src={clinic?.logo || logo} alt="Koda Health" width="320" />
        </HeaderContainer>
      <Title>{i18n.t('signupPage.title', 'Create your account')}</Title>
      <Caption>
        {i18n.t('signUpPage.enterYourEmailCreateAccount', 'Enter your email to create an account.')}
      </Caption>
      <form onSubmit={onBeforeSubmit}>
        <Row gutter={16}>
          <Col xs={24}>
            <EmailDomainAdder
              email={user?.email}
              onBlurInput={onBlurInput}
              handleUseAsGuest={handleUseAsGuest}
              onChange={(email) => {
                // could do validation here if you wanted to and store the errors in Step2 or store the in SignupFormV2
                onChange({ email })
              }}
              onBeforeSubmit={onBeforeSubmit}
              error={errors?.email}
              emailInput={emailInput}
            />
          </Col>
        </Row>
        <FormItem>
          <PasswordText>
            <Checkbox
              type="checkbox"
              style={{ marginRight: 8 }}
              checked={!user?.selectedCreatePassword}
              data-testid="signup-create-password-later-checkbox"
              onChange={() => handleShowCreatePasswordNow({ selectedCreatePassword: false })}
              id="sendPasswordLater"
            />
              {i18n.t('signupPage.createPasswordLater', 'Send me an email so I can create my password later.')}
          </PasswordText>
        </FormItem>
        <Col xs={24}>
          <FormItem>
            <PasswordText>
              <Checkbox
                style={{ marginRight: 8 }}
                type="checkbox"
                data-testid="signup-create-password-now-checkbox"
                checked={user?.selectedCreatePassword}
                onChange={() => handleShowCreatePasswordNow({ selectedCreatePassword: true })}
                id="createPasswordNow"
              />
              {i18n.t('signupPage.createPasswordNow', 'I want to create my password now.')}
            </PasswordText>
          </FormItem>
          {user?.selectedCreatePassword && (
            <>
              <FormItem label={i18n.t('signupPage.password', 'Password')} required error={errors.password} htmlFor="createPasswordNow">
                <PasswordInput
                  id="password"
                  value={user.password}
                  onChange={(password) => onPasswordChange(password)}
                  onBlur={() => onBlurInput()}
                  type={showPassword ? 'text' : 'password'}
                  onToggleShowPassword={() => setShowPassword(!showPassword)}
                  showPassword={showPassword}
                  placeholder=""
                  data-testid="signup-password"
                />
              </FormItem>{' '}
            </>
          )}
        </Col>
        {signupError && <ErrorBlock error={signupError} />}
      </form>
    </>
  )
}

export default Step2