import { AdPartEnum, PlanSetting } from '../types'
import en from 'lib/helpers/locales/en/translation.json'
import { Text } from '@react-pdf/renderer'
import s from '../../../KodaContent/styles'
import { Trans } from 'react-i18next'

export default function getAZ(t, defaultPartSettings, patient): PlanSetting {
  const patientFullName = `${patient?.firstName || ''} ${patient?.lastName || ''}`

  const az: PlanSetting = {
    state: 'Arizona',
    disclaimer: t(
      'AZ.disclaimer',
      'Disclaimer: This document needs to be signed and have at least one witness sign as well or be notarized in order to be a legal document.'
    ),
    parts: [
      // Part 1
      {
        ...defaultPartSettings?.[AdPartEnum.health_care_choices],
      },
      // Part 2
      {
        ...defaultPartSettings?.[AdPartEnum.medical_decision_maker],
        pageTitle: t('AZ.medical_decision_maker.pageTitle', en.AZ.medical_decision_maker.pageTitle),
        description: t(
          'AZ.medical_decision_maker.description',
          'This part is also known as a Health Care Power of Attorney. It designates a person to make decisions for you when you are unable to speak for yourself.'
        ),
      },
      // Part 3
      {
        ...defaultPartSettings?.[AdPartEnum.sign_the_form],
        description: t(
          'AZ.sign_the_form.description',
          'You can fill out Part 1, Part 2, or both, but you need to sign the form in Part 3. To be legal, at least one witness also needs to sign or a notary.'
        ),
        beforeYouSign: {
          requirements: [
            t(
              'planDoc.sign_the_form.beforeYouSignRequirements_age',
              en.planDoc.sign_the_form.beforeYouSignRequirements_age
            ),
            t(
              'planDoc.sign_the_form.beforeYouSignRequirements_notary',
              en.planDoc.sign_the_form.beforeYouSignRequirements_notary
            ),
            t(
              'planDoc.sign_the_form.beforeYouSignRequirements_signInPresenceOfOneWitness',
              en.planDoc.sign_the_form.beforeYouSignRequirements_signInPresenceOfOneWitness
            ),
          ],
          finalClause: t(
            'AZ.sign_the_form.beforeYouSignFinalClause',
            'By signing, I confirm that I am emotionally and mentally capable of making this document. I have made this Advance Directive willingly, I understand the purpose and effect of this document, and this document accurately expresses my wishes.'
          ),
        },
        witnessSignatures: {
          preSignaturesContent: (
            <Text style={{ ...s.text, fontSize: 11 }}>
              <Trans i18nKey="AZ.sign_the_form.witnessSignatures.preSignaturesContent">
                By signing, I confirm that I watched {{ patientFullName: patientFullName || '____________________ ' }}{' '}
                sign the document. I confirm that I know{' '}
                {{ patientFullName: patientFullName || '____________________ ' }}.{' '}
                {{ patientFullName: patientFullName || '____________________ ' }} appears to be emotionally competent,
                of sound mind, and under no duress, fraud, or undue influence in signing the document. I promise that I
                meet the witness requirements listed in the document.
              </Trans>
            </Text>
          ),
          //
        },
        witnessRequirements: {
          title: t('planDoc.sign_the_form.witnessRequirements_title', 'Witnesses or Notary Requirements'), // OR "Witness and Notary Requirements"
          requirements: [
            {
              title: t(
                'planDoc.sign_the_form.witnessRequirements_bothWitnesses',
                en.planDoc.sign_the_form.witnessRequirements_bothWitnesses
              ),
              width: '35%',
              phrases: [
                t('planDoc.sign_the_form.witnessRequirements_be18', en.planDoc.sign_the_form.witnessRequirements_be18),
                t(
                  'planDoc.sign_the_form.witnessRequirements_seeYouSign',
                  en.planDoc.sign_the_form.witnessRequirements_seeYouSign
                ),
              ],
            },
            {
              title: t(
                'planDoc.sign_the_form.witnessRequirements_ifOnePersonWitnessCannot',
                'If witnessed by one person only, that witness cannot:'
              ),
              width: '65%',
              phrases: [
                t(
                  'planDoc.sign_the_form.witnessRequirements_beRelatedBloodMarriageAdoption',
                  'be related to you by blood, marriage, or adoption'
                ),
                t(
                  'planDoc.sign_the_form.witnessRequirements_beAPersonEntitledToEstate',
                  'be a person entitled to any portion of your estate upon your death'
                ),
              ],
            },
            {
              title: t(
                'planDoc.sign_the_form.witnessRequirements_yourWitnessCannot',
                en.planDoc.sign_the_form.witnessRequirements_yourWitnessCannot
              ),
              width: '100%',
              phrases: [
                t(
                  'planDoc.sign_the_form.witnessRequirements_beYourMdm',
                  en.planDoc.sign_the_form.witnessRequirements_beYourMdm
                ),
                // be a person directly involved with your health care at the time
                t(
                  'planDoc.sign_the_form.witnessRequirements_beDirectlyInvolvedWithHealthcare',
                  en.planDoc.sign_the_form.witnessRequirements_beDirectlyInvolvedWithHealthcare
                ),
              ],
            },
          ],
          validityRequirements: [
            t(
              'planDoc.sign_the_form.validityRequirements_esignInThePresence',
              en.planDoc.sign_the_form.validityRequirements_esignInThePresence
            ),
            t(
              'planDoc.sign_the_form.validityRequirements_prinatAndSignBeforeOneWitnessOr',
              en.planDoc.sign_the_form.validityRequirements_prinatAndSignBeforeOneWitnessOr
            ),
            t(
              'planDoc.sign_the_form.validityRequirements_prinatAndSignBeforeNotary',
              en.planDoc.sign_the_form.validityRequirements_prinatAndSignBeforeNotary
            ),
          ],
        },
        notary: {},
      },
    ],
  }

  return az
}
