// TOP LEVEL IMPORTS
import React from 'react';
import styled from 'styled-components';

const TextArea = styled.textarea`
  background: ${(p) => {
    if (p.disabled) {
      return '#fff';
    }
    return p.theme.colors.neutral11;
  }};
  color: ${(p) => {
    if (p.disabled) {
      return p.theme.colors.neutral5;
    }
    return p.theme.colors.neutral1;
  }};
  border: 0px;
  width: 100%;
  padding-left: 8px;
  border-radius: 5px;
  border: 2px solid ${(p) => p.theme.colors.neutral9} !important;
  &:focus {
    outline: 0;
    background: #fff;
    border: 2px solid ${(p) => p.theme.colors.primary7} !important;
  }
  &:disabled {
    cursor: not-allowed;
  }
`;

// EXPORTED COMPONENT
// ===================================

const TextAreaInput = (props) => {
  return (
    <TextArea {...props} onChange={(e) => props.onChange(e.target.value)} />
  );
};

// Specifies the default values for props:
TextAreaInput.defaultProps = {
  onChange: () => console.log('no onchange passed to TextAreaInput'),
};

// EXPORT
// ===================================
export default TextAreaInput;
