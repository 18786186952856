import { AdPartEnum, PlanSetting } from '../types'
import en from 'lib/helpers/locales/en/translation.json'

export default function getMD(t, defaultPartSettings): PlanSetting {
  const md: PlanSetting = {
    state: 'Maryland',
    disclaimer: 'Disclaimer: This document needs to be signed and have two witnesses sign as well.',
    parts: [
      // Part 1
      {
        ...defaultPartSettings?.[AdPartEnum.health_care_choices],
        description:
          'This part is also known as a Living Will. It expresses the kind of treatment you would want in a medical crisis.',
      },
      // Part 2
      {
        ...defaultPartSettings?.[AdPartEnum.medical_decision_maker],
        pageTitle: 'Information for Medical Decision Maker (Health Care Agent)',
        description:
          'This part is also known as Selection of Health Care Agent. It designates a person to make decisions for you when you are unable to speak for yourself.',
      },
      // Part 3
      {
        ...defaultPartSettings?.[AdPartEnum.sign_the_form],

        description:
          'You can fill out Part 1, Part 2, or both, but you need to sign the form in Part 3. To be legal, two witnesses also need to sign.',
        beforeYouSign: {
          requirements: [
            t(
              'planDoc.sign_the_form.beforeYouSignRequirements_age',
              en.planDoc.sign_the_form.beforeYouSignRequirements_age
            ),
            'sign in the presence of two competent adult witnesses',
          ],
          finalClause: 'This Advanced Directive accurately expresses my wishes.',
        },
        witnessRequirements: {
          title: 'Witness Requirements', // OR "Witness and Notary Requirements"
          requirements: [
            {
              title: 'Your witnesses must:',
              phrases: [
                'be 18 years of age or older',
                'see you sign the form',
                'be physically present with you when you sign',
              ],
            },
            {
              title: 'Witnesses cannot:',
              phrases: ['be your medical decision maker (health care agent)'],
            },
            {
              title: 'At least one witness cannot:',
              phrases: [
                `be the person named to handle the estate after the declarant's death or intended to receive money or property from the declarant's estate`,
              ],
            },
          ],
          validityRequirements: ['print and sign the document in the presence of two competent adult witnesses'],
        },
      },
    ],
  }

  return md
}
