import styled from 'styled-components';
import { CloseCircleOutlined } from '@ant-design/icons';

const ErrorBlockContainer = styled.div`
  background: ${(p) => p.theme.colors.red10};
  border-left: 4px solid ${(p) => p.theme.colors.red4};
  padding: 8px;
  border-radius: 5px;
  color: ${(p) => p.theme.colors.red2};
  font-size: 13px;
  margin-bottom: 16px;
  display: flex;
`;

interface ErrorBlockProps {
  errors?: string[];
  error?: string;
  style?: React.CSSProperties;
}

const ErrorBlock = ({ errors = [], error, style }: ErrorBlockProps) => (
  <ErrorBlockContainer data-testid="error-block" style={style}>
    <CloseCircleOutlined style={{ marginRight: 4, fontSize: 18 }} />
    {errors && errors.map((item) => item)}
    <span data-testid="error-block__text">
      {error && error}
    </span>
  </ErrorBlockContainer>
);

export default ErrorBlock;
