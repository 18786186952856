import { Image, Text, View } from '@react-pdf/renderer'
import s from 'components/common/PlanDoc/KodaContent/styles'
import theme from 'lib/theme'

import checkPNG from 'components/common/PlanDoc/imgs/check.png'

interface CheckboxProps {
  children?: string | JSX.Element
  checked?: boolean
  label?: string
  checkboxStyle?: object
}

export default function Checkbox({ children, checked, label, checkboxStyle }: CheckboxProps) {
  const CHECK_HEIGHT = 12
  const CHECK_STYLE = {
    marginRight: 3,
    borderRadius: 2,
    borderWidth: 1,
    height: CHECK_HEIGHT,
    width: CHECK_HEIGHT,
    borderColor: theme.colors.neutral2,
    marginTop: 1,
    ...checkboxStyle,
  }

  const CHECK_IMAGE_HEIGHT = CHECK_HEIGHT * 0.85

  return (
    <View
      style={{
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'row',
        justifyContent: 'flex-start',
      }}
    >
      <View
        style={{
          width: CHECK_HEIGHT * 1.25,
          display: 'flex',
          alignItems: 'flex-start',
          flexDirection: 'row',
          justifyContent: 'flex-start',
        }}
      >
        {!checked ? (
          <View style={CHECK_STYLE} />
        ) : (
          <View
            style={{
              ...CHECK_STYLE,
              backgroundColor: theme.colors.primary10,
            }}
          >
            <Image src={checkPNG} style={{ width: CHECK_IMAGE_HEIGHT, height: CHECK_IMAGE_HEIGHT }} />
          </View>
        )}
      </View>
      <View>
        {children}
        {label && <Text style={s.formLabel}>{label}</Text>}
      </View>
    </View>
  )
}
