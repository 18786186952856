import styled from 'styled-components';

interface DropdownStyleWrapperProps {
  disabled?: boolean;
}

export default styled.div<DropdownStyleWrapperProps>`
  .ant-select {
    height: 50px;
    background: ${(p) => {
    if (p.disabled) {
      return '#fff';
    }
    return p.theme.colors.neutral11;
  }};
    border: 2px solid ${(p) => p.theme.colors.neutral9} !important;
  }

  .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
    .ant-select-selector {
    height: 46px;
    border: 0px;
    background: ${(p) => {
    if (p.disabled) {
      return '#fff';
    }
    return p.theme.colors.neutral11;
  }};
  }
  .ant-select-selection-item {
    line-height: 46px !important;
  }
  .ant-select-selection__rendered {
    height: 43px;
    align-items: center;
    display: flex;
    background: ${(p) => p.theme.colors.neutral11};
  }
  .ant-select-selection:focus,
  .ant-select-selection:active {
    border-color: ${(p) => p.theme.colors.primary7} !important;
  }
`;
