import React from 'react';
import { Title, Caption, TitleIcon, Content, TitleContainer } from '../index';
import personQuestionSVG from '../images/person-question.svg';
import Collapse from 'components/common/Collapse';
// LIB
import i18n from 'lib/helpers/i18n';

const { Panel } = Collapse;

export default function Step1() {
  return (
    <div>
      <TitleIcon
        src={personQuestionSVG}
        alt="First Onboarding Step"
        style={{ margin: 'auto', display: 'block' }}
      />
      <TitleContainer style={{ justifyContent: 'center' }}>
        <Title data-testid="step1-title">
          {i18n.t("medicalCarePlansFaq.kodaAdvanceMedical")}
        </Title>
      </TitleContainer>
      <Caption>
        {i18n.t("medicalCarePlansFaq.healthCarePlans")}
      </Caption>
      <Caption style={{ marginTop: 24 }}>
        {i18n.t("medicalCarePlansFaq.frequentlyAsked")}
      </Caption>
      <Content>
        <Collapse expandIconPosition="right">
          <Panel header={i18n.t("medicalCarePlansFaq.whatIsdvanceCare")} key="1">
            <div>
              {i18n.t("medicalCarePlansFaq.whatIsdvanceCareText")}
            </div>
          </Panel>
          <Panel header={i18n.t("medicalCarePlansFaq.financialPlanning")} key="2">
            <div>
              {i18n.t("medicalCarePlansFaq.financialPlanningText")}
            </div>
          </Panel>
          <Panel
            header={i18n.t("medicalCarePlansFaq.withMyLawyer")}
            key="3"
          >
            <div>
              {i18n.t("medicalCarePlansFaq.withMyLawyerText")}
            </div>
          </Panel>
          <Panel
            header={i18n.t("medicalCarePlansFaq.advanceDirective")}
            key="4"
          >
            <div>
              {i18n.t("medicalCarePlansFaq.advanceDirectiveText")}
            </div>
          </Panel>
        </Collapse>
      </Content>
    </div>
  );
}
