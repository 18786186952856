import { ModalOverlay } from 'components/common/KodaModal'
import styled from 'styled-components'
import Button from 'components/common/Button'
import ExitButton from 'components/common/ExitButton'
import React from 'react'
import i18n from 'lib/helpers/i18n'

const Modal = styled.div`
  width: 450px;
  background: #fff;
  min-height: 400px;
  max-width: 100%;
  border-radius: 5px;
  position: relative;
  max-height: 100vh;
  overflow-y: scroll;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.mediumSmall}) {
    max-width: 100%;
    position: fixed;
    top: 8px;
    bottom: 8px;
    right: 8px;
    left: 8px;
    width: inherit;
  }
`

const ButtonArea = styled.div`
  background: ${({ theme }) => theme.colors.neutral11};
  height: 10%;
  position: absolute;
  bottom: 0px;
  right: 0px;
  left: 0px;
  height: 80px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.mediumSmall}) {
    position: fixed;
    padding: 8px;
    bottom: 0px;
    right: 0px;
    left: 0px;
    margin: 8px;
    z-index: 999;
    /* justify-content: flex-end; */
  }
`

const Content = styled.div`
  max-height: 90vh;
  overflow-y: scroll;
  padding-top: 40px;
  z-index: 1;
`

const Padding = styled.div`
  padding: 32px 32px 112px 32px;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    padding: 16px 16px 112px 16px;
  }
`

interface ContentModalProps {
  visible: boolean
  content: string | JSX.Element
  onClose: () => void
  onDownloadClick?: () => void
  contentStyle?: React.CSSProperties
}

export default function ContentModal({ visible, content, onClose, onDownloadClick, contentStyle }: ContentModalProps) {
  if (!visible) return null

  return (
    <ModalOverlay onClick={onClose}>
      <Modal>
        <ExitButton onClick={onClose} />
        <Content style={contentStyle}>
          <Padding>{content}</Padding>
        </Content>
        <ButtonArea>
          {onDownloadClick && (
            <Button secondary onClick={onDownloadClick} style={{ width: 140, marginRight: 16 }}>
              {i18n.t('SignatureFormContentModal.download', 'Download')}
            </Button>
          )}

          <Button onClick={onClose} style={{ width: 140 }}>
            {i18n.t('SignatureFormContentModal.close', 'Close')}
          </Button>
        </ButtonArea>
      </Modal>
    </ModalOverlay>
  )
}
