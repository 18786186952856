import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const Header = styled.div`
  position: absolute;
  top: 0px;
  height: 64px;
  right: 0px;
  left: 0px;
  background: #fff;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const LinkContainer = styled.div`
margin-top: 35px;
`

export const BottomLink = styled(Link)`
font-size: 16px;
text-decoration: underline;
color: ${(p) => p.theme.colors.primary2};
&:hover {
  text-decoration: underline;
}
`

export const HintLink = styled.span`
  font-family: ${(p) => p.theme.fontFamily};
  color: ${(p) => p.theme.colors.supportB3};
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  padding: 0px;
  margin: 0px;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0px;
  border: 0px;
  cursor: pointer;
  outline: 0;
  background: transparent;
  &:hover {
    color: ${(p) => p.theme.colors.supportB3};
  }
`

export const BottomBtn = styled(HintLink)`
  font-size: 16px;
  text-decoration: underline;
`

export const BottomText = styled.label`
  display: block;
  color: ${(p) => p.theme.colors.neutral1};
  color: #1F2933;
  font-size: 16px;
  margin-top: 8px
  a {
    font-size: 16px;
  }
`