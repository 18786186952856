import { useHistory } from 'react-router-dom'
import { useState } from 'react'
// COMPONENTS
import Button from 'components/common/Button'
import Collapse from 'components/common/Collapse'
import esignatureSVG from '../eSignature.svg'
import { ActionCard, Image, Text } from '../index'
import ReadyToSignModal from 'components/common/ReadyToSignModal'
import WarningPopover from 'components/common/WarningPopover'
import theme from 'lib/theme'
import { PatientFragmentFragment, UserProfile } from 'generated/graphql'
import { getEsignFAQs } from 'routes/app-home/ESignAdditionalInformation'
import i18n from 'lib/helpers/i18n'

const { Panel } = Collapse

interface ESignatureCardProps {
  hasOfficialPlan: boolean
  currentUser: UserProfile
  patient: PatientFragmentFragment
}

export default function ESignatureCard({ hasOfficialPlan, currentUser, patient }: ESignatureCardProps) {
  const [showMore, setShowMore] = useState<boolean>(false)
  const [showReadyToSign, setShowReadyToSign] = useState<boolean>(false)
  const history = useHistory()
  const ESIGN_FAQS = getEsignFAQs()
  return (
    <>
      <ReadyToSignModal
        visible={showReadyToSign}
        currentUser={currentUser}
        patient={patient}
        onCancel={() => {
          setShowReadyToSign(false)
        }}
        onReady={() => history.push(`/app/esign`)}
        onCloseText={i18n.t('button.close', 'Close')}
        showExtraText={false}
      />

      <ActionCard
        icon={<Image src={esignatureSVG} alt="eSignature" />}
        title={i18n.t('ESignatureCard.eSignature', 'eSignature')}
        text={
          <>
            <Text>
              {i18n.t(
                'ESignatureCard.title',
                `Our in-app feature allows you to electronically sign your Care Plan in the presence of two witnesses. It's
              an easy and free way to make your Care Plan an official document.`
              )}
            </Text>
          </>
        }
        expanded={showMore}
        expandedContent={
          <>
            <Collapse defaultActiveKey={[ESIGN_FAQS?.[0]?.header]} expandIconPosition="right">
              {ESIGN_FAQS?.map((item, index) => {
                return (
                  <Panel header={item.header} key={item.header + index}>
                    <Text>{item.content}</Text>
                  </Panel>
                )
              })}
            </Collapse>
          </>
        }
        actions={[
          <Button grey key="1" style={{ marginRight: 8 }} onClick={() => setShowMore((currentState) => !currentState)}>
            {!showMore
              ? i18n.t('ESignatureCard.learnMore', 'Learn More')
              : i18n.t('ESignatureCard.seeLess', 'See Less')}
          </Button>,
          <div key="Start eSigning" style={{ display: 'inline-block' }}>
            {!hasOfficialPlan && (
              <Button
                key="1"
                onClick={() => setShowReadyToSign((currentState) => !currentState)}
                disabled={hasOfficialPlan}
                data-testid="start-esigning-button"
              >
                {i18n.t('ESignAdditionalInformation.startESigning', 'Start eSigning')}
              </Button>
            )}
            {hasOfficialPlan && (
              <button
                onClick={() => setShowReadyToSign(true)}
                disabled={hasOfficialPlan}
                style={{
                  border: 0,
                  background: theme.colors.neutral8,
                  color: theme.colors.neutral5,
                  paddingRight: 16,
                  paddingLeft: 16,
                  borderRadius: 5,
                  height: 56,
                  fontSize: 18,
                  fontWeight: 400,
                }}
              >
                <WarningPopover
                  title={i18n.t('ESignatureCard.warningTitle', 'You have a signed plan on file')}
                  description={i18n.t(
                    'ESignatureCard.warningDesription',
                    'If you edit your plan, you will need to re-sign.'
                  )}
                  showPopover={hasOfficialPlan}
                >
                  {i18n.t('ESignAdditionalInformation.startESigning', 'Start eSigning')}
                </WarningPopover>
              </button>
            )}
          </div>,
        ]}
      />
    </>
  )
}
