// TOP LEVEL IMPORTS
import { gql } from '@apollo/client'
import userFragment from './userFragment'

export default gql`
  fragment currentUserFragment on UserProfile {
    ...userFragment
    isGuest
    hasNetScore
    lang
    patient {
      actionHistory {
        name
        value
      }
    }
    clinic {
      id
      slug
      title
      logo
    }
  }
  ${userFragment}
`
