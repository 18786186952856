import { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import i18n from 'lib/helpers/i18n';
import { validate } from 'email-validator';
import ButtonsContainer from '../ButtonsContainer';
import QuestionText from 'components/text/QuestionText';
import { ErrorText, QuestionsContainer, StepContainer } from '../index';
import Animated from 'components/common/Animated';
import Button from 'components/common/Button';
import { Patient } from 'generated/graphql';
import { useTranslation } from 'react-i18next';
import Directions from 'components/text/Directions';

const TextArea = styled.textarea<{ ref: any }>`
  border: 0px;
  font-family: ${(p) => p.theme.fontFamily};
  font-size: 30px;
  font-weight: 400;
  letter-spacing: 1px;
  border: 1px solid ${(p) => p.theme.colors.neutral9};
  width: 100%;
  ::placeholder {
    color: ${(p) => p.theme.colors.neutral8};
  }
  @media only screen and (max-width: 1024px) {
    ::placeholder {
      font-size: 22px;
    }
  }
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints.small}) {
    font-size: 24px;
    ::placeholder {
      font-size: 13px;
      color: ${(p) => p.theme.colors.neutral7};
    }
  }
  /* &:focus {
    outline: 0;
    border-bottom: 1px solid transparent;
  }
  @media only screen and (max-width: 1024px) {
    &:focus {
      border-bottom: 3px solid ${(p) => p.theme.colors.primary4};
    }
  } */
`;

interface TextAreaInputProps {
  text?: JSX.Element | string;
  /**is this question required it be answerd before they move to the next question? */
  required?: boolean;
  defaultValue?: string;
  onNext: (plan?: Patient) => void;
  onBack: () => void;
  nextButton?: string;
  fieldName?: string;
  onChange: Function;
  placeholder?: string;
  inputType?: 'text' | string;
  showSkipButton?: boolean;
  hideButtons?: boolean; // true/false... hides the buttons
  editMode?: boolean; // true/false... the input may act differently if we're editing VS when it's being used inside the survey first time (one example: we autofocus the input in the survey but don't want that when editing our answers later in the app)
  isMobile?: boolean;
  directions?: string | JSX.Element | undefined;
}

export default function TextAreaInput({
  text, // the text for the actual question
  required, // is this question required it be answerd before they move to the next question?
  defaultValue = '',
  onNext,
  onBack,
  nextButton, // true/false => should the button be displayed/visible?
  fieldName, // the name of the DB value
  onChange,
  directions,
  placeholder = i18n.t('typeHere'),
  inputType = 'text',
  showSkipButton,
  hideButtons, // true/false... hides the buttons
  editMode, // true/false... the input may act differently if we're editing VS when it's being used inside the survey first time (one example: we autofocus the input in the survey but don't want that when editing our answers later in the app)
  isMobile, // true/false... input may have different functionality or style if it's on mobile
}: TextAreaInputProps) {
  const textInput = useRef<HTMLInputElement | undefined>();
  const { t } = useTranslation();

  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if ((!defaultValue && !editMode) || (defaultValue === '' && !editMode)) {
      textInput?.current?.focus();
    }
  }, [fieldName, defaultValue, editMode]);

  const hideNext = required && !defaultValue;

  const handleOnNext = () => {
    // just in case, don't let them submit if the field is required and not filled in
    if (required && !defaultValue) {
      return setError('This field is required');
    }
    // if the type of input is email, then we need to validate that it's an email
    if (
      inputType &&
      inputType === 'email' &&
      defaultValue &&
      defaultValue.length > 0
    ) {
      if (!validate(defaultValue)) {
        return setError('Must be a valid email');
      }
    }
    // if all is good, we can call onNext to move to the next question
    onNext();
  };

  return (
    <StepContainer key={fieldName} editMode={editMode} isMobile={isMobile}>
      <div style={{ maxWidth: '100%', position: 'relative' }}>
        <Animated>
          {!hideNext && !isMobile && (
            <div
              style={{
                position: 'absolute',
                right: 0,
                top: -80,
              }}
            >
              <Button
                onClick={handleOnNext}
                pulse
                style={{
                  minWidth: 112,
                }}
              >
                {t('button.continue')}
              </Button>
            </div>
          )}
          <QuestionText data-testid="textArea-question-text">
            {text}
          </QuestionText>
          {directions && <Directions>{directions}</Directions>}
          <QuestionsContainer
            style={{ width: !isMobile ? 620 : 400, maxWidth: '100%' }}
          >
            <TextArea
              data-testid="textArea-input-text"
              placeholder={placeholder || 'Enter text here'}
              value={defaultValue || ''}
              className="fs-exclude"
              ref={textInput}
              // inputMode={inputMode}
              onChange={(e) => {
                if (error) {
                  setError(null);
                }
                // for all other inputs, just save the values
                onChange({ [fieldName as string]: e.target.value });
              }}
            />
            {error && <ErrorText>{error}</ErrorText>}
          </QuestionsContainer>
        </Animated>
        <ButtonsContainer
          onNext={handleOnNext}
          onBack={onBack}
          hideButtons={hideButtons}
          nextButton={nextButton}
          hideNext={hideNext}
          showSkipButton={showSkipButton}
        />
      </div>
    </StepContainer>
  );
}
