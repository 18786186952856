import styled from "styled-components";
import checkSVG from "../check.svg";

const TextOptionContainer = styled.div<{ active: boolean }>`
  height: 100%;
  background: ${(p) =>
    p.active ? p.theme.colors.primary9 : p.theme.colors.neutral10};
  border: 1px solid
    ${(p) => (p.active ? p.theme.colors.primary7 : p.theme.colors.neutral9)};
  margin-top: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: color 0.3s ease, background-color 0.3s ease,
    border-color 0.3s ease, width 0.3s ease, opacity 0.3s ease;
  &:hover {
    background: ${(p) =>
      p.active ? p.theme.colors.primary9 : p.theme.colors.neutral9};
    border: 1px solid
      ${(p) => (p.active ? p.theme.colors.primary6 : p.theme.colors.neutral7)};
  }
`;

const SelectionContainer = styled.div`
  border-radius: 3px;
  width: 24px;
  height: 24px;
  display: flex;
  margin-right: 8px;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  align-items: center;
  justify-content: center;
  background: #fff;
  border: 1px solid ${(p) => p.theme.colors.neutral8};
`;

const Label = styled.div`
  max-width: 100%;
  font-weight: unset;
  font-size: 20px;
  line-height: 145%;
  margin: 0px;
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints.medium}) {
    font-size: 18px;
    line-height: 125%;
  }
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints.small}) {
    font-size: 16px;
    line-height: 125%;
  }
`;

const LabelContainer = styled.div`
  flex-grow: 1;
  width: 100%;
  min-width: 75px;
  min-height: 40px;
  display: flex;
  align-items: center;
  padding: 4px 12px 4px 0px;
`;

const Col = styled.div`
  display: flex;
  align-self: flex-start;
  box-shadow: transparent 0px 0px 0px 1px;
  margin: 8px;
  margin-right: 32px;
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints.small}) {
    margin-right: 0px;
  }
`;

const Row = styled.div`
  display: inline-flex;
  align-items: center;
  width: 100%;
`;

const Icon = styled.img<{ rel: string }>`
  margin: auto;
  height: 75px;
  max-width: 100%;
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints.small}) {
    height: 70px;
  }
`;

const IconContainer = styled.div`
  width: 80px;
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints.small}) {
    display: none;
  }
`;

interface IconBlockProps {
  item: {
    id: string;
    label: string;
    img: string;
  };
  disabled?: boolean;
  active: boolean;
  onClick: Function;
}

export default function IconBlock({
  item,
  disabled,
  active,
  onClick,
}: IconBlockProps) {
  return (
    <TextOptionContainer
      active={active}
      onClick={() => {
        if (disabled) return null;
        onClick();
      }}
      data-testid={`select-multiple-select-icons-${item.id}`}
    >
      <Row>
        <Col>
          {" "}
          <SelectionContainer data-testid={`select-icon-${item.id}`}>
            {" "}
            {active && <img height="12" src={checkSVG} alt="Check" />}
          </SelectionContainer>
          <IconContainer>
            <Icon src={item.img} rel="preload" alt="" />
          </IconContainer>
        </Col>
        <LabelContainer>
          <Label>{item.label}</Label>
        </LabelContainer>
      </Row>
    </TextOptionContainer>
  );
}
