import i18next from 'i18next'
import HttpBackend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import config from 'lib/config'

const loadPath = `https://api.i18nexus.com/project_resources/translations/{{lng}}/{{ns}}.json?api_key=${config.NEXUS_API_KEY}`

i18next
  .use(HttpBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: config.NODE_ENV !== 'production',
    fallbackLng: 'en',
    lng: i18next.options.lng,
    ns: ['default'],
    defaultNS: 'default',

    supportedLngs: ['en', 'es'],

    backend: {
      loadPath: loadPath,
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  })

export default i18next
