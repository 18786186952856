export enum AdPartEnum {
  'health_care_choices' = 'health_care_choices',
  'medical_decision_maker' = 'medical_decision_maker',
  'sign_the_form' = 'sign_the_form',
  'faqs_and_legal' = 'faqs_and_legal',
}

export const getPartByPartId = (parts: PlanSettingPart[], partId: AdPartEnum): PlanSettingPart | undefined => {
  return parts?.find((part) => part.id === partId?.toString())
}

export interface PlanSettingPart {
  id: AdPartEnum
  img: any
  name: string
  pageTitle?: string
  pageTitle2?: string
  description: string
  /* Only used for oregon right now. Will show a checkbox on signature page: https://kodahealth.atlassian.net/browse/KODA-960*/
  witness2InLongtermCareCheck?: boolean
  /** showSignatures is only used for the medical decision maker section, it will display a place for decision makers to sign. Currently only used for oregon (as of March 9, 2022) */
  showSignatures?: boolean
  beforeYouSign?: {
    requirements: string[]
    finalClause: string | JSX.Element
  }
  /** Content that will show at the top of the page where MDMs put in their contact information */
  preMdmContactFieldsContent?: string | JSX.Element
  /** Will show up as extra content under "By signing this form, you allow your medical decision maker to: section (example for georgia: https://kodahealth.atlassian.net/browse/KODA-1282" */
  addtionalMdmAllowances?: JSX.Element
  witness1SignatureText?: string | JSX.Element
  witness2SignatureText?: string | JSX.Element
  witnessSignatures?: {
    pageTitle?: string
    preSignaturesContent?: string | JSX.Element
    postSignaturesContent?: string | JSX.Element
  }
  postFlexibilityContent: string | JSX.Element // shows up after the flexibility question in the MDM section
  postSignTheFormContent?: string | JSX.Element
  witnessRequirements?: {
    title: string // OR "Witness and Notary Requirements"
    /** The total number of witnesses that are required */
    numberOfWitnessesRequired?: number
    /** Of the total numberOfWitnessesRequired, how many must meet the yourWitnessCannot requirements */
    numberOfWitnessesThatMustMeetRequirements?: number
    validityRequirements?: string[]
    requirements?: {
      title: string
      /** Optional way to control the width of the columns on the PDF */
      width?: string
      phrases: string[]
      highlighed?: boolean // will show the given requirment in a highlighted block like https://kodahealth.atlassian.net/browse/KODA-1146
    }[]
  }
  notary?: {
    disclaimer?: string
  }
  additionalContent?: any
  /** an array of sections that get mapped out. Typically used for adding many custom sections to a legal section */
  sections?: JSX.Element[]
}

export interface PlanSetting {
  state: string
  disclaimer: string
  parts: PlanSettingPart[]
}

export interface PlanSettings {
  [key: string]: PlanSetting
}
