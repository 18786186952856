



export default function getAltDecisionMakerName(data): string | null {
    if (data.altDecisionMakerName) {
        return data.altDecisionMakerName;
    }
    if (data.altDecisionMakerFirstName && data.altDecisionMakerLastName) {
        return `${data.altDecisionMakerFirstName} ${data.altDecisionMakerLastName}`;
    }
    if (data.altDecisionMakerFirstName && !data.altDecisionMakerLastName) {
        return `${data.altDecisionMakerFirstName}`;
    }
    return null;
};