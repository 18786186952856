import { useLocation, Link, useHistory } from 'react-router-dom'
import styled from 'styled-components'
// COMPONENTS
import Row from 'components/common/Row'
import Col from 'components/common/Col'
import InactiveModal from 'components/common/InactiveModal'
// LIB
import logo from 'lib/media/logo-light-bg-horizontal.svg'
import { AccessTypeEnum, UserProfile } from 'generated/graphql'
import userHasPermission from 'lib/helpers/userHasPermission'
import translateApolloError from 'lib/helpers/translateApolloError'
import PatientSearchInput from 'components/common/PatientSearchInput'
import useSignout from 'lib/hooks/useSignout'

const Container = styled.div`
  margin: auto;
  max-width: 100%;
  min-height: 94vh;
  background: ${(p) => p.theme.colors.neutral10};
  padding-top: 16px;
`

const InnerContainer = styled.div`
  margin: auto;
  width: 1150px;
  max-width: 100%;
`

const NavContainer = styled.div`
  height: 60px;
  border-bottom: 1px solid ${(p) => p.theme.colors.neutral9};
  display: flex;
  align-items: center;
`

const NavMenu = styled.nav`
  display: flex;
`

const MenuLink = styled(Link)`
  font-weight: 600;
  flex: 1;
  text-align: center;
  cursor: pointer;
  color: ${(p) => (p.active ? p.theme.colors.primary6 : p.theme.colors.neutral5)} !important;
  &:hover {
    color: ${(p) => (p.active ? p.theme.colors.primary4 : p.theme.colors.neutral2)} !important;
  }
`

const AvatarContainer = styled.div<{ active?: boolean }>`
  font-weight: 600;
  flex: 1;
  text-align: center;
  cursor: pointer;
  color: ${(p) => (p.active ? p.theme.colors.primary6 : p.theme.colors.neutral5)} !important;
  &:hover {
    color: ${(p) => (p.active ? p.theme.colors.primary4 : p.theme.colors.neutral2)} !important;
  }
`

const Logo = styled.img`
  height: 30px;
  cursor: pointer;
`

interface NavProps {
  pathname?: string
  currentUser: UserProfile
  onSignout?: () => void
}

const Nav = ({ pathname, currentUser, onSignout }: NavProps) => {
  const history = useHistory()
  return (
    <NavContainer>
      <Row style={{ width: 1300, margin: 'auto' }} align="middle">
        <Col xs={4}>
          <Logo src={logo} alt="logo" onClick={() => history.push('/admin')} />
        </Col>
        <Col xs={1} />
        <Col xs={6}>
          <PatientSearchInput
            onClick={(record) => history.push(`/admin/clinics/${record.clinicId}?patientId=${record.id}&tab=patients`)}
          />
        </Col>
        <Col xs={1} />
        <Col xs={12}>
          <NavMenu>
            <MenuLink to={'/admin'} active={pathname === '/admin'}>
              Home
            </MenuLink>
            <MenuLink to={'/admin/clinics'} active={pathname === '/admin/clinics'}>
              Clinics
            </MenuLink>
            <MenuLink to={'/admin/users'} active={pathname === '/admin/users'}>
              Users
            </MenuLink>
            {userHasPermission(currentUser, AccessTypeEnum.CanUpdateStateDetails) && (
              <MenuLink to={'/admin/states'} active={pathname === '/admin/states'}>
                States
              </MenuLink>
            )}
            <MenuLink to={'/admin/logs'} active={pathname === '/admin/logs'}>
              Logs
            </MenuLink>
            <MenuLink to={'/admin/settings'} active={pathname === '/admin/settings'}>
              Settings
            </MenuLink>
            <AvatarContainer onClick={onSignout}>Sign Out</AvatarContainer>
          </NavMenu>
        </Col>
      </Row>
    </NavContainer>
  )
}

interface AdminLayoutProps {
  currentUser: UserProfile
  children?: JSX.Element | string
}

export default function AdminLayout({ currentUser, children }: AdminLayoutProps) {
  const location = useLocation()
  const [signOut] = useSignout()
  const onSignout = async () => {
    try {
      // run logout function
      await signOut(currentUser)
 
    } catch (err: any) {
      return console.log(translateApolloError(err))
    }
  }

  return (
    <>
      <Nav pathname={location.pathname} onSignout={onSignout} currentUser={currentUser} />
      <Container className="fs-exclude">
        <InnerContainer>{children}</InnerContainer>
      </Container>
      <InactiveModal />
    </>
  )
}
