import React, {useState} from 'react';
import PlanDoc from 'components/common/PlanDoc';
import {pdf} from '@react-pdf/renderer';
import moment from 'moment';

export default function useDownloadPdf() {
  // State for keeping track of whether key is pressed
  const [downloading, setDownloading] = useState(false);

  const downloadPdf = async (plan) => {
    try {
      setDownloading(true);
      const title = `Plan-${plan.firstName}-${moment().format('M/D/YY')}.pdf`;
      const newBlob = await pdf(<PlanDoc data={plan} forBlob downloading />).toBlob();
      let exportFilename = title;
      if (navigator.msSaveBlob) {
        navigator.msSaveBlob(newBlob, exportFilename);
      } else {
        // In FF link must be added to DOM to be clicked
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(newBlob);
        link.setAttribute('download', exportFilename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
      setDownloading(false);
    } catch (err) {
      setDownloading(false);
      console.log('on download error');
    }
  };

  return {
    downloadPdf,
    downloading,
  };
}
