import React from 'react';
import styled from 'styled-components';
import emptySVG from './empty.svg';
import Button from 'components/common/Button';

const Caption = styled.p`
  margin: 0px;
  color: ${(p) => p.theme.colors.neutral5};
  font-size: 14px;
`;

const Title = styled.h1`
  margin: 0px;
  margin-top: 16px;
  margin-bottom: 4px;
  font-weight: 600;
  font-size: 18px;
`;

interface EmptyStateProps {
  title?: string;
  subtitle?: string;
  onOk?: () => void;
  onOkText?: string;
  img?: string;
  style?: React.CSSProperties;
  alt?: string;
}

export default function EmptyState({
  title,
  subtitle,
  onOk,
  onOkText,
  img = emptySVG,
  style,
  alt,
}: EmptyStateProps) {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: 175,
        flex: 1,
        textAlign: 'center',
        ...style,
      }}
    >
      <div>
        <img src={img} height="96" alt={alt || ""} />
        <Title>{title}</Title>
        <Caption>{subtitle}</Caption>
        {onOk && (
          <Button style={{width: 120, marginTop: 16}} onClick={onOk}>
            {onOkText || 'Okay'}
          </Button>
        )}
      </div>
    </div>
  );
}
