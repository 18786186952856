// TOP LEVEL IMPORTS
import { useEffect } from 'react'
import boldFront from 'lib/fonts/Roboto-Bold.ttf'
import regularFont from 'lib/fonts/Roboto-Regular.ttf'
import regularCalligraffitti from 'lib/fonts/Calligraffitti-Regular.ttf'
import { PlanFontFamily } from 'components/common/PlanDoc/PlanFontFamily'
import { Font } from '@react-pdf/renderer'

const hyphenationCallback = (word) => {
  if (word === 'machine' || 'or' || 'your') {
    return [word]
  }
  return [...word]
}

// we want to register the PDF font as early as possible in the application rendering process
const registerPdfFont = () => {
  // https://stackoverflow.com/questions/66255816/cannot-read-property-hasglyphforcodepoint-of-null-react-pdf
  // https://github.com/diegomura/react-pdf/issues/777
  Font.register({
    family: PlanFontFamily.bold,
    format: 'truetype',
    src: boldFront,
  })

  Font.register({
    family: PlanFontFamily.regular,
    format: 'truetype',
    src: regularFont,
  })

  Font.register({
    family: PlanFontFamily.cursive,
    format: 'truetype',
    src: regularCalligraffitti,
  })

  Font.registerHyphenationCallback(hyphenationCallback)
}

export default function useRegisterPdfFonts() {
  useEffect(() => {
    // register PDF font
    registerPdfFont()
  }, [])
}
