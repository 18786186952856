import { FC } from 'react';
import styled from 'styled-components';

import TextOption from 'components/common/SurveyForm/SelectMultiple/TextOption';

const SelectBlockStyled = styled.div`
  margin-top: 32px;
`;

const SelectBlockTitle = styled.div`
  font-size: 18px;
  font-weight: 400;
  color: #1F2933;
`;

const SelectBlockDescription = styled.div`
  margin-top: 8px;
  font-size: 16px;
  font-style: italic;
  color: #1F2933;
`;

const SelectBlockSubTitle = styled.div`
  font-size: 16px;
  margin-top: 8px;
  margin-bottom: 24px;
  color: #616E7C;
`;

interface SelectBlockProps {
  title: string;
  subtitle: string;
  description?: string;
  items: string[];
  active?: string | string[];
  hash: object;
  otherField?: string;
  otherValue?: string;
  onChange?: Function;
  onSelect: (item: string) => void;
  noCapitalize?: boolean;
};

const SelectBlock: FC<SelectBlockProps> = ({
  title,
  subtitle,
  hash,
  description,
  items,
  active,
  otherField,
  otherValue,
  onChange,
  onSelect,
  noCapitalize,
}) => {

  const checkActive = (item: string): boolean => {
    if (typeof active === 'string') {
      return item === active;
    }
    else if (active) {
      if (active.includes(item)) {
        return true;
      }
    }
    return false;
  };

  return (
    <SelectBlockStyled>
      <SelectBlockTitle>
        {title}
      </SelectBlockTitle>
      <SelectBlockDescription>
        {description}
      </SelectBlockDescription>
      <SelectBlockSubTitle>
        {subtitle}
      </SelectBlockSubTitle>
      {items.map((item, index) => (
        <TextOption
          id={index}
          key={index}
          item={{
            id: index,
            label: hash[item],
          }}
          disabled={false}
          otherFieldName={item}
          otherFieldValue={otherValue}
          active={checkActive(item)}
          onClick={() => onSelect(item)}
          onChange={onChange as Function}
          isOther={otherField === item}
          isOtherNewLine={otherField === item}
          noCapitalize={noCapitalize}
        />
      ))}
    </SelectBlockStyled>
  );
}

export default SelectBlock;
