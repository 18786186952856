import { useTranslation } from 'react-i18next'
import { Clinic } from 'generated/graphql'
// COMPONENTS
import VideoPlayer from 'components/common/VideoPlayer'
import { StepContainer, Container } from 'components/common/SurveyForm'
import Animated from 'components/common/Animated'
import ElephantChat from 'components/common/ElephantChat'
// LIB
import logoPNG from './logo.png'
import { BottomLink, BottomText, Header, LinkContainer } from './styledStart/start.Styled'
import constants from 'lib/config'
import i18n from 'lib/helpers/i18n'

interface StartProps {
  onNext: () => void
  clinic: Clinic
}

export default function Start({ onNext, clinic }: StartProps) {
  const { t } = useTranslation()

  const videoId = clinic?.startVideo || i18n.t('videos.startVideo') || '441039390'

  return (
    <Container>
      <Header>
        <img src={clinic?.logo || logoPNG} alt="Koda Health" width="270" />
      </Header>
      <StepContainer
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <div style={{ width: 600, maxWidth: '100%' }}>
          <Animated>
            <div style={{ display: 'flex', marginBottom: 48 }}>
              <ElephantChat
                text={t(
                  'signupPage.welcome',
                  "Hi, welcome to Koda Health. We're here to help you plan for your future medical care."
                )}
              />
            </div>
            <VideoPlayer videoId={videoId} onNext={onNext} skipOnEnd />
            {clinic?.v2Signup && (
              <>
                <LinkContainer>
                  <BottomText>
                    {i18n.t('signupPage.alreadyHaveAccount')}{' '}
                    <BottomLink to="/login">{i18n.t('signupPage.signinHere', 'Sign in here')}</BottomLink>
                  </BottomText>
                  <BottomText>
                    {i18n.t('signupPage.needHelp', 'Need help? Call us at')}{' '}
                    <a
                      style={{ textDecoration: 'underline' }}
                      href={`tel:+${constants.supportPhone.replace(/-/g, '')}`}
                    >
                      {constants.supportPhone}
                    </a>
                  </BottomText>
                </LinkContainer>
              </>
            )}
          </Animated>
        </div>
      </StepContainer>
    </Container>
  )
}
