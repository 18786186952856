import React, { useEffect, useCallback, useState } from 'react'
import styled from 'styled-components'
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import queryString from 'query-string'
import { useWindowWidth } from '@react-hook/window-size'
import { Patient } from 'generated/graphql'
// ICONS
import officialPlanSvg from 'lib/media/images/official_plan.svg'
import unofficialPlanSvg from 'lib/media/images/unofficial_plan.svg'
import emailSVG from './images/email.svg'
import personQuestionSVG from './images/person-question.svg'
import makePlanOfficialSvg from 'lib/media/images/make_plan_official.svg'
import contractSVG from './images/contract.svg'
// COMPONENTS
import CaretRightOutlined from 'components/icons/CaretRightOutlined'
import CaretLeftOutlined from 'components/icons/CaretLeftOutlined'
import Button from 'components/common/Button'
import elephantSVG from 'lib/media/images/elephant.svg'
// HOOKs
import useUrlChange from 'lib/hooks/useUrlChange'
import theme from 'lib/theme'
import replaceAllSpaces from 'lib/helpers/replaceAllSpaces'
import i18n from 'lib/helpers/i18n'
import useSavePatient from 'lib/hooks/useSavePatient'
import { useTranslation } from 'react-i18next'

const Drawer = styled.div<{ expandDrawer: boolean }>`
  background: ${(p) => p.theme.colors.primary10};
  padding: 16px;
  overflow-y: scroll;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 75%;
  top: 0;
  z-index: 1000;
  box-shadow: 2px 0 2px -2px rgba(0, 0, 0, 0.25);
  transform: ${(p) => {
    // show drawer partly open
    if (!p.expandDrawer) {
      return 'translateX(-96%)'
    }
    // show drawer fully open
    if (p.expandDrawer) {
      return 'translateX(-80%)'
    }
  }};
  @media only screen and (max-width: 1024px) {
    padding-right: 0px;
    transform: ${(p) => {
      // show drawer partly open
      if (!p.expandDrawer) {
        return 'translateX(-96%)'
      }
      // show drawer fully open
      if (p.expandDrawer) {
        return 'translateX(-75%)'
      }
    }};
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    transform: ${(p) => {
      // show drawer partly open
      if (!p.expandDrawer) {
        return 'translateX(-96%)'
      }
      // show drawer fully open
      if (p.expandDrawer) {
        return 'translateX(-55%)'
      }
    }};
  }
  transition: transform 0.3s ease-out;
`

const DrawerInner = styled.div`
  width: 100%;
`

const DotWrapper = styled.div<{ expandDrawer?: boolean }>`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 69%;
  top: 0;
  z-index: 15;
  transform: ${(p) => {
    // show drawer partly open
    if (!p.expandDrawer) {
      return 'translateX(-93.5%)'
    }
    // show drawer fully open
    if (p.expandDrawer) {
      return 'translateX(-76%)'
    }
  }};
  @media only screen and (max-width: 1024px) {
    transform: ${(p) => {
      // show drawer partly open
      if (!p.expandDrawer) {
        return 'translateX(-91.5%)'
      }
      // show drawer fully open
      if (p.expandDrawer) {
        return 'translateX(-68%)'
      }
    }};
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    transform: ${(p) => {
      // show drawer partly open
      if (!p.expandDrawer) {
        return 'translateX(-85.5%)'
      }
      // show drawer fully open
      if (p.expandDrawer) {
        return 'translateX(-42%)'
      }
    }};
  }
  transition: transform 0.3s ease-out;
`

const DrawerDot = styled.div<{ expandDrawer: boolean }>`
  position: absolute;
  padding-right: 10px;
  border-bottom-right-radius: 60px;
  border-top-right-radius: 60px;
  right: 0px;
  top: 24vh;
  z-index: 10;
  width: 25px;
  height: 50px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: start;
  background: ${(p) => p.theme.colors.primary8};
  @media only screen and (max-width: 1024px) {
    top: 16vh;
  }
`

const Tooltip = styled.div`
  position: absolute;
  right: -188px;
  height: 48px;
  width: 180px;
  background: ${(p) => p.theme.colors.neutral3};
  border-radius: 5px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
`

// const CarotIcon = styled(Icon)`
//   color: ${(p) => p.theme.colors.primary3};
//   font-size: 19px;
//   position: relative;
//   right: -2px;
// `;

const CarotIconRight = styled(CaretRightOutlined)`
  color: ${(p) => p.theme.colors.primary3};
  font-size: 19px;
  position: relative;
  right: -2px;
`
const CarotIconLeft = styled(CaretLeftOutlined)`
  color: ${(p) => p.theme.colors.primary3};
  font-size: 19px;
  position: relative;
  right: -2px;
`

const ActionImg = styled.img`
  width: 48px;
`

const ActionText = styled.h2`
  font-weight: 400;
  margin: 0px;
  color: ${(p) => p.theme.colors.neutral1};
  font-size: 18px;
  line-height: 1.2;
`

const ActionContainer = styled.div`
  border: 1px solid transparent;
  flex: 1;
  border-radius: 5px;
  margin: auto;
  margin-bottom: 32px;
  background: #fff;
  padding: 8px;
  height: 125px;
  width: 145px;
  border: 1px solid ${(p) => p.theme.colors.neutral9};
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.06);
  transition: border-color 0.3s ease;
  margin-right: 16px;
  @media only screen and (max-width: 1024px) {
    margin-right: 8px;
    height: 125px;
    width: 125px;
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    margin-right: 8px;
  }
  &:hover {
    border: 1px solid ${(p) => p.theme.colors.primary8};
    cursor: pointer;
  }
  &:active {
    transform: translatey(2px);
  }
`

interface ActionBlockProps {
  label: string
  src: string
  onClick: (e: React.MouseEvent) => void
}

const ActionBlock = ({ label, src, onClick = () => {} }: ActionBlockProps) => {
  return (
    <ActionContainer data-testid={`${replaceAllSpaces(label?.toLowerCase())}-btn`} onClick={onClick}>
      <div style={{ height: 115 }}>
        <div
          style={{
            height: 75,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <ActionImg src={src} alt="" />
        </div>
        {label && React.isValidElement(label) ? (
          label
        ) : (
          <ActionText data-testid={`${replaceAllSpaces(label?.toLowerCase())}-text`}>{label}</ActionText>
        )}
      </div>
    </ActionContainer>
  )
}

const DrawerWrapper = styled.div`
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    display: none;
  }
`

const MobileDrawerContainer = styled.div`
  position: fixed;
  background: #fff;
  left: 0px;
  bottom: 0px;
  top: 72px;
  width: 100%;
  border-right: 2px solid ${({ theme }) => theme.colors.neutral9};
  border-top: 2px solid ${({ theme }) => theme.colors.neutral9};
  z-index: 6000;
  overflow-y: scroll;
  @media only screen and (min-width: ${({ theme }) => `${parseInt(theme.breakpoints.medium) + 1}px`}) {
    display: none;
  }
`

const MobileActionItem = styled.div`
  padding: 14px;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 18px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral9};
`

type ItemType = {
  label?: string
  onClick?: (e: React.MouseEvent) => void
  src?: string
}

interface MobileDrawerProps {
  menuItems: ItemType[]
}

const MobileDrawer = ({ menuItems = [] }: MobileDrawerProps) => {
  const location = useLocation()
  const { onUrlChange } = useUrlChange()
  const { mobileMenuOpen } = queryString.parse(location.search)
  if (!mobileMenuOpen) return null

  return (
    <MobileDrawerContainer>
      {menuItems.map((item) => {
        return (
          <MobileActionItem key={item.label} onClick={item.onClick}>
            <img src={item.src} alt="" height="40" style={{ marginRight: 16 }} />
            {item.label}
          </MobileActionItem>
        )
      })}
      <div style={{ width: 100, margin: '16px auto 0px auto' }}>
        <Button onClick={() => onUrlChange({ mobileMenuOpen: undefined }, null)} style={{ minWidth: 100 }}>
          Close
        </Button>
      </div>
    </MobileDrawerContainer>
  )
}

interface ActionDrawerProps {
  expanded: boolean
  setExpanded: React.Dispatch<React.SetStateAction<boolean>>
  mode: string | string[]
  guideComplete: boolean
  patient: Patient
}

export default function ActionDrawer({ expanded, setExpanded, mode, guideComplete, patient }: ActionDrawerProps) {
  const { t } = useTranslation()
  const history = useHistory()
  const windowWidth = useWindowWidth()
  const [showTip, setShowTip] = useState(false)
  const onUpdateExpanded = useCallback(() => {
    setExpanded(true)
  }, [setExpanded])
  const { onSavePatient } = useSavePatient()
  const isAcpSchedulingPage = useRouteMatch('/app/acp-scheduling')

  useEffect(() => {
    /**
     * when viewing the PDF on desktop, this useEffect is causing the PDF
     * to re-render too many times, causing errors... so we want to avoid
     * running this code if we're on that page... thus we check if mode=viewPlan
     */
    if (guideComplete && mode !== 'viewPlan') {
      onUpdateExpanded()
    }
  }, [guideComplete, mode, onUpdateExpanded])

  const BLOCKS = [
    {
      label: !patient?.hasOfficialPlan
        ? i18n.t('blocks.viewUnofficialPlan', 'View Unofficial Plan')
        : i18n.t('blocks.viewOfficialPlan', 'View Official Plan'),
      src: !patient?.hasOfficialPlan ? unofficialPlanSvg : officialPlanSvg,
      onClick: () => history.push(`/app?mode=viewPlan`),
    },
    {
      label: i18n.t('blocks.makePlanOfficial', 'Make Plan Official'),
      src: makePlanOfficialSvg,
      dataTestId: 'make-plan-official-btn',
      onClick: () => history.push(`/app?mode=notarize`),
    },
    {
      label: i18n.t('blocks.sharePlan', 'Share Plan'),
      src: emailSVG,
      onClick: () => history.push(`/app?mode=emailDirective`),
    },
    {
      label: i18n.t('blocks.completedPlans', 'Completed Plans'),
      src: contractSVG,
      onClick: () => history.push(`/app?mode=completedPlans`),
    },
    {
      label: i18n.t('blocks.getHelp', 'Get Help'),
      src: personQuestionSVG,
      onClick: () => history.push(`/app?mode=help`),
    },
  ]

  const pagesToShowHomeMenuItem = [
    'viewPlan',
    'editPlan',
    'notarize',
    'emailDirective',
    'completedPlans',
    'help',
    'profile',
  ]

  if (pagesToShowHomeMenuItem.includes(mode as string) && parseInt(theme.breakpoints.medium) + 1 > windowWidth) {
    BLOCKS.unshift({
      label: i18n.t('blocks.home', 'Home'),
      src: elephantSVG,
      onClick: () => history.push(`/app/home`),
    })
  }

  const toggleDrawer = () => {
    setExpanded(!expanded)
  }

  const homeClick = useCallback(async () => {
    await onSavePatient({
      ...patient,
      schedulingACPinformation: 'done',
    })
    history.push('/app/home')
  }, [onSavePatient, patient, history])

  return (
    <>
      <MobileDrawer
        menuItems={
          isAcpSchedulingPage
            ? [
                {
                  label: 'Home',
                  src: elephantSVG,
                  onClick: homeClick,
                },
                ...BLOCKS,
              ]
            : BLOCKS
        }
      />
      <DrawerWrapper>
        <DotWrapper expandDrawer={expanded}>
          <DrawerDot
            onClick={toggleDrawer}
            role="button"
            data-testid="action-drawer-expand-btn"
            expandDrawer={expanded}
            onMouseOver={() => {
              if (!expanded) {
                setShowTip(true)
              }
            }}
            onMouseOut={() => setShowTip(false)}
          >
            {!expanded ? <CarotIconRight /> : <CarotIconLeft />}
            {showTip && <Tooltip>{t('clickToViewFull', 'Click to view full menu')}</Tooltip>}
          </DrawerDot>
        </DotWrapper>
        <Drawer expandDrawer={expanded}>
          <DrawerInner>
            <div>
              {BLOCKS.map((item) => {
                return <ActionBlock key={item.label} label={item.label} src={item.src} onClick={item.onClick} />
              })}
            </div>
          </DrawerInner>
        </Drawer>
      </DrawerWrapper>
    </>
  )
}
