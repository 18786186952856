import styled from 'styled-components'

// COMPONENTS
import Button from 'components/common/Button'

// IMAGES
import valuesSVG from 'lib/media/images/values_section.svg'
import medicalDecisionSVG from 'lib/media/images/mdm_section.svg'
import qualitySVG from 'lib/media/images/quality_of_life_section.svg'
import medicalSVG from 'lib/media/images/medical_section.svg'

const ModalContentScrollable = styled.div`
  overflow-y: auto;
  margin-top: 40px;
  max-height: calc(96vh - 150px);
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    max-height: calc(78vh - 150px);
  }
`

const CongratsBlock = styled.div`
  border: 1px solid #cbd2d9;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 24px;
  margin-bottom: 16px;
`

const CongratsBlockTitle = styled.div`
  margin-bottom: 8px;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  letter-spacing: -0.03em;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.mediumSmall}) {
    font-size: 20px;
  }
`
const CongratsBlockDescription = styled.div`
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: -0.03em;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.mediumSmall}) {
    font-size: 16px;
  }
`

const CongratsBlock2 = styled.div`
  border: 1px solid #cbd2d9;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 24px;
  margin-bottom: 16px;
`

const CongratsBlock2Title = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: -0.03em;
  margin-bottom: 16px;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.mediumSmall}) {
    font-size: 16px;
  }
`

const CongratsBlock2Description1 = styled.div`
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.03em;
`

const CongratsBlock2Sections = styled.div`
  padding: 16px;
`

const CongratsBlock2Section = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;
  &:last-child {
    margin-bottom: 0;
  }
`

const CongratsBlock2SectionImg = styled.div`
  width: 48px;
  height: 48px;
  max-width: 48px;
  min-width: 48px;
  background-color: #bae3ff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
  img {
    object-fit: contain;
    max-width: 80%;
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.mediumSmall}) {
    width: 32px;
    height: 32px;
    max-width: 32px;
    min-width: 32px;
  }
`

const CongratsBlock2SectionLabel = styled.div`
  width: 260px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.03em;
`

const CongratsBlock2Description2 = styled.div`
  margin-top: 24px;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.03em;
`

const CongratsOKButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 20;
  button {
    width: 140px;
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 0 40px 20px 40px;
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.mediumSmall}) {
    padding: 0 20px 20px 20px;
  }
`

interface PostSurveyCongratsWfpath2Props {
  closeClick: () => void
}

const PostSurveyCongratsWfpath2 = ({ closeClick }: PostSurveyCongratsWfpath2Props) => {
  return (
    <>
      <ModalContentScrollable>
        <CongratsBlock>
          <CongratsBlockTitle>Congratulations!</CongratsBlockTitle>
          <CongratsBlockDescription>
            You’ve completed the first part of the study. You will be sent a $25 gift card as a thank you for your
            participation.
          </CongratsBlockDescription>
        </CongratsBlock>
        <CongratsBlock2>
          <CongratsBlock2Title>
            You can now complete digital Advance Care Planning with Koda for the chance to win a $250 gift card.
          </CongratsBlock2Title>
          <CongratsBlock2Description1>
            All you need to do is create a Care Plan by watching videos and answering questions in the next 4 sections:
          </CongratsBlock2Description1>
          <CongratsBlock2Sections>
            <CongratsBlock2Section>
              <CongratsBlock2SectionImg>
                <img src={valuesSVG} alt="Values Icon" />
              </CongratsBlock2SectionImg>
              <CongratsBlock2SectionLabel>Your Values</CongratsBlock2SectionLabel>
            </CongratsBlock2Section>
            <CongratsBlock2Section>
              <CongratsBlock2SectionImg>
                <img src={medicalDecisionSVG} alt="Medical Decision Icon" />
              </CongratsBlock2SectionImg>
              <CongratsBlock2SectionLabel>Selecting a Medical Decision Maker</CongratsBlock2SectionLabel>
            </CongratsBlock2Section>
            <CongratsBlock2Section>
              <CongratsBlock2SectionImg>
                <img src={qualitySVG} alt="Quality Icon" />
              </CongratsBlock2SectionImg>
              <CongratsBlock2SectionLabel>Defining Quality of Life</CongratsBlock2SectionLabel>
            </CongratsBlock2Section>
            <CongratsBlock2Section>
              <CongratsBlock2SectionImg>
                <img src={medicalSVG} alt="Medical Icon" />
              </CongratsBlock2SectionImg>
              <CongratsBlock2SectionLabel>Selecting Medical Care Preferences</CongratsBlock2SectionLabel>
            </CongratsBlock2Section>
          </CongratsBlock2Sections>
          <CongratsBlock2Description1>
            Then complete an Experience Comparison survey that will be emailed to you.
          </CongratsBlock2Description1>
          <CongratsBlock2Description2>
            Please contact Andrew Brown at 336-716-0040 if you have any questions about this process.
          </CongratsBlock2Description2>
        </CongratsBlock2>
      </ModalContentScrollable>
      <CongratsOKButtonWrapper>
        <Button
          onClick={closeClick}
          style={{
            display: 'block',
            visibility: 'visible',
          }}
        >
          OK
        </Button>
      </CongratsOKButtonWrapper>
    </>
  )
}

export default PostSurveyCongratsWfpath2
