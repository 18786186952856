import { useHistory, useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { APP_PAGES_ENUMS } from 'routes/app-home'
import { GuideQuestionIdEnum } from 'generated/graphql'

interface OnUrlChangeNewValues {
  mode?: APP_PAGES_ENUMS
  editSection?: GuideQuestionIdEnum
  formType?: 'signup' | undefined
  mobileMenuOpen?: string
  tab?: string
  patientId?: string
}

export default function useUrlChange() {
  const location = useLocation()
  const history = useHistory()

  const onUrlChange = (newValues: OnUrlChangeNewValues, baseUrl?: string | null) => {
    const existing = queryString.parse(location.search)
    let newUrl = {
      ...existing,
      ...newValues, // object
    }

    // the user of onUrlChange can pass in a baseUrl, or it will default to the curent base url
    history.push({
      pathname: baseUrl || location.pathname,
      search: `?${queryString.stringify(newUrl)}`,
    })
  }

  return {
    onUrlChange,
    query: queryString.parse(location.search),
  }
}
