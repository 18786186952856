/**
 *
 */

export default function getAnswer({ questions, fieldName, value }) {
  let options = questions?.filter((item) => item.fieldName === fieldName)[0].options
  let option = options?.filter((item) => {
    if (item.value) return item.value === value
    return item.id === value
  })[0]

  let label = option?.label
  return label || value // if there is no label to display, then just return the value
}
