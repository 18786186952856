// TOP LEVEL IMPORTS
import { useEffect, lazy } from 'react'
import { Route, Switch } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ms from 'ms'
// PUBLIC
import PublicRoute from 'components/route-components/PublicRoute'
import AdminRoute from 'components/route-components/AdminRoute'
import BlankRoute from 'components/route-components/BlankRoute'
import ClinicRoute from 'components/route-components/ClinicRoute'
import ProtectedRoute from 'components/route-components/ProtectedRoute' // will redirect them to home screen if signed in
// LAYOUTS
import PublicLayout from 'components/layout/PublicLayout'
import AppLayout from 'components/layout/AppLayout'
import AdminLayout from 'components/layout/AdminLayout'
import ClinicLayout from 'components/layout/ClinicLayout'
// COMPONENTS
import Loading from 'components/common/Loading'
import ErrorState from 'components/common/ErrorState'
import Offline from 'components/common/Offline'
// APP
import AppHomeRoute from 'routes/app-home'
import AppQuestionsRoute from 'routes/app-questions-view'
import AppSurveyRoute from 'routes/app-survey'
import AppAcpSheduling from 'routes/app-acp-sheduling'
import AppPostSurvey from 'routes/app-post-survey'
import AppOnboarding from 'routes/app-onboarding'
// PUBLIC
import PublicForm from 'routes/public-start'
import PublicDownloadPDF from 'routes/public-download-pdf'
import PublicGuestComplete from 'routes/public-guest-complete' // page we show a guest to tell them we've sent them an email where they can create a password to get back into their account (i.e. our save and exist workflow)
// AUTH
import AuthLoginRoute from 'routes/auth-login'
import AuthMfaRoute from 'routes/auth-mfa'
import SetPasswordRoute from 'routes/auth-set-password'
// HOOKs
import useOnlineStatus from '@rehooks/online-status'
import useLogRouteChange from 'lib/hooks/useLogRouteChange'
import { LangTypeEnum, useCurrentUserQuery, UserProfile } from 'generated/graphql'
import { LocalStorageKeyEnum } from 'lib/types/LocalStorageKeyEnum'
import getLocalStorage from 'lib/helpers/getLocalStorage'
import translateError from 'lib/helpers/translateApolloError'
import AppBookACall from './app-book-a-call'

// LAZY LOAD ADMIN ROUTES
const AdminHomeRoute = lazy(() => import('./admin-home'))
const AdminClinicDetail = lazy(() => import('./admin-clinic-detail'))
const AdminUsersRoute = lazy(() => import('./admin-users'))
const AdminLogsRoute = lazy(() => import('./admin-logs'))
const AdminClinicsRoute = lazy(() => import('./admin-clinics'))
const AdminStates = lazy(() => import('./admin-states'))
const AdminNps = lazy(() => import('./admin-nps'))
const AdminSettingsRoute = lazy(() => import('./admin-settings'))
// OTHER PAGES
const PublicConsent = lazy(() => import('./public-wf-consent'))
const Esign = lazy(() => import('./app-esign'))
const ClinicSettings = lazy(() => import('./clinic-settings'))
const ClinicHome = lazy(() => import('./clinic-home'))
const AppProfileRoute = lazy(() => import('./app-profile'))
const PublicDMCA = lazy(() => import('./public-DMCA'))
const PublicTermsRoute = lazy(() => import('./public-terms'))
const PublicPrivacyRoute = lazy(() => import('./public-privacy'))
const PageNotFound = lazy(() => import('./public-not-found'))
const AuthForgotPasswordRoute = lazy(() => import('./auth-forgot-password'))
const ResetPasswordRoute = lazy(() => import('./auth-reset-password'))

export default function AppRoutes() {
  const { i18n } = useTranslation()
  const { data, loading, error } = useCurrentUserQuery({
    errorPolicy: 'all',
    pollInterval: ms('30m'), // re-run every 5 mins
    fetchPolicy: 'network-only',
  })

  useLogRouteChange()
  /**
   * Wil be true or false if we've lost network connection
   */
  const online = useOnlineStatus()

  let currentUser = data && data.currentUser

  let errorExists = error && error.message

  useEffect(() => {
    if (currentUser) {
      i18n.changeLanguage(currentUser.lang || getLocalStorage(LocalStorageKeyEnum.Language) || LangTypeEnum.En)
    }
  }, [i18n, currentUser])

  if (loading) {
    return <Loading />
  }

  if (error?.message) {
    return <ErrorState title={translateError(error)} />
  }

  if (errorExists === 'Network error: Failed to fetch') {
    return <ErrorState title={i18n.t('errors.troubleConnecting', 'We seem to be having trouble connecting...')} />
  }

  // all other errors (that are not in our white listed errors list) just print to screen for now, unless it's a white listed error
  if (errorExists) {
    return <ErrorState />
  }

  return (
    <>
      <Switch>
        <Route
          path={'/health'}
          render={() => (
            <PublicLayout>
              <h3 style={{ margin: 'auto', marginTop: 64 }}>The Koda App is Healthy 😊</h3>
            </PublicLayout>
          )}
        />
        {/* CLINIC */}

        <ClinicRoute
          exact
          layout={ClinicLayout}
          currentUser={currentUser as UserProfile}
          path={['/clinic', '/clinic/home']}
          component={ClinicHome}
        />
        <ClinicRoute
          exact
          layout={ClinicLayout}
          currentUser={currentUser as UserProfile}
          path="/clinic/settings"
          component={ClinicSettings}
        />

        {/* APP */}
        <ProtectedRoute	
          exact	
          layout={PublicLayout}	
          alignItems="flex-start"	
          currentUser={currentUser as UserProfile}	
          path="/app/book-a-call"	
          component={AppBookACall}	
        />
        <ProtectedRoute
          exact
          layout={AppLayout}
          currentUser={currentUser as UserProfile}
          path={['/app', '/app/contacts', '/app/home']}
          component={AppHomeRoute}
        />
        <ProtectedRoute
          exact
          layout={PublicLayout}
          alignItems="flex-start"
          currentUser={currentUser as UserProfile}
          path="/app/questions"
          component={AppQuestionsRoute}
        />
        <ProtectedRoute
          exact
          layout={PublicLayout}
          alignItems="flex-start"
          currentUser={currentUser as UserProfile}
          path="/app/onboarding"
          component={AppOnboarding}
        />
        <ProtectedRoute
          exact
          layout={PublicLayout}
          alignItems="flex-start"
          currentUser={currentUser as UserProfile}
          path="/app/esign"
          component={Esign}
        />
        <ProtectedRoute
          exact
          layout={AppLayout}
          currentUser={currentUser as UserProfile}
          path="/app/profile"
          component={AppProfileRoute}
        />
        <ProtectedRoute
          exact
          layout={PublicLayout}
          currentUser={currentUser as UserProfile}
          path="/app/survey"
          component={AppSurveyRoute}
        />
        <ProtectedRoute
          exact
          layout={PublicLayout}
          currentUser={currentUser as UserProfile}
          path="/app/post-survey"
          component={AppPostSurvey}
        />
        <ProtectedRoute
          exact
          layout={AppLayout}
          currentUser={currentUser as UserProfile}
          path="/app/acp-scheduling"
          component={AppAcpSheduling}
        />
        {/* ADMIN */}
        <AdminRoute
          exact
          layout={AdminLayout}
          currentUser={currentUser as UserProfile}
          path="/admin"
          component={AdminHomeRoute}
        />
        <AdminRoute
          exact
          layout={AdminLayout}
          currentUser={currentUser as UserProfile}
          path="/admin/settings"
          component={AdminSettingsRoute}
        />

        <AdminRoute
          exact
          layout={AdminLayout}
          currentUser={currentUser as UserProfile}
          path="/admin/nps"
          component={AdminNps}
        />
        <AdminRoute
          exact
          layout={AdminLayout}
          currentUser={currentUser as UserProfile}
          path="/admin/states"
          component={AdminStates}
        />
        <AdminRoute
          exact
          layout={AdminLayout}
          currentUser={currentUser as UserProfile}
          path="/admin/users"
          component={AdminUsersRoute}
        />
        <AdminRoute
          exact
          layout={AdminLayout}
          currentUser={currentUser as UserProfile}
          path="/admin/clinics"
          component={AdminClinicsRoute}
        />
        <AdminRoute
          exact
          layout={AdminLayout}
          currentUser={currentUser as UserProfile}
          path="/admin/clinics/:id"
          component={AdminClinicDetail}
        />
        <AdminRoute
          exact
          layout={AdminLayout}
          currentUser={currentUser as UserProfile}
          path="/admin/logs"
          component={AdminLogsRoute}
        />
        {/* PUBILC */}
        <PublicRoute
          exact
          layout={PublicLayout}
          path="/login"
          currentUser={currentUser as UserProfile}
          component={AuthLoginRoute}
        />
        <PublicRoute
          exact
          layout={PublicLayout}
          currentUser={currentUser as UserProfile}
          path="/reset-password/:token"
          component={ResetPasswordRoute}
        />
        <PublicRoute
          exact
          layout={PublicLayout}
          currentUser={currentUser as UserProfile}
          path="/set-password/:token"
          component={SetPasswordRoute}
        />
        <PublicRoute
          exact
          layout={PublicLayout}
          currentUser={currentUser as UserProfile}
          path="/create-account/:token"
          component={ResetPasswordRoute}
        />

        <BlankRoute
          exact
          layout={PublicLayout}
          currentUser={currentUser as UserProfile}
          path="/download-pdf/:token"
          component={PublicDownloadPDF}
        />
        <PublicRoute
          exact
          layout={PublicLayout}
          path="/forgot-password"
          currentUser={currentUser as UserProfile}
          component={AuthForgotPasswordRoute}
        />
        <PublicRoute
          exact
          layout={PublicLayout}
          path="/mfa"
          currentUser={currentUser as UserProfile}
          component={AuthMfaRoute}
        />
        <PublicRoute
          exact
          layout={PublicLayout}
          path="/terms"
          currentUser={currentUser as UserProfile}
          component={PublicTermsRoute}
        />
        <PublicRoute
          exact
          layout={PublicLayout}
          path="/DMCA"
          currentUser={currentUser as UserProfile}
          component={PublicDMCA}
        />
        <PublicRoute
          exact
          layout={PublicLayout}
          redirectOnAuth={false}
          path="/guest-complete"
          currentUser={currentUser as UserProfile}
          component={PublicGuestComplete}
        />
        <PublicRoute
          exact
          layout={PublicLayout}
          path="/privacy"
          currentUser={currentUser as UserProfile}
          component={PublicPrivacyRoute}
        />
        <PublicRoute
          exact
          layout={PublicLayout}
          path="/"
          currentUser={currentUser as UserProfile}
          component={AuthLoginRoute}
        />
        <BlankRoute
          exact
          layout={PublicLayout}
          // path="/:slug/:externalId"
          path={['/wfpath1/:externalId', '/wfpath2/:externalId']}
          currentUser={currentUser as UserProfile}
          component={PublicConsent}
        />
        <BlankRoute
          exact
          layout={PublicLayout}
          path="/:slug"
          currentUser={currentUser as UserProfile}
          component={PublicForm}
        />
        <Route
          render={() => (
            <PublicLayout>
              <PageNotFound />
            </PublicLayout>
          )}
        />
      </Switch>
      {!online && <Offline />}
    </>
  )
}
