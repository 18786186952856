import { useState, useEffect, useRef, useCallback } from 'react'
import { validate } from 'email-validator'
// COMPONENS
import BirthdayInput from 'components/inputs/BirthdayInput'
import Button from 'components/common/Button'
import StatesInput from 'components/inputs/StateInput'
import TextInput from 'components/inputs/TextInput'
import FormItem from 'components/common/FormItem'
import Col from 'components/common/Col'
import Row from 'components/common/Row'
// LIB
import { AnalyticEventTypeEnums } from 'lib/types/AnalyticEventTypeEnums'
import constants from 'lib/config'
import useCheckBirthdayErrors from 'lib/hooks/useCheckBirthdayErrors'
import { Clinic, PatientInput } from 'generated/graphql'
import i18n from 'lib/helpers/i18n'
// import { BottomText, BottomLink, Title } from '../SignupForm/styledSignUp'
import logEvent from 'lib/helpers/logEvent'
import {ContinueAsGuestText, BottomLink, BottomText, Title, Caption, BottomBtn, CaptionLink, Checkbox, ButtonContainer, CaptionsText, Logo, Header, HeaderContainer, LinkButton } from './StyledGuestFormV2/guestformV2.styled'
import queryString from 'query-string'
import { useHistory, useLocation } from 'react-router-dom'
import logo from 'lib/media/logo-light-bg-horizontal.svg'


interface GuestFormProps {
  onChange: (e: { [x: string]: string | number }) => void
  onBack: () => void
  loading?: boolean
  user: PatientInput
  onSubmit: () => void
  onChangeForm: (v: string) => void
  clinic?: Clinic
}

export default function GuestFormV2({ onChange, onBack, loading, clinic, user, onSubmit, onChangeForm }: GuestFormProps) {
  const firstNameInput = useRef<HTMLInputElement | undefined>()
  const [checkBirthdayErrors] = useCheckBirthdayErrors()
  const history = useHistory()
  const location = useLocation()
  const [errors, setErrors] = useState<{
    email?: string
    password?: string
    confirmPassword?: string
    stateId?: string
    firstName?: string
    lastName?: string
    birthday?: string
    agreeToTerms?: string
  }>({
    email: undefined,
    password: undefined,
    confirmPassword: undefined,
    stateId: undefined,
    firstName: undefined,
    lastName: undefined,
    birthday: undefined,
    agreeToTerms: undefined,
  })
  // scroll to top
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    firstNameInput?.current?.focus()
  }, [])

  const onBeforeSubmit = (e) => {
    e.preventDefault()
    if (!user.firstName || user.firstName.length === 0) {
      return setErrors({ firstName: i18n.t('signupPage.provideFirstName', 'Please provide your first name.') })
    }
    if (!user.lastName || user.lastName.length === 0) {
      return setErrors({ lastName: i18n.t('signupPage.provideLastName', 'Please provide your last name.') })
    }
    if (user.email && !validate(user.email)) {
      return setErrors({ email: i18n.t('signupPage.provideValidEmail', 'Please provide a valid email.') })
    }

    const birthdayError = checkBirthdayErrors(user.birthday as string)
    if (birthdayError) return setErrors({ birthday: birthdayError })

    if (!user.stateId) {
      return setErrors({ stateId: i18n.t('signupPage.selectState', 'Please select a state.') })
    }
    if (!user.agreeToTerms) {
      return setErrors({
        agreeToTerms: i18n.t('signupPage.acceptTermsService', 'Please accept the terms of service'),
      })
    }

    logEvent(AnalyticEventTypeEnums.SIGNUP_GUEST_CLICKED, {
      clinicId: clinic?.id,
    })

    return onSubmit()
  }

  const onBeforeChange = (newValues) => {
    setErrors({})
    onChange(newValues)
  }

  const { step = '1', repeat }: { step?: string; repeat?: string } = queryString.parse(location.search)

  const onBackHandler = useCallback(() => {
    let newUrl = {
      step: parseInt(step) - 1,
      repeat,
    }
    history.push(`/${clinic?.slug}?formType=guest&${queryString.stringify(newUrl)}`)
  }, [history, repeat, step])

  const onFormChangeHandler = useCallback(() => {
    let newUrl = {
      step: "2",
      repeat,
    }
    history.push(`/${clinic?.slug}?formType=signup&${queryString.stringify(newUrl)}`)
  }, [history, repeat, step])
  const onNext = useCallback(() => {

    let newUrl = {
      step: parseInt(step) + 1,
      repeat,
    }
    history.push(`/${clinic?.slug}?formType=guest&${queryString.stringify(newUrl)}`)
  }, [history, repeat, step])

  const onCreateAccountLinkHandler = useCallback(() => {
    if (user.firstName) {
      let newUrl = {
        step: "2",
        repeat,
      }
      history.push(`/${clinic?.slug}?formType=signup&${queryString.stringify(newUrl)}`)
    } else if (!user.firstName) {
      let newUrl = {
        step: parseInt(step) + 1,
        repeat,
      }
      history.push(`/${clinic?.slug}`)
    }
  }, [history, repeat, step])

  return (
    <div>


<HeaderContainer>
<Logo src={clinic?.logo || logo} alt="Koda Health" width="320" />

</HeaderContainer>


      <Title>{i18n.t('GuestForm.title', 'Use as a Guest')}</Title>

          <CaptionsText>
            {i18n.t('GuestForm.newSubtitlePart1', `Guest users have access to the app, but cannot log back into their accounts. 
            To have your answers saved, please`)} <LinkButton onClick={onCreateAccountLinkHandler}>{i18n.t('GuestForm.newSubtitlePart2', 'create an account')}</LinkButton> {i18n.t('GuestForm.newSubtitlePart3', 'with a valid email address.')}
          </CaptionsText>
          <CaptionsText>{i18n.t('GuestForm.newSubtitlePart4', 'If you have any questions about your guest account, please contact us at')} <a style={{ textDecoration: 'underline', color: '#01337D', font: "Roboto", fontSize: 16 }} href={`tel:+${constants.supportPhone.replace(/-/g, '')}`}>{constants.supportPhone}</a> {i18n.t('GuestForm.newSubtitlePart5', 'or email')} <a style={{ textDecoration: 'underline', color: '#01337D' }} href="mailto:care@kodahealthcare.com">{constants.supportEmail}</a>.</CaptionsText>

       <ButtonContainer>
        <Button
          disabled={loading}
          grey
          //if page is refreshed or session is interrupted, restart the form when clicking back
          onClick={!user.firstName ? onBack : onFormChangeHandler}
          style={{ width: 165, marginRight: 16 }}>
          {i18n.t('button.back', 'Back')}
        </Button>
        <Button
          disabled={loading}
          loading={loading}
          type="submit"
          onClick={onBeforeSubmit}
          style={{ width: 165 }}>
          <ContinueAsGuestText>
            {i18n.t('signupPage.continueAsGuest', 'Continue as Guest')}
          </ContinueAsGuestText>
        </Button>
      </ButtonContainer>
      <div style={{ marginTop: 32 }}>

        <BottomText>
          {i18n.t('GuestForm.wantToCreate', 'Want to create an account?')}{' '}
          <BottomBtn onClick={!user.firstName ? onBack : onFormChangeHandler}>{i18n.t('GuestForm.signupHere', 'Signup here')}</BottomBtn>
        </BottomText>
        <BottomText>
          {i18n.t('signupPage.needHelp', 'Need help? Call us at')}{' '}
          <a style={{ textDecoration: 'underline' }} href={`tel:+${constants.supportPhone.replace(/-/g, '')}`}>
            {constants.supportPhone}
          </a>
        </BottomText>
      </div>
    </div>
  )
}