import MdmPage, { MedicalDecisionMakerContactFields } from 'components/common/PlanDoc/KodaContent/MdmPage'
import { AdPartEnum } from '../plan-settings'
import { View } from '@react-pdf/renderer'
import PlanSectionDocument from 'components/common/PlanDoc/PlanSectionDocument'
import { PlanSetting } from '../plan-settings/types'
import { Patient } from 'generated/graphql'

export default function MedicalDecisionMaker({
  createdStamp,
  data,
  questions,
  settings,
  pageTitle,
}: {
  settings: PlanSetting
  pageTitle?: string
  questions: any[]
  data: Patient
  createdStamp: any
}) {
  const part = settings?.parts?.find(({ id }) => id === AdPartEnum.medical_decision_maker)

  return (
    <PlanSectionDocument
      pageTitleLeft={pageTitle}
      pageTitleRight={`Name: ${data.firstName} ${data.lastName}`}
      content={
        <View>
          <MdmPage
            pageTitle={part?.pageTitle}
            data={data}
            part={part}
            questions={questions}
            additionalContent={part?.additionalContent}
          />
          <MedicalDecisionMakerContactFields
            data={data}
            pageTitle={part?.pageTitle2}
            questions={questions}
            showSignatures={part?.showSignatures}
            part={part}
          />
        </View>
      }
      showFooter
      pageFooterRight={createdStamp}
    />
  )
}
