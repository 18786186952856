import { PatientActionHistoryItemEnum, RoleTypeEnum } from 'generated/graphql'
import getActionItemHistoryItem from 'lib/helpers/getActionItemHistoryItem'
import { useHistory } from 'react-router-dom'

export default function useRerouteUserOnLogin({ currentUser, redirectOnAuth }) {
  const history = useHistory()

  if (redirectOnAuth && currentUser?.roles?.includes(RoleTypeEnum.SuperAdmin)) {
    return history.push('/admin')
  }

  if (redirectOnAuth && currentUser?.roles?.includes(RoleTypeEnum.ClinicUser)) {
    return history.push('/clinic')
  }

  const hasViewedOnboarding = getActionItemHistoryItem(
    currentUser?.patient,
    PatientActionHistoryItemEnum.HasViewedOnboarding
  )?.value

  /** If they have not viewed onboarding yet, reroute them to the onboarding screen */
  if (redirectOnAuth && !hasViewedOnboarding && currentUser?.roles?.includes(RoleTypeEnum.Patient)) {
    return history.push('/app/onboarding')
  }

  if (redirectOnAuth && currentUser?.roles?.includes(RoleTypeEnum.Patient)) {
    return history.push('/app/home')
  }
}
