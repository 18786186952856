import { Text, View, Image } from '@react-pdf/renderer';
// helpers
import s from 'components/common/PlanDoc/KodaContent/styles';

interface TableOfContentsItemProps {
  img: string;
  text?: string | JSX.Element;
}

export default function TableOfContentsItem({ img, text }: TableOfContentsItemProps) {
  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        marginTop: 16,
      }}
    >
      <View
        style={{
          flex: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          alignSelf: 'center',
          marginRight: 8,
        }}
      >
        <Image
          style={{
            height: 24,
            width: 24,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            alignSelf: 'flex-end',
          }}
          src={img}
        />
      </View>
      <View style={{ flex: 18 }}>
        <Text style={{ ...s.subtitle, marginTop: 2 }}>{text}</Text>
      </View>
    </View>
  );
}
