import styled from 'styled-components'
import QuestionText from 'components/text/QuestionText'
import { ExplainerSectionEnum } from 'lib/types/QuestionItem'
import getAgeRange, {
  getCprStatsByAgeRange,
  getCprTextByAgeRange,
  getCprSurvivalRates,
  getCprUsers,
} from './getAgeRange'
import Caption from 'components/text/Caption'
import theme from 'lib/theme'
import i18n from './i18n'

const AnswerText = styled(QuestionText)`
  font-size: 18px;
  line-height: 125%;
  margin-bottom: 16px;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    font-size: 16px;
  }
`

export const EXPLAINER_SECTION_ENUMS = {
  MECHANICAL_VENTILATION: ExplainerSectionEnum.MECHANICAL_VENTILATION,
  CPR: ExplainerSectionEnum.CPR,
  FEEDING_TUBE: ExplainerSectionEnum.FEEDING_TUBE,
  DIALYSIS: ExplainerSectionEnum.DIALYSIS,
  PALLIATIVE_CARE: ExplainerSectionEnum.PALLIATIVE_CARE,
  HOSPICE_CARE: ExplainerSectionEnum.HOSPICE_CARE,
}

export default function getExplainer(section, patient: any) {
  const ageRange = getAgeRange(patient?.age)
  const cprUsers = getCprUsers()
  const cprStatsByAgeRange = getCprStatsByAgeRange()
  const cprSurvivalRates = getCprSurvivalRates()

  const EXPLAINERS = {
    [EXPLAINER_SECTION_ENUMS.CPR]: [
      {
        key: '1',
        question: i18n.t('learnAboutCPR.whatExactlyIsIt'),
        answer: (
          <>
            <AnswerText>{i18n.t('learnAboutCPR.yourHeartPumpsBlood')}</AnswerText>
            <AnswerText>{i18n.t('learnAboutCPR.cprIsAnEmergency')}</AnswerText>
          </>
        ),
      },
      {
        question: i18n.t('learnAboutCPR.whyIsItGood'),
        answer: (
          <>
            <AnswerText>{i18n.t('learnAboutCPR.forYoungerHealthier')}</AnswerText>
          </>
        ),
      },
      {
        question: i18n.t('learnAboutCPR.whyIsItBad'),
        answer: (
          <>
            <AnswerText>{i18n.t('learnAboutCPR.forOlderPeople')}</AnswerText>
          </>
        ),
      },
      {
        question: i18n.t('learnAboutCPR.whoIsItHelpfulFor'),
        answer: (
          <>
            <AnswerText>
              <ul>
                <li>{i18n.t('learnAboutCPR.youngerPeople')}</li>
                <li>{i18n.t('learnAboutCPR.healthierPeople')}</li>
                <li>{i18n.t('learnAboutCPR.peopleWhoAreIndependent')}</li>
              </ul>
            </AnswerText>
          </>
        ),
      },
      {
        question: i18n.t('learnAboutCPR.whoIsItNotHelpful'),
        answer: (
          <>
            <AnswerText>
              <ul>
                <li>{i18n.t('learnAboutCPR.olderPeople')}</li>
                <li>{i18n.t('learnAboutCPR.peopleWithOtherMajor')}</li>
                <li>{i18n.t('learnAboutCPR.peopleWhoWeaker')}</li>
              </ul>
            </AnswerText>
          </>
        ),
      },
      {
        question: i18n.t('learnAboutCPR.whatHappensIf'),
        answer: (
          <>
            <AnswerText>{i18n.t('learnAboutCPR.ifYourHearStops')}</AnswerText>
          </>
        ),
      },
    ],
    [EXPLAINER_SECTION_ENUMS.MECHANICAL_VENTILATION]: [
      {
        question: i18n.t('learnAboutMechanicalVent.whatExactlyIsIt'),
        answer: (
          <>
            <AnswerText>{i18n.t('learnAboutMechanicalVent.yourLungsHelpYou')}</AnswerText>
            <AnswerText>{i18n.t('learnAboutMechanicalVent.yourDoctorsMightTry')}</AnswerText>
            <AnswerText>{i18n.t('learnAboutMechanicalVent.duringThisLifeSupport')}</AnswerText>
            <AnswerText>{i18n.t('learnAboutMechanicalVent.youMayOnlyNeed')}</AnswerText>
          </>
        ),
      },
      {
        question: i18n.t('learnAboutMechanicalVent.whyIsItGood'),
        answer: (
          <>
            <AnswerText>{i18n.t('learnAboutMechanicalVent.forPatientsWhoHave')}</AnswerText>
          </>
        ),
      },
      {
        question: i18n.t('learnAboutMechanicalVent.whyIsItBad'),
        answer: (
          <>
            <AnswerText>{i18n.t('learnAboutMechanicalVent.patientsWillBeBedBound')}</AnswerText>
            <AnswerText>{i18n.t('learnAboutMechanicalVent.forPatientsWhoHavePermanent')}</AnswerText>
          </>
        ),
      },
      {
        question: i18n.t('learnAboutMechanicalVent.whoUsuallyHelpfulFor'),
        answer: (
          <>
            <AnswerText>
              <ul>
                <li>{i18n.t('learnAboutMechanicalVent.peopleWithACondition')} </li>
                <li>{i18n.t('learnAboutMechanicalVent.peopleWhoAreIndependent')} </li>
              </ul>
            </AnswerText>
          </>
        ),
      },
      {
        question: i18n.t('learnAboutMechanicalVent.whoIsItUsually'),
        answer: (
          <>
            <AnswerText>
              <ul>
                <li>{i18n.t('learnAboutMechanicalVent.olderPeople')}</li>
                <li>{i18n.t('learnAboutMechanicalVent.peopleWithOtherMajor')}</li>
                <li>{i18n.t('learnAboutMechanicalVent.peopleWhoAreAlready')} </li>
                <li>{i18n.t('learnAboutMechanicalVent.peopleWhoHaveSevere')}</li>
              </ul>
            </AnswerText>
          </>
        ),
      },
      {
        question: i18n.t('learnAboutMechanicalVent.whatHappensIfYou'),
        answer: (
          <>
            <AnswerText>{i18n.t('learnAboutMechanicalVent.ifYouAreNotAble')}</AnswerText>
          </>
        ),
      },
      /**
       * EXAMPLE OF A QUESTION WITH SUB QUESTIONS
       */
      // {
      //   question: 'I have sub questions',
      //   subQuestions: [
      //     {
      //       question: 'Sub question 1',
      //       answer: (
      //         <>
      //           <AnswerText>
      //             For patients who have a temporary or short illness that can be
      //             treated or cured (such as an infection like pneumonia), and for
      //             patients who are younger or without many permanent conditions,
      //             invasive mechanical ventilation can be life-saving.
      //           </AnswerText>
      //         </>
      //       ),
      //     },
      //     {
      //       question: 'Sub question 2',
      //       answer: 'Hello #2',
      //     },
      //   ],
      // },
    ],
    [EXPLAINER_SECTION_ENUMS.DIALYSIS]: [
      {
        question: i18n.t('learnAboutDialysis.whatExactlyIsIt'),
        answer: (
          <>
            <AnswerText>{i18n.t('learnAboutDialysis.yourKidneysAreOrgans')}</AnswerText>
            <AnswerText>{i18n.t('learnAboutDialysis.dialysisIsAProcedure')}</AnswerText>
          </>
        ),
      },
      {
        question: i18n.t('learnAboutDialysis.whyIsItGood'),
        answer: (
          <>
            <AnswerText>{i18n.t('learnAboutDialysis.dialysisIsAbleToClean')}</AnswerText>
          </>
        ),
      },
      {
        question: i18n.t('learnAboutDialysis.whyIsItBad'),
        answer: (
          <>
            <AnswerText>{i18n.t('learnAboutDialysis.thereAreSomeRisks')}</AnswerText>
          </>
        ),
      },
      {
        question: i18n.t('learnAboutDialysis.whoIsItUsuallyHelpful'),
        answer: (
          <>
            <AnswerText>{i18n.t('learnAboutDialysis.dialysisIsHelpful')}</AnswerText>
          </>
        ),
      },
      {
        question: i18n.t('learnAboutDialysis.whoIsItUsuallyNotHelpful'),
        answer: (
          <>
            <AnswerText>{i18n.t('learnAboutDialysis.patientsWithOther')}</AnswerText>
          </>
        ),
      },
      {
        question: i18n.t('learnAboutDialysis.whatHappensIfYouDontGet'),
        answer: (
          <>
            <AnswerText>{i18n.t('learnAboutDialysis.ifYouDontGetDialysis')}</AnswerText>
          </>
        ),
      },
    ],
    [EXPLAINER_SECTION_ENUMS.FEEDING_TUBE]: [
      {
        question: i18n.t('learnAboutArtificialNutrition.whatExactlyIsIt'),
        answer: (
          <>
            <AnswerText>{i18n.t('learnAboutArtificialNutrition.youMayDevelopACondition')}</AnswerText>
            <AnswerText>{i18n.t('learnAboutArtificialNutrition.feedingTubesAreNarrow')}</AnswerText>
          </>
        ),
      },
      {
        question: i18n.t('learnAboutArtificialNutrition.whyIsItGood'),
        answer: (
          <>
            <AnswerText>{i18n.t('learnAboutArtificialNutrition.feedingTubesCanBeImportant')}</AnswerText>
          </>
        ),
      },
      {
        question: i18n.t('learnAboutArtificialNutrition.whyIsItBad'),
        answer: (
          <>
            <AnswerText>{i18n.t('learnAboutArtificialNutrition.theFeedingTubesThatGo')}</AnswerText>
          </>
        ),
      },
      {
        question: i18n.t('learnAboutArtificialNutrition.whoIsItUsually'),
        answer: (
          <>
            <AnswerText>{i18n.t('learnAboutArtificialNutrition.patientsWhoAreRequiring')}</AnswerText>
          </>
        ),
      },
      {
        question: i18n.t('learnAboutArtificialNutrition.whoIsItUsuallyNotHelpful'),
        answer: (
          <>
            <AnswerText>{i18n.t('learnAboutArtificialNutrition.patientsWithTerminal')}</AnswerText>
          </>
        ),
      },
      {
        question: i18n.t('learnAboutArtificialNutrition.whatHappensIfYouDontGet'),
        answer: (
          <>
            <AnswerText>{i18n.t('learnAboutArtificialNutrition.patientsWhoDontReceive')}</AnswerText>
          </>
        ),
      },
    ],
    [EXPLAINER_SECTION_ENUMS.PALLIATIVE_CARE]: [
      {
        question: i18n.t('palliativeCareFaq.whoIsPalliativeCareFor'),
        answer: (
          <>
            <AnswerText>{i18n.t('palliativeCareFaq.whoIsPalliativeCareForBody')}</AnswerText>
          </>
        ),
      },
      {
        question: i18n.t('palliativeCareFaq.whatDoesPalliative'),
        answer: (
          <>
            <AnswerText>{i18n.t('palliativeCareFaq.whatDoesPalliativeBody')}</AnswerText>
          </>
        ),
      },
      {
        question: i18n.t('palliativeCareFaq.howWillPalliativeCareHelp'),
        answer: (
          <>
            <AnswerText>{i18n.t('palliativeCareFaq.howWillPalliativeCareHelpBody')}</AnswerText>
          </>
        ),
      },
      {
        question: i18n.t('palliativeCareFaq.isSameHospice'),
        answer: (
          <>
            <AnswerText>{i18n.t('palliativeCareFaq.isSameHospiceBody')}</AnswerText>
          </>
        ),
      },
      {
        question: i18n.t('palliativeCareFaq.palliativeMedicalTeamGiving'),
        answer: (
          <>
            <AnswerText>{i18n.t('palliativeCareFaq.palliativeMedicalTeamGivingBody')}</AnswerText>
          </>
        ),
      },
      {
        question: i18n.t('palliativeCareFaq.whereDoReceive'),
        answer: (
          <>
            <AnswerText>{i18n.t('palliativeCareFaq.whereDoReceiveBody')}</AnswerText>
          </>
        ),
      },
    ],
    [EXPLAINER_SECTION_ENUMS.HOSPICE_CARE]: [
      {
        question: i18n.t('hospiceCareFaq.whoIsHospiceFor'),
        answer: (
          <>
            <AnswerText>{i18n.t('hospiceCareFaq.whoIsHospiceForBody')}</AnswerText>
          </>
        ),
      },
      {
        question: i18n.t('hospiceCareFaq.whatDoesHospiceDo'),
        answer: (
          <>
            <AnswerText>{i18n.t('hospiceCareFaq.whatDoesHospiceDoBody')}</AnswerText>
          </>
        ),
      },
      {
        question: i18n.t('hospiceCareFaq.howWillHospiceHelpMe'),
        answer: (
          <>
            <AnswerText>{i18n.t('hospiceCareFaq.howWillHospiceHelpMeBody')}</AnswerText>
          </>
        ),
      },
      {
        question: i18n.t('hospiceCareFaq.whereDoIReceiveHospice'),
        answer: (
          <>
            <AnswerText>{i18n.t('hospiceCareFaq.whereDoIReceiveHospiceBody')}</AnswerText>
          </>
        ),
      },
      {
        question: i18n.t('hospiceCareFaq.willHospiceHastenMyDeath'),
        answer: (
          <>
            <AnswerText>{i18n.t('hospiceCareFaq.willHospiceHastenMyDeathBody')}</AnswerText>
          </>
        ),
      },
      {
        question: i18n.t('hospiceCareFaq.signsHospiceMightBeAppropriate'),
        answer: (
          <>
            <AnswerText>{i18n.t('hospiceCareFaq.signsHospiceMightBeAppropriateBody')}</AnswerText>
          </>
        ),
      },
    ],
  }

  const cprTextByAgeRange = getCprTextByAgeRange()

  if (patient?.guideTemplate?.name !== 'WF_Path1') {
    EXPLAINERS[EXPLAINER_SECTION_ENUMS.CPR] = [
      ...EXPLAINERS[EXPLAINER_SECTION_ENUMS.CPR],
      {
        key: 'PATIENT_CLICKED_AGE_SPECIFIC_DATA',
        question: i18n.t('cprAgeGroupVideo.howDoesCprAffect', { ageGroup: ageRange }),
        answer: (
          <>
            {/* <AnswerText>{cprStatsByAgeRange?.[ageRange as any]}</AnswerText> */}
            <AnswerText>{i18n.t('cprAgeGroupVideo.forPeopleInYourAge')}</AnswerText>
            <AnswerText
              style={{
                margin: 'auto',
                textAlign: 'center',
                fontSize: 24,
                fontWeight: 600,
                color: theme.colors.primary3,
                marginBottom: 24,
                marginTop: 16,
              }}
            >
              {ageRange && cprSurvivalRates?.[ageRange]}
            </AnswerText>
            {ageRange && cprUsers?.[ageRange]}
            {ageRange && cprTextByAgeRange?.[ageRange]}
            <AnswerText>{i18n.t('cprAgeGroupVideo.healthierMoreIndependent')}</AnswerText>
            <Caption>{i18n.t('cprAgeGroupVideo.sources')}</Caption>
            <Caption>
              Resuscitation. 2010 Mar;81(3):302-11. doi: 10.1016/j.resuscitation.2009.11.021. Epub 2010 Jan 4.
            </Caption>
            <Caption>Arch Intern Med. 1999 Feb 8;159(3):249-54. doi: 10.1001/archinte.159.3.249.</Caption>
          </>
        ),
      },
      {
        key: 'PATIENT_CLICKED_OTHER_AGE_RANGES_DATA',
        question: i18n.t('cprAgeGroupVideo.howCprAffectOtherAges'),
        answer: (
          <>
            <ul>
              {Object.keys(cprStatsByAgeRange)?.map((key) => {
                if (key !== ageRange) {
                  return (
                    <li key={key}>
                      <AnswerText>{cprStatsByAgeRange[key]}</AnswerText>
                    </li>
                  )
                }
                return null
              })}
            </ul>
            <AnswerText>{i18n.t('cprAgeGroupVideo.whoAreMoreFrail')}</AnswerText>

            <AnswerText>{i18n.t('cprAgeGroupVideo.healthierMoreIndependentPeople')}</AnswerText>

            <Caption>{i18n.t('cprAgeGroupVideo.sources')}</Caption>
            <Caption>
              Resuscitation. 2010 Mar;81(3):302-11. doi: 10.1016/j.resuscitation.2009.11.021. Epub 2010 Jan 4.
            </Caption>
            <Caption>Arch Intern Med. 1999 Feb 8;159(3):249-54. doi: 10.1001/archinte.159.3.249.</Caption>
          </>
        ),
      },
    ]
  }

  return EXPLAINERS[section]
}
