import { Text, View } from '@react-pdf/renderer'
import s from 'components/common/PlanDoc/KodaContent/styles'
import AnswerLine from 'components/common/PlanDoc/KodaContent/AnswerLine'
import { GuideQuestionIdEnum, Patient } from 'generated/graphql'
import i18n from 'lib/helpers/i18n'
import flatten from 'lib/helpers/flatten'

interface MedicalCareScenariosProps {
  data?: Patient
}

export default function OtherImportantInformation({ data }: MedicalCareScenariosProps) {
  const guideQuestions = flatten(data?.guideTemplate?.sections?.map((item) => item.questionIds))
  return (
    <View style={{ ...s.sectionContainer, paddingBottom: 16 }} break>
      <Text style={{ ...s.sectionTitle, marginTop: 2, marginBottom: 0 }}>{i18n.t('Other Important Information')}</Text>

      {guideQuestions?.includes(GuideQuestionIdEnum.SpiritualAndReligiousBeliefs) && (
        <>
          <Text style={{ ...s.formLabel, marginTop: 32 }}>
            {i18n.t(
              'spiritualAndReligiousBeliefs',
              `Do you have spiritual or religious beliefs you want your medical
          decision maker and those taking care of
          you to know? They can be rituals, sacraments, denying blood product
          transfusions and more.`
            )}
          </Text>
          <AnswerLine text={data?.[GuideQuestionIdEnum.SpiritualAndReligiousBeliefs] || ''} numberOfLines={4} />
        </>
      )}
      {guideQuestions?.includes(GuideQuestionIdEnum.LifeAndValues) && (
        <>
          <Text style={{ ...s.formLabel, marginTop: 32 }}>
            {i18n.t(
              'lifeAndValues',
              `Is there anything you want to share about your life and values to help
            your medical decision maker and health
            care providers make decisions about your health care. This might
            include family history, experiences with health care, cultural
            background, career, social support system and more.`
            )}
          </Text>
          <AnswerLine text={data?.[GuideQuestionIdEnum.LifeAndValues] || ''} numberOfLines={4} />
        </>
      )}
      {guideQuestions?.includes(GuideQuestionIdEnum.ReceiveCareOther) && (
        <>
          <Text style={{ ...s.formLabel, marginTop: 32 }}>
            {i18n.t(
              'receiveCareOther',
              `Is there any additional information about where you want or do not
            want to receive care? Or any extra information about quality of life
            preferences that you would like to provide?`
            )}
          </Text>
          <AnswerLine text={data?.[GuideQuestionIdEnum.ReceiveCareOther] || ''} numberOfLines={4} />
        </>
      )}
      {guideQuestions?.includes(GuideQuestionIdEnum.AdditionalCarePreferences) && (
        <>
          <Text style={{ ...s.formLabel, marginTop: 32 }}>
            {i18n.t(
              'additionalCarePreferences',
              `What else should your medical providers and medical decision maker
            know about you and your choices for medical care?`
            )}
          </Text>
          <AnswerLine text={data?.[GuideQuestionIdEnum.AdditionalCarePreferences] || ''} numberOfLines={4} />
        </>
      )}
    </View>
  )
}
