import { ApolloClient, HttpLink, ApolloLink, InMemoryCache } from '@apollo/client'
import authLink from './links/authLink'
import apolloLogger from 'apollo-link-logger'
import config from 'lib/config'
import { onError } from '@apollo/client/link/error'
import * as Sentry from '@sentry/browser'
import { SentryLink } from 'apollo-link-sentry'
import sentryTransactionIdLink from './links/sentryTransactionIdLink'

const httpLink = new HttpLink({
  uri: config.GRAPHQL_API_ENDPOINT,
  headers: {
    'keep-alive': 'true',
  },
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
  // only capture apollo errors not in production
  console.log(graphQLErrors, networkError)
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      Sentry.captureMessage(message)
      Sentry.setExtra('locations', locations)
      Sentry.setExtra('path', path)
    })
  }

  if (networkError) {
    Sentry.captureException(networkError)
  }
})

/**
 * Sentry apollo link only adds breadcrumbs, it doesn't do the actual logging. So we still need to setup errorLink
 */
const sentryLink = new SentryLink({
  setTransaction: true,
  setFingerprint: true,
  uri: config.GRAPHQL_API_ENDPOINT,
  attachBreadcrumbs: {
    includeQuery: true,
    includeVariables: config.NODE_ENV !== 'production', // only include variables if we're not in production
    includeFetchResult: config.NODE_ENV !== 'production',
    includeError: true,
    includeCache: config.NODE_ENV !== 'production',
  },
})

// for some reason, need to pass uri in via links like https://github.com/apollographql/apollo-link/issues/1246#issuecomment-656092271
let links = [sentryTransactionIdLink, sentryLink, errorLink, authLink, apolloLogger, httpLink] // if we're in development mode, we'll use apolloLogger so we can see queries/mutations logged in the console

// if we're in produciton, we don't want to use apolloLogger
if (config.NODE_ENV === 'production') {
  links = [sentryTransactionIdLink, sentryLink, errorLink, authLink, httpLink]
}

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: ApolloLink.from(links),
  connectToDevTools: true,
})

export default client
