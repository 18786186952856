import { Patient, GuideQuestionIdEnum, useRevertEsignMutation } from 'generated/graphql'
import { ModalOverlay } from 'components/common/KodaModal'
import Button from 'components/common/Button'
import ModalTitle from 'components/text/ModalTitle'
import Caption from 'components/text/Caption'
import styled from 'styled-components'
import warningSVG from './warning.svg'
import useSavePatient, { saveFieldsToRemove } from 'lib/hooks/useSavePatient'
import ResignNoticeModal from 'components/common/ResignNoticeModal'
import message from 'components/common/message'
import { useState } from 'react'
import { omit } from 'lodash'
import i18n from 'lib/helpers/i18n'
import { Trans } from 'react-i18next'
import translateApolloError from 'lib/helpers/translateApolloError'

export enum DeleteMDMType {
  mdm = 'MDM',
  altMDM = 'altMDM',
  thirdMDM = 'thirdMDM',
}

const getTitle = (type: DeleteMDMType): string => {
  if (type === DeleteMDMType.mdm) {
    return i18n.t('decisionMakers.decisionMaker', `Decision Maker`)
  }
  if (type === DeleteMDMType.altMDM) {
    return i18n.t('decisionMakers.alternateDecisionMaker', `Alternate Decision Maker`)
  }
  if (type === DeleteMDMType.thirdMDM) {
    return i18n.t('decisionMakers.thirdDecisionMaker', `Third Decision Maker`)
  }
  return ''
}

const getName = (plan: Patient, type: DeleteMDMType): string => {
  if (
    type === DeleteMDMType.mdm &&
    plan[GuideQuestionIdEnum.DecisionMakerFirstName] &&
    plan[GuideQuestionIdEnum.DecisionMakerLastName]
  ) {
    return `${plan[GuideQuestionIdEnum.DecisionMakerFirstName]} ${plan[GuideQuestionIdEnum.DecisionMakerLastName]}`
  }
  if (
    type === DeleteMDMType.altMDM &&
    plan[GuideQuestionIdEnum.AltDecisionMakerFirstName] &&
    plan[GuideQuestionIdEnum.AltDecisionMakerLastName]
  ) {
    return `${plan[GuideQuestionIdEnum.AltDecisionMakerFirstName]} ${
      plan[GuideQuestionIdEnum.AltDecisionMakerLastName]
    }`
  }
  if (
    type === DeleteMDMType.thirdMDM &&
    plan[GuideQuestionIdEnum.ThirdDecisionMakerFirstName] &&
    plan[GuideQuestionIdEnum.ThirdDecisionMakerLastName]
  ) {
    return `${plan[GuideQuestionIdEnum.ThirdDecisionMakerFirstName]} ${
      plan[GuideQuestionIdEnum.ThirdDecisionMakerLastName]
    }`
  }
  return 'this decision maker'
}

const getPlanData = (type: DeleteMDMType, plan: Patient) => {
  let planData = {
    ...plan,
  }

  if (type === DeleteMDMType.mdm) {
    planData[GuideQuestionIdEnum.DoYouKnowDecisionMaker] = 'no'
    planData[GuideQuestionIdEnum.DecisionMakerEmail] = null
    planData[GuideQuestionIdEnum.DecisionMakerFirstName] = null
    planData[GuideQuestionIdEnum.DecisionMakerName] = null
    planData[GuideQuestionIdEnum.DecisionMakerLastName] = null
    planData[GuideQuestionIdEnum.DecisionMakerPhone] = null
    planData[GuideQuestionIdEnum.DecisionMakerRelationship] = null
    planData[GuideQuestionIdEnum.DecisionMakerRelationshipOther] = null
    planData[GuideQuestionIdEnum.DecisionMakerStrictness] = null
    planData[GuideQuestionIdEnum.WhenCanDecisionMakerDecide] = null
    planData[GuideQuestionIdEnum.AdditionalMdmDirections] = null
    // address
    planData[GuideQuestionIdEnum.DecisionMakerAddressStreet] = null
    planData[GuideQuestionIdEnum.DecisionMakerAddressCity] = null
    planData[GuideQuestionIdEnum.DecisionMakerAddressState] = null
    planData[GuideQuestionIdEnum.DecisionMakerAddressZip] = null
  }

  if (type === DeleteMDMType.altMDM) {
    planData[GuideQuestionIdEnum.WantsAltDecisionMaker] = 'no'
    planData[GuideQuestionIdEnum.AltDecisionMakerEmail] = null
    // planData[GuideQuestionIdEnum.AltDecisionMakerName] = null;
    planData[GuideQuestionIdEnum.AltDecisionMakerFirstName] = null
    planData[GuideQuestionIdEnum.AltDecisionMakerLastName] = null
    planData[GuideQuestionIdEnum.AltDecisionMakerPhone] = null
    planData[GuideQuestionIdEnum.AltDecisionMakerRelationship] = null
    planData[GuideQuestionIdEnum.AltDecisionMakerRelationshipOther] = null
    // address
    planData[GuideQuestionIdEnum.AltDecisionMakerAddressStreet] = null
    planData[GuideQuestionIdEnum.AltDecisionMakerAddressCity] = null
    planData[GuideQuestionIdEnum.AltDecisionMakerAddressState] = null
    planData[GuideQuestionIdEnum.AltDecisionMakerAddressZip] = null
  }

  if (type === DeleteMDMType.thirdMDM) {
    planData[GuideQuestionIdEnum.WantsThirdDecisionMaker] = 'no'
    planData[GuideQuestionIdEnum.ThirdDecisionMakerEmail] = null
    planData[GuideQuestionIdEnum.ThirdDecisionMakerPhone] = null
    planData[GuideQuestionIdEnum.ThirdDecisionMakerFirstName] = null
    planData[GuideQuestionIdEnum.ThirdDecisionMakerLastName] = null
    planData[GuideQuestionIdEnum.ThirdDecisionMakerRelationship] = null
    planData[GuideQuestionIdEnum.ThirdDecisionMakerRelationshipOther] = null
    // address
    planData[GuideQuestionIdEnum.ThirdDecisionMakerAddressStreet] = null
    planData[GuideQuestionIdEnum.ThirdDecisionMakerAddressCity] = null
    planData[GuideQuestionIdEnum.ThirdDecisionMakerAddressState] = null
    planData[GuideQuestionIdEnum.ThirdDecisionMakerAddressZip] = null
  }

  return planData
}

function useConfirmDelete() {
  const { saving, onSavePatient } = useSavePatient()
  const onConfirmDelete = async (type, plan) => {
    const planData = getPlanData(type, plan)

    try {
      await onSavePatient(planData)
      return planData
    } catch (err: any) {
      message.error(translateApolloError(err))
    }
  }

  return [onConfirmDelete, { loading: saving }] as const
}

const Modal = styled.div`
  background: #fff;
  padding: 16px;
  text-align: center;
  border-radius: 5px;
  max-width: 100%;
`

const ModalContent = styled.div`
  margin: 16px;
  margin-bottom: 8px;
  width: 400px;
  max-width: 100%;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    margin: 0px;
  }
`

interface ConfirmDeleteProps {
  onConfirm: (plan: Patient) => void
  onCancel: () => void
  plan: Patient
  type: DeleteMDMType
}

export default function ConfirmRemoveMdmModal({ onConfirm, onCancel, plan, type }: ConfirmDeleteProps) {
  const name = getName(plan, type)
  const title = getTitle(type)
  const [onConfirmDelete, { loading: deleting }] = useConfirmDelete()
  const [revertEsignMutation, { loading: reverting }] = useRevertEsignMutation()
  const [showEditWarning, setShowEditWarning] = useState<DeleteMDMType | false>(false)

  const handleOnCancel = () => {
    setShowEditWarning(false)
    onCancel()
  }

  if (showEditWarning) {
    return (
      <ResignNoticeModal
        onResign={async () => {
          try {
            const planData = getPlanData(showEditWarning, plan)
            await revertEsignMutation({
              variables: {
                params: omit({ ...planData }, saveFieldsToRemove),
              },
            })
            message.info(
              i18n.t('ConfirmRemoveMdmModal.needResigning', `Answer successfully saved. Document needs re-signing.`)
            )
            setShowEditWarning(false)
            onConfirm(planData)
          } catch (err: any) {
            message.error(translateApolloError(err))
          }
        }}
        onCancel={handleOnCancel}
        loading={reverting}
        visible={showEditWarning ? true : false}
      />
    )
  }

  return (
    <ModalOverlay>
      <Modal>
        <ModalContent>
          <img src={warningSVG} alt="Warning" height="32" style={{ marginBottom: 16 }} />
          <ModalTitle>
            <Trans i18nKey={'ConfirmRemoveMdmModal.title'}>
              Would you like to delete {{ name }} as your {{ title }}?
            </Trans>
          </ModalTitle>
          <Caption>
            {i18n.t(
              'ConfirmRemoveMdmModal.subtitle',
              `Note: all information related to this decision maker will be deleted`
            )}
          </Caption>
          <div style={{ marginTop: 24 }}>
            <Button onClick={handleOnCancel} disabled={deleting} grey style={{ marginRight: 16 }}>
              {i18n.t('ConfirmRemoveMdmModal.no', `No`)}
            </Button>
            <Button
              onClick={async () => {
                if (plan?.hasOfficialPlan) {
                  return setShowEditWarning(type)
                }
                const planData = await onConfirmDelete(type, plan)
                onConfirm(planData as Patient)
              }}
              disabled={deleting}
              loading={deleting}
              data-testid="accept-delete"
            >
              {i18n.t('ConfirmRemoveMdmModal.yes', `Yes, delete`)}
            </Button>
          </div>
        </ModalContent>
      </Modal>
    </ModalOverlay>
  )
}
