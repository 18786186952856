import { Page, Text, View, Document } from '@react-pdf/renderer'
import styled from 'styled-components'
import ReactText from 'components/text/Text'
import config from 'lib/config'
import { StyleSheet } from '@react-pdf/renderer'
import theme from 'lib/theme'
import { PlanFontFamily } from 'components/common/PlanDoc/PlanFontFamily'
import i18n from 'lib/helpers/i18n'

const Title = styled.h2``
const SubHeading = styled.h3`
  margin-top: 24px;
`

const s = StyleSheet.create({
  body: {
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 10,
    textAlign: 'center',
    margin: 0,
    marginTop: 16,
    marginBottom: 4,
    color: theme.colors.neutral1,
    fontFamily: PlanFontFamily.bold,
  },
  subtitle: {
    fontSize: 10,
    marginTop: 6,
    color: theme.colors.neutral1,
    marginLeft: 0,
    fontFamily: PlanFontFamily.bold,
  },
  text: {
    fontSize: 10,
    marginTop: 3,
    color: theme.colors.neutral1,
    marginLeft: 0,
    fontFamily: PlanFontFamily.regular,
  },
})

const title = i18n
  .t(
    'SignatureFormLegalConsentPDF.title',
    'CONSENT AND NOTICE REGARDING ELECTRONIC SIGNATURES AND COMMUNICATIONS FOR Koda Health Inc.'
  )
  .replace(/&apos;/g, `"`)

const clause1Title = i18n
  .t('SignatureFormLegalConsentPDF.clause1Title', '1. Electronic Signatures of Agreements.')
  .replace(/&apos;/g, `"`)

const clause1Text = i18n
  .t(
    'SignatureFormLegalConsentPDF.clause1Text',
    `
By selecting the "Accept and Sign" button, you are signing this consent electronically. You agree that your
electronic signature is the legal equivalent of your manual signature on this consent, and on any other
terms and conditions or agreements that you enter into with Koda Health, Inc. (“Koda Health”) by
electronically signing (each, an “Electronic Agreement”). Your electronic signature (“E-Signature”) may take
the form of selecting or clicking on buttons or checkboxes that say “Agree and Sign”, “I agree”, “I accept”,
or similar terms, or by otherwise manifesting your intent to agree to the applicable Electronic Agreement.
You agree that such E-Signature is not a “digital signature” and no certification authority or other third
party verification is necessary to validate your E-Signature or make your E-Signature or any resulting
contract legally effective. You represent that you are authorized to enter into any Electronic Agreement for
all persons who own or are authorized to access any of your accounts and that such persons will be bound by
the terms of this and any other Electronic Agreement you enter into.
`
  )
  .replace(/&apos;/g, `"`)

const clause2Title = i18n
  .t('SignatureFormLegalConsentPDF.clause2Title', '2. Electronic Signature of Documents.')
  .replace(/&apos;/g, `"`)

const clause2Text = i18n.t(
  'SignatureFormLegalConsentPDF.clause2Text',
  `
  In certain states, the Koda Health platform may allow you to electronically or digitally sign your advance
  directives or similar documents (“Documents”). We offer this functionality as a convenience, and make good
  faith efforts to ensure such functionality conforms with any applicable legal requirements in the given
  jurisdiction. That said, due to factors including changing laws and regulatory guidance, we cannot guarantee
  that an electronic or digital signature applied using our platform will necessarily be held valid by any
  particular physician, governmental entity, or other party. You are always welcome to physically print and
  sign your Documents. If you choose to use our electronic or digital signature functionality to sign your
  Documents, you release us from any claims that such signatures are not legally valid.
`
)

const clause3Title = i18n
  .t('SignatureFormLegalConsentPDF.clause3Title', '3. Consent to Electronic Delivery.')
  .replace(/&apos;/g, `"`)

const clause3Text = i18n
  .t(
    'SignatureFormLegalConsentPDF.clause3Text',
    `
  You specifically agree to receive and/or obtain any and all Koda Health related "Electronic Communications"
  (defined below). The term "Electronic Communications" includes, but is not limited to, any and all current
  and future notices and/or disclosures that various federal and/or state laws or regulations require that we
  provide to you, as well as any Electronic Agreements, Documents, and other statements, data, records and any
  other communications regarding your relationship with Koda Health. You acknowledge that, for your records,
  you are able to use Koda Health online services to retain Electronic Communications by printing and/or
  downloading and saving this consent and all other Electronic Communications, documents, or records that you
  agree to (not merely witness) using your E-Signature. You accept Electronic Communications provided via Koda
  Health as reasonable and proper notice, for the purpose of any and all laws, rules, and regulations, and
  agree that such electronic form fully satisfies any requirement that such communications be provided to you
  in writing or in a form that you may keep.
`
  )
  .replace(/&apos;/g, `"`)

const clause4Title = i18n
  .t('SignatureFormLegalConsentPDF.clause4Title', '4. Paper version of Electronic Communications.')
  .replace(/&apos;/g, `"`)

const clause4Text = i18n
  .t(
    'SignatureFormLegalConsentPDF.clause4Text',
    `
  You may request a paper version of an Electronic Communication. You acknowledge that Koda Health reserves
  the right to charge you a reasonable fee for the production and mailing of paper versions of Electronic
  Communications. To request a paper copy of an Electronic Communication contact us at ${config.supportPhone}{' '}
  or ${config.supportEmail}.
`,
    { supportEmail: config.supportEmail, supportPhone: config.supportPhone }
  )
  .replace(/&apos;/g, `"`)

const clause5Title = i18n
  .t('SignatureFormLegalConsentPDF.clause5Title', '5. Revocation of electronic delivery.')
  .replace(/&apos;/g, `"`)

const clause5Text = i18n
  .t(
    'SignatureFormLegalConsentPDF.clause5Text',
    `
  You have the right to withdraw your consent to receive/obtain communications via Koda Health at any time.
  You acknowledge that Koda Health reserves the right to restrict or terminate your access to Koda Health
  services if you withdraw your consent to receive Electronic Communications. If you wish to withdraw your
  consent, contact us at ${config.supportPhone} or ${config.supportEmail}.
`,
    { supportEmail: config.supportEmail, supportPhone: config.supportPhone }
  )
  .replace(/&apos;/g, `"`)

const clause6Title = i18n
  .t('SignatureFormLegalConsentPDF.clause6Title', '6. Controlling Agreement.')
  .replace(/&apos;/g, `"`)

const clause6Text = i18n
  .t(
    'SignatureFormLegalConsentPDF.clause6Text',
    'This consent supplements and modifies any other agreements that you may have with Koda Health. To the extent that this consent and another agreement contain conflicting provisions, the provisions in this consent will control (with the exception of provisions in another agreement for an electronic service which provisions specify the necessary hardware, software and operating system, in which such other provision controls). All other obligations of the parties remain subject to the terms and conditions of any other agreement.'
  )
  .replace(/&apos;/g, `"`)

const clause6Text2 = i18n
  .t(
    'SignatureFormLegalConsentPDF.clause6Text2',
    'To consent to use of electronic signatures, and obtain electronic services and communications, indicate your consent to the terms and conditions of this Consent by clicking on the "Accept and Sign" button.'
  )
  .replace(/&apos;/g, `"`)

const clause6Text3 = i18n
  .t(
    'SignatureFormLegalConsentPDF.clause6Text3',
    'It is recommended that you print a copy of this consent, and any Electronic Agreements, Documents, and other Electronic Communications, for future reference.'
  )
  .replace(/&apos;/g, `"`)

export function LegalConsentPDF() {
  return (
    <Document title="Legal Consent Document">
      <Page>
        <View style={s.body}>
          <Text style={s.title}>{title}</Text>
          <Text style={s.subtitle}>{clause1Title}</Text>
          <Text style={s.text}>{clause1Text}</Text>
          <Text style={s.subtitle}>{clause2Title}</Text>
          <Text style={s.text}>{clause2Text}</Text>
          <Text style={s.subtitle}>{clause3Title}</Text>
          <Text style={s.text}>{clause3Text}</Text>
          <Text style={s.subtitle}>{clause4Title}</Text>
          <Text style={s.text}>{clause4Text}</Text>
          <Text style={s.subtitle}>{clause5Title}</Text>
          <Text style={s.text}>{clause5Text}</Text>
          <Text style={s.subtitle}>{clause6Title} </Text>
          <Text style={s.text}>{clause6Text}</Text>
          <Text style={s.subtitle}>{clause6Text2}</Text>
          <Text style={s.text}>{clause6Text3}</Text>
        </View>
      </Page>
    </Document>
  )
}

export default function LegalConsentContent() {
  return (
    <div id="signature-form-legal-text">
      <Title>{title}</Title>
      <SubHeading>{clause1Title}</SubHeading>
      <ReactText>{clause1Text}</ReactText>
      <SubHeading>{clause2Title}</SubHeading>
      <ReactText>{clause2Text}</ReactText>
      <SubHeading>{clause3Title}</SubHeading>
      <ReactText>{clause3Text}</ReactText>
      <SubHeading>{clause4Title}</SubHeading>
      <ReactText>{clause4Text}</ReactText>
      <SubHeading>{clause5Title}</SubHeading>
      <ReactText>{clause5Text}</ReactText>
      <SubHeading>{clause6Title}</SubHeading>
      <ReactText>{clause6Text}</ReactText>
      <SubHeading>{clause6Text2}</SubHeading>
      <ReactText>{clause6Text3}</ReactText>
    </div>
  )
}
