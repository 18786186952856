import styled from 'styled-components';

const getColor = ({type, style, theme}) => {
  switch (type) {
    case 'light':
      return theme.colors.neutral8;
    default:
      return theme.colors.neutral3;
  }
};

interface PageHeaderProps {
  type?: string;
}

const PageHeader = styled.span<PageHeaderProps>`
  min-height: 44px;
  font-family: ${(p) => p.theme.fontFamily};
  font-size: 24px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 1px;
  display: block;
  color: ${({type, style, theme}) => getColor({type, style, theme})};
  @media only screen and (max-width: ${({theme}) => theme.breakpoints.small}) {
    font-size: 20px;
  }
`;

PageHeader.defaultProps = {
  style: {},
  // size: null,
};

export default PageHeader;
