import styled from 'styled-components'
import Image from 'components/common/Image'
import {
    Link,
  } from "react-router-dom";
import theme from 'lib/theme/theme'
export const TitleIcon = styled(Image)`
height: 240;
width: 240px;
border-radius: 50%;
`
export const ButtonContainer = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
@media only screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
  div,
  button {
    width: 120px !important;
  }
}
`
export const BottomLink = styled(Link)`
font-size: 16px;
margin: 16px; 
text-decoration: underline;
color: ${(p) => p.theme.colors.primary2};
&:hover {
  text-decoration: underline;
}
`
export const Title = styled.h1`
font-family: 'Roboto';
font-style: normal;
font-weight: 500;
font-size: 24px;
line-height: 28px;
color: ${(p) => p.theme.colors.neutral1};
margin-bottom:32px;
@media only screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
  margin-bottom: 16px;
margin-top:32px;

}

`
export const BookACallText = styled.label`
display: block;
color: ${(p) => p.theme.colors.neutral1};
font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 21px;
margin-bottom: 20px;
a {
  font-size: 18px;
}
&:last-of-type {
  margin-bottom: 0px;
}
`
export const Header = styled.div`
position: absolute;
top: 0px;
height: 64px;
right: 0px;
left: 0px;
background: #fff;
z-index: 10;
display: flex;
align-items: center;
justify-content: center;
@media only screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
  margin-bottom: 0px;
}
`
export const Logo = styled.img`
max-width: 85%;
margin: auto;
margin-bottom: 9px;
margin-top: 16px;
display: block;
@media only screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
  margin-top: 36px;
  margin-bottom: 0px;
  z-index: 10000
}
`
export const SchedulerContainer = styled.div`
background: #fff;
padding: 0px 24px 24px 24px;
min-height: 400px;
border-radius: 5px;
display: flex;
position: relative;
align-items: center;
@media only screen and (max-width: ${({ theme }) => theme.breakpoints.mediumSmall}) {
  margin-top: 0px;
flex-direction: column-reverse;

}
`
export const Container = styled.div`
min-height: 100vh;
display: flex;
align-items: center;
justify-content: center;
width: 834px;
max-width: 95%;
padding-top: 2.5%;
padding-bottom: 2.5%;
@media only screen and (max-width: ${({ theme }) => theme.breakpoints.mediumSmall}) {
  padding-top: 80px;
  width:100%;
}
@media only screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
  width:100%;

}
`
export const HeaderContainer = styled.div`
@media only screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
  background: white;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  height: 60px;
  z-index: 1098;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin-bottom: 0px;
  }
}
`
export const LogoContainer = styled.div`
@media only screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
  background: white;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  height: 60px;
  z-index: 1098;
}
`

