import styled from 'styled-components'

const TestimonialText = styled.p`
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.2;
`

const Container = styled.div`
  padding: 0px 8px;
  display: flex;
`

const Image = styled.img`
  margin-left: 16px;
  height: 88px;
  position: relative;
  left: -16px;
  top: -8px;
`

const Quote = styled.span`
  color: ${(props) => props.theme.colors.primary3};
  font-size: 2.5rem;
  font-weight: 700;
  position: relative;
  top: 0.75rem;
  line-height: 0.15;
`

export default function Testimonial({ imageRight, text }) {
  return (
    <Container>
      <TestimonialText>
        <Quote>" </Quote>
        {text}
        <Quote> "</Quote>
      </TestimonialText>
      {imageRight && <Image src={imageRight} />}
    </Container>
  )
}
