import {useRef, useState, useEffect} from 'react';
import styled from 'styled-components';
import moment from 'moment';
import InputMask from 'react-input-mask';
import ButtonsContainer from '../ButtonsContainer';
import QuestionText from 'components/text/QuestionText';
import {ErrorText, QuestionsContainer, StepContainer} from '../index';
import Animated from 'components/common/Animated';
import { Patient } from 'generated/graphql';

const Input = styled.input`
  border: 0px;
  height: 50px;
  font-family: ${(p) => p.theme.fontFamily};
  font-size: 30px;
  font-weight: 400;
  letter-spacing: 1px;
  border-bottom: 1px solid ${(p) => p.theme.colors.neutral9};
  width: 100%;
  ::placeholder {
    color: ${(p) => p.theme.colors.neutral8};
  }
  @media only screen and (max-width: 1024px) {
    ::placeholder {
      font-size: 22px;
    }
  }
  @media only screen and (max-width: ${({theme}) => theme.breakpoints.small}) {
    font-size: 24px;
    ::placeholder {
      font-size: 13px;
      color: ${(p) => p.theme.colors.neutral7};
    }
  }
  &:focus {
    outline: 0;
    border-bottom: 0;
  }
`;

interface DateQuestionProps {
  text?: string | JSX.Element;
  required?: boolean;
  defaultValue?: string | number;
  onNext: (plan?: Patient) => void;
  onBack: () => void;
  nextButton: string;
  fieldName?: string;
  onChange: (e: { [x: string]: string | number }) => void;
  hideButtons?: boolean;
  editMode?: boolean;
  isMobile?: boolean;
}

export default function DateQuestion({
  text,
  required,
  defaultValue,
  onNext,
  onBack,
  nextButton,
  fieldName,
  onChange,
  hideButtons,
  editMode,
  isMobile,
}: DateQuestionProps) {
  const textInput = useRef<HTMLInputElement | undefined>();

  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!defaultValue && textInput?.current) {
      textInput?.current?.focus();
    }
  }, [fieldName, defaultValue]);

  return (
    <StepContainer key={fieldName} editMode={editMode} isMobile={isMobile}>
      <div style={{maxWidth: '100%'}}>
        <Animated>
          <QuestionText>{text}</QuestionText>
          <QuestionsContainer
            style={{width: !isMobile ? 620 : 400, maxWidth: '100%'}}
          >
            <InputMask
              mask="99/99/9999"
              value={defaultValue}
              onChange={(e) => {
                if (error) {
                  setError(null);
                }
                onChange({[fieldName as string]: e.target.value});
              }}
            >
              {(inputProps) => <Input {...inputProps} ref={textInput} />}
            </InputMask>
            {/* <Input
              placeholder={placeholder}
              value={defaultValue}
              ref={textInput}
              onChange={(e) => {
                if (error) {
                  setError(null);
                }
                // if this is a number in put and the value onChange is not a number, show an error
                if (inputType === 'number' && isNaN(e.target.value)) {
                  return setError('Must be a number');
                }
                // if
                if (inputType === 'number' && !isNaN(e.target.value)) {
                  return onChange({[fieldName]: parseInt(e.target.value || 1)});
                }
                // for all other inputs, just save the values
                onChange({[fieldName]: e.target.value});
              }}
            /> */}
            {error && <ErrorText>{error}</ErrorText>}
          </QuestionsContainer>
        </Animated>
        <ButtonsContainer
          onNext={() => {
            if (required && !defaultValue) {
              return setError('This field is required');
            }
            if (!moment(defaultValue).isValid) {
              return setError('That is not a valid date');
            }
            // make sure date is not greater than today
            if (moment(defaultValue).valueOf() > moment().valueOf()) {
              return setError('Your birthday can not be in the future');
            }
            // if all is good, we can call onNext to move to the next question
            onNext();
          }}
          onBack={onBack}
          hideButtons={hideButtons}
          nextButton={nextButton}
          hideNext={required && !defaultValue}
        />
      </div>
    </StepContainer>
  );
}

// import React from 'react';
// import styled from 'styled-components';
//
// import TextInput from 'components/inputs/TextInput';

// const Input = styled.input`
//   border: 0px;
//   height: 50px;
//   font-family: ${(p) => p.theme.fontFamily};
//   font-size: 30px;
//   font-weight: 400;
//   letter-spacing: 1px;
//   border-bottom: 1px solid ${(p) => p.theme.colors.neutral9};
//   width: 100%;
//   ::placeholder {
//     color: ${(p) => p.theme.colors.neutral8};
//   }
//   @media only screen and (max-width: 1024px) {
//     ::placeholder {
//       font-size: 22px;
//     }
//   }
//   @media only screen and (max-width: ${({theme}) => theme.breakpoints.small}) {
//     font-size: 24px;
//     ::placeholder {
//       font-size: 13px;
//       color: ${(p) => p.theme.colors.neutral7};
//     }
//   }
//   &:focus {
//     outline: 0;
//     border-bottom: 0;
//   }
// `;
