import { AdPartEnum, PlanSetting } from '../types'
import en from 'lib/helpers/locales/en/translation.json'
import { Text, View } from '@react-pdf/renderer'
import s from '../../../KodaContent/styles'

export default function getAK(t, defaultPartSettings): PlanSetting {
  const ak: PlanSetting = {
    state: 'Alaska',
    disclaimer: t(
      'planDoc.disclaimer',
      'Disclaimer: This document needs to be signed and have two witnesses sign as well or be notarized in order to be a legal document.'
    ),
    parts: [
      // Part 1
      {
        ...defaultPartSettings?.[AdPartEnum.health_care_choices],
      },
      // Part 2
      {
        ...defaultPartSettings?.[AdPartEnum.medical_decision_maker],
        description: t(
          'AK.medical_decision_maker.description',
          'This part is also known as a Medical Power of Attorney. It designates a person to make decisions for you when you are unable to speak for yourself. Your medical decision maker cannot be an owner, operator, or employee of the health care institution at which you are receiving care unless the individual is related to you by blood, marriage, or adoption.'
        ),
      },
      // Part 3
      {
        ...defaultPartSettings?.[AdPartEnum.sign_the_form],
        description: t(
          'AK.sign_the_form.description',
          'You can fill out Part 1, Part 2, or both, but you need to sign the form in Part 3. To be legal, two witnesses also need to sign or a notary.'
        ),
        beforeYouSign: {
          requirements: [
            t(
              'planDoc.sign_the_form.beforeYouSignRequirements_age',
              en.planDoc.sign_the_form.beforeYouSignRequirements_age
            ),
            t(
              'planDoc.sign_the_form.beforeYouSignRequirements_notary',
              en.planDoc.sign_the_form.beforeYouSignRequirements_notary
            ),
            t(
              'planDoc.sign_the_form.beforeYouSignRequirements_PersonallyKnownByYou',
              en.planDoc.sign_the_form.beforeYouSignRequirements_PersonallyKnownByYou
            ),
          ],
          finalClause: t(
            'AK.sign_the_form.beforeYouSignFinalClause',
            'By signing, I confirm that I am emotionally and mentally capable of making this document. I am thinking clearly, I have made this document willingly, and this Advance Directive accurately expresses my wishes.'
          ),
        },
        witness1SignatureText: (
          <Text style={s.formLabel}>
            I swear under penalty of perjury under AS 11.56.200 that the principal is personally known to me, that the
            principal signed or acknowledged this document in my presence, that the principal appears to be of sound
            mind and under no duress, fraud, or undue influence, and that I meet the witness requirements outlined in
            this document.
          </Text>
        ),

        witnessRequirements: {
          title: t('planDoc.sign_the_form.witnessRequirements_title', 'Witnesses or Notary Requirements'), // OR "Witness and Notary Requirements"
          requirements: [
            {
              title: t(
                'planDoc.sign_the_form.witnessRequirements_bothWitnesses',
                en.planDoc.sign_the_form.witnessRequirements_bothWitnesses
              ),
              width: '40%',
              phrases: [
                t('planDoc.sign_the_form.witnessRequirements_be18', en.planDoc.sign_the_form.witnessRequirements_be18),
                t(
                  'planDoc.sign_the_form.witnessRequirements_seeYouSign',
                  en.planDoc.sign_the_form.witnessRequirements_seeYouSign
                ),
                t(
                  'planDoc.sign_the_form.witnessRequirements_bePersonallyKnown',
                  en.planDoc.sign_the_form.witnessRequirements_bePersonallyKnown
                ),
              ],
            },
            {
              title: t(
                'planDoc.sign_the_form.witnessRequirements_yourWitnessCannot',
                en.planDoc.sign_the_form.witnessRequirements_yourWitnessCannot
              ),
              width: '60%',
              phrases: [
                t(
                  'planDoc.sign_the_form.witnessRequirements_beYourMdm',
                  en.planDoc.sign_the_form.witnessRequirements_beYourMdm
                ),
                t(
                  'planDoc.sign_the_form.witnessRequirements_beYourProvider',
                  en.planDoc.sign_the_form.witnessRequirements_beYourProvider
                ),
                t(
                  'AK.sign_the_form.witnessRequirements_beEmployeeOrProvider',
                  en.AK.sign_the_form.witnessRequirements_beEmployeeOrProvider
                ),
              ],
            },
            {
              title: t(
                'planDoc.sign_the_form.witnessRequirements_oneWitnessCannot',
                en.planDoc.sign_the_form.witnessRequirements_oneWitnessCannot
              ),
              width: '100%',
              phrases: [
                t(
                  'planDoc.sign_the_form.witnessRequirements_beRelatedBloodMarriageAdoption',
                  en.planDoc.sign_the_form.witnessRequirements_beRelatedBloodMarriageAdoption
                ),
                t(
                  'planDoc.sign_the_form.witnessRequirements_entitledToAnyPartOfEstateOrWill',
                  en.planDoc.sign_the_form.witnessRequirements_entitledToAnyPartOfEstateOrWill
                ),
              ],
            },
          ],
          validityRequirements: [
            t(
              'planDoc.sign_the_form.validityRequirements_esignInThePresence',
              en.planDoc.sign_the_form.validityRequirements_esignInThePresence
            ),
            t(
              'planDoc.sign_the_form.validityRequirements_prinatAndSignBeforeTwoWitnessesOr',
              en.planDoc.sign_the_form.validityRequirements_prinatAndSignBeforeTwoWitnessesOr
            ),
            t(
              'planDoc.sign_the_form.validityRequirements_prinatAndSignBeforeNotary',
              en.planDoc.sign_the_form.validityRequirements_prinatAndSignBeforeNotary
            ),
          ],
        },
        notary: {},
      },
      {
        ...defaultPartSettings?.[AdPartEnum.faqs_and_legal],
        sections: [
          <View style={s.sectionContainer} key="Pregnancy Legal Disclaimer">
            <Text style={s.sectionTitle}>
              {t('AK.faqs_and_legal.pregnancyTitle', en.AK.faqs_and_legal.pregnancyTitle)}
            </Text>
            <Text style={{ ...s.text }}>
              {t('AK.faqs_and_legal.pregnancyClause', en.AK.faqs_and_legal.pregnancyClause)}
            </Text>
            <Text style={{ ...s.text }}>
              {t('AK.faqs_and_legal.pregnancyBullet1', en.AK.faqs_and_legal.pregnancyBullet1)}
            </Text>
            <Text style={{ ...s.text }}>
              {t('AK.faqs_and_legal.pregnancyBullet2', en.AK.faqs_and_legal.pregnancyBullet2)}
            </Text>
            <Text style={{ ...s.text }}>
              {t('AK.faqs_and_legal.pregnancyBullet3', en.AK.faqs_and_legal.pregnancyBullet3)}
            </Text>
            <Text style={{ ...s.text }}>
              {t('AK.faqs_and_legal.pregnancyBullet4', en.AK.faqs_and_legal.pregnancyBullet4)}
            </Text>
          </View>,
        ],
      },
    ],
  }

  return ak
}
