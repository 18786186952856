import styled from 'styled-components'

export const ErrorText = styled.div`
  font-family: ${(p) => p.theme.fontFamily};
  color: ${(p) => p.theme.colors.red5};
  font-size: 16px;
  margin: 8px;
`

export const QuestionsContainer = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
  font-family: ${(p) => p.theme.fontFamily};
  max-width: 100%;
`

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 90%;
  margin: auto;
  background: #fff;
  padding-top: 88px;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    align-items: flex-start;
    padding-bottom: 88px;
  }
`

export const StepContainer = styled.div<{
  editMode?: boolean
  textAlign?: string
  isMobile?: boolean
}>`
  width: 840px;
  padding-left: 70px;
  padding-top: 48px;
  padding-right: 70px;
  max-width: 100%;
  min-height: ${(p) => {
    if (p.editMode) {
      return '275px'
    }
    return '375px'
  }};
  display: flex;
  align-items: flex-start;
  justify-content: ${(p) => {
    if (p.textAlign) {
      return 'center'
    }
    return 'flex-start'
  }};
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    padding-bottom: 90px;
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.mediumSmall}) {
    padding: 8px;
    width: 600px;
    padding-bottom: 80px;
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    padding: 8px;
    width: 400px;
    padding-bottom: 80px;
  }
  @media only screen and (max-width: 375px) {
    padding: 8px;
    width: 375px;
  }
`
