import { ApolloError } from '@apollo/client'
import logEvent from 'lib/helpers/logEvent'
import { AnalyticEventTypeEnums } from 'lib/types/AnalyticEventTypeEnums'
import i18n from 'lib/helpers/i18n'
import { TOptions } from 'i18next'

export default function translateApolloError(error: ApolloError): string {
  // log error to mixpanel (different logic if it's an apollo error vs a normal error that was accidentally passed to translateApolloError)
  if (error?.graphQLErrors?.[0]) {
    logEvent(AnalyticEventTypeEnums.ERROR_CAPTURED, {
      i18nkey: error?.graphQLErrors?.[0]?.message as string,
      content: error?.graphQLErrors?.[0]?.extensions?.defaultMessage as string,
      extraContext: error?.graphQLErrors?.[0]?.extensions?.extraContext as string,
    })
  } else if (error?.message) {
    logEvent(AnalyticEventTypeEnums.ERROR_CAPTURED, {
      content: error?.message,
    })
  }

  const i18nKey = error?.graphQLErrors?.[0]?.message as string
  const options: TOptions = {
    replace: error?.graphQLErrors?.[0]?.extensions?.i18nVariables,
    defaultValue: (error?.graphQLErrors?.[0]?.extensions?.defaultMessage as string) || error?.message,
  }
  return (
    i18n.t(i18nKey, options) || error?.message // handle case where a normal JS Error was passed to translateApolloError
  )
}
