import { AdPartEnum, PlanSetting } from '../types'
import en from 'lib/helpers/locales/en/translation.json'
import { Text, View } from '@react-pdf/renderer'
import s from '../../../KodaContent/styles'
import BulletText from '../../../KodaContent/BulletText'
import { Trans } from 'react-i18next'

export default function getMi(t, defaultPartSettings, patient): PlanSetting {
  const BULLET_FONT_SIZE = 11

  const patientFullName = `${patient?.firstName || ''} ${patient?.lastName || ''}`

  const mi: PlanSetting = {
    state: 'Michigan',
    disclaimer: t('MI.disclaimer', 'Disclaimer: This document needs to be signed and have two witnesses sign as well.'),
    parts: [
      // Part 1
      {
        ...defaultPartSettings?.[AdPartEnum.health_care_choices],
        description: t(
          'MI.health_care_choices.description',
          'This part is also known as a Living Will. It expresses the kind of treatment you would want in a medical crisis. Michigan does not recognize Living Wills as a legal document. However, a Living Will can be a helpful guide for your medical decision makers.'
        ),
      },
      // Part 2
      {
        ...defaultPartSettings?.[AdPartEnum.medical_decision_maker],
        pageTitle: t('MI.medical_decision_maker.pageTitle', en.MI.medical_decision_maker.pageTitle),
        description: t('MI.medical_decision_maker.description', en.MI.medical_decision_maker.description),
        postFlexibilityContent: (
          <View>
            <View style={s.sectionContainer}>
              <Text style={{ ...s.sectionTitle }}>Acceptance by Medical Decision Maker</Text>
              <BulletText
                fontSize={BULLET_FONT_SIZE}
                text={t(
                  'MI.medical_decision_maker.additionalContent1',
                  en.MI.medical_decision_maker.additionalContent1
                )}
              />
              <BulletText
                text={t(
                  'MI.medical_decision_maker.additionalContent2',
                  en.MI.medical_decision_maker.additionalContent2
                )}
                fontSize={BULLET_FONT_SIZE}
              />
              <BulletText
                text={t(
                  'MI.medical_decision_maker.additionalContent3',
                  en.MI.medical_decision_maker.additionalContent3
                )}
                fontSize={BULLET_FONT_SIZE}
              />
              <BulletText
                text={t(
                  'MI.medical_decision_maker.additionalContent4',
                  en.MI.medical_decision_maker.additionalContent4
                )}
                fontSize={BULLET_FONT_SIZE}
              />
              <BulletText
                text={t(
                  'MI.medical_decision_maker.additionalContent5',
                  en.MI.medical_decision_maker.additionalContent5
                )}
                fontSize={BULLET_FONT_SIZE}
              />
              <BulletText
                text={t(
                  'MI.medical_decision_maker.additionalContent6',
                  en.MI.medical_decision_maker.additionalContent6
                )}
                fontSize={BULLET_FONT_SIZE}
              />
              <BulletText
                text={t(
                  'MI.medical_decision_maker.additionalContent7',
                  en.MI.medical_decision_maker.additionalContent7
                )}
                fontSize={BULLET_FONT_SIZE}
              />
              <BulletText
                text={t(
                  'MI.medical_decision_maker.additionalContent8',
                  en.MI.medical_decision_maker.additionalContent8
                )}
                fontSize={BULLET_FONT_SIZE}
              />
              <BulletText
                text={t(
                  'MI.medical_decision_maker.additionalContent9',
                  en.MI.medical_decision_maker.additionalContent9
                )}
                fontSize={BULLET_FONT_SIZE}
              />
              <BulletText
                text={t(
                  'MI.medical_decision_maker.additionalContent10',
                  en.MI.medical_decision_maker.additionalContent10
                )}
                fontSize={BULLET_FONT_SIZE}
              />
            </View>
          </View>
        ),
      },
      // Part 3
      {
        ...defaultPartSettings?.[AdPartEnum.sign_the_form],
        description: t(
          'MI.sign_the_form.description',
          'You can fill out Part 1, Part 2, or both, but you need to sign the form in Part 3. To make Part 2 legal, two witnesses also need to sign.'
        ),

        beforeYouSign: {
          requirements: [
            t(
              'planDoc.sign_the_form.beforeYouSignRequirements_age',
              en.planDoc.sign_the_form.beforeYouSignRequirements_age
            ),
            t(
              'planDoc.sign_the_form.beforeYouSignRequirements_witnesses',
              en.planDoc.sign_the_form.beforeYouSignRequirements_witnesses
            ),
          ],
          finalClause: t(
            'MI.sign_the_form.beforeYouSignFinalClause',
            'By signing, I confirm that I am emotionally and mentally capable of making this document. I am thinking clearly, I have made this document willingly, and this Advance Directive accurately expresses my wishes.'
          ),
        },
        witnessSignatures: {
          preSignaturesContent: (
            <Text style={{ ...s.text, fontSize: 11 }}>
              <Trans i18nKey="MI.sign_the_form.witnessSignatures.preSignaturesContent">
                By signing, I promise that {{ patientFullName: patientFullName || '____________________ ' }} signed this
                form while I watched. They appear to be of sound mind and under no duress, fraud, or undue influence. I
                also promise that I meet the witness requirements listed above.
              </Trans>
            </Text>
          ),
          //
        },
        witnessRequirements: {
          title: t('MI.sign_the_form.witnessRequirements_title', en.MI.sign_the_form.witnessRequirements_title), // OR "Witness and Notary Requirements"
          requirements: [
            {
              title: t(
                'planDoc.sign_the_form.witnessRequirements_yourWitnessMust',
                en.planDoc.sign_the_form.witnessRequirements_yourWitnessMust
              ),
              width: '30%',
              phrases: [
                t('planDoc.sign_the_form.witnessRequirements_be18', en.planDoc.sign_the_form.witnessRequirements_be18),
                t(
                  'planDoc.sign_the_form.witnessRequirements_seeYouSign',
                  en.planDoc.sign_the_form.witnessRequirements_seeYouSign
                ),
              ],
            },
            {
              title: t(
                'planDoc.sign_the_form.witnessRequirements_yourWitnessCannot',
                en.planDoc.sign_the_form.witnessRequirements_yourWitnessCannot
              ),
              width: '70%',
              phrases: [
                t(
                  'MI.sign_the_form.witnessRequirements_beYourSpouseParentChildHeirEtc',
                  en.MI.sign_the_form.witnessRequirements_beYourSpouseParentChildHeirEtc
                ),
                t(
                  'planDoc.sign_the_form.witnessRequirements_beYourMdm',
                  en.planDoc.sign_the_form.witnessRequirements_beYourMdm
                ),
                t(
                  'planDoc.sign_the_form.witnessRequirements_beYourPhysician',
                  en.planDoc.sign_the_form.witnessRequirements_beYourPhysician
                ),
                t(
                  'MI.sign_the_form.witnessRequirements_beEmployeeOfInsuranceProvider',
                  en.MI.sign_the_form.witnessRequirements_beEmployeeOfInsuranceProvider
                ),
                t(
                  'MI.sign_the_form.witnessRequirements_beEmployeeOfHealthFacilityTreatingYou',
                  en.MI.sign_the_form.witnessRequirements_beEmployeeOfHealthFacilityTreatingYou
                ),
                t(
                  'MI.sign_the_form.witnessRequrements_beEmployeeOfCommunityMentalHealthService',
                  en.MI.sign_the_form.witnessRequrements_beEmployeeOfCommunityMentalHealthService
                ),
                t(
                  'MI.sign_the_form.witnessRequirements_beEmployeeOfHomeForAged',
                  en.MI.sign_the_form.witnessRequirements_beEmployeeOfHomeForAged
                ),
              ],
            },
          ],
          validityRequirements: [
            t(
              'planDoc.sign_the_form.validityRequirements_esignInThePresence',
              en.planDoc.sign_the_form.validityRequirements_esignInThePresence
            ),
            t(
              'planDoc.sign_the_form.validityRequirements_printAndSign',
              en.planDoc.sign_the_form.validityRequirements_printAndSign
            ),
          ],
        },
      },
    ],
  }

  return mi
}
