const theme = {
  fontFamily: `'Roboto', sans-serif`, //, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
  // COLORS
  borderRadius: 5,
  colors: {
    // primary
    primary1: "#002159",
    primary2: "#01337D",
    primary3: "#03449E",
    primary4: "#0552B5",
    primary5: "#0967D2",
    primary6: "#2186EB",
    primary7: "#47A3F3",
    primary8: "#7CC4FA",
    primary9: "#BAE3FF",
    primary10: "#E6F6FF",
    // Neutrals
    neutral1: "#1F2933",
    neutral2: "#323F4B",
    neutral3: "#3E4C59",
    neutral4: "#52606D",
    neutral5: "#616E7C",
    neutral6: "#7B8794",
    neutral7: "#9AA5B1",
    neutral8: "#CBD2D9",
    neutral9: "#E4E7EB",
    neutral10: "#F5F7FA",
    neutral11: "#F6F8FB",
    // Accent B
    supportB1: "#05606E",
    supportB2: "#07818F",
    supportB3: "#01337D",
    supportB4: "#0FB5BA",
    supportB5: "#1CD4D4",
    supportB6: "#3AE7E1",
    supportB7: "#62F4EB",
    supportB8: "#92FDF2",
    supportB9: "#C1FEF6",
    supportB10: "#E1FCF8",
    // Red
    red1: "#610316",
    red2: "#8A041A",
    red3: "#AB091E",
    red4: "#CF1124",
    red5: "#E12D39",
    red6: "#EF4E4E",
    red7: "#F86A6A",
    red8: "#FF9B9B",
    red9: "#FFBDBD",
    red10: "#FFE3E3",
    // Yellow
    yellow1: "#513C06",
    yellow2: "#7C5E10",
    yellow3: "#A27C1A",
    yellow4: "#C99A2E",
    yellow5: "#E9B949",
    yellow6: "#F7D070",
    yellow7: "#F9DA8B",
    yellow8: "#F8E3A3",
    yellow9: "#FCEFC7",
    yellow10: "#FFFAEB",
    //
    success1: "#05606e",
    success10: "#e1fcf8",
  },
  breakpoints: {
    large: "1366px",
    medium: "1024px",
    mediumSmall: "600px",
    small: "429px", // iphone 6/7/8 plus
    xSmall: "375px", // iphone 7/7/8
    xxSmall: "320px", // iphone 5/SE
  },
};

export interface StyledTheme {
  breakpoints: { mediumSmall: string }; // @TODO: actually type this out
  colors: typeof theme.colors;
}

export default theme;
