import React from 'react';
import {
  Title,
  Caption,
  StepContainer,
  StepIcon,
  TitleIcon,
  Content,
  TitleContainer,
  StepText,
} from '../index';
// LIB
import i18n from 'lib/helpers/i18n';

import thumbsupSVG from '../images/thumbsup.svg';
import calendarSVG from '../images/calendar.svg';
import doctorSVG from '../images/doctor.svg';
import relationshipSVG from '../images/relationship.svg';
import deathSVG from '../images/death.svg';

export default function Step6() {
  return (
    <div>
      <TitleContainer>
        <TitleIcon
          src={thumbsupSVG}
          alt="Life events can change... and so can your Care Plan"
        />
        <Title data-testid="step6-title">{i18n.t("medicalCarePlansFaq.thatsIt")}</Title>
      </TitleContainer>
      <Caption>
        {i18n.t("medicalCarePlansFaq.wishesAtAnytime")}
      </Caption>
      <Content style={{ paddingLeft: '5%' }}>
        <StepContainer>
          <StepIcon
            src={calendarSVG}
            alt="Every year to make sure nothing has changed"
            borderRadius="0px"
          />
          <StepText>{i18n.t("medicalCarePlansFaq.everyYearMake")}</StepText>
        </StepContainer>
        <StepContainer>
          <StepIcon
            src={doctorSVG}
            alt="New diagnosis or change in health status"
            border
          />
          <StepText>{i18n.t("medicalCarePlansFaq.newDiagnosis")}</StepText>
        </StepContainer>
        <StepContainer>
          <StepIcon
            src={relationshipSVG}
            alt="Divorce or change in relationship status"
            borderRadius="0px"
          />
          <StepText>{i18n.t("medicalCarePlansFaq.divorceOrChange")}</StepText>
        </StepContainer>
        <StepContainer>
          <StepIcon
            src={deathSVG}
            alt="A death of a loved one"
            borderRadius="0px"
          />
          <StepText>{i18n.t("medicalCarePlansFaq.aDeathLovedOne")}</StepText>
        </StepContainer>
      </Content>
    </div>
  );
}
