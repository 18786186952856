import { AdPartEnum, PlanSetting } from '../types'
import { Text, View } from '@react-pdf/renderer'
import s from '../../../KodaContent/styles'
import { Trans } from 'react-i18next'

export default function getOR(t, defaultPartSettings): PlanSetting {
  const or: PlanSetting = {
    state: 'Oregon',
    disclaimer: t(
      'OR.disclaimer',
      'Disclaimer: This document needs to be signed and have two witnesses sign as well or be notarized in order to be a legal document.'
    ),
    parts: [
      // Part 1
      {
        ...defaultPartSettings?.[AdPartEnum.health_care_choices],
      },
      // Part 2
      {
        ...defaultPartSettings?.[AdPartEnum.medical_decision_maker],
        showSignatures: true,
        pageTitle: t(
          'OR.medical_decision_maker.pageTitle',
          'Appointment of Medical Decision Maker (health care representative)'
        ),
        pageTitle2: t('OR.medical_decision_maker.pageTitle2', 'Medical Decision Maker (health care representative)'),
        description: t(
          'OR.medical_decision_maker.description',
          'This part is also known as a Health Care Representative. It designates a person to make decisions for you when you are unable to speak for yourself. Your appointment of a medical decision maker (health care representative) is not effective until the health care representative accepts the appointment.'
        ),
        additionalContent: (
          <Trans i18nKey="OR.medical_decision_maker.additionalContent">
            <View>
              <Text style={s.textBold}>Appointment of Medical Decision Maker (health care representative)</Text>
              <Text style={{ ...s.text, marginBottom: 0 }}>
                Your appointment of a health care representative is not effective until the health care representative
                accepts the appointment.
              </Text>
              <Text style={{ ...s.text, marginBottom: 0 }}>
                If you do not have an effective health care representative appointment and you become too sick to speak
                for yourself, a health care representative will be appointed for you in the order of priority set forth
                in ORS 127.635 (2) and this person can only decide to withhold or withdraw life sustaining treatments if
                you meet one of the conditions set forth in ORS 127.635 (1).
              </Text>
              <Text style={s.textBold}>Revocation:</Text>
              <Text style={{ ...s.text, marginBottom: 0 }}>
                If your advance directive includes directions regarding the withdrawal of life support or tube feeding,
                you may revoke your advance directive at any time and in any manner that expresses your desire to revoke
                it.
              </Text>
              <Text style={{ ...s.text, marginBottom: 0 }}>
                In all other cases, you may revoke your advance directive at any time and in any manner as long as you
                are capable of making medical decisions.
              </Text>
            </View>
          </Trans>
        ),
      },
      // Part 3
      {
        ...defaultPartSettings?.[AdPartEnum.sign_the_form],
        description: t(
          'OR.sign_the_form.description',
          'You can fill out Part 1, Part 2, or both, but you need to sign the form in Part 3. To be legal, two witnesses also need to sign or a notary.'
        ),
        beforeYouSign: {
          requirements: [
            t('planDoc.sign_the_form.beforeYouSignRequirements_age'),
            t('planDoc.sign_the_form.beforeYouSignRequirements_notary'),
            t('planDoc.sign_the_form.beforeYouSignRequirements_witnesses'),
          ],
          finalClause: t('planDoc.sign_the_form.beforeYouSignFinalClause'),
        },
        notary: {},
        witness2InLongtermCareCheck: true,
        witnessRequirements: {
          title: t('planDoc.sign_the_form.witnessRequirements_title', 'Witness Requirements'),
          requirements: [
            {
              title: t('planDoc.sign_the_form.witnessRequirements_yourWitnessMust'),
              phrases: [
                t('planDoc.sign_the_form.witnessRequirements_be18'),
                t('planDoc.sign_the_form.witnessRequirements_seeYouSign', 'see you sign the form'),
              ],
            },
            {
              title: t('planDoc.sign_the_form.witnessRequirements_yourWitnessCannot'),
              phrases: [
                t('OR.sign_the_form.witnessRequirements_beYourMdm'),
                t('planDoc.sign_the_form.witnessRequirements_aPhysician'),
              ],
            },
            {
              title: t('OR.sign_the_form.witnessRequirements_ForIndividualsInLTC'),
              phrases: [t('OR.sign_the_form.witnessRequirements_oneWitness')],
            },
          ],
          validityRequirements: [
            t('planDoc.sign_the_form.validityRequirements_presenceOfTwoWitnesses'),
            t('planDoc.sign_the_form.validityRequirements_notarizeSignature'),
          ],
        },
      },
    ],
  }

  return or
}
