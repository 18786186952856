// TOP LEVEL IMPORTS
import { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { validate } from 'email-validator'
import { useTranslation } from 'react-i18next'
// COMPONENTS
import Button from 'components/common/Button'
import TextInput from 'components/inputs/TextInput'
import Caption from 'components/text/Caption'
import Row from 'components/common/Row'
import Col from 'components/common/Col'
import FormItem from 'components/common/FormItem'
import SwitchLanguage from 'components/common/SwitchLanguage'
// APOLLO
import ApolloClient from 'ApolloClient'
import { useHistory } from 'react-router-dom'
// LIB
import logo from 'lib/media/logo-light-bg.svg'
import constants from 'lib/config'
import { AnalyticEventTypeEnums } from 'lib/types/AnalyticEventTypeEnums'
import logEvent from 'lib/helpers/logEvent'
import handleLogin from 'lib/helpers/handleLogin'
import translateApolloError from 'lib/helpers/translateApolloError'

const TitleContainer = styled.div`
  min-height: 95px;
  text-align: center;
`

const PageHeader = styled.span`
  min-height: 44px;
  font-family: ${(p) => p.theme.fontFamily};
  font-size: 24px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 1px;
  display: block;
  color: ${(p) => p.theme.colors.neutral3};
  @media only screen and (max-width: 812px) {
    font-size: 20px;
  }
`

const Container = styled.div`
  align-items: center;
  padding-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: ${(p) => p.theme.colors.neutral10};
  height: 100vh;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    padding-top: 30px;
  }
`

const Logo = styled.img`
  height: 300px;
  width: 400px;
  border-radius: 5px;
  margin: auto;
`

const Content = styled.div`
  width: 800px;
  margin: auto;
  display: flex;
  align-items: center;
  background: #fff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  min-height: 500px;
  border-radius: 7px;
  padding: 16px;
  max-width: 100%;
`

// STYLED-COMPONENTS
// ========================================

const ErrorBlock = styled.div`
  border: 1px solid ${(p) => p.theme.colors.red5};
  background: ${(p) => p.theme.colors.red10};
  padding: 8px;
  color: ${(p) => p.theme.colors.red2};
  border-radius: 5px;
  margin-top: 8px;
`

export default function AuthLogin() {
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [errors, setErrors] = useState<any>(null)
  const { t } = useTranslation()
  const history = useHistory()

  useEffect(() => {
    logEvent(AnalyticEventTypeEnums.LOGIN_PAGE)
  }, [])

  const onSubmit = async (e) => {
    try {
      e.preventDefault()
      // // reset errors
      setErrors([])

      // check that user added an email
      if (!email || email.length === 0) {
        return setErrors(['Please provide an email'])
      }
      // check if its a valid email
      if (!validate(email)) {
        return setErrors(['That is not a valid email'])
      }
      // check that they give a password
      if (!password || password.length === 0) {
        return setErrors(['Please provide a password'])
      }

      setLoading(true)

      const result = await handleLogin({
        email,
        password,
      })

      if (result?.mfaRequired) {
        return history.push(`/mfa?email=${encodeURIComponent(email)}`)
      }

      await ApolloClient.resetStore()

      history.push(`/app`)
    } catch (err: any) {
      setLoading(false)
      setErrors([translateApolloError(err)])
      return
    }
  }

  return (
    <Container>
      <SwitchLanguage />
      <Content>
        <Row align="middle" style={{ width: '100%' }} gutter={24}>
          <Col xs={0} md={14}>
            <div
              style={{
                height: 500,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Logo src={logo} alt="Koda Health" />
            </div>
          </Col>
          <Col xs={24} md={10}>
            <div
              style={{
                marginTop: 32,
                marginLeft: 16,
              }}
            >
              <TitleContainer>
                <PageHeader>{t('login.loginTo', 'Login to')} Koda</PageHeader>
                <Caption style={{ width: '90%', margin: 'auto' }}>{t('welcomeBack', 'Welcome back!')}</Caption>
              </TitleContainer>
              <form onSubmit={onSubmit}>
                <FormItem label={t('login.email', 'Email')} htmlFor="email">
                  <TextInput
                    onChange={(e) => setEmail(e.target.value.toLowerCase())}
                    value={email}
                    inputMode="email"
                    type="email"
                    id="email"
                    data-testid="login-email-input"
                  />
                </FormItem>
                <FormItem label={t('login.password', 'Password')} htmlFor="password">
                  <TextInput
                    value={password}
                    id="password"
                    onChange={(e) => setPassword(e.target.value?.trim())}
                    type="password"
                    data-testid="login-password-input"
                  />
                </FormItem>
                {errors &&
                  errors.map((item) => (
                    <ErrorBlock key={item} data-testid="error-block">
                      {item.replace('error: ', '')}
                    </ErrorBlock>
                  ))}
                <FormItem>
                  <Button disabled={loading} loading={loading} type="submit" data-testid="login-submit-btn" fullWidth>
                    {t('login.login', 'Login')}
                  </Button>
                </FormItem>
              </form>
              <div style={{ marginTop: 16, textAlign: 'center' }}>
                <Caption>
                  <Link style={{ textDecoration: 'underline' }} to="/forgot-password">
                    {t('forgotPassword', 'Forgot your password')}
                  </Link>
                </Caption>
                <Caption style={{ marginTop: 16 }}>
                  {t('login.needHelpCallUsAt', 'Need help? Call us at ')}
                  <a style={{ textDecoration: 'underline' }} href={`tel:+${constants.supportPhone.replace(/-/g, '')}`}>
                    {constants.supportPhone}
                  </a>
                </Caption>
              </div>
            </div>
          </Col>
        </Row>
      </Content>
    </Container>
  )
}
