import { Text, View } from '@react-pdf/renderer'
// helpers
import s from 'components/common/PlanDoc/KodaContent/styles'
import DocPage from 'components/common/PlanDoc/KodaContent/DocPage'
import BulletText from 'components/common/PlanDoc/KodaContent/BulletText'
import TexasLegal from './TexasLegal'
import i18n from 'lib/helpers/i18n'
import { AdPartEnum, PlanSetting } from '../plan-settings'
import { Patient } from 'generated/graphql'

const PennsylvaniaLegal = ({ name, createdStamp, pageTitle }) => {
  return (
    <>
      <DocPage
        pageTitle={pageTitle}
        name={name}
        content={
          <>
            <View style={s.sectionContainer}>
              <Text style={s.sectionTitle}>{i18n.t('PennsylvaniaLegal.title', 'Pregnancy Legal Disclaimer')}</Text>
              <Text style={{ ...s.text }}>{i18n.t('PennsylvaniaLegal.p1')}</Text>
              <BulletText text={i18n.t('PennsylvaniaLegal.p2')} />
              <BulletText text={i18n.t('PennsylvaniaLegal.p3')} />
              <BulletText text={i18n.t('PennsylvaniaLegal.p4')} />
            </View>
          </>
        }
        showPageNumbers
        createdStamp={createdStamp}
      />
    </>
  )
}

export default function FaqsAndLegal({
  data,
  version,
  name,
  createdStamp,
  pageTitle,
  settings,
}: {
  pageTitle?: string
  version: string
  name: string
  data: Patient
  createdStamp: any
  settings: PlanSetting
}) {
  const part = settings?.parts?.find(({ id }) => id === AdPartEnum.faqs_and_legal)

  if (version === 'TX') {
    return <TexasLegal data={data} name={name} createdStamp={createdStamp} pageTitle={pageTitle} />
  }

  if (version === 'PA') {
    return <PennsylvaniaLegal name={name} createdStamp={createdStamp} pageTitle={pageTitle} />
  }

  console.log(part)

  return (
    <>
      <DocPage
        pageTitle={pageTitle}
        name={name}
        content={<>{part?.sections?.map((section, index) => section)}</>}
        showPageNumbers
        createdStamp={createdStamp}
      />
    </>
  )
}
