import { useState, useEffect, FC } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

import {
  ActivityLogKeyEnum,
  Patient,
  PostAcpInfoAboutCprEnum,
  PostAcpMedicalEducationPreferEnum,
  PostAcpOnlineFormatEnum,
  StatementMostAdvanceCarePlanningEnum,
  UserProfile,
} from "generated/graphql";

import Button from "components/common/Button";
import useSavePatient from "lib/hooks/useSavePatient";
import usePrevious from "lib/hooks/usePrevious";
import { Step1Continue } from "../step1";
import Loading from "components/common/Loading";
import { AfterSaveAction } from "routes/app-survey/step1";
import SectionHeader from "routes/app-survey/SectionHeader";
import SelectBlock from "routes/app-survey/step1/SelectBlock";
import SectionFooter from "routes/app-survey/SectionFooter";
import useLogActivityItemMutation from "lib/hooks/useLogActivityItemMutation";
import PlanConfiguratorHelper from "routes/app-home/PlanConfigurator/PlanConfiguratorHelper";
import getSurveyHashes from "lib/helpers/getSurveyHashes";

const Step3Styled = styled.div`
  background-color: white;
  flex: 1;
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints.medium}) {
    padding-bottom: 100px;
  }
`;

const Step3Body = styled.div`
  margin: auto;
  max-width: 600px;
  padding: 48px 10px;
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints.mediumSmall}) {
    padding: 24px;
  }
`;

const Step3Title = styled.h1`
  font-weight: 500;
  font-size: 18px;
  color: #323f4b;
`;

interface Step1Props {
  patient?: Patient | null;
  currentUser: UserProfile;
}

const Step4: FC<Step1Props> = ({ patient, currentUser }) => {
  const [logActivityItem] = useLogActivityItemMutation();

  const [postSurveyComplete, setPostSurveyComplete] = useState<boolean>(false);
  const [showCongratsModal, setShowCongratsModal] = useState<boolean>(false);

  const [afterSaveAction, setAfterSaveAction] = useState<AfterSaveAction>();
  const [postAcpOnlineFormat, setPostAcpOnlineFormatEnum] =
    useState<PostAcpOnlineFormatEnum>();
  const [postAcpOnlineFormatOther, setPostAcpOnlineFormatOther] =
    useState<string>("");
  const [postAcpMedicalEducationPrefer, setPostAcpMedicalEducationPrefer] =
    useState<PostAcpMedicalEducationPreferEnum>();
  const [
    postAcpMedicalEducationPreferOther,
    setPostAcpMedicalEducationPreferOther,
  ] = useState<string>("");
  const [postAcpInfoAboutCPR, setPostAcpInfoAboutCPR] =
    useState<PostAcpInfoAboutCprEnum>();
  const [postAcpInfoAboutCPROther, setPostAcpInfoAboutCPROther] =
    useState<string>("");
  const [
    postAcpStatementMostAdvanceCarePlanning,
    setPostAcpStatementMostAdvanceCarePlanning,
  ] = useState<StatementMostAdvanceCarePlanningEnum>();
  const [
    postAcpStatementMostAdvanceCarePlanningNoneText,
    setPostAcpStatementMostAdvanceCarePlanningNoneText,
  ] = useState<string>("");

  const { onSavePatient, saving } = useSavePatient();

  const history = useHistory();
  const { t } = useTranslation();
  const savingPrev = usePrevious(saving);

  const save = async () => {
    if (patient) {
      const newPatient = {
        ...patient,
        postAcpOnlineFormat,
        postAcpOnlineFormatOther,
        postAcpMedicalEducationPrefer,
        postAcpMedicalEducationPreferOther,
        postAcpInfoAboutCPR,
        postAcpInfoAboutCPROther,
        postAcpStatementMostAdvanceCarePlanning,
        postAcpStatementMostAdvanceCarePlanningNoneText,
      };
      if (patient?.guideTemplate?.name !== "WF_Path1") {
        const planHelpers = new PlanConfiguratorHelper();
        const isPostSurveyComplete =
          planHelpers.getIsPostAcpSurveyCompleted(newPatient);
        if (isPostSurveyComplete) {
          await logActivityItem({
            variables: {
              key: ActivityLogKeyEnum.CompletedPostassesmentSurvey,
              userId: currentUser?.id || "",
              patientId: patient?.id || "",
              content: `Patient completed post-assessment survey`,
            },
          });
        }
      }
      await onSavePatient(newPatient);
    }
  };

  const goNext = () => {
    setAfterSaveAction(AfterSaveAction.NEXT);
    save();
  };

  useEffect(() => {
    // set patient values every patient change
    if (patient) {
      setPostAcpOnlineFormatEnum(
        patient.postAcpOnlineFormat as PostAcpOnlineFormatEnum
      );
      setPostAcpOnlineFormatOther(
        (patient.postAcpOnlineFormatOther as string) || ""
      );
      setPostAcpMedicalEducationPrefer(
        patient.postAcpMedicalEducationPrefer as PostAcpMedicalEducationPreferEnum
      );
      setPostAcpMedicalEducationPreferOther(
        (patient.postAcpMedicalEducationPreferOther as string) || ""
      );
      setPostAcpInfoAboutCPR(
        patient.postAcpInfoAboutCPR as PostAcpInfoAboutCprEnum
      );
      setPostAcpInfoAboutCPROther(
        (patient.postAcpInfoAboutCPROther as string) || ""
      );
      setPostAcpStatementMostAdvanceCarePlanning(
        patient.postAcpStatementMostAdvanceCarePlanning as StatementMostAdvanceCarePlanningEnum
      );
      setPostAcpStatementMostAdvanceCarePlanningNoneText(
        (patient.postAcpStatementMostAdvanceCarePlanningNoneText as string) ||
          ""
      );
    }
  }, [patient]);

  useEffect(() => {
    if (
      postAcpStatementMostAdvanceCarePlanning &&
      postAcpStatementMostAdvanceCarePlanning !==
        StatementMostAdvanceCarePlanningEnum.None
    ) {
      setPostAcpStatementMostAdvanceCarePlanningNoneText("");
    }
  }, [postAcpStatementMostAdvanceCarePlanning]);

  useEffect(() => {
    // Go to step 2 on saving end and if patient is truthy
    if (savingPrev && !saving && patient && afterSaveAction) {
      switch (afterSaveAction) {
        case AfterSaveAction.HOME: {
          if (showCongratsModal) {
            history.push("/app/home?postSurveyComplete=true");
          } else {
            history.push("/app/home");
          }
          break;
        }
        case AfterSaveAction.NEXT: {
          if (patient?.guideTemplate?.name === "WF_Path1") {
            history.push("/app/post-survey?step=5");
          } else if (showCongratsModal) {
            history.push("/app/home?postSurveyComplete=true");
          } else {
            history.push("/app/home");
          }
          break;
        }
        case AfterSaveAction.BACK: {
          history.push("/app/post-survey?step=3");
          break;
        }
        default: {
          return;
        }
      }
    }
  }, [
    history,
    savingPrev,
    saving,
    patient,
    afterSaveAction,
    postSurveyComplete,
    showCongratsModal,
  ]);

  useEffect(() => {
    if (
      patient?.postAcpOnlineFormat &&
      patient?.postAcpMedicalEducationPrefer &&
      patient?.postAcpInfoAboutCPR &&
      patient?.postAcpStatementMostAdvanceCarePlanning
    ) {
      setPostSurveyComplete(true);
    }
    /* eslint-disable-next-line */
  }, [patient?.id]);

  useEffect(() => {
    if (
      patient?.postAcpOnlineFormat &&
      patient?.postAcpMedicalEducationPrefer &&
      patient?.postAcpInfoAboutCPR &&
      patient?.postAcpStatementMostAdvanceCarePlanning &&
      !postSurveyComplete
    ) {
      setShowCongratsModal(true);
    }
  }, [patient, postSurveyComplete]);

  const hashes = getSurveyHashes();

  const disabled =
    !postAcpOnlineFormat ||
    !postAcpMedicalEducationPrefer ||
    !postAcpInfoAboutCPR ||
    !postAcpStatementMostAdvanceCarePlanning;

  return (
    <>
      <Step3Styled>
        <SectionHeader
          sectionName={t("planConfigurator.POST_SURVEY", "Post-ACP Survey")}
          progress={patient?.guideTemplate?.name === "WF_Path1" ? "60" : "75"}
          onClick={() => {
            setAfterSaveAction(AfterSaveAction.HOME);
            save();
          }}
        />
        {saving && <Loading height={80} />}
        <Step3Body>
          <Step3Title>
            {t(
              "survey.contentPreferencesAssessment",
              "Content Preferences Assessment "
            )}
          </Step3Title>
          <SelectBlock
            title={`10. ${t(
              "survey.whichOnlineFormatDoYouPrefer",
              "Which online format do you prefer to learn through?"
            )}`}
            subtitle={t(
              "survey.markTheBoxBestDescribesYou",
              "Please mark the box that best describes you"
            )}
            items={Object.keys(hashes.POST_ACP_ONLINE_FORMAT_TEXT_HASH)}
            hash={hashes.POST_ACP_ONLINE_FORMAT_TEXT_HASH}
            active={postAcpOnlineFormat as string}
            otherField={PostAcpOnlineFormatEnum.Other as string}
            otherValue={postAcpOnlineFormatOther}
            onChange={(value) =>
              setPostAcpOnlineFormatOther(value[PostAcpOnlineFormatEnum.Other])
            }
            onSelect={(item: string) =>
              setPostAcpOnlineFormatEnum(item as PostAcpOnlineFormatEnum)
            }
            noCapitalize
          />
          <SelectBlock
            title={`11. ${t(
              "survey.whenYouWantToLearnAboutMedical",
              `When you want to learn about medical education, what tone
              do you prefer?`
            )}`}
            subtitle={t(
              "survey.markTheBoxBestDescribesYou",
              "Please mark the box that best describes you"
            )}
            items={Object.keys(
              hashes.POST_ACP_MEDICAL_EDUCATION_PREFER_TEXT_HASH
            )}
            hash={hashes.POST_ACP_MEDICAL_EDUCATION_PREFER_TEXT_HASH}
            active={postAcpMedicalEducationPrefer as string}
            otherField={PostAcpMedicalEducationPreferEnum.Other as string}
            otherValue={postAcpMedicalEducationPreferOther}
            onChange={(value) =>
              setPostAcpMedicalEducationPreferOther(
                value[PostAcpMedicalEducationPreferEnum.Other]
              )
            }
            onSelect={(item: string) =>
              setPostAcpMedicalEducationPrefer(
                item as PostAcpMedicalEducationPreferEnum
              )
            }
            noCapitalize
          />
          <SelectBlock
            title={`12. ${t(
              "survey.wasInfoAboutCPRSurvivalRates",
              `Was information about CPR survival rates for adults in your 
              age range helpful to you in making a decision about whether or 
              not you would like to receive CPR?`
            )}`}
            subtitle={t(
              "survey.markTheBoxBestDescribesYou",
              "Please mark the box that best describes you"
            )}
            items={Object.keys(hashes.POST_ACP_INFO_ABOUT_CPR_TEXT_HASH)}
            hash={hashes.POST_ACP_INFO_ABOUT_CPR_TEXT_HASH}
            active={postAcpInfoAboutCPR as string}
            otherField={PostAcpInfoAboutCprEnum.Other as string}
            otherValue={postAcpInfoAboutCPROther}
            onChange={(value) =>
              setPostAcpInfoAboutCPROther(value[PostAcpInfoAboutCprEnum.Other])
            }
            onSelect={(item: string) =>
              setPostAcpInfoAboutCPR(item as PostAcpInfoAboutCprEnum)
            }
            noCapitalize
          />
          <SelectBlock
            title={`13. ${t(
              "survey.whichStatementDoYouIdentify",
              `Which statement do you identify with the most
              when thinking about advance care planning?`
            )}`}
            subtitle={t(
              "survey.markTheBoxBestDescribesYou",
              "Please mark the box that best describes you"
            )}
            description={t(
              "survey.advanceCarePlanningIsAProcess",
              `Advance care planning is a process that supports adults at any
              age or stage of health in understanding and sharing their personal values,
              life goals, and preferences regarding future medical care. The goal of
              advance care planning is to help ensure that people receive medical care
              that is consistent with their values, goals and preferences during
              serious and chronic illness.`
            )}
            items={Object.keys(
              hashes.STATEMENT_MOST_ADVANCE_CARE_PLANNING_TEXT_HASH
            )}
            hash={hashes.STATEMENT_MOST_ADVANCE_CARE_PLANNING_TEXT_HASH}
            active={postAcpStatementMostAdvanceCarePlanning}
            onSelect={(item: string) =>
              setPostAcpStatementMostAdvanceCarePlanning(
                item as StatementMostAdvanceCarePlanningEnum
              )
            }
            otherField={StatementMostAdvanceCarePlanningEnum.None as string}
            otherValue={postAcpStatementMostAdvanceCarePlanningNoneText}
            onChange={(value) =>
              setPostAcpStatementMostAdvanceCarePlanningNoneText(
                value[StatementMostAdvanceCarePlanningEnum.None]
              )
            }
            noCapitalize
          />
          {!disabled && (
            <Step1Continue>
              <Button loading={saving} disabled={saving} onClick={goNext} pulse>
                {t("button.continue", "Continue")}
              </Button>
            </Step1Continue>
          )}
        </Step3Body>
      </Step3Styled>
      <SectionFooter
        goBack={() => {
          setAfterSaveAction(AfterSaveAction.BACK);
          save();
        }}
        goNext={goNext}
        loading={saving}
        disabled={disabled}
      />
    </>
  );
};

export default Step4;
