import { Title, Caption, TitleIcon, Content, TitleContainer, StepContainer, StepText, StepIcon } from '../index'
// LIB
import i18n from 'lib/helpers/i18n'
import checklistSVG from '../images/checklist.svg'
import valuesSVG from 'lib/media/images/values_section.svg'
import qualitySVG from 'lib/media/images/quality_of_life_section.svg'
import decisionSVG from 'lib/media/images/mdm_section.svg'
import medicalSVG from 'lib/media/images/medical_section.svg'

export default function Step3() {
  return (
    <div>
      <TitleContainer>
        <TitleIcon src={checklistSVG} alt="Checklist" />
        <Title data-testid="step3-title">{i18n.t('medicalCarePlansFaq.createCarePlan')}</Title>
      </TitleContainer>
      <Caption>{i18n.t('medicalCarePlansFaq.answeringQuestions')}</Caption>
      <Content>
        <StepContainer style={{ paddingLeft: 38 }}>
          <StepIcon src={valuesSVG} alt="values" borderRadius={'0px'} />
          <StepText>{i18n.t('medicalCarePlansFaq.yourValues')}</StepText>
        </StepContainer>
        <StepContainer style={{ paddingLeft: 38 }}>
          <StepIcon src={decisionSVG} alt="decision maker" borderRadius={'0px'} />
          <StepText>{i18n.t('medicalCarePlansFaq.medicalDecisionMaker')}</StepText>
        </StepContainer>
        <StepContainer style={{ paddingLeft: 38 }}>
          <StepIcon src={qualitySVG} alt="quality" borderRadius={'0px'} />
          <StepText>{i18n.t('medicalCarePlansFaq.definingQualityLife')}</StepText>
        </StepContainer>
        <StepContainer style={{ paddingLeft: 38 }}>
          <StepIcon src={medicalSVG} alt="medical" borderRadius={'0px'} />
          <StepText>{i18n.t('medicalCarePlansFaq.medicalCarePreferences')}</StepText>
        </StepContainer>
      </Content>
    </div>
  )
}
