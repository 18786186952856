import s from "../styles";
import { Text, View, Image, StyleSheet } from "@react-pdf/renderer";
import moment from "moment";
import { PatientFragmentFragment } from "generated/graphql";
import { PlanFontFamily } from "../../PlanFontFamily";
import secureShieldSVG from "./secure-shield.png";

const textLine = { ...s.text, marginTop: 3 };

const internalStyles = StyleSheet.create({
  cursiveText: {
    ...textLine,
    fontFamily: PlanFontFamily.cursive,
  },
});

interface ESignCertificateProps {
  data: PatientFragmentFragment;
}

export default function ESignCertificate({
  data,
}: ESignCertificateProps) {
  return (
    <View>
      <View style={s.sectionContainer}>
        <Text style={{ ...s.sectionTitle, marginBottom: 0 }}>
          Electronic Signing Certificate
        </Text>
        <View style={{ display: "flex", flexDirection: "row" }}>
          <View style={{ width: 24, marginRight: 8 }}>
            <Image
              src={secureShieldSVG}
              style={{ position: "relative", top: 8 }}
            />
          </View>
          <Text style={{ ...s.textBold, marginBottom: 0, paddingRight: 32 }}>
            This signing certificate indicates that {data.firstName}{" "}
            {data.lastName} has completed the Koda Health electronic signing
            process.
          </Text>
        </View>

        <Text style={{ ...s.textBold, marginTop: 24 }}>Your eSignature</Text>
        <Text style={textLine}>
          Name: {data.patientSignatureFirstName} {data.patientSignatureLastName}
        </Text>
        <Text style={textLine}>Date of Birth: {data.birthday}</Text>
        <Text style={textLine}>State: {data.stateVersion?.name}</Text>
        <Text style={textLine}>Email: {data.email}</Text>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Text style={textLine}>Electronic Signature: </Text>
          <Text style={internalStyles.cursiveText}>
            {data.patientSignatureFirstName} {data.patientSignatureLastName}
          </Text>
        </View>
        <Text style={textLine}>
          Signed:{" "}
          {data.patientSignatureDate &&
            moment(data.patientSignatureDate).format("MM/DD/YYYY h:mma")}
        </Text>
        <Text style={{ ...s.textBold, marginTop: 24 }}>Witness #1</Text>
        <Text style={textLine}>
          Name: {data.witness1SignatureFirstName}{" "}
          {data.witness1SignatureLastName}
        </Text>
        <Text style={textLine}>Email: {data.witness1SignatureEmail}</Text>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Text style={textLine}>Electronic Signature: </Text>
          <Text style={internalStyles.cursiveText}>
            {data.witness1SignatureFirstName} {data.witness1SignatureLastName}
          </Text>
        </View>
        <Text style={textLine}>
          Signed:{" "}
          {data.witness1SignatureDate &&
            moment(data.witness1SignatureDate).format("MM/DD/YYYY h:mma")}
        </Text>
        <Text style={{ ...s.textBold, marginTop: 24 }}>Witness #2</Text>
        <Text style={textLine}>
          Name: {data.witness2SignatureFirstName}{" "}
          {data.witness2SignatureLastName}
        </Text>
        <Text style={textLine}>Email: {data.witness2SignatureEmail}</Text>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Text style={textLine}>Electronic Signature: </Text>
          <Text style={internalStyles.cursiveText}>
            {data.witness2SignatureFirstName} {data.witness2SignatureLastName}
          </Text>
        </View>
        <Text style={textLine}>
          Signed:{" "}
          {data.witness2SignatureDate &&
            moment(data.witness2SignatureDate).format("MM/DD/YYYY h:mma")}
        </Text>
      </View>
    </View>
  );
}

// State: {User’s State}

// Email: user’s email address

// Electronic Signature:

// Signed:
