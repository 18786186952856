// TOP LEVEL IMPORTS
import React from 'react';
import styled from 'styled-components';
import Row from 'components/common/Row';
import Col from 'components/common/Col';
import Header from 'components/text/Header';
// COMPONENTS

const Container = styled(Row)`
  border-top: 1px solid ${(p) => p.theme.colors.neutral10};
  padding-top: 16px;
  margin-top: 32px;
  margin-bottom: 25px;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    margin-bottom: 0px;
    margin-top: 0px;
  }
  @media only screen and (max-width: 375px) {
    margin-bottom: 0px;
    margin-top: 0px;
  }
`;

const TextContainer = styled.div`
  max-width: 100%;
`;

const Body = styled.div`
  max-width: 100%;
  min-height: 84px;
  font-size: 15px;
  font-family: ${(p) => p.theme.fontFamily};
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.56;
  letter-spacing: normal;
  color: ${(p) => p.theme.colors.neutral5};
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    min-height: 0px;
  }
`;

interface PageSectionProps {
  header?: string;
  body?: string;
}

const PageSection = (props: PageSectionProps) => {
  const { header, body } = props

  return (
    <div>
      <Header>{header}</Header>
      <div />
      <TextContainer>
        <Body>{body}</Body>
      </TextContainer>
    </div>
  );

}


interface SectionBreakProps {
  header?: string;
  body?: string;
  children: JSX.Element | JSX.Element[];
}

const SectionBreak = (props: SectionBreakProps) => {
  const { header, body, children } = props
  
  return (
    <div>
      <Container align="top">
        <Col xs={24} md={12}>
          <PageSection header={header} body={body} />
        </Col>
        <Col xs={24} md={12}>
          {children}
        </Col>
      </Container>
    </div>
  );

}

export default SectionBreak;
