import styled from 'styled-components';

const Header = styled.div`
  min-height: 28px;
  font-size: 18px;
  font-family: ${(p) => p.theme.fontFamily};
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 145%;
  letter-spacing: normal;
  color: ${(p) => p.theme.colors.neutral2};
`;

export default Header;
