import { Text, View, Image, StyleSheet } from '@react-pdf/renderer'
// helpers
import s from '../../../KodaContent/styles'
import docHelpers from '../../../KodaContent/helpers'
import aboutMeSVG from 'components/common/PlanDoc/imgs/about-me.png'
import i18n from 'lib/helpers/i18n'
import CheckRow from 'components/common/PlanDoc/KodaContent/CheckRow'

const styles = StyleSheet.create({
  checkRowStyle: {
    padding: 4,
    paddingTop: 1,
    paddingBottom: 1,
    paddingLeft: 8,
    borderRadius: 4,
  },
})

export default function MeAndMyValues({ data }) {
  return (
    <View style={s.sectionContainer}>
      <Text style={s.sectionTitle}>{i18n.t('MasterPlanDocMeAndMyValues.title', 'About Me and My Values')}</Text>
      <View style={{ marginBottom: 8 }}>
        <Text style={s.textBold}>{i18n.t('MasterPlanDocMeAndMyValues.whatsImportant', 'What’s important to me:')}</Text>
        <Text style={s.text}>
          {data.importantToMe.map((item, i) => {
            return docHelpers.getImportantToMeItem(data, i, item)
          })}
        </Text>
      </View>
      <View style={{ display: 'flex', flexDirection: 'row' }}>
        <View style={{ flex: 3 }}>
          <Text style={s.textBold}>
            {i18n.t('MasterPlanDocMeAndMyValues.myLivingSituation', 'My Living Situation:')}
          </Text>
          <CheckRow
            label={i18n.t('MasterPlanDocMeAndMyValues.alone', 'Alone')}
            checked={data.livingSituation === 'alone'}
            containerStyle={styles.checkRowStyle}
          />
          <CheckRow
            label={i18n.t('MasterPlanDocMeAndMyValues.liveWithOthers', 'Live with other(s)')}
            checked={data.livingSituation === 'withOthers'}
            containerStyle={styles.checkRowStyle}
          />
          <CheckRow
            label={i18n.t('MasterPlanDocMeAndMyValues.assistedLiving', 'Assisted Living Facility')}
            checked={data.livingSituation === 'assistedLiving'}
            containerStyle={styles.checkRowStyle}
          />
          <CheckRow
            label={i18n.t('MasterPlanDocMeAndMyValues.independentLiving', 'Independent Living Facility')}
            checked={data.livingSituation === 'independentLiving'}
            containerStyle={styles.checkRowStyle}
          />
          <CheckRow
            label={i18n.t('MasterPlanDocMeAndMyValues.nursingHome', 'Nursing Home')}
            checked={data.livingSituation === 'nursingHome'}
            containerStyle={styles.checkRowStyle}
          />
          <CheckRow
            label={
              !data.livingSituationOther
                ? i18n.t('MasterPlanDocMeAndMyValues.other', 'Other _________________')
                : data.livingSituationOther
            }
            checked={data.livingSituation === 'other'}
            containerStyle={styles.checkRowStyle}
          />
        </View>
        <View style={{ flex: 3 }}>
          <Text style={s.textBold}>
            {i18n.t('MasterPlanDocMeAndMyValues.ifDying', 'If I were dying I would want to be:')}
          </Text>
          <CheckRow
            label={i18n.t('MasterPlanDocMeAndMyValues.home', 'Home')}
            checked={data.lastLocation.includes('home')}
            containerStyle={styles.checkRowStyle}
          />
          <CheckRow
            label={i18n.t('MasterPlanDocMeAndMyValues.hospital', 'Hospital')}
            checked={data.lastLocation.includes('hospital')}
            containerStyle={styles.checkRowStyle}
          />
          <CheckRow
            label={i18n.t('MasterPlanDocMeAndMyValues.careFacility', 'Care Facility')}
            checked={data.lastLocation.includes('careFacility')}
            containerStyle={styles.checkRowStyle}
          />
          <CheckRow
            label={i18n.t('MasterPlanDocMeAndMyValues.notSure', 'Not sure')}
            checked={data.lastLocation.includes('notSure')}
            containerStyle={styles.checkRowStyle}
          />
        </View>
        <View style={{ flex: 1, padding: 8, paddingLeft: 16 }}>
          <Image src={aboutMeSVG} />
        </View>
      </View>
    </View>
  )
}
