import { FC } from 'react';
import styled from 'styled-components'

import CheckSVG from './check.svg';

const SectionBlockOptionStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 32px;
  @media only screen and (max-width: ${({ theme }) =>
  theme.breakpoints.medium}) {
    margin: 0 20px 32px 20px;
  }
`;

const SectionBlockOptionCircle = styled.div<{ active?: boolean }>`
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background-color: ${props => props.active ? '#47A3F3' : '#BAE3FF'};
  position: relative;
  z-index: 2;
  transition: all 0.4s ease;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 24px;
  }
  &:hover {
    background-color: #47A3F3;
  }
`;

const SectionBlockOptionLabel = styled.div`
  margin-top: 8px;
  font-size: 18px;
  line-height: 21px;
  color: #1F2933;
  text-align: center;
`;

interface SectionBlockOptionProps {
  active?: boolean;
  onClick: () => void;
}

const SectionBlockOption: FC<SectionBlockOptionProps> = ({ children, active, onClick }) => (
  <SectionBlockOptionStyled>
    <SectionBlockOptionCircle onClick={onClick} active={active}>
      {active && <img src={CheckSVG} alt="Check" />}
    </SectionBlockOptionCircle>
    <SectionBlockOptionLabel>
      {children}
    </SectionBlockOptionLabel>
  </SectionBlockOptionStyled>
);

export default SectionBlockOption;
