import styled from 'styled-components';

export default styled.input<{ ref?: any }>`
  background: ${(p) => {
    if (p.disabled) {
      return p.theme.colors.neutral10;
    }
    return p.theme.colors.neutral11;
  }};
  color: ${(p) => {
    if (p.disabled) {
      return p.theme.colors.neutral7;
    }
    return p.theme.colors.neutral1;
  }};
  border: 0px;
  width: 100%;
  max-width: 100%;
  height: 50px;
  padding-left: 8px;
  border-radius: 5px;
  border: 2px solid ${(p) => p.theme.colors.neutral9};
  &:focus {
    outline: 0;
    background: #fff;
    border: 2px solid ${(p) => p.theme.colors.primary7};
  }
  &:disabled {
    cursor: not-allowed;
  }
`;
