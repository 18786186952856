import { ModalOverlay, Modal, ButtonArea } from 'components/common/KodaModal'
import styled from 'styled-components'
import Button from 'components/common/Button'
import Text from 'components/text/Text'
import ModalTitle from 'components/text/ModalTitle'
import ExitButton from 'components/common/ExitButton'
import contractSVG from 'lib/media/images/make_plan_official.svg'
import i18n from 'lib/helpers/i18n'

const ResignModal = styled(Modal)`
  width: 400px;
  overflow-y: auto;
`

// const ButtonArea = styled.div`
//   background: ${({ theme }) => theme.colors.neutral11};
//   height: 10%;
//   position: absolute;
//   bottom: 0px;
//   right: 0px;
//   left: 0px;
//   height: 80px;
//   border-bottom-left-radius: 5px;
//   border-bottom-right-radius: 5px;
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   padding: 32px;
//   @media only screen and (max-width: ${({ theme }) => theme.breakpoints.mediumSmall}) {
//     // position: fixed;
//     padding: 8px;
//     z-index: 999;
//   }
// `

const Padding = styled.div`
  text-align: left;
  padding: 32px 32px 112px 32px;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    padding: 16px 16px 112px 16px;
  }
`

interface ResignNoticeModalProps {
  visible?: boolean
  onCancel: (e?: React.MouseEvent) => void
  onResign?: (e?: React.MouseEvent<HTMLButtonElement>) => void
  loading?: boolean
}

export default function ResignNoticeModal({ visible, onCancel, onResign, loading }: ResignNoticeModalProps) {
  if (!visible) return null
  return (
    <ModalOverlay>
      <ResignModal>
        <ExitButton onClick={onCancel} />
        <Padding>
          <img
            src={contractSVG}
            alt="Contract"
            style={{ height: 50, margin: 'auto', display: 'block', marginBottom: 16 }}
          />
          <ModalTitle style={{ textAlign: 'center' }}>{i18n.t('ResignNoticeModal.title', 'Re-Sign Notice')}</ModalTitle>
          <Text style={{ marginTop: 16 }}>
            {i18n.t(
              'ResignNoticeModal.subtitle',
              `You made a change that will create a new Care Plan, and will require re-signing as per your state’s
            requirements. A copy of your previously signed document will be saved to Completed Plans. `
            )}
          </Text>
          <Text style={{ marginTop: 16, fontWeight: 700 }}>
            {i18n.t(
              'ResignNoticeModal.content',
              `Would you like to save your change knowing that you will need to re-sign your new document? `
            )}
          </Text>
        </Padding>
        <ButtonArea>
          <Button onClick={onCancel} grey style={{ minWidth: 140 }} disabled={loading}>
            {i18n.t('ResignNoticeModal.dontSave', `Don’t Save `)}
          </Button>
          <Button
            onClick={onResign}
            style={{ minWidth: 140 }}
            disabled={loading}
            loading={loading}
            data-testid="resign-modal-btn"
          >
            {i18n.t('ResignNoticeModal.save', `Save `)}
          </Button>
        </ButtonArea>
      </ResignModal>
    </ModalOverlay>
  )
}
