import styled from "styled-components";

export default styled.button<{ colorType?: "grey" | null }>`
  border: 0px;
  background: transparent;
  padding: 0px;
  margin: 0px;
  color: ${(p) => {
    if (p.colorType === "grey") {
      return p.theme.colors.supportB1;
    }
    return p.theme.colors.supportB3;
  }};
  &:hover {
    cursor: pointer;
  }
  &:focus {
    outline: 0;
  }
`;
