import { sortBy, get } from 'lodash'
// ICONS
import hobbiesSVG from 'lib/media/icons/hobbies.svg'
import otherSVG from 'lib/media/icons/other.svg'
import agreeSVG from 'lib/media/icons/agree.svg'
import religionSVG from 'lib/media/icons/religion.svg'
import friendshipSVG from 'lib/media/icons/friendship.svg'
import familySVG from 'lib/media/icons/family.svg'
import petsSVG from 'lib/media/icons/pets.svg'
import aloneSVG from 'lib/media/icons/alone.svg'
import assistedLivingSVG from 'lib/media/icons/assisted-living.svg'
import independentSVG from 'lib/media/icons/independent-living.svg'
import liveWithOthersSVG from 'lib/media/icons/live-with-others.svg'
import flexibilitySVG from 'lib/media/icons/flexible.svg'
import noFlexibilitySVG from 'lib/media/icons/no-flexibility.svg'
import notSureSVG from 'lib/media/icons/not-sure.svg'
import noSVG from 'lib/media/icons/no.svg'
import nursingHomeSVG from 'lib/media/icons/nursingHome.svg'
import noneSVG from 'lib/media/icons/none.svg'
import homeSVG from 'lib/media/icons/home.svg'
import hospitalSVG from 'lib/media/icons/hospital.svg'
import yesSVG from 'lib/media/icons/yes.svg'
import independenceSVG from 'lib/media/icons/independence.svg'
import irreversibleSVG from 'lib/media/icons/irreversible.svg'
import todaySVG from 'lib/media/icons/today.svg'
import careerSVG from 'lib/media/icons/career.svg'
// IMAGES
import notAbleToMovePNG from 'lib/media/icons/not-being-able-to-move.png'
import notAbleToTalkPNG from 'lib/media/icons/not-being-able-to-talk.png'
import notAbleToThinkPNG from 'lib/media/icons/not-being-able-to-think.png'
import notHavingRelationshipsPNG from 'lib/media/icons/not-being-able-to-have-relationships.png'
import livingInANursingHomePNG from 'lib/media/icons/living-in-a-nursing-home.png'
import livingInConstantPainPNG from 'lib/media/icons/living-in-constant-pain.png'
import livingWithDiscomfortPNG from 'lib/media/icons/living-with-severe-discomfort.png'
import notBeingAbleToBathroomPNG from 'lib/media/icons/not-being-able-to-bathroom.png'
import notBeingAbleToFeedPNG from 'lib/media/icons/not-being-able-to-feed.png'
import notBeingAbleToLiveAlonePNG from 'lib/media/icons/not-being-able-to-live-alone.png'
import severeFinancialBurdenPNG from 'lib/media/icons/severe-financial-burden.png'
import notAbleToDrivePNG from 'lib/media/icons/not-being-able-to-drive.png'
import notAbleToLeaveBedPNG from 'lib/media/icons/not-being-able-to-leave-bed.png'
//
import SelectLabel from 'components/text/SelectLabel'
import QuestionText from 'components/text/QuestionText'
import QuestionBody from 'components/text/QuestionBody'
import englishTrans from './locales/en/translation.json'
import Directions from 'components/text/Directions'
import ms from 'ms'
// LIB
import i18n from 'lib/helpers/i18n'
import { Trans } from 'react-i18next'
import getExplainer, { EXPLAINER_SECTION_ENUMS } from './getExplainer'
// TYPES
import { QuestionType, AnimationInEnum, StepNameEnum, QuestionItem } from 'lib/types/QuestionItem'
import { GuideQuestionIdEnum, NotWorthLivingEnum, YesNoNotSureEnum } from 'generated/graphql'
// GRPAHQL
import { Patient } from 'generated/graphql'
import { DeepPartial } from 'utility-types'

export default function getQuestions(
  patient: DeepPartial<Patient>,
  questionIds: GuideQuestionIdEnum[] | false
): QuestionItem[] {
  const advanceDirectiveVideo = get(patient, 'stateVersion.advanceDirectiveVideo', null) // TODO: remove this for now
  const medicalCareScenariosVideo = patient?.stateVersion?.medicalCareScenariosVideo // TODO: should use spanish tags
  const QUESTIONS = [
    {
      stepName: GuideQuestionIdEnum.HealthRisk,
      fieldName: GuideQuestionIdEnum.HealthRisk,
      fieldNames: [
        'healthIs',
        'doOnYourOwn',
        'doOnYourOwnWithoutHelp',
        'personalLossOrMisfortune',
        'takeCareSomeoneWasSeriouslyIll',
      ],
      type: QuestionType['select-columns'],
      button: i18n.t('button.continue', 'Continue'),
      required: true,
    },
    {
      stepName: GuideQuestionIdEnum.AdvanceCarePlanningKnowledge,
      fieldName: GuideQuestionIdEnum.AdvanceCarePlanningKnowledge,
      fieldNames: [
        'knowWhatAdvanceCarePlanningIs',
        'aboutWhoCanBeDecisionMaker',
        'aboutWhatMakesGoodDecisionMaker',
        'informedAboutFlexibilityDecisionMaker',
      ],
      type: QuestionType['select-columns'],
      button: i18n.t('button.continue', 'Continue'),
      required: true,
    },
    {
      stepName: GuideQuestionIdEnum.SchedulingAcPinformation,
      fieldName: GuideQuestionIdEnum.SchedulingAcPinformation,
      type: QuestionType['explainer'],
      button: i18n.t('button.continue', 'Continue'),
      required: true,
    },
    {
      stepName: GuideQuestionIdEnum.AdvanceCarePlanningReadiness,
      fieldName: GuideQuestionIdEnum.AdvanceCarePlanningReadiness,
      fieldNames: [
        'comfortLevelBecomeIll',
        'worriedFutureIllnesses',
        'decisionMakerKindOfMedicalYouWouldWant',
        'healthcareProviderKindOfMedicalYouWouldWant',
        'medicalAttorneyLivingWillAdvanceDirective',
        'statementMostAdvanceCarePlanning',
      ],
      type: QuestionType['select-columns'],
      button: i18n.t('button.continue', 'Continue'),
      required: true,
    },
    {
      stepName: GuideQuestionIdEnum.PostAcpSystemUsabilityScale,
      fieldName: GuideQuestionIdEnum.PostAcpSystemUsabilityScale,
      fieldNames: [
        'postAcpUseSystemFrequently',
        'postAcpSystemUnnecessarilyComplex',
        'postAcpSystemWasEasyUse',
        'postAcpSupportTechnicalPerson',
        'postAcpVariousFunctionsWellIntegrated',
        'postAcpTooMuchInconsistency',
        'postAcpMostPeopleWouldLearn',
        'postAcpSystemVeryCumbersomeToUse',
        'postAcpConfidentUsingTheSystem',
        'postAcpLearnLotPfThingsBefore',
      ],
      type: QuestionType['select-columns'],
      button: i18n.t('button.continue', 'Continue'),
      required: true,
    },
    {
      stepName: GuideQuestionIdEnum.SchedulingAcPinformation,
      fieldName: GuideQuestionIdEnum.SchedulingAcPinformation,
      type: QuestionType['select-columns'],
      button: i18n.t('button.continue', 'Continue'),
      required: true,
    },
    {
      stepName: GuideQuestionIdEnum.PostAcpDigitalAdvanceCarePlanning,
      fieldName: GuideQuestionIdEnum.PostAcpDigitalAdvanceCarePlanning,
      fieldNames: ['postAcpDigitalAdvanceCarePlanning'],
      type: QuestionType['select-columns'],
      button: i18n.t('button.continue', 'Continue'),
      required: true,
    },
    {
      stepName: GuideQuestionIdEnum.PostAcpAdvanceCarePlanningKnowledge,
      fieldName: GuideQuestionIdEnum.PostAcpAdvanceCarePlanningKnowledge,
      fieldNames: [
        'postAcpKnowWhatAdvanceCarePlanningIs',
        'postAcpAboutWhoCanBeDecisionMaker',
        'postAcpAboutWhatMakesGoodDecisionMaker',
        'postAcpInformedAboutFlexibilityDecisionMaker',
      ],
      type: QuestionType['select-columns'],
      button: i18n.t('button.continue', 'Continue'),
      required: true,
    },
    {
      stepName: GuideQuestionIdEnum.PostAcpAdvanceCarePlanningReadiness,
      fieldName: GuideQuestionIdEnum.PostAcpAdvanceCarePlanningReadiness,
      fieldNames: [
        'postAcpComfortLevelBecomeIll',
        'postAcpWorriedFutureIllnesses',
        'postAcpDecisionMakerKindOfMedicalYouWouldWant',
        'postAcpHealthcareProviderKindOfMedicalYouWouldWant',
        'postAcpHealthcareSystemTrust',
      ],
      type: QuestionType['select-columns'],
      button: i18n.t('button.continue', 'Continue'),
      required: true,
    },
    {
      stepName: GuideQuestionIdEnum.PostAcpContentPreferencesAssessment,
      fieldName: GuideQuestionIdEnum.PostAcpContentPreferencesAssessment,
      fieldNames: [
        'postAcpOnlineFormat',
        'postAcpMedicalEducationPrefer',
        'postAcpInfoAboutCPR',
        'postAcpStatementMostAdvanceCarePlanning',
      ],
      type: QuestionType['select-columns'],
      button: i18n.t('button.continue', 'Continue'),
      required: true,
    },
    {
      stepName: GuideQuestionIdEnum.ImportantToMe,
      type: QuestionType['select-columns'],
      multiple: true,
      fieldName: GuideQuestionIdEnum.ImportantToMe,
      otherFieldName: GuideQuestionIdEnum.ImportantToMeOther,
      text: i18n.t('importantToMe.text', `Now let’s learn more about you. What is important to you?`),
      shortText: i18n.t('importantToMe.shortText', `What is important to you?`),
      options: [
        {
          id: 'family',
          label: i18n.t('importantToMe.family', 'Family'),
          img: familySVG,
        },
        {
          id: 'friends',
          label: i18n.t('importantToMe.friends', 'Friends'),
          img: friendshipSVG,
        },
        {
          id: 'religion',
          label: i18n.t('importantToMe.religion', 'Religion'),
          img: religionSVG,
        },
        {
          id: 'hobbies',
          label: i18n.t('importantToMe.hobbies', 'Hobbies'),
          img: hobbiesSVG,
        },
        {
          id: 'pets',
          label: i18n.t('importantToMe.pets', 'Pets'),
          img: petsSVG,
        },
        {
          id: 'independence',
          label: i18n.t('importantToMe.independence', 'Independence'),
          img: independenceSVG,
        },
        {
          id: 'career',
          label: i18n.t('importantToMe.career', 'Career'),
          img: careerSVG,
        },
        {
          id: 'other',
          label: i18n.t('importantToMe.other', 'Other'),
          img: otherSVG,
        },
      ],
      required: true,
      button: i18n.t('button.continue', 'Continue'),
    },
    {
      stepName: GuideQuestionIdEnum.AffordMedications,
      type: QuestionType['select-icons'],
      multiple: false,
      fieldName: GuideQuestionIdEnum.AffordMedications,
      text: i18n.t('affordMedications.text'),
      options: [
        {
          id: YesNoNotSureEnum.Yes,
          label: i18n.t('affordMedications.yes'),
          img: yesSVG,
        },
        {
          id: YesNoNotSureEnum.No,
          label: i18n.t('affordMedications.no'),
          img: noSVG,
        },
      ],
      required: true,
      button: i18n.t('button.continue', 'Continue'),
    },
    // PERSONAS
    {
      stepName: GuideQuestionIdEnum.PersonaVacation,
      type: QuestionType.persona,
      text: i18n.t('personaVacation.text', `When planning for a vacation, how important is it to plan every detail?`),
      fieldName: GuideQuestionIdEnum.PersonaVacation,
      options: [
        {
          label: i18n.t('personaVacation.notImportant', 'Not Important'),
          value: 1,
        },
        {
          label: i18n.t('personaVacation.verySlightlyImportant', 'Very slightly important'),
          value: 2,
        },
        {
          label: i18n.t('personaVacation.slightlyImportant', 'Slightly important'),
          value: 3,
        },
        {
          label: i18n.t('personaVacation.fairlyImportant', 'Fairly important'),
          value: 4,
        },
        {
          label: i18n.t('personaVacation.important', 'Important'),
          value: 5,
        },
        {
          label: i18n.t('personaVacation.veryImportant', 'Very Important'),
          value: 6,
        },
      ],
      required: true,
      button: i18n.t('button.continue', 'Continue'),
    },

    {
      fieldName: 'personaSocialTies',
      stepName: 'personaSocialTies',
      type: QuestionType.persona,
      text: i18n.t('personaSocialTies.text', `How important are your social ties with family and friends?`),

      options: [
        {
          label: i18n.t('personaSocialTies.notImportant', 'Not Important'),
          value: 1,
        },
        {
          label: i18n.t('personaSocialTies.verySlightlyImportant', 'Very slightly important'),
          value: 2,
        },
        {
          label: i18n.t('personaSocialTies.slightlyImportant', 'Slightly important'),
          value: 3,
        },
        {
          label: i18n.t('personaSocialTies.fairlyImportant', 'Fairly important'),
          value: 4,
        },
        {
          label: i18n.t('personaSocialTies.important', 'Important'),
          value: 5,
        },
        {
          label: i18n.t('personaSocialTies.veryImportant', 'Very Important'),
          value: 6,
        },
      ],
      required: true,
      button: i18n.t('button.continue', 'Continue'),
    },
    {
      fieldName: GuideQuestionIdEnum.LivingSituation,
      stepName: GuideQuestionIdEnum.LivingSituation,
      type: QuestionType['select-columns'],
      multiple: false,
      required: true,

      otherFieldName: GuideQuestionIdEnum.LivingSituationOther,
      text: i18n.t('livingSituation.text', 'What is your living situation?'),
      options: [
        {
          id: 'alone',
          label: i18n.t('livingSituation.alone', 'Alone'),
          img: aloneSVG,
        },
        {
          id: 'withOthers',
          label: i18n.t('livingSituation.withOthers', 'With Others'),
          img: liveWithOthersSVG,
        },
        {
          id: 'assistedLiving',
          label: i18n.t('livingSituation.assistedLiving', 'Assisted Living Facility'),
          img: assistedLivingSVG,
        },
        {
          id: 'independentLiving',
          label: i18n.t('livingSituation.independentLiving', 'Independent Living Facility'),
          img: independentSVG,
        },
        {
          id: 'nursingHome',
          label: i18n.t('livingSituation.nursingHome', 'Nursing Home'),
          img: nursingHomeSVG,
        },
        {
          id: 'other',
          label: i18n.t('livingSituation.other', 'Other'),
          img: otherSVG,
        },
      ],
      button: i18n.t('button.continue', 'Continue'),
    },
    {
      stepName: 'videoQOL',
      type: QuestionType.video,
      required: false,
      text: i18n.t(
        'videoQOL',
        `Quality of Life is different for each person. Watch the video to learn more about what it means to you.`
      ),
      videoId: i18n.t('videos.videoQOL', '441046910'),
      button: i18n.t('button.continue', 'Continue'),
      skippable: true,
    },
    {
      fieldName: GuideQuestionIdEnum.MeaningOfLife,
      stepName: GuideQuestionIdEnum.MeaningOfLife,
      type: QuestionType.slider,
      text: i18n.t(
        'meaningOfLife.text',
        `Quality of Life is different for each person. What does quality of life mean to you?`
      ),
      shortText: i18n.t('meaningOfLife.shortText', `Quality of life to me means`),
      required: true,

      animationIn: AnimationInEnum.fadeIn,
      button: i18n.t('button.continue', 'Continue'),
    },
    {
      fieldName: GuideQuestionIdEnum.NotWorthLiving,
      stepName: GuideQuestionIdEnum.NotWorthLiving,
      type: QuestionType['select-icons'],
      multiple: true,
      text: (
        <Trans i18nKey="notWorthLiving.text">
          <QuestionText>
            When you think about your <strong>ability to communicate</strong>, what would be considered an
            <strong> unacceptable quality of life?</strong>
          </QuestionText>
        </Trans>
      ),
      // text: (
      //   <QuestionText>
      //     When you think about your {<strong>ability to communicate</strong>},
      //     what would be considered an{' '}
      //     {<strong>unacceptable quality of life?</strong>}
      //   </QuestionText>
      // ),
      options: [
        {
          id: NotWorthLivingEnum.NotAbleToMove,
          label: i18n.t(
            'notWorthLiving.notAbleToMove',
            'Not being able to move around and interact with people (such as a coma)'
          ),
          img: notAbleToMovePNG,
        },
        {
          id: NotWorthLivingEnum.NotAbleToThink,
          label: i18n.t('notWorthLiving.notAbleToThink', 'Not being able to think for myself or make my own decisions'),
          img: notAbleToThinkPNG,
        },
        {
          id: 'notHavingRelationships',
          label: i18n.t(
            'notWorthLiving.notHavingRelationships',
            'Not being able to have meaningful relationships or recognize family/friends'
          ),
          img: notHavingRelationshipsPNG,
        },
        {
          id: 'notAbleToTalk',
          label: i18n.t('notWorthLiving.notAbleToTalk', 'Not being able to talk or be understood by others'),
          img: notAbleToTalkPNG,
        },
        {
          id: 'none',
          label: i18n.t('notWorthLiving.none', 'None of the above would be hard on my quality of life'),
          img: noneSVG,
        },
      ],
      canBeNone: true,
      required: true,
      button: i18n.t('button.continue', 'Continue'),
    },
    {
      fieldName: 'notWorthLiving2',
      stepName: 'notWorthLiving2',
      type: QuestionType['select-icons'],
      // animationIn: 'fadeIn',
      multiple: true,
      text: (
        <Trans i18nKey="notWorthLiving2.text">
          <QuestionText>
            When you think about your <strong>mobility</strong>, what would be considered an
            <strong>unacceptable quality of life?</strong>
          </QuestionText>
        </Trans>
      ),
      // text: (
      //   <QuestionText>
      //     When you think about your {<strong>mobility</strong>}, what would be
      //     considered an {<strong>unacceptable quality of life?</strong>}
      //   </QuestionText>
      // ),
      options: [
        {
          id: NotWorthLivingEnum.NotAbleToDrive,
          label: i18n.t('notWorthLiving2.notAbleToDrive', 'Not being able to drive a car'),
          img: notAbleToDrivePNG,
        },
        {
          id: NotWorthLivingEnum.NotAbleToLeaveBed,
          label: i18n.t('notWorthLiving2.notAbleToLeaveBed', 'Not being able to get out of bed'),
          img: notAbleToLeaveBedPNG,
        },
        {
          id: NotWorthLivingEnum.NotBeingAbleToLiveAlone,
          label: i18n.t('notWorthLiving2.notBeingAbleToLiveAlone', 'Not being able to live on my own'),
          img: notBeingAbleToLiveAlonePNG,
        },
        {
          id: NotWorthLivingEnum.LivingInANursingHome,
          label: i18n.t('notWorthLiving2.livingInANursingHome', 'Living in a nursing home'),
          img: livingInANursingHomePNG,
        },
        {
          id: 'none',
          label: i18n.t('notWorthLiving2.none', 'None of the above would be hard on my quality of life'),
          img: noneSVG,
        },
      ],
      canBeNone: true,
      required: true,
      button: i18n.t('button.continue', 'Continue'),
    },
    {
      stepName: 'breakEncourageQOL',
      type: 'section-break',
      text: (
        <Trans i18nKey="breakEncourageQOL">
          You're doing great, {{ patient: (patient && patient.firstName) || '' }}! We will review a few more scenarios
          that would be hard on your quality of life.
        </Trans>
      ),
      // text: `You’re doing great, ${
      //   (patient && patient.firstName) || ''
      // }! We will review a few more scenarios that would be hard on your quality of life.`,
      required: false,
      buttonAlign: 'center',
      button: i18n.t('button.continue', 'Continue'),
    },
    {
      stepName: 'notWorthLiving3',
      fieldName: 'notWorthLiving3',
      type: QuestionType['select-icons'],
      multiple: true,
      text: (
        <Trans i18nKey="notWorthLiving3.text">
          <QuestionText>
            When you think about your <strong>independence</strong>, what would be considered an
            <strong> unacceptable quality of life?</strong>
          </QuestionText>
        </Trans>
      ),
      // text: (
      //   <QuestionText>
      //     <QuestionText>
      //       When you think about your {<strong>independence</strong>}, what
      //       would be considered an{' '}
      //       {<strong>unacceptable quality of life?</strong>}
      //     </QuestionText>
      //   </QuestionText>
      // ),
      options: [
        {
          id: NotWorthLivingEnum.SevereFinancialBurden,
          label: i18n.t(
            'notWorthLiving3.severeFinancialBurden',
            'Being a burden on my loved ones due to my cost of care'
          ),
          img: severeFinancialBurdenPNG,
        },
        {
          id: NotWorthLivingEnum.NotBeingAbleToFeed,
          label: i18n.t('notWorthLiving3.notBeingAbleToFeed', 'Not being able to feed, bathe, or take care of myself'),
          img: notBeingAbleToFeedPNG,
        },
        {
          id: NotWorthLivingEnum.NotBeingAbleToBathroom,
          label: i18n.t(
            'notWorthLiving3.notBeingAbleToBathroom',
            'Not being able to control when I poop or pee, or having to wear diapers'
          ),
          img: notBeingAbleToBathroomPNG,
        },
        {
          id: NotWorthLivingEnum.LivingInConstantPain,
          label: i18n.t(
            'notWorthLiving3.livingInConstantPain',
            'Living in constant severe pain or shortness of breath'
          ),
          img: livingInConstantPainPNG,
        },
        {
          id: NotWorthLivingEnum.LivingWithDiscomfort,
          label: i18n.t(
            'notWorthLiving3.livingWithDiscomfort',
            'Being uncomfortable most of the time, such as nausea, vomiting, or diarrhea'
          ),
          img: livingWithDiscomfortPNG,
        },
        {
          id: 'none',
          label: i18n.t('notWorthLiving3.none', 'None of the above would be hard on my quality of life'),
          img: noneSVG,
        },
      ],
      canBeNone: true,
      required: true,
      button: i18n.t('button.continue', 'Continue'),
    },
    {
      fieldName: 'lastLocation',
      stepName: 'lastLocation',
      type: QuestionType['select-columns'],
      multiple: true,
      text: i18n.t(
        'lastLocation.text',
        'If you became very ill and only had a few hours or days to live, where would you want to spend them?'
      ),
      options: [
        {
          id: 'home',
          label: i18n.t('lastLocation.home', 'Home'),
          img: homeSVG,
        },
        {
          id: 'hospital',
          label: i18n.t('lastLocation.hospital', 'Hospital'),
          img: hospitalSVG,
        },
        {
          id: 'careFacility',
          label: i18n.t('lastLocation.careFacility', 'Care Facility'),
          img: independentSVG,
        },
        {
          id: 'notSure',
          label: i18n.t('lastLocation.notSure', 'Not sure'),
          img: notSureSVG,
        },
      ],
      required: true,
      button: i18n.t('button.continue', 'Continue'),
    },
    // {
    //   type: 'explainer-medical-break',
    //   required: false,
    //   button: 'Continue',
    // },
    {
      fieldName: 'videoLifeSupport',
      stepName: 'videoLifeSupport',
      type: QuestionType.video,
      text: i18n.t('videoLifeSupport', `Introduction to Life Support Treatments`),
      required: false,
      videoId: i18n.t('videos.videoLifeSupport', '449830842'),
      button: i18n.t('button.continue', 'Continue'),
      skippable: true,
    },
    {
      type: QuestionType.video,
      stepName: 'videoCPR',
      text: i18n.t('videoCPR', `Learn about Cardiopulmonary Resuscitation (CPR)`),
      required: false,
      videoId: i18n.t('videos.videoCPR', '441048542'),
      button: i18n.t('button.continue', 'Continue'),
      skippable: true,
    },
    {
      stepName: StepNameEnum.learnAboutCPR,
      type: QuestionType.explainer,
      explainerValues: getExplainer(EXPLAINER_SECTION_ENUMS.CPR, patient),
      //title: 'Learn about CPR',
      text: i18n.t(
        'learnAboutCPR.text',
        'CPR stands for cardiopulmonary resuscitation. Here is some more information about it.'
      ),
      required: false,
      button: i18n.t('button.continue', 'Continue'), // need to put in the continue button
    },
    {
      stepName: GuideQuestionIdEnum.LikeToKnowCprAgeGroup,
      type: QuestionType.select,
      //title: 'Learn about CPR',
      text: 'Would you like to know how CPR affects people in your age group ({ageRange})?',
      required: false,
      button: i18n.t('button.continue', 'Continue'), // need to put in the continue button
    },
    {
      stepName: GuideQuestionIdEnum.CprAgeGroupVideo,
      type: QuestionType.select,
      //title: 'Learn about CPR',
      text: 'Would you like to know how CPR affects people in your age group ({ageRange})?',
      required: false,
      button: i18n.t('button.continue', 'Continue'), // need to put in the continue button
    },
    {
      stepName: 'cprPreferenceToday',
      type: QuestionType.select,
      multiple: false,
      fieldName: 'cprPreferenceToday',
      scenario: i18n.t('cprPreferenceToday.scenario', 'In my current state of health today'),
      scenarioImg: todaySVG,

      text: (
        <Trans i18nKey="cprPreferenceToday.text">
          <QuestionText>
            I would want <strong>CPR </strong> to be provided as follows if my heart stopped:
          </QuestionText>
        </Trans>
      ),
      options: [
        {
          id: 'noneAtAll',
          label: (
            <Trans i18nKey="cprPreferenceToday.noneAtAll">
              <SelectLabel>
                I would want to die naturally, and I would <strong>NOT </strong>
                want CPR at all.
              </SelectLabel>
            </Trans>
          ),
        },
        {
          id: 'onlyIf',
          label: (
            <Trans i18nKey="cprPreferenceToday.onlyIf">
              <SelectLabel>
                I would want CPR <strong>only if</strong> my treatment team thought it was appropriate.
              </SelectLabel>
            </Trans>
          ),
        },
        {
          id: 'alwaysWant',
          label: (
            <Trans i18nKey="cprPreferenceToday.alwaysWant">
              <SelectLabel>
                I would want CPR <strong>always</strong>, even if my treatment team thought this treatment might be
                harmful and did not recommend it.
              </SelectLabel>
            </Trans>
          ),
        },
        {
          id: 'notSure',
          label: i18n.t('cprPreferenceToday.notSure', `I’m not sure`),
        },
      ],
      required: true,
      button: i18n.t('button.continue', 'Continue'),
    },
    {
      stepName: 'cprPreference',
      type: QuestionType.select,
      multiple: false,
      fieldName: 'cprPreference',
      scenario: i18n.t(
        'cprPreference.scenario',
        'Quality of life is unacceptable AND condition is irreversible (it will not improve)'
      ),
      scenarioImg: irreversibleSVG,

      text: (
        <Trans i18nKey="cprPreference.text">
          <QuestionText>
            I would want <strong>CPR </strong> to be provided as follows if my heart stopped:
          </QuestionText>
        </Trans>
      ),
      options: [
        {
          id: 'noneAtAll',
          label: (
            <Trans i18nKey="cprPreference.noneAtAll">
              <SelectLabel>
                I would want to die naturally, and I would <strong>NOT </strong>
                want CPR at all.
              </SelectLabel>
            </Trans>
          ),
        },
        {
          id: 'alwaysWant',
          label: (
            <Trans i18nKey="cprPreference.alwaysWant">
              <SelectLabel>
                I would want CPR <strong>always,</strong> even if my treatment team thought this treatment might be
                harmful and did not recommend it.
              </SelectLabel>
            </Trans>
          ),
        },
        {
          id: 'notSure',
          label: i18n.t('cprPreference.notSure', `I’m not sure`),
        },
      ],
      required: true,
      button: i18n.t('button.continue', 'Continue'),
    },
    //
    {
      stepName: 'videoVentilation',
      type: QuestionType.video,
      text: i18n.t('videoVentilation', 'Learn about Mechanical Ventilation'),
      videoId: i18n.t('videos.videoVentilation', '441052025'),
      required: false,
      button: i18n.t('button.continue', 'Continue'),
    },
    {
      stepName: 'learnAboutMechanicalVent',
      type: QuestionType.explainer,
      explainerValues: getExplainer(EXPLAINER_SECTION_ENUMS.MECHANICAL_VENTILATION, patient),
      //title: 'Learn about mechanical ventilation',
      text: i18n.t('learnAboutMechanicalVent.text'),
      required: false,
      button: i18n.t('button.continue', 'Continue'), // need to put in the continue button
    },
    {
      stepName: 'ventilationPreferenceToday',
      type: QuestionType.select,
      multiple: false,
      fieldName: 'ventilationPreferenceToday',
      scenario: i18n.t('ventilationPreferenceToday.scenario', 'In my current state of health today'),
      scenarioImg: todaySVG,
      text: (
        <Trans i18nKey="ventilationPreferenceToday.text">
          <QuestionText>
            I would want
            <strong>invasive mechanical ventilation (a breathing tube and machine) </strong>
            to be provided as follows if I was not able to breathe on my own:
          </QuestionText>
        </Trans>
      ),
      options: [
        {
          id: 'noneAtAll',
          label: (
            <Trans i18nKey="ventilationPreferenceToday.noneAtAll">
              <SelectLabel>
                I would want to die naturally, and I would <strong>NOT</strong>
                want a breathing tube and machine at all.
              </SelectLabel>
            </Trans>
          ),
        },
        {
          id: 'trial',
          label: (
            <Trans i18nKey="ventilationPreferenceToday.trial">
              <SelectLabel>
                I would want a <strong>short trial</strong> of a breathing tube and machine if recommended by my
                treatment team and if it will reasonably preserve what I consider as quality of life. Treatment should
                be stopped when it does not accomplish this goal.
              </SelectLabel>
            </Trans>
          ),
        },
        {
          id: 'anyLengthOfTime',
          label: (
            <Trans i18nKey="ventilationPreferenceToday.anyLengthOfTime">
              <SelectLabel>
                I would want to be on a breathing tube and machine
                <strong>always,</strong> for any length of time - even if my treatment team thought this treatment might
                be harmful and did not recommend it.
              </SelectLabel>
            </Trans>
          ),
        },
        {
          id: 'notSure',
          label: i18n.t('ventilationPreferenceToday.notSure', `I’m not sure`),
        },
      ],
      required: true,
      button: i18n.t('button.continue', 'Continue'),
    },
    {
      stepName: 'ventilationPreference',
      type: QuestionType.select,
      multiple: false,
      fieldName: 'ventilationPreference',
      scenario: i18n.t(
        'ventilationPreference.scenario',
        'Quality of life is unacceptable AND condition is irreversible (it will not improve)'
      ),
      scenarioImg: irreversibleSVG,
      text: (
        <Trans i18nKey="ventilationPreference.text">
          <QuestionText>
            I would want
            <strong>invasive mechanical ventilation (a breathing tube and machine)</strong>
            to be provided as follows if I was not able to breathe on my own:
          </QuestionText>
        </Trans>
      ),
      options: [
        {
          id: 'noneAtAll',
          label: (
            <Trans i18nKey="ventilationPreference.noneAtAll">
              <SelectLabel>
                I would want to die naturally, and I would <strong>NOT</strong>
                want a breathing tube and machine at all.
              </SelectLabel>
            </Trans>
          ),
        },
        {
          id: 'trial',
          label: (
            <Trans i18nKey="ventilationPreference.trial">
              <SelectLabel>
                I would want a <strong>short trial</strong> of a breathing tube and machine even if my quality of life
                remains unacceptable.
              </SelectLabel>
            </Trans>
          ),
        },
        {
          id: 'anyLengthOfTime',
          label: (
            <Trans i18nKey="ventilationPreference.anyLengthOfTime">
              <SelectLabel>
                I would want to be on a breathing tube and machine
                <strong>always,</strong> for any length of time - even if my treatment team thought this treatment might
                be harmful and did not recommend it.
              </SelectLabel>
            </Trans>
          ),
        },
        {
          id: 'notSure',
          label: i18n.t('ventilationPreference.notSure', `I’m not sure`),
        },
      ],
      required: true,
      button: i18n.t('button.continue', 'Continue'),
    },
    {
      stepName: 'videoFeedingTube',
      type: QuestionType.video,
      text: i18n.t('videoFeedingTube', 'Learn about Long-term Artificial Nutrition via a Feeding Tube'),
      fieldName: 'videoFeedingTube',
      required: false,
      videoId: i18n.t('videos.videoFeedingTube', '441048952'),
      button: i18n.t('button.continue', 'Continue'),
    },
    {
      stepName: 'learnAboutArtificialNutrition',
      type: QuestionType.explainer,
      explainerValues: getExplainer(EXPLAINER_SECTION_ENUMS.FEEDING_TUBE, patient),
      text: i18n.t('learnAboutArtificialNutrition.text'),
      required: false,
      button: i18n.t('button.continue', 'Continue'), // need to put in the continue button
    },
    {
      stepName: 'longTermFeedingToday',
      type: QuestionType.select,
      multiple: false,
      fieldName: 'longTermFeedingToday',

      scenario: i18n.t('longTermFeedingToday.scenario', 'In my current state of health today'),
      scenarioImg: todaySVG,
      text: (
        <Trans i18nKey="longTermFeedingToday.text">
          <QuestionText>
            I would want
            <strong>long-term artificial nutrition via a feeding tube</strong>to be provided as follows:
          </QuestionText>
        </Trans>
      ),
      options: [
        {
          id: 'noneAtAll',
          label: (
            <Trans i18nKey="longTermFeedingToday.noneAtAll">
              <SelectLabel>
                I would want to die naturally, and I would <strong>NOT</strong>
                want long-term artificial nutrition via a feeding tube at all.
              </SelectLabel>
            </Trans>
          ),
        },
        {
          id: 'onlyIf',
          label: (
            <Trans i18nKey="longTermFeedingToday.onlyIf">
              <SelectLabel>
                I would want long-term artificial nutrition via a feeding tube
                <strong>only if</strong> this will preserve what I consider quality of life. Treatment should be stopped
                when it does not accomplish this goal.
              </SelectLabel>
            </Trans>
          ),
        },
        {
          id: 'alwaysWant',
          label: (
            <Trans i18nKey="longTermFeedingToday.alwaysWant">
              <SelectLabel>
                I would want long-term artificial nutrition via a feeding tube
                <strong>always,</strong> for any length of time and in any condition - even if my treatment team thought
                this treatment might be harmful and did not recommend it.
              </SelectLabel>
            </Trans>
          ),
        },
        {
          id: 'notSure',
          label: i18n.t('longTermFeedingToday.notSure', `I'm not sure`),
        },
      ],
      required: true,
      button: i18n.t('button.continue', 'Continue'),
    },
    {
      stepName: 'longTermFeeding',
      type: QuestionType.select,
      multiple: false,
      fieldName: 'longTermFeeding',
      scenario: i18n.t(
        'longTermFeeding.scenario',
        'Quality of life is unacceptable AND condition is irreversible (it will not improve)'
      ),
      scenarioImg: irreversibleSVG,
      text: (
        <Trans i18nKey="longTermFeeding.text">
          <QuestionText>
            I would want
            <strong>long-term artificial nutrition via a feeding tube</strong> to be provided as follows:
          </QuestionText>
        </Trans>
      ),
      options: [
        {
          id: 'noneAtAll',
          label: (
            <Trans i18nKey="longTermFeeding.noneAtAll">
              <SelectLabel>
                I would want to die naturally, and I would <strong>NOT</strong> want long-term artificial nutrition via
                a feeding tube at all.
              </SelectLabel>
            </Trans>
          ),
        },
        {
          id: 'alwaysWant',
          label: (
            <Trans i18nKey="longTermFeeding.alwaysWant">
              <SelectLabel>
                I would want long-term artificial nutrition via a feeding tube
                <strong>always,</strong> for any length of time and in any condition - even if my treatment team thought
                this treatment might be harmful and did not recommend it.
              </SelectLabel>
            </Trans>
          ),
        },
        {
          id: 'notSure',
          label: i18n.t('longTermFeeding.notSure', `I'm not sure`),
        },
      ],
      required: true,
      button: i18n.t('button.continue', 'Continue'),
    },
    {
      stepName: 'videoDialysis',
      type: QuestionType.video,
      text: i18n.t('videoDialysis', 'Learn about Dialysis'),
      required: false,
      videoId: i18n.t('videos.videoDialysis', '441055391'),
      button: i18n.t('button.continue', 'Continue'),
    },
    {
      stepName: 'learnAboutDialysis',
      type: QuestionType.explainer,
      explainerValues: getExplainer(EXPLAINER_SECTION_ENUMS.DIALYSIS, patient),
      text: i18n.t('learnAboutDialysis.text'),
      required: false,
      button: i18n.t('button.continue', 'Continue'), // need to put in the continue button
    },
    {
      stepName: 'dialysisTreatmentToday',
      type: QuestionType.select,
      multiple: false,
      fieldName: 'dialysisTreatmentToday',
      scenario: i18n.t('dialysisTreatmentToday.scenario', 'In my current state of health today'),
      scenarioImg: todaySVG,
      text: (
        <Trans i18nKey="dialysisTreatmentToday.text">
          <QuestionText>
            I would want <strong>dialysis </strong> to be provided as follows:
          </QuestionText>
        </Trans>
      ),
      options: [
        {
          id: 'noneAtAll',
          label: (
            <Trans i18nKey="dialysisTreatmentToday.noneAtAll">
              <SelectLabel>
                I would want to die naturally, and I would <strong>NOT</strong> want long-term dialysis at all.
              </SelectLabel>
            </Trans>
          ),
        },
        {
          id: 'onlyIf',
          label: (
            <Trans i18nKey="dialysisTreatmentToday.onlyIf">
              <SelectLabel>
                I would want to be on long-term dialysis
                <strong>only if</strong> my treatment team recommended it and this will preserve what I consider quality
                of life. Treatment should be stopped when it does not accomplish this goal.
              </SelectLabel>
            </Trans>
          ),
        },
        {
          id: 'anyLengthOfTime',
          label: (
            <Trans i18nKey="dialysisTreatmentToday.anyLengthOfTime">
              <SelectLabel>
                I would want to be on long-term dialysis
                <strong>always,</strong> for any length of time and in any condition - even if my treatment team thought
                this treatment might be harmful and did not recommend it.
              </SelectLabel>
            </Trans>
          ),
        },
        {
          id: 'notSure',
          label: i18n.t('dialysisTreatmentToday.notSure', `I'm not sure`),
        },
      ],
      required: true,
      button: i18n.t('button.continue', 'Continue'),
    },
    {
      stepName: 'dialysisTreatment',
      type: QuestionType.select,
      multiple: false,
      fieldName: 'dialysisTreatment',
      scenario: i18n.t(
        'dialysisTreatment.scenario',
        'Quality of life is unacceptable AND condition is irreversible (it will not improve)'
      ),
      scenarioImg: irreversibleSVG,
      text: (
        <Trans i18nKey="dialysisTreatment.text">
          <QuestionText>
            I would want <strong>dialysis </strong> to be provided as follows:
          </QuestionText>
        </Trans>
      ),
      options: [
        {
          id: 'noneAtAll',
          label: (
            <Trans i18nKey="dialysisTreatment.noneAtAll">
              <SelectLabel>
                I would want to die naturally, and I would <strong>NOT</strong>
                want long-term dialysis at all.
              </SelectLabel>
            </Trans>
          ),
        },
        {
          id: 'anyLengthOfTime',
          label: (
            <Trans i18nKey="dialysisTreatment.anyLengthOfTime">
              <SelectLabel>
                I would want to be on long-term dialysis
                <strong>always,</strong> for any length of time and in any condition - even if my treatment team thought
                this treatment might be harmful and did not recommend it.
              </SelectLabel>
            </Trans>
          ),
        },
        {
          id: 'notSure',
          label: i18n.t('dialysisTreatment.notSure', `I'm not sure`),
        },
      ],
      required: true,
      button: i18n.t('button.continue', 'Continue'),
    },
    {
      stepName: 'additionalCarePreferences',
      type: QuestionType.textarea,
      showSkipButton: true,
      text: i18n.t(
        'additionalCarePreferences',
        'Is there anything else you want your medical providers or medical decision maker to know about your medical care preferences.'
      ),
      fieldName: 'additionalCarePreferences',
      required: false,
      button: i18n.t('button.continue', 'Continue'),
    },

    {
      stepName: GuideQuestionIdEnum.SpiritualAndReligiousBeliefs,
      type: QuestionType.textarea,
      showSkipButton: true,
      text: i18n.t(
        'spiritualAndReligiousBeliefs',
        `Do you have spiritual or religious beliefs you want your medical
        decision maker and those taking care of
        you to know? They can be rituals, sacraments, denying blood product
        transfusions and more.`
      ),
      directions: i18n.t('skippableDirections', '(Optional - Click continue if you don’t want to provide right now)'),
      fieldName: GuideQuestionIdEnum.SpiritualAndReligiousBeliefs,
      required: false,
      button: i18n.t('button.continue', 'Continue'),
    },

    {
      stepName: GuideQuestionIdEnum.LifeAndValues,
      type: QuestionType.textarea,
      showSkipButton: true,
      text: i18n.t(
        'lifeAndValues',
        `Is there anything you want to share about your life and values to
        help your medical decision maker and
        health care providers make decisions about your health care. This
        might include family history, experiences with health care, cultural
        background, career, social support system and more.`
      ),
      directions: i18n.t('skippableDirections', '(Optional - Click continue if you don’t want to provide right now)'),
      fieldName: GuideQuestionIdEnum.LifeAndValues,
      required: false,
      button: i18n.t('button.continue', 'Continue'),
    },
    {
      stepName: GuideQuestionIdEnum.ReceiveCareOther,
      type: QuestionType.textarea,
      showSkipButton: true,
      text: i18n.t(
        'receiveCareOther',
        `Is there any additional information about where you want or do not
        want to receive care? Or any extra information about quality of life
        preferences that you would like to provide?`
      ),
      directions: i18n.t('skippableDirections', '(Optional - Click continue if you don’t want to provide right now)'),
      fieldName: GuideQuestionIdEnum.ReceiveCareOther,
      required: false,
      button: i18n.t('button.continue', 'Continue'),
    },

    {
      stepName: GuideQuestionIdEnum.MedicalCareScenariosVideo,
      type: QuestionType.video,
      text: i18n.t('videoLifeSupport', 'Introduction to Life Support Treatments'),
      required: false,
      videoId: medicalCareScenariosVideo,
      button: i18n.t('button.continue', 'Continue'),
    },
    {
      stepName: GuideQuestionIdEnum.MedicalCareScenariosIntro,
      type: QuestionType['section-break'],
      text: i18n.t(
        'medicalCareScenariosIntro',
        'There are three scenarios for you to express your wishes. They will help you think about the kinds of life support decisions your medical decision maker could face.'
      ),
      goToNextTimer: ms('12.5s'),
      required: false,
      buttonAlign: 'center',
      button: i18n.t('button.continue', 'Continue'),
    },

    {
      stepName: GuideQuestionIdEnum.VideoAd,
      type: QuestionType.video,
      required: false,
      videoId: advanceDirectiveVideo ? advanceDirectiveVideo : i18n.t('videos.advanceDirectiveVideo', '441053475'),
      button: i18n.t('button.continue', 'Continue'),
    },
    {
      stepName: GuideQuestionIdEnum.VideoMedicalDecisionMaker,
      type: QuestionType.video,
      text: i18n.t('videoMedicalDecisionMaker', 'Learn about Selecting a Medical Decision Maker'),
      required: false,
      videoId: i18n.t('videos.videoMedicalDecisionMaker', '441051972'),
      button: i18n.t('button.continue', 'Continue'),
    },
    {
      stepName: GuideQuestionIdEnum.DoYouKnowDecisionMaker,
      fieldName: GuideQuestionIdEnum.DoYouKnowDecisionMaker,
      type: QuestionType['select-columns'],
      multiple: false,

      text: i18n.t('doYouKnowDecisionMaker.text', `Are you comfortable selecting a medical decision maker today?`),
      options: [
        {
          id: 'yes',
          label: i18n.t('doYouKnowDecisionMaker.yes', `Yes`),
          img: yesSVG,
        },
        {
          id: 'no',
          label: i18n.t('doYouKnowDecisionMaker.no', `No, not sure`),
          img: notSureSVG,
        },
      ],
      required: true,
      button: i18n.t('button.continue', 'Continue'),
    },
    {
      type: QuestionType['two-strings'],
      stepName: GuideQuestionIdEnum.DecisionMakerName,
      inputs: [
        {
          fieldName: 'decisionMakerFirstName',
          text: i18n.t('decisionMakerName.firstName', 'First name:'),
        },
        {
          fieldName: 'decisionMakerLastName',
          text: i18n.t('decisionMakerName.lastName', 'Last name:'),
        },
      ],
      text: (
        <Trans i18nKey="decisionMakerName.text">
          <QuestionText>
            Congrats! Selecting this person is a big decision and one of the best ways to ensure your wishes are
            fulfilled.
          </QuestionText>
          <QuestionText>Who would you like to select?</QuestionText>
        </Trans>
      ),
      required: true,
      button: i18n.t('button.continue', 'Continue'),
    },
    {
      stepName: GuideQuestionIdEnum.DecisionMakerRelationship,
      type: QuestionType.select,
      multiple: false,
      fieldName: GuideQuestionIdEnum.DecisionMakerRelationship,
      text: i18n.t('decisionMakerRelationship.text', `How do you know this person?`),
      otherFieldName: GuideQuestionIdEnum.DecisionMakerRelationshipOther,
      options: [
        {
          id: 'spouse',
          label: i18n.t('decisionMakerRelationship.spouse', `Husband/Wife`),
        },
        {
          id: 'child',
          label: i18n.t('decisionMakerRelationship.child', `Child`),
        },
        {
          id: 'brotherOrSister',
          label: i18n.t('decisionMakerRelationship.brotherOrSister', `Brother/Sister`),
        },
        {
          id: 'otherFamily',
          label: i18n.t('decisionMakerRelationship.otherFamily', `Other family member`),
        },
        {
          id: 'friend',
          label: i18n.t('decisionMakerRelationship.friend', `Friend`),
        },
        {
          id: 'other',
          label: i18n.t('decisionMakerRelationship.other', `Type other answer here`),
        },
      ],
      required: true,
      button: i18n.t('button.continue', 'Continue'),
    },
    {
      stepName: GuideQuestionIdEnum.DecisionMakerStrictness,
      type: QuestionType['select-icons'],
      multiple: false,
      fieldName: GuideQuestionIdEnum.DecisionMakerStrictness,
      text: i18n.t('decisionMakerStrictness.text', `How strictly do you want him/her to follow your wishes?`),
      options: [
        {
          id: 'flexibility',
          label: i18n.t(
            'decisionMakerStrictness.flexibility',
            `Full Flexibility - it's okay to change my medical decisions if my doctors think it's best for me at the time.`
          ),
          img: flexibilitySVG,
        },
        {
          id: 'noFlexibility',
          label: i18n.t(
            'decisionMakerStrictness.noFlexibility',
            `No Flexibility - it's not okay to change my medical decisions even if the doctor recommends it.`
          ),
          img: noFlexibilitySVG,
        },
      ],
      required: true,
      button: i18n.t('button.continue', 'Continue'),
    },
    {
      stepName: GuideQuestionIdEnum.AdditionalMdmDirections,
      type: QuestionType.textarea,
      showSkipButton: true,
      text: i18n.t(
        'additionalMdmDirections.text',
        'If there are decisions you do not want them to make (limitations on the decision-making authority), write them here:'
      ),
      fieldName: 'additionalMdmDirections',
      required: false,
      button: i18n.t('button.continue', 'Continue'),
    },
    // FINISH ADDING NEW QUESTION : https://www.figma.com/file/5KnKCr3JEFB5IFiJH881gf/Koda-MVP?node-id=349%3A4
    {
      type: QuestionType['two-strings'],
      stepName: GuideQuestionIdEnum.DecisionMakerContact,
      showSkipButton: true,
      inputs: [
        {
          fieldName: 'decisionMakerEmail',
          text: i18n.t('decisionMakerContact.email', 'Email:'),
          inputType: 'email',
          inputMode: 'email',
          required: false,
        },
        {
          fieldName: 'decisionMakerPhone',
          text: i18n.t('decisionMakerContact.phone', 'Phone:'),
          inputType: 'phone',
          inputMode: 'tel',
          required: false,
        },
      ],
      text: (
        <Trans i18nKey="decisionMakerContact.text">
          <QuestionText>What is his/her email address and phone number?</QuestionText>
          <Directions>(Optional - Click "Skip" if you don’t want to provide right now)</Directions>
        </Trans>
      ),
      required: false,
      button: i18n.t('button.continue', 'Continue'),
    },
    {
      stepName: GuideQuestionIdEnum.WantsAltDecisionMaker,
      type: QuestionType['select-columns'],
      multiple: false,
      fieldName: GuideQuestionIdEnum.WantsAltDecisionMaker,
      text: (
        // `Is there someone you would like to select as an alternate decision maker if ${
        //   patient && patient.decisionMakerFirstName
        // } is unable to do so?`,

        //   (<Trans i18nKey="wantsAltDecisionMaker.text">
        //   <QuestionText>
        //     Would you like to select alternate decision makers if your medical decision maker is unable to do so?
        //   </QuestionText>
        //   <Directions>
        //     Note: You can select two additional decision makers.
        //   </Directions>
        // </Trans>),
        <Trans i18nKey="wantsAltDecisionMaker.text">
          <QuestionText>
            Is there someone you would like to select as an alternate decision maker if{' '}
            {{ decisionMaker: patient?.decisionMakerFirstName }} is unable to do so?
          </QuestionText>
          <Directions>Note: You can select two additional decision makers.</Directions>
        </Trans>
      ),
      options: [
        {
          id: 'yes',
          label: i18n.t('wantsAltDecisionMaker.yes', `Yes`),
          img: yesSVG,
        },
        {
          id: 'no',
          label: i18n.t('wantsAltDecisionMaker.no', `No`),
          img: notSureSVG,
        },
      ],
      required: true,
      button: i18n.t('button.continue', 'Continue'),
    },
    {
      type: QuestionType['two-strings'],
      stepName: GuideQuestionIdEnum.AltDecisionMakerName,
      inputs: [
        {
          fieldName: 'altDecisionMakerFirstName',
          text: i18n.t('decisionMakerName.firstName', 'First name:'),
        },
        {
          fieldName: 'altDecisionMakerLastName',
          text: i18n.t('decisionMakerName.lastName', 'Last name:'),
        },
      ],
      text: (
        <Trans i18nKey="altDecisionMakerName.text">
          <QuestionText>
            That's great! Who would you like to select as your first alternate decision maker?
          </QuestionText>
        </Trans>
      ),
      required: true,
      button: i18n.t('button.continue', 'Continue'),
    },
    {
      fieldName: GuideQuestionIdEnum.AltDecisionMakerRelationship,
      stepName: GuideQuestionIdEnum.AltDecisionMakerRelationship,
      type: QuestionType.select,
      multiple: false,
      text: i18n.t('decisionMakerRelationship.text', `How do you know this person?`),
      otherFieldName: GuideQuestionIdEnum.AltDecisionMakerRelationshipOther,
      options: [
        {
          id: 'spouse',
          label: i18n.t('decisionMakerRelationship.spouse', `Husband/Wife`),
        },
        {
          id: 'child',
          label: i18n.t('decisionMakerRelationship.child', `Child`),
        },
        {
          id: 'brotherOrSister',
          label: i18n.t('decisionMakerRelationship.brotherOrSister', `Brother/Sister`),
        },
        {
          id: 'otherFamily',
          label: i18n.t('decisionMakerRelationship.otherFamily', `Other family member`),
        },
        {
          id: 'friend',
          label: i18n.t('decisionMakerRelationship.friend', `Friend`),
        },
        {
          id: 'other',
          label: i18n.t('decisionMakerRelationship.other', `Type other answer here`),
        },
      ],
      required: true,
      button: i18n.t('button.continue', 'Continue'),
    },
    {
      type: QuestionType['two-strings'],
      showSkipButton: true,
      stepName: GuideQuestionIdEnum.AltDecisionMakerContact, // "altDecisionMakerContact"
      inputs: [
        {
          fieldName: 'altDecisionMakerEmail',
          text: i18n.t('decisionMakerContact.email', 'Email:'),
          inputType: 'email',
          inputMode: 'email',
          required: false,
        },
        {
          fieldName: 'altDecisionMakerPhone',
          text: i18n.t('decisionMakerContact.phone', 'Phone:'),
          inputType: 'phone',
          inputMode: 'tel',
          required: false,
        },
      ],
      // text: (
      //   <>
      //     <QuestionText>
      //       What is your first alternative decision maker's email address and phone
      //       number?
      //     </QuestionText>
      //     <Directions>
      //       (Optional - Click "Skip" if you don’t want to provide right now)
      //     </Directions>
      //   </>
      // ),
      text: (
        <Trans i18nKey="altDecisionMakerContact.text">
          <QuestionText>
            What is {{ altDecisionMaker: patient?.altDecisionMakerFirstName }}'s email address and phone number?
          </QuestionText>
          <Directions>(Optional - Click "Skip" if you don’t want to provide right now)</Directions>
        </Trans>
      ),
      required: false,
      button: i18n.t('button.continue', 'Continue'),
    },
    {
      stepName: GuideQuestionIdEnum.WantsThirdDecisionMaker,
      type: QuestionType['select-columns'],
      multiple: false,
      fieldName: GuideQuestionIdEnum.WantsThirdDecisionMaker,
      //text: `Is there another person you would like to select as a second alternate decision maker?`,
      text: (
        <Trans i18nKey="wantsThirdDecisionMaker.text">
          <QuestionText>
            Is there someone you would like to select as an alternate decision maker if
            {{ decisionMaker: patient?.decisionMakerFirstName }} and
            {{ altDecisionMaker: patient?.altDecisionMakerFirstName }} are unable to do so?
          </QuestionText>
        </Trans>
      ),
      options: [
        {
          id: 'yes',
          label: i18n.t('wantsThirdDecisionMaker.yes', `Yes`),
          img: yesSVG,
        },
        {
          id: 'no',
          label: i18n.t('wantsThirdDecisionMaker.no', `No`),
          img: noSVG,
        },
      ],
      required: true,
      button: i18n.t('button.continue', 'Continue'),
    },
    {
      type: QuestionType['two-strings'],
      stepName: GuideQuestionIdEnum.ThirdDecisionMakerName,
      inputs: [
        {
          fieldName: 'thirdDecisionMakerFirstName',
          text: i18n.t('decisionMakerName.firstName', 'First name:'),
        },
        {
          fieldName: 'thirdDecisionMakerLastName',
          text: i18n.t('decisionMakerName.lastName', 'Last name:'),
        },
      ],
      text: (
        <Trans i18nKey="thirdDecisionMakerName.text">
          <QuestionText>
            That's great! Who would you like to select as your second alternate decision maker?
          </QuestionText>
        </Trans>
      ),
      required: true,
      button: i18n.t('button.continue', 'Continue'),
    },
    {
      fieldName: GuideQuestionIdEnum.ThirdDecisionMakerRelationship,
      stepName: GuideQuestionIdEnum.ThirdDecisionMakerRelationship,
      type: QuestionType.select,
      multiple: false,
      text: i18n.t('decisionMakerRelationship.text', `How do you know this person?`),
      otherFieldName: GuideQuestionIdEnum.ThirdDecisionMakerRelationshipOther,
      options: [
        {
          id: 'spouse',
          label: i18n.t('decisionMakerRelationship.spouse', `Husband/Wife`),
        },
        {
          id: 'child',
          label: i18n.t('decisionMakerRelationship.child', `Child`),
        },
        {
          id: 'brotherOrSister',
          label: i18n.t('decisionMakerRelationship.brotherOrSister', `Brother/Sister`),
        },
        {
          id: 'otherFamily',
          label: i18n.t('decisionMakerRelationship.otherFamily', `Other family member`),
        },
        {
          id: 'friend',
          label: i18n.t('decisionMakerRelationship.friend', `Friend`),
        },
        {
          id: 'other',
          label: i18n.t('decisionMakerRelationship.other', `Type other answer here`),
        },
      ],
      required: true,
      button: i18n.t('button.continue', 'Continue'),
    },
    {
      stepName: GuideQuestionIdEnum.ThirdDecisionMakerContact,
      type: QuestionType['two-strings'],
      showSkipButton: true,

      inputs: [
        {
          fieldName: GuideQuestionIdEnum.ThirdDecisionMakerEmail,
          text: i18n.t('decisionMakerContact.email', 'Email:'),
          inputType: 'email',
          inputMode: 'email',
          required: false,
        },
        {
          fieldName: GuideQuestionIdEnum.ThirdDecisionMakerPhone,
          text: i18n.t('decisionMakerContact.phone', 'Phone:'),
          inputType: 'phone',
          inputMode: 'tel',
          required: false,
        },
      ],
      text: (
        <Trans i18nKey="thirdDecisionMaker.text">
          <QuestionText>
            What is {{ thirdDecisionMaker: patient?.thirdDecisionMakerFirstName }}
            's email address and phone number?
          </QuestionText>
          <Directions>(Optional - Click "Skip" if you don’t want to provide right now)</Directions>
        </Trans>
      ),
      required: false,
      button: i18n.t('button.continue', 'Continue'),
    },

    /** SUPORTIVE CARE */
    {
      stepName: 'videoPalliative',
      type: QuestionType.video,
      text: i18n.t('videoPalliative'),
      required: false,
      videoId: advanceDirectiveVideo ? advanceDirectiveVideo : i18n.t('videos.videoPalliative', '548997552'),
      button: i18n.t('button.continue', 'Continue'),
    },
    {
      stepName: 'palliativeExplainer',
      type: QuestionType.explainer,
      explainerValues: getExplainer(EXPLAINER_SECTION_ENUMS.PALLIATIVE_CARE, patient),
      text: i18n.t('palliativeExplainer'),
      // stepName: StepNameEnum.learnAboutCPR,
      required: false,
      button: i18n.t('button.continue', 'Continue'), // need to put in the continue button
    },
    {
      stepName: GuideQuestionIdEnum.InterestedInPalliativeCare,
      type: QuestionType['select-icons'],
      multiple: false,
      fieldName: GuideQuestionIdEnum.InterestedInPalliativeCare,
      text: i18n.t('interestedInPalliativeCare.text'),
      options: [
        {
          id: YesNoNotSureEnum.Yes,
          label: i18n.t('interestedInPalliativeCare.yes'),
          img: yesSVG,
        },
        {
          id: YesNoNotSureEnum.No,
          label: i18n.t('interestedInPalliativeCare.no'),
          img: noSVG,
        },
        {
          id: YesNoNotSureEnum.NotSure,
          label: i18n.t('interestedInPalliativeCare.notSure'),
          img: notSureSVG,
        },
      ],
      required: true,
      button: i18n.t('button.continue', 'Continue'),
    },
    {
      type: QuestionType.video,
      text: i18n.t('questionVideoText'),
      stepName: 'videoHospice',
      required: false,
      videoId: advanceDirectiveVideo ? advanceDirectiveVideo : i18n.t('videos.videoHospice', '549010375'),
      button: i18n.t('button.continue', 'Continue'),
    },
    {
      stepName: 'hospiceExplainer',
      type: QuestionType.explainer,
      explainerValues: getExplainer(EXPLAINER_SECTION_ENUMS.HOSPICE_CARE, patient),
      text: i18n.t('hospiceExplainerText'),
      required: false,
      button: i18n.t('button.continue', 'Continue'), // need to put in the continue button
    },
    {
      stepName: GuideQuestionIdEnum.InterestedInHospiceCare,
      type: QuestionType['select-icons'],
      multiple: false,
      fieldName: GuideQuestionIdEnum.InterestedInHospiceCare,
      text: i18n.t('interestedInHospiceCare.text'),
      options: [
        {
          id: YesNoNotSureEnum.Yes,
          label: i18n.t('interestedInHospiceCare.yes'),
          img: yesSVG,
        },
        {
          id: YesNoNotSureEnum.No,
          label: i18n.t('interestedInHospiceCare.no'),
          img: noSVG,
        },
        {
          id: YesNoNotSureEnum.NotSure,
          label: i18n.t('interestedInHospiceCare.notSure'),
          img: notSureSVG,
        },
      ],
      required: true,
      button: i18n.t('button.continue', 'Continue'),
    },

    /** END */

    {
      type: QuestionType.end,
      textAlign: 'center',
      stepName: 'submit',
      text: i18n.t(
        'submitPage',
        `Congrats! You made it to the last step. Click the Submit button to see a summary of your wishes and create your Advance Directive.`
      ),
      required: false,
      img: agreeSVG,
      button: 'Submit',
      buttonAlign: 'center',
    },

    {
      type: QuestionType.address,
      text: (
        <Trans i18nKey="decisionMakerAddress.text">
          <QuestionText>What is {{ decisionMakerFirstName: patient?.decisionMakerFirstName }}'s address?</QuestionText>
          <Directions>(Optional - Click continue if you don’t want to provide right now)</Directions>
        </Trans>
      ),
      skippable: true,
      stepName: GuideQuestionIdEnum.DecisionMakerAddress,
      required: false,
      inputs: [
        {
          fieldName: GuideQuestionIdEnum.DecisionMakerAddressStreet,
          text: 'Address:',
          required: false,
        },
        {
          fieldName: GuideQuestionIdEnum.DecisionMakerAddressCity,
          text: 'City:',
          required: false,
        },
        {
          fieldName: GuideQuestionIdEnum.DecisionMakerAddressState,
          text: 'State:',
          required: false,
        },
        {
          fieldName: GuideQuestionIdEnum.DecisionMakerAddressZip,
          text: 'Zip:',
          required: false,
        },
      ],
      button: i18n.t('button.continue', 'Continue'),
    },

    {
      type: QuestionType.address,
      text: (
        <Trans i18nKey="decisionMakerAddress.text">
          <QuestionText>
            What is {{ decisionMakerFirstName: patient?.altDecisionMakerFirstName }}'s address?
          </QuestionText>
          <Directions>(Optional - Click continue if you don’t want to provide right now)</Directions>
        </Trans>
      ),
      skippable: true,
      stepName: GuideQuestionIdEnum.AltDecisionMakerAddress,
      required: false,
      inputs: [
        {
          fieldName: GuideQuestionIdEnum.AltDecisionMakerAddressStreet,
          text: 'Address:',
          required: false,
        },
        {
          fieldName: GuideQuestionIdEnum.AltDecisionMakerAddressCity,
          text: 'City:',
          required: false,
        },
        {
          fieldName: GuideQuestionIdEnum.AltDecisionMakerAddressState,
          text: 'State:',
          required: false,
        },
        {
          fieldName: GuideQuestionIdEnum.AltDecisionMakerAddressZip,
          text: 'Zip:',
          required: false,
        },
      ],
      button: i18n.t('button.continue', 'Continue'),
    },
    {
      type: QuestionType.address,
      text: (
        <Trans i18nKey="decisionMakerAddress.text">
          <QuestionText>
            What is {{ decisionMakerFirstName: patient?.thirdDecisionMakerFirstName }}'s address?
          </QuestionText>
          <Directions>(Optional - Click continue if you don’t want to provide right now)</Directions>
        </Trans>
      ),
      skippable: true,
      stepName: GuideQuestionIdEnum.ThirdDecisionMakerAddress,
      required: false,
      inputs: [
        {
          fieldName: GuideQuestionIdEnum.ThirdDecisionMakerAddressStreet,
          text: 'Address:',
          required: false,
        },
        {
          fieldName: GuideQuestionIdEnum.ThirdDecisionMakerAddressCity,
          text: 'City:',
          required: false,
        },
        {
          fieldName: GuideQuestionIdEnum.ThirdDecisionMakerAddressState,
          text: 'State:',
          required: false,
        },
        {
          fieldName: GuideQuestionIdEnum.ThirdDecisionMakerAddressZip,
          text: 'Zip:',
          required: false,
        },
      ],
      button: i18n.t('button.continue', 'Continue'),
    },

    {
      fieldName: GuideQuestionIdEnum.MedicalCareScenario1,
      stepName: GuideQuestionIdEnum.MedicalCareScenario1,
      type: QuestionType.select,
      multiple: false,
      text: (
        <>
          <QuestionText>
            <strong>{i18n.t('medicalCareScenario1.title', 'Scenario 1: Terminal Condition')}</strong>
          </QuestionText>
          <QuestionBody>{i18n.t('medicalCareScenario.whatIWantIf', 'This is what I want if:')}</QuestionBody>
          <ul>
            <li>
              <QuestionBody>
                {i18n.t('medicalCareScenario1.ifIhaveIllness', 'I have an illness that cannot be cured or reversed.')}
              </QuestionBody>
            </li>
          </ul>
          <QuestionBody style={{ marginLeft: 16 }}>
            <strong>{i18n.t('medicalCareScenario.and', 'AND')}</strong>
          </QuestionBody>
          <ul>
            <li>
              <QuestionBody>
                {i18n.t(
                  'medicalCareScenario1.myHealthProviders',
                  'My health care providers believe it will result in my death within six months, regardless of any treatments.'
                )}
              </QuestionBody>
            </li>
          </ul>
        </>
      ),
      directions: i18n.t('pleaseSelectOne', 'Please select one'),
      options: [
        {
          id: 'a',
          label: i18n.t(
            'medicalCareScenario.a',
            'I want to try all available treatments to sustain my life, such as artificial feeding and hydration with feeding tubes, IV fluids, kidney dialysis and breathing machines.'
          ),
        },
        {
          id: 'b',
          label: i18n.t(
            'medicalCareScenario.b',
            'I want to try to sustain my life with artificial feeding and hydration with feeding tubes and IV fluids. I do not want other treatments to sustain my life, such as kidney dialysis and breathing machines.'
          ),
        },
        {
          id: 'c',
          label: i18n.t(
            'medicalCareScenario.c',
            'I do not want treatments to sustain my life, such as artificial feeding and hydration with feeding tubes, IV fluids, kidney dialysis or breathing machines. I want to be kept comfortable and be allowed to die naturally.'
          ),
        },
        {
          id: 'd',
          label: i18n.t(
            'medicalCareScenario.d',
            'I want my health care representative to decide for me, after talking with my health care providers and taking into account the things that matter to me. I have expressed what matters to me in the Values and Quality of Life sections.'
          ),
        },
      ],
      required: true,
      button: i18n.t('button.continue', 'Continue'),
    },

    {
      fieldName: GuideQuestionIdEnum.MedicalCareScenario2,
      stepName: GuideQuestionIdEnum.MedicalCareScenario2,
      type: QuestionType.select,
      multiple: false,
      text: (
        <>
          <QuestionText>
            <strong>{i18n.t('medicalCareScenario2.title', 'Scenario 2: Advanced Progressive Illness')}</strong>
          </QuestionText>
          <QuestionBody> {i18n.t('medicalCareScenario.whatIWantIf', 'This is what I want if:')}</QuestionBody>
          <ul>
            <li>
              <QuestionBody>
                {i18n.t(
                  'medicalCareScenario2.ifIHaveAdvancedStage',
                  ' I have an illness that is in an advanced stage.'
                )}
              </QuestionBody>
            </li>
          </ul>
          <QuestionBody style={{ marginLeft: 16 }}>
            <strong>{i18n.t('medicalCareScenario.and', 'AND')}</strong>
          </QuestionBody>
          <ul>
            <li>
              <QuestionBody>
                {i18n.t(
                  'medicalCareScenario2.myHealthProvidersWillNotImprove',
                  'My health care providers believe it will not improve and will very likely get worse over time and result in death.'
                )}
              </QuestionBody>
            </li>
          </ul>
          <QuestionBody style={{ marginLeft: 16 }}>
            <strong>{i18n.t('medicalCareScenario.and', 'AND')}</strong>
          </QuestionBody>
          <ul>
            <li>
              <QuestionBody>
                {i18n.t(
                  'medicalCareScenario2.myHealthProvidersBelieve',
                  'My health care providers believe I will never be able to:'
                )}
              </QuestionBody>
              <ul>
                <li>
                  <QuestionBody>{i18n.t('medicalCareScenario2.Communicate', 'Communicate')}</QuestionBody>
                </li>
                <li>
                  <QuestionBody>
                    {i18n.t('medicalCareScenario2.Swallow food and water safely', 'Swallow food and water safely')}
                  </QuestionBody>
                </li>
                <li>
                  <QuestionBody>{i18n.t('medicalCareScenario2.Care for myself', 'Care for myself')}</QuestionBody>
                </li>
                <li>
                  <QuestionBody>
                    {i18n.t(
                      'medicalCareScenario2.Recognize my family and other people',
                      'Recognize my family and other people'
                    )}
                  </QuestionBody>
                </li>
              </ul>
            </li>
          </ul>
        </>
      ),
      directions: i18n.t('pleaseSelectOne', 'Please select one'),
      options: [
        {
          id: 'a',
          label: i18n.t(
            'medicalCareScenario.a',
            'I want to try all available treatments to sustain my life, such as artificial feeding and hydration with feeding tubes, IV fluids, kidney dialysis and breathing machines.'
          ),
        },
        {
          id: 'b',
          label: i18n.t(
            'medicalCareScenario.b',
            'I want to try to sustain my life with artificial feeding and hydration with feeding tubes and IV fluids. I do not want other treatments to sustain my life, such as kidney dialysis and breathing machines.'
          ),
        },
        {
          id: 'c',
          label: i18n.t(
            'medicalCareScenario.c',
            'I do not want treatments to sustain my life, such as artificial feeding and hydration with feeding tubes, IV fluids, kidney dialysis or breathing machines. I want to be kept comfortable and be allowed to die naturally.'
          ),
        },
        {
          id: 'd',
          label: i18n.t(
            'medicalCareScenario.d',
            'I want my health care representative to decide for me, after talking with my health care providers and taking into account the things that matter to me. I have expressed what matters to me in the Values and Quality of Life sections.'
          ),
        },
      ],
      required: true,
      button: i18n.t('button.continue', 'Continue'),
    },

    {
      fieldName: GuideQuestionIdEnum.MedicalCareScenario3,
      stepName: GuideQuestionIdEnum.MedicalCareScenario3,
      type: QuestionType.select,
      multiple: false,
      text: (
        <>
          <QuestionText>
            <strong>{i18n.t('medicalCareScenario3.title', 'Scenario 3: Permanently Unconscious')}</strong>
          </QuestionText>
          <QuestionBody>{i18n.t('medicalCareScenario.whatIWantIf', 'This is what I want if:')}</QuestionBody>
          <ul>
            <li>
              <QuestionBody>{i18n.t('medicalCareScenario3.amNotConcious', 'I am not conscious.')}</QuestionBody>
            </li>
          </ul>
          <QuestionBody style={{ marginLeft: 16 }}>
            <strong>{i18n.t('medicalCareScenario.and', 'AND')}</strong>
          </QuestionBody>
          <ul>
            <li>
              <QuestionBody>
                {i18n.t(
                  'medicalCareScenario3.ifUnlikelyToBeConscious',
                  'If my health care providers believe it is very unlikely that I will ever become conscious again.'
                )}
              </QuestionBody>
            </li>
          </ul>
        </>
      ),
      directions: i18n.t('pleaseSelectOne', 'Please select one'),
      options: [
        {
          id: 'a',
          label: i18n.t(
            'medicalCareScenario.a',
            'I want to try all available treatments to sustain my life, such as artificial feeding and hydration with feeding tubes, IV fluids, kidney dialysis and breathing machines.'
          ),
        },
        {
          id: 'b',
          label: i18n.t(
            'medicalCareScenario.b',
            'I want to try to sustain my life with artificial feeding and hydration with feeding tubes and IV fluids. I do not want other treatments to sustain my life, such as kidney dialysis and breathing machines.'
          ),
        },
        {
          id: 'c',
          label: i18n.t(
            'medicalCareScenario.c',
            'I do not want treatments to sustain my life, such as artificial feeding and hydration with feeding tubes, IV fluids, kidney dialysis or breathing machines. I want to be kept comfortable and be allowed to die naturally.'
          ),
        },
        {
          id: 'd',
          label: i18n.t('medicalCareScenario.d', englishTrans['medicalCareScenario.d']),
        },
      ],
      required: true,
      button: i18n.t('button.continue', 'Continue'),
    },

    {
      fieldName: GuideQuestionIdEnum.LifeSustainingProcedures,
      stepName: GuideQuestionIdEnum.LifeSustainingProcedures,
      type: QuestionType.select,
      multiple: true,
      text: i18n.t(
        'lifeSustainingProcedures.text',
        'I do not want life-sustaining procedures if I can not be supported and be able to engage in the following ways:'
      ),
      options: [
        {
          id: 'a',
          label: i18n.t('lifeSustainingProcedures.a', 'Express my needs.'),
        },
        {
          id: 'b',
          label: i18n.t('lifeSustainingProcedures.b', 'Be free from long-term severe pain and suffering.'),
        },
        {
          id: 'c',
          label: i18n.t('lifeSustainingProcedures.c', 'Know who I am and who I am with.'),
        },
        {
          id: 'd',
          label: i18n.t('lifeSustainingProcedures.d', 'Live without being hooked up to mechanical life support.'),
        },
        {
          id: 'other',
          label: i18n.t(
            'lifeSustainingProcedures.other',
            'Participate in activities that have meaning to me, such as:'
          ),
        },
        {
          id: 'none',
          label: i18n.t('lifeSustainingProcedures.none', 'None of the above.'),
        },
      ],
      otherFieldName: GuideQuestionIdEnum.LifeSustainingProceduresOther,
      required: true,
      button: i18n.t('button.continue', 'Continue'),
    },
  ]

  const questions = QUESTIONS?.map((question, i) => {
    return {
      ...question,
      defaultValue: question?.fieldName && patient[question?.fieldName],
      actualIndex: i, // we're later going to remove/filter out some questions, so we need to know the actual index of the question in the context of all the avaialble questions
    }
  })

  // if no questionIds were passed, just return all of them
  if (questionIds === false || !questionIds?.[0]) {
    return questions
  }

  // we want to sort the questions that we are returning into the same order as the questionIds that we recieve
  const sortedQuestions = sortBy(questions, (v) => questionIds?.indexOf(v.stepName))

  return sortedQuestions?.filter((q) => q?.stepName && questionIds?.includes(q?.stepName as GuideQuestionIdEnum))
}
