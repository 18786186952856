import { FC, ReactElement, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import Button from 'components/common/Button';
import Loading from 'components/common/Loading';
import useSavePatient from 'lib/hooks/useSavePatient';
import { Patient } from 'generated/graphql';
import usePrevious from 'lib/hooks/usePrevious';
import { Step1Continue } from '../step1';
import { AfterSaveAction } from 'routes/app-survey/step1';
import SectionHeader from 'routes/app-survey/SectionHeader';
import SectionBlock from 'routes/app-survey/step2/SectionBlock';
import SectionFooter from 'routes/app-survey/SectionFooter';

const Step2Styled = styled.div`
  background-color: white;
  flex: 1;
  @media only screen and (max-width: ${({ theme }) =>
  theme.breakpoints.medium}) {
    padding-bottom: 100px;
  }
`;

const Step2Body = styled.div`
  margin: auto;
  max-width: 600px;
  padding: 48px 10px;
  @media only screen and (max-width: ${({ theme }) =>
    theme.breakpoints.mediumSmall}) {
    padding: 24px;
  }
`;

const Step2Title = styled.h1`
  margin-bottom: 32px;
  font-size: 18px;
`;

interface Step2Props {
  patient?: Patient | null;
};


const Step2: FC<Step2Props> = ({ patient }): ReactElement => {
  const history = useHistory();
  const { t } = useTranslation();

  const [afterSaveAction, setAfterSaveAction] = useState<AfterSaveAction>();
  const [postAcpKnowWhatAdvanceCarePlanningIs, setPostAcpKnowWhatAdvanceCarePlanningIs] = useState<number | null>(null);
  const [postAcpAboutWhoCanBeDecisionMaker, setPostAcpAboutWhoCanBeDecisionMaker] = useState<number | null>(null);
  const [postAcpAboutWhatMakesGoodDecisionMaker, setPostAcpAboutWhatMakesGoodDecisionMaker] = useState<number | null>(null);
  const [postAcpInformedAboutFlexibilityDecisionMaker, setPostAcpInformedAboutFlexibilityDecisionMaker] = useState<number | null>(null);

  const { onSavePatient, saving } = useSavePatient();

  const savingPrev = usePrevious(saving);

  const save = async () => {
    if (patient) {
      await onSavePatient({
        ...patient,
        postAcpKnowWhatAdvanceCarePlanningIs,
        postAcpAboutWhoCanBeDecisionMaker,
        postAcpAboutWhatMakesGoodDecisionMaker,
        postAcpInformedAboutFlexibilityDecisionMaker,
      });
    }
  };

  useEffect(() => {
    // set patient values every patient change
    if (patient) {
      setPostAcpKnowWhatAdvanceCarePlanningIs(patient.postAcpKnowWhatAdvanceCarePlanningIs as number);
      setPostAcpAboutWhoCanBeDecisionMaker(patient.postAcpAboutWhoCanBeDecisionMaker as number);
      setPostAcpAboutWhatMakesGoodDecisionMaker(patient.postAcpAboutWhatMakesGoodDecisionMaker as number);
      setPostAcpInformedAboutFlexibilityDecisionMaker(patient.postAcpInformedAboutFlexibilityDecisionMaker as number);
    }
  }, [patient]);

  const goNext = () => {
    setAfterSaveAction(AfterSaveAction.NEXT);
    save();
  };

  useEffect(() => {
    // Go to step 2 on saving end and if patient is truthy
    if (savingPrev && !saving && patient && afterSaveAction) {
      switch (afterSaveAction) {
        case AfterSaveAction.NEXT: {
          history.push('/app/post-survey?step=3');
          break;
        }
        case AfterSaveAction.HOME: {
          history.push('/app/home');
          break;
        }
        case AfterSaveAction.BACK: {
          history.push('/app/post-survey?step=1');
          break;
        }
        default: {
          return;
        }
      }
    }
  }, [
    history,
    savingPrev,
    saving,
    patient,
    afterSaveAction,
  ]);

  const disabled =
    !postAcpKnowWhatAdvanceCarePlanningIs ||
    !postAcpAboutWhoCanBeDecisionMaker ||
    !postAcpAboutWhatMakesGoodDecisionMaker ||
    !postAcpInformedAboutFlexibilityDecisionMaker;
  
  return (
    <>
      <Step2Styled>
        <SectionHeader
          sectionName={t("planConfigurator.POST_SURVEY", "Post-ACP Survey")}
          progress={patient?.guideTemplate?.name === "WF_Path1" ? "20" : "25"}
          onClick={() => {
            setAfterSaveAction(AfterSaveAction.HOME);
            save();
          }}
        />
        {saving && <Loading height={80} />}
        <Step2Body>
          <Step2Title>
            {t("survey.advanceCarePlanningKnowledge", "Advance Care Planning Knowledge Assessment")}
          </Step2Title>
          <SectionBlock
            title={`1. ${t(
              "survey.doYouKnowWhatAdvanceCare",
              "Do you know what Advance Care Planning is?"
            )}`}
            subtitle={t("survey.pleaseMarkTheCircleThat", "Please mark the circle that best describes you")}
            value={postAcpKnowWhatAdvanceCarePlanningIs}
            onSelect={(value: number) => setPostAcpKnowWhatAdvanceCarePlanningIs(value)}
          />
          <SectionBlock
            title={`2. ${t(
              "survey.howWellInformedAreYou",
              "How well informed are you about who can be a medical decision maker?"
            )}`}
            subtitle={t("survey.pleaseMarkTheCircleThat", "Please mark the circle that best describes you")}
            value={postAcpAboutWhoCanBeDecisionMaker}
            onSelect={(value: number) => setPostAcpAboutWhoCanBeDecisionMaker(value)}
          />
          <SectionBlock
            title={`3. ${t(
              "survey.howWellInformedGoodDecision",
              "How well informed are you about what makes a good medical decision maker?"
            )}`}
            subtitle={t("survey.pleaseMarkTheCircleThat", "Please mark the circle that best describes you")}
            value={postAcpAboutWhatMakesGoodDecisionMaker}
            onSelect={(value: number) => setPostAcpAboutWhatMakesGoodDecisionMaker(value)}
          />
          <SectionBlock
            title={`4. ${t(
              "survey.howWellInformedAboutDifferent",
              "How well informed are you about the different amounts of flexibility a person can give their medical decision maker?"
            )}`}
            subtitle={t("survey.pleaseMarkTheCircleThat", "Please mark the circle that best describes you")}
            value={postAcpInformedAboutFlexibilityDecisionMaker}
            onSelect={(value: number) => setPostAcpInformedAboutFlexibilityDecisionMaker(value)}
          />
          {!disabled && (
            <Step1Continue>
              <Button
                loading={saving}
                disabled={saving}
                onClick={goNext}
                pulse
              >
                {t("button.continue", "Continue")}
              </Button>
            </Step1Continue>
          )}
        </Step2Body>
      </Step2Styled>
      <SectionFooter
        goBack={() => {
          setAfterSaveAction(AfterSaveAction.BACK);
          save();
        }}
        goNext={goNext}
        loading={saving}
        disabled={disabled}
      />
    </>
  );
}

export default Step2;
