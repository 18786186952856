import styled from 'styled-components'
import constants from 'lib/config'
import LoadingOutlined from 'components/icons/LoadingOutlined'
import i18n from 'lib/helpers/i18n'

const Container = styled.div`
  align-items: center;
  justify-content: center;
  text-align: center;
  max-width: 100%;
  display: flex;
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2000;
`

const Content = styled.div``

const Title = styled.h1`
  color: ${(p) => p.theme.colors.neutral10};
  font-size: 24px;
  margin: 0px;
  margin-top: 16px;
`

const Subtitle = styled.h3`
  color: ${(p) => p.theme.colors.neutral9};
  font-size: 18px;
  margin: 0px;
  margin-top: 8px;
  font-weight: 400 !important;
  max-width: 100%;
`

interface OfflineProps {
  title?: string
  subtitle?: string
}

export default function Offline({
  title = i18n.t('Offline.title', 'You are offline...'),
  subtitle = `${i18n.t('Offline.subtitle', `Please try to refresh. If the error persists, contact support at`)} ${
    constants.supportEmail
  }}`,
}: OfflineProps) {
  return (
    <>
      <Container>
        <Content>
          <LoadingOutlined style={{ color: '#fff', fontSize: 28 }} />
          <Title>{title}</Title>
          <Subtitle>{subtitle}</Subtitle>
        </Content>
      </Container>
    </>
  )
}
