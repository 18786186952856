import ButtonsContainer from '../ButtonsContainer'
import { StepContainer } from '../index'
import ElephantChat from 'components/common/ElephantChat'
import { Patient } from 'generated/graphql'
import i18n from 'lib/helpers/i18n'
import en from 'lib/helpers/locales/en/translation.json'

interface DontKnowDecisionMakerProps {
  progress?: string
  onNext?: (plan?: Patient) => void
  onBack: () => void
  nextButton?: string
}

export default function DontKnowDecisionMaker({ progress, onNext, onBack, nextButton }: DontKnowDecisionMakerProps) {
  return (
    <StepContainer>
      <div>
        <div style={{ marginBottom: 24 }}>
          <ElephantChat
            text={i18n.t('doYouKnowDecisionMaker.noDescription', en.doYouKnowDecisionMaker.noDescription)}
          />
        </div>

        <ButtonsContainer
          onNext={onNext}
          onBack={onBack}
          buttonAlign="center"
          progress={progress}
          nextButton={nextButton}
        />
      </div>
    </StepContainer>
  )
}
