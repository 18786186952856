import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import styled from 'styled-components'

import Step1 from './step1'
import Step2 from './step2'
import Step3 from './step3'
import Loading from 'components/common/Loading'
import { useGetOnePatientQuery, User } from 'generated/graphql'

const AppSurveyStyled = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

interface AppSurveyProps {
  currentUser: User
}

const AppSurvey = (props: AppSurveyProps) => {
  const location = useLocation()
  const { step = '1' } = queryString.parse(location.search)

  const { data, loading, error } = useGetOnePatientQuery({
    variables: { userId: props?.currentUser.id },
  })

  useEffect(() => {
    if (step && document?.body) {
      window.scrollTo(0, 0)
    }
  }, [step])

  if (error) {
    return <div>Error...</div>
  }

  if (loading) {
    return <Loading />
  }

  return (
    <AppSurveyStyled>
      {step === '1' && <Step1 patient={data?.getOnePatient} />}
      {step === '2' && <Step2 patient={data?.getOnePatient} />}
      {step === '3' && <Step3 currentUser={props?.currentUser} patient={data?.getOnePatient} />}
    </AppSurveyStyled>
  )
}

export default AppSurvey
