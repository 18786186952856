import styled from 'styled-components'
import FormLabel from '../FormLabel'

const HintText = styled(FormLabel)`
  margin-bottom: 8px;
  font-weight: 400;
  margin: 0px;
  margin-top: 8px;
  font-size: 14px;
  color: ${(p) => p.theme.colors.neutral5};
  max-width: 100%;
`

export default HintText
