import styled from 'styled-components'
import Row from 'components/common/Row'
import Col from 'components/common/Col'
import PlanDoc from 'components/common/PlanDoc'
import Button from 'components/common/Button'
import theme from 'lib/theme'
import logo from 'lib/media/logo-light-bg-horizontal.svg'
import { ButtonContainer } from '../index'
import { ClickableText, Text } from '../index'
import { useState } from 'react'
import config from 'lib/config'
import { PatientFragmentFragment } from 'generated/graphql'
import i18n from 'lib/helpers/i18n'
import { Trans } from 'react-i18next'

const Outter = styled.div`
  background: ${(p) => p.theme.colors.neutral10};
  height: 100%;
`

const Container = styled.div`
  padding-top: 32px;
  width: 1100px;
  height: 100%;
  max-width: 95%;
  margin: auto;
`

const Header = () => {
  return (
    <div
      style={{
        height: 56,
        background: '#fff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderBottom: `1px solid ${theme.colors.neutral9}`,
      }}
    >
      <img src={logo} height="32" alt="Koda Health" />
    </div>
  )
}

// FOR PDF ATTACHMENT
// Create it on the client, upload it to an attachment, which you'll then attach to a nodemailer send function
interface PlanProps {
  data: PatientFragmentFragment
  onDownload: () => void
  downloading: boolean
}

export default function Plan({ data, onDownload, downloading }: PlanProps) {
  const [showText, setShowText] = useState<boolean>(false)
  return (
    <div style={{ height: '98vh', width: '100%' }}>
      <Header />
      <Outter className="fs-exclude">
        <Container>
          <Row align="middle" style={{ marginBottom: 16 }}>
            <Col xs={24} sm={18} style={{ marginTop: 16 }}>
              <Text>
                <Trans i18nKey={'PublicDownloadPdfPlan.title'}>
                  Below is {{ firstName: data.firstName }} {{ lastName: data.lastName }}'s Advance Directive
                </Trans>
              </Text>
              <ClickableText onClick={() => setShowText((currenState) => !currenState)}>
                {i18n.t('PublicDownloadPdfPlan.havingTrouble', `Having trouble viewing your document?`)}
              </ClickableText>
              {showText && (
                <Text style={{ marginTop: 16 }}>
                  {i18n.t(
                    'PublicDownloadPdfPlan.clickToDownload',
                    `Click the ‘Download’ button or contact Koda Support at`
                  )}{' '}
                  <a href={`mailto:${config.supportEmail}`}>{config.supportEmail}</a> or{' '}
                  <a href={`tel:+${config.supportPhone.replace(/-/g, '')}`}>{config.supportPhone}</a> .
                </Text>
              )}
            </Col>{' '}
            <Col xs={0} sm={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <ButtonContainer>
                <Button disabled={downloading} onClick={onDownload} style={{ width: 180 }}>
                  {!downloading ? i18n.t('button.download') : i18n.t('button.downloading')}
                </Button>
              </ButtonContainer>
            </Col>
          </Row>
          <PlanDoc data={data} clinic={data?.clinic} />
        </Container>
      </Outter>
    </div>
  )
}
