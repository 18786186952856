import { useState } from 'react'
import { CardHeader, CardRow, Card } from '../index'
import decisionSVG from 'lib/media/images/mdm_section.svg'
import { Patient, GuideQuestionIdEnum, GuideTemplateSectionIdEnum } from 'generated/graphql'
import Button from 'components/common/Button'
import styled from 'styled-components'
import ConfirmRemoveMdmModal, { DeleteMDMType } from 'components/common/ConfirmRemoveMdmModal'
import { QuestionItem } from 'lib/types/QuestionItem'
import i18n from 'lib/helpers/i18n'
import en from 'lib/helpers/locales/en/translation.json'

const getMdmName = (plan) => {
  const { decisionMakerFirstName, decisionMakerLastName } = plan
  const EMPTY_TEXT = i18n.t('EditMedicalDecisionMaker.noneSelected', 'None selected')

  if (!decisionMakerFirstName && !decisionMakerLastName) {
    return EMPTY_TEXT
  }

  if (decisionMakerFirstName && decisionMakerLastName) {
    return `${decisionMakerFirstName} ${decisionMakerLastName}`
  }

  if (!decisionMakerFirstName && decisionMakerLastName) {
    return decisionMakerLastName
  }

  if (decisionMakerFirstName && !decisionMakerLastName) {
    return decisionMakerFirstName
  }

  return EMPTY_TEXT
}

const getFirstAltMdmName = (plan) => {
  const EMPTY_TEXT = i18n.t('EditMedicalDecisionMaker.noneSelected', en.EditMedicalDecisionMaker.noneSelected)
  const { altDecisionMakerFirstName, altDecisionMakerLastName } = plan

  if (!altDecisionMakerFirstName && !altDecisionMakerFirstName) {
    return EMPTY_TEXT
  }

  if (altDecisionMakerFirstName && altDecisionMakerLastName) {
    return `${altDecisionMakerFirstName} ${altDecisionMakerLastName}`
  }

  if (!altDecisionMakerFirstName && altDecisionMakerLastName) {
    return altDecisionMakerLastName
  }

  if (altDecisionMakerFirstName && !altDecisionMakerLastName) {
    return altDecisionMakerFirstName
  }

  return EMPTY_TEXT
}

const getThirdMdmName = (plan) => {
  const EMPTY_TEXT = i18n.t('EditMedicalDecisionMaker.noneSelected', en.EditMedicalDecisionMaker.noneSelected)
  const { thirdDecisionMakerFirstName, thirdDecisionMakerLastName } = plan

  if (!thirdDecisionMakerFirstName && !thirdDecisionMakerLastName) {
    return EMPTY_TEXT
  }

  if (thirdDecisionMakerFirstName && thirdDecisionMakerLastName) {
    return `${thirdDecisionMakerFirstName} ${thirdDecisionMakerLastName}`
  }

  if (!thirdDecisionMakerFirstName && thirdDecisionMakerLastName) {
    return thirdDecisionMakerLastName
  }

  if (thirdDecisionMakerFirstName && !thirdDecisionMakerLastName) {
    return thirdDecisionMakerFirstName
  }

  return EMPTY_TEXT
}

// decisionMakerFirstName: String
// decisionMakerLastName: String
// decisionMakerRelationship: DecisionMakerRelationshipEnum
// decisionMakerRelationshipOther: String
// decisionMakerStrictness: DecisionMakerStrictnessEnum
// wantsAltDecisionMaker: String
// altDecisionMakerFirstName: String
// altDecisionMakerLastName: String
// altDecisionMakerEmail: String
// altDecisionMakerPhone: String
// whenCanDecisionMakerDecide: String

const getQuestion = (questions: any[], fieldNameOrStepName: string) => {
  // if you can't find it by step name, then find it by fieldname and return that
  const fieldNameQ = questions.find((q) => q.fieldName === fieldNameOrStepName)
  if (fieldNameQ) return fieldNameQ

  const stepNameQ = questions.find((q) => q.stepName === fieldNameOrStepName)
  // if you can find it by stepName, return that
  if (stepNameQ) return stepNameQ

  return null
}

type MedicalDecisionMakerProps = {
  plan: Patient
  questions: QuestionItem[]
}

const DeleteRow = styled.div`
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const getAddress = (patient: Patient): string => {
  let fullAdress = ''
  if (patient.decisionMakerAddressStreet) {
    fullAdress = fullAdress + patient.decisionMakerAddressStreet
  }
  if (patient.decisionMakerAddressCity) {
    fullAdress = `${fullAdress} ${patient.decisionMakerAddressCity}`
  }
  if (patient.decisionMakerAddressState) {
    fullAdress = `${fullAdress}, ${patient.decisionMakerAddressState} `
  }
  if (patient.decisionMakerAddressZip) {
    fullAdress = `${fullAdress} ${patient.decisionMakerAddressZip} `
  }
  return fullAdress
}

const getAltAddress = (patient: Patient): string => {
  let fullAdress = ''
  if (patient.altDecisionMakerAddressStreet) {
    fullAdress = fullAdress + patient.altDecisionMakerAddressStreet
  }
  if (patient.altDecisionMakerAddressCity) {
    fullAdress = `${fullAdress} ${patient.altDecisionMakerAddressCity}`
  }
  if (patient.altDecisionMakerAddressState) {
    fullAdress = `${fullAdress}, ${patient.altDecisionMakerAddressState} `
  }
  if (patient.altDecisionMakerAddressZip) {
    fullAdress = `${fullAdress} ${patient.altDecisionMakerAddressZip} `
  }
  return fullAdress
}

const getThirdAddress = (patient: Patient): string => {
  let fullAdress = ''
  if (patient.thirdDecisionMakerAddressStreet) {
    fullAdress = fullAdress + patient.thirdDecisionMakerAddressStreet
  }
  if (patient.thirdDecisionMakerAddressCity) {
    fullAdress = `${fullAdress} ${patient.thirdDecisionMakerAddressCity}`
  }
  if (patient.thirdDecisionMakerAddressState) {
    fullAdress = `${fullAdress}, ${patient.thirdDecisionMakerAddressState} `
  }
  if (patient.thirdDecisionMakerAddressZip) {
    fullAdress = `${fullAdress} ${patient.thirdDecisionMakerAddressZip} `
  }
  return fullAdress
}

export default function MedicalDecisionMaker({ questions, plan }: MedicalDecisionMakerProps) {
  const SECTION_1 = i18n.t('EditMedicalDecisionMaker.section1', 'Medical Decision Maker')
  const SECTION_2 = i18n.t('EditMedicalDecisionMaker.section2', 'First Alternative Medical Decision Maker')
  const SECTION_3 = i18n.t('EditMedicalDecisionMaker.section3', 'Second Alternative Medical Decision Maker')
  const EMPTY_TEXT = i18n.t('EditMedicalDecisionMaker.noneSelected', en.EditMedicalDecisionMaker.noneSelected)
  const [expanded, setExpanded] = useState<string | null>(SECTION_1)
  const [confirmDelete, setConfirmDelete] = useState<DeleteMDMType | undefined>(undefined)

  const sharedCardRowProps = {
    questions,
    editSection: GuideTemplateSectionIdEnum.MedicalDecisionMaker,
  }

  const handleDelete = (deleteMDMType: DeleteMDMType) => {
    setConfirmDelete(deleteMDMType)
  }

  return (
    <>
      <Card className="fs-exclude">
        <CardHeader
          title={SECTION_1}
          icon={decisionSVG}
          setExpanded={() => {
            if (expanded === SECTION_1) return setExpanded(null)
            setExpanded(SECTION_1)
          }}
          expanded={expanded === SECTION_1}
        />
        {expanded === SECTION_1 && (
          <>
            <CardRow
              {...sharedCardRowProps}
              question={getQuestion(questions, GuideQuestionIdEnum.DoYouKnowDecisionMaker)}
              label={i18n.t('EditMedicalDecisionMaker.mdmSelected', 'Medical Decision Maker Selected')}
            />
            <CardRow
              {...sharedCardRowProps}
              question={getQuestion(questions, GuideQuestionIdEnum.DecisionMakerName)}
              answerText={getMdmName(plan)}
              label={i18n.t('EditMedicalDecisionMaker.fullName', 'Full Name')}
            />
            <CardRow
              {...sharedCardRowProps}
              question={getQuestion(questions, GuideQuestionIdEnum.DecisionMakerRelationship)}
              label={i18n.t('EditMedicalDecisionMaker.relationship', 'Relationship')}
              plan={plan}
            />
            <CardRow
              {...sharedCardRowProps}
              question={getQuestion(questions, GuideQuestionIdEnum.DecisionMakerStrictness)}
              label={i18n.t('EditMedicalDecisionMaker.flexibility', 'Flexibility')}
            />
            <CardRow
              {...sharedCardRowProps}
              question={getQuestion(questions, GuideQuestionIdEnum.AdditionalMdmDirections)}
              answerText={plan[GuideQuestionIdEnum.AdditionalMdmDirections] || EMPTY_TEXT}
              label={i18n.t(
                'EditMedicalDecisionMaker.additionalMdmDirections',
                'Limitations on decision making authority'
              )}
            />
            <CardRow
              {...sharedCardRowProps}
              question={getQuestion(questions, 'decisionMakerContact')}
              answerText={plan[GuideQuestionIdEnum.DecisionMakerEmail] || EMPTY_TEXT}
              label={i18n.t('profile.email', en.profile.email)}
            />
            <CardRow
              {...sharedCardRowProps}
              question={getQuestion(questions, 'decisionMakerContact')}
              answerText={plan[GuideQuestionIdEnum.DecisionMakerPhone] || EMPTY_TEXT}
              label={i18n.t('EditMedicalDecisionMaker.phone', 'Phone')}
            />
            {getQuestion(questions, GuideQuestionIdEnum.DecisionMakerAddress) && (
              <CardRow
                {...sharedCardRowProps}
                question={getQuestion(questions, GuideQuestionIdEnum.DecisionMakerAddress)}
                answerText={getAddress(plan) || EMPTY_TEXT}
                label={i18n.t('EditMedicalDecisionMaker.address', 'Address')}
              />
            )}
            <DeleteRow>
              <Button
                outline
                style={{ width: 150 }}
                onClick={() => handleDelete(DeleteMDMType.mdm)}
                data-testid={`deleteAll-${SECTION_1}`}
              >
                {i18n.t('EditMedicalDecisionMaker.deleteAll', 'Delete All')}
              </Button>
            </DeleteRow>
          </>
        )}
      </Card>
      <Card className="fs-exclude">
        <CardHeader
          title={SECTION_2}
          data-testid={SECTION_2}
          icon={decisionSVG}
          setExpanded={() => {
            if (expanded === SECTION_2) return setExpanded(null)
            setExpanded(SECTION_2)
          }}
          expanded={expanded === SECTION_2}
        />
        {expanded === SECTION_2 && (
          <>
            <CardRow
              {...sharedCardRowProps}
              question={getQuestion(questions, GuideQuestionIdEnum.WantsAltDecisionMaker)}
              label={i18n.t('EditMedicalDecisionMaker.altMdmSelected', 'Alternate Medical Decision Maker Selected')}
            />
            <CardRow
              {...sharedCardRowProps}
              question={getQuestion(questions, GuideQuestionIdEnum.AltDecisionMakerName)} //
              answerText={getFirstAltMdmName(plan)}
              label={i18n.t('EditMedicalDecisionMaker.fullName', 'Full Name')}
            />
            <CardRow
              {...sharedCardRowProps}
              question={getQuestion(questions, GuideQuestionIdEnum.AltDecisionMakerRelationship)}
              label={i18n.t('EditMedicalDecisionMaker.relationship', 'Relationship')}
              plan={plan}
            />
            <CardRow
              {...sharedCardRowProps}
              question={getQuestion(questions, 'altDecisionMakerContact')}
              answerText={plan['altDecisionMakerEmail'] || EMPTY_TEXT}
              label={i18n.t('profile.email', en.profile.email)}
            />
            <CardRow
              {...sharedCardRowProps}
              question={getQuestion(questions, 'altDecisionMakerContact')}
              answerText={plan['altDecisionMakerPhone'] || EMPTY_TEXT}
              label={i18n.t('EditMedicalDecisionMaker.phone', 'Phone')}
            />
            {getQuestion(questions, GuideQuestionIdEnum.AltDecisionMakerAddress) && (
              <CardRow
                {...sharedCardRowProps}
                question={getQuestion(questions, GuideQuestionIdEnum.AltDecisionMakerAddress)}
                answerText={getAltAddress(plan) || EMPTY_TEXT}
                label={i18n.t('EditMedicalDecisionMaker.address', 'Address')}
              />
            )}

            <DeleteRow>
              <Button
                outline
                style={{ width: 150 }}
                onClick={() => handleDelete(DeleteMDMType.altMDM)}
                data-testid={`deleteAll-${SECTION_2}`}
              >
                {i18n.t('EditMedicalDecisionMaker.deleteAll', 'Delete All')}
              </Button>
            </DeleteRow>
          </>
        )}
      </Card>
      <Card className="fs-exclude">
        <CardHeader
          title={SECTION_3}
          icon={decisionSVG}
          setExpanded={() => {
            if (expanded === SECTION_3) return setExpanded(null)
            setExpanded(SECTION_3)
          }}
          expanded={expanded === SECTION_3}
        />
        {expanded === SECTION_3 && (
          <>
            <CardRow
              {...sharedCardRowProps}
              question={getQuestion(questions, GuideQuestionIdEnum.WantsThirdDecisionMaker)}
              label={i18n.t('EditMedicalDecisionMaker.thirdMdmSelected', 'Second Medical Decision Maker Selected')}
            />
            <CardRow
              {...sharedCardRowProps}
              question={getQuestion(questions, GuideQuestionIdEnum.ThirdDecisionMakerName)}
              answerText={getThirdMdmName(plan)}
              label={i18n.t('EditMedicalDecisionMaker.fullName', 'Full Name')}
            />
            <CardRow
              {...sharedCardRowProps}
              question={getQuestion(questions, GuideQuestionIdEnum.ThirdDecisionMakerRelationship)}
              label={i18n.t('EditMedicalDecisionMaker.relationship', 'Relationship')}
              plan={plan}
            />
            <CardRow
              {...sharedCardRowProps}
              question={getQuestion(questions, GuideQuestionIdEnum.ThirdDecisionMakerContact)}
              answerText={plan['thirdDecisionMakerEmail'] || EMPTY_TEXT}
              label={i18n.t('profile.email', en.profile.email)}
            />
            <CardRow
              {...sharedCardRowProps}
              question={getQuestion(questions, GuideQuestionIdEnum.ThirdDecisionMakerContact)}
              answerText={plan['thirdDecisionMakerPhone'] || EMPTY_TEXT}
              label={i18n.t('EditMedicalDecisionMaker.phone', 'Phone')}
            />
            {getQuestion(questions, GuideQuestionIdEnum.ThirdDecisionMakerAddress) && (
              <CardRow
                {...sharedCardRowProps}
                question={getQuestion(questions, GuideQuestionIdEnum.ThirdDecisionMakerAddress)}
                answerText={getThirdAddress(plan) || EMPTY_TEXT}
                label={i18n.t('EditMedicalDecisionMaker.address', 'Address')}
              />
            )}
            <DeleteRow>
              <Button outline style={{ width: 150 }} onClick={() => handleDelete(DeleteMDMType.thirdMDM)}>
                {i18n.t('EditMedicalDecisionMaker.deleteAll', 'Delete All')}
              </Button>
            </DeleteRow>
          </>
        )}
      </Card>
      {confirmDelete && (
        <ConfirmRemoveMdmModal
          onConfirm={() => setConfirmDelete(undefined)}
          plan={plan}
          type={confirmDelete}
          onCancel={() => setConfirmDelete(undefined)}
        />
      )}
    </>
  )
}
