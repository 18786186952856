import Popover from "components/common/Popover";
import { ExclamationCircleFilled } from "@ant-design/icons";
// lib
import config from "lib/config";
import theme from "lib/theme";

interface PopoverContentProps {
  title: string;
  description?: string;
}

const PopoverContent = ({ title, description }: PopoverContentProps) => {
  return (
    <div
      style={{
        width: 325,
        maxWidth: "100%",
        display: "flex",
      }}
    >
      <ExclamationCircleFilled
        style={{
          marginRight: 8,
          fontSize: 17,
          color: theme.colors.yellow5,
          position: "relative",
          top: 3,
        }}
      />
      <div>
        <p style={{ margin: 0 }}>
          <strong>{title}</strong>
        </p>
        {description || (
          <p style={{ margin: 0 }}>
            Contact Koda Support at{" "}
            <a href={`tel:+${config.supportPhone.replace(/-/g, "")}`}>
              {config.supportPhone}
            </a>
            <br /> or{" "}
            <a href={`mailto:${config.supportEmail}`}>{config.supportEmail}</a>.
          </p>
        )}
      </div>
    </div>
  );
};

export default function WarningPopover({
  children,
  title,
  description,
  showPopover = true,
}: {
  children?: JSX.Element | JSX.Element[] | string;
  title: string;
  description?: string;
  showPopover?: boolean;
}) {
  if (!showPopover) return <>{children}</>;

  return (
    <Popover
      content={<PopoverContent title={title} description={description} />}
    >
      {children}
    </Popover>
  );
}
