import { Page, Text, View, Image } from '@react-pdf/renderer'
import logoPNG from 'components/common/PlanDoc/horizontal-logo.png'
import CoverLetter from 'components/common/PlanDoc/KodaContent/FaxCoverLetter'
// LIB
import theme from 'lib/theme'
import { QuestionItem } from 'lib/types/QuestionItem'
import part1PNG from 'components/common/PlanDoc/imgs/part1.png'
import part2PNG from 'components/common/PlanDoc/imgs/part4.png'
import part3PNG from 'components/common/PlanDoc/imgs/part3.png'
import TableOfContentsItem from 'components/common/PlanDoc/KodaContent/TableOfContentsItem'
import QualityOfLifeV2 from '../MasterPlanDoc/HealthCareChoices/QualityOfLifeV2'
import MdmPage, { MedicalDecisionMakerContactFields } from './MdmPage'
import {
  MedicalCareTreatmentUnacceptable,
  MedicalCareTreatmentToday,
} from '../MasterPlanDoc/HealthCareChoices/MedicalCareTreatment'
import MeAndMyValues from '../MasterPlanDoc/HealthCareChoices/MeAndMyValues'
// helpers
import s from './styles'
import DocPage from './DocPage'
import { GuideQuestionIdEnum, Patient, PatientFragmentFragment } from 'generated/graphql'
import SupportiveCare from '../MasterPlanDoc/HealthCareChoices/SupportiveCare'
import SessionSummary from './SessionSummary'
import ESignCertificate from './ESignCertificate'
import { AdPartEnum } from '../MasterPlanDoc/plan-settings/types'
import flatten from 'lib/helpers/flatten'

const LineBreak = () => (
  <View
    style={{
      height: 1,
      backgroundColor: theme.colors.neutral9,
    }}
  />
)

const Page1 = ({ stateName }) => (
  <View>
    <View
      style={{
        marginTop: 24,
        display: 'flex',
        justifyContent: 'center',
        marginHorizontal: '25%',
      }}
    >
      <Image src={logoPNG} style={{ width: '250px' }} />
    </View>
    <View style={{ marginTop: 32 }}>
      <Text style={s.title}>Koda Care Plan</Text>
      <Text style={s.title}>and {stateName} Advance Directive</Text>
    </View>
    <View style={{ marginTop: 20 }}>
      <Text style={{ color: 'red', fontSize: 16, textAlign: 'center' }}>
        Currently for {stateName}, we only provide a Koda Care Plan
      </Text>
      <Text style={{ color: 'red', fontSize: 16, textAlign: 'center' }}>
        and the {stateName} Advance Directive "as is" (see note below).
      </Text>
    </View>
    <View style={{ marginTop: 15, marginBottom: 15 }}>
      <Text style={s.subtitle}>This form has 3 parts:</Text>
      <TableOfContentsItem img={part1PNG} text="Part 1 - Review Koda Care Plan" />
      <TableOfContentsItem
        img={part2PNG}
        text={`Part 2 - Use the Koda Care Plan as your guide to complete the ${stateName} Advance Directive`}
      />
      <TableOfContentsItem
        img={part3PNG}
        text={`Part 3 - Sign and legalize Advance Directive according to ${stateName} requirements `}
      />
    </View>
    <LineBreak />
    <LineBreak />
    <View
      style={{
        marginTop: 32,
        marginBottom: 0,
        backgroundColor: theme.colors.primary10,
        padding: 16,
        borderRadius: 5,
      }}
    >
      <Text style={s.blueText}>
        Note: You can use the Koda Care Plan as your guide to complete your Advance Directive. The {stateName} Advance
        Directive is provided to you as a public service. It is provided "as is" and not the substitute for the advice
        of an attorney. By providing these forms and information, Koda Health is not providing legal advice to you.
      </Text>
      <Text style={s.blueText}>If you have any questions, please contact us at care@kodahealthcare.com.</Text>
    </View>
  </View>
)

const Page2 = ({ data }) => (
  <View>
    <QualityOfLifeV2 data={data} />
    <MeAndMyValues data={data} />
  </View>
)

const Page5 = ({ stateName }) => (
  <View>
    <View
      style={{
        marginTop: 100,
        display: 'flex',
        justifyContent: 'center',
        marginHorizontal: '25%',
      }}
    >
      <Image src={logoPNG} style={{ width: '250px' }} />
    </View>
    <View style={{ marginTop: 50, marginBottom: 50 }}>
      <Text style={s.title}>The following pages are Parts 2-3: </Text>
      <TableOfContentsItem
        img={part2PNG}
        text={`Part 2 - Use the Koda Care Plan as your guide to complete the ${stateName} Advance Directive`}
      />
      <TableOfContentsItem
        img={part3PNG}
        text={`Part 3 - Sign and legalize Advance Directive according to ${stateName} requirements`}
      />
    </View>
    <LineBreak />
    <LineBreak />
    <View
      style={{
        marginTop: 32,
        marginBottom: 0,
        backgroundColor: theme.colors.primary10,
        padding: 16,
        borderRadius: 5,
      }}
    >
      <Text style={s.blueText}>
        Note: You can use the Koda Care Plan as your guide to complete your Advance Directive. The {stateName} Advance
        Directive is provided to you as a public service. It is provided "as is" and not the substitute for the advice
        of an attorney. By providing these forms and information, Koda Health is not providing legal advice to you.
      </Text>
      <Text style={s.blueText}>If you have any questions, please contact us at care@kodahealthcare.com.</Text>
    </View>
  </View>
)

interface KodaContentProps {
  data: PatientFragmentFragment
  questions: QuestionItem[]
  createdStamp: string
  includeCoverLetter?: boolean
  clinicName: string
  faxNumber?: string
  stateName?: string
  hasSupportiveCare?: boolean
}

export default function KodaContent({
  data,
  questions,
  createdStamp,
  includeCoverLetter,
  clinicName,
  faxNumber,
  stateName,
  hasSupportiveCare,
}: KodaContentProps) {
  const sharedProps = {
    stateName,
    data,
    questions,
  }

  const settings: any = undefined

  const name = `Name: ${data.firstName} ${data.lastName}`

  const questionIds = flatten(data?.guideTemplate?.sections?.map((section) => section.questionIds))
  const fileName = `${data?.hasOfficialPlan ? 'Official' : 'Unofficial'}-KodaCarePlan.pdf`
  return (
    <>
      {includeCoverLetter && (
        <Page>
          <CoverLetter
            faxNumber={faxNumber}
            clinicName={clinicName}
            patientName={`${data.firstName} ${data.lastName}`}
            birthday={data.birthday as string}
            patientCreatedAt={data.createdAt as string}
            filename={fileName}
          />
        </Page>
      )}
      {questionIds?.includes(GuideQuestionIdEnum.AffordMedications) && (
        <DocPage
          pageTitle="Copy for Provider"
          name={`${data.firstName} ${data.lastName}`}
          content={<SessionSummary data={data as Patient} />}
          showPageNumbers
          createdStamp={createdStamp}
        />
      )}
      {/* PAGE 1 */}
      <DocPage name={name} content={<Page1 {...sharedProps} />} />
      {/* PAGE 2 */}
      <DocPage
        pageTitle="Part 1: Review Koda Care Plan"
        name={name}
        content={<Page2 {...sharedProps} />}
        showPageNumbers
        createdStamp={createdStamp}
      />
      {/* PAGE 3 */}
      <DocPage
        pageTitle="Part 1: Review Koda Care Plan"
        name={name}
        content={<MedicalCareTreatmentToday {...sharedProps} />}
        showPageNumbers
        createdStamp={createdStamp}
      />
      <DocPage
        pageTitle="Part 1: Review Koda Care Plan"
        name={name}
        content={<MedicalCareTreatmentUnacceptable {...sharedProps} />}
        showPageNumbers
        createdStamp={createdStamp}
      />
      {hasSupportiveCare && (
        <DocPage
          pageTitle="Part 1: Review Koda Care Plan"
          name={name}
          content={<SupportiveCare {...sharedProps} />}
          showPageNumbers
          createdStamp={createdStamp}
        />
      )}
      {/* PAGE 4 */}
      <DocPage
        pageTitle="Part 1: Review Koda Care Plan"
        name={name}
        content={
          <View>
            <MdmPage
              {...sharedProps}
              part={settings?.parts?.find(({ id }) => id === AdPartEnum.medical_decision_maker)}
            />
            <MedicalDecisionMakerContactFields
              {...sharedProps}
              part={settings?.parts?.find(({ id }) => id === AdPartEnum.medical_decision_maker)}
            />
          </View>
        }
        showPageNumbers
        createdStamp={createdStamp}
      />
      {/* PAGE 5 */}
      <DocPage
        pageTitle={`Part 2: ${stateName} Advance Directive`}
        name={name}
        content={<Page5 {...sharedProps} />}
        showPageNumbers
        createdStamp={createdStamp}
      />
      {data?.patientSignatureDate && (
        <DocPage
          pageTitle="Part 5: eSigning Certificate"
          name={name}
          content={<ESignCertificate data={data} />}
          showPageNumbers
          createdStamp={createdStamp}
        />
      )}
    </>
  )
}
