import { LoginDocument, LoginMutationVariables } from 'generated/graphql'
import client from 'ApolloClient/index'
import setLocalStorage from './setLocalStorage'
import constants from 'lib/config'

export default function handleLogin({ email, password }): Promise<{ mfaRequired?: boolean }> {
  return new Promise(async (resolve, reject) => {
    try {
      // sign in with email
      const variables: LoginMutationVariables = {
        params: {
          password,
          user: {
            email: email,
          },
        },
      }
      let result = await client.mutate({
        mutation: LoginDocument,
        variables,
      })

      setLocalStorage(constants.authTokenName, result?.data?.login?.tokens?.accessToken)
      setLocalStorage(constants?.refreshTokenName, result?.data?.login?.tokens?.refreshToken)

      resolve(result?.data?.login)
    } catch (err) {
      reject(err)
    }
  })
}
