import { useEffect } from 'react'
import config from 'lib/config'
import { excludeGraphQLFetch } from 'apollo-link-sentry'
import { Integrations } from '@sentry/tracing'
import * as Sentry from '@sentry/browser'

// Global interface declaration
declare global {
  interface Window {
    Sentry: typeof Sentry
  }
}

export default function useSentry() {
  useEffect(() => {
    // register sentry if we're not running locally
    Sentry.init({
      dsn: config?.SENTRY_DSN,
      // debug: config.NODE_ENV !== 'production',
      integrations: [new Integrations.BrowserTracing()],
      // We recommend adjusting this value in production, or using tracesSampler
      // for finer control
      release: config.RELEASE,
      tracesSampleRate: 1.0,
      environment: config.NODE_ENV,
      beforeBreadcrumb: (breadcrumb, hint) => {
        // we need more breadcrumb information sent to sentry... styled-components have jumbled classNames so it's hard to tell what was clicked
        if (breadcrumb.category === 'ui.click') {
          return {
            ...breadcrumb,
            innerText: hint?.event?.target?.innerText,
            query: window?.location?.search,
          }
        }
        return excludeGraphQLFetch(breadcrumb, hint)
      },
      ignoreErrors: [/FORBIDDEN[a-zA-Z]*/, /UNAUTHENTICATED[a-zA-Z]*/, /BAD_USER_INPUT[a-zA-Z]*/],
    })
  }, [])
}
