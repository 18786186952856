import Select from "antd/lib/select";
import "antd/lib/select/style/css";
import DropdownStyleWrapper from "../DropdownStyleWrapper";
// APOLLO
import { useStatesQuery } from "generated/graphql";

// CONSTANTS AND DESTRUCTURING
// ================================================================
const Option = Select.Option;

// EXPORTED COMPONENT
// ================================================================
const StatesInput = (props) => {
  const { onChange, value = "" } = props;
  const { data, loading } = useStatesQuery();

  if (loading) return null;

  return (
    <DropdownStyleWrapper>
      <Select
        {...props}
        size="large"
        onChange={onChange}
        value={value || undefined}
        optionFilterProp="children"
      >
        {data &&
          (data || [])?.states?.map((state) => (
            <Option key={state?.id || ""} value={state?.id || ""}>
              {props.abbrev ? state?.abbreviation : state?.name}
            </Option>
          ))}
      </Select>
    </DropdownStyleWrapper>
  );
};

StatesInput.defaultProps = {
  style: {
    width: "100%",
  },
  placeholder: "Select one",
};

// EXPORT
// ================================================================
export default StatesInput;
