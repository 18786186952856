import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

import SectionBlockOption from './SectionBlockOption';

const SectionBlockStyled = styled.div`
  max-width: 550px;
`;

const SectionBlockBody = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 24px;
  position: relative;
  @media only screen and (max-width: ${({ theme }) =>
  theme.breakpoints.medium}) {
    flex-wrap: wrap;
    justify-content: center;
  }
`;

const SectionBlockBgLine = styled.div`
  background-color: #CBD2D9;
  height: 8px;
  width: 92%;
  top: 26px;
  left: 0;
  right: 0;
  margin: 0 auto;
  position: absolute;
  z-index: 1;
  @media only screen and (max-width: ${({ theme }) =>
  theme.breakpoints.medium}) {
    display: none;
  }
`;

const SectionBlockTitle = styled.div`
  font-size: 18px;
  line-height: 21px;
  margin-bottom: 8px;
  color: #1F2933;
`;

const SectionBlockSubTitle = styled.div`
  font-size: 16px;
  line-height: 19px;
  color: #616E7C;
`;

interface SectionBlockProps {
  title: string;
  subtitle: string;
  value?: number | null;
  onSelect: (value: number) => void;
};

const SectionBlock: FC<SectionBlockProps> = ({ title, subtitle, value, onSelect }) => {
  const { t } = useTranslation();

  return (
    <SectionBlockStyled>
      <SectionBlockTitle>{title}</SectionBlockTitle>
      <SectionBlockSubTitle>{subtitle}</SectionBlockSubTitle>
      <SectionBlockBody>
        <SectionBlockBgLine />
        <SectionBlockOption onClick={() => onSelect(1)} active={value === 1}>
          <Trans>
            {t("evaluativeChoiceAnswers.notAtAll", "Not <br /> at all")}
          </Trans>
        </SectionBlockOption>
        <SectionBlockOption onClick={() => onSelect(2)} active={value === 2}>
          {t("evaluativeChoiceAnswers.aLittle", "A Little")}
        </SectionBlockOption>
        <SectionBlockOption onClick={() => onSelect(3)} active={value === 3}>
          {t("evaluativeChoiceAnswers.somewhat", "Somewhat")}
        </SectionBlockOption>
        <SectionBlockOption onClick={() => onSelect(4)} active={value === 4}>
          {t("evaluativeChoiceAnswers.fairly", "Fairly")}
        </SectionBlockOption>
        <SectionBlockOption onClick={() => onSelect(5)} active={value === 5}>
          {t("evaluativeChoiceAnswers.extremely", "Extremely")}
        </SectionBlockOption>
      </SectionBlockBody>
    </SectionBlockStyled>
  );
}

export default SectionBlock;
