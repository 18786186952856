import styled from 'styled-components'

const BIG = '24px'
const SMALL = '18px'

export const QuestionText = styled.label`
  font-size: ${BIG};
  line-height: 150%;
  display: block;
  font-weight: 400;
  margin-bottom: 24px;
  font-family: ${(p) => p.theme.fontFamily};
  color: ${(p) => p.theme.colors.neutral2};
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    font-size: ${SMALL};
    margin-bottom: 8px;
    line-height: 135%;
  }

  a {
    font-size: ${BIG};
    line-height: 32px;
    font-weight: 400;
    margin-bottom: 32px;
    font-family: ${(p) => p.theme.fontFamily};
    @media only screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
      font-size: ${SMALL};
      margin-bottom: 8px;
      line-height: 135%;
    }
  }
`

export default QuestionText
