// import { Upload } from "@aws-sdk/lib-storage";
// import { S3Client } from "@aws-sdk/client-s3";
// import { nanoid } from "nanoid";
// import { useState } from "react";
// import { toNumber } from "lodash";
// import { useGetS3UploaderCredentialsMutation } from "generated/graphql";

// const getProgress = (part: number, length: number) =>
//   toNumber(((part / length) * 100).toFixed(0));

// export default function useUploadFile() {
//   const [getS3UploaderCredentialsMutation] =
//     useGetS3UploaderCredentialsMutation();
//   const [uploading, setUploading] = useState<boolean>(false);
//   const [progress, setProgress] = useState<string | number>(0);

//   const uploadFile = async (file: File, filepath?: string) => {
//     try {
//       setUploading(true);

//       const creds = await getS3UploaderCredentialsMutation();
//       const credentials = {
//         accessKeyId: creds?.data?.getS3UploaderCredentials?.accessKeyId,
//         secretAccessKey: creds?.data?.getS3UploaderCredentials?.secretAccessKey,
//       };
//       const key = `uploads/${nanoid()}.pdf`;

//       if (
//         !creds?.data?.getS3UploaderCredentials?.bucket ||
//         !credentials?.accessKeyId ||
//         !credentials?.secretAccessKey
//       ) {
//         throw new Error("Can not upload at this time...");
//       }

//       const target = {
//         Bucket: creds?.data?.getS3UploaderCredentials?.bucket,
//         Key: key,
//         Body: file,
//       };

//       const parallelUploads3 = new Upload({
//         client: new S3Client({
//           region: "us-east-1",
//           credentials,
//         }),
//         // tags: [...], // optional tags
//         queueSize: 4, // optional concurrency configuration
//         //partSize: 5MB, // optional size of each part
//         leavePartsOnError: false, // optional manually handle dropped parts
//         params: target,
//       });

//       parallelUploads3.on("httpUploadProgress", (progress) => {
//         if (progress?.loaded && progress?.total) {
//           const prog = getProgress(progress?.loaded, progress?.total);
//           setProgress(prog);
//         }
//       });

//       const res = await parallelUploads3.done();

//       const uploadedFile = {
//         fileType: file.type,
//         filename: file.name,
//         key,
//         baseUrl: res["Bucket"],
//         url: res["Location"],
//       };

//       setUploading(false);
//       return uploadedFile;
//     } catch (err) {
//       console.log(err);
//       setUploading(false);
//       throw new Error(
//         "Something went wrong with your upload. Please make sure it is no larger than 50mb."
//       );
//     }
//   };

//   return [uploadFile, uploading, progress] as const;
// }

import {
  MultipartUploadCompleteEnum,
  useCompleteMultiUploadMutation,
  useGetPresignedUploadMultiPartMutation,
} from 'generated/graphql'
import ky from 'ky'

import { useState } from 'react'

import { split } from 'lodash'
import { toNumber } from 'lodash'
import translateApolloError from 'lib/helpers/translateApolloError'

const FILE_CHUNK_SIZE = 1024 * 1024 * 5 // 5MB

const getPartsFromFile = (file: File) => {
  const parts: Blob[] = []
  console.log('size', file.size)
  for (let start = 0; start < file.size; start += FILE_CHUNK_SIZE) {
    const offset = start + FILE_CHUNK_SIZE
    const chunk = file.slice(start, offset)
    console.log(start, offset)
    parts.push(chunk)
  }
  console.log(parts)
  console.log(parts?.map((blob) => blob.size).reduce((a, b) => a + b, 0) === file.size)
  return parts
}

const getProgress = (part: number, length: number) => toNumber(((part / length) * 100).toFixed(0))

export default function useUploadFile() {
  const [uploading, setUploading] = useState<boolean>(false)
  const [progress, setProgress] = useState<string | number>(0)
  const [completeMultiUploadMutation] = useCompleteMultiUploadMutation()
  const [getPresignedUploadMultiPartMutation] = useGetPresignedUploadMultiPartMutation()

  const uploadFile = async (file: File, filepath?: string) => {
    let presignRes
    let completedParts: {
      ETag: string
      PartNumber: number
    }[] = []

    try {
      setUploading(true)

      const parts: Blob[] = getPartsFromFile(file)
      const response = await getPresignedUploadMultiPartMutation({
        variables: {
          parts: parts.length,
          contentType: file.type || 'application/pdf',
        },
      })
      presignRes = response?.data?.getPresignedUploadMultiPart
      const urls = presignRes?.urls

      const uploadedFile: {
        fileType: string
        filename: string
        key: string | number
        url?: string
        baseUrl?: string | string[]
      } = {
        fileType: file.type,
        filename: file.name,
        key: presignRes?.key,
      }

      for (let i = 0; i < urls.length; i++) {
        const url = urls[i]
        const part = parts[i]
        const partNumber = i + 1

        const res: any = await ky.put(url, {
          body: part,
          timeout: 2147483647,
        })

        const urlParts = split(res?.url, '/')

        uploadedFile.url = `https://${urlParts?.[2]}/${presignRes?.key}`
        uploadedFile.baseUrl = urlParts?.[2]
        completedParts.push({
          ETag: res?.headers?.get('ETag')?.replace(`"`, '')?.replace(`"`, ''),
          PartNumber: partNumber,
        })

        const prog = getProgress(partNumber, urls.length)
        setProgress(prog)
      }

      await completeMultiUploadMutation({
        variables: {
          type: MultipartUploadCompleteEnum.Complete,
          uploadId: presignRes?.uploadId,
          key: presignRes?.key,
          parts: completedParts,
        },
      })
      setUploading(false)
      setProgress(0)
      return uploadedFile
    } catch (err: any) {
      console.log(err)
      setUploading(false)
      setProgress(0)
      if (presignRes?.uploadId) {
        completeMultiUploadMutation({
          variables: {
            type: MultipartUploadCompleteEnum.Abort,
            uploadId: presignRes?.uploadId,
            key: presignRes?.key,
            parts: completedParts,
          },
        })
      }

      throw new Error(
        translateApolloError(err) ||
          'Something went wrong with your upload. Please make sure it is no larger than 50mb.'
      )
    }
  }

  return [uploadFile, uploading, progress] as const
}
