import i18n from 'lib/helpers/i18n'
import { Text, View } from '@react-pdf/renderer'
// helpers
import s from 'components/common/PlanDoc/KodaContent/styles'
import PlanSectionDocument from 'components/common/PlanDoc/PlanSectionDocument'
import theme from 'lib/theme'

const TEXT_STYLE = { ...s.text, marginBottom: 0, marginTop: 6 }
const MARGIN_BOTTOM = 0
const TexasLegalSummaryPage2 = () => {
  return (
    <View style={s.sectionContainer} break>
      <Text style={{ ...s.sectionTitle, marginBottom: MARGIN_BOTTOM }}>
        {i18n.t('TexasLegalSummaryPage2.title', 'Information Concerning the Medical Power of Attorney')}
      </Text>
      <Text style={{ ...s.textBold, marginBottom: MARGIN_BOTTOM }}>
        {i18n.t(
          'TexasLegalSummaryPage2.subtitle',
          'This is an important legal document. Before signing this document, you should know these important facts:'
        )}
      </Text>
      <Text style={TEXT_STYLE}>
        {i18n.t(
          'TexasLegalSummaryPage2.text1',
          `
            Except to the extent you state otherwise, this document gives the person you name as your agent the authority
            to make any and all health care decisions for you in accordance with your wishes, including your religious and
            moral beliefs, when you are unable to make the decisions for yourself. Because "health care" means any
            treatment, service, or procedure to maintain, diagnose, or treat your physical or mental condition, your agent
            has the power to make a broad range of health care decisions for you. Your agent may consent, refuse to
            consent, or withdraw consent to medical treatment and may make decisions about withdrawing or withholding
            life- sustaining treatment. Your agent may not consent to voluntary inpatient mental health services,
            convulsive treatment, psychosurgery, or abortion. A physician must comply with your agent's instructions or
            allow you to be transferred to another physician.
          `
        )}
      </Text>
      <Text style={TEXT_STYLE}>
        {i18n.t(
          'TexasLegalSummaryPage2.text2',
          `
              Your agent's authority is effective when your doctor certifies that you lack the competence to make health
              care decisions.
              `
        )}
      </Text>
      <Text style={TEXT_STYLE}>
        {i18n.t(
          'TexasLegalSummaryPage2.text3',
          `
              Your agent is obligated to follow your instructions when making decisions on your behalf. Unless you state
            otherwise, your agent has the same authority to make decisions about your health care as you would have if you
            were able to make health care decisions for yourself. It is important that you discuss this document with your
            physician or other health care provider before you sign the document to ensure that you understand the nature
            and range of decisions that may be made on your behalf. If you do not have a physician, you should talk with
            someone else who is knowledgeable about these issues and can answer your questions. You do not need a lawyer's
            assistance to complete this document, but if there is anything in this document that you do not understand,
            you should ask a lawyer to explain it to you.
              `
        )}
      </Text>
      <Text style={TEXT_STYLE}>
        {i18n.t(
          'TexasLegalSummaryPage2.text4',
          `
              The person you appoint as agent should be someone you know and trust. The person must be 18 years of age or
              older or a person under 18 years of age who has had the disabilities of minority removed. If you appoint your
              health or residential care provider (e.g., your physician or an employee of a home health agency, hospital,
              nursing facility, or residential care facility, other than a relative), that person has to choose between
              acting as your agent or as your health or residential care provider; the law does not allow a person to serve
              as both at the same time. You should inform the person you appoint that you want the person to be your health
              care agent. You should discuss this document with your agent and your physician and give each a signed copy.
              You should indicate on the document itself the people and institutions that you intend to have signed copies.
              Your agent is not liable for health care decisions made in good faith on your behalf.
              `
        )}
      </Text>
      <Text style={TEXT_STYLE} wrap={false}>
        {i18n.t(
          'TexasLegalSummaryPage2.text5',
          `
              Once you have signed this document, you have the right to make health care decisions for yourself as long as
              you are able to make those decisions, and treatment cannot be given to you or stopped over your objection. You
              have the right to revoke the authority granted to your agent by informing your agent or your health or
              residential care provider orally or in writing or by your execution of a subsequent medical power of attorney.
              Unless you state otherwise in this document, your appointment of a spouse is revoked if your marriage is
              dissolved, annulled, or declared void.
              `
        )}
      </Text>
      <Text style={TEXT_STYLE} wrap={false}>
        {i18n.t(
          'TexasLegalSummaryPage2.text6',
          `
              This document may not be changed or modified. If you want to make changes in this document, you must execute a
            new medical power of attorney.
              `
        )}
      </Text>
      <Text style={TEXT_STYLE} wrap={false}>
        {i18n.t(
          'TexasLegalSummaryPage2.text7',
          `
              You may wish to designate an alternate agent in the event that your agent is unwilling, unable, or ineligible
            to act as your agent. If you designate an alternate agent, the alternate agent has the same authority as the
            agent to make health care decisions for you.
              `
        )}
      </Text>
    </View>
  )
}

const TexasLegalSummaryPage3 = () => {
  const MARGIN_BOTTOM = 0
  const TEXT_STYLE = { ...s.text, marginBottom: 0, marginTop: 6 }
  return (
    <View>
      <View style={s.sectionContainer} break>
        <Text style={{ ...s.sectionTitle, marginBottom: MARGIN_BOTTOM }}>
          {i18n.t('TexasLegalSummaryPage3.title', 'Information Concerning the Medical Power of Attorney (continued)')}
        </Text>
        <Text style={{ ...s.textBold, marginBottom: MARGIN_BOTTOM }}>
          {i18n.t('TexasLegalSummaryPage3.text1', 'This POWER OF ATTORNEY IS NOT VALID UNLESS:')}
        </Text>
        <Text style={TEXT_STYLE}>
          {i18n.t(
            'TexasLegalSummaryPage3.text2',
            '(1) YOU SIGN IT AND HAVE YOUR SIGNATURE ACKNOWLEDGED BEFORE A NOTARY PUBLIC; OR'
          )}
        </Text>
        <Text style={TEXT_STYLE}>
          {i18n.t('TexasLegalSummaryPage3.text3', '(2) YOU SIGN IT IN THE PRESENCE OF TWO COMPETENT ADULT WITNESSES.')}
        </Text>
        <Text style={{ ...s.textBold, marginBottom: MARGIN_BOTTOM }}>
          {i18n.t('TexasLegalSummaryPage3.text4', 'THE FOLLOWING PERSONS MAY NOT ACT AS ONE OF THE WITNESSES:')}
        </Text>
        <Text style={TEXT_STYLE}>
          {i18n.t('TexasLegalSummaryPage3.text5', '(1) the person you have designated as your agent;')}
        </Text>
        <Text style={TEXT_STYLE}>
          {i18n.t('TexasLegalSummaryPage3.text6', '(2) a person related to you by blood or marriage;')}
        </Text>
        <Text style={TEXT_STYLE}>
          {i18n.t(
            'TexasLegalSummaryPage3.text7',
            '(3) a person entitled to any part of your estate after your death under a will or codicil executed by you or by operation of law;'
          )}
        </Text>
        <Text style={TEXT_STYLE}>{i18n.t('TexasLegalSummaryPage3.text8', '(4) your attending physician;')}</Text>
        <Text style={TEXT_STYLE}>
          {i18n.t('TexasLegalSummaryPage3.text9', '(5) an employee of your attending physician;')}
        </Text>
        <Text style={TEXT_STYLE}>
          {i18n.t(
            'TexasLegalSummaryPage3.text10',
            `(6) an employee of a health care facility in which you are a patient if the employee is providing direct
            patient care to you or is an officer, director, partner, or business office employee of the health care
            facility or of any parent organization of the health care facility; or`
          )}
        </Text>
        <Text style={TEXT_STYLE}>
          {i18n.t(
            'TexasLegalSummaryPage3.text11',
            `(7) a person who, at the time this medical power of attorney is executed, has a claim against any part of your
              estate after your death.`
          )}
        </Text>
      </View>
    </View>
  )
}
interface ImageBulletProps {
  number?: string
  text?: string
}

const ImageBullet = ({ number, text }: ImageBulletProps) => {
  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        marginBottom: 12,
      }}
    >
      <View style={{ width: '6%' }}>
        <View
          style={{
            height: 24,
            width: 24,
            backgroundColor: theme.colors.primary10,
            borderRadius: 24 / 2,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Text style={s.textNoMargin}>{number}</Text>
        </View>
      </View>
      <View style={{ width: '94%' }}>
        <Text style={s.textNoMargin}>{text}</Text>
      </View>
    </View>
  )
}

const TexasLegalSummaryPage1 = () => {
  return (
    <View style={s.sectionContainer}>
      <Text style={s.sectionTitle}>{i18n.t('TexasLegalSummaryPage1.title', 'Legal Terms Summary')}</Text>
      <Text style={s.textBold}>{i18n.t('TexasLegalSummaryPage1.subtitle', 'Texas legal terms we must give you:')}</Text>
      <Text style={s.text}>
        {i18n.t(
          'TexasLegalSummaryPage1.text1',
          `We made sure this legal form is easy to read. But, Texas law requires us to use legal terms and words too. The
          next 2 pages have the legal language we must give you. Here are the 5 main points:`
        )}
      </Text>
      <View style={{ height: 8 }} />
      <ImageBullet
        number="1"
        text={i18n.t(
          'TexasLegalSummaryPage1.text2',
          `Your medical decision maker can make all health care decisions for you
            if you are not able to make them yourself.`
        )}
      />
      <ImageBullet
        number="2"
        text={i18n.t(
          'TexasLegalSummaryPage1.text3',
          `But, your decision maker cannot send you to a mental institution, make
            you have electric shock treatments or brain surgery, or allow a doctor
            to perform an abortion on you.`
        )}
      />
      <ImageBullet
        number="3"
        text={i18n.t(
          'TexasLegalSummaryPage1.text4',
          `Your medical decision maker must follow your wishes to the best of
            their ability.`
        )}
      />
      <ImageBullet
        number="4"
        text={i18n.t(
          'TexasLegalSummaryPage1.text5',
          `You can change the wishes you made on this form whenever you want, but
            you will have to fill out the form again. If you fill out a new form,
            destroy your old form and share your new form and your wishes with
            your medical decision maker and medical providers.`
        )}
      />
      <ImageBullet
        number="5"
        text={i18n.t(
          'TexasLegalSummaryPage1.text6',
          ` You may want a backup medical decision maker in case the one you
            choose is not able to help when you need them. Write their name and
            contact information in Part 2.`
        )}
      />
    </View>
  )
}

const TexasEnglishLegal = ({ name, createdStamp, pageTitle }) => {
  return (
    <>
      <PlanSectionDocument
        pageTitleLeft={pageTitle}
        pageTitleRight={name}
        showFooter
        pageFooterRight={createdStamp}
        content={
          <>
            <TexasLegalSummaryPage1 />
            <TexasLegalSummaryPage2 />
            <TexasLegalSummaryPage3 />
          </>
        }
      />
    </>
  )
}

const WRAP = true

export default function TexasLegal({ data, name, createdStamp, pageTitle }) {
  if (i18n.language === 'en') {
    return <TexasEnglishLegal name={name} createdStamp={createdStamp} pageTitle={pageTitle} />
  }

  return (
    <>
      <PlanSectionDocument
        pageTitleLeft={pageTitle}
        pageTitleRight={name}
        showFooter
        pageFooterRight={createdStamp}
        content={
          <>
            <View style={s.sectionContainer}>
              <Text style={s.sectionTitle}>Resumen de términos legales</Text>
              <Text style={s.textBold}>Términos legales de Texas que debemos entregarle:</Text>
              <Text style={s.text}>
                Nos hemos asegurado de que este formulario legal sea fácil de leer. Sin embargo, la ley de Texas también
                nos obliga a usar términos y palabras legales. Las siguientes 2 páginas contienen el lenguaje legal que
                debemos proporcionarle a usted. Estos son los 5 puntos principales:
              </Text>
              <View style={{ height: 8 }} />
              <ImageBullet
                number="1"
                text={`Su encargado de tomar decisiones médicas puede tomar todas las decisiones de atención médica en su nombre si usted no puede tomarlas por sí mismo.`}
              />
              <ImageBullet
                number="2"
                text={`Sin embargo, su encargado de tomar decisiones no puede enviarlo a usted a una institución de salud mental, someterle a tratamientos de terapia electroconvulsiva o cirugía de cerebro ni permitir que un doctor le realice un aborto a usted.`}
              />
              <ImageBullet
                number="3"
                text={`Su encargado de tomar decisiones médicas debe seguir lo mejor posible los deseos que usted haya expresado.`}
              />
              <ImageBullet
                number="4"
                text={`Usted puede cambiar los deseos que expresó en este formulario cuando usted quiera, pero tendrá que volver a llenar el formulario. Si llena un nuevo formulario, destruya el anterior y comparta el nuevo, así como sus deseos, con su encargado de tomar decisiones médicas y sus proveedores médicos.`}
              />
              <ImageBullet
                number="5"
                text={`Es recomendable que tenga un encargado suplente para tomar decisiones médicas en caso de que la persona que usted haya elegido no pueda ayudarle cuando usted lo necesite. Escriba el nombre e información de contacto de esta persona en la parte 2.`}
              />
            </View>
            {/* PAGE BREAK */}
            <View wrap={WRAP}>
              <View style={s.sectionContainer} wrap={WRAP} break>
                <Text style={{ ...s.sectionTitle, marginBottom: MARGIN_BOTTOM }}>
                  Información sobre el Poder notarial médico
                </Text>
                <Text style={{ ...s.textBold, marginBottom: MARGIN_BOTTOM }}>
                  ESTE PODER NOTARIAL MÉDICO ES UN DOCUMENTO LEGAL IMPORTANTE. ANTES DE FIRMAR ESTE DOCUMENTO, TENGA EN
                  CUENTA LO SIGUIENTE:
                </Text>
                <Text style={TEXT_STYLE}>
                  A menos que usted establezca lo contrario, este documento autoriza a la persona que usted nombre como
                  apoderado a tomar cualquier decisión sobre la atención médica que le ataña, conforme a los deseos y
                  creencias religiosas y morales de usted, cuando usted ya no tenga la capacidad necesaria para
                  manifestar su voluntad. Dado que "atención médica" significa cualquier tratamiento, servicio o
                  procedimiento para mantener, diagnosticar o tratar su estado de salud física y mental, su apoderado
                  tiene facultades para tomar un amplio margen de decisiones en su nombre sobre la atención médica que
                  le ataña. Su apoderado puede consentir, negarse a consentir o retirar su consentimiento a que se le dé
                  a usted tratamiento médico, y puede tomar decisiones relacionadas con la retirada o suspensión de
                  cualquier tratamiento para prolongarle la vida. Su apoderado no puede dar su consentimiento a que se
                  le presten servicios voluntarios de salud mental para pacientes hospitalizados, terapia convulsiva,
                  psicocirugía o aborto. El médico tiene que cumplir las instrucciones que su apoderado le dé o permitir
                  que a usted se le transfiera para recibir la atención de otro médico.
                </Text>
                <Text style={TEXT_STYLE}>
                  La facultad de su apoderado entra en vigor a partir del momento en que su médico certifique que usted
                  carece de la capacidad para tomar decisiones sobre su atención médica.
                </Text>
                <Text style={TEXT_STYLE}>
                  Su apoderado está obligado a seguir las instrucciones de usted al tomar decisiones en su nombre. A
                  menos que usted establezca lo contrario, su apoderado tiene las mismas facultades para tomar
                  decisiones sobre su atención médica que las que usted tendría si tuviera la capacidad de manifestar su
                  voluntad acerca de la atención médica. Antes de firmar este documento, es importante que estudie su
                  contenido con su médico u otro proveedor de atención médica, a fin de asegurarse de que comprende cuál
                  es la naturaleza y la variedad de decisiones que podrán adoptarse en su nombre. Si no cuenta con un
                  médico que le atienda, deberá hablar con alguien más que esté bien informado sobre estos asuntos y
                  pueda responder a sus preguntas. No necesita la ayuda de un abogado para llenar este documento; pero,
                  si en este hay algo que no comprende, debe pedirle a un abogado que se lo explique.
                </Text>
                <Text style={TEXT_STYLE} wrap={false}>
                  La persona que usted nombre como apoderado debe ser alguien que conozca y en quien confíe. Esa persona
                  debe ser mayor de 18 años de edad o alguien menor de 18 años a quien se le hayan eliminado los
                  impedimentos por minoría de edad. Si usted nombra a su proveedor médico o proveedor de atención
                  residencial (p. ej., su médico o un empleado de una agencia de atención médica a domicilio, hospital,
                  casa de reposo o centro de atención residencial, que no sea un familiar), esa persona tendrá que
                  elegir entre actuar ya sea como su apoderado o como su proveedor de atención residencial; la ley no
                  permite que una misma persona desempeñe las dos funciones a la vez. A la persona a quien nombre, debe
                  informarle que quiere que esa persona sea su apoderado para actuar en los asuntos de atención médica
                  que le atañan a usted. Usted debe estudiar el contenido de este documento con su apoderado y su
                  médico, y entregarle a cada uno una copia firmada. Debe indicar en el propio documento las personas e
                  instituciones que usted quiere que reciban copias firmadas. Su apoderado no es responsable de aquellas
                  decisiones de atención médica que se hayan tomado de buena fe en su nombre.
                </Text>
                <Text style={TEXT_STYLE} wrap={WRAP}>
                  Una vez haya firmado este documento, tiene derecho a tomar aquellas decisiones de atención médica que
                  le afecten, siempre y cuando tenga la capacidad de tomarlas, y no se le puede dar tratamiento o
                  suspenderlo si usted se opone a ello. Tiene derecho a revocar la facultad otorgada a su apoderado
                  informando a su apoderado o proveedor médico o de atención residencial, ya sea oralmente o por
                  escrito, o formalizando un poder notarial médico posterior. A menos que establezca lo contrario en
                  este documento, la designación por usted de su cónyuge queda revocada si su matrimonio se disuelve, se
                  anula o se declara nulo.
                </Text>
                <Text style={TEXT_STYLE} wrap={WRAP}>
                  En este documento no se pueden introducir cambios ni modificaciones. Si quiere introducir cambios en
                  este documento, necesitará formalizar un nuevo poder notarial médico.
                </Text>
                <Text style={TEXT_STYLE} wrap={WRAP}>
                  Usted podría querer designar un apoderado suplente en el caso de que su apoderado no quiera, sea
                  incapaz o no reúna las condiciones para actuar como su apoderado. Si designa a un apoderado suplente,
                  el apoderado suplente tiene las mismas facultades que el apoderado para adoptar decisiones de atención
                  médica en su nombre.
                </Text>
              </View>
            </View>
            {/*  */}

            <View style={s.sectionContainer} break>
              <Text style={{ ...s.sectionTitle, marginBottom: MARGIN_BOTTOM }}>
                {i18n.t(
                  'TexasLegalSummaryPage3.title',
                  'Information Concerning the Medical Power of Attorney (continued)'
                )}
              </Text>
              <Text style={{ ...s.textBold, marginBottom: MARGIN_BOTTOM }}>
                ESTE PODER NOTARIAL NO ES VÁLIDO A MENOS QUE:
              </Text>
              <Text style={TEXT_STYLE}>(1) USTED LO FIRME Y SU FIRMA ESTÉ RECONOCIDA ANTE NOTARIO PÚBLICO;</Text>
              <Text style={TEXT_STYLE}>(2) USTED LO FIRME EN PRESENCIA DE DOS TESTIGOS ADULTOS COMPETENTES.</Text>
              <Text style={{ ...s.textBold, marginBottom: MARGIN_BOTTOM }}>
                LAS SIGUIENTES PERSONAS NO PUEDEN ACTUAR EN CALIDAD DE TESTIGOS:
              </Text>
              <Text style={TEXT_STYLE}>(1) la persona a quien usted haya designado como apoderado;</Text>
              <Text style={TEXT_STYLE}>(2) una persona relacionada con usted por consanguinidad o matrimonio;</Text>
              <Text style={TEXT_STYLE}>
                (3) una persona con derecho a cualquier porción de su patrimonio después de su muerte bajo un testamento
                o codicilo formalizado por usted o por disposición legal;
              </Text>
              <Text style={TEXT_STYLE}>(4) el médico que le atiende a usted;</Text>
              <Text style={TEXT_STYLE}>(5) un empleado del médico que le atiende a usted; </Text>
              <Text style={TEXT_STYLE}>
                (6) un empleado de un centro de atención médica del que usted es un paciente si el empleado le está
                proporcionando atención médica directa a usted, o si es un funcionario, director, socio o empleado de la
                oficina comercial del centro de atención médica o de cualquier organización matriz del centro de
                atención médica; o bien
              </Text>
              <Text style={TEXT_STYLE}>
                (7) una persona que, al momento en que este poder médico se formaliza, tiene una reclamación contra
                cualquier porción de su patrimonio después de su muerte.
              </Text>
            </View>
          </>
        }
      />
    </>
  )
}
