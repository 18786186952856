import { AdPartEnum, PlanSetting } from '../types'
import en from 'lib/helpers/locales/en/translation.json'
import { Text } from '@react-pdf/renderer'
import s from '../../../KodaContent/styles'
import { Trans } from 'react-i18next'

export default function getNY(t, defaultPartSettings, patient): PlanSetting {
  const patientFullName = `${patient?.firstName || ''} ${patient?.lastName || ''}`

  const ny: PlanSetting = {
    state: 'New York',
    disclaimer: t(
      'NY.disclaimer',
      'Disclaimer: This document needs to be signed and have two witnesses sign as well in order to be a legal document.'
    ),
    parts: [
      // Part 1
      {
        ...defaultPartSettings?.[AdPartEnum.health_care_choices],
        description: t(
          'NY.health_care_choices.description',
          'This part is also known as a Living Will. It expresses the kind of treatment you would want in a medical crisis. New York does not recognize Living Wills as a legal document. However, a Living Will can be a helpful guide for your medical decision makers.'
        ),
      },
      // Part 2
      {
        ...defaultPartSettings?.[AdPartEnum.medical_decision_maker],
        pageTitle: t(
          'NY.medical_decision_maker.pageTitle',
          'Information for Medical Decision Maker (Health Care Proxy)'
        ),
        description: t(
          'NY.medical_decision_maker.description',
          'This part is also known as a Health Care Proxy. It designates a person to make decisions for you when you are unable to speak for yourself.'
        ),
      },
      // Part 3
      {
        ...defaultPartSettings?.[AdPartEnum.sign_the_form],
        description: t(
          'NY.sign_the_form.description',
          'You can fill out Part 1, Part 2, or both, but you need to sign the form in Part 3. To make Part 2 legal, two witnesses also need to sign.'
        ),
        beforeYouSign: {
          requirements: [
            t(
              'planDoc.sign_the_form.beforeYouSignRequirements_age',
              en.planDoc.sign_the_form.beforeYouSignRequirements_age
            ),
            t(
              'planDoc.sign_the_form.beforeYouSignRequirements_witnesses',
              en.planDoc.sign_the_form.beforeYouSignRequirements_witnesses
            ),
          ],
          finalClause: t(
            'NY.sign_the_form.beforeYouSignFinalClause',
            'By signing, I confirm that I am emotionally and mentally capable of making this document. I have made this Advance Directive willingly, I understand the purpose and effect of this document, and this document accurately expresses my wishes.'
          ),
        },
        witnessSignatures: {
          preSignaturesContent: (
            <Text style={{ ...s.text, fontSize: 11 }}>
              <Trans i18nKey="NY.sign_the_form.witnessSignatures.preSignaturesContent">
                By signing, I confirm that I watched {{ patientFullName: patientFullName || '____________________ ' }}{' '}
                sign the document. I confirm that I know{' '}
                {{ patientFullName: patientFullName || '____________________ ' }}.{' '}
                {{ patientFullName: patientFullName || '____________________ ' }} appears to be emotionally competent,
                of sound mind, and under no duress, fraud, or undue influence in signing the document. I promise that I
                meet the witness requirements listed in the document.
              </Trans>
            </Text>
          ),
          //
        },
        witnessRequirements: {
          title: t('NY.sign_the_form.witnessRequirements_title', 'Witnesses Requirements'),
          requirements: [
            {
              title: t(
                'planDoc.sign_the_form.witnessRequirements_bothWitnesses',
                en.planDoc.sign_the_form.witnessRequirements_bothWitnesses
              ),
              width: '50%',
              phrases: [
                t('planDoc.sign_the_form.witnessRequirements_be18', en.planDoc.sign_the_form.witnessRequirements_be18),
                t(
                  'planDoc.sign_the_form.witnessRequirements_seeYouSign',
                  en.planDoc.sign_the_form.witnessRequirements_seeYouSign
                ),
              ],
            },
            {
              title: t(
                'planDoc.sign_the_form.witnessRequirements_yourWitnessCannot',
                en.planDoc.sign_the_form.witnessRequirements_yourWitnessCannot
              ),
              width: '50%',
              phrases: [
                t(
                  'planDoc.sign_the_form.witnessRequirements_beYourMdm',
                  en.planDoc.sign_the_form.witnessRequirements_beYourMdm
                ),
              ],
            },
          ],
          validityRequirements: [
            t(
              'planDoc.sign_the_form.validityRequirements_esignInThePresence',
              en.planDoc.sign_the_form.validityRequirements_esignInThePresence
            ),
            t(
              'planDoc.sign_the_form.validityRequirements_prinatAndSignBeforeTwoWitnesses',
              en.planDoc.sign_the_form.validityRequirements_prinatAndSignBeforeTwoWitnesses
            ),
          ],
        },
      },
    ],
  }

  return ny
}
