import React, { MouseEventHandler } from 'react'
import styled from 'styled-components'
// COMPONENTS
import userSVG from './user.svg'

const AccountIcon = styled.div`
  border-radius: 50%;
  background: ${(p) => p.theme.colors.neutral9};
  height: 48px;
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

type AccountAvatarProps = {
  onClick?: MouseEventHandler<HTMLDivElement>
  ['data-testid']?: string
}

export default function AccountAvatar(props: AccountAvatarProps) {
  const { onClick = () => {} } = props
  return (
    <AccountIcon onClick={onClick} data-testid={props['data-testid']} role="button">
      <img src={userSVG} alt="User Avatar" height="24" />
    </AccountIcon>
  )
}
