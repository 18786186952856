import s from 'components/common/PlanDoc/KodaContent/styles'
import { AdPartEnum, getPartByPartId } from 'components/common/PlanDoc/MasterPlanDoc/plan-settings'
import { PlanSetting, PlanSettingPart } from 'components/common/PlanDoc/MasterPlanDoc/plan-settings/types'
import Text from 'components/text/Text'
import styled from 'styled-components'


const Title = styled.h3``

const Requirements = ({ witnessSettings, onClick }: { witnessSettings: PlanSettingPart; onClick: () => void }) => {
  return (
    <div style={{ marginBottom: 16 }} id="signature-form-witness-requirements-text">
      <Title onClick={onClick}>Witness Requirements </Title>
      {witnessSettings.witnessRequirements?.requirements?.map((req) => {
        return (
          <div key={req.title}>
            <Text style={{...s.text, fontSize: 16, fontWeight: 'bold', fontFamily:'inherit'}}>{req.title}</Text>
            <ul>
              {req?.phrases?.map((phrase) => {
                return (
                  <li key={phrase}>
                    <Text>{phrase}</Text>
                  </li>
                )
              })}
            </ul>
          </div>
        )
      })}
    </div>
  )
}

export default function getReqsByState(settings: PlanSetting, setShowWitnessRequirements?: any): JSX.Element | null {
  const witnessSettings = settings?.parts && getPartByPartId(settings?.parts, AdPartEnum.sign_the_form)

  if (witnessSettings) {
    return <Requirements witnessSettings={witnessSettings} onClick={() => setShowWitnessRequirements(false)} />
  }

  return null
}
