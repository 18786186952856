import {
  Patient,
  PostAcpDigitalAdvanceCarePlanningEnum,
  PostAcpSystemUsabilityScale,
} from "generated/graphql";
import i18n from "./i18n";

const getPostSurveyQuestions = (patient: Patient) => {
  const POST_ACP_DIGITAL_ADVANCE_CARE_PLANNING_ENUM_TEXT_HASH = {
    [PostAcpDigitalAdvanceCarePlanningEnum.Yes]: i18n.t("yes", "Yes"),
    [PostAcpDigitalAdvanceCarePlanningEnum.No]: i18n.t("no", "No"),
  };

  const POST_ACP_SYSTEM_USABILITY_SCALE_TEXT_HASH = {
    [PostAcpSystemUsabilityScale.StronglyDisagree]: i18n.t(
      "angreeDisAgreeAnswers.stronglyDisagree",
      "Strongly Disagree"
    ),
    [PostAcpSystemUsabilityScale.Disagree]: i18n.t(
      "angreeDisAgreeAnswers.disagree",
      "Disagree"
    ),
    [PostAcpSystemUsabilityScale.Neither]: i18n.t(
      "angreeDisAgreeAnswers.neither",
      "Neither"
    ),
    [PostAcpSystemUsabilityScale.Agree]: i18n.t(
      "angreeDisAgreeAnswers.agree",
      "Agree"
    ),
    [PostAcpSystemUsabilityScale.StronglyAgree]: i18n.t(
      "angreeDisAgreeAnswers.stronglyAgree",
      "Strongly Agree"
    ),
  };

  const QUESTIONS = {
    step1: {
      title:
        patient?.guideTemplate?.name !== "WF_Path1"
          ? i18n.t(
              "survey.wouldYouBeInterestedInUsing",
              "Would you be interested in using a web-based application to do digital Advance Care Planning?"
            )
          : i18n.t(
              "survey.wouldYouBeInterestedInBeing",
              `Would you be interested in being contacted by a nurse navigator for a follow-up 
          telehealth Advance Care Planning interview?`
            ),
      questions: [
        {
          question: "",
          answer: POST_ACP_DIGITAL_ADVANCE_CARE_PLANNING_ENUM_TEXT_HASH,
          enum: POST_ACP_DIGITAL_ADVANCE_CARE_PLANNING_ENUM_TEXT_HASH,
          field: "postAcpDigitalAdvanceCarePlanning",
        },
      ],
    },
    step5: {
      title: i18n.t("survey.systemUsabilityScale"),
      questions: [
        {
          question: `14. ${i18n.t(
            "survey.iThinkThatIWouldLikeToUse",
            "I think that I would like to use this system frequently."
          )}`,
          description: i18n.t(
            "survey.markTheBoxBestDescribesYou",
            "Please mark the box that best describes you"
          ),
          answer: POST_ACP_SYSTEM_USABILITY_SCALE_TEXT_HASH,
          enum: POST_ACP_SYSTEM_USABILITY_SCALE_TEXT_HASH,
          field: "postAcpUseSystemFrequently",
        },
        {
          question: `15. ${i18n.t(
            "survey.iFoundTheSystemUnnecessarilyComplex",
            "I found the system unnecessarily complex."
          )}`,
          description: i18n.t(
            "survey.markTheBoxBestDescribesYou",
            "Please mark the box that best describes you"
          ),
          answer: POST_ACP_SYSTEM_USABILITY_SCALE_TEXT_HASH,
          enum: POST_ACP_SYSTEM_USABILITY_SCALE_TEXT_HASH,
          field: "postAcpSystemUnnecessarilyComplex",
        },
        {
          question: `16. ${i18n.t(
            "survey.iThoughtTheSystemWasEasyToUse",
            "I thought the system was easy to use."
          )}`,
          description: i18n.t(
            "survey.markTheBoxBestDescribesYou",
            "Please mark the box that best describes you"
          ),
          answer: POST_ACP_SYSTEM_USABILITY_SCALE_TEXT_HASH,
          enum: POST_ACP_SYSTEM_USABILITY_SCALE_TEXT_HASH,
          field: "postAcpSystemWasEasyUse",
        },
        {
          question: `17. ${i18n.t(
            "survey.iThinkThatIWouldNeedTheSupport",
            `I think that I would need the support of a technical person to be able to use this 
            system.`
          )}`,
          description: i18n.t(
            "survey.markTheBoxBestDescribesYou",
            "Please mark the box that best describes you"
          ),
          answer: POST_ACP_SYSTEM_USABILITY_SCALE_TEXT_HASH,
          enum: POST_ACP_SYSTEM_USABILITY_SCALE_TEXT_HASH,
          field: "postAcpSupportTechnicalPerson",
        },
        {
          question: `18. ${i18n.t(
            "survey.iFoundTheVariousFunctionsInThis",
            "I found the various functions in this system were well integrated."
          )}`,
          description: i18n.t(
            "survey.markTheBoxBestDescribesYou",
            "Please mark the box that best describes you"
          ),
          answer: POST_ACP_SYSTEM_USABILITY_SCALE_TEXT_HASH,
          enum: POST_ACP_SYSTEM_USABILITY_SCALE_TEXT_HASH,
          field: "postAcpVariousFunctionsWellIntegrated",
        },
        {
          question: `19. ${i18n.t(
            "survey.iThoughtThereWasTooMuchInconsistency",
            "I thought there was too much inconsistency in this system."
          )}`,
          description: i18n.t(
            "survey.markTheBoxBestDescribesYou",
            "Please mark the box that best describes you"
          ),
          answer: POST_ACP_SYSTEM_USABILITY_SCALE_TEXT_HASH,
          enum: POST_ACP_SYSTEM_USABILITY_SCALE_TEXT_HASH,
          field: "postAcpTooMuchInconsistency",
        },
        {
          question: `20. ${i18n.t(
            "survey.iWouldImagineThatMostPeople",
            "I would imagine that most people would learn to use this system very quickly."
          )}`,
          description: i18n.t(
            "survey.markTheBoxBestDescribesYou",
            "Please mark the box that best describes you"
          ),
          answer: POST_ACP_SYSTEM_USABILITY_SCALE_TEXT_HASH,
          enum: POST_ACP_SYSTEM_USABILITY_SCALE_TEXT_HASH,
          field: "postAcpMostPeopleWouldLearn",
        },
        {
          question: `21. ${i18n.t(
            "survey.iFoundTheSystemVeryCumbersomeToUse",
            "I found the system very cumbersome to use."
          )}`,
          description: i18n.t(
            "survey.markTheBoxBestDescribesYou",
            "Please mark the box that best describes you"
          ),
          answer: POST_ACP_SYSTEM_USABILITY_SCALE_TEXT_HASH,
          enum: POST_ACP_SYSTEM_USABILITY_SCALE_TEXT_HASH,
          field: "postAcpSystemVeryCumbersomeToUse",
        },
        {
          question: `22. ${i18n.t(
            "survey.iFeltVeryConfidentUsingTheSystem",
            "I felt very confident using the system."
          )}`,
          description: i18n.t(
            "survey.markTheBoxBestDescribesYou",
            "Please mark the box that best describes you"
          ),
          answer: POST_ACP_SYSTEM_USABILITY_SCALE_TEXT_HASH,
          enum: POST_ACP_SYSTEM_USABILITY_SCALE_TEXT_HASH,
          field: "postAcpConfidentUsingTheSystem",
        },
        {
          question: `23. ${i18n.t(
            "survey.iNeededToLearnALotOfThings",
            `I needed to learn a lot of things before
            I could get going with this system.`
          )}`,
          description: "Please mark the box that best describes you.",
          answer: POST_ACP_SYSTEM_USABILITY_SCALE_TEXT_HASH,
          enum: POST_ACP_SYSTEM_USABILITY_SCALE_TEXT_HASH,
          field: "postAcpLearnLotPfThingsBefore",
        },
      ],
    },
  };

  return QUESTIONS;
};

export default getPostSurveyQuestions;
