import constants from 'lib/config'
import styled from 'styled-components'
import Animated from 'components/common/Animated'
// LIB
import logoSVG from 'lib/media/logo-light-bg.svg'
import { Trans } from 'react-i18next'

const Title = styled.h1`
  font-size: 24px;
`

const Container = styled.div`
  width: 700px;
  max-width: 100%;
  text-align: center;
`

export default function NoClinicExists() {
  return (
    <Container>
      <Animated>
        <img
          src={logoSVG}
          alt="Koda Health"
          height="150"
          style={{ margin: 'auto', marginBottom: 24, display: 'block' }}
        />{' '}
        <Title>
          <Trans i18nKey={'NoClinicExists.title'}>Page not found. </Trans>
        </Title>
        <Trans i18nKey={'NoClinicExists.subtitle'}>
          If you were given a clinic link please contact {{ supportEmail: constants.supportEmail }} for assistance.
        </Trans>
      </Animated>
    </Container>
  )
}
