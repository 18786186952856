import { useState, useCallback, useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import queryString from 'query-string'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'

import { ActivityLogKeyEnum, useGetOnePatientQuery, UserProfile } from 'generated/graphql'

import Button from 'components/common/Button'
import ElephantChat from 'components/common/ElephantChat'
import Collapse from 'components/common/Collapse'
import Loading from 'components/common/Loading'
import ActionDrawer from 'routes/app-home/ActionDrawer'
import PlanConfiguratorHelper from 'routes/app-home/PlanConfigurator/PlanConfiguratorHelper'

import useSavePatient from 'lib/hooks/useSavePatient'
import useLogActivityItemMutation from 'lib/hooks/useLogActivityItemMutation'
import { StyledTheme } from 'lib/theme/theme'

const { Panel } = Collapse

const AppAcpShedulingStyled = styled.div`
  padding-top: 55px;
  background-color: #f5f7fa;
  min-height: calc(100vh - 73px);
  padding-bottom: 60px;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.mediumSmall}) {
    padding: 24px;
  }
`

const AppAcpShedulingContainer = styled.div`
  max-width: 650px;
  margin: 0 auto 0 auto;
`

const AppAcpShedulingBody = styled.div`
  margin-top: 50px;
  padding: 24px 16px;
  background-color: white;
  padding-bottom: 50px;
  .ant-collapse {
    .ant-collapse-item {
      .ant-collapse-header {
        background-color: #f5f7fa;
        padding-left: 9px;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        color: #000000;
      }
    }
  }
  .ant-collapse-content {
    .ant-collapse-content-box {
      padding: 19px 9px 22px 9px;
      font-size: 16px;
      line-height: 19px;
      color: #000000;
    }
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.mediumSmall}) {
    padding: 24px 8px;
  }
`

export const AppAcpShedulingBodyTitle = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  margin-bottom: 16px;
  color: ${(p) => p.theme.colors.neutral1};
`

export const AppAcpShedulingBodyDescription = styled.div`
  margin-bottom: 24px;
  font-size: 16px;
  line-height: 1.5;
  color: ${(p) => p.theme.colors.neutral4};
  p {
    margin-bottom: 8px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`

export const AppAcpShedulingBodySubTitle = styled.div`
  margin-bottom: 16px;
  font-size: 16px;
  line-height: 1.5;
  color: ${(p) => p.theme.colors.neutral4};
`

export const AppAcpShedulingBodyOptions = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 15px;
  @media only screen and (max-width: ${({ theme }: { theme: StyledTheme }) => theme.breakpoints.mediumSmall}) {
    grid-template-columns: 1fr;
  }
`

export const AppAcpShedulingBodyOption = styled.div`
  border: 1px solid #cbd2d9;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 24px 20px;
  min-height: 222px;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.mediumSmall}) {
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`

export const AppAcpShedulingBodyOptionTitle = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: ${(p) => p.theme.colors.neutral1};
`

export const AppAcpShedulingBodyOptionDescription = styled.div`
  font-size: 16px;
  line-height: 19px;
  margin-top: 16px;
  color: ${(p) => p.theme.colors.neutral4};
  a {
    color: #01337d;
  }
`

export const AppAcpShedulingBodyOptionButton = styled.button`
  width: 185px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #01337d;
  border-radius: 4px;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: white;
  margin-top: 24px;
  border: none;
  cursor: pointer;
`

export const AppAcpShedulingBodyNote = styled.div`
  margin: 24px 0;
  line-height: 1.5;
  color: ${(p) => p.theme.colors.neutral4};
`

export const AppAcpShedulingBodyCollapseTitle = styled.div`
  margin-bottom: 16px;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: ${(p) => p.theme.colors.neutral1};
`

const AppAcpShedulingBodyContinueBtn = styled.div`
  margin-top: 20px;
`

const PanelTitle = styled.div`
  color: ${(p) => p.theme.colors.neutral1};
`

const PanelDescription = styled.div`
  color: ${(p) => p.theme.colors.neutral4};
`

interface AppAcpShedulingProps {
  location: {
    search: string
  }
  currentUser: UserProfile
}
const AppAcpSheduling = (props: AppAcpShedulingProps) => {
  const { t } = useTranslation()
  const [logActivityItem] = useLogActivityItemMutation()

  const { mode } = queryString.parse(props.location && props.location.search)
  const [expanded, setExpanded] = useState(false)

  const history = useHistory()

  const { data, loading, error } = useGetOnePatientQuery({
    variables: { userId: props?.currentUser.id },
  })

  const { onSavePatient, saving } = useSavePatient()

  const patient = data?.getOnePatient

  const logScheduleInterview = useCallback(() => {
    logActivityItem({
      variables: {
        key: ActivityLogKeyEnum.ClickedScheduleInterview,
        userId: props?.currentUser?.id || '',
        patientId: patient?.id || '',
        content: `Patient clicked "Schedule interview" button`,
      },
    })
  }, [logActivityItem, patient?.id, props?.currentUser?.id])

  useEffect(() => {
    if (patient?.id && props.currentUser?.id) {
      logActivityItem({
        variables: {
          key: ActivityLogKeyEnum.ViewedAcpSchedulingPage,
          userId: props?.currentUser?.id || '',
          patientId: patient?.id || '',
          content: `Patient looked at ACP scheduling page`,
        },
      })
    }
    // eslint-disable-next-line
  }, [patient?.id, props?.currentUser?.id])

  if (loading) {
    return <Loading />
  }

  if (error || !patient) {
    return <div>Error...</div>
  }

  if (!patient?.guideTemplate?.sections) return <div>No Plan Found</div>

  const planHelpers = new PlanConfiguratorHelper()

  const save = async () => {
    if (patient) {
      await onSavePatient({
        ...patient,
        schedulingACPinformation: 'done',
      })
      history.push('/app/home')
    }
  }

  const guideComplete = planHelpers.getguideComplete({
    patient,
    modules: patient?.guideTemplate?.sections,
  })

  return (
    <AppAcpShedulingStyled>
      <ActionDrawer
        setExpanded={setExpanded}
        expanded={expanded}
        mode={mode as string | string[]}
        patient={patient}
        guideComplete={guideComplete}
      />
      <AppAcpShedulingContainer>
        <ElephantChat
          text={t('acpScheduling.weWouldLikeToScheduleTelehealth')}
          bubleStyle={{
            filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
          }}
        />
        <AppAcpShedulingBody>
          <AppAcpShedulingBodyTitle>{t('acpScheduling.whatIsACPInterview')}</AppAcpShedulingBodyTitle>
          <AppAcpShedulingBodyDescription>
            <p>{t('acpScheduling.kodaHealthResearcher')}</p>
            <p>{t('acpScheduling.thisInterviewTypicallyTakes')}</p>
          </AppAcpShedulingBodyDescription>
          <AppAcpShedulingBodyTitle>{t('acpScheduling.howDoIScheduleInterview')}</AppAcpShedulingBodyTitle>
          <AppAcpShedulingBodySubTitle>{t('acpScheduling.thereAreTwoWays')}</AppAcpShedulingBodySubTitle>
          <AppAcpShedulingBodyOptions>
            <AppAcpShedulingBodyOption>
              <AppAcpShedulingBodyOptionTitle>{t('acpScheduling.option1')}</AppAcpShedulingBodyOptionTitle>
              <AppAcpShedulingBodyOptionDescription>
                {t('acpScheduling.youCanScheduleTimeByClicking')}
              </AppAcpShedulingBodyOptionDescription>
              <a
                onClick={logScheduleInterview}
                href="https://calendly.com/samanthasanky"
                target="_blank"
                rel="noreferrer"
              >
                <AppAcpShedulingBodyOptionButton>
                  {t('acpScheduling.scheduleInterview')}
                </AppAcpShedulingBodyOptionButton>
              </a>
            </AppAcpShedulingBodyOption>
            <AppAcpShedulingBodyOption>
              <AppAcpShedulingBodyOptionTitle>{t('acpScheduling.option2')}</AppAcpShedulingBodyOptionTitle>
              <AppAcpShedulingBodyOptionDescription>
                {t('acpScheduling.callAndrewBrownResearchMember')}
                <a href="tel:336-716-0040">{` 336-716-0040 `}</a>
                {t('acpScheduling.toScheduleITheInterview')}
              </AppAcpShedulingBodyOptionDescription>
            </AppAcpShedulingBodyOption>
          </AppAcpShedulingBodyOptions>
          <AppAcpShedulingBodyNote>
            <Trans>{t('acpScheduling.noteCourtesyCallByWakeForest')}</Trans>
          </AppAcpShedulingBodyNote>
          <AppAcpShedulingBodyCollapseTitle>
            {t('acpScheduling.frequentlyAskedQuestions')}
          </AppAcpShedulingBodyCollapseTitle>
          <Collapse expandIconPosition="right" onChange={(keys) => console.log(keys)}>
            <Panel
              header={<PanelTitle>{t('acpScheduling.willThisBePartOfMedicalChart')}</PanelTitle>}
              key="medical-chart"
            >
              <PanelDescription>{t('acpScheduling.theInformationYouShareWill')}</PanelDescription>
            </Panel>
            <Panel
              header={<PanelTitle>{t('acpScheduling.whatHappensAfterISchedule')}</PanelTitle>}
              key="phone-interview"
            >
              <PanelDescription>{t('acpScheduling.afterTheAppointmentIsScheduled')}</PanelDescription>
            </Panel>
          </Collapse>
          <AppAcpShedulingBodyContinueBtn>
            <Button loading={saving} disabled={saving} onClick={save} pulse>
              {t('button.continue')}
            </Button>
          </AppAcpShedulingBodyContinueBtn>
        </AppAcpShedulingBody>
      </AppAcpShedulingContainer>
    </AppAcpShedulingStyled>
  )
}

export default AppAcpSheduling
