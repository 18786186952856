// TOP LEVEL IMPORTS
import { Suspense, useEffect } from 'react'
import { Route, Redirect } from 'react-router-dom'
import Loading from 'components/common/Loading'
import { RoleTypeEnum, UserProfile } from 'generated/graphql'
import * as FullStory from '@fullstory/browser'
import config from 'lib/config'

interface AdminRouteProps {
  currentUser?: UserProfile
  path?: string
  noNav?: boolean
  noFooter?: boolean
  location?: string
  isAllowed?: boolean
  component?: any
  layout?: any
  exact?: boolean
}

const AdminRoute = (props: AdminRouteProps) => {
  const {
    currentUser,
    path,
    noNav,
    noFooter,
    location,
    isAllowed,
    component: Component,
    layout: Layout,
    ...rest
  } = props

  useEffect(() => {
    FullStory.init(
      {
        orgId: config.FULLSTORY_ORD_ID,
        debug: config.NODE_ENV !== 'production',
      },
      () => FullStory?.shutdown() // make sure no fullstory recordings happen on any clinic user route)
    )
  })

  if (!currentUser) {
    return <Redirect to="/" />
  }

  if (currentUser && currentUser.roles && !currentUser.roles.includes(RoleTypeEnum.SuperAdmin)) {
    return <Redirect to="/" />
  }

  return (
    <Route
      {...rest}
      path={path}
      render={(args) => (
        <>
          {currentUser && currentUser.id ? (
            <Layout {...args} {...props}>
              <Suspense fallback={<Loading />}>
                <Component {...args} {...props} />
              </Suspense>
            </Layout>
          ) : (
            <Redirect to="/" />
          )}
        </>
      )}
    />
  )
}

export default AdminRoute
