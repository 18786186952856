import { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { RouteComponentProps } from 'react-router-dom'
import { validate } from 'email-validator'
// COMPONENS
import Button from 'components/common/Button'
import TextInput from 'components/inputs/TextInput'
import BirthdayInput from 'components/inputs/BirthdayInput'
import StatesInput from 'components/inputs/StateInput'
import FormItem from 'components/common/FormItem'
import Row from 'components/common/Row'
import PasswordInput from 'components/inputs/PasswordInput'
import Col from 'components/common/Col'
import ErrorBlock from 'components/common/ErrorBlock'
// HOOKS
import useCheckPasswordErrors from 'lib/hooks/useCheckPasswordErrors'
import useCheckBirthdayErrors from 'lib/hooks/useCheckBirthdayErrors'
// LIB
import logo from 'lib/media/logo-light-bg-horizontal.svg'
import { AnalyticEventTypeEnums } from 'lib/types/AnalyticEventTypeEnums'
import constants from 'lib/config'
import i18n from 'lib/helpers/i18n'
import en from 'lib/helpers/locales/en/translation.json'
import { Clinic } from 'generated/graphql'
import logEvent from 'lib/helpers/logEvent'

import {
  BottomLink,
  BottomText,
  ButtonContainer,
  Caption,
  Checkbox,
  BottomBtn,
  HintLink,
  Logo,
  Title,
} from './styledSignUp/signupForm.Styled'

// const HideButton = styled.button`
//   border: 0px;
//   background: transparent;
//   position: absolute;
//   right: 16px;
//   top: 36px;
//   color: ${(p) => p.theme.colors.supportB2};
//   cursor: pointer;
//   &:focus {
//     outline: 0;
//   }
// `;

export const UploadingOverlay = styled.div`
  z-index: 1000;
  background: rgba(0, 0, 0, 0.03);
  position: absolute;
  border-radius: 5px;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

interface SignupFormProps {
  onChange: (e: { [x: string]: string | number }) => void
  user: any
  signupError: string
  onBack: () => void
  loading: boolean
  onSubmit: () => void
  onChangeForm: (v: string) => void
  clinic?: Clinic
  match: RouteComponentProps<{ slug?: string }>
}

export default function SignupForm({
  onChange,
  user,
  signupError,
  onBack,
  loading,
  onSubmit,
  onChangeForm,
  clinic,
  match,
}: SignupFormProps) {
  const [errors, setErrors] = useState<{
    email?: string
    password?: string
    confirmPassword?: string
    stateId?: string
    firstName?: string
    lastName?: string
    birthday?: string
    agreeToTerms?: string
  }>({
    email: undefined,
    password: undefined,
    confirmPassword: undefined,
    stateId: undefined,
    firstName: undefined,
    lastName: undefined,
    birthday: undefined,
    agreeToTerms: undefined,
  })
  const [showPassword, setShowPassword] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false)
  const firstNameInput = useRef<HTMLInputElement | undefined>()
  const { checkPasswordErrors, passwordSchema } = useCheckPasswordErrors()
  const [checkBirthdayErrors] = useCheckBirthdayErrors()
  // always scroll to the top
  useEffect(() => {
    window.scrollTo(0, 0)
    firstNameInput?.current?.focus()
  }, [])
  const onBlurInput = () => {
    if (!isSubmitted) return

    if (!user.firstName) {
      return setErrors({ firstName: i18n.t('signupPage.provideFirstName') })
    } else if (!user.lastName) {
      return setErrors({ lastName: i18n.t('signupPage.provideLastName', en.signupPage.provideLastName) })
    } else if (!user.email) {
      return setErrors({ email: i18n.t('signupPage.provideEmail', en.signupPage.provideEmail) })
    } else if (user.email && !validate(user.email)) {
      return setErrors({ email: i18n.t('signupPage.provideValidEmail', en.signupPage.provideValidEmail) })
    } else if (!user.password) {
      return setErrors({ password: i18n.t('signupPage.confirmPassword', 'Please confirm your password.') })
    } else if (!passwordSchema.validate(user.password)) {
      return setErrors({
        password: i18n.t('signupPage.passwordSchema'),
      })
    }

    const birthdayError = checkBirthdayErrors(user.birthday)
    if (birthdayError) return setErrors({ birthday: birthdayError })

    if (!user.confirmPassword) {
      return setErrors({ confirmPassword: i18n.t('signupPage.confirmPassword', 'Please confirm your password.') })
    } else if (user.password !== user.confirmPassword) {
      return setErrors({ confirmPassword: i18n.t('signupPage.passwordsNotMatch') })
    } else if (!user.stateId) {
      return setErrors({ stateId: i18n.t('signupPage.selectState') })
    } else if (!user.agreeToTerms) {
      return setErrors({
        agreeToTerms: i18n.t('signupPage.acceptTermsService'),
      })
    }
  }

  const onPasswordChange = (password) => {
    onChange({ password })
    setErrors({})
    const error = checkPasswordErrors(password)
    if (error) {
      setErrors({ password: error })
    } else {
      setErrors({ password: undefined })
    }
  }

  const onBeforeSubmit = (e) => {
    e.preventDefault()
    setIsSubmitted(true)

    // do a bunch of validation
    // check if they're 18

    if (!user.firstName) {
      return setErrors({ firstName: i18n.t('signupPage.provideFirstName') })
    }
    if (!user.lastName) {
      return setErrors({ lastName: i18n.t('signupPage.provideLastName') })
    }
    if (!user.email) {
      return setErrors({ email: i18n.t('signupPage.provideEmail') })
    }
    if (user.email && !validate(user.email)) {
      return setErrors({ email: i18n.t('signupPage.provideValidEmail') })
    }
    if (!user.password) {
      return setErrors({ password: i18n.t('signupPage.confirmPassword', 'Please confirm your password.') })
    }
    if (!passwordSchema.validate(user.password)) {
      return setErrors({
        password: i18n.t('signupPage.passwordSchema'),
      })
    }
    if (!user.confirmPassword) {
      return setErrors({ confirmPassword: i18n.t('signupPage.confirmPassword', 'Please confirm your password.') })
    }
    if (user.password !== user.confirmPassword) {
      return setErrors({ confirmPassword: i18n.t('signupPage.passwordsNotMatch') })
    }

    const birthdayError = checkBirthdayErrors(user.birthday)
    if (birthdayError) return setErrors({ birthday: birthdayError })

    if (!user.stateId) {
      return setErrors({ stateId: i18n.t('signupPage.selectState') })
    }
    if (!user.agreeToTerms) {
      return setErrors({
        agreeToTerms: i18n.t('signupPage.acceptTermsService'),
      })
    }

    if (!user.stateId) {
      return
    }

    logEvent(AnalyticEventTypeEnums.SIGNUP_CLICKED, {
      clinicId: clinic?.id,
    })
    // if everything passes, then submit
    return onSubmit()
  }

  // can clear out errors after something changes
  const onBeforeChange = (newValues) => {
    setErrors({})
    onChange(newValues)
  }

  const handleUseAsGuest = () => {
    // we want to reset the email/password to null before going to the guest form, just in case it the email was autofilled by the browsers autocomplete functionatiliy
    onBeforeChange({
      email: null,
      password: null,
    })
    onChangeForm('guest')
  }

  return (
    <div style={{ width: '600px', maxWidth: '100%', padding: '24px' }}>
      <Logo src={clinic?.logo || logo} alt="Koda Health" />
      <Title>{i18n.t('signupPage.title')}</Title>
      <Caption>{i18n.t('signupPage.caption')}</Caption>
      <form onSubmit={onBeforeSubmit} style={{ marginBottom: 16 }}>
        <Row gutter={16}>
          <Col xs={24} sm={12}>
            <FormItem label={i18n.t('signupPage.legalFirstName')} required error={errors.firstName} htmlFor="firstName">
              <TextInput
                id="firstName"
                onChange={(e) => onBeforeChange({ firstName: e.target.value })}
                data-testid="signup-firstname"
                value={user.firstName}
                ref={firstNameInput}
              />
            </FormItem>
          </Col>
          <Col xs={24} sm={12}>
            <FormItem label={i18n.t('signupPage.legalLastName')} required error={errors.lastName} htmlFor="lastName">
              <TextInput
                onChange={(e) => onBeforeChange({ lastName: e.target.value })}
                data-testid="signup-lastname"
                id="lastName"
                value={user.lastName}
                className="fs-exclude"
              />
            </FormItem>
          </Col>
          <Col xs={24}>
            <FormItem
              label={i18n.t('signupPage.email')}
              required
              htmlFor="email"
              error={errors.email}
              tooltip={
                <>
                  {i18n.t('signupPage.dontHaveEmail')}
                  <HintLink onClick={handleUseAsGuest}>{i18n.t('signupPage.signinAsGuest')}</HintLink>.
                </>
              }
            >
              <TextInput
                type="email"
                id="email"
                className="fs-exclude"
                inputMode="email"
                data-testid="signup-email"
                onChange={(e) => onBeforeChange({ email: e.target.value.toLowerCase() })}
                onBlur={() => onBlurInput()}
                value={user.email}
              />
            </FormItem>
            <FormItem
              label={i18n.t('signupPage.password')}
              required
              error={errors.password}
              hint={i18n.t('signupPage.passwordHint')}
              htmlFor="password"
            >
              <PasswordInput
                id="password"
                value={user.password}
                onChange={(password) => onPasswordChange(password)}
                onBlur={() => onBlurInput()}
                type={showPassword ? 'text' : 'password'}
                onToggleShowPassword={() => setShowPassword(!showPassword)}
                showPassword={showPassword}
                placeholder=""
                data-testid="signup-password"
              />
            </FormItem>
            <FormItem
              label={i18n.t('signupPage.reenterPassword')}
              required
              error={errors.confirmPassword}
              htmlFor="confirmPassword"
            >
              <PasswordInput
                id="confirmPassword"
                value={user.confirmPassword}
                onChange={(confirmPassword) => onBeforeChange({ confirmPassword })}
                onBlur={() => {
                  onBlurInput()
                  if (user?.confirmPassword !== user.password) {
                    return setErrors({
                      confirmPassword: i18n.t('signupPage.passwordsNotMatch'),
                    })
                  }
                }}
                placeholder=""
                onToggleShowPassword={() => setShowPassword(!showPassword)}
                type={showPassword ? 'text' : 'password'}
                showPassword={showPassword}
                data-testid="signup-password-confirm"
              />
            </FormItem>{' '}
          </Col>
          <Col xs={24} sm={14} className="fs-exclude">
            <FormItem
              label={i18n.t('signupPage.birthday')}
              htmlFor="birthday"
              required
              error={errors.birthday}
              tooltip={i18n.t('signupPage.birthdayTooltip')}
            >
              <BirthdayInput onChange={(birthday) => onBeforeChange({ birthday })} id="birthday" />
            </FormItem>
          </Col>
          <Col xs={24} sm={10}>
            <FormItem
              label={i18n.t('signupPage.state')}
              htmlFor="state"
              required
              error={errors.stateId}
              tooltip={i18n.t('signupPage.stateTooltip')}
            >
              <StatesInput
                onChange={(stateId) => onBeforeChange({ stateId })}
                onBlur={() => onBlurInput()}
                value={user.stateId}
                id="state"
                className="fs-exclude"
              />
            </FormItem>
          </Col>
        </Row>
        <FormItem error={errors.agreeToTerms}>
          <div style={{ display: 'flex' }}>
            <div style={{ marginRight: 8, paddingTop: 8 }}>
              <Checkbox
                type="checkbox"
                data-testid="signup-checkbox"
                onChange={(e) => onBeforeChange({ agreeToTerms: e.target.checked })}
                onBlur={() => onBlurInput()}
                id="agreeText"
              />
            </div>
            <div>
              <BottomText htmlFor="agreeText">
                {i18n.t('signupPage.agreeText')}{' '}
                <BottomLink to="/terms?showBackBtn=true">{i18n.t('signupPage.terms')}</BottomLink> and{' '}
                <BottomLink to="/privacy?showBackBtn=true">{i18n.t('signupPage.privacyPolicy')}</BottomLink>.
              </BottomText>
            </div>
          </div>
        </FormItem>
        <ButtonContainer>
          <Button disabled={loading} grey onClick={onBack} style={{ width: 165, marginRight: 16 }}>
            {i18n.t('button.back')}
          </Button>
          <Button
            disabled={loading}
            loading={loading}
            type="submit"
            data-testid="signup-submit"
            onClick={onBeforeSubmit}
            style={{ width: 165 }}
          >
            {i18n.t('signupPage.signUp')}
          </Button>
        </ButtonContainer>
        {signupError && <ErrorBlock error={signupError} />}
      </form>
      <div style={{ marginTop: 32 }}>
        <BottomText>
          {i18n.t('signupPage.alreadyHaveAccount')}{' '}
          <BottomLink to="/login">{i18n.t('signupPage.signinHere')}</BottomLink>
        </BottomText>
        {!['wfpath1', 'wfpath2'].includes(match?.params?.slug) && (
          <BottomText>
            {i18n.t('signupPage.dontCreateAccount')}{' '}
            <BottomBtn onClick={handleUseAsGuest}>{i18n.t('signupPage.useGuest')}</BottomBtn>
          </BottomText>
        )}
        <BottomText>
          {i18n.t('signupPage.needHelp')}{' '}
          <a style={{ textDecoration: 'underline' }} href={`tel:+${constants.supportPhone.replace(/-/g, '')}`}>
            {constants.supportPhone}
          </a>
        </BottomText>
      </div>
    </div>
  )
}
