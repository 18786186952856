import React from 'react'
import styled from 'styled-components'
import { UserProfile } from 'generated/graphql'
import Header from './Header'

// STYLE-COMPONENTS
// ===================================
const Container = styled.main`
  min-height: 100%;
  max-width: 100%;
  /* background-color: ${(p) => p.theme.colors.neutral10} !important; */
`

// EXPORT
// ===================================
interface AppLayoutProps {
  children?: string | JSX.Element
  currentUser: UserProfile
}

export default function AppLayout({ children, currentUser }: AppLayoutProps) {
  return (
    <Container id="authenticated-layout">
      <>
        <Header currentUser={currentUser} />
        <div style={{ height: '100%' }}>{children}</div>
      </>
    </Container>
  )
}
