import styled from 'styled-components'
import { Link } from 'react-router-dom'
// COMPONENTS
import Row from 'components/common/Row'
import Col from 'components/common/Col'
// APOLLO

import { UserProfile } from 'generated/graphql'
// LIB
import logo from 'lib/media/logo-light-bg-horizontal.svg'
import useSignOut from 'lib/hooks/useSignout'

const HeaderContainer = styled.div`
  background: #fff;
  font-family: ${(p) => p.theme.fontFamily};
  border-bottom: 1px solid ${(p) => p.theme.colors.neutral9};
`

const Username = styled.div`
  color: #000;
  font-family: ${(p) => p.theme.fontFamily};
  font-weight: 400;
  margin-left: auto;
`

const SignoutBtn = styled.button`
  border: 0px;
  background: transparent;
  color: ${(p) => p.theme.colors.support1};
  margin: 0px;
  padding: 0px;
  font-size: 16px;
  margin-left: 8px;
  font-family: ${(p) => p.theme.fontFamily};
  text-decoration: underline;
  cursor: pointer;
  font-weight: 400;
`

interface HeaderProps {
  currentUser: UserProfile
}

const Header = ({ currentUser }: HeaderProps) => {
  const [signOut] = useSignOut()
  const onLogout = async () => {
    await signOut(currentUser)
  }

  return (
    <HeaderContainer>
      <Row
        style={{
          height: 56,
          margin: 'auto',
          width: 1150,
          maxWidth: '90%',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {' '}
        <Col xs={6}>
          <Link to="/clinic/home">
            <img
              src={logo}
              height="32"
              alt="Koda Health-home"
              style={{ marginBottom: 0, position: 'relative', left: -24 }}
            />
          </Link>
        </Col>
        <Col xs={9}></Col>
        <Col xs={9} style={{ textAlign: 'right' }}>
          <Username>
            logged-in as <strong>{currentUser && currentUser.email}</strong>{' '}
            <SignoutBtn onClick={onLogout}>sign-out</SignoutBtn>
          </Username>
        </Col>
      </Row>{' '}
    </HeaderContainer>
  )
}

export default Header
