import { useState } from 'react'
import Collapse from 'components/common/Collapse'
import styled from 'styled-components'
import ButtonsContainer from '../ButtonsContainer'
import QuestionText from 'components/text/QuestionText'
import ElephantChat from 'components/common/ElephantChat'
// LIB
import { AnalyticEventTypeEnums } from 'lib/types/AnalyticEventTypeEnums'
import useLogActivityItemMutation from 'lib/hooks/useLogActivityItemMutation'
import { ActivityLogKeyEnum, Patient, UserProfile } from 'generated/graphql'
import logEvent from 'lib/helpers/logEvent'

const { Panel } = Collapse

const Container = styled.div`
  width: 600px;
  max-width: 100%;
  margin: auto;
  padding-bottom: 140px;
`

const Wrapper = styled.div`
  padding-bottom: 24px;
`

interface ExplainerExpanderProps {
  title?: string
  text?: string | JSX.Element
  explainerValues?: any
  onNext?: (plan?: Patient) => void
  onBack?: () => void
  buttonAlign?: string
  nextButton?: string
  stepName?: string
  currentUser?: UserProfile
  plan?: Patient
}

export default function ExplainerExpander({
  title,
  text,
  explainerValues,
  onNext,
  onBack,
  buttonAlign,
  nextButton,
  stepName,
  currentUser,
  plan,
}: ExplainerExpanderProps) {
  const [activeKeys, setActiveKeys] = useState<any[]>([]) // will hold an array of the "keys" (ie the question titles) that are currently open
  const [activeSubKeys, setActiveSubKeys] = useState<any[]>([]) // will hold an array of the "keys" (ie the sub question titles) that are currently open
  const [logActivityItem] = useLogActivityItemMutation()

  const logClick = (item) => {
    // if this question has not already been expanded, then we'll log that somebody opened it
    if (!activeKeys.includes(item?.question)) {
      if (item.key === ActivityLogKeyEnum.PatientClickedAgeSpecificData) {
        logActivityItem({
          variables: {
            key: ActivityLogKeyEnum.PatientClickedAgeSpecificData,
            userId: currentUser?.id || '',
            patientId: (plan && plan.id) || '',
            content: `User clicked age specific data on CPR Age Specific Page`,
          },
        })
      } else if (item.key === ActivityLogKeyEnum.PatientClickedOtherAgeRangesData) {
        logActivityItem({
          variables: {
            key: ActivityLogKeyEnum.PatientClickedOtherAgeRangesData,
            userId: currentUser?.id || '',
            patientId: (plan && plan.id) || '',
            content: `User clicked other age ranges data on CPR Age Specific Page`,
          },
        })
      } else {
        logEvent(AnalyticEventTypeEnums.CLICK_EXPLAINER_SECTION, {
          question: item.question,
          stepName,
        })
      }
    }
  }

  const logSubQClick = (subQ) => {
    // if this subQ has not already been expanded, then we'll log that somebody opened it
    if (!activeSubKeys.includes(subQ?.question)) {
      logEvent(AnalyticEventTypeEnums.CLICK_EXPLAINER_SECTION, {
        question: subQ.question,
        stepName,
      })
    }
  }

  return (
    <Container>
      <QuestionText>{title}</QuestionText>
      <ElephantChat text={text} style={{ marginBottom: 24 }} />

      <Wrapper>
        <Collapse expandIconPosition="right" onChange={(keys) => setActiveKeys(keys as string[])}>
          {explainerValues.map((item, index) => {
            if (item.subQuestions) {
              return (
                <Panel header={item.question} key={item.question + index}>
                  <Collapse expandIconPosition="right" onChange={(keys) => setActiveSubKeys(keys as string[])}>
                    {item.subQuestions.map((subQ) => (
                      <Panel
                        header={<div onClick={() => logSubQClick(subQ)}>{subQ.question}</div>}
                        key={subQ.key || subQ.question}
                      >
                        <div>{subQ.answer}</div>
                      </Panel>
                    ))}
                  </Collapse>
                </Panel>
              )
            }
            return (
              <Panel header={<div onClick={() => logClick(item)}>{item.question}</div>} key={item.key || item.question}>
                <div>{item.answer}</div>
              </Panel>
            )
          })}
        </Collapse>
      </Wrapper>
      <ButtonsContainer onNext={onNext} onBack={onBack} buttonAlign={buttonAlign} nextButton={nextButton} />
    </Container>
  )
}
