import s from '../styles'
import { View, Text } from '@react-pdf/renderer'
// LIB
import theme from 'lib/theme'

interface AnswerLineProps {
  style?: any
  text?: string
  numberOfLines?: number
}

export default function AnswerLine({ style = {}, text, numberOfLines }: AnswerLineProps) {
  if (text) {
    return (
      <View style={{ position: 'relative' }}>
        <Text
          style={{
            ...s.text,
            color: theme.colors.neutral1,
            lineHeight: 2.1,
            position: 'absolute',
            top: 0,
            zIndex: 2,
          }}
        >
          {text}
        </Text>
        {Array.from(Array(numberOfLines || 2).keys())?.map((line, i) => {
          return (
            <View
              key={line}
              style={{
                height: 1,
                backgroundColor: theme.colors.neutral7,
                marginTop: 24,
                ...style,
              }}
            />
          )
        })}
      </View>
    )
  }
  if (numberOfLines) {
    return (
      <>
        {Array.from(Array(numberOfLines).keys())?.map((line) => {
          return (
            <View
              key={line}
              style={{
                height: 1,
                backgroundColor: theme.colors.neutral7,
                marginTop: 24,
                ...style,
              }}
            />
          )
        })}
      </>
    )
  }
  return (
    <>
      <View
        style={{
          height: 1,
          backgroundColor: theme.colors.neutral7,
          marginTop: 24,
          ...style,
        }}
      />
    </>
  )
}
