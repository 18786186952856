import { PatientFragmentFragment, useGetOnePatientQuery } from 'generated/graphql';


const usePatient = ({ id }): PatientFragmentFragment | null => {
  const { data, loading } = useGetOnePatientQuery({
    variables: {
      userId: id,
    },
    skip: !id,
    fetchPolicy: 'network-only',
  });

  return !loading && data?.getOnePatient ? data?.getOnePatient : null;
};

export default usePatient;
