import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
// COMPONENTS
import Progress from 'components/common/Progress'
import Popover from 'components/common/Popover'
import AccountDropdown from 'components/common/AccountDropdown'
import AccountAvatar from 'components/common/AccountAvatar'
import DashboardButton from 'components/common/DashboardButton'
import { Patient, UserProfile } from 'generated/graphql'
import { DeepPartial } from 'utility-types'

const Container = styled.div`
  height: 104px;
  width: 100%;
  background: ${(p) => p.theme.colors.neutral10};
`

const AvatarWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`

const Inner = styled.div`
  width: 700px;
  margin: auto;
  max-width: 95%;
  display: flex;
  align-items: center;
  height: 100px;
  position: relative;
`

const H1 = styled.h1`
  font-size: 18px;
  margin: 0px;
`

const ProgressText = styled.div`
  font-size: 14px;
  position: relative;
  top: 8px;
  font-weight: 400;
  color: ${(p) => p.theme.colors.neutral5};
  line-height: 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 8px;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    font-size: 16px;
    position: static;
  }
`

const ButtonContainer = styled.div`
  margin-right: 32px;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    margin-right: 16px;
  }
`

type SectionHeaderProps = {
  onSave: Function
  sectionName?: string
  progress: string
  currentUser: UserProfile
  patient: DeepPartial<Patient>
}

export default function SectionHeader({ onSave, sectionName, progress, currentUser, patient }: SectionHeaderProps) {
  const { t } = useTranslation()
  return (
    <Container>
      <Inner>
        <ButtonContainer>
          <DashboardButton
            onClick={() => {
              onSave()
              return
            }}
          />
        </ButtonContainer>
        <div style={{ marginTop: 16 }}>
          <H1>
            {t('section', 'Section')}: {sectionName}
          </H1>
          <div>
            <ProgressText>
              {progress}% {t('completed', 'completed')}
            </ProgressText>
            <Progress percent={parseInt(progress)} showInfo={false} size="small" />
          </div>
        </div>
        {!patient.hasOfficialPlan && (
          <AvatarWrapper>
            <Popover content={<AccountDropdown currentUser={currentUser} />}>
              <div>
                <AccountAvatar />
              </div>
            </Popover>
          </AvatarWrapper>
        )}
      </Inner>
    </Container>
  )
}
