import styled from 'styled-components'
import FormItem from 'components/common/FormItem'
import TextInput from 'components/inputs/TextInput'
import Button from 'components/common/Button'
import Row from 'components/common/Row'
import Col from 'components/common/Col'
import { useState } from 'react'
import { validate } from 'email-validator'
import ContentModal from './ContentModal'
import LegalConsentContent, { LegalConsentPDF } from './LegalConsentContent'
import { toNumber } from 'lodash'
import moment from 'moment'
import { pdf } from '@react-pdf/renderer'
import theme from 'lib/theme'
import { useWindowWidth } from '@react-hook/window-size'
import { useTranslation } from 'react-i18next'

const Title = styled.h3``

interface SignatureFormProps {
  title?: string
  onChange: (values: {
    firstName?: string
    lastName?: string
    email?: string
    signatureDate?: string
    address?: string
    city?: string
    state?: string
    postal?: string
  }) => void
  values: {
    firstName?: string
    lastName?: string
    email?: string
    signatureDate?: string
    address?: string
    city?: string
    state?: string
    postal?: string
  }
  onAgree: () => void
  onCancel: () => void
  showEmailInput: boolean
  legalText?: string | JSX.Element
  showLegal: boolean
  /** Tell the form whether to show the witness rquirements text or not */
  showWitnessRequirements?: boolean
  /** Will show the witness requirements text below the form */
  setShowWitnessRequirements: (v?: boolean) => void
  /** Will show the legal text below the form */
  setShowLegal: (boolean) => void
  /** Will show a loader for the submit button and disable both cancel and submit */
  loading: boolean
  /** The witness requirements text, if it exists */
  witnessRequirementText?: null | JSX.Element
  showAddressInputs?: boolean
}

const FormContent = styled.div`
  width: 400px;
  max-width: 100%;
  padding-bottom: 80px;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    padding-bottom: 180px;
  }
`

const ButtonRow = styled.div`
  margin-top: 32px;
  margin-bottom: 24px;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    position: fixed;
    bottom: 0px;
    right: 0px;
    left: 0px;
    background-color: ${({ theme }) => theme.colors.neutral9};
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0px;
    padding: 16px 8px;
  }
`

export default function SignatureForm({
  title,
  values,
  onChange,
  onAgree,
  onCancel,
  showEmailInput,
  legalText,
  showLegal,
  setShowLegal,
  loading,
  witnessRequirementText,
  showWitnessRequirements,
  setShowWitnessRequirements,
  showAddressInputs,
}: SignatureFormProps) {
  const windowWidth = useWindowWidth()
  const { t } = useTranslation()

  const [errors, setErrors] = useState<{
    firstName?: string
    lastName?: string
    email?: string
    address?: string
    city?: string
    state?: string
    postal?: string
  }>({
    firstName: undefined,
    lastName: undefined,
    email: undefined,
    address: undefined,
    city: undefined,
    state: undefined,
    postal: undefined,
  })

  const handleAgree = () => {
    setErrors({
      firstName: undefined,
      lastName: undefined,
      email: undefined,
      address: undefined,
      city: undefined,
      state: undefined,
      postal: undefined,
    })
    if (!values?.firstName?.[0]) {
      return setErrors({
        firstName: t('SignatureForm.firstName.error1', 'Please provide your first name'),
      })
    }
    if (!values?.lastName || values?.lastName?.length === 0) {
      return setErrors({
        lastName: t('SignatureForm.lastName.error1', 'Please provide your last name'),
      })
    }
    if (showEmailInput && (!values?.email || !validate(values?.email))) {
      return setErrors({
        email: t('SignatureForm.email.error1', 'Please provide a valid email'),
      })
    }

    if (showAddressInputs) {
      if (!values?.address?.[0]) {
        return setErrors({
          address: t('SignatureForm.address.error1', 'Please provide a valid address'),
        })
      }
      if (!values?.city?.[0]) {
        return setErrors({
          city: t('SignatureForm.city.error1', 'Please provide a valid city'),
        })
      }
      if (!values?.postal?.[0]) {
        return setErrors({
          postal: t('SignatureForm.postal.error1', 'Please provide a valid zip code'),
        })
      }
    }
    // do validation on names
    onAgree()
  }

  return (
    <div style={{ padding: 8 }}>
      <Title>{title || 'Your Signature'}</Title>
      {legalText}
      <FormContent>
        <FormItem
          label={t('SignatureForm.firstName.label', 'First Name')}
          error={errors?.firstName}
          htmlFor="firstName"
        >
          <TextInput
            value={values?.firstName || ''}
            onChange={(e) => onChange({ firstName: e.target.value })}
            placeholder={t('SignatureForm.firstName.placeholder', 'Type your first name here')}
            className="fs-exclude"
            data-testid="signature-first-name"
            id="firstName"
          />
        </FormItem>
        <FormItem label={t('SignatureForm.lastName.label', 'Last Name')} error={errors?.lastName} htmlFor="lastName">
          <TextInput
            value={values?.lastName || ''}
            onChange={(e) => onChange({ lastName: e.target.value })}
            placeholder={t('SignatureForm.lastName.placeholder', 'Type your last name here')}
            className="fs-exclude"
            data-testid="signature-last-name"
            id="lastName"
          />
        </FormItem>
        {showEmailInput && (
          <FormItem label={t('SignatureForm.email.label', 'Email')} error={errors?.email} htmlFor="email">
            <TextInput
              value={values?.email || ''}
              onChange={(e) => onChange({ email: e.target.value })}
              placeholder={t('SignatureForm.email.placeholder', 'Type your email here')}
              inputMode="email"
              type="email"
              id="email"
              className="fs-exclude"
              data-testid="signature-email"
            />
          </FormItem>
        )}
        {showAddressInputs && (
          <>
            <FormItem label={t('SignatureForm.address.label', 'Address')} error={errors?.address} htmlFor="address">
              <TextInput
                value={values?.address || ''}
                onChange={(e) => onChange({ address: e.target.value })}
                placeholder={t('SignatureForm.address.placeholder', 'Type your address here')}
                className="fs-exclude"
                data-testid="signature-address"
                id="address"
              />
            </FormItem>
            <FormItem label={t('SignatureForm.city.label', 'City')} error={errors?.city} htmlFor="city">
              <TextInput
                value={values?.city || ''}
                id="city"
                placeholder={t('SignatureForm.city.placeholder', 'Type your city here')}
                onChange={(e) => onChange({ city: e.target.value })}
                className="fs-exclude"
                data-testid="signature-city"
              />
            </FormItem>
            <Row gutter={16}>
              <Col xs={12}>
                <FormItem label={t('SignatureForm.state.label', 'State')} error={errors?.state} htmlFor="state">
                  <TextInput value={values?.state || ''} disabled className="fs-exclude" id="state" />
                </FormItem>
              </Col>
              <Col xs={12}>
                <FormItem label={t('SignatureForm.postal.label', 'Zip Code')} error={errors?.postal} htmlFor="postal">
                  <TextInput
                    value={values?.postal || ''}
                    type="tel"
                    id="postal"
                    className="fs-exclude"
                    placeholder={t('SignatureForm.postal.label', 'Type here')}
                    pattern="[0-9]*"
                    max="99999"
                    data-testid="signature-zip-code"
                    onChange={(e) => {
                      // only save numbers
                      if (!isNaN(toNumber(e.target.value)) && e.target.value?.length <= 5) {
                        return onChange({ postal: e.target.value })
                      }
                    }}
                  />
                </FormItem>
              </Col>
            </Row>
          </>
        )}
        <ButtonRow>
          <Button onClick={onCancel} grey style={{ marginRight: 8 }}>
            {t('SignatureForm.decline', 'Decline')}
          </Button>
          <Button data-testid="signature-agree-and-sign" onClick={handleAgree} loading={loading} disabled={loading}>
            {t('SignatureForm.agreeAndSign', 'Agree and Sign')}
          </Button>
        </ButtonRow>
        <ContentModal
          visible={showLegal}
          onClose={() => setShowLegal(false)}
          content={<LegalConsentContent />}
          onDownloadClick={async () => {
            try {
              const title = t('SignatureForm.downloadTitle', `Koda Legal Consent ${moment().format('M/D/YY')}.pdf`, {
                date: moment().format('M/D/YY'),
              })
              const newBlob = await pdf(<LegalConsentPDF />).toBlob()
              let exportFilename = title
              const nav: any = navigator // reassign global navigator variable to get rid of TS error
              if (nav?.msSaveBlob) {
                nav?.msSaveBlob(newBlob, exportFilename)
              } else {
                // In FF link must be added to DOM to be clicked
                let link = document.createElement('a')
                link.href = window.URL.createObjectURL(newBlob)
                // if on mobile, open the link in a different tab: https://kodahealth.atlassian.net/browse/KODA-540
                if (parseInt(theme.breakpoints.small) >= windowWidth) {
                  link.setAttribute('target', '_blank')
                }
                link.setAttribute('download', exportFilename)
                console.log(parseInt(theme.breakpoints.small), windowWidth)
                console.log(link)
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
              }
            } catch (err) {
              console.log('on download error')
            }
          }}
        />
        <ContentModal
          visible={witnessRequirementText && showWitnessRequirements ? true : false}
          contentStyle={{ paddingTop: 16 }}
          onClose={() => setShowWitnessRequirements(false)}
          content={
            <>
              <div id="signature-form-legal-text">{witnessRequirementText}</div>
            </>
          }
        />
      </FormContent>
    </div>
  )
}
