import { useState } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { validate } from 'email-validator'
import moment from 'moment'
// COMPONENTS
import EmailDirectiveForm from 'components/forms/EmailDirectiveForm'
import ElephantChat from 'components/common/ElephantChat'
import ShareSuccessModal from './ShareSuccessModal'
// APOLLO
// LIB
import { AnalyticEventTypeEnums } from 'lib/types/AnalyticEventTypeEnums'
// HOOKS
import useLogActivityItemMutation from 'lib/hooks/useLogActivityItemMutation'
import {
  ActivityLogKeyEnum,
  GetOnePatientDocument,
  Patient,
  useEmailDirectiveMutation,
  UserProfile,
} from 'generated/graphql'
import { DeepPartial } from 'utility-types'
import i18n from '../../../lib/helpers/i18n'
import PostSurveyAlert from 'components/common/PostSurveyAlert'
import logEvent from 'lib/helpers/logEvent'
import message from 'components/common/message'
import translateApolloError from 'lib/helpers/translateApolloError'

const Container = styled.div`
  width: 650px;
  background: #fff;
  border-radius: 5px;
  max-width: 100%;
  margin: auto;
  border: 1px solid ${(p) => p.theme.colors.neutral9};
`

const ContainerLarge = styled.div`
  width: 700px;
  max-width: 100%;
  margin: auto;
`

const Title = styled.h3`
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 8px;
  color: ${({ theme }) => theme.colors.neutral1};
`

const DEFAULT_SHARE_SUCCESS = {
  showModal: false,
  email: undefined,
  decisionMakerEmail: undefined,
  otherEmails: [],
}

const getDefaultOtherEmails = (plan: DeepPartial<Patient>): string[] => {
  const otherEmails: string[] = []

  if (plan.altDecisionMakerEmail) {
    otherEmails.push(plan.altDecisionMakerEmail)
  }

  if (plan.thirdDecisionMakerEmail) {
    otherEmails.push(plan.thirdDecisionMakerEmail)
  }

  if (plan.witness1SignatureEmail) {
    otherEmails.push(plan.witness1SignatureEmail)
  }

  if (plan.witness2SignatureEmail) {
    otherEmails.push(plan.witness2SignatureEmail)
  }
  return otherEmails
}

interface ErrorState {
  email?: string
  decisionMakerEmail?: string
  otherEmails?: string[]
}

interface EmailDirectiveProps {
  currentUser: UserProfile
  patient: Patient
  guideComplete: boolean
}

export default function EmailDirective({ patient, currentUser, guideComplete }: EmailDirectiveProps) {
  const [errors, setErrors] = useState<ErrorState>({
    email: undefined,
    decisionMakerEmail: undefined,
    otherEmails: undefined,
  })
  const [logActivityItemMutation] = useLogActivityItemMutation()
  const [logActivityItem] = useLogActivityItemMutation()
  const history = useHistory()
  const [showShareSuccess, setShowShareSuccess] = useState(DEFAULT_SHARE_SUCCESS)
  const [emailDirectiveMutation] = useEmailDirectiveMutation()
  const [personalNote, setPersonalNote] = useState<string | undefined>(undefined)
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState(patient.email || undefined)
  const defaultOtherEmails = getDefaultOtherEmails(patient)
  const [otherEmails, setOtherEmails] = useState<string[] | []>(defaultOtherEmails)
  const [decisionMakerEmail, setDecisionMakerEmail] = useState(patient.decisionMakerEmail || undefined)

  const noEmailsExist = !otherEmails?.[0] && !email && !decisionMakerEmail

  const onComplete = async ({ email, decisionMakerEmail, otherEmails = [], personalNote }) => {
    try {
      setLoading(true)
      // send the directive to emails
      await emailDirectiveMutation({
        variables: {
          email,
          decisionMakerEmail,
          otherEmails,
          personalNote,
        },
        refetchQueries: [
          {
            query: GetOnePatientDocument,
            variables: {
              userId: currentUser?.id,
            },
          },
        ],
      })
      // log the activity in the patient history
      logActivityItem({
        variables: {
          key: ActivityLogKeyEnum.EmailDirective,
          userId: currentUser.id || '',
          patientId: patient?.id || '',
          content:
            email && decisionMakerEmail
              ? `Patient emailed directive to ${email} and ${decisionMakerEmail}`
              : `Patient emailed directive to ${email} and did not send to medical descision maker`,
        },
      })
      // turn the loader off
      setLoading(false)
      // set local state for the "success modal"
      setShowShareSuccess({
        showModal: true,
        email,
        decisionMakerEmail,
        otherEmails,
      })
      // log an analytics event
      logEvent(AnalyticEventTypeEnums.PLAN_SHARED, {
        extraEmailsSent: Array.isArray(otherEmails) ? otherEmails.length : 0,
        decisionMakerEmailSent: decisionMakerEmail ? true : false,
        notaryInfo: patient.notaryInfo ? true : false,
      })
    } catch (err: any) {
      setLoading(false)
      message.error(translateApolloError(err))
    }
  }

  const handleOnComplete = () => {
    // clear errors when starting
    setErrors({})

    const newErrors: ErrorState = {
      email: undefined,
      decisionMakerEmail: undefined,
      otherEmails: [],
    }

    if (email && !validate(email)) {
      return setErrors({
        email: i18n.t('EmailDirective.validEmailError', 'Please provide a valid email address'),
      })
    }

    if (decisionMakerEmail && !validate(decisionMakerEmail)) {
      return setErrors({
        decisionMakerEmail: i18n.t('EmailDirective.validEmailError', 'Please provide a valid email address'),
      })
    }

    if (otherEmails?.length > 0) {
      let errorExists
      const validations: string[] = []
      otherEmails.forEach((otherEmail, index) => {
        validations.push('')
        if (!validate(otherEmail)) {
          errorExists = true
          validations[index] = 'Please provide a valid email address'
        }
      })
      if (errorExists) {
        newErrors.otherEmails = validations
      }
    }

    if (newErrors?.email || newErrors?.decisionMakerEmail || newErrors?.otherEmails?.[0]) {
      return setErrors(newErrors)
    }

    if (noEmailsExist) {
      return setErrors({
        ...errors,
        email: i18n.t('EmailDirective.validEmailError', 'Please provide a valid email address'),
      })
    }

    let params: {
      email?: string
      decisionMakerEmail?: string
      otherEmails?: string | string[]
    } = {
      email: undefined,
      decisionMakerEmail: undefined,
      otherEmails: [],
    }

    if (email && email.length > 0) {
      params.email = email
    }

    if (decisionMakerEmail && decisionMakerEmail.length > 0) {
      params.decisionMakerEmail = decisionMakerEmail
    }

    if (otherEmails && otherEmails?.[0]) {
      params.otherEmails = otherEmails
    }

    // if they input a string with at least two characters, log it into mixpanel
    if (personalNote?.[1]) {
      logActivityItemMutation(
        {
          variables: {
            key: ActivityLogKeyEnum.SentMdmEmailWithPersonalMessage,
            userId: patient?.userId || '',
            content: AnalyticEventTypeEnums.SENT_MDM_EMAIL_WITH_PERSONAL_MESSAGE,
            clinicId: patient?.clinicId || '',
            patientId: patient?.id,
          },
        },
        AnalyticEventTypeEnums.SENT_MDM_EMAIL_WITH_PERSONAL_MESSAGE
      )
    }

    onComplete({
      email: params.email,
      decisionMakerEmail: params.decisionMakerEmail,
      otherEmails: params.otherEmails as never[],
      personalNote,
    })
  }

  return (
    <>
      <ShareSuccessModal
        visible={showShareSuccess.showModal}
        email={showShareSuccess?.email}
        decisionMakerEmail={showShareSuccess.decisionMakerEmail}
        otherEmails={showShareSuccess?.otherEmails}
        askForFeedback={!currentUser?.hasNetScore ? true : false} //
        patient={patient}
        onClose={() => {
          setShowShareSuccess(DEFAULT_SHARE_SUCCESS)
          history.push('/app')
        }}
      />
      <ContainerLarge>
        <PostSurveyAlert guideComplete={guideComplete} clinicId={patient?.clinic?.id} patient={patient} />
        <div style={{ display: 'flex', marginBottom: 48 }}>
          <ElephantChat
            text={!patient?.hasOfficialPlan ? i18n.t('yourCarePlanShare.yes') : i18n.t('yourCarePlanShare.no')}
          />
        </div>
        {patient.planShares && patient.planShares.length > 0 && (
          <Container style={{ padding: 16, marginBottom: 32 }}>
            <Title>{!patient?.hasOfficialPlan ? i18n.t('planSharesTitle.yes') : i18n.t('planSharesTitle.no')}</Title>
            {patient?.planShares?.map((planShare) => {
              if (!planShare) return null
              const { sendToEmail, dateSent } = planShare
              if (!sendToEmail || !dateSent) return null
              return (
                <div key={sendToEmail + dateSent} style={{ marginTop: 16 }}>
                  <strong>{sendToEmail}</strong>{' '}
                  <p style={{ margin: 0 }}>{moment(parseInt(dateSent)).format('M/D/YYYY')}</p>
                </div>
              )
            })}
          </Container>
        )}
        <Container>
          <EmailDirectiveForm
            email={email}
            onPersonalNoteChange={(newNote) => setPersonalNote(newNote)}
            hasOfficialPlan={patient?.hasOfficialPlan}
            onCancel={() => history.push('/app')}
            otherEmails={otherEmails}
            errors={errors}
            loading={loading}
            onComplete={handleOnComplete}
            decisionMakerEmail={decisionMakerEmail}
            onChange={(newValues) => {
              if (typeof newValues?.email === 'string') {
                setEmail(newValues.email)
              }

              if (typeof newValues?.decisionMakerEmail === 'string') {
                setDecisionMakerEmail(newValues.decisionMakerEmail)
              }
              if (newValues?.otherEmails) {
                setOtherEmails(newValues.otherEmails)
              }
            }}
          />
        </Container>
      </ContainerLarge>
    </>
  )
}
