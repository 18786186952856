import { useCallback } from 'react'
import styled from 'styled-components'

import ButtonsContainer from '../SurveyForm/ButtonsContainer'

import ElephantChat from '../ElephantChat'
import getAgeRange from 'lib/helpers/getAgeRange'
import useLogActivityItemMutation from 'lib/hooks/useLogActivityItemMutation'
import { ActivityLogKeyEnum, Patient, UserProfile } from 'generated/graphql'
import { useTranslation } from 'react-i18next'

const MedicalCareYesNoWrapper = styled.div`
  max-width: 600px;
  margin: 48px auto;
  padding: 0 8px;
`

const MedicalCareYesNoButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 40px 0;
  button {
    width: 120px;
    height: 56px;
    background: #01337d;
    border-radius: 4px;
    color: white;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    border: none;
    text-transform: capitalize;
    cursor: pointer;
    &:first-child {
      margin-right: 40px;
    }

    @media only screen and (max-width: 600px) {
      &:first-child {
        margin-right: 24px;
      }
    }
  }
`

interface MedicalCareYesNoProps {
  progress?: string
  nextButton?: string
  patient?: Patient
  setPlan?: (plan: Patient) => void
  handleSavePatient?: (plan: Patient, callback: () => void) => void
  onNext?: (plan: Patient) => void
  onBack?: () => void
  currentUser?: UserProfile
}

const MedicalCareYesNo = ({
  progress,
  nextButton,
  patient,
  setPlan,
  handleSavePatient,
  onNext,
  onBack,
  currentUser,
}: MedicalCareYesNoProps) => {
  const [logActivityItem] = useLogActivityItemMutation()

  const { t } = useTranslation()

  const noClick = useCallback(async () => {
    const newPlan = {
      ...patient,
      likeToKnowCprAgeGroup: false,
    }
    await logActivityItem({
      variables: {
        key: ActivityLogKeyEnum.ClickedNoAgeSpecificStat,
        userId: currentUser?.id || '',
        patientId: patient?.id || '',
        content: `Patient clicked "no" for age-specific statistics`,
      },
    })
    if (setPlan) {
      setPlan(newPlan as Patient)
    }
    if (handleSavePatient) {
      handleSavePatient(newPlan as Patient, () => {
        if (onNext) {
          onNext(newPlan as Patient)
        }
      })
    }
  }, [currentUser, patient, handleSavePatient, logActivityItem, onNext, setPlan])

  const yesClick = useCallback(async () => {
    const newPlan = {
      ...patient,
      likeToKnowCprAgeGroup: true,
    }
    await logActivityItem({
      variables: {
        key: ActivityLogKeyEnum.ClickedYesAgeSpecificStat,
        userId: currentUser?.id || '',
        patientId: patient?.id || '',
        content: `Patient clicked "yes" for age-specific statistics`,
      },
    })
    if (setPlan) {
      setPlan(newPlan as Patient)
    }
    if (handleSavePatient) {
      handleSavePatient(newPlan as Patient, () => {
        if (onNext) {
          onNext(newPlan as Patient)
        }
      })
    }
  }, [currentUser, patient, handleSavePatient, logActivityItem, onNext, setPlan])

  const ageRange = getAgeRange(patient?.age as number)

  return (
    <MedicalCareYesNoWrapper>
      <ElephantChat
        text={t('likeToKnowCprAgeGroup.text', {
          ageGroup: ageRange,
        })}
        bubleStyle={{ filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))' }}
      />
      <MedicalCareYesNoButtons>
        <button onClick={noClick}>{t('likeToKnowCprAgeGroup.no')}</button>
        <button onClick={yesClick}>{t('likeToKnowCprAgeGroup.yes')}</button>
      </MedicalCareYesNoButtons>
      <ButtonsContainer onNext={yesClick} onBack={onBack} nextButton={nextButton} progress={progress} hideNext />
    </MedicalCareYesNoWrapper>
  )
}

export default MedicalCareYesNo
