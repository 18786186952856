const getPrNumber = (): string | undefined => {
  // get PR number from url if it's AWS Amplify
  if (window.location.href.includes('d2m6pbecrqpcm9')) {
    const prNumber = window.location.href?.replace('https://pr-', '')?.replace('.d2m6pbecrqpcm9.amplifyapp.com', '')
    return prNumber
  }
  // if it's a netlify url, there is different logic to find the PR
  if (window.location.href.includes('netlify')) {
    const strippedUrl = window.location.href?.replace('https://deploy-preview-', '')
    const prNumber = strippedUrl.slice(0, strippedUrl?.indexOf('-'))
    return prNumber
  }
}

const getGraphqlEndpoint = (): string => {
  // for PR preview apps, we have some special logic to figure out the graphql endpoint
  if (process.env.REACT_APP_BUILD_ENV === 'preview') {
    const prNumber = getPrNumber()
    console.log(process.env.REVIEW_ID, process.env.BRANCH)
    console.log(process.env)
    const endpoint = `https://koda-preview-pr-${prNumber}.herokuapp.com/graphql` // `https://backend-preview-pr-${prNumber}.onrender.com/graphql` // OLD HEROKU REVIEW APP URL =>  ``
    console.log(endpoint) // only log it for preview, so we can check
    return endpoint
  }
  return process.env.REACT_APP_API_HOST || 'http://localhost:3050/graphql'
}

const getCommitRef = () => {
  if (process.env.AWS_COMMIT_ID) return process.env.AWS_COMMIT_ID
  if (process.env.AWS_BRANCH) return process.env.AWS_BRANCH
  if (process.env.REACT_APP_COMMIT_ID) {
    return process.env.REACT_APP_COMMIT_ID
  }
  if (process.env.REACT_APP_BUILD_ENV === 'preview') {
    const prNumber = getPrNumber()
    return `pr-${prNumber}`
  }
  if (process.env.REACT_APP_BUILD_ENV === 'development' || process.env.REACT_APP_BUILD_ENV === 'local') {
    return 'localhost'
  }
}

const constants = {
  appName: 'Koda',
  authTokenName: process.env.AUTH_TOKEN_NAME || 'koda_access_token',
  refreshTokenName: process.env.REFERSH_TOKEN_NAME || 'koda_refresh_token',
  supportEmail: 'care@kodahealthcare.com',
  supportPhone: '832-736-3116',
  calendlyNotaryLink: 'https://calendly.com/notaries/60min',
  NODE_ENV: process.env.REACT_APP_BUILD_ENV || process.env.NODE_ENV, // on netlify and amplify, it seems to always set NODE_ENV to production... so creating a custom env var called REACT_APP_BUILD_ENV that we check for first
  SENTRY_DSN:
    process.env.REACT_APP_SENTRY_DSN || 'https://a96adb486ae84c0abfd7047d45317acf@o423856.ingest.sentry.io/5354801',
  GRAPHQL_API_ENDPOINT: getGraphqlEndpoint(),
  COMMIT_REF: getCommitRef(), // todo: figure out how to get the commit ref as a build variable
  FULLSTORY_ORD_ID: process.env.REACT_APP_FULLSTORY_ORD_ID || 'YBA1B',
  NEXUS_API_KEY: process.env.REACT_APP_NEXUS_API_KEY,
  RELEASE: process.env.REACT_APP_RELEASE,
}

export default constants
