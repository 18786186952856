// TOP LEVEL IMPORTS
import { FC } from 'react'
import { Route } from 'react-router-dom'
import { UserProfile } from 'generated/graphql'

interface BlankRouteProps {
  currentUser?: UserProfile
  redirectOnAuth?: boolean
  showHeader?: boolean
  showFooter?: boolean
  component?: any
  path?: string | string[]
  backgroundColor?: string
  layout?: any
  exact?: boolean
}

// EXPORTED COMPONENT
// ==============================
const BlankRoute: FC<BlankRouteProps> = (props) => {
  const {
    currentUser,
    showHeader,
    showFooter,
    component: Component,
    path,
    backgroundColor,
    layout: Layout,
    ...rest
  } = props

  return (
    <Route
      {...rest}
      path={path}
      render={(args) => (
        <Layout
          currentUser={currentUser}
          backgroundColor={backgroundColor}
          showHeader={showHeader}
          showFooter={showFooter}
        >
          <Component currentUser={currentUser} {...args} />
        </Layout>
      )}
    />
  )
}

// PROPS
// ==============================
BlankRoute.defaultProps = {
  redirectOnAuth: true,
}

// EXPORT
// ==============================
export default BlankRoute
