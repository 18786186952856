import { FC, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import {
  ActivityLogKeyEnum,
  Patient,
  PostAcpSystemUsabilityScale,
  UserProfile,
} from "generated/graphql";

import Button from "components/common/Button";
import useSavePatient from "lib/hooks/useSavePatient";
import usePrevious from "lib/hooks/usePrevious";
import Loading from "components/common/Loading";
import SectionFooter from "routes/app-survey/SectionFooter";
import SectionHeader from "routes/app-survey/SectionHeader";
import SelectBlock from "routes/app-survey/step1/SelectBlock";
import { AfterSaveAction } from "routes/app-survey/step1";
import getPostSurveyQuestions from "lib/helpers/getPostSurveyQuestions";
import useLogActivityItemMutation from "lib/hooks/useLogActivityItemMutation";
import PlanConfiguratorHelper from "routes/app-home/PlanConfigurator/PlanConfiguratorHelper";

const Step1Styled = styled.div`
  background-color: white;
  flex: 1;
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints.medium}) {
    padding-bottom: 100px;
  }
`;

const Step1Body = styled.div`
  max-width: 600px;
  margin: auto;
  padding: 48px 10px;
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints.mediumSmall}) {
    padding: 24px;
  }
`;

const Step1Title = styled.h1`
  font-weight: 500;
  font-size: 18px;
  color: #323f4b;
`;

export const Step1Continue = styled.div`
  display: none;
  margin-top: 20px;
  @media only screen and (min-width: 1025px) {
    display: block;
  }
`;

interface Step1Props {
  patient?: Patient | null;
  currentUser?: UserProfile;
}

interface Plan {
  postAcpUseSystemFrequently: PostAcpSystemUsabilityScale | null;
  postAcpSystemUnnecessarilyComplex: PostAcpSystemUsabilityScale | null;
  postAcpSystemWasEasyUse: PostAcpSystemUsabilityScale | null;
  postAcpSupportTechnicalPerson: PostAcpSystemUsabilityScale | null;
  postAcpVariousFunctionsWellIntegrated: PostAcpSystemUsabilityScale | null;
  postAcpTooMuchInconsistency: PostAcpSystemUsabilityScale | null;
  postAcpMostPeopleWouldLearn: PostAcpSystemUsabilityScale | null;
  postAcpSystemVeryCumbersomeToUse: PostAcpSystemUsabilityScale | null;
  postAcpConfidentUsingTheSystem: PostAcpSystemUsabilityScale | null;
  postAcpLearnLotPfThingsBefore: PostAcpSystemUsabilityScale | null;
}

const Step5: FC<Step1Props> = ({ patient, currentUser }) => {
  const [logActivityItem] = useLogActivityItemMutation();

  const [postSurveyComplete, setPostSurveyComplete] = useState<boolean>(true);
  const [showCongratsModal, setShowCongratsModal] = useState<boolean>(false);
  const [afterSaveAction, setAfterSaveAction] = useState<AfterSaveAction>();
  const [plan, setPlan] = useState<Plan>({
    postAcpUseSystemFrequently: null,
    postAcpSystemUnnecessarilyComplex: null,
    postAcpSystemWasEasyUse: null,
    postAcpSupportTechnicalPerson: null,
    postAcpVariousFunctionsWellIntegrated: null,
    postAcpTooMuchInconsistency: null,
    postAcpMostPeopleWouldLearn: null,
    postAcpSystemVeryCumbersomeToUse: null,
    postAcpConfidentUsingTheSystem: null,
    postAcpLearnLotPfThingsBefore: null,
  });

  const { onSavePatient, saving } = useSavePatient();

  const history = useHistory();
  const { t } = useTranslation();
  const savingPrev = usePrevious(saving);

  const questions = patient ? getPostSurveyQuestions(patient) : undefined;

  const save = async () => {
    if (patient) {
      const newPatient = {
        ...patient,
        postAcpUseSystemFrequently:
          plan.postAcpUseSystemFrequently as PostAcpSystemUsabilityScale,
        postAcpSystemUnnecessarilyComplex:
          plan.postAcpSystemUnnecessarilyComplex as PostAcpSystemUsabilityScale,
        postAcpSystemWasEasyUse:
          plan.postAcpSystemWasEasyUse as PostAcpSystemUsabilityScale,
        postAcpSupportTechnicalPerson:
          plan.postAcpSupportTechnicalPerson as PostAcpSystemUsabilityScale,
        postAcpVariousFunctionsWellIntegrated:
          plan.postAcpVariousFunctionsWellIntegrated as PostAcpSystemUsabilityScale,
        postAcpTooMuchInconsistency:
          plan.postAcpTooMuchInconsistency as PostAcpSystemUsabilityScale,
        postAcpMostPeopleWouldLearn:
          plan.postAcpMostPeopleWouldLearn as PostAcpSystemUsabilityScale,
        postAcpSystemVeryCumbersomeToUse:
          plan.postAcpSystemVeryCumbersomeToUse as PostAcpSystemUsabilityScale,
        postAcpConfidentUsingTheSystem:
          plan.postAcpConfidentUsingTheSystem as PostAcpSystemUsabilityScale,
        postAcpLearnLotPfThingsBefore:
          plan.postAcpLearnLotPfThingsBefore as PostAcpSystemUsabilityScale,
      };
      const planHelpers = new PlanConfiguratorHelper();
      const isPostSurveyComplete =
        planHelpers.getIsPostAcpSurveyCompleted(newPatient);
      if (isPostSurveyComplete) {
        await logActivityItem({
          variables: {
            key: ActivityLogKeyEnum.CompletedPostassesmentSurvey,
            userId: currentUser?.id || "",
            patientId: patient?.id || "",
            content: `Patient completed post-assessment survey`,
          },
        });
      }
      await onSavePatient(newPatient);
    }
  };

  const goNext = () => {
    setAfterSaveAction(AfterSaveAction.NEXT);
    save();
  };

  const onSelect = (value, fieldName) => {
    setPlan({
      ...plan,
      [fieldName]: value,
    });
  };

  useEffect(() => {
    // set patient values every patient change
    if (patient) {
      setPlan({
        postAcpUseSystemFrequently: patient.postAcpUseSystemFrequently || null,
        postAcpSystemUnnecessarilyComplex:
          patient.postAcpSystemUnnecessarilyComplex || null,
        postAcpSystemWasEasyUse: patient.postAcpSystemWasEasyUse || null,
        postAcpSupportTechnicalPerson:
          patient.postAcpSupportTechnicalPerson || null,
        postAcpVariousFunctionsWellIntegrated:
          patient.postAcpVariousFunctionsWellIntegrated || null,
        postAcpTooMuchInconsistency:
          patient.postAcpTooMuchInconsistency || null,
        postAcpMostPeopleWouldLearn:
          patient.postAcpMostPeopleWouldLearn || null,
        postAcpSystemVeryCumbersomeToUse:
          patient.postAcpSystemVeryCumbersomeToUse || null,
        postAcpConfidentUsingTheSystem:
          patient.postAcpConfidentUsingTheSystem || null,
        postAcpLearnLotPfThingsBefore:
          patient.postAcpLearnLotPfThingsBefore || null,
      });
    }
  }, [patient]);

  useEffect(() => {
    // Go to step 2 on saving end and if patient is truthy
    if (savingPrev && !saving && patient && afterSaveAction) {
      switch (afterSaveAction) {
        case AfterSaveAction.NEXT: {
          if (showCongratsModal) {
            history.push("/app/home?postSurveyComplete=true");
          } else {
            history.push("/app/home");
          }
          break;
        }
        case AfterSaveAction.HOME: {
          if (showCongratsModal) {
            history.push("/app/home?postSurveyComplete=true");
          } else {
            history.push("/app/home");
          }
          break;
        }
        case AfterSaveAction.BACK: {
          history.push("/app/post-survey?step=4");
          break;
        }
        default: {
          return;
        }
      }
    }
  }, [
    history,
    savingPrev,
    saving,
    patient,
    afterSaveAction,
    showCongratsModal,
  ]);

  useEffect(() => {
    if (
      !patient?.postAcpUseSystemFrequently ||
      !patient?.postAcpSystemUnnecessarilyComplex ||
      !patient?.postAcpSystemWasEasyUse ||
      !patient?.postAcpSupportTechnicalPerson ||
      !patient?.postAcpVariousFunctionsWellIntegrated ||
      !patient?.postAcpTooMuchInconsistency ||
      !patient?.postAcpMostPeopleWouldLearn ||
      !patient?.postAcpSystemVeryCumbersomeToUse ||
      !patient?.postAcpConfidentUsingTheSystem ||
      !patient?.postAcpLearnLotPfThingsBefore
    ) {
      setPostSurveyComplete(false);
    }
    /* eslint-disable-next-line */
  }, [patient?.id]);

  useEffect(() => {
    if (
      patient?.postAcpUseSystemFrequently &&
      patient?.postAcpSystemUnnecessarilyComplex &&
      patient?.postAcpSystemWasEasyUse &&
      patient?.postAcpSupportTechnicalPerson &&
      patient?.postAcpVariousFunctionsWellIntegrated &&
      patient?.postAcpTooMuchInconsistency &&
      patient?.postAcpMostPeopleWouldLearn &&
      patient?.postAcpSystemVeryCumbersomeToUse &&
      patient?.postAcpConfidentUsingTheSystem &&
      patient?.postAcpLearnLotPfThingsBefore &&
      !postSurveyComplete
    ) {
      setShowCongratsModal(true);
    }
  }, [patient, postSurveyComplete]);

  const disabled =
    !plan?.postAcpUseSystemFrequently ||
    !plan?.postAcpSystemUnnecessarilyComplex ||
    !plan?.postAcpSystemWasEasyUse ||
    !plan?.postAcpSupportTechnicalPerson ||
    !plan?.postAcpVariousFunctionsWellIntegrated ||
    !plan?.postAcpTooMuchInconsistency ||
    !plan?.postAcpMostPeopleWouldLearn ||
    !plan?.postAcpSystemVeryCumbersomeToUse ||
    !plan?.postAcpConfidentUsingTheSystem ||
    !plan?.postAcpLearnLotPfThingsBefore;

  return (
    <>
      <Step1Styled>
        <SectionHeader
          sectionName={t("planConfigurator.POST_SURVEY", "Post-ACP Survey")}
          progress={"80"}
          onClick={() => {
            setAfterSaveAction(AfterSaveAction.HOME);
            save();
          }}
        />
        {saving && <Loading height={80} />}
        <Step1Body>
          <Step1Title>{questions?.step5?.title || ""}</Step1Title>
          {questions?.step5?.questions.map((question, index) => (
            <SelectBlock
              key={index}
              title={question.question}
              subtitle={question.description}
              items={Object.keys(question.enum)}
              hash={question.answer}
              active={plan[question.field]}
              onSelect={(item: string) => onSelect(item, question.field)}
            />
          ))}
          {!disabled && (
            <Step1Continue>
              <Button loading={saving} disabled={saving} onClick={goNext} pulse>
                {t("button.continue", "Continue")}
              </Button>
            </Step1Continue>
          )}
        </Step1Body>
      </Step1Styled>
      <SectionFooter
        goBack={() => {
          setAfterSaveAction(AfterSaveAction.BACK);
          save();
        }}
        goNext={goNext}
        loading={saving}
        disabled={disabled}
      />
    </>
  );
};

export default Step5;
