import { AdPartEnum, PlanSetting } from '../types'
import en from 'lib/helpers/locales/en/translation.json'
import { Text, View } from '@react-pdf/renderer'
import s from '../../../KodaContent/styles'
import TextField from 'components/common/PlanDoc/KodaContent/TextField'

export default function getDC(t, defaultPartSettings): PlanSetting {
  const dc: PlanSetting = {
    state: 'Washington, D.C.',
    disclaimer: t(
      'DC.disclaimer',
      'Disclaimer: This document needs to be signed and have two witnesses sign as well in order to be a legal document.'
    ),
    parts: [
      // Part 1
      {
        ...defaultPartSettings?.[AdPartEnum.health_care_choices],
      },
      // Part 2
      {
        ...defaultPartSettings?.[AdPartEnum.medical_decision_maker],
        pageTitle: t(
          'DC.medical_decision_maker.pageTitle',
          'Information for Medical Decision Maker (Medical Power of Attorney)'
        ),
        description: t(
          'DC.medical_decision_maker.description',
          'This part is also known as a Medical Power of Attorney. It designates a person to make decisions for you when you are unable to speak for yourself.'
        ),
      },
      // Part 3
      {
        ...defaultPartSettings?.[AdPartEnum.sign_the_form],
        description: t(
          'DC.sign_the_form.description',
          'You can fill out Part 1, Part 2, or both, but you need to sign the form in Part 3. To be legal, two witnesses also need to sign.'
        ),
        beforeYouSign: {
          requirements: [
            t(
              'planDoc.sign_the_form.beforeYouSignRequirements_age',
              en.planDoc.sign_the_form.beforeYouSignRequirements_age
            ),
            t(
              'planDoc.sign_the_form.beforeYouSignRequirements_witnesses',
              en.planDoc.sign_the_form.beforeYouSignRequirements_witnesses
            ),
          ],
          finalClause: t(
            'DC.sign_the_form.beforeYouSignFinalClause',
            'By my signature I indicate that I understand the purpose and effect of this document.'
          ),
        },
        witness1SignatureText: (
          <Text style={s.formLabel}>
            {t('DC.sign_the_form.witness1SignatureText', en.DC.sign_the_form.witness1SignatureText)}
          </Text>
        ),
        witnessSignatures: {
          postSignaturesContent: (
            <View style={{ marginTop: 16, paddingBottom: 40 }}>
              <Text style={s.formLabel}>
                {t(
                  'DC.sign_the_form.witnessSignatures.postSignaturesContentTitle',
                  en.DC.sign_the_form.witnessSignatures.postSignaturesContentTitle
                )}
              </Text>
              <Text style={s.formLabel}>
                {t(
                  'DC.sign_the_form.witnessSignatures.postSignaturesContentClause',
                  en.DC.sign_the_form.witnessSignatures.postSignaturesContentClause
                )}
              </Text>

              <View style={{ display: 'flex', flexDirection: 'row', width: '50%' }}>
                <TextField
                  label={t('MasterPlanDocSignaturePage.signYourName', en.MasterPlanDocSignatureBlock.signYourName)}
                  style={{
                    marginTop: 3,
                    borderWidth: 4,
                  }}
                  value=""
                />
              </View>
              <View style={{ display: 'flex', flexDirection: 'row', width: '50%' }}>
                <TextField
                  label={t('MasterPlanDocSignaturePage.signYourName', en.MasterPlanDocSignatureBlock.signYourName)}
                  style={{
                    marginTop: 3,
                    borderWidth: 4,
                  }}
                  value=""
                />
              </View>
            </View>
          ),
        },
        witnessRequirements: {
          title: t('planDoc.sign_the_form.witnessRequirements_title', 'Witnesses or Notary Requirements'), // OR "Witness and Notary Requirements"
          requirements: [
            {
              title: t(
                'planDoc.sign_the_form.witnessRequirements_bothWitnesses',
                en.planDoc.sign_the_form.witnessRequirements_bothWitnesses
              ),
              width: '50%',
              phrases: [
                t('planDoc.sign_the_form.witnessRequirements_be18', en.planDoc.sign_the_form.witnessRequirements_be18),
                t(
                  'planDoc.sign_the_form.witnessRequirements_seeYouSign',
                  en.planDoc.sign_the_form.witnessRequirements_seeYouSign
                ),
              ],
            },
            {
              title: t(
                'planDoc.sign_the_form.witnessRequirements_yourWitnessCannot',
                en.planDoc.sign_the_form.witnessRequirements_yourWitnessCannot
              ),
              width: '50%',
              phrases: [
                t(
                  'planDoc.sign_the_form.witnessRequirements_beYourProvider',
                  en.planDoc.sign_the_form.witnessRequirements_beYourProvider
                ),
                t(
                  'planDoc.sign_the_form.witnessRequirements_beEmployeeOfProvider',
                  en.planDoc.sign_the_form.witnessRequirements_beEmployeeOfProvider
                ),
              ],
            },
            {
              title: t(
                'planDoc.sign_the_form.witnessRequirements_atLeastOneWitnessCannot',
                en.planDoc.sign_the_form.witnessRequirements_atLeastOneWitnessCannot
              ),
              width: '100%',
              phrases: [
                t(
                  'planDoc.sign_the_form.witnessRequirements_beRelatedBloodMarriageAdoption',
                  en.planDoc.sign_the_form.witnessRequirements_beRelatedBloodMarriageAdoption
                ),
                t(
                  'planDoc.sign_the_form.witnessRequirements_entitledToAnyPartOfEstateOrWill',
                  en.planDoc.sign_the_form.witnessRequirements_entitledToAnyPartOfEstateOrWill
                ),
              ],
            },
          ],
          validityRequirements: [
            t(
              'planDoc.sign_the_form.validityRequirements_prinatAndSignBeforeTwoWitnesses',
              en.planDoc.sign_the_form.validityRequirements_prinatAndSignBeforeTwoWitnesses
            ),
          ],
        },
      },
    ],
  }

  return dc
}
