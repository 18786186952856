import PlanSectionDocument from 'components/common/PlanDoc/PlanSectionDocument'
import { Text } from '@react-pdf/renderer'
// LIB
import QualityOfLifeV2 from './QualityOfLifeV2'
import MeAndMyValues from './MeAndMyValues'
import SupportiveCare from './SupportiveCare'
import { MedicalCareTreatmentUnacceptable, MedicalCareTreatmentToday } from './MedicalCareTreatment'
import { MedicalCareScenariosPage1, MedicalCareScenariosPage2 } from './MedicalCareScenarios'
import OtherImportantInformation from './OtherImportantInformation'
import { GuideQuestionIdEnum, GuideTemplateSectionIdEnum, Patient } from 'generated/graphql'
import { Trans } from 'react-i18next'
import s from '../../KodaContent/styles'
import flatten from 'lib/helpers/flatten'

const checkIfTemplateHasScenarios = (data: Patient): boolean => {
  const medicalCareQuestions = data?.guideTemplate?.sections
    ?.find((item) => item.sectionId === GuideTemplateSectionIdEnum.MedicalCare)
    ?.questionIds?.map((item) => item.toString())
  const scenarioQuestions = [
    GuideQuestionIdEnum.MedicalCareScenario1,
    GuideQuestionIdEnum.MedicalCareScenario2,
    GuideQuestionIdEnum.MedicalCareScenario3,
    GuideQuestionIdEnum.LifeSustainingProcedures,
  ]
  for (let i = 0; i < scenarioQuestions.length; i++) {
    if (medicalCareQuestions?.includes(scenarioQuestions[i])) {
      return true
    }
  }
  return false
}

export default function HealthCareChoices({ createdStamp, pageTitle, data, name, hasSupportiveCare, questions }) {
  const hasScenariosSection = checkIfTemplateHasScenarios(data)
  const guideQuestions = flatten(data?.guideTemplate?.sections?.map((item) => item.questionIds))

  const medicalCareTreatmentTodayQs = [
    GuideQuestionIdEnum.CprPreferenceToday,
    GuideQuestionIdEnum.VentilationPreferenceToday,
    GuideQuestionIdEnum.LongTermFeedingToday,
    GuideQuestionIdEnum.DialysisTreatmentToday,
  ]

  const medicalCareTreatmentQs = [
    GuideQuestionIdEnum.CprPreference,
    GuideQuestionIdEnum.VentilationPreference,
    GuideQuestionIdEnum.LongTermFeeding,
    GuideQuestionIdEnum.DialysisTreatment,
  ]

  return (
    <>
      <PlanSectionDocument
        pageTitleLeft={pageTitle}
        pageTitleRight={name}
        content={
          <>
            <QualityOfLifeV2 data={data} />
            <MeAndMyValues data={data} />
            {guideQuestions?.includes(GuideQuestionIdEnum.AdditionalCarePreferences) && (
              <Text style={{ marginTop: 4, fontSize: 10 }}>
                <Trans i18nKey="MasterPlanDocHealthCareChoices.hint">
                  * If you have additional information, see
                  <Text style={{ ...s.textBold, margin: 0, fontSize: 10 }}>Other Important Information</Text> in Part 1.
                </Trans>
              </Text>
            )}
            {/* only show if guide has medicalCareTreatmentTodayQs */}
            {medicalCareTreatmentTodayQs?.some((r) => guideQuestions.includes(r)) && (
              <MedicalCareTreatmentToday data={data} questions={questions} />
            )}
            {/* only show if guide has medicalCareTreatmentQs */}
            {medicalCareTreatmentQs?.some((r) => guideQuestions.includes(r)) && (
              <MedicalCareTreatmentUnacceptable data={data} questions={questions} />
            )}
            {hasScenariosSection && (
              <>
                <MedicalCareScenariosPage1 data={data} questions={questions} />
                <MedicalCareScenariosPage2 data={data} questions={questions} />
              </>
            )}
            {hasSupportiveCare && <SupportiveCare data={data} questions={questions} />}
            <OtherImportantInformation data={data} />
          </>
        }
        pageFooterRight={createdStamp}
        showFooter
      />

      {/* <DocPage
        pageTitle={pageTitle}
        name={name}
        content={
          <View>
            <QualityOfLifeV2 data={data} />
            <MeAndMyValues data={data} />
            {guideQuestions?.includes(GuideQuestionIdEnum.AdditionalCarePreferences) && (
              <Text style={{ marginTop: 4, fontSize: 10 }}>
                <Trans i18nKey="MasterPlanDocHealthCareChoices.hint">
                  * If you have additional information, see
                  <Text style={{ ...s.textBold, margin: 0, fontSize: 10 }}>Other Important Information</Text> in Part 1.
                </Trans>
              </Text>
            )}
          </View>
        }
        showPageNumbers
        createdStamp={createdStamp}
      /> */}
      {/* only show if guide has medicalCareTreatmentTodayQs */}
      {/* {medicalCareTreatmentTodayQs?.some((r) => guideQuestions.includes(r)) && (
        <DocPage
          pageTitle={pageTitle}
          name={name}
          content={<MedicalCareTreatmentToday data={data} questions={questions} />}
          showPageNumbers
          createdStamp={createdStamp}
        />
      )} */}
      {/* only show if guide has medicalCareTreatmentQs */}
      {/* {medicalCareTreatmentQs?.some((r) => guideQuestions.includes(r)) && (
        <DocPage
          pageTitle={pageTitle}
          name={name}
          content={<MedicalCareTreatmentUnacceptable data={data} questions={questions} />}
          showPageNumbers
          createdStamp={createdStamp}
        />
      )} */}

      {/* {hasScenariosSection && (
        <>
          <DocPage
            pageTitle={pageTitle}
            name={name}
            content={<MedicalCareScenariosPage1 data={data} questions={questions} />}
            showPageNumbers
            createdStamp={createdStamp}
          />
          <DocPage
            pageTitle={pageTitle}
            name={name}
            content={<MedicalCareScenariosPage2 data={data} questions={questions} />}
            showPageNumbers
            createdStamp={createdStamp}
          />
        </>
      )} */}
      {/* {hasSupportiveCare && (
        <DocPage
          pageTitle={pageTitle}
          name={name}
          content={<SupportiveCare data={data} questions={questions} />}
          showPageNumbers
          createdStamp={createdStamp}
        />
      )}
      <DocPage
        pageTitle={pageTitle}
        name={name}
        content={<OtherImportantInformation data={data} />}
        showPageNumbers
        createdStamp={createdStamp}
      /> */}
    </>
  )
}
