import theme from 'lib/theme'
import { Text, View, StyleSheet } from '@react-pdf/renderer'
import moment from 'moment'
import { Maybe } from 'generated/graphql'

const s = StyleSheet.create({
  page: {
    padding: 32,
  },
  topRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderColor: theme.colors.neutral2,
    borderWidth: 1,
    padding: 16,
    marginBottom: 16,
  },
  warning: {
    borderColor: theme.colors.neutral2,
    borderWidth: 1,
    padding: 4,
    borderRadius: 2,
  },
  headerText: {
    fontSize: 12,
    marginBottom: 4,
    color: theme.colors.neutral1,
  },
  patientRow: {
    paddingTop: 24,
    paddingBottom: 24,
  },
})

interface CoverLetterProps {
  faxNumber?: Maybe<string>
  clinicName?: Maybe<string>
  patientName?: string
  birthday?: string
  patientCreatedAt: string
  filename: string
}

export default function CoverLetter({
  faxNumber,
  clinicName,
  patientName,
  birthday,
  patientCreatedAt,
  filename,
}: CoverLetterProps) {
  return (
    <View style={s.page}>
      <View style={s.topRow}>
        <View
          style={{
            borderColor: theme.colors.neutral9,
            borderRightWidth: 1,
            paddingRight: 32,
          }}
        >
          <Text style={s.headerText}>Koda Health</Text>
          <Text style={s.headerText}>2450 Holcombe Blvd</Text>
          <Text style={s.headerText}>Ste X</Text>
          <Text style={s.headerText}>Houston, TX 77021</Text>
          <Text style={s.headerText}>Tel: 405-602-4242 | Fax: 855-940-1052</Text>
        </View>

        <View
          style={{
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Text style={{ fontSize: 32 }}>Fax</Text>
        </View>
      </View>
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <View
          style={{
            flex: 1,
          }}
        >
          <Text style={s.headerText}>TO: {clinicName}</Text>
          <Text style={s.headerText}>FAX: {faxNumber}</Text>
          <Text style={s.headerText}>SUBJECT: ACP Documentation</Text>
        </View>
        <View
          style={{
            flex: 1,
          }}
        >
          <Text style={s.headerText}>FROM: Taylor Huffman - Koda Health</Text>
          <Text style={s.headerText}>DATE: {moment().format('MM/DD/YYYY hh:mma')}</Text>
        </View>
      </View>
      <View
        style={{
          borderWidth: 1,
          borderColor: theme.colors.neutral1,
          marginTop: 16,
        }}
      />
      <View style={s.patientRow}>
        <Text style={s.headerText}>Place in folder: Advance Directive → Koda</Text>
        <Text style={s.headerText}>Title to save as: {filename || 'Koda Care Plan Summary'}</Text>
        <Text style={s.headerText}>NAME: {patientName}</Text>
        <Text style={s.headerText}>DOB: {birthday}</Text>
        <Text style={s.headerText}>DATE OF SERVICE: {moment(parseInt(patientCreatedAt)).format('MM/DD/YYYY')}</Text>
      </View>
      <View style={s.warning}>
        <Text style={{ fontSize: 11 }}>
          Confidentiality Warning: This message is intended only for the use of the individual or entity to which it is
          addressed, and may contain information which is privileged, confidential, propietary or exempt from disclosure
          under applicable law. if you are not the indeded recipient or the person responsible for delivering the
          msssage to the intended recipient, you are strictly prohibited from disclosing, distributing, coping or in any
          way using this message. i fyou have received this communication in error, please notify the sender and destry
          and elete any copies you may have received.
        </Text>
      </View>
    </View>
  )
}
