import React, { useState } from 'react'
import styled from 'styled-components'
import TextInput from 'components/inputs/TextInput'
import numeral from 'numeral'
import { toNumber } from 'lodash'
// LIB
import i18n from 'lib/helpers/i18n'
import moment from 'moment'

const Container = styled.div`
  display: flex;
`

const Margin = styled.div`
  margin-left: 4px;
`

const HiddenLabel = styled.label`
  display: none;
`

const Hint = styled.label`
  font-size: 16px;
  font-weight: 500;
  color: ${(p) => p.theme.colors.neutral3};
`

interface BirthdayInputProps {
  onChange: Function
  minYear?: string
  maxYear?: string
  id?: string
  value?: string
}

export default function BirthdayInput({ value, onChange, minYear = '1900', maxYear = '2020', id }: BirthdayInputProps) {
  const [values, setValues] = useState({
    month: value ? moment(value, 'MM/DD/YYYY')?.format('MM') : undefined,
    day: value ? moment(value, 'MM/DD/YYYY')?.format('DD') : undefined,
    year: value ? moment(value, 'MM/DD/YYYY')?.format('YYYY') : undefined,
  })
  const handleOnChange = (v) => {
    setValues(v)
    let birthday = `${v.month}/${v.day}/${v.year}`
    onChange(birthday)
  }

  return (
    <Container>
      <div style={{ width: '30%' }}>
        <TextInput
          type="number"
          id={id}
          title="Month"
          placeholder="MM"
          inputMode="numeric"
          value={values.month}
          min="1"
          max="12"
          data-testid="signup-birthday-month"
          onChange={(e) => {
            let month = numeral(e.target.value).format('00')
            if (month > '12' || month.length > 2) return
            handleOnChange({
              ...values,
              month,
            })
          }}
        />
        <Hint>{i18n.t('signupPage.month')}</Hint>
      </div>
      <Margin style={{ width: '30%' }}>
        <HiddenLabel htmlFor="day">day</HiddenLabel>
        <TextInput
          type="number"
          placeholder="DD"
          id="day"
          title="day"
          inputMode="numeric"
          value={values.day}
          min="1"
          max="31"
          data-testid="signup-birthday-day"
          onChange={(e) => {
            let day = numeral(e.target.value).format('00')
            if (day > '31' || day.length > 2) return
            handleOnChange({
              ...values,
              day,
            })
          }}
        />
        <Hint>{i18n.t('signupPage.day')}</Hint>
      </Margin>
      <Margin style={{ width: '40%' }}>
        <HiddenLabel htmlFor="year">year</HiddenLabel>
        <TextInput
          type="number"
          id="year"
          title="year"
          placeholder="YYYY"
          inputMode="numeric"
          value={values.year}
          min={minYear}
          max={maxYear}
          data-testid="signup-birthday-year"
          onChange={(e) => {
            let year = numeral(e.target.value).format('0000')
            if (toNumber(year) > parseInt(maxYear) || year.length > 4) {
              return handleOnChange({
                ...values,
                year: values.year || '',
              })
            }

            handleOnChange({
              ...values,
              year,
            })
          }}
        />
        <Hint>{i18n.t('signupPage.year')}</Hint>
      </Margin>
    </Container>
  )
}