import { useState } from 'react'
import styled from 'styled-components'
import { Link, RouteComponentProps } from 'react-router-dom'
// components
import FormItem from 'components/common/FormItem'
import PasswordInput from 'components/inputs/PasswordInput'
import Button from 'components/common/Button'
// COMPONENTS
import Caption from 'components/text/Caption'
import logo from 'lib/media/logo-light-bg.svg'
import message from 'components/common/message'
import TextInput from 'components/inputs/TextInput'
// LIB
import useCheckPasswordErrors from 'lib/hooks/useCheckPasswordErrors'
import constants from 'lib/config'
import {
  CurrentUserDocument,
  useAgreeToKodaTermsMutation,
  useCurrentUserLazyQuery,
  useFindResetQuery,
  useResetPasswordMutation,
} from 'generated/graphql'
import client from 'ApolloClient/index'
import i18n from 'lib/helpers/i18n'
import { Trans } from 'react-i18next'
import setLocalStorage from 'lib/helpers/setLocalStorage'
import translateApolloError from 'lib/helpers/translateApolloError'

const ButtonContainer = styled.div`
  text-align: center;
  margin-top: 16px;
`

const FormContainer = styled.form`
  width: 500px;
  padding: 16px;
  max-width: 100%;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  margin: auto;
  padding-top: 20px;
  background: #fff;
  padding: 32px 64px;
  border-radius: ${(p) => p.theme.borderRadius}px;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    padding: 20px;
  }
`

const Container = styled.div`
  align-items: center;
  padding-top: 20px;
  width: 100%;
  display: flex;
  height: 100vh;
  width: 100%;
  background: ${(p) => p.theme.colors.neutral10};
`

const Logo = styled.img`
  height: 150px;
  margin: auto;
`
const TitleContainer = styled.div`
  min-height: 95px;
  text-align: center;
`

const TermsText = styled.p`
  margin: 0px;
  margin-top: 16px;
`

const SetPassword = ({ match }: RouteComponentProps<{ token?: string }>) => {
  const { checkPasswordErrors } = useCheckPasswordErrors()
  const [agreeToKodaTermsMutation] = useAgreeToKodaTermsMutation()
  const [errors, setErrors] = useState<{
    password?: string
    confirmPassword?: string
  }>({
    password: undefined,
    confirmPassword: undefined,
  })
  const [showPassword, setShowPassword] = useState(false)
  const [agreeToTerms, setAgreeToTerms] = useState(false)
  const [getCurrentUser] = useCurrentUserLazyQuery()
  const [resetPassword] = useResetPasswordMutation()
  const findResetQuery = useFindResetQuery({
    variables: {
      token: match.params.token,
    },
  })
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmedPassword] = useState('')
  const [loading, setLoading] = useState(false)

  const onSubmit = async (e) => {
    try {
      e?.preventDefault()
      setErrors({})
      if (password !== confirmPassword) {
        return setErrors({
          confirmPassword: i18n.t('SetPassword.confirmPassword.matchError', 'Passwords do not match.'),
        })
      }
      setLoading(true)
      let res = await resetPassword({
        variables: {
          newPassword: password,
          token: match.params.token,
        },
      })

      message.success(i18n.t('SetPassword.success', 'Password reset. Logging you in...'))
      const accessToken = res?.data?.resetPassword?.tokens?.accessToken
      const refreshToken = res?.data?.resetPassword?.tokens?.refreshToken
      setLocalStorage(constants.authTokenName, accessToken)
      setLocalStorage(constants.refreshTokenName, refreshToken)
      setTimeout(async () => {
        if (agreeToTerms) {
          await agreeToKodaTermsMutation()
        }
        await client.query({
          query: CurrentUserDocument,
          fetchPolicy: 'network-only',
        })
      }, 1500)
    } catch (err: any) {
      setLoading(false)
      message.error(translateApolloError(err))
    }
  }

  if (findResetQuery.loading) return null

  if (findResetQuery.error) {
    return `${i18n.t('SetPassword.error')} ${constants.supportEmail}`
  }

  if (!findResetQuery.data) return null

  const disabled =
    /**
     * check to see if they agreed to terms, if they haven't the disabled button logic
     * will include if they've checked off/agreed to the terms.
     * If they already agreed, we just look at if the passwords a valid, match, etc
     */
    !findResetQuery.data.findReset?.agreeToTerms
      ? !password || !confirmPassword || !agreeToTerms || password !== confirmPassword
      : !password || !confirmPassword || password !== confirmPassword

  return (
    <Container onSubmit={onSubmit} id="reset-form">
      <FormContainer onSubmit={onSubmit}>
        <TitleContainer>
          <Logo src={logo} />
          <Caption style={{ margin: '16px auto' }}>{i18n.t('SetPassword.title', 'Set your password')}</Caption>
        </TitleContainer>
        <FormItem
          label={i18n.t(`SetPassword.newPassword.label`, 'New Password')}
          error={errors.password}
          hint={i18n.t(
            'SetPassword.newPassword.hint',
            'Minimum 8 characters with a number, uppercase and lowercase letter'
          )}
        >
          <PasswordInput
            value={password}
            onChange={(password) => {
              setPassword(password)
              const error = checkPasswordErrors(password)
              if (error) {
                setErrors({ password: error })
              } else {
                setErrors({ password: undefined })
              }
            }}
            placeholder={i18n.t(`SetPassword.newPassword.placeholder`)}
            onToggleShowPassword={() => setShowPassword(!showPassword)}
            type={showPassword ? 'text' : 'password'}
            showPassword={showPassword}
          />
        </FormItem>
        <FormItem
          label={i18n.t(`SetPassword.confirmPassword.label`, 'Confirm New Password')}
          error={errors.confirmPassword}
        >
          <PasswordInput
            value={confirmPassword}
            onChange={(confirmPassword) => setConfirmedPassword(confirmPassword)}
            onBlur={() => {
              if (confirmPassword !== password) {
                return setErrors({
                  confirmPassword: i18n.t(`SetPassword.confirmPassword.error`, 'Your passwords do not match'),
                })
              }
            }}
            placeholder={i18n.t(`SetPassword.confirmPassword.placeholder`)}
            onToggleShowPassword={() => setShowPassword(!showPassword)}
            type={showPassword ? 'text' : 'password'}
            showPassword={showPassword}
          />
        </FormItem>
        <FormItem label={i18n.t(`SetPassword.name`)}>
          <TextInput value={findResetQuery?.data?.findReset?.name || ''} disabled />
        </FormItem>
        <FormItem label="Email">
          <TextInput value={findResetQuery?.data?.findReset?.email || ''} disabled />
        </FormItem>
        <TermsText>
          <input type="checkbox" onChange={(e) => setAgreeToTerms(e.target.checked)} style={{ marginRight: 8 }} />
          <Trans i18nKey={'SetPassword.agreeToTerms'}>
            I agree that all of my responses are honest to the best of my ability. I agree to the
            <Link style={{ textDecoration: 'underline' }} to="/terms">
              Terms
            </Link>
            and
            <Link style={{ textDecoration: 'underline' }} to="/privacy">
              Privacy Policy
            </Link>
          </Trans>
          .
        </TermsText>
        <ButtonContainer>
          <Button disabled={disabled || loading} loading={loading ? true : undefined} onClick={onSubmit} fullWidth>
            {!loading ? i18n.t(`SetPassword.submitBtn`, 'Set password') : '...'}
          </Button>
        </ButtonContainer>
      </FormContainer>
    </Container>
  )
}

export default SetPassword
