import { useRef, useEffect } from "react";
import styled from "styled-components";
import ButtonsContainer from "../ButtonsContainer";
import QuestionText from "components/text/QuestionText";
import { QuestionsContainer, StepContainer } from "../index";
import Animated from "components/common/Animated";
import Row from "components/common/Row";
import Col from "components/common/Col";
import { Patient } from "generated/graphql";
import Button from "components/common/Button";
import { toNumber } from "lodash";
import { useTranslation } from "react-i18next";

const Input = styled.input<{ ref?: any }>`
  border: 0px;
  height: 64px;
  font-family: ${(p) => p.theme.fontFamily};
  font-size: 30px;
  font-weight: 400;
  letter-spacing: 1px;
  border: 2px solid ${(p) => p.theme.colors.neutral9};
  border-radius: 5px;
  width: 100%;
  padding: 16px;
  ::placeholder {
    color: ${(p) => p.theme.colors.neutral8};
  }

  @media only screen and (max-width: 1024px) {
    ::placeholder {
      font-size: 22px;
    }
  }
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints.small}) {
    font-size: 24px;
    ::placeholder {
      font-size: 13px;
      color: ${(p) => p.theme.colors.neutral7};
    }
  }
  &:focus {
    outline: 0;
    border: 3px solid ${(p) => p.theme.colors.primary4};
  }

  @media only screen and (max-width: 1024px) {
    &:focus {
      border: 3px solid ${(p) => p.theme.colors.primary4};
    }
  }
`;

interface AddressInputsProps {
  text?: JSX.Element | string;
  required?: boolean;
  defaultValues?: any[];
  onNext: (plan?: Patient | React.MouseEvent<HTMLButtonElement>) => void;
  onBack?: () => void;
  nextButton?: string;
  fieldName?: string;
  onChange: (e: { [x: string]: string }) => void;
  hideButtons?: boolean;
  editMode?: boolean;
  isMobile?: boolean;
  skippable?: boolean;
  inputs?: any[];
}

const QuestionTextLabel = styled(QuestionText)`
  margin-bottom: 2px;
`;

export default function TwoStringInputs({
  text,
  required,
  defaultValues = [],
  onNext,
  onBack,
  nextButton,
  fieldName,
  onChange,
  inputs,
  skippable,
  hideButtons,
  editMode,
  isMobile,
}: AddressInputsProps) {
  const textInput1 = useRef<HTMLInputElement | undefined>(null);
  const { t } = useTranslation();
  useEffect(() => {
    textInput1?.current?.focus();
  }, []);

  const hideNext = required && (!defaultValues[0] || !defaultValues[1]);

  const handleOnNext = () => {
    // if all is good, we can call onNext to move to the next question
    onNext();
  };

  return (
    <StepContainer key={fieldName} editMode={editMode} isMobile={isMobile}>
      <div style={{ maxWidth: "100%", position: "relative" }}>
        <Animated>
          {!hideNext && !isMobile && (
            <div
              style={{
                position: "absolute",
                right: 0,
                top: -80,
              }}
            >
              <Button
                onClick={handleOnNext}
                pulse
                style={{
                  minWidth: 112,
                }}
              >
                {t("button.continue")}
              </Button>
            </div>
          )}

          <QuestionText data-testid="text-input-overall-question-text">
            {text}
          </QuestionText>
          <QuestionsContainer
            style={{ width: !isMobile ? 620 : 400, maxWidth: "100%" }}
          >
            <div>
              <QuestionTextLabel>
                {t("addressInput.street", "Address")}:
              </QuestionTextLabel>
              <Input
                ref={textInput1}
                className="fs-exclude"
                defaultValue={defaultValues[0]}
                placeholder={t("typeHere", "Type your answer here")}
                onChange={(e) => {
                  // for all other inputs, just save the values
                  onChange({ [inputs?.[0].fieldName]: e.target.value });
                }}
              />
              <QuestionTextLabel style={{ marginTop: 24 }}>
                {t("addressInput.city", "City")}:
              </QuestionTextLabel>
              <Input
                className="fs-exclude"
                placeholder={t("typeHere", "Type your answer here")}
                defaultValue={defaultValues[1]}
                onChange={(e) => {
                  // for all other inputs, just save the values
                  onChange({ [inputs?.[1].fieldName]: e.target.value });
                }}
              />

              <Row gutter={16} style={{ marginTop: 24 }}>
                <Col xs={12}>
                  <QuestionTextLabel>
                    {t("addressInput.state", "State")}:
                  </QuestionTextLabel>
                  <Input
                    defaultValue={defaultValues[2]}
                    placeholder={t("typeHereShort", "Type here")}
                    className="fs-exclude"
                    onChange={(e) => {
                      // for all other inputs, just save the values
                      onChange({ [inputs?.[2].fieldName]: e.target.value });
                    }}
                  />
                </Col>
                <Col xs={12}>
                  <QuestionTextLabel>
                    {t("addressInput.zipCode", "Zip Code")}:
                  </QuestionTextLabel>
                  <Input
                    type="tel"
                    className="fs-exclude"
                    placeholder={t("typeHereShort", "Type here")}
                    pattern="[0-9]*"
                    value={defaultValues[3]}
                    max="99999"
                    data-testid="signature-zip-code"
                    onChange={(e) => {
                      // only save numbers
                      if (
                        !isNaN(toNumber(e.target.value)) &&
                        e.target.value?.length <= 5
                      ) {
                        return onChange({
                          [inputs?.[3].fieldName]: e.target.value,
                        });
                      }
                    }}
                  />
                </Col>
              </Row>
            </div>
          </QuestionsContainer>
        </Animated>
        <ButtonsContainer
          onNext={handleOnNext}
          onBack={onBack}
          onSkip={onNext}
          hideButtons={hideButtons}
          nextButton={nextButton}
          hideNext={hideNext}
          showSkipButton={skippable}
        />
      </div>
    </StepContainer>
  );
}
