// TOP LEVEL IMPORTS
import { useState, useEffect } from 'react'
import styled from 'styled-components'
// COMPONENTS
import Button from 'components/common/Button'
import TextInput from 'components/inputs/TextInput'
import Caption from 'components/text/Caption'
import FormItem from 'components/common/FormItem'
// APOLLO
import ApolloClient from 'ApolloClient'
// LIB
import constants from 'lib/config'
import { AnalyticEventTypeEnums } from 'lib/types/AnalyticEventTypeEnums'
import { useResendMfaMutation, useVerifyMfaMutation } from 'generated/graphql'
import useUrlChange from 'lib/hooks/useUrlChange'
import ButtonLink from 'components/common/ButtonLink'
import message from 'components/common/message'
import i18n from 'lib/helpers/i18n'
import logEvent from 'lib/helpers/logEvent'
import setLocalStorage from 'lib/helpers/setLocalStorage'
import translateApolloError from 'lib/helpers/translateApolloError'

const TitleContainer = styled.div`
  min-height: 95px;
  text-align: center;
`

const PageHeader = styled.span`
  min-height: 44px;
  font-family: ${(p) => p.theme.fontFamily};
  font-size: 24px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 1px;
  display: block;
  color: ${(p) => p.theme.colors.neutral3};
  @media only screen and (max-width: 812px) {
    font-size: 20px;
  }
`

const Container = styled.div`
  align-items: center;
  padding-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: ${(p) => p.theme.colors.neutral10};
  height: 100vh;
`

const Content = styled.div`
  width: 400px;
  margin: auto;
  display: flex;
  align-items: center;
  background: #fff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  min-height: 500px;
  border-radius: 7px;
  padding: 16px;
  max-width: 100%;
`

// STYLED-COMPONENTS
// ========================================

const ErrorBlock = styled.div`
  border: 1px solid ${(p) => p.theme.colors.red5};
  background: ${(p) => p.theme.colors.red10};
  padding: 8px;
  color: ${(p) => p.theme.colors.red2};
  border-radius: 5px;
  margin-top: 8px;
`

export default function AuthMFA() {
  const [pin, setPin] = useState('')
  const [errors, setErrors] = useState<null | string[]>(null)
  const [verifyMfaMutation, { loading }] = useVerifyMfaMutation()
  const [resendMfaMutation, { loading: resending }] = useResendMfaMutation()
  const {
    query: { email },
  } = useUrlChange()

  useEffect(() => {
    logEvent(AnalyticEventTypeEnums.LOGIN_PAGE)
  }, [])

  const onSubmit = async (e) => {
    try {
      if (loading) return

      e.preventDefault()
      // // reset errors
      setErrors([])

      // check that they give a password
      if (!pin || pin.length === 0) {
        return setErrors([i18n.t('AuthMFA.providePinError', 'Please provide a pin')])
      }

      if (!email) return

      const { data } = await verifyMfaMutation({
        variables: {
          email: email as string,
          pin,
        },
      })

      setLocalStorage(constants.authTokenName, data?.verifyMfa?.tokens?.accessToken)
      setLocalStorage(constants?.refreshTokenName, data?.verifyMfa?.tokens?.refreshToken)

      await ApolloClient.resetStore()
    } catch (err: any) {
      setErrors([translateApolloError(err)])
      return
    }
  }

  return (
    <Container>
      <Content>
        <div
          style={{
            marginTop: 32,
            marginLeft: 16,
          }}
        >
          <TitleContainer>
            <PageHeader>{i18n.t('AuthMFA.title', 'Verify Your Identity')}</PageHeader>
            <Caption style={{ width: '90%', margin: 'auto' }}>
              {i18n.t(
                'AuthMFA.subtitle',
                'A verification code has been sent to your email. Enter the code to continue and be redirected.'
              )}
            </Caption>
          </TitleContainer>
          <form onSubmit={onSubmit}>
            <FormItem label={i18n.t('AuthMFA.code', `Code`)}>
              <TextInput onChange={(e) => setPin(e.target.value.toLowerCase()?.trim())} value={pin} />
            </FormItem>
            {errors &&
              errors?.map((item) => (
                <ErrorBlock key={item} data-testid="error-block">
                  {item.replace('error: ', '')}
                </ErrorBlock>
              ))}
            <FormItem>
              <Button disabled={loading} loading={loading} type="submit" data-testid="login-submit-btn" fullWidth>
                {i18n.t('button.submit')}
              </Button>
            </FormItem>
          </form>
          <div style={{ marginTop: 16, textAlign: 'center' }}>
            <Caption style={{ marginTop: 16 }}>
              {i18n.t('AuthMFA.needNewCode', ` Need a new code?`)}
              <ButtonLink
                onClick={async () => {
                  try {
                    if (resending) return
                    await resendMfaMutation({
                      variables: {
                        email: email as string,
                      },
                    })
                    message.success(i18n.t('AuthMFA.sentNewCode', `We've sent you a new code`))
                  } catch (err: any) {
                    message.error(translateApolloError(err))
                  }
                }}
              >
                {i18n.t('AuthMFA.sendNewCode', ` Send new code`)}
              </ButtonLink>
            </Caption>
          </div>
          <div style={{ marginTop: 16, textAlign: 'center' }}>
            <Caption style={{ marginTop: 16 }}>
              {i18n.t('AuthMFA.needHelp', ` Need help? Call us at`)}
              <a href={`tel:+${constants.supportPhone.replace(/-/g, '')}`}>{constants.supportPhone}</a>
            </Caption>
          </div>
        </div>
      </Content>
    </Container>
  )
}
