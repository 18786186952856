import { GuideQuestionIdEnum, NotWorthLivingEnum, GuideTemplateSectionIdEnum } from 'generated/graphql'
// import { TFunctionDetailedResult } from 'i18next'

/*
 * These question items are an integral part to the frontend and tell the application which question template to render and the settings for it (hide this button, what field value it should save to, etc)
 */

export enum QuestionType {
  'select-columns' = 'select-columns',
  'select-icons' = 'select-icons',
  'section-break' = 'section-break',
  'persona' = 'persona',
  'video' = 'video',
  'explainer' = 'explainer',
  'select' = 'select',
  'slider' = 'slider',
  'string' = 'string',
  'textarea' = 'textarea',
  'two-strings' = 'two-strings',
  'end' = 'end',
  'agreeToTerms' = 'agreeToTerms',
  'birthday' = 'birthday',
  'address' = 'address',
}

export enum StepNameEnum {
  'learnAboutCPR' = 'learnAboutCPR',
}
// TFunctionDetailedResult<object>
export type PersonaQuestionOption = {
  label?: string | JSX.Element | any
  value: number
}

export type QuestionItemOption = {
  id?: NotWorthLivingEnum | string
  label?: string | JSX.Element | any
  img?: string
}

export enum AnimationInEnum {
  'fadeIn' = 'fadeIn',
}

export enum ExplainerSectionEnum {
  MECHANICAL_VENTILATION = 'MECHANICAL_VENTILATION',
  CPR = 'CPR',
  FEEDING_TUBE = 'FEEDING_TUBE',
  DIALYSIS = 'DIALYSIS',
  PALLIATIVE_CARE = 'PALLIATIVE_CARE',
  HOSPICE_CARE = 'HOSPICE_CARE',
}

export type QuestionItem = {
  type: QuestionType | string // String: The type of question format ['select-columns', 'persona', 'video', 'select-icons', 'section-break]
  section?: GuideTemplateSectionIdEnum // String: which section/module the question belongs to [ABOUT_ME, QUALITY_OF_LIFE, MEDICAL_CARE, MEDICAL_DECISION_MAKER]
  fieldName?: GuideQuestionIdEnum | string // String: This is improtant. It's the name of the field... i.e. the key that the value will be saved in the databse as
  otherFieldName?: GuideQuestionIdEnum | string
  fieldNames?: string[]
  stepName: StepNameEnum | string | any // String: A name for the step... used for analytics and other ancilliary things
  text?: string | JSX.Element // React Component: This is the main question text... can be either a string or react component
  directions?: string | JSX.Element // React Component: This is the directions text... can be either a string or react component
  shortText?: string //  A shorter peice of text used in place of the "text" option...
  videoId?: string // If the step is of a video, provide the vimeo video ID
  options?: QuestionItemOption[] | PersonaQuestionOption[]
  button: string // String: The button text for the "next" button
  required?: boolean // Boolean: whether the question must be answered before continuing or not
  multiple?: boolean // Boolean: Whether the questions, if a select (select-columns, select-icons, etc) is multiple-choice
  skippable?: boolean // Typically used for type=video, determines if the video must be watched or is skippable
  animationIn?: AnimationInEnum
  scenario?: string
  scenarioImg?: string
  placeholder?: string
  canBeNone?: boolean
  img?: string
  /** tell us how to align the button*/
  buttonAlign?: string // should be enum
  inputType?: string // should be enum
  inputs?: any[] // should have specific interface
  defaultValue?: any
  actualIndex?: number // we're later going to remove some questions, so we need to know the actual index of the question prior to that
}
