// TOP LEVEL IMPORTS
import { gql } from '@apollo/client'
import currentUserFragment from 'ApolloClient/Fragments/currentUserFragment'

export default gql`
  query currentUser {
    currentUser {
      ...currentUserFragment
    }
  }
  ${currentUserFragment}
`
