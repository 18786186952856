import { createGlobalStyle } from 'styled-components'
import theme from './theme'

export const GlobalStyle = createGlobalStyle`


  /* TOP LEVEL CSS */

  div, a, h1, h2, h3, h4, h5, p, span {
    font-family: ${theme.fontFamily};
    font-size: 16px;
  }

  html, body {
    margin: 0px;
    height: 100%;
    width: 100%;
    max-width: 100%;
    /* overflow-x: auto; */
    /* overflow-y: scroll; */
    -webkit-overflow-scrolling: touch;
  }

  a {
    color: ${theme.colors.supportB3} !important;
    text-decoration: underline !important;
  }

  #root {
    height: 100%;
    width: 100vw;
  }

  .ant-calendar-column-header-inner {
    color: #fff !important;
  }

  .ant-calendar-picker-input {
    height: 52px !important;
    background: ${theme.colors.neutral10} !important;
    border: 0px !important;
  }

  .ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month {
    background: ${theme.colors.primary5} !important;
    color: #fff !important;
  }

  /* CALENDAR STYLES OVERWRITING ANTD CALENDAR DEFAULT STYLES */

  .ant-calendar-month-panel-tbody {
    font-family: ${theme.fontFamily} !important;
  }

  .ant-progress-inner {
    background-color: ${theme.colors.neutral8} !important;
  }

  .ant-calendar-header a:hover {
    color: ${theme.colors.primary1} !important;
  }



  .ant-popover-buttons {
    .ant-btn:hover, .ant-btn:focus {
      color: ${theme.colors.primary1}  !important;
      background-color: #fff;
      border-color: ${theme.colors.primary1} !important;
    }
    .ant-btn.ant-btn-primary.ant-btn-sm{
      background: ${theme.colors.primary1} !important;
      border-color: ${theme.colors.primary1} !important;
      color: #fff !important;
    }
  }

  
  /* ================================================
   RANGE PICKER STYLES
  ==================================================== */

  .ant-calendar-range .ant-calendar-selected-start-date .ant-calendar-date {
    background: ${theme.colors.primary1} !important;
  }

  a.ant-calendar-month-select, a.ant-calendar-year-select {
    color: ${theme.colors.primary1} !important;
  }

  .ant-calendar-range .ant-calendar-selected-end-date .ant-calendar-date {
    background: ${theme.colors.primary1} !important;
  }

  .ant-calendar-range .ant-calendar-in-range-cell::before {
    background: ${theme.colors.primary10} !important;
  }

  .ant-calendar-today .ant-calendar-date {
    border-color: ${theme.colors.primary1} !important;
  }


  /* ================================================
    TABLE STYLES OVERWRITING ANTD TABLE DEFAULT STYLES 
  ==================================================== */

  .ant-table-tbody {
    background: #fff !important;
  }



  .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
    background: ${theme.colors.primary10} !important;
  }

  .ant-pagination-item a {
    color: ${theme.colors.primary3} !important;
  }

  .ant-pagination-item-active {
    border-color: ${theme.colors.primary7} !important;
  }

  .ant-pagination-item:hover {
        border-color: ${theme.colors.primary7} !important;
  }

  .ant-pagination-prev a, .ant-pagination-next a {
    color: ${theme.colors.primary7} !important;
  }


  .ant-table-thead > tr > th {
    color: ${theme.colors.neutral5} !important;
  }

  .ant-table {
    font-size: 12px !important
  }

`

export default theme
