import type { ReactNode } from 'react'
import styled from 'styled-components'

const CheckboxTextContainer = styled.div<{
  backgroundColor?: string
  isError?: boolean
  checked?: boolean
}>`
  border: 1px solid
    ${({ theme, backgroundColor, checked }) =>
      backgroundColor ? backgroundColor : checked ? theme.colors.primary6 : theme.colors.neutral9};
  background-color: ${({ theme, backgroundColor, checked }) =>
    backgroundColor ? backgroundColor : checked ? theme.colors.primary9 : theme.colors.neutral10};
  padding: 16px 8px;
  border-radius: 5px;
  line-height: 120%;
  font-size: 14px;
  margin-bottom: ${({ isError }) => (isError ? '0' : '16px')};
  margin-top: 8px;
  display: flex;
  cursor: pointer;
  align-items: flex-start;
  &:hover {
    background-color: ${({ theme, backgroundColor, checked }) =>
      backgroundColor ? backgroundColor : checked ? theme.colors.primary9 : theme.colors.neutral9};
  }
`

const CheckoutContainer = styled.label`
  display: flex;
  align-items: center;
  position: relative;
  pointer-events: none;
  .check_input {
    position: absolute;
    width: 0px;
    height: 0px;
    border: 0;
    padding: 0;
    visibility: hidden;
  }
  .check_box {
    display: inline-block;
    width: 18px;
    height: 18px;
    overflow: hidden;

    border: 1px solid ${({ theme }) => theme.colors.neutral7};
    border-radius: 3px;
    background-color: #fff;
  }
  /* Checked */
  .check_input:checked + .check_box:after {
    content: '';
    position: absolute;
    left: 4px;
    top: 8px;
    background: blue;
    width: 2px;
    height: 2px;
    box-shadow: 2px 0 0 blue, 4px 0 0 blue, 4px -2px 0 blue, 4px -4px 0 blue, 4px -6px 0 blue, 4px -8px 0 blue;
    transform: rotate(45deg);
  }

  /* Disabled */
  .check_input:disabled + .check_box {
    border: 1px solid ${({ theme }) => theme.colors.neutral9};
  }

  /*Checked disabled */
  .check_input:checked:disabled + .check_box {
    background-color: ${({ theme }) => theme.colors.neutral7};
  }
`

interface CheckboxTextProps {
  backgroundColor?: string
  children: ReactNode
  checked: boolean
  onChange: (boolean) => void
  style?: React.CSSProperties
  isError?: boolean
  dataTestId?: string
}

const CheckboxText = ({
  children,
  checked,
  onChange,
  backgroundColor,
  style,
  isError,
  dataTestId,
}: CheckboxTextProps) => {
  return (
    <CheckboxTextContainer
      onClick={() => onChange(!checked)}
      backgroundColor={backgroundColor}
      style={style}
      isError={isError}
      checked={checked}
      data-testid={dataTestId}
      role="button"
    >
      <div style={{ marginRight: 8 }}>
        <CheckoutContainer>
          <input className="check_input" type="checkbox" checked={checked} />
          <span className="check_box"></span>
        </CheckoutContainer>
      </div>
      {children}
    </CheckboxTextContainer>
  )
}

export default CheckboxText
