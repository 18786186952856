import { View } from '@react-pdf/renderer'
import TextField from '../../TextField'
import i18n from 'lib/helpers/i18n'

interface MdmInfoProps {
  fullName?: string
  email?: string
  phone?: string
  relationship?: string
  street?: string
  city?: string
  state?: string
  zip?: string
}

const MdmInfo = ({ fullName, email, phone, relationship, street, city, state, zip }: MdmInfoProps) => {
  const STYLE = {
    marginTop: 2,
    borderTopWidth: 2,
  }

  return (
    <>
      <View style={{ display: 'flex', flexDirection: 'row' }}>
        <TextField flex={2} label={i18n.t('MasterPlanDocMdmInfo.fullName')} value={fullName} style={STYLE} />
        <TextField label={i18n.t('MasterPlanDocMdmInfo.relationship')} value={relationship} style={STYLE} />
      </View>
      <View style={{ display: 'flex', flexDirection: 'row' }}>
        <TextField flex={2} label={i18n.t('MasterPlanDocMdmInfo.email')} value={email} style={STYLE} />
        <TextField flex={1} label={i18n.t('MasterPlanDocMdmInfo.phone1')} value={phone} style={STYLE} />
        {/* <TextField label="Phone #2" /> */}
      </View>
      <View style={{ display: 'flex', flexDirection: 'row' }}>
        <TextField flex={2} label={i18n.t('MasterPlanDocMdmInfo.street')} value={street} style={STYLE} />
        <TextField label={i18n.t('MasterPlanDocMdmInfo.city')} value={city} style={STYLE} />
        <TextField label={i18n.t('MasterPlanDocMdmInfo.state')} value={state} style={STYLE} />
        <TextField label={i18n.t('MasterPlanDocMdmInfo.zipCode')} value={zip} style={STYLE} />
      </View>
    </>
  )
}

export default MdmInfo
