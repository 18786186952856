import { CloseOutlined } from '@ant-design/icons'
import theme from 'lib/theme'
import { MouseEvent } from 'react'

interface ExitButtonProps {
  onClick: (e?: MouseEvent) => void
  buttonStyles?: React.CSSProperties
  styles?: React.CSSProperties
  iconStyles?: React.CSSProperties
}

export default function ExitButton({ onClick, buttonStyles, styles, iconStyles }: ExitButtonProps) {
  return (
    <div
      style={{
        position: 'absolute',
        right: 16,
        top: 16,
        height: 32,
        width: 32,
        backgroundColor: '#fff',
        ...styles,
      }}
    >
      <button
        onClick={onClick}
        aria-label="close"
        data-testid="exit-button"
        style={{
          background: '#fff',
          height: 32,
          width: 32,
          border: `1px solid ${theme.colors.neutral9}`,
          borderRadius: 5,
          cursor: 'pointer',
          padding: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          ...buttonStyles,
          backgroundColor: '#fff',
        }}
      >
        <CloseOutlined style={{ fontSize: 20, ...iconStyles }} />
      </button>
    </div>
  )
}
