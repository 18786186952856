// TOP LEVEL IMPORTS
import {gql} from '@apollo/client';

export default gql`
  fragment userFragment on UserProfile {
    id
    email
    roles
    lang
    firstName
    lastName
    phoneNumber
    createdAt
    permission {
      id
      title
      accesses {
        id
        title
      }
    }
  }
`;