import { Text, Image, View } from '@react-pdf/renderer'
import s from '../../../KodaContent/styles'
import theme from 'lib/theme'
import cprPNG from 'components/common/PlanDoc/imgs/cpr.png'
import breathingPNG from 'components/common/PlanDoc/imgs/breathing.png'
import feedingPNG from 'components/common/PlanDoc/imgs/feeding.png'
import dialysisPNG from 'components/common/PlanDoc/imgs/dialysis.png'
import { QuestionItem } from 'lib/types/QuestionItem'
import { GuideTemplateSectionIdEnum, Patient } from 'generated/graphql'
import i18n from 'lib/helpers/i18n'
import CheckRow from 'components/common/PlanDoc/KodaContent/CheckRow'
import checkIfGuideIncludesQuestions, { GUIDE_QUESTION_SECTIONS } from 'lib/helpers/checkIfGuideIncludesQuestions'

interface TreatmentBlockProps {
  icon?: string
  title?: string
  question?: QuestionItem
  value?: string
  dataTestId?: string
  style?: object
}

const TreatmentBlock = ({ icon, title, question, value, dataTestId, style }: TreatmentBlockProps) => {
  const ICON_SIZE = 24

  return (
    <View
      style={{
        padding: 4,
        paddingBottom: 0,
        marginBottom: 8,
        ...style,
      }}
      wrap
    >
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '97%',
          alignItems: 'center',
        }}
      >
        <View
          style={{
            width: ICON_SIZE + 16,
          }}
        >
          {icon ? (
            <Image
              src={icon}
              style={{
                height: ICON_SIZE,
                width: ICON_SIZE,
                backgroundColor: theme.colors.supportB10,
                borderRadius: ICON_SIZE / 2,
                margin: 'auto',
              }}
            />
          ) : (
            <View
              style={{
                height: ICON_SIZE,
                width: ICON_SIZE,
                backgroundColor: theme.colors.supportB10,
                borderRadius: ICON_SIZE / 2,
                margin: 'auto',
              }}
            />
          )}
        </View>
        <View style={{ flex: 8 }}>
          <Text
            data-testid={dataTestId}
            style={{
              ...s.text,
              margin: 0,
              fontSize: 12,
              fontFamily: 'Roboto Bold',
            }}
          >
            {title}
          </Text>
        </View>
      </View>
      {question && question.options
        ? question.options.map((option, i) => {
            const checked = value === option.id
            return (
              <CheckRow
                key={`${option?.value}${i}`}
                label={option.label}
                checked={checked}
                id={option?.id}
                value={value}
                highlightChecked
              />
            )
          })
        : null}
    </View>
  )
}

const getQuestionsHashMap = (questions) => {
  let hashMap = {}
  questions.forEach((q) => {
    hashMap[q.fieldName] = q
  })

  return hashMap
}

interface MedicalCareTreatmentTodayProps {
  data?: Patient
  questions?: QuestionItem[]
}

export const MedicalCareTreatmentToday = ({ data, questions }: MedicalCareTreatmentTodayProps) => {
  const hashMap = getQuestionsHashMap(questions)

  const guideHasDialysis =
    data?.guideTemplate &&
    checkIfGuideIncludesQuestions(
      GuideTemplateSectionIdEnum.MedicalCare,
      GUIDE_QUESTION_SECTIONS.dialysis,
      data?.guideTemplate
    )

  return (
    <View style={s.sectionContainer} wrap break>
      <Text style={{ ...s.sectionTitle, marginTop: 2, marginBottom: 0 }}>
        {i18n.t('MasterPlanDocMedicalCareTreatmentToday.title', 'Medical Care Treatment - Today in my Current Health')}
      </Text>
      <Text style={s.text}>
        {i18n.t(
          'MasterPlanDocMedicalCareTreatmentToday.subtitle',
          'Today in my current health, I direct that medically appropriate treatment be provided as follows.'
        )}
      </Text>
      <TreatmentBlock
        value={data?.cprPreferenceToday || ''}
        icon={cprPNG}
        title={i18n.t('MasterPlanDocMedicalCareTreatmentToday.cprPreference', 'CPR (Cardiopulmonary Resuscitation):')}
        question={hashMap['cprPreferenceToday']}
      />
      <TreatmentBlock
        icon={breathingPNG}
        question={hashMap['ventilationPreferenceToday']}
        value={data?.ventilationPreferenceToday || ''}
        title={i18n.t(
          'MasterPlanDocMedicalCareTreatmentToday.ventilationPreference',
          'Ventilator (Breathing Machine):'
        )}
      />
      <TreatmentBlock
        question={hashMap['longTermFeedingToday']}
        value={data?.longTermFeedingToday || ''}
        icon={feedingPNG}
        title={i18n.t('MasterPlanDocMedicalCareTreatmentToday.longTermFeeding', 'Feeding Tube:')}
      />
      {guideHasDialysis && (
        <TreatmentBlock
          value={data?.dialysisTreatmentToday || ''}
          question={hashMap['dialysisTreatmentToday']}
          icon={dialysisPNG}
          dataTestId="dialysisTreatmentToday"
          title={i18n.t('MasterPlanDocMedicalCareTreatmentToday.dialysisTreatment', 'Dialysis:')}
        />
      )}
    </View>
  )
}

interface MedicalCareTreatmentUnacceptableProps {
  data?: Patient
  questions?: QuestionItem[]
}

export const MedicalCareTreatmentUnacceptable = ({ data, questions }: MedicalCareTreatmentUnacceptableProps) => {
  const hashMap = getQuestionsHashMap(questions)

  const guideHasDialysis =
    data?.guideTemplate &&
    checkIfGuideIncludesQuestions(
      GuideTemplateSectionIdEnum.MedicalCare,
      GUIDE_QUESTION_SECTIONS.dialysis,
      data?.guideTemplate
    )

  return (
    <View style={s.sectionContainer} wrap break>
      <Text style={{ ...s.sectionTitle, marginTop: 2, marginBottom: 0 }}>
        {i18n.t(
          'MedicalCareTreatmentUnacceptable.title',
          'Medical Care Treatment - When Quality of Life is Unacceptable'
        )}
      </Text>
      <Text style={s.text}>{i18n.t('MedicalCareTreatmentUnacceptable.subtitle')}</Text>
      <TreatmentBlock
        value={data?.cprPreference || ''}
        question={hashMap['cprPreference']}
        icon={cprPNG}
        title={i18n.t('MasterPlanDocMedicalCareTreatmentToday.cprPreference', 'CPR (Cardiopulmonary Resuscitation):')}
      />
      <TreatmentBlock
        icon={breathingPNG}
        question={hashMap['ventilationPreference']}
        value={data?.ventilationPreference || ''}
        title={i18n.t(
          'MasterPlanDocMedicalCareTreatmentToday.ventilationPreference',
          'Ventilator (Breathing Machine):'
        )}
      />
      <TreatmentBlock
        value={data?.longTermFeeding || ''}
        question={hashMap['longTermFeeding']}
        icon={feedingPNG}
        title={i18n.t('MasterPlanDocMedicalCareTreatmentToday.longTermFeeding', 'Feeding Tube:')}
      />
      {guideHasDialysis && (
        <TreatmentBlock
          value={data?.dialysisTreatment || ''}
          question={hashMap['dialysisTreatment']}
          icon={dialysisPNG}
          dataTestId="dialysisTreatment"
          title={i18n.t('MasterPlanDocMedicalCareTreatmentToday.dialysisTreatment', 'Dialysis:')}
        />
      )}
    </View>
  )
}
