import i18n from 'lib/helpers/i18n'

export default function useCheckPhoneNumberErrors() {

    const checkPhoneNumberErrors =(phoneNumber: string): string | null => {
        if (!phoneNumber) {
            return i18n.t('PhoneNumberError.providePhoneNumber', 'Please provide your phone number.')
        } else if (phoneNumber.length !== 14) {
            return i18n.t('PhoneNumberError.provideValidPhoneNumber', 'Please provide a valid phone number.')
        } else if(!phoneNumber.match(/^(\(?\d\d\d\)?)?( |-|\.)?\d\d\d( |-|\.)/)){
            return i18n.t('PhoneNumberError.provideValidPhoneNumber', 'Please provide a valid phone number.')
        }
        return null
    }
    return [checkPhoneNumberErrors] as const
}