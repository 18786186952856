import { useState } from 'react'
import { omit } from 'lodash'
import { Patient, PatientInput, useUpdatePatientMutation } from 'generated/graphql'
import translateApolloError from 'lib/helpers/translateApolloError'

const DEFAULT_PROPS = {
  refetchQueries: [],
}

export const saveFieldsToRemove = [
  // delete some fields that we can't pass into our mutation because they're not defined in the schema
  'id',
  'decisionMakerName',
  '__typename',
  'updatedAt',
  'clinicId',
  'slug',
  'createdAt',
  'stateVersion',
  'sharedPlan',
  'planShares',
  'clinic',
  'completedAt',
  'hasOfficialPlan',
  'userId',
  // remove signature fields too
  'patientSignatureFirstName',
  'patientSignatureLastName',
  'patientSignatureDate',
  'address',
  'city',
  'postal',
  'age',
  'phoneNumber',
  'witness1SignatureFirstName',
  'witness1SignatureLastName',
  'witness1SignatureEmail',
  'witness1SignatureDate',
  'witness1LocationType',
  'witness2SignatureFirstName',
  'witness2SignatureLastName',
  'witness2SignatureEmail',
  'witness2SignatureDate',
  'witness2LocationType',
  'guideTemplate',
  'readiness',
  'actionHistory',
]
const useSavePatient = ({ refetchQueries } = DEFAULT_PROPS) => {
  const [saving, setSaving] = useState(false)
  const [updatePatient] = useUpdatePatientMutation()

  // used to save changes to the user's survey/patient record
  const onSavePatient = async (newData: Partial<Patient>) => {
    try {
      let planId = newData.id

      let params: PatientInput = omit(newData, saveFieldsToRemove)

      // set saving true so we see the loader
      setSaving(true)
      if (!planId) return
      await updatePatient({
        variables: {
          patientId: planId,
          params,
        },
        refetchQueries,
      })
      // this will show the saving... loading in the top right corner a little longer and will look a little smoother... otherwise it flashes on the screen so fast you can't read it.
      setTimeout(() => {
        setSaving(false)
      }, 750)
    } catch (err: any) {
      console.log(translateApolloError(err))
    }
  }

  return {
    saving,
    onSavePatient,
  }
}

export default useSavePatient
