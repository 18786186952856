import styled from 'styled-components'
import { Link } from 'react-router-dom'
import theme from 'lib/theme/theme'

export const Title = styled.h1`
  font-size: 24px;
  margin-bottom: 0px;
  color: ${(p) => p.theme.colors.neutral1};
`
export const BottomText = styled.label`
  display: block;
  color: ${(p) => p.theme.colors.neutral1};
  color: #1F2933;
  font-size: 16px;
  margin-top: 8px
  a {
    font-size: 16px;
  }
`

export const LinkButton = styled.span`
  text-decoration: underline;
  display: inline-block;
  font-size: 16px;
  font-family: ${({ theme }) => theme.fontFamily};
  color: ${({ theme }) => theme.colors.primary - 2};
  color: #01337D;
  margin: 0px;
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.colors.neutral2};

  }
  `
export const Header = styled.div`
position: fixed;
top: 0px;
height: 64px;
right: 0px;
left: 0px;
background: #fff;
z-index: 10;
display: flex;
align-items: center;
justify-content: center;
@media only screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
  margin-bottom: 0px;
}
`
export const HeaderContainer = styled.div`
@media only screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
  background: white;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  height: 80px;
}
`
export const Logo = styled.img`
max-width: 90%;
margin: auto;
margin-bottom: 36px;
margin-top: 40px;
display: block;
@media only screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
  margin-top: 80px;
  margin-bottom: 0px;
}
`

export const BottomLink = styled(Link)`
font-size: 16px;
text-decoration: underline;
color: ${(p) => p.theme.colors.primary2};
&:hover {
  text-decoration: underline;
}
`
export const HintLink = styled.span`
  font-family: ${(p) => p.theme.fontFamily};
  color: ${(p) => p.theme.colors.supportB3};
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  padding: 0px;
  margin: 0px;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0px;
  border: 0px;
  cursor: pointer;
  outline: 0;
  background: transparent;
  &:hover {
    color: ${(p) => p.theme.colors.supportB3};
  }
`

export const Caption = styled.h3`
  font-size: 18px;
  margin: 0px;
  color: ${(p) => p.theme.colors.neutral5};
`

export const BottomBtn = styled(HintLink)`
  font-size: 16px;
  text-decoration: underline;
`

export const CaptionLink = styled(Link)`
font-family: 'Roboto';
font-style: normal;
font-size: 16px;
line-height: 21px;
text-decoration-line: underline;
color: #01337D;
  &:hover {
    text-decoration: underline;
  }
`
export const Checkbox = styled.input``

export const ButtonContainer = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: flex-start;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    div,
    button {
      width: 120px !important;
    }
  }
`

export const CaptionsText = styled.span`
margin: 0px;
display: block;
color: #52606D;
font-size: 16px;
margin-bottom: 32px;
margin-top: 8px;
a {
  font-size: 16px;
}
`

export const ContinueAsGuestText = styled.span`
font-family: Roboto,
font-style: normal;
font-weight: 400;
word-break: keep-all;
white-space: nowrap;
font-size: 18px;
letter-spacing: 0;
line-height: 21px;
@media only screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
white-space: unset;
letter-spacing: 0;
word-wrap: break-word;
white-space: inital;
}
`