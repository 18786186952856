import { GuideQuestionIdEnum, GuideTemplate, GuideTemplateSectionIdEnum } from 'generated/graphql'

export const GUIDE_QUESTION_SECTIONS = {
  dialysis: [
    GuideQuestionIdEnum.VideoDialysis,
    GuideQuestionIdEnum.LearnAboutDialysis,
    GuideQuestionIdEnum.DialysisTreatmentToday,
    GuideQuestionIdEnum.DialysisTreatment,
  ],
}

export default function checkIfGuideIncludesQuestions(
  sectionId: GuideTemplateSectionIdEnum,
  questionIds: GuideQuestionIdEnum[],
  guideTemplate: GuideTemplate
) {
  if (!guideTemplate) return null
  const guideQuestionIds = guideTemplate?.sections?.find((section) => section.sectionId === sectionId)?.questionIds
  return questionIds?.every((questionIdEnum) => guideQuestionIds?.includes(questionIdEnum))
}
