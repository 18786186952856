import { Text, View } from '@react-pdf/renderer'
// helpers
import s from 'components/common/PlanDoc/KodaContent/styles'
import Checkbox from 'components/common/PlanDoc/KodaContent/Checkbox'
import { QuestionItem } from 'lib/types/QuestionItem'
import { GuideQuestionIdEnum, Patient } from 'generated/graphql'
import BulletText from 'components/common/PlanDoc/KodaContent/BulletText'
import i18n from 'lib/helpers/i18n'
import { Trans } from 'react-i18next'

const CheckboxRow = ({
  checked,
  label,
  otherValue,
  hasOtherField,
}: {
  checked: boolean
  label?: string
  otherValue?: string
  hasOtherField?: boolean
}) => {
  return (
    <View style={{ marginTop: 4, paddingRight: 12 }}>
      <Checkbox checkboxStyle={{ marginTop: 3 }} checked={checked}>
        <Text style={s.formLabel}>
          {label} {!hasOtherField ? null : otherValue || '_____________________________________'}
        </Text>
      </Checkbox>
    </View>
  )
}

interface MedicalCareScenariosProps {
  data: Patient
  questions?: QuestionItem[]
}

export const MedicalCareScenariosPage1 = ({ questions }: MedicalCareScenariosProps) => {
  const scenarioQuestions = [
    GuideQuestionIdEnum.MedicalCareScenario1,
    GuideQuestionIdEnum.MedicalCareScenario2,
    GuideQuestionIdEnum.MedicalCareScenario3,
    GuideQuestionIdEnum.LifeSustainingProcedures,
  ]

  const questionsToShow = questions?.filter((q) => scenarioQuestions.includes(q.fieldName as GuideQuestionIdEnum))

  return (
    <View>
      <View style={s.sectionContainer}>
        <Text style={{ ...s.sectionTitle, marginTop: 2, marginBottom: 0 }}>
          {i18n.t('MedicalCareScenariosPage1.title', 'Medical Care Treatment')}
        </Text>
        <Text style={s.text}>
          {i18n.t(
            'MedicalCareScenariosPage1.subtitle',
            `
            There are three scenarios for you to express your wishes. They will help you think about the kinds of life
            support decisions your medical decision maker (health care representative) could face.
            `
          )}
        </Text>
        {/* 
        
        SCENARIO 1
        
        */}
        <Text style={{ ...s.textBold, margin: 0, marginTop: 8 }}>
          {i18n.t('MedicalCareScenariosPage1.scenario1', 'Scenario 1: Terminal Condition')}
        </Text>
        <Text style={{ ...s.formLabel, marginTop: 4 }}>
          {i18n.t('MedicalCareScenariosPage1.whatIWantIf', 'This is what I want if:')}
        </Text>
        <BulletText text={i18n.t('MedicalCareScenariosPage1.text1')} fontSize={11} />
        <Text style={{ ...s.textBold, margin: 2, marginLeft: 22 }}>
          {i18n.t('MedicalCareScenariosPage1.and', 'AND')}{' '}
        </Text>
        <BulletText text={i18n.t('MedicalCareScenariosPage1.text2')} fontSize={11} />
        <Text style={{ ...s.formLabel, marginTop: 2, marginBottom: 2 }}>
          <Trans i18nKey={'MedicalCareScenariosPage1.chooseOption'}>
            Choose the <Text style={{ ...s.textBold, margin: 0 }}>one option</Text> that most closely fits your wishes
          </Trans>
        </Text>
        {questionsToShow?.[0]?.options?.map((option) => {
          return (
            <CheckboxRow
              key={option?.id}
              checked={questionsToShow?.[0]?.defaultValue === option?.id}
              label={option.label}
            />
          )
        })}
        {/* 
        
        SCENARIO 2
        
        */}
        <Text style={{ ...s.textBold, margin: 0, marginTop: 8 }}>
          {i18n.t('MedicalCareScenariosPage1.scenario2', 'Scenario 2: Advanced Progressive Illness')}
        </Text>
        <Text style={{ ...s.formLabel, marginTop: 4 }}>
          {i18n.t('MedicalCareScenariosPage1.whatIWantIf', 'This is what I want if:')}
        </Text>
        <BulletText
          text={i18n.t(
            'MedicalCareScenariosPage1.illnessCannotBeReversed',
            'I have an illness that cannot be cured or reveresed'
          )}
          fontSize={11}
        />
        <Text style={{ ...s.textBold, margin: 2, marginLeft: 22 }}>
          {i18n.t('MedicalCareScenariosPage1.and', 'AND')}{' '}
        </Text>
        <BulletText
          text={i18n.t(
            'MedicalCareScenariosPage1.believeWillGetWorse',
            'My health care providers believe it will not improve and will very likely get worse over time and result in death'
          )}
          fontSize={11}
        />
        <Text style={{ ...s.textBold, margin: 2, marginLeft: 22 }}>
          {i18n.t('MedicalCareScenariosPage1.and', 'AND')}{' '}
        </Text>
        <BulletText
          text={i18n.t(
            'MedicalCareScenariosPage1.neverBeAbleTo',
            'My health care providers believe I will never be able to:'
          )}
          fontSize={11}
        />
        <View style={{ paddingLeft: 24 }}>
          <BulletText text={i18n.t('MedicalCareScenariosPage1.communicate', 'Communicate')} fontSize={11} />
          <BulletText
            text={i18n.t('MedicalCareScenariosPage1.swallow', 'Swallow food and water safety')}
            fontSize={11}
          />
          <BulletText text={i18n.t('MedicalCareScenariosPage1.careForMyself', 'Care for myself')} fontSize={11} />
          <BulletText
            text={i18n.t('MedicalCareScenariosPage1.recognize', 'Recognize my family and other people')}
            fontSize={11}
          />
        </View>
        <Text style={{ ...s.formLabel, marginTop: 2, marginBottom: 2 }}>
          <Trans i18nKey={'MedicalCareScenariosPage1.chooseOption'}>
            Choose the <Text style={{ ...s.textBold, margin: 0 }}>one option</Text> that most closely fits your wishes
          </Trans>
        </Text>
        {questionsToShow?.[1]?.options?.map((option) => {
          return (
            <CheckboxRow
              key={option?.id}
              checked={questionsToShow?.[1]?.defaultValue === option?.id}
              label={option.label}
            />
          )
        })}
      </View>
    </View>
  )
}

export const MedicalCareScenariosPage2 = ({ data, questions }: MedicalCareScenariosProps) => {
  const scenarioQuestions = [
    GuideQuestionIdEnum.MedicalCareScenario1,
    GuideQuestionIdEnum.MedicalCareScenario2,
    GuideQuestionIdEnum.MedicalCareScenario3,
    GuideQuestionIdEnum.LifeSustainingProcedures,
  ]

  const questionsToShow = questions?.filter((q) => scenarioQuestions.includes(q.fieldName as GuideQuestionIdEnum))

  return (
    <View>
      <View style={s.sectionContainer}>
        <Text style={{ ...s.sectionTitle, marginTop: 2, marginBottom: 0 }}>
          {i18n.t('MedicalCareScenariosPage2.title', 'Medical Care Treatment')}
        </Text>
        {/* 
        
        SCENARIO 3
        
        */}
        <Text style={{ ...s.textBold, margin: 0, marginTop: 8 }}>
          {i18n.t('MedicalCareScenariosPage2.scenario3', 'Scenario 3: Permanently Unconcious')}
        </Text>
        <Text style={{ ...s.formLabel, marginTop: 4 }}>
          {i18n.t('MedicalCareScenariosPage2.whatIWantIf', ' This is what I want if:')}
        </Text>
        <BulletText text={i18n.t('MedicalCareScenariosPage2.notConcious', 'I am not conscious')} fontSize={11} />
        <Text style={{ ...s.textBold, margin: 2, marginLeft: 22 }}>
          {i18n.t('MedicalCareScenariosPage1.and', 'AND')}{' '}
        </Text>
        <BulletText
          text={i18n.t(
            'MedicalCareScenariosPage2.unlikelyToBecomeConcious',
            ' If my health care providers believe it is very unlikely that I will ever become conscious again.'
          )}
          fontSize={11}
        />
        <View style={{ paddingLeft: 24 }}>
          <BulletText text={i18n.t('MedicalCareScenariosPage2.communicate', 'Communicate')} fontSize={11} />
          <BulletText
            text={i18n.t('MedicalCareScenariosPage2.swallow', 'Swallow food and water safety')}
            fontSize={11}
          />
          <BulletText text={i18n.t('MedicalCareScenariosPage2.swallow', 'Care for myself')} fontSize={11} />
          <BulletText
            text={i18n.t('MedicalCareScenariosPage2.recognize', 'Recognize my family and other people')}
            fontSize={11}
          />
        </View>
        <Text style={{ ...s.formLabel, marginTop: 2, marginBottom: 4 }}>
          <Trans i18nKey={'MedicalCareScenariosPage1.chooseOption'}>
            Choose the <Text style={{ ...s.textBold, margin: 0 }}>one option</Text> that most closely fits your wishes
          </Trans>
        </Text>
        {questionsToShow?.[2]?.options?.map((option) => {
          return (
            <CheckboxRow
              key={option.label}
              checked={questionsToShow?.[2]?.defaultValue === option?.id}
              label={option.label}
            />
          )
        })}
        {/* 
          LIFE SUSTAINING PROCEDURES
        */}
        <Text style={{ ...s.formLabel, marginTop: 32 }}>
          {i18n.t(
            'MedicalCareScenariosPage2.lifeSustaining',
            'I do not want life-sustaining procedures if I can not be supported and be able to engage in the following ways:'
          )}
        </Text>
        <Text style={{ ...s.formLabel, marginTop: 4 }}>
          {i18n.t('MedicalCareScenariosPage2.selectAll', '(Select all that apply)')}
        </Text>
        {questionsToShow?.[3]?.options?.map((option) => {
          return (
            <CheckboxRow
              key={option.label}
              checked={questionsToShow?.[3]?.defaultValue?.includes(option?.id)}
              label={option.label}
              hasOtherField={option.id === 'other'}
              otherValue={data?.[questionsToShow?.[3]?.otherFieldName as any]}
            />
          )
        })}
      </View>
    </View>
  )
}
