import styled from 'styled-components'
import Row from 'components/common/Row'
import Col from 'components/common/Col'
import theme from 'lib/theme'
import logo from 'lib/media/logo-light-bg-horizontal.svg'
import { ButtonContainer } from '../index'
import Loading from 'components/common/Loading'
import Button from 'components/common/Button'
import config from 'lib/config'
import { ClickableText, Text } from '../index'
import forceDownloadFile from 'lib/helpers/forceDownloadFile'
import { useCallback, useEffect, useState } from 'react'
// GRAPHQL
import { usePlanAttachmentByIdQuery, UserProfile } from 'generated/graphql'
import i18n from 'lib/helpers/i18n'
import { Trans } from 'react-i18next'

const Outter = styled.div`
  background: ${(p) => p.theme.colors.neutral10};
  height: 100%;
`

const Container = styled.div`
  padding-top: 32px;
  width: 1100px;
  height: 100%;
  max-width: 95%;
  margin: auto;
`

const Header = () => {
  return (
    <div
      style={{
        height: 56,
        background: '#fff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderBottom: `1px solid ${theme.colors.neutral9}`,
      }}
    >
      <img src={logo} height="32" alt="Koda Health" />
    </div>
  )
}

interface AttachmentProps {
  fileId: string
  token: string
  currentUser: UserProfile | any
}

export default function Attachment({ fileId, token, currentUser }: AttachmentProps) {
  const [showText, setShowText] = useState<boolean>(false)
  const { data, loading, error } = usePlanAttachmentByIdQuery({
    variables: {
      downloadToken: token,
      fileId,
    },
  })

  const base64String: any = data?.planAttachmentById?.url || data?.planAttachmentById?.base64String

  if (!base64String || typeof base64String !== 'string') return null

  if (loading) return <Loading />

  if (error || (error && !currentUser?._id)) {
    return (
      <div style={{ height: '98vh', width: '100%' }}>
        <Header />
        <Outter>
          <Container>
            <Text>
              {i18n.t(
                'PublicDownloadPdfAttachment.unableError',
                `We were unable to retrieve you're document. Please make sure you're logged into your Koda account.`
              )}
            </Text>
          </Container>
        </Outter>
      </div>
    )
  }

  console.log({
    base64String,
  })

  const base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/
  console.log('isbase64', base64regex.test(base64String))

  return (
    <div style={{ height: '98vh', width: '100%' }}>
      <Header />
      <Outter className="fs-exclude">
        <Container>
          <Row align="middle" style={{ marginBottom: 16 }}>
            <Col xs={24} sm={16} style={{ marginTop: 16 }}>
              <Text>
                <Trans i18nKey={'PublicDownloadPdfAttachment.title'}>
                  Below is {{ firstName: data?.planAttachmentById?.patientFirstName }}{' '}
                  {{ lastName: data?.planAttachmentById?.patientLastName }}'s Advance Directive
                </Trans>
              </Text>
              <ClickableText onClick={() => setShowText((currenState) => !currenState)}>
                {i18n.t(
                  'PublicDownloadPdfAttachment.havingTrouble',
                  'Having trouble viewing your document or need to delete a file?'
                )}
              </ClickableText>
              {showText && (
                <Text style={{ marginTop: 16 }}>
                  {i18n.t(
                    'PublicDownloadPdfAttachment.havingTroubleSubtitle',
                    `Trouble viewing? Click the ‘Download’ button. If you’re still having trouble or need to delete a file, contact Koda Support at`
                  )}
                  <a href={`mailto:${config.supportEmail}`}>{config.supportEmail}</a>{' '}
                  {i18n.t('PublicDownloadPdfAttachment.or', 'or')}{' '}
                  <a href={`tel:+${config.supportPhone.replace(/-/g, '')}`}>{config.supportPhone}</a> .
                </Text>
              )}
            </Col>{' '}
            <Col xs={0} sm={8} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <ButtonContainer>
                <Button onClick={() => forceDownloadFile(base64String, data?.planAttachmentById?.name || 'Attachment')}>
                  {i18n.t('button.download', 'Download')}
                </Button>
              </ButtonContainer>
            </Col>
          </Row>
          <div style={{ width: '100%' }}>
            {data?.planAttachmentById?.mimetype?.includes('pdf') && !base64String?.includes('base64') && (
              <iframe
                src={base64String}
                style={{ width: '100%', minHeight: '80vh' }}
                title={data?.planAttachmentById?.name || 'Attachment'}
                onError={(thing) => console.log(thing)}
              />
            )}
            {data?.planAttachmentById?.mimetype?.includes('pdf') && base64String?.includes('base64') && (
              <DisplayOldBase64 title={data?.planAttachmentById?.name || 'Attachment'} src={base64String} />
            )}
            {!data?.planAttachmentById?.mimetype?.includes('pdf') && !base64String?.includes('base64') && (
              <img src={base64String} alt={data?.planAttachmentById?.name || 'Attachment, Koda Health'} />
            )}
          </div>
        </Container>
      </Outter>
    </div>
  )
}

/**
 *
 * For some reason some old base64 pdfs, particularly if they were photocopy/scans, seem to have
 * trouble loading from the base64 string but they will load if you create a objectUrl
 * So, this is a messy solution to ensure some of the old pdfs not saved in s3 will still render.
 */
const DisplayOldBase64 = ({ title, src }) => {
  const [url, setUrl] = useState<string | undefined>(undefined)

  const getDataUri = useCallback(
    async function () {
      const a = await fetch(src)
      const b = await a.blob()
      const c = URL.createObjectURL(b)
      console.log('here', c)
      setUrl(c)
    },
    [src]
  )

  useEffect(() => {
    getDataUri()
  }, [getDataUri])

  if (!url) return null

  return <iframe src={url} style={{ width: '100%', minHeight: '80vh' }} title={title} />
}
