import styled from 'styled-components'
// COMPONENTS
import Progress from 'components/common/Progress'
import Button from 'components/common/Button'
import i18n from 'lib/helpers/i18n'

const Container = styled.div`
  height: 104px;
  width: 100%;
  background: ${(p) => p.theme.colors.neutral10};
`

const Inner = styled.div`
  width: 700px;
  margin: auto;
  max-width: 95%;
  display: flex;
  align-items: center;
  height: 100px;
  position: relative;
`

const H1 = styled.h1`
  font-size: 18px;
  margin: 0px;
`

const ProgressText = styled.div`
  font-size: 14px;
  position: relative;
  top: 8px;
  font-weight: 400;
  color: ${(p) => p.theme.colors.neutral5};
  line-height: 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 8px;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    font-size: 16px;
    position: static;
  }
`

const ButtonContainer = styled.div`
  margin-right: 32px;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    margin-right: 16px;
  }
`

type SectionHeaderProps = {
  onExit: () => void
  sectionName?: string
  progress: string
}

export default function SectionHeader({ onExit, sectionName, progress }: SectionHeaderProps) {
  return (
    <Container>
      <Inner>
        <ButtonContainer>
          <Button grey onClick={() => onExit()}>
            {i18n.t('EsignSectionHeader.exit', 'Exit')}
          </Button>
        </ButtonContainer>
        <div style={{ marginTop: 16 }}>
          <H1>{sectionName}</H1>
          <div>
            <ProgressText data-testid="percent-complete-text">{progress}% completed</ProgressText>
            <Progress percent={parseInt(progress)} showInfo={false} size="small" />
          </div>
        </div>
      </Inner>
    </Container>
  )
}
