import { Text, View } from '@react-pdf/renderer'
// helpers
import s from '../styles'
import theme from 'lib/theme'
import Checkbox from 'components/common/PlanDoc/KodaContent/Checkbox'

interface CheckRowProps {
  label: string
  checked: boolean
  colored?: boolean
  containerStyle?: object
  id?: string
  value?: string
  textStyle?: object
  /** When true, will highlight checked rows with background color and a border */
  highlightChecked?: boolean
}

const getBorderColor = (value) => {
  let backgroundColor = theme.colors.neutral8

  if (value === 'noneAtAll') {
    backgroundColor = theme.colors.red5
  }
  if (value === 'anyLengthOfTime') {
    backgroundColor = theme.colors.primary4
  }
  if (value === 'onlyIf') {
    backgroundColor = '#FEB920'
  }
  if (value === 'trial') {
    backgroundColor = theme.colors.primary4
  }
  if (value === 'alwaysWant') {
    backgroundColor = theme.colors.primary4
  }
  if (value === 'notSure') {
    backgroundColor = theme.colors.neutral5
  }
  return backgroundColor
}

const getBackgroundColorOpacity = (value) => {
  let backgroundColor = theme.colors.neutral10

  if (value === 'noneAtAll') {
    backgroundColor = theme.colors.red10
  }
  if (value === 'anyLengthOfTime') {
    backgroundColor = theme.colors.primary10
  }
  if (value === 'onlyIf') {
    backgroundColor = theme.colors.yellow10
  }
  if (value === 'trial') {
    backgroundColor = theme.colors.primary10
  }
  if (value === 'alwaysWant') {
    backgroundColor = theme.colors.primary10
  }
  if (value === 'notSure') {
    backgroundColor = theme.colors.neutral10
  }
  return backgroundColor
}

export default function CheckRow({
  label,
  colored,
  checked,
  containerStyle,
  textStyle,
  id,
  value,
  highlightChecked,
}: CheckRowProps) {
  let borderColor = '#fff'
  let backgroundColorOpacity = colored ? theme.colors.neutral10 : '#fff'

  if (checked && highlightChecked) {
    borderColor = getBorderColor(id)
    backgroundColorOpacity = getBackgroundColorOpacity(value)
  }
  return (
    <View
      style={{
        borderWidth: 1,
        borderRadius: 5,
        borderColor: borderColor,
        backgroundColor: backgroundColorOpacity,
        padding: 3,
        marginTop: 2,
        paddingRight: 16,
        ...containerStyle,
      }}
    >
      <Checkbox checked={checked}>
        <Text
          style={{
            ...s.text,
            fontSize: 11,
            margin: 0,
            ...textStyle,
          }}
        >
          {label}
        </Text>
      </Checkbox>
    </View>
  )
}
