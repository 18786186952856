import type { ReactNode } from 'react'
import React, { useState, useEffect } from 'react'
import moment from 'moment'
import getQuestions from 'lib/helpers/questions'
import { useWindowWidth } from '@react-hook/window-size'
import { Font, usePDF } from '@react-pdf/renderer'
import { Document, pdfjs, Page } from 'react-pdf'
import styled from 'styled-components'
// DOCS
import MasterPlanDoc from './MasterPlanDoc'
// LIB
import { renderToString } from 'react-dom/server'
import { DeepPartial } from 'utility-types'
import GenericStateDoc from './GenericStateDoc'
import kodaStates from './koda-states.json'
import { ClinicFragmentFragment, Patient, GuideQuestionIdEnum, PatientFragmentFragment } from 'generated/graphql'
import { getPlanSettings } from './MasterPlanDoc/plan-settings'
import cleanHtmlFromString from 'lib/helpers/cleanHtmlFromString'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`

// https://stackoverflow.com/questions/61637191/how-to-convert-pdf-to-image-in-reactjs

/**
 * BELOW ARE A NUMBER OF SETTINGS THAT NEED TO INITIATE FOR THE PDF TO RENDER
 
 * DOC SWITCHERS
 * These two functions willl look at the version of the plan doc, and figure out which components/pdfs to render
 */

const DocSwitcher = (props) => {
  const MASTER_PLAN_STATES = Object.keys(getPlanSettings(props.data)) // @TODO: this is probably very inefficient to create this huge object just to figure out what states we have....

  if (MASTER_PLAN_STATES?.includes(props.version)) {
    return <MasterPlanDoc version={props.version} {...props} />
  }
  /** If the state is not in MASTER_PLAN_STATES, then we'll use the generic doc  */

  let adImages = kodaStates.filter((s) => s.abbreviation === props.version)[0].adImages
  return <GenericStateDoc {...props} adImages={adImages} />
}

interface PlanDocProps {
  data?: PatientFragmentFragment
  clinic?: ClinicFragmentFragment // clinic
  forBlob?: boolean
  downloading?: boolean
  faxNumber?: string
  includeCoverLetter?: boolean
  id?: string
  children?: ReactNode
}

const PdfContainer = styled.div`
  /* Using a mix of the CSS from here https://github.com/wojtekmaj/react-pdf/issues/339, plus passing screenSize into the <Page /> component from screensize hook... without screenwidth given to Page, the pdf seems fuzzy  */
  margin: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 80vh;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 8px;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }

  .PDFDocument {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100% !important;
  }
  .PDFPage {
    width: 100% !important;
    max-width: 100%;
    height: auto !important;
  }
  .PDFPage > canvas {
    width: 100% !important;
    max-width: 100%;
    height: auto !important;
    border-radius: 5px;
  }
`

/**
 * getHasSupportiveCare
 * Will return true if the clinic has Supportive Care module turned on AND the user has answered at least one supportive care question
 */
const getHasSupportiveCare = (patient: DeepPartial<Patient>): boolean => {
  const supportiveCareQs = [GuideQuestionIdEnum.InterestedInPalliativeCare, GuideQuestionIdEnum.InterestedInHospiceCare]

  let hasAnsweredASupportiveCareQ = false

  supportiveCareQs.forEach((q) => {
    if (patient?.[q]) {
      hasAnsweredASupportiveCareQ = true
    }
  })

  return hasAnsweredASupportiveCareQ
}

export default function PlanDoc(props: PlanDocProps) {
  const [isDocReady, setIsDocReady] = useState<boolean>(!!props.forBlob)
  const title = `Plan-${props?.data?.firstName}-${moment().format('M/D/YY')}.pdf`
  const version = props?.data?.stateVersion?.abbreviation
  const stateName = props?.data?.stateVersion?.name
  let questions = getQuestions(props.data as Patient, false)
  const updatedAt = props.data?.updatedAt as string
  let createdStamp = updatedAt && moment(parseInt(updatedAt)).format('M/DD/YYYY')

  useEffect(() => {
    Font.register({
      family: 'Roboto',
      src: 'https://fonts.googleapis.com/css?family=Roboto:300,400,500,700',
    })
  }, [])

  useEffect(() => {
    setTimeout(
      () => {
        setIsDocReady(true)
      },
      props.forBlob ? 0 : 500
    )
  }, [props.forBlob])

  if (!props?.data?.clinic || !isDocReady) return null

  const hasSupportiveCare = getHasSupportiveCare(props?.data)

  // we need to clean up some of the select question labels because they use react components and the PDF document can't parse them.
  // so, we'll map over all of our questions, then we'll map over questions with options, then we'll map over each option and clean any react components
  questions.forEach((q) => {
    if (q.options) {
      q.options = q.options.map((option) => {
        // if the option we're mapping over is a label AND is a react component, we need to do some cleanup
        if (option.label && React.isValidElement(option.label)) {
          option.label = cleanHtmlFromString(renderToString(option.label))
        }
        return option
      })
    }
  })

  /**
   * These sharedprops can be passed to any of the below DocSwitchers... everything takes the same props
   */
  const sharedProps = {
    title,
    createdStamp,
    questions,
    version,
    stateName,
    clinic: props?.data?.clinic,
    data: props?.data,
    faxNumber: props.faxNumber,
    includeCoverLetter: props.includeCoverLetter,
    hasSupportiveCare,
  }

  // if we're trying to download the document, just return the raw PDF component WITHOUT the PDFViewer wrapping it.
  if (props.downloading) {
    return <DocSwitcher {...sharedProps} />
  }

  return <GeneratePDF {...sharedProps} />
}

const GeneratePDF = (props) => {
  const [instance] = usePDF({ document: <DocSwitcher {...props} /> })
  const screenWidth = useWindowWidth()
  const [mobilePages, setMobilePages] = useState<number[] | []>([])

  const onDocumentLoadSuccess = ({ numPages }) => {
    try {
      const pageArray: number[] = []
      let i
      for (i = 0; i < numPages; i++) {
        pageArray.push(i)
      }
      if (mobilePages.length === 0) {
        setMobilePages(pageArray)
      }
    } catch (err) {
      console.log(err)
    }
  }
  return (
    <>
      {instance?.url && (
        <PdfContainer className="fs-exclude">
          <Document
            file={instance?.url}
            onLoadSuccess={onDocumentLoadSuccess}
            renderMode="canvas"
            className="PDFDocument"
          >
            {mobilePages.map((page, index) => {
              return <Page key={index.toString()} pageNumber={index + 1} width={screenWidth} className="PDFPage" />
            })}
          </Document>
        </PdfContainer>
      )}
      {instance?.error && <span>PDF file unavailable</span>}
      {instance?.loading && !instance.error && (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <iframe src="https://embed.lottiefiles.com/animation/30206" frameBorder="0" title="elephant" />
        </div>
      )}
    </>
  )
}
