import styled from 'styled-components'
import {
    BrowserRouter as Router,
    Route,
    Link,
    useLocation,
    RouteComponentProps
  } from "react-router-dom";

  import theme from 'lib/theme/theme'

export const Title = styled.h1`
  font-size: 24px;
  margin-bottom: 0px;
  color: ${(p) => p.theme.colors.neutral1};
`

export const Header = styled.div`
top: 0px;
height: 64px;
right: 0px;
left: 0px;
background: #fff;
z-index: 10;
display: flex;
align-items: center;
justify-content: center;
`
export const Logo = styled.img`
max-width: 90%;
margin: auto;
margin-top: 40px;
margin-bottom: 36px;
display: block;
@media only screen and (max-width: ${({ theme }) => theme.breakpoints.mediumSmall}) {
  margin-top: 10px;
  margin-bottom: 30px;
}
`
export const HeaderContainer = styled.div`

  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  height: 40px;

  

@media (max-height: 2000px) {
  left: 0;
  right: 0;
  top: 0;
  height: 40px;
  position: static;
  margin-bottom: 40px;
}
`
export const Caption = styled.label`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  margin: 0px;
  color: ${(p) => p.theme.colors.neutral5};
 
`
export const CaptionLink = styled(Link)`
font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 21px;
text-decoration-line: underline;
color: #01337D;
  &:hover {
    text-decoration: underline;
  }
`
export const HintLink = styled.span`
  font-family: ${(p) => p.theme.fontFamily};
  color: ${(p) => p.theme.colors.supportB3};
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  padding: 0px;
  margin: 0px;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0px;
  border: 0px;
  cursor: pointer;
  outline: 0;
  display: inline;
  background: transparent;
  &:hover {
    color: ${(p) => p.theme.colors.supportB3};
  }
`

export const BottomText = styled.label`
  display: block;
  color: ${(p) => p.theme.colors.neutral1};
  color: #1F2933;
  font-size: 16px;
  margin-top: 8px
  a {
    font-size: 16px;
  }
`

export const PasswordText = styled.label`
  display: block;
  font-size: 16px;
  line-height: 19px;
  color: #1F2933;
  a {
    font-size: 16px;
  }
`

export const EmailEndingBtn = styled.button`
  margin-right: 16px;
  margin-bottom: 8px;
  color: ${(p) => p.theme.colors.primary1};
  background: ${(p) => p.theme.colors.primary10};
  border-radius: 5px;
  border: 0px;
  padding: 4px 8px;
`;



export const EmailEndingRow = styled.div`
margin-top: 16px;
display: flex;
width: 100%;
/* overflow-x: scroll; */
flex-wrap: wrap;
padding-top: 4px;
padding-bottom: 8px;
`;
export const UseAsGuestText = styled.label`
  margin: 0px;
  display: block;
  font-family: 'Roboto';
  font-style: bold;
  font-size: 16px;
  line-height: 19px;
  color: #1F2933;
  margin-top: 8px;
  a {
    font-size: 16px;
    font-weight: 400;

  }
`
export const BottomBtn = styled(HintLink)`
  font-size: 16px;
  text-decoration: underline;
  &:hover {
    text-decoration: underline;
  }
`

export const BottomLink = styled(Link)`
  font-size: 16px;
  text-decoration: underline;
  color: ${(p) => p.theme.colors.primary2};
  &:hover {
    text-decoration: underline;
  }
`

export const Checkbox = styled.input``

export const UploadingOverlay = styled.div`
  z-index: 1000;
  background: rgba(0, 0, 0, 0.03);
  position: absolute;
  border-radius: 5px;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ButtonContainer = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
  display: flex;
  justify-content: flex-start;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    div,
    button {
      width: 120px !important;
    }
  }
`

export const RedTextWrapper = styled.span`
color: #CF1124
`

export const RequiredFieldsText = styled.span`
margin: 0px;
display: block;
color: #52606D;
font-size: 16px;
margin-top: 8px;
a {
  font-size: 16px;
}
`
export const CaptionsText = styled.span`
margin: 0px;
display: block;
color: #52606D;
font-size: 16px;
margin-top: 8px;
a {
  font-size: 16px;
}
`

export const Container = styled.div`
width: 600px;
`