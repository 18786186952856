import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import queryString from 'query-string'
import styled from 'styled-components'
import { useLocation, useHistory } from 'react-router-dom'
import { useWindowWidth } from '@react-hook/window-size'
// COMPONENTS
import SelectMultiple from 'components/common/SurveyForm/SelectMultiple'
import PersonaQuestion from 'components/common/SurveyForm/PersonaQuestion'
import Video from 'components/common/SurveyForm/Video'
import Loading from 'components/common/Loading'
import DateQuestion from 'components/common/SurveyForm/DateQuestion'
import AgreeToTerms from 'components/common/SurveyForm/AgreeToTerms'
import StringInput from 'components/common/SurveyForm/StringInput'
import QualityOfLife from 'components/common/SurveyForm/QualityOfLife'
import SectionBreak from 'components/common/SurveyForm/SectionBreak'
import ResignNoticeModal from 'components/common/ResignNoticeModal'
import MedicalCareYesNo from 'components/common/MedicalCareYesNo'
import AddressInput from 'components/common/SurveyForm/AddressInput'
// import ExplainerMedicalBreak from 'components/common/SurveyForm/ExplainerMedicalBreak';
import TwoStringInputs from 'components/common/SurveyForm/TwoStringInputs'
import TextAreaInput from 'components/common/SurveyForm/TextAreaInput'
import InactiveModal from 'components/common/InactiveModal'
import SectionHeader from './SectionHeader'
import { omit } from 'lodash'
import ExplainerExpander from 'components/common/SurveyForm/ExplainerExpander'
import { DeleteMDMType } from 'components/common/ConfirmRemoveMdmModal'
import DecisionMakerQuestions from './DecisionMakerQuestions'
// HOOKS
import useSavePatient, { saveFieldsToRemove } from 'lib/hooks/useSavePatient'
import useLogActivityItemMutation from 'lib/hooks/useLogActivityItemMutation'
// LIB
import theme from 'lib/theme'
import { AnalyticEventTypeEnums } from 'lib/types/AnalyticEventTypeEnums'
import getQuestions from 'lib/helpers/questions'
import { QuestionType, QuestionItem } from 'lib/types/QuestionItem'
// APOLLO
import {
  ActivityLogKeyEnum,
  GetOnePatientDocument,
  GuideQuestionIdEnum,
  Patient,
  useGetOnePatientQuery,
  useRevertEsignMutation,
  UserProfile,
} from 'generated/graphql'
import LoadingOutlined from 'components/icons/LoadingOutlined'
import message from 'components/common/message'
import MedicalCareCprWithVideo from 'components/common/MedicalCareCprWithVideo'
import i18n from 'lib/helpers/i18n'
import logError from 'lib/helpers/logError'
import logEvent from 'lib/helpers/logEvent'
import translateApolloError from 'lib/helpers/translateApolloError'

const Saving = () => {
  const { t } = useTranslation()

  return (
    <div
      style={{
        position: 'fixed',
        left: 16,
        top: 104,
        color: theme.colors.neutral5,
      }}
    >
      <p>
        <LoadingOutlined style={{ marginRight: 8, fontSize: 12 }} />
        {t('saving', 'saving')}...
      </p>
    </div>
  )
}

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 90%;
  margin: auto;
  background: #fff;
  padding-top: 40px;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    align-items: flex-start;
    padding-bottom: 88px;
    max-width: 98%;
  }
`

export const SharedContainer = (props) => {
  return (
    <>
      <InactiveModal />
      {props.children}
    </>
  )
}

interface QuestionsViewProps {
  defaultPlan?: Patient | null
  currentUser: UserProfile
}

const QuestionsView = ({ defaultPlan, currentUser }: QuestionsViewProps) => {
  const location = useLocation()
  const history = useHistory()
  const { t } = useTranslation()
  const [plan, setPlan] = useState<any>(defaultPlan || {})
  const [dontKnowDM, setDontKnowDM] = useState<boolean>(false)
  /// showEditWarning will hold whatever the values are the user is trying to save, if they confirm that they do want to edit the plan, then it will use these values
  const [showEditWarning, setShowEditWarning] = useState<{ plan: object; callback: Function } | undefined>(undefined)
  const { onSavePatient, saving } = useSavePatient()
  const { editSection = '', step = 0, mode } = queryString.parse(location.search)
  const stateVersion = plan?.stateVersion
  const screenWidth = useWindowWidth()
  const [revertEsignMutation] = useRevertEsignMutation()
  const isMobile = screenWidth <= parseInt(theme.breakpoints.medium)
  const [confirmDelete, setConfirmDelete] = useState<DeleteMDMType | undefined>(undefined)

  // get the questionIds from this section
  const questionIds: GuideQuestionIdEnum[] =
    plan?.guideTemplate?.sections?.filter((section) => section?.sectionId === editSection)?.[0]?.questionIds || []

  const questions = getQuestions(plan, questionIds)

  const currentQuestion = questions?.find((q) => q.stepName === step)

  useEffect(() => {
    setPlan(defaultPlan)
  }, [defaultPlan])

  const currentQuestionIndex = questionIds?.findIndex((questionId) => questionId === currentQuestion?.stepName)

  const progress = (((currentQuestionIndex as number) / (questionIds?.length || 1)) * 100).toFixed(0)

  const [logActivityItem] = useLogActivityItemMutation()
  // console.log({
  //   currentQuestion,
  //   step,
  //   progress,
  // });
  // let decisionMaker
  let doYouKnowDecisionMakerIndex // the first question in the decision maker section e.g. 27

  // we will forEach over our array of questions to try and find the index of the beginging and end of decision maker index
  questions.forEach((q, i) => {
    // if they click next, we skip ahead to the advanced direcrives video
    if (q && q.fieldName === 'doYouKnowDecisionMaker') {
      doYouKnowDecisionMakerIndex = i
    }
  })

  const handleSavePatient = async (newValues: Patient, callback: () => void) => {
    const values = newValues?.id ? newValues : plan
    if (plan?.hasOfficialPlan) {
      // only show the "edit warning" if the plan has been changed
      const planHasChanged = JSON.stringify(values) !== JSON.stringify(defaultPlan)

      // uncomment if you want to see which fields were diffed
      // console.log({
      //   old: pickBy(defaultPlan, (value, key) => {
      //     return !isEqual(value, newValues[key]);
      //   }),
      //   new: pickBy(newValues, (value, key) => {
      //     return !isEqual(defaultPlan?.[key], value);
      //   }),
      // });
      if (planHasChanged) {
        return setShowEditWarning({ plan: values, callback })
      } else {
        return callback && callback()
      }
    }
    // check if we need to verify the user want's to save changes
    await onSavePatient(values)
    callback && callback()
  }

  const handleOnResign = async () => {
    try {
      await revertEsignMutation({
        variables: {
          params: omit(
            {
              ...showEditWarning?.plan,
            },
            saveFieldsToRemove
          ),
        },
        refetchQueries: [
          {
            query: GetOnePatientDocument,
            variables: { userId: currentUser.id },
          },
        ],
      })
      message.info(i18n.t('QuestionsView.answerSaved', `Answer successfully saved. Document needs re-signing.`))
      showEditWarning?.callback()
      setShowEditWarning(undefined)
    } catch (err: any) {
      message.error(translateApolloError(err))
    }
  }

  const handleCancelResign = () => {
    setPlan(defaultPlan)
    message.info(i18n.t('QuestionsView.answerNotSaved', `Answer not saved.`))
    showEditWarning?.callback()
    setShowEditWarning(undefined)
  }

  const setStep = async (newStepName: string) => {
    try {
      const current = queryString.parse(location.search)
      let newValues = {
        ...current,
        step: newStepName,
      }

      await logActivityItem({
        variables: {
          key: ActivityLogKeyEnum.SurveyNextStep,
          userId: currentUser.id || '',
          patientId: plan && plan.id,
          content: `Patient moved to step ${newStepName}`,
        },
      })
      let newString = queryString.stringify(newValues)
      history.push(`${location.pathname}?${newString}`)
    } catch (err) {
      logError(err)
    }
  }

  const onNext = (newPlan) => {
    /**
     * TODO: Figure out the logic for alt decision maker = no
     * 1. figure out
     */
    if (step === doYouKnowDecisionMakerIndex && (newPlan || plan).wantsAltDecisionMaker === 'no') {
      return handleSavePatient(newPlan || plan, () => history.push('/app'))
    }

    if (currentQuestionIndex + 1 === questions.length) {
      return handleSavePatient(newPlan || plan, () => history.push('/app'))
    }

    if (questions[currentQuestionIndex]?.stepName === GuideQuestionIdEnum.LikeToKnowCprAgeGroup) {
      if (!(newPlan || plan).likeToKnowCprAgeGroup) {
        const nextQuestion = questions[currentQuestionIndex + 2]
        return handleSavePatient(newPlan || plan, () => setStep(nextQuestion?.stepName))
      }
    }
    const nextQuestion = questions[currentQuestionIndex + 1]
    return handleSavePatient(newPlan || plan, () => setStep(nextQuestion?.stepName))
  }

  const onBack = () => {
    if (currentQuestionIndex !== 0) {
      // log analytics about if the user clicked the back button
      if (mode && mode === 'editing') {
        logEvent(AnalyticEventTypeEnums.SURVEY_BACK_BUTTON_EDITING, {
          from: currentQuestion?.fieldName || currentQuestion?.stepName,
          to:
            questions?.[parseInt(step as string) - 1]?.fieldName || questions?.[parseInt(step as string) - 1]?.stepName,
        })
      } else {
        logEvent(AnalyticEventTypeEnums.SURVEY_BACK_BUTTON_INITIAL, {
          from: currentQuestion?.fieldName || currentQuestion?.stepName,
          to:
            questions?.[parseInt(step as string) - 1]?.fieldName || questions?.[parseInt(step as string) - 1]?.stepName,
        })
      }
      const lastQuestion = questions[currentQuestionIndex - 1]
      if (lastQuestion.stepName === GuideQuestionIdEnum.CprAgeGroupVideo && !plan.likeToKnowCprAgeGroup) {
        handleSavePatient(plan, () => setStep(questions[currentQuestionIndex - 2]?.stepName))
      } else {
        handleSavePatient(plan, () => setStep(lastQuestion?.stepName))
      }
    } else if (currentQuestionIndex === 0) {
      handleSavePatient(plan, () => history.push('/app'))
    }
  }

  const sharedProps = {
    ...currentQuestion,
    isMobile,
    onBack,
    onNext,
    currentUser,
    stateVersion,
    plan,
    key: currentQuestion?.fieldName,
    // The type of question
    type: currentQuestion && currentQuestion?.type,
    // The text for the "Next" button
    nextButton: (currentQuestion && currentQuestion.button) || 'Next',
    // The actual text for the question, if one exists
    text: currentQuestion && currentQuestion?.text,
    // if this question is required
    required: currentQuestion && currentQuestion?.required,
    // placeholder text for, for instance, a text input
    placeholder: currentQuestion && currentQuestion?.placeholder,
    // for multiple select, does it have a none of the above options
    canBeNone: currentQuestion && currentQuestion?.canBeNone,
    // img if one exists
    img: currentQuestion && currentQuestion?.img,
    // The name of the value we'll store this on the patient object
    fieldName: currentQuestion && currentQuestion?.fieldName,
    // For select questions, is this is multi select or one choice
    multiple: currentQuestion && currentQuestion?.multiple,
    // Holds the value of the current question (could be a string, array or otherwise)
    defaultValue: plan[currentQuestion?.fieldName || ''],
    // next button aligns left by default, this can change it to center, etc
    buttonAlign: currentQuestion && currentQuestion?.buttonAlign,
    // an array of options to map over for multi select etc
    options: currentQuestion && currentQuestion?.options,
    // what sort of validation do we need to do... string, number, email
    inputType: currentQuestion && currentQuestion?.inputType,
    // ID of the youtube video
    videoId: currentQuestion && currentQuestion?.videoId,
    // a percentage 0-100 based on what step we're on
    progress,
    //
    otherFieldValue: plan[currentQuestion?.otherFieldName as string], // only for multiple choice, select, etc where we want to give people an option to write in a value when they select "other"
    // basic onChange that will update the patient object
    onChange: (newValue) => {
      setPlan({
        ...plan,
        ...newValue,
      })
    },
  }

  if (
    dontKnowDM ||
    currentQuestion?.fieldName === GuideQuestionIdEnum.WantsAltDecisionMaker ||
    currentQuestion?.fieldName === GuideQuestionIdEnum.WantsThirdDecisionMaker ||
    currentQuestion?.fieldName === GuideQuestionIdEnum.DoYouKnowDecisionMaker
  ) {
    return (
      <DecisionMakerQuestions
        plan={plan}
        currentUser={currentUser}
        handleSavePatient={handleSavePatient}
        dontKnowDM={dontKnowDM}
        handleOnResign={handleOnResign}
        handleCancelResign={handleCancelResign}
        saving={saving}
        showEditWarning={showEditWarning as object}
        editSection={editSection as string}
        progress={progress}
        sharedProps={sharedProps}
        questions={questions}
        currentQuestion={currentQuestion as QuestionItem}
        setConfirmDelete={setConfirmDelete}
        setStep={setStep}
        setDontKnowDM={setDontKnowDM}
        onNext={onNext}
        defaultPlan={defaultPlan as Patient}
        confirmDelete={confirmDelete as DeleteMDMType}
      />
    )
  }

  /**
   * LOGIC BRANCH FOR DECISION MAKER RELATED QUESTION ENDS HERE
   */

  return (
    <div style={{ width: '100%' }}>
      <ResignNoticeModal
        onResign={handleOnResign}
        onCancel={handleCancelResign}
        loading={saving}
        visible={showEditWarning ? true : false}
      />
      {saving && <Saving />}
      <SectionHeader
        sectionName={t(
          `planConfigurator.${
            plan?.guideTemplate?.sections?.find((section) => section.sectionId === editSection)?.sectionId
          }`
        )}
        onSave={() => {
          handleSavePatient(plan, () => history.push('/app'))
        }}
        progress={progress}
        currentUser={currentUser}
        patient={plan}
      />
      {currentQuestion?.stepName === GuideQuestionIdEnum.LikeToKnowCprAgeGroup && (
        <MedicalCareYesNo
          patient={plan}
          setPlan={setPlan}
          handleSavePatient={handleSavePatient}
          onNext={onNext}
          onBack={onBack}
          progress={progress}
          nextButton={sharedProps.nextButton}
          currentUser={currentUser}
        />
      )}
      {currentQuestion?.stepName === GuideQuestionIdEnum.CprAgeGroupVideo && (
        <MedicalCareCprWithVideo
          patient={plan}
          setPlan={setPlan}
          handleSavePatient={handleSavePatient}
          onNext={onNext}
          onBack={onBack}
          progress={progress}
          nextButton={sharedProps.nextButton}
        />
      )}
      {currentQuestion?.stepName !== GuideQuestionIdEnum.LikeToKnowCprAgeGroup &&
        currentQuestion?.stepName !== GuideQuestionIdEnum.CprAgeGroupVideo && (
          <SharedContainer currentUser={currentUser}>
            {/* {errorBlock && <ErrorBlock message={errorBlock} />} */}
            <Container>
              {(() => {
                switch (currentQuestion?.type) {
                  case QuestionType.video:
                    return <Video {...sharedProps} key={currentQuestion?.fieldName} />
                  case QuestionType.agreeToTerms:
                    return <AgreeToTerms {...sharedProps} />
                  case QuestionType.birthday:
                    return <DateQuestion {...sharedProps} />
                  case QuestionType.persona:
                    return <PersonaQuestion {...sharedProps} />
                  case QuestionType.textarea:
                    return <TextAreaInput {...sharedProps} />
                  case QuestionType['section-break']:
                    return <SectionBreak {...sharedProps} />
                  // case 'explainer-medical-break':
                  //  return <ExplainerMedicalBreak {...sharedProps} />;
                  case QuestionType.string:
                    return <StringInput {...sharedProps} />
                  case QuestionType['two-strings']:
                    return (
                      <TwoStringInputs
                        {...sharedProps}
                        key={currentQuestion?.inputs?.[0].fieldName}
                        defaultValues={[
                          plan[currentQuestion?.inputs?.[0].fieldName],
                          plan[currentQuestion?.inputs?.[1].fieldName],
                        ]}
                      />
                    )
                  case QuestionType.explainer: {
                    return <ExplainerExpander {...sharedProps} />
                  }
                  case QuestionType['select-icons']:
                    return <SelectMultiple {...sharedProps} key={currentQuestion?.fieldName} />
                  case QuestionType['select-columns']:
                    return <SelectMultiple {...sharedProps} key={currentQuestion?.fieldName} />
                  case QuestionType.select:
                    return <SelectMultiple {...sharedProps} key={currentQuestion?.fieldName} />
                  case QuestionType.address:
                    return (
                      <AddressInput
                        {...sharedProps}
                        key={currentQuestion?.fieldName}
                        defaultValues={[
                          plan[currentQuestion?.inputs?.[0].fieldName],
                          plan[currentQuestion?.inputs?.[1].fieldName],
                          plan[currentQuestion?.inputs?.[2].fieldName],
                          plan[currentQuestion?.inputs?.[3].fieldName],
                        ]}
                      />
                    )

                  case QuestionType.slider:
                    return <QualityOfLife {...sharedProps} />
                  default:
                    return <div>No Step Found</div>
                }
              })()}
            </Container>
          </SharedContainer>
        )}
    </div>
  )
}

interface AppQuestionViewProps {
  currentUser: UserProfile
}

export default function AppQuestionView({ currentUser }: AppQuestionViewProps) {
  const { data, loading, error } = useGetOnePatientQuery({
    variables: { userId: currentUser.id },
  })
  if (loading) return <Loading />
  if (error) return 'Error...'
  return <QuestionsView defaultPlan={data?.getOnePatient} currentUser={currentUser} />
}
