import { useState, useEffect } from 'react'
import styled from 'styled-components'
import queryString from 'query-string'
import ms from 'ms'
import { Redirect } from 'react-router-dom'
import useUrlChange from 'lib/hooks/useUrlChange'
// LIBS
import constants from 'lib/config'
// COMPONENTS
import SwitchLanguage from 'components/common/SwitchLanguage'
import SignupForm from './SignupForm'
import GuestForm from './GuestForm'
import GuestFormV2 from './GuestFormV2'

import NoClinicExists from './NoClinicExists'
import Start from './Start'
// APOLLO
import CURRENT_USER from 'ApolloClient/Queries/currentUser'
import client from 'ApolloClient/index'
import { useNewPatientSignupMutation, Clinic } from 'generated/graphql'
// HOOKS
import useCheckSlugExists from './useCheckSlugExists'
import useFullStory from 'lib/hooks/useFullStory'
import setLocalStorage from 'lib/helpers/setLocalStorage'
import translateApolloError from 'lib/helpers/translateApolloError'
import SignupFormV2 from './SignupFormV2'

const PageContainer = styled.div`
  background: ${(p) => p.theme.colors.neutral10};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  max-width: 100%;
`

const FormContainer = styled.div`
  background: #fff;
  padding: 24px;
  min-height: 400px;
  border-radius: 5px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 800px;
  max-width: 100%;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin-top: 40px;
  }
`

interface UserObj {
  email?: string
  password?: string
  firstName?: string
  lastName?: string
  birthday?: string
  stateId?: string
  phoneNumber?: string
}

export default function PublicForm(props) {
  const [user, setUser] = useState<UserObj>({
    email: undefined,
    password: undefined,
    firstName: undefined,
    lastName: undefined,
    birthday: undefined,
    stateId: undefined,
    phoneNumber: undefined,
  })
  const [createNewPatient] = useNewPatientSignupMutation()
  const [loading, setLoading] = useState(false)
  const {
    onUrlChange,
    query: { externalId },
  } = useUrlChange()
  const [signupError, setSignupError] = useState<string>()
  const { slugCheckLoading, slugExists, clinic } = useCheckSlugExists({
    slug: props.match.params.slug,
  })

  useEffect(() => {
    setUser((user) => ({
      ...user,
      stateId: clinic?.state?.id as string,
    }))
  }, [clinic])

  // init fullstory
  useFullStory()

  const { match, location, history, currentUser } = props

  /**
   * when the page loads, there may not be a formType
   * query string variable in it. Here we pluck the
   * variable if it exists, or default it to signup
   */
  let formType

  if (location.search) {
    const queryStringResult = queryString.parse(location.search)
    formType = queryStringResult.formType
  }

  /**
   * CHECK IF SLUG EXISTS
   * We want to pluck the slug from he URL and see if there is
   * a clinic in our system associated with that slug.
   * If not, we want to show an error message that we can't find that clinic
   * */

  if (slugCheckLoading) return null

  if (!slugExists) {
    return <NoClinicExists />
  }

  // update the type of form (signup vs guest) that we want to show by updating the query string in the URL
  const setFormType = (newValue) => {
    if (clinic?.v2Signup) {
      console.log(location.pathname)
      history.push(`${location.pathname}?formType=${newValue}`)
    } else {
      history.push(`${location.pathname}?formType=${newValue}`)
    }
  }

  /**
   * Used to call the mutation to create the user's account
   * This single mutation is the same for a quest user or a full account user
   */
  const onCreateAccount = async () => {
    try {
      setLoading(true)

      const res = await createNewPatient({
        variables: {
          email: user.email,
          password: user.password,
          firstName: user.firstName,
          lastName: user.lastName,
          birthday: user.birthday,
          slug: match.params.slug,
          stateId: user.stateId,
          externalId: externalId as string,
          phoneNumber: user.phoneNumber?.replaceAll(/[- )(]/g, ''),
        },
      })

      const accessToken = res.data?.newPatientSignup?.accessToken
      const refreshToken = res.data?.newPatientSignup?.refreshToken
      const userId = res.data?.newPatientSignup?.userId
      const patientId = res.data?.newPatientSignup?.patientId

      // set the new login tokens
      setLocalStorage(constants.authTokenName, accessToken)
      setLocalStorage(constants.refreshTokenName, refreshToken)

      // update the URL with new params

      history.push({
        pathname: location.pathname,
        search: `?userId=${userId}&patientId=${patientId}&formType=${formType}?`,
      })

      // set a timeout so it's not too abrupt, then re-query the user not that we have new tokens (set about)
      setTimeout(async () => {
        await client.query({
          query: CURRENT_USER,
          fetchPolicy: 'network-only',
        })

        setLoading(false)
        // onUrlChange({ formType: undefined }); // reset the start screen
        setUser({}) // reset the user data/object to be empty
      }, ms('1.5s'))
    } catch (err: any) {
      setLoading(false)
      setSignupError(translateApolloError(err))
      console.log(err)
    }
  }

  // if there is a currentUser (i.e. user is logged in), then we show the survey form questions
  if (currentUser?.id && clinic?.v2Signup) {
    return <Redirect to="/app/book-a-call" />
  } else if (currentUser?.id) {
    return <Redirect to="/app/onboarding" />
  }

  // if we're on the start step, which is stored in local state in this component, show the start screen
  if (!formType) {
    return (
      <>
        <SwitchLanguage />
        <Start onNext={() => onUrlChange({ formType: 'signup' }, null)} clinic={clinic as Clinic} />
      </>
    )
  }

  // since both forms use the same exact props, define them in one place
  const sharedProps = {
    user,
    clinic,
    onChangeForm: setFormType,
    onSubmit: onCreateAccount,
    loading: loading,
    onBack: () => onUrlChange({ formType: undefined }, null),
    onChange: (newData) => {
      setUser({
        ...user,
        ...newData,
      })
    },
    match,
  }

  if (clinic?.v2Signup) {
    console.log('Test 1')
    return (
      <PageContainer style={{ background: '#fff' }}>
        <div style={{ maxWidth: '100%' }}>
          <SwitchLanguage />
          <FormContainer>
            {formType === 'signup' && <SignupFormV2 {...sharedProps} signupError={signupError as string} {...clinic} />}
            {formType === 'guest' && <GuestFormV2 {...sharedProps} />}
          </FormContainer>
        </div>
      </PageContainer>
    )
  }

  return (
    <PageContainer style={{ background: '#fff' }}>
      <div style={{ maxWidth: '100%' }}>
        <SwitchLanguage />
        <FormContainer>
          {formType === 'signup' && <SignupForm {...sharedProps} signupError={signupError as string} />}
          {formType === 'guest' && <GuestForm {...sharedProps} />}
        </FormContainer>
      </div>
    </PageContainer>
  )
}
