import { useState } from 'react'
import styled from 'styled-components'
import Button from 'components/common/Button'
import TextInput from 'components/inputs/TextInput'
import PlusOutlined from 'components/icons/PlusOutlined'
import CloseCircleOutlined from 'components/icons/CloseCircleOutlined'
import i18n from '../../../lib/helpers/i18n'
import TextAreaInput from 'components/inputs/TextAreaInput'
import HintText from 'components/common/FormItem/HintText'
import addNoteIcon from './add-note-icon.svg'
import replaceAllSpaces from 'lib/helpers/replaceAllSpaces'

const ModalTitle = styled.h1`
  color: ${(p) => p.theme.colors.neutral1};
  font-size: 22px;
`

const FooterContainer = styled.div`
  padding: 8px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
`

const BoldLabel = styled.label`
  font-weight: 700;
  display: block;
  color: ${(p) => p.theme.colors.neutral3};
`

const ModalRow = styled.div`
  border-top: 1px solid ${(p) => p.theme.colors.neutral9};
  padding: 16px 0px;
`

const ExpandBtn = styled.button`
  border: 0px;
  text-decoration: underline;
  background: transparent;
  cursor: pointer;
  &:focus {
    outline: 0;
  }
`

const ErrorText = styled.p`
  margin: 0px;
  color: ${(p) => p.theme.colors.red4};
`

const RemoveBtn = styled.button`
  border: 0px;
  background: transparent;
  cursor: pointer;
`

const AddEmailBtn = styled.button`
  background: transparent;
  border: 0px;
  color: ${({ theme }) => theme.colors.primary3};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 4px 8px;
  border-radius: 5px;
  margin-right: 68px;
  &:hover {
    background: ${({ theme }) => theme.colors.primary10};
  }
  &:focus {
    outline: 0;
  }
`

const IconCircle = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.primary10};
  color: ${({ theme }) => theme.colors.primary3};
  margin-right: 8px;
`

const StyledTextInput = styled(TextInput)`
  margin-top: 4px;
  width: 550px;
  max-width: 100%;
  margin-right: 8px;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.mediumSmall}) {
    max-width: 100%;
  }
`

const StyledTextAreaInput = styled(TextAreaInput)`
  margin-top: 8px;
  width: 550px;
  max-width: 100%;
  margin-right: 8px;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.mediumSmall}) {
    max-width: 90%;
  }
`

const InputGroup = styled.div`
  margin-right: 16px;
  max-width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  /* button {
    position: absolute;
    right: -44px;
    top: 42px;
  } */
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.mediumSmall}) {
    /* button {
      position: absolute;
      right: -5px;
      top: 42px;
    } */
  }
`

interface TopInputProps {
  label?: string
  value?: string | number
  onChange: React.ChangeEventHandler<HTMLInputElement>
  error?: string
  onRemove: React.MouseEventHandler<HTMLButtonElement>
  placeholder?: string
  infoText?: string
  id: string
}

const TopInput = ({ id, label, value, onChange, error, onRemove, placeholder, infoText }: TopInputProps) => {
  return (
    <div style={{ marginBottom: 24 }}>
      <BoldLabel style={{ marginTop: 4 }} htmlFor={id}>
        {label}
      </BoldLabel>
      <InputGroup>
        <div style={{ width: '100%' }}>
          <div style={{ width: '100%', display: 'flex' }}>
            <StyledTextInput
              onChange={onChange}
              placeholder={placeholder}
              type="email"
              className="fs-exclude"
              data-testid={`${id}-input`}
              value={value || ''}
              id={id}
            />
            <RemoveBtn onClick={onRemove} aria-label="Remove">
              <CloseCircleOutlined style={{ fontSize: 20 }} />
            </RemoveBtn>
          </div>
          {error && <ErrorText>{error}</ErrorText>}
          <HintText>{infoText}</HintText>
        </div>
      </InputGroup>
    </div>
  )
}

const OtherEmailInputGroup = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  width: 100%;
  label {
    min-width: 60px;
    margin-right: 8px;
  }
  input {
    width: 404px;
    max-width: 100%;
    margin-right: 8px;
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.mediumSmall}) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    label {
      width: 100%;
    }
    input {
      max-width: 90%;
    }
    button {
      position: absolute;
      right: -5px;
      top: 38px;
    }
  }
`
const OtherEmailInput = ({ value, label, error, onRemove, onChange }) => {
  return (
    <div style={{ marginBottom: 16, width: '100%' }}>
      <OtherEmailInputGroup>
        <label>{label}:</label>
        <TextInput
          value={value}
          placeholder={i18n.t('sharePlanEmail.inputEmailPlaceholder')}
          className="fs-exclude"
          onChange={onChange}
        />
        <RemoveBtn onClick={onRemove} aria-label="Remove" data-testid={`${replaceAllSpaces(label)}-remove-btn`}>
          <CloseCircleOutlined style={{ fontSize: 20 }} />
        </RemoveBtn>
      </OtherEmailInputGroup>
      {error && <ErrorText style={{ marginLeft: 64 }}>{error}</ErrorText>}
    </div>
  )
}

const AddPersonalNoteBtn = styled.button`
  border: 0px;
  font-size: 16px;
  background: transparent;
  padding: 0px;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.primary3};
  text-decoration: underline;
  cursor: pointer;
`

const AddPersonalNote = ({ onChange }) => {
  const [visible, setVisible] = useState<boolean>(false)
  return (
    <div style={{ minHeight: 56, marginBottom: 16, display: 'flex', alignItems: 'center' }}>
      {!visible && (
        <AddPersonalNoteBtn onClick={() => setVisible(true)}>
          <img
            src={addNoteIcon}
            alt={i18n.t('AddPersonalNote.btnText', 'Add a personal message')}
            style={{ marginRight: 8, height: 32, width: 32 }}
          />
          {i18n.t('AddPersonalNote.btnText', 'Add a personal message')}
        </AddPersonalNoteBtn>
      )}
      {visible && (
        <div style={{ paddingBottom: 24, marginTop: 16, width: '100%' }}>
          <BoldLabel>{i18n.t('AddPersonalNote.label', 'Personal Message:')}</BoldLabel>
          <InputGroup>
            <StyledTextAreaInput
              onChange={(value) => onChange(value)}
              placeholder={i18n.t('AddPersonalNote.placeholder', 'Type your message here...')}
              rows={5}
              className="fs-exclude"
            />
            <RemoveBtn
              aria-label="Remove"
              data-testid={`AddPersonalNote-remove-btn`}
              onClick={() => {
                setVisible(false)
                onChange('')
              }}
            >
              <CloseCircleOutlined style={{ fontSize: 20 }} />
            </RemoveBtn>
          </InputGroup>
          <HintText>
            {i18n.t(
              'AddPersonalNote.hint',
              'This personal message will be included in the email sent to your Decision Maker'
            )}
          </HintText>
        </div>
      )}
    </div>
  )
}

interface EmailDirectiveFormProps {
  loading?: boolean
  hasOfficialPlan?: boolean | null | any
  onChange: (args: { email?: string; decisionMakerEmail?: string; otherEmails?: string[] }) => void
  decisionMakerEmail?: string
  onComplete?: (e: any) => void
  onCancel?: () => void
  otherEmails?: string[] | []
  email?: string
  errors: {
    email?: string
    decisionMakerEmail?: string
    otherEmails?: string[]
  }
  onPersonalNoteChange: (newValue: string) => void
  disabled?: boolean
}

export default function EmailDirectiveForm({
  loading,
  disabled = false,
  onChange, // used to save data to the patient/survey object
  onComplete, // called when user is finally ready to submit the form
  decisionMakerEmail, // holds the value for the decision maker email input
  onCancel, // will close the modal
  otherEmails = [],
  hasOfficialPlan,
  onPersonalNoteChange,
  email, // holds the string value for the main email input
  errors, // holds an object where each field correlates to an input and holds an error message string (if an error exists for the given field)
}: EmailDirectiveFormProps) {
  const [expandedTerms, setExpandedTerms] = useState(false)

  return (
    <>
      <div style={{ padding: 16 }}>
        <ModalTitle>
          {!hasOfficialPlan ? i18n.t('shareUnofficialPlan.yes') : i18n.t('shareUnofficialPlan.no')}
        </ModalTitle>
        <div style={{ marginTop: 24 }}>
          <TopInput
            label={i18n.t('sharePlanInput.emailLabel')}
            placeholder={i18n.t('sharePlanInput.emailPlaceholder')}
            value={email}
            onChange={(e) => onChange({ email: e.target.value })}
            error={errors?.email}
            onRemove={() => onChange({ email: '' })}
            infoText={i18n.t('sharePlanInput.emailInfoText')}
            id="Your Email"
          />
          <TopInput
            label={i18n.t('sharePlanInput.decisionEmailLabel')}
            placeholder={i18n.t('sharePlanInput.decisionEmailPlaceholder')}
            value={decisionMakerEmail}
            onChange={(e) => onChange({ decisionMakerEmail: e.target.value })}
            error={errors?.decisionMakerEmail}
            onRemove={() => onChange({ decisionMakerEmail: '' })}
            infoText={i18n.t('sharePlanInput.decisionEmailInfoText')}
            id="Decision Maker Email"
          />
          <AddPersonalNote onChange={(newValue) => onPersonalNoteChange(newValue)} />

          {/* ADD ANOTHER EMAIL */}
          <ModalRow>
            <ModalTitle>{i18n.t('sharePlanModalTitle')}</ModalTitle>
            {otherEmails?.map((otherEmail, index) => {
              return (
                <OtherEmailInput
                  key={`${index}`}
                  label={i18n.t('sharePlanEmail.inputEmailLabel', { keyNumber: index + 1 })}
                  value={otherEmail}
                  error={errors.otherEmails?.[index]}
                  onChange={(e) => {
                    const otherEmailCopy: string[] = [...otherEmails]
                    otherEmailCopy[index] = e.target.value
                    console.log({ otherEmails: otherEmailCopy })
                    onChange({ otherEmails: otherEmailCopy })
                  }}
                  onRemove={() => {
                    const otherEmailCopy = [...otherEmails]
                    otherEmailCopy.splice(index, 1)
                    onChange({ otherEmails: otherEmailCopy })
                  }}
                />
              )
            })}
            <AddEmailBtn onClick={() => onChange({ otherEmails: [...otherEmails, ''] })}>
              <IconCircle>
                <PlusOutlined />
              </IconCircle>
              {i18n.t('sharePlanEmail.addEmailButtonText')}
            </AddEmailBtn>
          </ModalRow>
          {/* VIRTUAL NOTARY */}
          {/* commented out the extra information about virtual notary because not currently sending extra information to patients */}
          {/* {!hasOfficialPlan && (
            <ModalRow>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div>
                  <CheckWrapper>
                    <input
                      type="checkbox"
                      id="checkbox"
                      checked={notaryInfo}
                      onChange={(e) => onChangeNotaryInfo(e.target.checked)}
                    />
                    <label htmlFor="checkbox"></label>
                  </CheckWrapper>
                </div>
                <div style={{ width: "100%" }}>
                  <BoldLabel style={{ marginTop: 4 }}>
                    I would like information about free virtual notarization
                  </BoldLabel>
                </div>
              </div>
            </ModalRow>
          )} */}
          {/* TERMS OF SERVICE */}
          <p style={{ marginTop: 32 }}>
            {i18n.t('agreeEmailText')}
            <ExpandBtn onClick={() => setExpandedTerms(true)}>{i18n.t('agreeEmailSendConsent')}</ExpandBtn>.
          </p>
          {expandedTerms && <p>{i18n.t('agreeEmailSendContent')}</p>}
        </div>
      </div>
      {/* FOOTER BUTTON */}
      <FooterContainer>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button grey onClick={onCancel} disabled={loading} style={{ display: 'inline-block', marginRight: 16 }}>
            {i18n.t('agreeEmailSendButtonCancel')}
          </Button>
          <Button
            onClick={onComplete}
            disabled={disabled || loading}
            loading={loading}
            style={{ display: 'inline-block' }}
            data-testid="signature-send-email"
          >
            {i18n.t('agreeEmailSendButton')}
          </Button>
        </div>
      </FooterContainer>
    </>
  )
}
