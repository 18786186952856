import { ApolloLink } from '@apollo/client'
import * as Sentry from '@sentry/browser'

const sentryTransactionIdLink = new ApolloLink((operation, forward) => {
  // generate unique transactionId and set as Sentry tag
  const transactionId = Math.random().toString(36).substr(2, 9)
  Sentry.configureScope((scope) => {
    scope.setTag('transaction_id', transactionId)
  })
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      'X-Transaction-ID': transactionId,
    },
  }))

  return forward(operation)
})

export default sentryTransactionIdLink
