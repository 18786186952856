import iOS from './iOS'
import storage from 'local-storage-fallback'
import { LocalStorageKeyEnum } from 'lib/types/LocalStorageKeyEnum'

export default function removeLocalStorage(valueName: LocalStorageKeyEnum | string) {
  try {
    if (!iOS()) {
      return window.localStorage.removeItem(valueName)
    } else {
      return storage.removeItem(valueName)
    }
  } catch (err) {
    throw new Error((err as Error).message)
  }
}
