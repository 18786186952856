import { AdPartEnum, PlanSetting } from '../types'
import en from 'lib/helpers/locales/en/translation.json'
import { Text, View } from '@react-pdf/renderer'
import s from '../../../KodaContent/styles'
import { Trans } from 'react-i18next'
import BulletText from '../../../KodaContent/BulletText'

export default function getGA(t, defaultPartSettings): PlanSetting {
  const PRE_MDM_CONTACT_FIELDS_CONTENT = [
    t(
      'GA.medical_decision_maker.preMdmContactFieldsContent1',
      en.GA.medical_decision_maker.preMdmContactFieldsContent1
    ),
    t(
      'GA.medical_decision_maker.preMdmContactFieldsContent2',
      en.GA.medical_decision_maker.preMdmContactFieldsContent2
    ),
    t(
      'GA.medical_decision_maker.preMdmContactFieldsContent3',
      en.GA.medical_decision_maker.preMdmContactFieldsContent3
    ),
  ]

  const EXTRA_MDM_BULLETS = [
    t('GA.medical_decision_maker.addtionalMdmAllowances1', en.GA.medical_decision_maker.addtionalMdmAllowances1),
    t('GA.medical_decision_maker.addtionalMdmAllowances2', en.GA.medical_decision_maker.addtionalMdmAllowances2),
    t('GA.medical_decision_maker.addtionalMdmAllowances3', en.GA.medical_decision_maker.addtionalMdmAllowances3),
    t('GA.medical_decision_maker.addtionalMdmAllowances4', en.GA.medical_decision_maker.addtionalMdmAllowances4),
    t('GA.medical_decision_maker.addtionalMdmAllowances5', en.GA.medical_decision_maker.addtionalMdmAllowances5),
    t('GA.medical_decision_maker.addtionalMdmAllowances6', en.GA.medical_decision_maker.addtionalMdmAllowances6),
    t('GA.medical_decision_maker.addtionalMdmAllowances7', en.GA.medical_decision_maker.addtionalMdmAllowances7),
  ]

  const ga: PlanSetting = {
    state: 'Georgia',
    disclaimer: t('GA.disclaimer', en.GA.disclaimer),
    parts: [
      // Part 1
      {
        ...defaultPartSettings?.[AdPartEnum.health_care_choices],
      },
      // Part 2
      {
        ...defaultPartSettings?.[AdPartEnum.medical_decision_maker],
        pageTitle: 'Information for Medical Decision Maker (Health Care Agent)',
        description: t('GA.medical_decision_maker.description', en.GA.medical_decision_maker.description),
        addtionalMdmAllowances: (
          <>
            <Text style={s.textBold}>
              {t(
                'GA.medical_decision_maker.addtionalMdmAllowancesTitle',
                en.GA.medical_decision_maker.addtionalMdmAllowancesTitle
              )}
            </Text>
            {EXTRA_MDM_BULLETS?.map((text) => {
              return <BulletText key={text} fontSize={11} text={text} />
            })}
          </>
        ),
        additionalContent: (
          <>
            <Trans i18nKey="GA.medical_decision_maker.guidance">
              <Text style={s.textBold}>Guidance for Medical Decision Maker </Text>
              <Text style={s.formLabel}>
                When making health care decisions for you, your medical decision maker should think about what action
                would be consistent with past conversations you have had, your treatment preferences as expressed in
                Part 1 (if Part 1 is filled out), your religious and other beliefs and values, and how you have handled
                medical and other important issues in the past. If what you would decide is still unclear, then your
                medical decision maker should make decisions for you that your medical decision maker believes are in
                your best interest, considering the benefits, burdens, and risks of your current circumstances and
                treatment options.
              </Text>
            </Trans>
            <Trans i18nKey="GA.medical_decision_maker.revocation">
              <Text style={s.textBold}>Revocation: </Text>
              <Text style={s.formLabel}>
                If you are married, a future divorce or annulment of your marriage will revoke the selection of your
                current spouse as your medical decision maker.
              </Text>
              <Text style={s.formLabel}>
                If you are not married, a future marriage will revoke the selection of your medical decision maker
                unless the person you selected as your medical decision maker is your new spouse.
              </Text>
            </Trans>
          </>
        ),
        preMdmContactFieldsContent: (
          <>
            <Text style={s.textBold}>
              {t(
                'GA.medical_decision_maker.preMdmContactFieldsContentTitle',
                en.GA.medical_decision_maker.preMdmContactFieldsContentTitle
              )}
            </Text>
            {PRE_MDM_CONTACT_FIELDS_CONTENT?.map((text) => {
              return <BulletText key={text} fontSize={11} text={text} />
            })}
          </>
        ),
      },
      // Part 3
      {
        ...defaultPartSettings?.[AdPartEnum.sign_the_form],
        description: t('GA.sign_the_form.description', en.GA.sign_the_form.description),
        beforeYouSign: {
          requirements: [
            t('GA.sign_the_form.beforeYouSignRequirements_age', en.GA.sign_the_form.beforeYouSignRequirements_age),
            t(
              'GA.sign_the_form.beforeYouSignRequirements_signOrAcknowledge',
              en.GA.sign_the_form.beforeYouSignRequirements_signOrAcknowledge
            ),
          ],
          finalClause: (
            <View>
              <Text style={s.formLabel}>{t('GA.sign_the_form.finalClause1', en.GA.sign_the_form.finalClause1)}</Text>
              <Text style={s.formLabel}>{t('GA.sign_the_form.finalClause2', en.GA.sign_the_form.finalClause2)}</Text>
              <Text style={s.formLabel}>{t('GA.sign_the_form.finalClause3', en.GA.sign_the_form.finalClause3)}</Text>
              <Text style={s.textBold}>{t('GA.sign_the_form.finalClause4', en.GA.sign_the_form.finalClause4)}</Text>
            </View>
          ),
        },
        witnessSignatures: {
          preSignaturesContent: (
            <View style={{ marginBottom: 3 }}>
              <Text style={s.formLabel}>
                {t(
                  'GA.sign_the_form.witnessSignatures.preSignaturesContent',
                  en.GA.sign_the_form.witnessSignatures.preSignaturesContent
                )}
              </Text>
            </View>
          ),
        },
        witnessRequirements: {
          title: t('GA.sign_the_form.witnessRequirements_title', en.GA.sign_the_form.witnessRequirements_title), // OR "Witness and Notary Requirements"
          requirements: [
            {
              title: t(
                'planDoc.sign_the_form.witnessRequirements_witnessesMust',
                en.planDoc.sign_the_form.witnessRequirements_witnessesMust
              ),
              phrases: [
                t('planDoc.sign_the_form.witnessRequirements_be18', en.planDoc.sign_the_form.witnessRequirements_be18),
                t(
                  'planDoc.sign_the_form.witnessRequirements_beOfSoundMind',
                  en.planDoc.sign_the_form.witnessRequirements_beOfSoundMind
                ),
              ],
            },
            {
              title: t(
                'planDoc.sign_the_form.witnessRequirements_oneWitnessCannot',
                en.planDoc.sign_the_form.witnessRequirements_oneWitnessCannot
              ),
              phrases: [
                t(
                  'planDoc.sign_the_form.witnessRequirements_beEmployeeAgentOrStaff',
                  en.planDoc.sign_the_form.witnessRequirements_beEmployeeAgentOrStaff
                ),
              ],
            },
            {
              title: t(
                'planDoc.sign_the_form.witnessRequirements_yourWitnessCannot',
                en.planDoc.sign_the_form.witnessRequirements_yourWitnessCannot
              ),
              width: '100%',
              phrases: [
                t(
                  'planDoc.sign_the_form.witnessRequirements_beYourMDM',
                  en.planDoc.sign_the_form.witnessRequirements_beYourMDM
                ),
                t(
                  'GA.sign_the_form.witnessRequirements_bePersonWhoWillInherit',
                  en.GA.sign_the_form.witnessRequirements_bePersonWhoWillInherit
                ),
                t(
                  'GA.sign_the_form.witnessRequirements_bePersonInvolvedInHealthcare',
                  en.GA.sign_the_form.witnessRequirements_bePersonInvolvedInHealthcare
                ),
              ],
            },
          ],
          validityRequirements: [
            t(
              'planDoc.sign_the_form.validityRequirements_esignInThePresence',
              en.planDoc.sign_the_form.validityRequirements_esignInThePresence
            ),
            t(
              'planDoc.sign_the_form.validityRequirements_prinatAndSignBeforeTwoWitnessesOr',
              en.planDoc.sign_the_form.validityRequirements_prinatAndSignBeforeTwoWitnessesOr
            ),
            t(
              'GA.sign_the_form.validityRequirements_printAndSign',
              en.GA.sign_the_form.validityRequirements_printAndSign
            ),
          ],
        },
      },
    ],
  }

  return ga
}
