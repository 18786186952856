import styled from "styled-components";
import { Link } from "react-router-dom";

// COMPONENTS
import Button from 'components/common/Button';

import phoneSVG from "../../../routes/app-home/PlanConfigurator/icons/phone.svg";

const ModalContentScrollable = styled.div`
  overflow-y: auto;
  margin-top: 40px;
  max-height: calc(96vh - 150px);
  margin-bottom: 50px;
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints.medium}) {
    max-height: calc(78vh - 150px);
  }
`;

const CongratsBlock = styled.div`
  border: 1px solid #CBD2D9;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 24px;
  margin-bottom: 24px;
  margin-top: 40px;
`;

const CongratsInterviewBlock = styled(CongratsBlock)`
  margin-top: 0;
`;

const CongratsInterviewBlockTitle = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
  letter-spacing: -0.03em;
  margin-bottom: 16px;
`;

const CongratsInterviewBlockDescription = styled.div`
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.03em;
  padding-right: 20px;
  a {
    color: ${({ theme }) => theme.colors.neutral9};
    text-decoration: underline;
  }
`;

const CongratsInterviewBlockImgWrapper = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 16px 0;
`;

const CongratsInterviewBlockImg = styled.div`
  margin-right: 16px;
  width: 48px;
  height: 48px;
  background-color: #E6F6FF;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 65%;
  }
`;

const CongratsInterviewBlockText = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.03em;
  color: ${({ theme }) => theme.colors.neutral1};
`;

const CongratsInterviewBlockDescription1 = styled.div`
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.03em;
`;

const CongratsInterviewBlockDescription2 = styled.div`
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.03em;
  margin-top: 24px;
`;

const CongratsBlockTitle = styled.div`
  margin-bottom: 8px;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  letter-spacing: -0.03em;
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints.mediumSmall}) {
    font-size: 20px;
  }
`;
const CongratsBlockDescription = styled.div`
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: -0.03em;
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints.mediumSmall}) {
    font-size: 16px;
  }
`;

const CongratsOKButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 0 40px 20px 40px;
  z-index: 20;
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints.mediumSmall}) {
    padding: 0 20px 20px 20px;
  }
`;

interface PostSurveyCongratsWfpath1YesProps {
  closeClick: () => void;
};

const PostSurveyCongratsWfpath1Yes = ({ closeClick }: PostSurveyCongratsWfpath1YesProps) => {
  return (
    <ModalContentScrollable>
      <CongratsBlock>
        <CongratsBlockTitle>Congratulations!</CongratsBlockTitle>
        <CongratsBlockDescription>
          You’ve completed the first part of the study. You will be sent a $25 gift 
          card as a thank you for your participation.
        </CongratsBlockDescription>
      </CongratsBlock>
      <CongratsInterviewBlock>
        <CongratsInterviewBlockTitle>
          You can now participate in an Advance Care Planning
          interview with Koda for the chance to win a $250 gift card.
        </CongratsInterviewBlockTitle>
        <CongratsInterviewBlockDescription>
          All you need to do is schedule and complete a 30-minute
          phone interview. Information on how to schedule your
          interview is found on the{' '}<Link to="/app/acp-scheduling">ACP Scheduling</Link>
          {' '} page.
        </CongratsInterviewBlockDescription>
        <CongratsInterviewBlockImgWrapper to="/app/acp-scheduling">
          <CongratsInterviewBlockImg>
            <img src={phoneSVG} alt="" />
          </CongratsInterviewBlockImg>
          <CongratsInterviewBlockText>ACP Scheduling</CongratsInterviewBlockText>
        </CongratsInterviewBlockImgWrapper>
        <CongratsInterviewBlockDescription1>
          Then complete an Experience Comparison survey that will be emailed to you. 
        </CongratsInterviewBlockDescription1>
        <CongratsInterviewBlockDescription2>
          Please contact Andrew Brown at 336-716-0040 if you have any questions about 
          this process.
        </CongratsInterviewBlockDescription2>
      </CongratsInterviewBlock>
      <CongratsOKButtonWrapper>
        <Button
          onClick={closeClick}
          style={{
            display: "block",
            visibility: "visible",
          }}
        >
          OK
        </Button>
      </CongratsOKButtonWrapper>
    </ModalContentScrollable>
  )
}

export default PostSurveyCongratsWfpath1Yes;
