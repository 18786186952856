import { setContext } from '@apollo/client/link/context'
import { gql } from '@apollo/client'
import jwtDecode from 'jwt-decode'
import constants from 'lib/config'
import client from '../index'
import clearUsersLocalStorage from 'lib/helpers/clearUsersLocalStorage'
import getLocalStorage from 'lib/helpers/getLocalStorage'
import setLocalStorage from 'lib/helpers/setLocalStorage'

const refreshTokensMutation = gql`
  mutation refreshTokens($accessToken: String!, $refreshToken: String!) {
    refreshTokens(accessToken: $accessToken, refreshToken: $refreshToken) {
      sessionId
      tokens {
        refreshToken
        accessToken
      }
    }
  }
`

async function getRefreshedAccessTokenPromise(token, refreshToken, operation) {
  try {
    console.log(`======> refreshing tokens for ${operation?.operationName}`)
    const { data } = await client.mutate({
      mutation: refreshTokensMutation,
      variables: {
        accessToken: token,
        refreshToken,
      },
    })
    if (data?.refreshTokens?.tokens?.accessToken && data?.refreshTokens?.tokens?.refreshToken) {
      setLocalStorage(constants.authTokenName, data.refreshTokens.tokens.accessToken)
      setLocalStorage(constants.refreshTokenName, data.refreshTokens.tokens.refreshToken)
    }
    return data?.refreshTokens?.tokens?.accessToken
  } catch (error) {
    // logout, show alert or something
    clearUsersLocalStorage()
    client.resetStore()

    return error
  }
}

const isTokenExpired = (token) => {
  const currentTime = Date.now() / 1000
  const decodedToken = jwtDecode(token)
  return decodedToken.exp < currentTime
}

let pendingAccessTokenPromise: any = null

function getAccessTokenPromise(operation) {
  const token = getLocalStorage(constants.authTokenName)
  const refreshToken = getLocalStorage(constants.refreshTokenName)

  if (!token) return null

  if (!isTokenExpired(token)) {
    return new Promise((resolve) => resolve(token))
  }

  if (!pendingAccessTokenPromise) {
    pendingAccessTokenPromise = getRefreshedAccessTokenPromise(token, refreshToken, operation).finally(
      () => (pendingAccessTokenPromise = null)
    )
  }

  return pendingAccessTokenPromise
}

const OPERATIONS_THAT_DONT_NEED_AUTH_TOKEN = ['refreshTokens']

export const authLink2 = setContext(async (operation, { headers }) => {
  try {
    if (OPERATIONS_THAT_DONT_NEED_AUTH_TOKEN?.includes(operation?.operationName as string)) {
      return {
        headers,
      }
    }
    const accessToken = await getAccessTokenPromise(operation)
    return {
      headers: {
        ...headers,
        Authorization: accessToken ? accessToken : undefined,
      },
    }
  } catch (err) {
    return {
      headers: {
        ...headers,
        Authorization: undefined,
      },
    }
  }
})

export default authLink2
