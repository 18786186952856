import { AdPartEnum, PlanSetting } from '../types'
import en from 'lib/helpers/locales/en/translation.json'

export default function getCO(t, defaultPartSettings): PlanSetting {
  const co: PlanSetting = {
    state: 'Colorado',
    disclaimer: t(
      'CO.disclaimer',
      'Disclaimer: This document needs to be signed and have two witnesses sign as well or be notarized in order to be a legal document.'
    ),
    parts: [
      // Part 1
      {
        ...defaultPartSettings?.[AdPartEnum.health_care_choices],
      },
      // Part 2
      {
        ...defaultPartSettings?.[AdPartEnum.medical_decision_maker],
        description: t(
          'CO.medical_decision_maker.description',
          'This part is also known as a Medical Power of Attorney. It designates a person to make decisions for you when you are unable to speak for yourself'
        ),
      },
      // Part 3
      {
        ...defaultPartSettings?.[AdPartEnum.sign_the_form],
        description: t(
          'CO.sign_the_form.description',
          'You can fill out Part 1, Part 2, or both, but you need to sign the form in Part 3. To be legal, two witnesses also need to sign or a notary.'
        ),
        beforeYouSign: {
          requirements: [
            t(
              'planDoc.sign_the_form.beforeYouSignRequirements_age',
              en.planDoc.sign_the_form.beforeYouSignRequirements_age
            ),
            t(
              'planDoc.sign_the_form.beforeYouSignRequirements_notary',
              'have your signature acknowledged before a notary public; or'
            ),
            t(
              'planDoc.sign_the_form.beforeYouSignRequirements_witnesses',
              'sign in the presence of two competent adult witnesses.'
            ),
          ],
          finalClause: t(
            'planDoc.sign_the_form.beforeYouSignFinalClause',
            'This Advanced Directive accurately expresses my wishes.'
          ),
        },
        witnessRequirements: {
          title: t('planDoc.sign_the_form.witnessRequirements_title', 'Witnesses or Notary Requirements'), // OR "Witness and Notary Requirements"
          requirements: [
            {
              title: t('planDoc.sign_the_form.witnessRequirements_witnessesMust'),
              phrases: [
                t('planDoc.sign_the_form.witnessRequirements_be18'),
                t('planDoc.sign_the_form.witnessRequirements_seeYouSign', 'see you sign the form'),
              ],
            },
            {
              title: t('planDoc.sign_the_form.witnessRequirements_witnessesCannotBe', 'These witnesses cannot be:'),
              phrases: [
                t(
                  'planDoc.sign_the_form.witnessRequirements_personSigningAtYourDirection',
                  'A person signing the document at your direction'
                ),
                t('planDoc.sign_the_form.witnessRequirements_aPhysician', 'A physician'),
                t(
                  'planDoc.sign_the_form.witnessRequirements_EmployeeOrPhysician',
                  'An employee of your attending physician or of a health care facility in which you are a patient when you sign your document'
                ),
                t('planDoc.sign_the_form.witnessRequirements_haveClaimAgainstEstate'),
                t('planDoc.sign_the_form.witnessRequirements_personEntitledToEstate'),
              ],
            },
          ],
          validityRequirements: [
            t('planDoc.sign_the_form.validityRequirements_esignInThePresence'),
            t('planDoc.sign_the_form.validityRequirements_prinatAndSignBeforeTwoWitnessesOr'),
            t('planDoc.sign_the_form.validityRequirements_prinatAndSignBeforeNotary'),
          ],
        },
        notary: {
          disclaimer: t('CO.notary.disclaimer'),
        },
      },
    ],
  }

  return co
}
