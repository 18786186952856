import { useEffect, useCallback } from 'react'
import queryString from 'query-string'
import styled from 'styled-components'
import { useHistory, useLocation } from 'react-router-dom'
import Button from 'components/common/Button'
import Image from 'components/common/Image'
import PreloadImages from 'components/common/PreloadImages'
import DashboardButton from 'components/common/DashboardButton'
import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'
import Step4 from './Step4'
import Step5 from './Step5'
import Step6 from './Step6'
import ExitButton from 'components/common/ExitButton'
// LIB
import identifyUser from 'lib/helpers/identifyUser'
// images
import personQuestionSVG from './images/person-question.svg'
import calendarSVG from './images/calendar.svg'
import checklistSVG from './images/checklist.svg'
import contractSVG from './images/contract.svg'
import createPlanSVG from './images/create-plan.svg'
import deathSVG from './images/death.svg'
import notarySVG from './images/notary.svg'
import pencilSVG from './images/pencil.svg'
import relationshipSVG from './images/relationship.svg'
import shareYourPlanSVG from './images/share-your-plan.svg'
import shareSVG from './images/share.svg'
import shieldSVG from './images/shield.svg'
import thumbsupSVG from './images/thumbsup.svg'
import ArrowLeftOutlined from 'components/icons/ArrowLeftOutlined'
import {
  PatientActionHistoryItemEnum,
  useCreateOneActionHistoryItemMutation,
  useGetOnePatientQuery,
  UserProfile,
} from 'generated/graphql'
import i18n from 'lib/helpers/i18n'

const IMAGES_TO_LOAD: string[] = [
  personQuestionSVG,
  calendarSVG,
  checklistSVG,
  contractSVG,
  createPlanSVG,
  deathSVG,
  notarySVG,
  pencilSVG,
  personQuestionSVG,
  relationshipSVG,
  shareYourPlanSVG,
  shareSVG,
  shieldSVG,
  thumbsupSVG,
]

const Container = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 650px;
  max-width: 95%;
  margin: auto;
  padding-top: 2.5%;
  padding-bottom: 2.5%;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    padding-top: 80px;
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    padding-bottom: 32%;
  }
`

export const Title = styled.h1`
  font-weight: 600;
  margin: 0px;
  font-size: 32px;
  margin-top: 8px;
  color: ${(p) => p.theme.colors.neutral1};
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    margin-top: 16px;
    font-size: 24px;
    line-height: 26px;
  }
`

export const Caption = styled.p`
  font-weight: 400;
  margin: 0px;
  font-size: 20px;
  color: ${(p) => p.theme.colors.neutral4};
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    font-size: 18px;
    line-height: 22px;
  }
`

export const TitleIcon = styled(Image)`
  height: 88px;
  width: 88px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 16px;
`

export const TitleContainer = styled.div`
  min-height: 60px;
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  max-width: 100%;
`

export const StepText = styled.h2`
  font-size: 22px;
  font-weight: 500;
  margin: 0px;
  color: ${(p) => p.theme.colors.neutral1};
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    font-size: 20px;
    line-height: 18px;
  }
`

export const ContentImageContainer = styled.div`
  margin: auto;
  max-width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Content = styled.div`
  padding-top: 24px;
  padding-bottom: 96px;
  min-height: 100px;
`

export const StepContainer = styled.div`
  min-height: 60px;
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`

export const StepIcon = styled(Image)`
  height: 64px;
  width: 64px;
  border-radius: ${(p) => (p.borderRadius ? p.borderRadius : '50%')};
  display: inline-block;
  margin-right: 16px;
  border: ${(p) => {
    if (p.border) {
      return `1px solid ${p.theme.colors.neutral9}`
    }
    return `0px solid #000`
  }};
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    height: 48px;
    width: 48px;
  }
`

const ContinueContainer = styled.div`
  @media only screen and (max-width: 1024px) {
    position: absolute;
    bottom: 20px;
    right: 16px;
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    margin-left: 16px;
    button {
      width: 165px !important;
    }
  }
`

const MobileBackBtn = styled.div`
  display: none;
  @media only screen and (max-width: 1024px) {
    display: inherit;
    position: absolute;
    bottom: 20px;
    left: 16px;
  }
`

const ButtonContainer = styled.div`
  padding-top: 24px;
  padding-bottom: 24px;
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @media only screen and (max-width: 1024px) {
    position: fixed;
    bottom: 0px;
    height: 100px;
    background: ${(p) => p.theme.colors.neutral10};
    right: 0px;
    left: 0px;
  }
`

const BackButtonContainer = styled.div`
  position: absolute;
  left: 16px;
  bottom: 24px;
  display: inherit;
  @media only screen and (max-width: 1024px) {
    display: none;
  }
`

const DashboardBtnContainer = styled.div`
  position: fixed;
  top: 16px;
  left: 16px;
  z-index: 1098;
`

const HeaderContainer = styled.div`
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    background: white;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    height: 80px;
    z-index: 1098;
  }
`

interface AppOnboardingProps {
  currentUser: UserProfile
}

export default function AppOnboarding(props: AppOnboardingProps) {
  const history = useHistory()
  const location = useLocation()
  const [createOneActionHistoryItemMutation] = useCreateOneActionHistoryItemMutation()
  const { data } = useGetOnePatientQuery({
    variables: {
      userId: props?.currentUser?.id,
    },
  })

  useEffect(() => {
    if (props?.currentUser?.id && data?.getOnePatient?.clinicId) {
      // identify the user in mixpanel & fullstory
      identifyUser(props?.currentUser?.id, data?.getOnePatient?.clinicId)
    }
  }, [props?.currentUser?.id, data?.getOnePatient?.clinicId])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  // step: tells us which onboarding step they're on
  // repeat: if true, this tells us it's not the users first time through the onboarding, and so we can make UI changes accordingly
  const { step = '1', repeat }: { step?: string; repeat?: string } = queryString.parse(location.search)

  const onExit = useCallback(() => {
    createOneActionHistoryItemMutation({
      variables: {
        name: PatientActionHistoryItemEnum.HasViewedOnboarding,
        value: 'true',
      },
    })
    history.push('/app/home')
  }, [history, createOneActionHistoryItemMutation])

  const onNext = useCallback(() => {
    if (parseInt(step) >= 6) {
      return onExit()
    }

    let newUrl = {
      step: parseInt(step) + 1,
      repeat,
    }

    history.push(`/app/onboarding?${queryString.stringify(newUrl)}`)
  }, [history, repeat, step, onExit])

  const onBack = useCallback(() => {
    let newUrl = {
      step: parseInt(step) - 1,
      repeat,
    }
    history.push(`/app/onboarding?${queryString.stringify(newUrl)}`)
  }, [history, repeat, step])

  useEffect(() => {
    if (step && document?.body) {
      window.scrollTo(0, 0)
    }
  }, [step])

  // Add event listeners to capture usage of keys
  useEffect(() => {
    const downHandler = ({ keyCode }) => {
      // when pressing left
      if (keyCode === 37 && onBack) {
        onBack()
      }
      // when pressing down
      if (keyCode === 40 && onBack) {
        onBack()
      }
      // when pressing up
      if (keyCode === 38 && onNext) {
        onNext()
      }
      // when pressing right
      if (keyCode === 39 && onNext) {
        onNext()
      }
      // when pressing enter
      if (keyCode === 13 && onNext) {
        onNext()
      }
    }
    window.addEventListener('keydown', downHandler)
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('keydown', downHandler)
    }
  }, [onBack, onNext]) // Empty array ensures that effect is only run on mount and unmount

  return (
    <Container>
      <HeaderContainer>
        <ExitButton onClick={onExit} />
        {step === '1' && repeat === 'true' && (
          <DashboardBtnContainer>
            <DashboardButton />
          </DashboardBtnContainer>
        )}
      </HeaderContainer>
      <div style={{ maxWidth: '100%' }}>
        {step === '1' && <Step1 />}
        {step === '2' && <Step2 />}
        {step === '3' && <Step3 />}
        {step === '4' && <Step4 />}
        {step === '5' && <Step5 />}
        {step === '6' && <Step6 />}
        <ButtonContainer>
          <>
            <ContinueContainer>
              <Button
                onClick={onNext}
                style={{ margin: 'auto', width: 165 }}
                pulse={true}
                data-testid="onboarding-continue-btn"
              >
                {step === '6' ? i18n.t('medicalCarePlansFaq.startMyPlan') : i18n.t('medicalCarePlansFaq.continue')}
              </Button>
            </ContinueContainer>
            <BackButtonContainer>
              {step !== '1' && (
                <Button data-testid="onboarding-back-btn" onClick={onBack} grey>
                  <ArrowLeftOutlined
                    style={{
                      marginRight: 4,
                      fontSize: 14,
                      position: 'relative',
                      left: -2,
                      top: -1,
                    }}
                  />
                  {i18n.t('medicalCarePlansFaq.goBack')}
                </Button>
              )}
            </BackButtonContainer>
          </>
          <MobileBackBtn>
            {step !== '1' && (
              <Button grey onClick={step === '1' && repeat === 'true' ? () => onExit() : onBack}>
                <ArrowLeftOutlined
                  style={{
                    marginRight: 4,
                    fontSize: 14,
                    position: 'relative',
                    left: -2,
                    top: -1,
                  }}
                />
                {i18n.t('medicalCarePlansFaq.back')}
              </Button>
            )}
          </MobileBackBtn>
        </ButtonContainer>
      </div>
      <PreloadImages imagesToLoad={IMAGES_TO_LOAD} />
    </Container>
  )
}
