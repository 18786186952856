import { Document } from '@react-pdf/renderer'
import { Patient } from 'generated/graphql'
import i18n from 'lib/helpers/i18n'
import { QuestionItem } from 'lib/types/QuestionItem'
import KodaContent from '../KodaContent'
import ExtraPage from '../KodaContent/ExtraPage'

interface DesktopDocProps {
  data: Patient
  title: string
  questions: QuestionItem[]
  createdStamp: string
  includeCoverLetter?: boolean
  clinicName: string
  faxNumber?: string
  stateName?: string
  adImages?: string[]
  hasSupportiveCare?: boolean
}

export default function DesktopDoc({
  data,
  title,
  questions,
  createdStamp,
  includeCoverLetter = false,
  clinicName,
  faxNumber,
  stateName,
  adImages = [],
  hasSupportiveCare,
}: DesktopDocProps) {
  return (
    <Document title={title}>
      <KodaContent
        data={data}
        questions={questions}
        createdStamp={createdStamp}
        includeCoverLetter={includeCoverLetter}
        clinicName={clinicName}
        faxNumber={faxNumber}
        stateName={stateName}
        hasSupportiveCare={hasSupportiveCare}
      />
      {adImages.map((extraPage) => {
        return (
          <ExtraPage
            key={extraPage}
            extraPage={extraPage}
            lastUpdated={`${i18n.t('DocPage.lastUpdated', 'Last updated: ')} ${createdStamp}`}
          />
        )
      })}
    </Document>
  )
}
