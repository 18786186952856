import React, {useEffect} from 'react';
import ms from 'ms';
// COMPONENTS
import {StepContainer} from '../index';
import ButtonsContainer from '../ButtonsContainer';
import Animated from 'components/common/Animated';
import ElephantChat from 'components/common/ElephantChat';
import { Patient } from 'generated/graphql';

interface SectionBreakProps {
  text?: string | JSX.Element;
  buttonAlign?: string;
  onNext: (plan?: Patient) => void;
  onBack?: () => void;
  nextButton?: string;
  img?: string;
  textAlign?: string;
  goToNextTimer?: number;
}

export default function SectionBreak({
  text,
  buttonAlign,
  onNext,
  onBack,
  nextButton,
  img,
  textAlign,
  goToNextTimer = ms('6.5s'),
}: SectionBreakProps) {
  // we want to go to the next page after X seconds
  useEffect(() => {
    let timer = setTimeout(() => {
      onNext();
    }, goToNextTimer);
    return () => {
      clearTimeout(timer);
    };
  }, [goToNextTimer, onNext]);

  // return component
  return (
    <StepContainer textAlign={textAlign}>
      <div>
        <Animated>
          {img && (
            <img
              src={img}
              alt={text as string}
              height="100"
              style={{margin: 'auto', marginBottom: 24, display: 'block'}}
            />
          )}
          <ElephantChat text={text} style={{marginBottom: 24}} />
        </Animated>
        <ButtonsContainer
          onNext={onNext}
          onBack={onBack}
          nextButton={nextButton}
          buttonAlign={buttonAlign}
        />
      </div>
    </StepContainer>
  );
}
