import { useState, useEffect, useMemo } from 'react'
import queryString from 'query-string'
import DocumentTitle from 'react-document-title'
import styled from 'styled-components'
// COMPONENTS
import Loading from 'components/common/Loading'
import InactiveModal from 'components/common/InactiveModal'
import PostSurveyCongrats from 'components/common/PostSurveyCongrats'
import EditSurvey from './EditSurvey'
import ViewDirective from './ViewDirective'
import EmailDirective from './SharePlan'
import PlanConfigurator from './PlanConfigurator'
import CompletedPlans from './CompletedPlans'
import PlanConfiguratorHelper from './PlanConfigurator/PlanConfiguratorHelper'
import ActionDrawer from './ActionDrawer'
import ESignAdditionalInformation from './ESignAdditionalInformation'
import Help from './Help'
// APOLLO
import { useGetOnePatientQuery, UserProfile } from 'generated/graphql'
// LIB
import useFullStory from 'lib/hooks/useFullStory'
import constants from 'lib/config'
import identifyUser from 'lib/helpers/identifyUser'
import MakeOfficial from './MakeOfficial'
import NextStepsLearnMore from 'components/common/SurveyForm/NextStepsLearnMore'
import useUrlChange from 'lib/hooks/useUrlChange'

const Outter = styled.div`
  background: ${(p) => p.theme.colors.neutral10};
  min-height: 100vh;
  padding-bottom: 32px;
  position: relative;
  padding-top: 16px;
`

const Container = styled.div<{ drawerExpanded: boolean }>`
  padding-top: 32px;
  padding-left: ${(p) => (p.drawerExpanded ? '160px' : 'auto')};
  width: ${(p) => (p.drawerExpanded ? '1100px' : '1100px')};
  height: 100%;
  max-width: 95%;
  margin: auto;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    width: ${(p) => (p.drawerExpanded ? '820px' : '1100px')};
    padding-left: 0;
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    width: ${(p) => (p.drawerExpanded ? '620px' : '1100px')};
    padding-left: 0;
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.xSmall}) {
    padding-top: 0px;
    padding-left: 0;
    width: ${(p) => (p.drawerExpanded ? '370px' : '1100px')};
  }
`

export enum APP_PAGES_ENUMS {
  EDIT_PLAN = 'editPlan',
  VIEW_PLAN = 'viewPlan',
  NOTARIZE = 'notarize',
  ESIGN_ADDITIONAL_INFORMATION = 'esignAdditionalInformation',
  NEXT_STEPS_LEARN_MORE = 'nextSteps',
}

const getTitle = (mode) => {
  if (!mode) return `${constants.appName}  | Home`
  if (mode === APP_PAGES_ENUMS.EDIT_PLAN) {
    return `${constants.appName} | Edit Plan`
  }
  if (mode === APP_PAGES_ENUMS.VIEW_PLAN) {
    return `${constants.appName} | Advance Directive`
  }
  if (mode === APP_PAGES_ENUMS.NOTARIZE) {
    return `${constants.appName} | Free Virtual Notarization`
  }
  if (mode === APP_PAGES_ENUMS.ESIGN_ADDITIONAL_INFORMATION) {
    return `${constants.appName} | ESign Additional Information`
  }
  if (mode === 'emailDirective') {
    return `${constants.appName} | Email My Directive`
  }
  return constants.appName
}

const planHelpers = new PlanConfiguratorHelper()

const NextSteps = ({ userId, patient }) => {
  const { onUrlChange } = useUrlChange()
  return (
    <>
      <NextStepsLearnMore
        userId={userId}
        patient={patient}
        onContinue={() => onUrlChange({ mode: APP_PAGES_ENUMS.VIEW_PLAN })}
      />
    </>
  )
}

interface AppHomeProps {
  location: {
    search: string
  }
  currentUser: UserProfile
}

export default function AppHome(props: AppHomeProps) {
  const searchObj = useMemo(() => queryString.parse(props.location && props.location.search), [props.location])
  const [expanded, setExpanded] = useState(false) // is the drawer expanded
  const [congratsModal, setCongratsModal] = useState(false)

  const { mode } = searchObj

  const { data, loading, error } = useGetOnePatientQuery({
    variables: {
      userId: props?.currentUser?.id,
    },
  })
  // init fullstory
  useFullStory()

  useEffect(() => {
    if (props?.currentUser?.id && data?.getOnePatient?.clinicId) {
      // identify the user in mixpanel & fullstory
      identifyUser(props?.currentUser?.id, data?.getOnePatient?.clinicId)
    }
  }, [props?.currentUser?.id, data?.getOnePatient?.clinicId])

  useEffect(() => {
    if (searchObj?.postSurveyComplete) {
      setCongratsModal(true)
    }
  }, [searchObj])

  const patient = data?.getOnePatient

  if (loading) return <Loading />

  if (error) return 'error...'

  if (!patient?.guideTemplate?.sections) return <div>No Plan Found</div>

  const guideComplete = planHelpers.getguideComplete({
    patient,
    modules: patient?.guideTemplate?.sections,
  })

  return (
    <DocumentTitle title={getTitle(mode)}>
      <Outter data-testid="koda-homepage">
        {mode && mode === APP_PAGES_ENUMS.NEXT_STEPS_LEARN_MORE && (
          <NextSteps patient={patient} userId={props?.currentUser?.id} />
        )}
        <Container drawerExpanded={expanded}>
          <ActionDrawer
            setExpanded={setExpanded}
            expanded={expanded}
            mode={mode as string | string[]}
            patient={patient}
            guideComplete={guideComplete}
          />
          {mode && mode === APP_PAGES_ENUMS.EDIT_PLAN && <EditSurvey plan={patient} />}

          {mode && mode === 'help' && <Help patient={patient} />}

          {mode && mode === 'completedPlans' && <CompletedPlans plan={patient} />}
          {mode && mode === APP_PAGES_ENUMS.VIEW_PLAN && (
            <ViewDirective patient={patient} guideComplete={guideComplete} currentUser={props?.currentUser} />
          )}
          {mode && mode === APP_PAGES_ENUMS.NOTARIZE && (
            <MakeOfficial patient={patient} currentUser={props?.currentUser} />
          )}
          {mode && mode === APP_PAGES_ENUMS.ESIGN_ADDITIONAL_INFORMATION && (
            <ESignAdditionalInformation patient={patient} currentUser={props?.currentUser} />
          )}
          {mode && mode === 'emailDirective' && (
            <EmailDirective patient={patient} currentUser={props.currentUser} guideComplete={guideComplete} />
          )}
          {!mode && <PlanConfigurator plan={patient} guideComplete={guideComplete} />}
        </Container>
        <InactiveModal />
        {congratsModal && <PostSurveyCongrats closeClick={() => setCongratsModal(false)} patient={patient} />}
      </Outter>
    </DocumentTitle>
  )
}
