import type { ReactNode } from 'react';
import {Animated, AnimationString} from 'react-animated-css';

type AnimatedComponentProps = {
  children?: ReactNode;
  back?: boolean;
  animationIn?: AnimationString;
};

export default function AnimatedComponent({
  children,
  back,
  animationIn = 'fadeInUp',
}: AnimatedComponentProps) {
  return (
    <Animated
      animationIn={!back ? animationIn : 'fadeInUp'}
      animationOut="fadeOut"
      style={{height: '100%', width: '100%'}}
      isVisible
    >
      {children}
    </Animated>
  );
}
