import React from 'react'
import {
  Title,
  Caption,
  TitleIcon,
  Content,
  ContentImageContainer,
  TitleContainer,
  StepText,
  StepContainer,
  StepIcon,
} from '../index'
// LIB
import i18n from 'lib/helpers/i18n'
import womanPNG from './woman.png'
import { Trans } from 'react-i18next'

import contractSVG from '../images/contract.svg'
import notarySVG from '../images/notary.svg'
import shieldSVG from '../images/shield.svg'
import Testimonial from 'components/common/Testimonial'

export default function Step5() {
  return (
    <div>
      <TitleContainer>
        <TitleIcon src={contractSVG} alt="Step 3: Make your Care Plan Official" />
        <Title data-testid="step5-title">{i18n.t('medicalCarePlansFaq.carePlanOfficial')}</Title>
      </TitleContainer>
      <Caption>{i18n.t('medicalCarePlansFaq.makeYourCarePlan')}</Caption>
      <Content style={{ paddingLeft: '10%' }}>
        <StepContainer>
          <StepIcon src={shieldSVG} alt="Most states require these documents to be notarized." borderRadius="0px" />
          <StepText>{i18n.t('medicalCarePlansFaq.mostStatesRequire')}</StepText>
        </StepContainer>
        <StepContainer>
          <StepIcon src={notarySVG} alt="You can schedule a FREE virtual notary session with us." borderRadius="0px" />
          <StepText>
            <Trans>{i18n.t('medicalCarePlansFaq.yourStateRequirements')}</Trans>
          </StepText>
        </StepContainer>
      </Content>
      <ContentImageContainer>
        <Testimonial
          text={
            <Trans i18nKey={'onboarding.testimonialMary'}>
              I did a virtual notary with Koda Health and was <strong>amazed how easy it was</strong>. I didn’t even
              have to leave my house!
            </Trans>
          }
          imageRight={womanPNG}
        />
      </ContentImageContainer>
    </div>
  )
}
