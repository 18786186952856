import { Text, View, Image } from '@react-pdf/renderer'
import logoPNG from 'components/common/PlanDoc/horizontal-logo.png'
import theme from 'lib/theme'
import TableOfContentsItem from '../../KodaContent/TableOfContentsItem'
import s from 'components/common/PlanDoc/KodaContent/styles'
import i18n from 'lib/helpers/i18n'
import { Trans } from 'react-i18next'

const LineBreak = () => (
  <View
    style={{
      height: 1,
      backgroundColor: theme.colors.neutral9,
    }}
  />
)

const Part = ({ part }) => {
  return (
    <>
      <TableOfContentsItem img={part.img} text={part.name} />
      <Text style={{ ...s.textLeftMargin, ...s.text }}>{part.description}</Text>
    </>
  )
}

export default function CoverPage({ settings }) {
  return (
    <View>
      <View
        style={{
          marginTop: 24,
          display: 'flex',
          justifyContent: 'center',
          marginHorizontal: '25%',
        }}
      >
        <Image src={logoPNG} style={{ width: '250px' }} />
      </View>
      <View style={{ marginTop: 32 }}>
        <Text style={s.title}>
          <Trans i18nKey="MasterPlanDocCoverPage.title">
            {{ location: settings?.state || '' }} Advance Health Care Directive
          </Trans>
        </Text>
      </View>
      <View style={{ marginTop: 20 }}>
        <Text
          style={{
            color: 'red',
            fontSize: 16,
            textAlign: 'center',
            width: '80%',
            margin: 'auto',
          }}
        >
          {settings.disclaimer}
        </Text>
      </View>
      <View style={{ marginTop: 15, marginBottom: 15 }}>
        <Text style={s.subtitle}>
          <Trans i18nKey="MasterPlanDocCoverPage.numberOfParts">
            This form has {{ numberOfParts: settings?.parts?.length }} parts:
          </Trans>
        </Text>
        {settings?.parts?.map((part) => {
          return <Part key={part.id} part={part} />
        })}
      </View>
      <LineBreak />
      <View
        style={{
          marginTop: 10,
          marginBottom: 0,
          backgroundColor: theme.colors.primary10,
          padding: 16,
          borderRadius: 5,
        }}
      >
        <Text style={s.blueText}>{i18n.t('MasterPlanDocCoverPage.share')}</Text>
      </View>
    </View>
  )
}
