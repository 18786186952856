import styled from 'styled-components';

export const ModalTitle = styled.h2`
  font-size: 20px;
  line-height: 150%;
  display: block;
  font-weight: 600;
  margin-bottom: 8px;
  font-family: ${(p) => p.theme.fontFamily};
  color: ${(p) => p.theme.colors.neutral2};
  line-height: 125%;
  max-width: 100%;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    font-size: 18px;
  }
`;

export default ModalTitle;
