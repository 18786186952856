import React from 'react'
import ErrorState from '../ErrorState'
import i18n from 'lib/helpers/i18n'
import logError from 'lib/helpers/logError'

const PageHasBeenForceRefreshed = 'page-has-been-force-refreshed'

const retryPageLoading = () => {
  const pageHasAlreadyBeenForceRefreshed = JSON.parse(
    window.localStorage.getItem(PageHasBeenForceRefreshed) || 'false'
  ) as boolean

  if (!pageHasAlreadyBeenForceRefreshed) {
    window.localStorage.setItem(PageHasBeenForceRefreshed, 'true')
    return window.location.reload()
  } else {
    window.localStorage.setItem(PageHasBeenForceRefreshed, 'false')
  }
}

interface ErrorBoundaryProps {
  children: React.ReactNode
}
export default class ErrorBoundary extends React.Component<ErrorBoundaryProps, { hasError: boolean }> {
  constructor(props: Readonly<ErrorBoundaryProps>) {
    super(props)
    this.state = { hasError: false }
  }
  componentDidCatch(error: unknown, info: object | undefined) {
    retryPageLoading()
    this.setState({ hasError: true })
    console.log(error, info)
    logError(error, info)
  }
  render() {
    if (this.state.hasError) {
      return <ErrorState title={i18n.t('ErrorBoundary.title', 'We seem to be having trouble connecting...')} />
    }
    return this.props.children
  }
}
