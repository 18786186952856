import styled from 'styled-components'
import queryString from 'query-string'
import { useHistory, useLocation } from 'react-router-dom'
// COMMON
import Button from 'components/common/Button'
// LIB
import successSVG from 'lib/media/success-circle.svg'
import i18n from 'lib/helpers/i18n'

const Container = styled.div`
  background: ${(p) => p.theme.colors.neutral10};
  height: 100vh;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`

const Content = styled.div`
  background: #fff;
  padding: 24px;
  border-radius: 5px;
  width: 500px;
  max-width: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
`

const Image = styled.img`
  height: 80px;
  width: 80px;
  margin: auto;
`

const Title = styled.h1`
  font-size: 22px;

  color: ${(p) => p.theme.colors.neutral3};
  margin: 0px;
  font-weight: 600;
  margin-top: 16px;
`

const Subtitle = styled.h3`
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  color: ${(p) => p.theme.colors.neutral5};
  margin: 0px;
  margin-bottom: 16px;
`

export default function PublicGuestComplete() {
  const history = useHistory()
  const location = useLocation()
  const { clinicSlug } = queryString.parse(location.search)
  return (
    <Container>
      <Content>
        <div>
          <Image src={successSVG} />
          <Title>{i18n.t('PublicGuestComplete.title', `We've sent you an email!`)}</Title>
          <Subtitle>
            {i18n.t(
              'PublicGuestComplete.subtitle',
              `It has information about creating a Koda account so you can continue when you’re ready.`
            )}
          </Subtitle>
          <Button onClick={() => history.push(`/${clinicSlug}`)} style={{ width: 120 }}>
            {i18n.t('PublicGuestComplete.submitBtn', `Okay`)}
          </Button>
        </div>
      </Content>
    </Container>
  )
}
