import i18n from 'lib/helpers/i18n'
import passwordSchema from 'lib/helpers/passwordSchema'
import en from 'lib/helpers/locales/en/translation.json'

export default function useCheckPasswordErrors() {
  const checkPasswordErrors = (password: string): string | null => {
    let errors = passwordSchema.validate(password, { list: true })
    if (errors && typeof errors !== 'boolean' && errors.length > 0) {
      if (errors.includes('min')) {
        return i18n.t('PasswordValidation.min8', en.PasswordValidation.min8)
      }
      if (errors.includes('uppercase')) {
        return i18n.t('PasswordValidation.requiresUppercase', en.PasswordValidation.requiresUppercase)
      }
      if (errors.includes('digits')) {
        return i18n.t('PasswordValidation.atleastOneNumber', en.PasswordValidation.atleastOneNumber)
      }
      if (errors.includes('oneOf')) {
        return i18n.t('PasswordValidation.oneOf', en.PasswordValidation.oneOf)
      }
    }
    return null
  }

  return { checkPasswordErrors, passwordSchema }
}
