import React, { useState } from 'react';
import styled from 'styled-components';
import ButtonsContainer from '../ButtonsContainer';
import QuestionText from 'components/text/QuestionText';
import { ErrorText, QuestionsContainer, StepContainer } from '../index';
import Animated from 'components/common/Animated';
import checkSVG from './check.svg';
import Directions from 'components/text/Directions';
import { Patient } from 'generated/graphql';
import { useTranslation } from 'react-i18next';

const Option = styled.div<{ active: boolean; size: number }>`
  border-radius: 50%;
  height: ${(p) => p.size}px;
  width: ${(p) => p.size}px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: color 0.3s ease, background-color 0.3s ease,
    border-color 0.3s ease, width 0.3s ease, opacity 0.3s ease;
  background: ${(p) =>
    p.active ? p.theme.colors.primary7 : p.theme.colors.primary9};
  &:hover {
    background: ${(p) =>
    p.active ? p.theme.colors.primary7 : p.theme.colors.primary8};
  }
`;

const ChoiceList = styled.div`
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: -8px;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: stretch;
  margin-right: -8px;
`;

const OptionCol = styled.div`
  width: calc(16% - 10px + 2px);
  @media only screen and (max-width: 1023px) {
    width: calc(33% - 10px + 2px);
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    width: calc(50% - 10px + 2px);
  }
`;

type OptionType = {
  label: string;
  value: string | number;
}

interface OptionItemProps {
  option: OptionType;
  fieldName: string;
  active: boolean;
  size: number;
  onChange: (e: { [x: string]: string | number }) => void;
}

const OptionItem = ({ option, fieldName, active, size, onChange }: OptionItemProps) => (
  <OptionCol>
    <div style={{ textAlign: 'center' }}>
      <div
        style={{
          height: 90,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Option
          active={active}
          size={size}
          data-testid={`persona-question-option-item-${option.value}`}
          onClick={() =>
            onChange({
              [fieldName]: option.value,
            })
          }
        >
          {active && <img src={checkSVG} alt="Check" width="24" />}
        </Option>
      </div>
      <label>{option.label}</label>
    </div>
  </OptionCol>
);

interface PersonaQuestionProps {
  text?: string | JSX.Element;
  required?: boolean;
  defaultValue?: string | number;
  onNext: (plan?: Patient) => void;
  onBack?: () => void;
  nextButton?: string;
  fieldName?: string;
  options?: any[];
  onChange: (e: { [x: string]: string | number }) => void;
  hideButtons?: boolean;
  editMode?: boolean;
  isMobile?: boolean;
}

export default function PersonaQuestion({
  text,
  required,
  defaultValue,
  onNext,
  onBack,
  nextButton,
  fieldName,
  options,
  onChange,
  hideButtons,
  editMode,
  isMobile,
}: PersonaQuestionProps) {
  const [error, setError] = useState<string | null>(null);
  const { t } = useTranslation();

  return (
    <StepContainer key={fieldName} editMode={editMode} isMobile={isMobile}>
      <div style={{ maxWidth: '100%' }}>
        <Animated>
          <QuestionText data-testid="persona-question-text">
            {text}
          </QuestionText>
          <Directions>{t('pleaseSelectOne')}</Directions>
          <QuestionsContainer>
            <ChoiceList>
              {options?.map((option, i) => {
                let size = 80;
                // size the size of the dot
                if (i === 1 || i === 4) {
                  size = 64;
                }
                if (i === 2 || i === 3) {
                  size = 48;
                }
                let active = option.value === defaultValue;

                return (
                  <OptionItem
                    key={option.value}
                    onChange={onChange}
                    active={active}
                    size={size}
                    fieldName={fieldName as string}
                    option={option}
                  />
                );
              })}
            </ChoiceList>
            {error && <ErrorText>{error}</ErrorText>}
          </QuestionsContainer>
        </Animated>
        <ButtonsContainer
          onNext={() => {
            // just in case, don't let them submit if the field is required and not filled in
            if (required && !defaultValue) {
              return setError('This field is required');
            }
            // if all is good, we can call onNext to move to the next question
            onNext();
          }}
          onBack={onBack}
          hideButtons={hideButtons}
          nextButton={nextButton}
          hideNext={required && !defaultValue}
        />
      </div>
    </StepContainer>
  );
}
