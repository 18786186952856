import { useTranslation } from 'react-i18next';
import styled from "styled-components";

// COMPONENTS
import Progress from "components/common/Progress";
import DashboardButton from "components/common/DashboardButton";

const Container = styled.div`
  height: 104px;
  width: 100%;
  background: ${(p) => p.theme.colors.neutral10};
`;

const Inner = styled.div`
  width: 600px;
  max-width: 95%;
  display: flex;
  align-items: center;
  height: 100px;
  position: relative;
  margin: auto;
  padding: 0 10px;
`;

const H1 = styled.h1`
  font-size: 18px;
  margin: 0px;
`;

const ProgressText = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: ${(p) => p.theme.colors.neutral5};
  line-height: 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints.small}) {
    font-size: 16px;
    position: static;
  }
`;

const ButtonContainer = styled.div`
  margin-right: 120px;
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints.small}) {
    margin-right: 16px;
  }
`;

type SectionHeaderProps = {
  sectionName?: string;
  progress: string;
  onClick?: () => void;
};

export default function SectionHeader({
  sectionName,
  progress,
  onClick,
}: SectionHeaderProps) {
  const { t } = useTranslation();
  return (
    <Container>
      <Inner>
        <ButtonContainer>
          <DashboardButton
            onClick={onClick}
          />
        </ButtonContainer>
        <div style={{ marginTop: 0 }}>
          <H1>{t("section", "Section")}: {sectionName}</H1>
          <div>
            <ProgressText>{progress}% {t("completed", "completed")}</ProgressText>
            <Progress
              percent={parseInt(progress)}
              showInfo={false}
              size="small"
            />
          </div>
        </div>
        <div />
      </Inner>
    </Container>
  );
}
