import styled from 'styled-components'
// COMPONENTS
import unitedStatesSVG from '../UnitedStates.svg'
import texasSVG from '../Texas.svg'
import { Patient } from 'generated/graphql'
import { ActionCard, Text } from '../index'
import Collapse from 'components/common/Collapse'
import Button from 'components/common/Button'
import { useHistory } from 'react-router-dom'
import i18n from 'lib/helpers/i18n'
import { getPartByPartId, AdPartEnum } from 'components/common/PlanDoc/MasterPlanDoc/plan-settings'
import { PlanSettingPart } from 'components/common/PlanDoc/MasterPlanDoc/plan-settings/types'
import useGetPlanSettings from 'lib/hooks/useGetPlanSettings'
import s from 'components/common/PlanDoc/KodaContent/styles'

const { Panel } = Collapse

const Image = styled.img`
  margin-right: 24px;
`

export const RequirementsCard = ({
  patient,
  witnessSettings,
}: {
  patient: Patient
  witnessSettings: PlanSettingPart
}) => {
  const VALIDITY_REQUIREMENTS = witnessSettings?.witnessRequirements?.validityRequirements || []

  const PANEL_CONTENT = [
    {
      header: i18n.t('requirementsCard.signingRequirements', 'Signing Requirements'),
      content: (
        <>
          <Text style={{ marginBottom: 8 }}>
            {i18n.t('requirementsCard.toMakeValid', { name: patient?.stateVersion?.name })}
          </Text>
          <ul>
            {VALIDITY_REQUIREMENTS?.map((item) => {
              return (
                <li key={item}>
                  <Text>{item}</Text>
                </li>
              )
            })}
          </ul>
        </>
      ),
    },
    {
      header: i18n.t('requirementsCard.witnessRequirements', 'Witness Requirements'),
      content: (
        <>
          {witnessSettings?.witnessRequirements?.requirements?.map((req) => {
            return (
              <>
                <Text style={{...s.text, fontSize: 18, fontWeight: 'bold', fontFamily:'inherit'}}>{req.title}</Text>
                <ul>
                  {req.phrases?.map((phrase) => {
                    return (
                      <li key={phrase}>
                        <Text>{phrase}</Text>
                      </li>
                    )
                  })}
                </ul>
              </>
            )
          })}
        </>
      ),
    },
  ]

  return (
    <ActionCard
      icon={(() => {
        switch (patient?.stateVersion?.abbreviation) {
          case 'TX':
            return <Image src={texasSVG} alt="Texas" />
          default:
            return <Image src={unitedStatesSVG} alt="United States" />
        }
      })()}
      title={i18n.t('requirementsCard.title', {
        name: patient?.stateVersion?.name,
        defaultValue: `${patient?.stateVersion?.name} Requirements`,
      })}
      text={
        <>
          <Text style={{ marginBottom: 8 }}>
            {i18n.t('requirementsCard.learnHow', 'Learn how to make your document valid in the state of ')}
            {patient?.stateVersion?.name}
          </Text>
          <Collapse defaultActiveKey={[PANEL_CONTENT?.[0]?.header]} expandIconPosition="right">
            {PANEL_CONTENT?.map((item, index) => {
              return (
                <Panel header={item.header} key={item.header + index}>
                  <Text>{item.content}</Text>
                </Panel>
              )
            })}
          </Collapse>
        </>
      }
    />
  )
}

interface StateRequirementsCardProps {
  patient: Patient
}

export default function StateRequirementsCard({ patient }: StateRequirementsCardProps) {
  const history = useHistory()
  const getPlanSettings = useGetPlanSettings()
  const setting = patient ? getPlanSettings(patient) : null

  const witnessSettings = setting?.parts && getPartByPartId(setting?.parts, AdPartEnum.sign_the_form)

  if (witnessSettings) {
    return <RequirementsCard patient={patient} witnessSettings={witnessSettings} />
  }

  return (
    <ActionCard
      icon={<Image src={unitedStatesSVG} alt="United States" />}
      title={i18n.t('requirementsCard.title', {
        name: patient?.stateVersion?.name,
        defaultValue: `${patient?.stateVersion?.name} Requirements`,
      })}
      text={
        <>
          <Text style={{ marginBottom: 14 }}>{i18n.t('requirementsCard.printText')}</Text>
          <Text style={{ marginBottom: 14 }}>{i18n.t('requirementsCard.clickText')}</Text>
          <Text>
            {i18n.t('requirementsCard.toLearnMore')}
            <a href={patient?.stateVersion?.linkToSigningRequirements || ''} target="_blank" rel="noreferrer">
              {i18n.t('requirementsCard.advanceCare', {
                name: patient?.stateVersion?.name,
              })}
            </a>
            .
          </Text>
          <Button style={{ marginTop: 16 }} onClick={() => history.push(`/app?mode=viewPlan`)}>
            {i18n.t('requirementsCard.btnViewPlan')}
          </Button>
        </>
      }
    />
  )
}
