import { ModalOverlay } from 'components/common/KodaModal'
import Button from 'components/common/Button'
import ModalTitle from 'components/text/ModalTitle'
import Caption from 'components/text/Caption'
import styled from 'styled-components'
import warningSVG from './warning.svg'
import i18n from 'lib/helpers/i18n'

const Modal = styled.div`
  background: #fff;
  padding: 16px;
  text-align: center;
  border-radius: 5px;
  max-width: 100%;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    top: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

const ModalContent = styled.div`
  margin: 16px;
  margin-bottom: 8px;
  width: 490px;
  max-width: 100%;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    margin: 0px;
  }
`

const ButtonContainter = styled.div`
  margin-top: 24px;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    padding: 16px;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    bottom: 0px;
    right: 0px;
    left: 0px;
    background-color: ${({ theme }) => theme.colors.neutral9};
    height: 80px;
  }
`

interface WarningModalProps {
  onConfirm: () => void
  onCancel: () => void
  visible: boolean
}

export default function ExitWarningModal({ onConfirm, onCancel, visible }: WarningModalProps) {
  if (!visible) return null
  return (
    <ModalOverlay>
      <Modal>
        <ModalContent>
          <img src={warningSVG} alt="Warning" height="32" style={{ marginBottom: 16 }} />
          <ModalTitle>
            {i18n.t('ExitWarningModal.title', 'Are you sure you want to leave the eSigning session?')}
          </ModalTitle>
          <Caption>{i18n.t('ExitWarningModal.subtitle', 'All information will be deleted')}</Caption>
          <ButtonContainter>
            <Button onClick={onCancel} grey style={{ width: 100, marginRight: 16 }}>
              {i18n.t('ExitWarningModal.cancel', 'Cancel')}
            </Button>
            <Button onClick={onConfirm} style={{ width: 140 }}>
              {i18n.t('ExitWarningModal.leave', 'Yes, leave')}
            </Button>
          </ButtonContainter>
        </ModalContent>
      </Modal>
    </ModalOverlay>
  )
}
