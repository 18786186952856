import styled from "styled-components";

const QuestionBody = styled.p`
  font-size: 20px;
  margin: 0px;
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints.mediumSmall}) {
    font-size: 16px;
  }
`;

export default QuestionBody;
