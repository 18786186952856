import React, { useState, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import { message } from 'antd'
import styled from 'styled-components'
import { isNil } from 'lodash'
// COMPONENTS
import SectionHeader from './SectionHeader'
import ElephantChat from 'components/common/ElephantChat'
import Button from 'components/common/Button'
import SignatureForm from './SignatureForm'
import ExitWarningModal from './ExitWarningModal'
import Text from 'components/text/Text'
import Loading from 'components/common/Loading'
import PlanDoc from 'components/common/PlanDoc'
import { pdf } from '@react-pdf/renderer'
import { ExclamationCircleFilled } from '@ant-design/icons'
import useUploadFile from 'lib/hooks/useUploadFile'
import useLogActivityItemMutation from 'lib/hooks/useLogActivityItemMutation'
import getReqsByState from './getReqsByState'
import CheckboxText from 'components/common/CheckboxText'
// GRAPHQL
import {
  ActivityLogKeyEnum,
  Clinic,
  Patient,
  useCompleteESignMutation,
  useGetOnePatientQuery,
  UserProfile,
  WitnessSignatureLocationEnum,
} from 'generated/graphql'
import useGetPlanSettings from 'lib/hooks/useGetPlanSettings'

const Content = styled.div`
  width: 700px;
  margin: auto;
  padding-top: 48px;
  max-width: 100%;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    padding-top: 0px;
  }
`

export const LinkButton = styled.span`
  text-decoration: underline;
  display: inline-block;
  font-size: 16px;
  font-family: ${({ theme }) => theme.fontFamily};
  color: ${({ theme }) => theme.colors.primary-2};
  color: #01337D;
  margin: 0px;
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.colors.neutral2};

  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    font-size: 16px;
  }
`

const ButtonRow = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    display: none;
  }
`

const ButtonRowMobile = styled.div`
  display: none;
  margin-top: 16px;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    position: fixed;
    bottom: 0px;
    right: 0px;
    left: 0px;
    display: inline-flex;
    padding: 16px 8px;
    justify-content: flex-end;
  }
`

const ErrorText = styled.p`
  color: ${(p) => p.theme.colors.red2};
  margin-top: 0px;
  margin-bottom: 16px;
  font-weight: 400;
`

const ListItem = styled.li`
  margin-top: 4px;
`

interface EsignProps {
  currentUser: UserProfile
}

export default function Esign({ currentUser }: EsignProps) {
  const { t } = useTranslation()
  const [showLegal, setShowLegal] = useState<boolean>(false)
  const [errors, setErrors] = useState<{
    acknowledgeRequirements1?: string
    acknowledgeRequirements2?: string
  }>({})
  // handles the checkbox from https://kodahealth.atlassian.net/jira/software/projects/KODA/boards/3?selectedIssue=KODA-559
  const [acknowledgeRequirements1, setAcknowledgeRequirements1] = useState<boolean>(false)
  // handles the checkbox from https://kodahealth.atlassian.net/jira/software/projects/KODA/boards/3?selectedIssue=KODA-559
  const [acknowledgeRequirements2, setAcknowledgeRequirements2] = useState<boolean>(false)
  const [showWitnessRequirements, setShowWitnessRequirements] = useState<boolean>(false)

  const [values, setValues] = useState<{
    // patient
    patientSignatureFirstName?: string
    patientSignatureLastName?: string
    patientSignatureDate?: string
    address?: string
    city?: string
    postal?: string
    state?: string
    // witness 1
    witness1SignatureFirstName?: string
    witness1SignatureLastName?: string
    witness1SignatureEmail?: string
    witness1SignatureDate?: string
    witness1LocationType: WitnessSignatureLocationEnum
    // witness 2
    witness2SignatureFirstName?: string
    witness2SignatureLastName?: string
    witness2SignatureEmail?: string
    witness2SignatureDate?: string
    witness2LocationType: WitnessSignatureLocationEnum
  }>({
    // patient signature
    patientSignatureFirstName: undefined,
    patientSignatureLastName: undefined,
    patientSignatureDate: undefined,
    address: undefined,
    city: undefined,
    postal: undefined,
    state: undefined,
    // witness 1
    witness1SignatureFirstName: undefined,
    witness1SignatureLastName: undefined,
    witness1SignatureEmail: undefined,
    witness1SignatureDate: undefined,
    witness1LocationType: WitnessSignatureLocationEnum.InPerson,
    // witness 2
    witness2SignatureFirstName: undefined,
    witness2SignatureLastName: undefined,
    witness2SignatureEmail: undefined,
    witness2SignatureDate: undefined,
    witness2LocationType: WitnessSignatureLocationEnum.InPerson,
  })
  const { data, loading } = useGetOnePatientQuery({
    variables: {
      userId: currentUser?.id,
    },
    onCompleted: (data) => {
      setValues({
        ...values,
        state: data?.getOnePatient?.stateVersion?.name || '',
      })
    },
  })

  const [completeESignMutation] = useCompleteESignMutation()
  const [logActivityItem] = useLogActivityItemMutation()
  const [step, setStep] = useState<0 | 1 | 2 | 3 | number>(0)
  const [showForm, setShowForm] = useState<boolean>(false)
  const [showExitWarning, setShowExitWarning] = useState<boolean>(false)
  const history = useHistory()
  const [finishStatus] = useState(false)
  const [loader, setLoader] = useState<boolean>(false)
  const [uploadFile] = useUploadFile()
  const getPlanSettings = useGetPlanSettings()
  const onBackButtonEvent = useCallback(
    (e) => {
      e.preventDefault()

      if (!finishStatus) {
        setShowExitWarning(true)
        // if (window.confirm("Do you want to go back ?")) {
        //   setfinishStatus(true);
        //   // your logic
        //   // history.push("/");
        // } else {
        //   window.history.pushState(null, "", window.location.pathname);
        //   setfinishStatus(false);
        // }
      }
    },
    [finishStatus]
  )

  useEffect(() => {
    history.push(window.location.pathname)
    window.history.pushState(null, '', window.location.pathname)
    window.addEventListener('popstate', onBackButtonEvent)
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent)
    }
  }, [onBackButtonEvent, history])

  const patient = data?.getOnePatient
  const setting = getPlanSettings(patient as Patient)

  if (loading) return <Loading />

  const onOpenSignature = () => {
    setShowLegal(true)
    setTimeout(() => {
      const el = window?.document?.getElementById('signature-form-legal-text')
      if (el) {
        el.scrollIntoView()
      }
    }, 500)
  }

  const onViewWitnessRequirements = () => {
    setShowWitnessRequirements((currentState) => !currentState)
    const el = window?.document?.getElementById('signature-form-witness-requirements-text')
    if (el) {
      el.scrollIntoView()
    }
  }

  const STEPS = [
    {
      pageTitle: t('esign.witnessESigning', 'Witness eSigning'),
      formTitle: t('esign.yourSignature', 'Your Signature'),
      elephantText: t('esign.itsNowTimeForYouToSign', {
        firstName: patient?.firstName,
      }),
      legalText: (
        <Text>
          {t('esign.byTypiingTourName', 'By typing your name and clicking “Agree and Sign”, you are agreeing to the ')}
          <LinkButton onClick={onOpenSignature}>{t('esign.eSignLegalConsent', 'eSign Legal Consent')}</LinkButton>.
        </Text>
      ),
      showAddressInputs: true,
      buttonText: t('esign.signPlan', 'Sign Plan'),
    },
    {
      pageTitle: t('esign.witnessESigning', 'Witness eSigning'),
      formTitle: t('esign.firstWitnessSignature', 'First Witness Signature'),
      elephantText: t('esign.firstWitnessItsNowYour', "First witness, it's now your turn to sign the document"),
      legalText: (
        <>
          <Text>
            {t(
              'esign.byTypiingTourName',
              'By typing your name and clicking “Agree and Sign”, you are agreeing to the '
            )}
            <LinkButton onClick={onOpenSignature}>{t('esign.eSignLegalConsent', 'eSign Legal Consent')}</LinkButton>.
          </Text>
          <CheckboxText
            checked={acknowledgeRequirements1}
            onChange={() => {
              setAcknowledgeRequirements1((lastValue) => !lastValue)
              setErrors({ acknowledgeRequirements1: undefined })
            }}
            backgroundColor="#fff"
            style={{ marginBottom: 0 }}
            dataTestId="witness-confirm-checbkox"
          >
            <div>{t('esign.byCheckingThisBox', 'By checking this box, you are confirming that:')}</div>
          </CheckboxText>
          <ul style={{ marginLeft: 20, marginTop: 0 }}>
            <ListItem>
              {t('esign.youMeetThe', 'You meet the ')}
              <LinkButton onClick={onViewWitnessRequirements}>
                {t('esign.witnessRequirements', 'witness requirements')}
              </LinkButton>
            </ListItem>
            <ListItem>
              {t('esign.youAreInThePhysical', 'You are in the physical presence of ')}
              <span className="fs-mask">
                {patient?.firstName} {patient?.lastName}
              </span>
            </ListItem>
            <ListItem>
              {t('esign.youAreNot', 'You are not ')}
              <span className="fs-mask">
                {patient?.firstName} {patient?.lastName}
              </span>
            </ListItem>
          </ul>
          {errors?.acknowledgeRequirements1 && (
            <ErrorText>
              <ExclamationCircleFilled style={{ marginRight: 4 }} />
              {errors?.acknowledgeRequirements1}
            </ErrorText>
          )}
        </>
      ),
      buttonText: t('esign.witness1Sign', 'Witness #1 Sign'),
      witnessRequirementText: getReqsByState(setting, setShowWitnessRequirements),
    },
    {
      pageTitle: t('esign.witnessESigning', 'Witness eSigning'),
      formTitle: t('esign.secondWitnessSignature', 'Second Witness Signature'),
      elephantText: t('esign.secondWitnessItsNowYourTurn', 'Second witness, it’s now your turn to sign the document.'),
      legalText: (
        <>
          <Text>
            {t(
              'esign.byTypiingTourName',
              'By typing your name and clicking “Agree and Sign”, you are agreeing to the '
            )}
            <LinkButton onClick={onOpenSignature}>{t('esign.eSignLegalConsent', 'eSign Legal Consent')}</LinkButton>.
          </Text>
          <CheckboxText
            checked={acknowledgeRequirements2}
            onChange={() => {
              setAcknowledgeRequirements2((lastValue) => !lastValue)
              setErrors({ acknowledgeRequirements2: undefined })
            }}
            backgroundColor="#fff"
            dataTestId="witness-confirm-checbkox"
          >
            <div>{t('esign.byCheckingThisBox', 'By checking this box, you are confirming that:')}</div>
          </CheckboxText>
          <ul style={{ marginLeft: 20, marginTop: 0 }}>
            <ListItem>
              {t('esign.youMeetThe', 'You meet the ')}
              <LinkButton onClick={onViewWitnessRequirements}>
                {t('esign.witnessRequirements', 'witness requirements')}
              </LinkButton>
            </ListItem>
            <ListItem>
              {t('esign.youAreInThePhysical', 'You are in the physical presence of ')}
              <span className="fs-mask">
                {patient?.firstName} {patient?.lastName}
              </span>
            </ListItem>
            <ListItem>
              {t('esign.youAreNot', 'You are not ')}
              <span className="fs-mask">
                {patient?.firstName} {patient?.lastName}
              </span>
            </ListItem>
          </ul>
          {errors?.acknowledgeRequirements2 && (
            <ErrorText>
              {' '}
              <ExclamationCircleFilled style={{ marginRight: 4 }} />
              {errors?.acknowledgeRequirements2}
            </ErrorText>
          )}
        </>
      ),
      buttonText: t('esign.witness2Sign', 'Witness #2 Sign'),
      witnessRequirementText: getReqsByState(setting, setShowWitnessRequirements),
    },
    {
      pageTitle: t('esign.witnessESigning', 'Witness eSigning'),
      elephantText: t(
        'esign.yourDocumentIsNowOfficial',
        'Your document is now official! Next step is to share your plan.'
      ),
      buttonText: t('esign.sharePlan', 'Share plan'),
    },
  ]

  const docKey = Math.random()?.toString() //

  const onAgree = async () => {
    try {
      setLoader(true)
      setShowWitnessRequirements(false)
      setShowLegal(false)

      if (step === 0) {
        setValues({
          ...values,
          patientSignatureDate: new Date()?.toString(),
        })
      }
      if (step === 1) {
        if (!acknowledgeRequirements1) {
          setLoader(false)
          return setErrors({
            acknowledgeRequirements1: t(
              'esignErrors.pleaseCheckTheBox',
              'Please check the box to confirm you meet the requirements for Witness eSigning.'
            ),
          })
        }

        setValues({
          ...values,
          witness1SignatureDate: new Date()?.toString(),
        })
      }
      if (step === 2) {
        if (!acknowledgeRequirements2) {
          setLoader(false)
          return setErrors({
            acknowledgeRequirements2: t(
              'esignErrors.pleaseCheckTheBox',
              'Please check the box to confirm you meet the requirements for Witness eSigning.'
            ),
          })
        }

        // create the date for witness 2's esign
        const witness2SignatureDate = new Date()?.toString()
        // update local state
        setValues({
          ...values,
          witness2SignatureDate,
        })
        if (!patient?.id) return
        const newBlob = await pdf(
          <PlanDoc
            data={{
              ...patient,
              ...values,
              witness2SignatureDate,
            }}
            clinic={data?.getOnePatient?.clinic}
            forBlob
            downloading
          />
        ).toBlob()
        const file = new File([newBlob], 'file_name.pdf', {
          lastModified: moment().valueOf(),
        })
        if (!file) return
        const uploadedFile = await uploadFile(file)

        // actually update the server with the data
        await completeESignMutation({
          variables: {
            patientId: patient?.id,
            s3Key: uploadedFile?.key as string,
            params: {
              // patient stuff
              patientSignatureFirstName: values?.patientSignatureFirstName,
              patientSignatureLastName: values?.patientSignatureLastName,
              patientSignatureDate: values?.patientSignatureDate,
              address: values?.address,
              city: values?.city,
              postal: values?.postal,
              // witness 1 stuff
              witness1SignatureFirstName: values?.witness1SignatureFirstName,
              witness1SignatureLastName: values?.witness1SignatureLastName,
              witness1SignatureEmail: values?.witness1SignatureEmail,
              witness1SignatureDate: values?.witness1SignatureDate,
              witness1LocationType: values?.witness1LocationType,
              // witness 2 stuff
              witness2SignatureFirstName: values?.witness2SignatureFirstName,
              witness2SignatureLastName: values?.witness2SignatureLastName,
              witness2SignatureEmail: values?.witness2SignatureEmail,
              witness2SignatureDate,
              witness2LocationType: values?.witness2LocationType,
            },
          },
        })

        await logActivityItem({
          variables: {
            key: ActivityLogKeyEnum.EsignComplete,
            userId: currentUser?.id || '',
            patientId: patient?.id || '',
            content: `Patient has completed eSign`,
          },
        })
      }
      if (step === 3) {
        return history.push('/app?mode=emailDirective')
      }
      setTimeout(() => {
        // close form
        setShowForm(false)
        // move up one step
        setStep((currentStep) => currentStep + 1)
        setLoader(false)
      }, 500)
    } catch (e) {
      console.log(e)
      message.error((e as Error).message)
      setLoader(false)
    }
  }

  const onChange = (newValues) => {
    switch (step) {
      case 0:
        return setValues({
          ...values,
          patientSignatureFirstName: !isNil(newValues?.firstName)
            ? newValues?.firstName
            : values?.patientSignatureFirstName,
          patientSignatureLastName: !isNil(newValues?.lastName)
            ? newValues?.lastName
            : values?.patientSignatureLastName,
          patientSignatureDate: !isNil(newValues?.signatureDate)
            ? newValues?.signatureDate
            : values?.patientSignatureDate,
          address: !isNil(newValues?.address) ? newValues?.address : values?.address,
          city: !isNil(newValues?.city) ? newValues?.city : values?.city,
          postal: !isNil(newValues?.postal) ? newValues?.postal : values?.postal,
        })
      case 1:
        return setValues({
          ...values,
          witness1SignatureFirstName: !isNil(newValues?.firstName)
            ? newValues?.firstName
            : values?.witness1SignatureFirstName,
          witness1SignatureLastName: !isNil(newValues?.lastName)
            ? newValues?.lastName
            : values?.witness1SignatureLastName,
          witness1SignatureEmail: !isNil(newValues?.email) ? newValues?.email : values?.witness1SignatureEmail,
          witness1SignatureDate: !isNil(newValues?.signatureDate)
            ? newValues?.signatureDate
            : values?.witness1SignatureDate,
        })
      case 2:
        return setValues({
          ...values,
          witness2SignatureFirstName: !isNil(newValues?.firstName)
            ? newValues?.firstName
            : values?.witness2SignatureFirstName,
          witness2SignatureLastName: !isNil(newValues?.lastName)
            ? newValues?.lastName
            : values?.witness2SignatureLastName,
          witness2SignatureEmail: !isNil(newValues?.email) ? newValues?.email : values?.witness2SignatureEmail,
          witness2SignatureDate: !isNil(newValues?.signatureDate)
            ? newValues?.signatureDate
            : values?.witness2SignatureDate,
        })
      default:
        return {
          firstName: undefined,
          lastName: undefined,
          signatureDate: undefined,
        }
    }
  }

  const getValues = () => {
    switch (step) {
      case 0:
        return {
          firstName: values?.patientSignatureFirstName,
          lastName: values?.patientSignatureLastName,
          address: values?.address,
          city: values?.city,
          postal: values?.postal,
          state: values?.state,
        }
      case 1:
        return {
          firstName: values?.witness1SignatureFirstName,
          lastName: values?.witness1SignatureLastName,
          email: values?.witness1SignatureEmail,
          signatureDate: values?.witness1SignatureDate,
        }
      case 2:
        return {
          firstName: values?.witness2SignatureFirstName,
          lastName: values?.witness2SignatureLastName,
          email: values?.witness2SignatureEmail,
          signatureDate: values?.witness2SignatureDate,
        }
      default:
        return {
          firstName: undefined,
          lastName: undefined,
          signatureDate: undefined,
        }
    }
  }

  const signatureValues = getValues()

  const handleOnSign = () => {
    if (step === 3) {
      return history.push('/app?mode=emailDirective')
    }
    setShowForm(true)
  }

  const PlanDocMemoized = React.memo<any>((props) => {
    return <PlanDoc id={props?.docKey} data={props?.data} clinic={props?.patient?.clinic as Clinic} />
  })

  return (
    <div
      style={{
        overflowY: 'scroll',
        maxHeight: '100vh',
        width: '100%',
        scrollBehavior: 'smooth',
      }}
    >
      <ExitWarningModal
        visible={showExitWarning}
        onConfirm={() => history.push(`/app/home`)}
        onCancel={() => setShowExitWarning(false)}
      />
      {console.log('hasOfficialPlan', patient?.hasOfficialPlan)}
      <SectionHeader
        sectionName={STEPS?.[step]?.pageTitle}
        progress={step === 5 || patient?.hasOfficialPlan ? '100' : ((step / STEPS.length) * 100).toFixed(0)}
        onExit={() => {
          if (step === 3) {
            return history.push('/app')
          }
          setShowExitWarning(true)
        }}
      />
      <Content>
        {!showForm ? (
          <>
            {STEPS?.[step]?.elephantText && <ElephantChat text={STEPS?.[step]?.elephantText as string} />}
            <ButtonRow>
              <Button onClick={handleOnSign} data-testid={STEPS?.[step]?.buttonText} style={{ zIndex: 200 }}>
                {STEPS?.[step]?.buttonText}
              </Button>

              {/* {config.NODE_ENV !== "production" && (
                <Button
                  secondary
                  style={{ marginLeft: 8 }}
                  onClick={() => {
                    setValues({
                      ...values,
                      patientSignatureFirstName: "Test",
                      patientSignatureLastName: "Test",
                      patientSignatureDate: moment()?.toString(),
                      address: "123 market",
                      city: "Portsmouth",
                      postal: "03801",
                      state: "TX",
                      // witness 1
                      witness1SignatureFirstName: "Test",
                      witness1SignatureLastName: "Test",
                      witness1SignatureEmail: "Test",
                      witness1SignatureDate: moment()?.toString(),
                      witness1LocationType:
                        WitnessSignatureLocationEnum.InPerson,
                    });
                    setStep(2);
                  }}
                >
                  Test Fill In
                </Button>
              )} */}
            </ButtonRow>
            <div style={{ height: '80vh', marginTop: 16 }} className="fs-exclude">
              {!loader && !loading && patient && patient?.clinic && values && (
                <PlanDocMemoized
                  id={docKey}
                  data={{
                    ...patient,
                    // patient signature
                    patientSignatureFirstName: values?.patientSignatureFirstName,
                    patientSignatureLastName: values?.patientSignatureLastName,
                    patientSignatureDate: values?.patientSignatureDate,
                    address: values?.address,
                    city: values?.city,
                    postal: values?.postal,
                    // witness 1
                    witness1SignatureFirstName: values?.witness1SignatureFirstName,
                    witness1SignatureLastName: values?.witness1SignatureLastName,
                    witness1SignatureEmail: values?.witness1SignatureEmail,
                    witness1SignatureDate: values?.witness1SignatureDate,
                    // witness 2
                    witness2SignatureFirstName: values?.witness2SignatureFirstName,
                    witness2SignatureLastName: values?.witness2SignatureLastName,
                    witness2SignatureEmail: values?.witness2SignatureEmail,
                    witness2SignatureDate: values?.witness2SignatureDate,
                  }}
                  clinic={patient?.clinic as Clinic}
                />
              )}
            </div>
            <ButtonRowMobile>
              <Button onClick={handleOnSign} data-testid="sign-plan-btn" style={{ zIndex: 200 }}>
                {STEPS?.[step]?.buttonText}
              </Button>
            </ButtonRowMobile>
          </>
        ) : (
          <SignatureForm
            title={STEPS?.[step]?.formTitle} // title of page
            showAddressInputs={STEPS?.[step]?.showAddressInputs}
            onAgree={onAgree} // runs when the user submits
            showWitnessRequirements={showWitnessRequirements} // true/false to show the witness requirements
            showLegal={showLegal} // true/false if loegal modal shows
            setShowLegal={(value) => (value ? onOpenSignature : setShowLegal(false))} // what function runs will depend on if we're trying to open or close it
            setShowWitnessRequirements={(value) =>
              value ? onViewWitnessRequirements : setShowWitnessRequirements(false)
            }
            onChange={onChange} // runs when a form value changes
            onCancel={() => setShowExitWarning(true)} // runs when a user tries to exit the signature flow
            values={signatureValues} // values for the form
            showEmailInput={step === 1 || step === 2} // will determine if a email input shows or not
            legalText={STEPS?.[step]?.legalText} //
            loading={loader} // true/false if we should show loading
            witnessRequirementText={STEPS?.[step]?.witnessRequirementText} // the text for witeness requirements for the given eSign step
          />
        )}
      </Content>
    </div>
  )
}
