import { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { validate } from 'email-validator'
// COMPONENS
import BirthdayInput from 'components/inputs/BirthdayInput'
import Button from 'components/common/Button'
import StatesInput from 'components/inputs/StateInput'
import TextInput from 'components/inputs/TextInput'
import FormItem from 'components/common/FormItem'
import Col from 'components/common/Col'
import Row from 'components/common/Row'
import { BottomText, BottomLink, Title } from '../SignupForm/styledSignUp/signupForm.Styled'
// LIB
import { AnalyticEventTypeEnums } from 'lib/types/AnalyticEventTypeEnums'
import constants from 'lib/config'
import useCheckBirthdayErrors from 'lib/hooks/useCheckBirthdayErrors'
import { Clinic, PatientInput } from 'generated/graphql'
import i18n from 'lib/helpers/i18n'
import logEvent from 'lib/helpers/logEvent'

const HintLink = styled.span`
  font-family: ${(p) => p.theme.fontFamily};
  color: ${(p) => p.theme.colors.supportB3};
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  padding: 0px;
  margin: 0px;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0px;
  border: 0px;
  cursor: pointer;
  outline: 0;
  background: transparent;
  &:hover {
    color: ${(p) => p.theme.colors.supportB3};
  }
`

export const Caption = styled.h3`
  font-size: 18px;
  margin: 0px;
  color: ${(p) => p.theme.colors.neutral5};
`

const BottomBtn = styled(HintLink)`
  font-size: 16px;
  text-decoration: underline;
`

const Checkbox = styled.input``

const ButtonContainer = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: flex-start;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    div,
    button {
      width: 120px !important;
    }
  }
`

interface GuestFormProps {
  onChange: (e: { [x: string]: string | number }) => void
  onBack: () => void
  loading?: boolean
  user: PatientInput
  onSubmit: () => void
  onChangeForm: (v: string) => void
  clinic?: Clinic
}

export default function GuestForm({ onChange, onBack, loading, clinic, user, onSubmit, onChangeForm }: GuestFormProps) {
  const firstNameInput = useRef<HTMLInputElement | undefined>()
  const [checkBirthdayErrors] = useCheckBirthdayErrors()

  const [errors, setErrors] = useState<{
    email?: string
    password?: string
    confirmPassword?: string
    stateId?: string
    firstName?: string
    lastName?: string
    birthday?: string
    agreeToTerms?: string
  }>({
    email: undefined,
    password: undefined,
    confirmPassword: undefined,
    stateId: undefined,
    firstName: undefined,
    lastName: undefined,
    birthday: undefined,
    agreeToTerms: undefined,
  })
  // scroll to top
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    firstNameInput?.current?.focus()
  }, [])

  const onBeforeSubmit = (e) => {
    e.preventDefault()
    if (!user.firstName || user.firstName.length === 0) {
      return setErrors({ firstName: i18n.t('signupPage.provideFirstName') })
    }
    if (!user.lastName || user.lastName.length === 0) {
      return setErrors({ lastName: i18n.t('signupPage.provideLastName') })
    }
    if (user.email && !validate(user.email)) {
      return setErrors({ email: i18n.t('signupPage.provideValidEmail') })
    }
    const birthdayError = checkBirthdayErrors(user.birthday as string)
    if (birthdayError) return setErrors({ birthday: birthdayError })

    if (!user.stateId) {
      return setErrors({ stateId: i18n.t('signupPage.selectState') })
    }
    if (!user.agreeToTerms) {
      return setErrors({
        agreeToTerms: i18n.t('signupPage.acceptTermsService'),
      })
    }

    logEvent(AnalyticEventTypeEnums.SIGNUP_GUEST_CLICKED, {
      clinicId: clinic?.id,
    })
    // if everything passes, then submit
    return onSubmit()
  }

  // can clear out errors after something changes
  const onBeforeChange = (newValues) => {
    setErrors({})
    onChange(newValues)
  }

  return (
    <div style={{ width: '600px', padding: '24px' }}>
      <Title>{i18n.t('GuestForm.title', 'Use as a Guest')}</Title>
      <Caption>{i18n.t('GuestForm.subtitle', 'Start planning your future medical care plans')}</Caption>
      <form onSubmit={onBeforeSubmit} style={{ marginBottom: 16 }}>
        <Row gutter={16}>
          <Col xs={12}>
            <FormItem label={i18n.t('signupPage.legalFirstName')} required error={errors.firstName} htmlFor="firstName">
              <TextInput
                id="firstName"
                onChange={(e) => onBeforeChange({ firstName: e.target.value })}
                ref={firstNameInput}
                value={user.firstName || ''}
              />
            </FormItem>
          </Col>
          <Col xs={12}>
            <FormItem label={i18n.t('signupPage.legalLastName')} htmlFor="lastName" required error={errors.lastName}>
              <TextInput
                onChange={(e) => onBeforeChange({ lastName: e.target.value })}
                value={user.lastName || ''}
                id="lastName"
                className="fs-exclude"
                type="search"
                autoComplete="off"
              />
            </FormItem>
          </Col>
          <Col xs={24}>
            <FormItem
              label={i18n.t('signupPage.emailOptional', 'Email (optional)')}
              error={errors.email}
              htmlFor="email"
            >
              <TextInput
                type="email"
                id="email"
                inputMode="email"
                className="fs-exclude"
                onChange={(e) => onBeforeChange({ email: e.target.value.toLowerCase() })}
                value={user.email || ''}
              />
            </FormItem>{' '}
          </Col>
          <Col xs={24} sm={14} className="fs-exclude">
            <FormItem
              label={i18n.t('signupPage.birthday')}
              htmlFor="birthday"
              required
              error={errors.birthday}
              tooltip={i18n.t('signupPage.birthdayTooltip')}
            >
              <BirthdayInput onChange={(birthday) => onBeforeChange({ birthday })} id="birthday" />
            </FormItem>
          </Col>
          <Col xs={24} sm={10}>
            <FormItem
              label={i18n.t('signupPage.state')}
              required
              error={errors.stateId}
              tooltip={i18n.t('signupPage.stateTooltip')}
              htmlFor="state"
            >
              <StatesInput
                onChange={(stateId) => onBeforeChange({ stateId })}
                value={user.stateId}
                className="fs-exclude"
                id="state"
              />
            </FormItem>
          </Col>
        </Row>

        <FormItem error={errors.agreeToTerms}>
          <div style={{ display: 'flex' }}>
            <div style={{ marginRight: 8, paddingTop: 8 }}>
              <Checkbox
                id="agreeText"
                type="checkbox"
                onChange={(e) => onBeforeChange({ agreeToTerms: e.target.checked })}
              />
            </div>
            <div>
              <BottomText htmlFor="agreeText">
                {i18n.t('signupPage.agreeText')}{' '}
                <BottomLink to="/terms?showBackBtn=true">{i18n.t('signupPage.terms')}</BottomLink> and{' '}
                <BottomLink to="/privacy?showBackBtn=true">{i18n.t('signupPage.privacyPolicy')}</BottomLink>.
              </BottomText>
            </div>
          </div>
        </FormItem>
        <ButtonContainer>
          <Button disabled={loading} grey onClick={onBack} style={{ width: 165, marginRight: 16 }}>
            {i18n.t('button.back')}
          </Button>
          <Button disabled={loading} loading={loading} type="submit" onClick={onBeforeSubmit} style={{ width: 165 }}>
            {i18n.t('signupPage.signUp')}
          </Button>
        </ButtonContainer>
      </form>
      <div style={{ marginTop: 32 }}>
        <BottomText>
          {i18n.t('signupPage.alreadyHaveAccount')}{' '}
          <BottomLink to="/login">{i18n.t('signupPage.signinHere')}</BottomLink>
        </BottomText>
        <BottomText>
          {i18n.t('GuestForm.wantToCreate')}{' '}
          <BottomBtn onClick={() => onChangeForm('signup')}>{i18n.t('GuestForm.signupHere')}</BottomBtn>
        </BottomText>
        <BottomText>
          {i18n.t('signupPage.needHelp')}{' '}
          <a style={{ textDecoration: 'underline' }} href={`tel:+${constants.supportPhone.replace(/-/g, '')}`}>
            {constants.supportPhone}
          </a>
        </BottomText>
      </div>
    </div>
  )
}
