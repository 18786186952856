import { useState, useCallback } from 'react'
import styled from 'styled-components'
import queryString from 'query-string'
import moment from 'moment'
import { useHistory, useLocation } from 'react-router-dom'
import { useDropzone } from 'react-dropzone'
import useUploadFile from 'lib/hooks/useUploadFile'
// COMPONENTS
import ElephantChat from 'components/common/ElephantChat'
import Row from 'components/common/Row'
import Col from 'components/common/Col'
import Loading from 'components/common/Loading'
import message from 'components/common/message'
import TextInput from 'components/inputs/TextInput'
import ProfileOutlined from 'components/icons/ProfileOutlined'
import Progress from 'components/common/Progress'
import Tooltip from 'components/common/Tooltip'
import Popconfirm from 'components/common/Popconfirm'
import Button from 'components/common/Button'
import EmptyState from 'components/common/EmptyState'
import FormItem from 'components/common/FormItem'
import ErrorBlock from 'components/common/ErrorBlock'
import TruncateMarkup from 'react-truncate-markup'
import { ExclamationCircleFilled } from '@ant-design/icons'
import forceDownloadFile from 'lib/helpers/forceDownloadFile'
import { toNumber } from 'lodash'
// APOLLO
import {
  useDeletePlanAttachmentMutation,
  usePlanAttachmentsQuery,
  PlanAttachmentsDocument,
  useUploadPlanAttachmentMutation,
  PlanAttachmentTypeEnum,
  Patient,
} from 'generated/graphql'
// LIB
import theme from 'lib/theme'
import config from 'lib/config'
import { AnalyticEventTypeEnums } from 'lib/types/AnalyticEventTypeEnums'
import LoadingOutlined from 'components/icons/LoadingOutlined'
import DeleteOutlined from 'components/icons/DeleteOutlined'
import CloseCircleFilled from 'components/icons/CloseCircleFilled'
import Popover from 'components/common/Popover'
import i18n from 'lib/helpers/i18n'
import iOS from 'lib/helpers/iOS'
import logEvent from 'lib/helpers/logEvent'
import translateApolloError from 'lib/helpers/translateApolloError'

const Container = styled.div`
  width: 700px;
  max-width: 100%;
  margin: auto;
`

const Content = styled.div`
  background: #fff;
  padding: 16px;
  border-radius: ${(p) => p.theme.borderRadius}px;
  position: relative;
`

const Title = styled.h1`
  font-size: 18px;
  font-weight: 600;
  margin: 0px;
`

const Caption = styled.h3`
  font-size: 16px;
  color: ${(p) => p.theme.colors.neutral4};
  margin: 0px;
  font-weight: 400;
  max-width: 67%;
`

const TabelLabel = styled.p`
  font-weight: 600;
  font-size: 13px;
  letter-spacing: 0.05rem;
  text-transform: uppercase;
  color: ${(p) => p.theme.colors.neutral5};
`

export const TabelValue = styled.p`
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.05rem;
  color: ${(p) => p.theme.colors.neutral2};
`

interface FileRowProps {
  onDownload: () => void
  loading: boolean
  file: any
  onDelete: () => void
}

export const FileRow = ({ onDownload, loading, file, onDelete }: FileRowProps) => (
  <Row style={{ marginTop: 8 }}>
    <Col xs={10} sm={14}>
      <TruncateMarkup lines={1}>
        <TabelValue style={{ fontWeight: 600, cursor: 'pointer' }} onClick={onDownload}>
          {file.name || 'KodaCarePlan.pdf'}
        </TabelValue>
      </TruncateMarkup>
    </Col>
    <Col xs={8} sm={6}>
      <TabelValue>{file.updatedAt && moment(parseInt(file.updatedAt)).format('M/D/YYYY')}</TabelValue>
    </Col>
    <Col xs={6} sm={4}>
      {loading && <LoadingOutlined />}
      {!loading && (
        <>
          {file.planAttachmentType === PlanAttachmentTypeEnum.OfficialKodaPlan && (
            <Popover
              content={
                <div
                  style={{
                    width: 325,
                    maxWidth: '100%',
                    display: 'flex',
                  }}
                >
                  <ExclamationCircleFilled
                    style={{
                      marginRight: 8,
                      fontSize: 17,
                      color: theme.colors.yellow5,
                      position: 'relative',
                      top: 3,
                    }}
                  />
                  <div>
                    <strong>{i18n.t('editSurveyPage.FileRow.title', 'Need to delete your document?')}</strong>
                    <p style={{ margin: 0 }}>
                      {i18n.t('editSurveyPage.FileRow.contactUs', 'Contact Koda Support at ')}{' '}
                      <a href={`tel:+${config.supportPhone.replace(/-/g, '')}`}>{config.supportPhone}</a>
                      <br /> or <a href={`mailto:${config.supportEmail}`}>{config.supportEmail}</a>.
                    </p>
                  </div>
                </div>
              }
            >
              <DeleteOutlined style={{ marginRight: 16, fontSize: 20, cursor: 'pointer' }} />
            </Popover>
          )}
          {file.planAttachmentType !== PlanAttachmentTypeEnum.OfficialKodaPlan && (
            <Popconfirm
              title={i18n.t('editSurveyPage.FileRow.areYouSure', 'Are you sure you want to delete this file?')}
              onConfirm={onDelete}
              okText={i18n.t('editSurveyPage.FileRow.okText', 'Yes')}
            >
              <DeleteOutlined style={{ marginRight: 16, fontSize: 20, cursor: 'pointer' }} />
            </Popconfirm>
          )}

          {iOS() ? (
            <Tooltip
              title={i18n.t(
                'editSurveyPage.FileRow.clickToDownloadIOS',
                'Please open the app on desktop to download your plan'
              )}
            >
              <ProfileOutlined style={{ fontSize: 20, cursor: 'pointer' }} />
            </Tooltip>
          ) : (
            <Tooltip title={i18n.t('editSurveyPage.FileRow.clickToDownload', 'Click to download your file')}>
              <ProfileOutlined onClick={onDownload} style={{ fontSize: 20, cursor: 'pointer' }} />
            </Tooltip>
          )}
        </>
      )}
    </Col>
  </Row>
)

const Overlay = styled.div`
  background: rgba(0, 0, 0, 0.25);
  position: fixed;
  align-items: center;
  justify-content: center;
  display: flex;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
`

const ModalContent = styled.div`
  min-height: 300px;
  width: 500px;
  max-width: 100%;
  background: #fff;
  border-radius: 5px;
  padding: 16px;
`

const DragContainer = styled.div`
  border: 2px dashed ${(p) => p.theme.colors.neutral8};
  background: ${(p) => p.theme.colors.neutral10};
  padding: 32px 16px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease, width 0.3s ease, opacity 0.3s ease;
  &:hover {
    border: 2px dashed ${(p) => p.theme.colors.neutral7};
    background: ${(p) => p.theme.colors.neutral9};
  }
`

const DragText = styled.p`
  margin: 0px;
`

const SelectedFileContainer = styled.div`
  padding: 16px;
`

interface SelectedFileProps {
  file: {
    name: string
  }
  onRemove: () => void
}

export const SelectedFile = ({ file, onRemove }: SelectedFileProps) => (
  <SelectedFileContainer>
    {file && file.name}
    <CloseCircleFilled onClick={onRemove} style={{ marginLeft: 16, cursor: 'pointer' }} />
  </SelectedFileContainer>
)

const ButtonContainer = styled.div`
  margin-top: 24px;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    div,
    button {
      width: 120px !important;
    }
  }
`

const HeaderContainer = styled.div`
  display: flex;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    flex-direction: column;
    h3 {
      max-width: 100%;
      margin-bottom: 16px;
    }
  }
`

const UploadBtnContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 1;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    justify-content: center;
  }
`

export const TableHeader = () => {
  return (
    <Row style={{ marginTop: 40 }}>
      <Col xs={10} sm={14}>
        <TabelLabel>{i18n.t('editSurveyPage.TableHeader.fileName', `File Name`)}</TabelLabel>
      </Col>
      <Col xs={8} sm={6}>
        <TabelLabel>{i18n.t('editSurveyPage.TableHeader.lastUpdated', `Last Updated`)}</TabelLabel>
      </Col>
      <Col xs={6} sm={4}>
        <TabelLabel>{i18n.t('editSurveyPage.TableHeader.actions', `Actions`)}</TabelLabel>
      </Col>
    </Row>
  )
}

interface CompletedPlansProps {
  plan: Patient
}

export default function CompletedPlans({ plan }: CompletedPlansProps) {
  const location = useLocation()
  const history = useHistory()
  const [uploadFile, uploading, progress] = useUploadFile()
  const [file, setFile] = useState<File | undefined>(undefined)
  const [name, setName] = useState<string | null>(null)
  const [error, setError] = useState<string | null>(null)
  const { uploader } = queryString.parse(location.search)

  const [uploadPlanAttachmentMutation, { loading: uploadingFileToServer }] = useUploadPlanAttachmentMutation()

  const { data, loading } = usePlanAttachmentsQuery({
    fetchPolicy: 'cache-and-network',
  })

  const uploadingFile = uploading || uploadingFileToServer

  const [deleteFile, { loading: deletingFile }] = useDeletePlanAttachmentMutation()

  const onDrop = useCallback((acceptedFiles) => {
    setFile(acceptedFiles[0])
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  const attachments = data?.planAttachments

  const onClose = () => {
    setFile(undefined)
    history.push(`/app?mode=completedPlans`)
  }

  const onUpload = async () => {
    try {
      if (!file) return
      // reset error
      setError(null)
      const uploadedFile = await uploadFile(file)

      await uploadPlanAttachmentMutation({
        variables: {
          s3Key: uploadedFile.key as string,
          mimetype: file.type,
          name,
        },
        refetchQueries: [
          {
            query: PlanAttachmentsDocument,
          },
        ],
      })
      message.success(i18n.t('editSurveyPage.saveFileSuccess', `File successfully saved!`))
      logEvent(AnalyticEventTypeEnums.UPLOAD_COMPLETED_PLAN, {
        patientId: plan.id as string,
      })
      onClose()
    } catch (err: any) {
      console.log(err)
      message.error(translateApolloError(err))
    }
  }

  const onDelete = async (fileId) => {
    try {
      await deleteFile({
        variables: {
          id: fileId,
        },
        refetchQueries: [
          {
            query: PlanAttachmentsDocument,
          },
        ],
      })
      message.success(i18n.t('editSurveyPage.deleteFileSuccess', `Your file was deleted`))
    } catch (err: any) {
      console.log(err)
      message.error(translateApolloError(err))
    }
  }

  const downloadPDF = ({
    base64String,
    name,
    url,
  }: {
    base64String?: string | null
    name?: string | null
    url?: string | null
  }) => {
    console.log({
      base64String,
      name,
      url,
    })
    if (!url && !base64String) return
    forceDownloadFile(url || (base64String as string), name || '')
    // const downloadLink = document.createElement("a");
    // const clearUrl =
    //   url ||
    //   (base64String && base64String.replace(/^data:image\/\w+;base64,/, ""));
    // const fileName = name || "Koda.pdf";
    // downloadLink.href = clearUrl as string;
    // downloadLink.download = fileName;
    // downloadLink.click();
    // const win = window.open();
    // const iframe = `<iframe width='100%' height='100%' src="${base64String}"><iframe>`;
    // win?.document.open();
    // win?.document.write(iframe);
    // win?.document.close();
  }

  return (
    <Container>
      {uploader && uploader === 'true' && (
        <Overlay>
          <ModalContent>
            <FormItem label={i18n.t('editSurveyPage.uploadFile')}>
              {!file && (
                <DragContainer {...getRootProps()}>
                  <input {...getInputProps()} />
                  {isDragActive ? (
                    <DragText>{i18n.t('editSurveyPage.dropFiles')}</DragText>
                  ) : (
                    <DragText>{i18n.t('editSurveyPage.dragAndDrop')}</DragText>
                  )}
                </DragContainer>
              )}
              {file && <SelectedFile file={file} onRemove={() => setFile(undefined)} />}
            </FormItem>
            <FormItem label={i18n.t('editSurveyPage.documentName')}>
              <TextInput onChange={(e) => setName(e.target.value)} placeholder={i18n.t('editSurveyPage.provideName')} />
            </FormItem>
            {error && <ErrorBlock style={{ marginTop: 16 }} error={error} />}

            <ButtonContainer>
              <div style={{ height: 25, marginBottom: 16 }}>
                {uploading && <Progress percent={toNumber(progress)} />}
              </div>
              <Button grey style={{ marginRight: 16, width: 160 }} onClick={onClose}>
                {i18n.t('button.cancel')}
              </Button>
              <Button
                onClick={onUpload}
                disabled={!file || uploadingFile}
                loading={uploadingFile}
                style={{ width: 160, justifySelf: 'end' }}
              >
                {i18n.t('button.save')}
              </Button>
            </ButtonContainer>
          </ModalContent>
        </Overlay>
      )}
      <ElephantChat text={i18n.t('editSurveyPage.uploadYourSigned')} />
      <Content>
        <HeaderContainer>
          <div>
            <Title>{i18n.t('editSurveyPage.documentFiles')}</Title>
            <Caption>{i18n.t('editSurveyPage.completedCarePlans')}</Caption>
          </div>
          <UploadBtnContainer>
            <Button onClick={() => history.push(`/app?mode=completedPlans&uploader=true`)}>
              {i18n.t('editSurveyPage.uploadFile')}
            </Button>
          </UploadBtnContainer>
        </HeaderContainer>

        {/* <div style={{position: 'absolute', right: 16, top: 16}}>
          
        </div> */}
        <TableHeader />
        {loading && <Loading />}
        {attachments?.map((file) => {
          if (!file?.id) return null
          return (
            <FileRow
              key={file?.id}
              file={file}
              loading={deletingFile}
              onDelete={() => onDelete(file?.id)}
              onDownload={() => downloadPDF(file)}
            />
          )
        })}
        {attachments && attachments.length === 0 && (
          <EmptyState
            title={i18n.t('editSurveyPage.noFiles')}
            subtitle={i18n.t('editSurveyPage.clickUploadFile')}
            style={{ marginTop: 24 }}
          />
        )}
      </Content>
    </Container>
  )
}
