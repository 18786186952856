import passwordValidator from 'password-validator'

let passwordSchema = new passwordValidator()

passwordSchema
  .has()
  .uppercase(1) // needs translation
  .has()
  .lowercase(1) // needs translation
  .has()
  .digits(1) // needs translation
  .has()
  .not()
  .spaces() // needs translation
  .is()
  .min(8) // needs translation
  .is()
  .max(100) // needs translation
  .is()
  .not()
  .oneOf(['Passw0rd', 'Password123']) // needs translation

export default passwordSchema
