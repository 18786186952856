import { useTranslation } from 'react-i18next'
// COMPONENTS
import SelectMultiple from 'components/common/SurveyForm/SelectMultiple'
import DontKnowDecisionMaker from 'components/common/SurveyForm/DontKnowDecisionMaker'
import ResignNoticeModal from 'components/common/ResignNoticeModal'
import { SharedContainer, Container } from '../index'
import SectionHeader from '../SectionHeader'
import { useHistory } from 'react-router-dom'
import { GuideTemplateSectionIdEnum, Patient, GuideQuestionIdEnum, UserProfile } from 'generated/graphql'
import ConfirmRemoveMdmModal, { DeleteMDMType } from 'components/common/ConfirmRemoveMdmModal'
import message from 'components/common/message'
import { QuestionItem } from 'lib/types/QuestionItem'
import i18n from 'lib/helpers/i18n'

interface DecisionMakerQuestionsProps {
  plan: Patient
  currentUser: UserProfile
  handleSavePatient: Function
  dontKnowDM: boolean
  handleOnResign: () => void
  handleCancelResign: () => void
  saving: boolean
  showEditWarning: object
  editSection: string | GuideTemplateSectionIdEnum
  progress: string
  sharedProps: any
  questions: QuestionItem[]
  currentQuestion: QuestionItem
  setConfirmDelete: React.Dispatch<React.SetStateAction<DeleteMDMType | undefined>>
  setStep: (v: string) => Promise<void>
  setDontKnowDM: React.Dispatch<React.SetStateAction<boolean>>
  onNext: (plan?: Patient) => void
  confirmDelete: DeleteMDMType
  defaultPlan: Patient
}

const mdmRemoved = i18n.t('DecisionMakerQuestions.mdmRemoved', 'Decision maker successfully removed')

export default function DecisionMakerQuestions({
  plan,
  currentUser,
  handleSavePatient,
  dontKnowDM,
  handleOnResign,
  handleCancelResign,
  saving,
  showEditWarning,
  editSection,
  progress,
  sharedProps,
  questions,
  currentQuestion,
  setConfirmDelete,
  setStep,
  setDontKnowDM,
  onNext,
  confirmDelete,
  defaultPlan,
}: DecisionMakerQuestionsProps) {
  const history = useHistory()
  const { t } = useTranslation()

  const altOnNext = async (args: { fromHeader?: boolean }) => {
    // 1. If they have previously selected an mdm AND they change their answer to no, then we show the modal to confirm they really want to delete this decision maker
    if (
      plan[GuideQuestionIdEnum.AltDecisionMakerFirstName] &&
      plan[GuideQuestionIdEnum.WantsAltDecisionMaker] === 'no'
    ) {
      return setConfirmDelete(DeleteMDMType.altMDM)
    }

    // 2. if they have nobody set (ie never chose anyoneas mdm), then we just let them move on to the next question, without any confirmation modal
    if (
      !plan[GuideQuestionIdEnum.AltDecisionMakerFirstName] &&
      plan[GuideQuestionIdEnum.WantsAltDecisionMaker] === 'no'
    ) {
      sharedProps.onChange({
        ...plan,
        [GuideQuestionIdEnum.WantsAltDecisionMaker]: 'no',
      })
      return await handleSavePatient(
        {
          ...plan,
          [GuideQuestionIdEnum.WantsAltDecisionMaker]: 'no',
        },
        () => history.push(`/app`)
      )
    }

    if (plan[GuideQuestionIdEnum.WantsAltDecisionMaker] === 'yes') {
      let planData = {
        ...plan,
        [GuideQuestionIdEnum.DoYouKnowDecisionMaker]: 'yes',
      }
      sharedProps.onChange(planData)
      return handleSavePatient(planData, args?.fromHeader ? () => history.push('/app') : onNext)
    }
  }

  const thirdOnNext = async (args: { fromHeader?: boolean }) => {
    // 1. If they have previously selected an mdm AND they change their answer to no, then we show the modal to confirm they really want to delete this decision maker
    if (
      plan[GuideQuestionIdEnum.ThirdDecisionMakerFirstName] &&
      plan[GuideQuestionIdEnum.WantsThirdDecisionMaker] === 'no'
    ) {
      return setConfirmDelete(DeleteMDMType.thirdMDM)
    }

    // 2. if they have nobody set (ie never chose anyoneas mdm), then we just let them move on to the next question, without any confirmation modal
    if (
      !plan[GuideQuestionIdEnum.ThirdDecisionMakerFirstName] &&
      plan[GuideQuestionIdEnum.WantsThirdDecisionMaker] === 'no'
    ) {
      sharedProps.onChange({
        ...plan,
        [GuideQuestionIdEnum.WantsThirdDecisionMaker]: 'no',
      })
      return await handleSavePatient(
        {
          ...plan,
          [GuideQuestionIdEnum.WantsThirdDecisionMaker]: 'no',
        },
        () => history.push(`/app`)
      )
    }

    // if they selected yes, we just save their response and then go to the next question
    if (plan[GuideQuestionIdEnum.WantsThirdDecisionMaker] === 'yes') {
      sharedProps.onChange({
        ...plan,
        [GuideQuestionIdEnum.WantsThirdDecisionMaker]: 'yes',
      })
      return await handleSavePatient(
        {
          ...plan,
          [GuideQuestionIdEnum.WantsThirdDecisionMaker]: 'yes',
        },
        args?.fromHeader ? () => history.push('/app') : onNext
      )
    }
  }

  const mdmOnNext = (args: { fromHeader?: boolean }) => {
    /**
     *  1. If they have previously selected an mdm
     *  AND they change their answer to no, then we
     *  show the modal to confirm they really want
     *  to delete this decision maker
     */
    if (plan[GuideQuestionIdEnum.DecisionMakerFirstName] && plan.doYouKnowDecisionMaker === 'no') {
      return setConfirmDelete(DeleteMDMType.mdm)
    }
    /**
     *  2. if they have nobody set (ie never chose anyone as mdm),
     *  then we just let them move on to the next question,
     *  without any confirmation modal
     */
    if (!plan[GuideQuestionIdEnum.DecisionMakerFirstName] && plan.doYouKnowDecisionMaker === 'no') {
      let planData = {
        ...plan,
        [GuideQuestionIdEnum.DoYouKnowDecisionMaker]: 'no',
      }
      sharedProps.onChange(planData)
      return handleSavePatient(planData, () => setDontKnowDM(true))
    }

    // if they selected yes, we just save their response and then go to the next question
    if (!plan[GuideQuestionIdEnum.DecisionMakerFirstName] && plan.doYouKnowDecisionMaker === 'yes') {
      let planData = {
        ...plan,
        [GuideQuestionIdEnum.DoYouKnowDecisionMaker]: 'yes',
      }
      sharedProps.onChange(planData)
      return handleSavePatient(planData, onNext)
    }

    if (plan[GuideQuestionIdEnum.DecisionMakerFirstName] && plan.doYouKnowDecisionMaker === 'yes') {
      let planData = {
        ...plan,
        [GuideQuestionIdEnum.DoYouKnowDecisionMaker]: 'yes',
      }
      sharedProps.onChange(planData)
      return handleSavePatient(planData, args?.fromHeader ? () => history.push('/app') : onNext)
    }
  }

  const section = plan?.guideTemplate?.sections?.find((section) => section.sectionId === editSection)

  const sectionId = section?.sectionId

  if (dontKnowDM) {
    return (
      <div style={{ width: '100%' }}>
        <ResignNoticeModal
          onResign={handleOnResign}
          onCancel={handleCancelResign}
          loading={saving}
          visible={showEditWarning ? true : false}
        />
        <SectionHeader
          sectionName={t(`planConfigurator.${sectionId}`)}
          progress={progress}
          patient={plan}
          currentUser={currentUser}
          onSave={() => {
            handleSavePatient(plan, () => history.push('/app'))
          }}
        />
        <SharedContainer currentUser={currentUser}>
          <Container>
            <DontKnowDecisionMaker
              {...sharedProps}
              onNext={() => {
                // if they continue, we want to clear all decision maker related question EXCEPT the yes/no one saying they dont want one now
                handleSavePatient(
                  {
                    ...plan,
                    decisionMakerFirstName: null,
                    decisionMakerLastName: null,
                    decisionMakerRelationship: null,
                    decisionMakerStrictness: null,
                    whenCanDecisionMakerDecide: null,
                    decisionMakerEmail: null,
                    decisionMakerPhone: null,
                    wantsAltDecisionMaker: null,
                    altDecisionMakerFirstName: null,
                    altDecisionMakerLastName: null,
                  },
                  () => history.push(`/app`)
                )
              }}
              onBack={() => {
                questions.forEach((q, i) => {
                  // if they said they don't know their decision maker, and they want to go back, they have to go back to a specific step
                  if (q && q.fieldName === 'doYouKnowDecisionMaker') {
                    sharedProps.onChange({ doYouKnowDecisionMaker: undefined })
                    return setStep(`${i}`)
                  }
                })
              }}
            />
          </Container>
        </SharedContainer>
      </div>
    )
  }

  /**
   * we only show this question if the user wants an alternative decision maker
   * wantsAltDecisionMaker will require specific logic for it's on change function
   */

  if (currentQuestion.fieldName === GuideQuestionIdEnum.WantsAltDecisionMaker) {
    return (
      <div style={{ width: '100%' }}>
        <ResignNoticeModal
          onResign={handleOnResign}
          onCancel={handleCancelResign}
          loading={saving}
          visible={showEditWarning ? true : false}
        />
        <SectionHeader
          sectionName={t(`planConfigurator.${sectionId}`)}
          progress={progress}
          currentUser={currentUser}
          onSave={() => altOnNext({ fromHeader: true })}
          patient={plan}
        />
        <SharedContainer currentUser={currentUser}>
          <Container>
            <SelectMultiple
              {...sharedProps}
              // we require some special logic for onNext
              onNext={altOnNext}
            />
          </Container>
        </SharedContainer>
        {confirmDelete && (
          <ConfirmRemoveMdmModal
            onConfirm={(planData) => {
              sharedProps.onChange(planData)
              setConfirmDelete(undefined)
              message.success(mdmRemoved)
              history.push(`/app`)
            }}
            plan={plan}
            type={confirmDelete}
            onCancel={() => {
              sharedProps.onChange(defaultPlan)
              setConfirmDelete(undefined)
              history.push(`/app`)
            }}
          />
        )}
      </div>
    )
  }

  /**
   * If the current question is WantsThirdDecisionMaker, then we show this component
   */

  if (currentQuestion?.fieldName === GuideQuestionIdEnum.WantsThirdDecisionMaker) {
    return (
      <div style={{ width: '100%' }}>
        <ResignNoticeModal
          onResign={handleOnResign}
          onCancel={handleCancelResign}
          loading={saving}
          visible={showEditWarning ? true : false}
        />
        <SectionHeader
          sectionName={t(`planConfigurator.${sectionId}`)}
          progress={progress}
          currentUser={currentUser}
          onSave={() => thirdOnNext({ fromHeader: true })}
          patient={plan}
        />
        <SharedContainer currentUser={currentUser}>
          <Container>
            <SelectMultiple
              {...sharedProps}
              // we require some special logic for onNext
              onNext={thirdOnNext}
            />
          </Container>
        </SharedContainer>
        {confirmDelete && (
          <ConfirmRemoveMdmModal
            onConfirm={() => {
              setConfirmDelete(undefined)
              message.success(mdmRemoved)
              return history.push(`/app`)
            }}
            plan={plan}
            type={confirmDelete}
            onCancel={() => {
              sharedProps.onChange(defaultPlan)
              setConfirmDelete(undefined)
            }}
          />
        )}
      </div>
    )
  }

  /**
   * If the current question is DoYouKnowDecisionMaker, then we show this component
   */
  if (currentQuestion.fieldName === GuideQuestionIdEnum.DoYouKnowDecisionMaker) {
    return (
      <div style={{ width: '100%' }}>
        <ResignNoticeModal
          onResign={handleOnResign}
          onCancel={handleCancelResign}
          loading={saving}
          visible={showEditWarning ? true : false}
        />
        <SectionHeader
          sectionName={t(`planConfigurator.${sectionId}`)}
          progress={progress}
          onSave={() => mdmOnNext({ fromHeader: true })}
          currentUser={currentUser}
          patient={plan}
        />
        <SharedContainer currentUser={currentUser}>
          <Container>
            <SelectMultiple {...sharedProps} key={currentQuestion.fieldName} onNext={mdmOnNext} />
          </Container>
        </SharedContainer>
        {confirmDelete && (
          <ConfirmRemoveMdmModal
            onConfirm={() => {
              setConfirmDelete(undefined)
              return setDontKnowDM(true)
            }}
            plan={plan}
            type={confirmDelete}
            onCancel={() => {
              sharedProps.onChange(defaultPlan)
              setConfirmDelete(undefined)
            }}
          />
        )}
      </div>
    )
  }

  return null
}
