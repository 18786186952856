import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { useWindowWidth } from '@react-hook/window-size'
// COMPONENTS
import ElephantChat from 'components/common/ElephantChat'
import Button from 'components/common/Button'
import PlanDoc from 'components/common/PlanDoc'
import BottomBar from 'components/common/BottomBar'
import ReadinessSurveyModal from 'components/common/ReadinessSurveyModal'
// LIB
import theme from 'lib/theme'
import { AnalyticEventTypeEnums } from 'lib/types/AnalyticEventTypeEnums'
import ArrowRightOutlined from 'components/icons/ArrowRightOutlined'
import { Patient, UserProfile, PatientActionHistoryItemEnum } from 'generated/graphql'
import PlanConfiguratorHelper from '../PlanConfigurator/PlanConfiguratorHelper'
import useSavePatient from 'lib/hooks/useSavePatient'
import i18n from 'lib/helpers/i18n'
import PostSurveyAlert from 'components/common/PostSurveyAlert'
import getActionItemHistoryItem from 'lib/helpers/getActionItemHistoryItem'
import isWakeForest from 'lib/helpers/isWakeForest'
import logEvent from 'lib/helpers/logEvent'

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-bottom: 16px;
  padding-right: 16px;
  flex: 1;
`

const ButtonWrapper = styled.div`
  max-width: 100%;
  margin-right: 4px;
  button {
    min-width: 100px;
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    div {
      width: 100%;
    }
    button {
      min-width: auto;
      margin-right: 0;
      width: 100%;
      padding: 0 16px !important;
    }
  }
`

const PrimaryButtonWrapper = styled.div`
  max-width: 100%;
  margin-right: 4px;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    div {
      width: 100%;
    }
    button {
      min-width: 140px;
      margin-right: 0;
      width: 100%;
    }
  }
`

const EditSignButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

const getElephantText = (patient: Patient) => {
  const hasESign = patient?.stateVersion?.hasESign

  const planHelpers = new PlanConfiguratorHelper()

  const guideComplete = planHelpers.getguideComplete({
    patient,
    modules: patient?.guideTemplate?.sections || [],
  })

  // if complete and plan is not official and state is esign
  if (hasESign && guideComplete && !patient.hasOfficialPlan) {
    return i18n.t('getElephantText.textWitnessEsigning')
  }

  if (hasESign && guideComplete && patient.hasOfficialPlan) {
    return i18n.t('getElephantText.textResignDocument')
  }

  if (!hasESign && guideComplete && !patient.hasOfficialPlan) {
    return i18n.t('getElephantText.textSharePlanEmail')
  }
  // if not complete and plan is not official and state is esign
  if (hasESign && !guideComplete && !patient.hasOfficialPlan) {
    return i18n.t('getElephantText.textEditPlanComplete')
  }

  return i18n.t('getElephantText.textEditPlanCompleteRemaining')
}

interface PrimaryButtonProps {
  patient: Patient
  onClick: () => void
}

const PrimaryButton = ({ patient, onClick }: PrimaryButtonProps) => {
  return patient?.stateVersion?.hasESign ? (
    <>
      {!patient.hasOfficialPlan && (
        <Button pulse={true} onClick={onClick} data-testid="continue-esigning-btn">
          {i18n.t('button.continue', 'Continue')} <ArrowRightOutlined />
        </Button>
      )}
      {patient.hasOfficialPlan && (
        <Button pulse={true} onClick={onClick}>
          {i18n.t('btnSharePlan')} <ArrowRightOutlined />
        </Button>
      )}
    </>
  ) : (
    <Button pulse={true} onClick={onClick}>
      {i18n.t('btnSharePlan')} <ArrowRightOutlined />
    </Button>
  )
}

const EditButton = ({ style = {}, onClick }) => {
  return (
    <Button grey style={style} onClick={onClick}>
      {i18n.t('button.edit')}
    </Button>
  )
}

const SignButton = ({ onClick }) => {
  return (
    <Button style={{ marginRight: 8 }} grey onClick={onClick}>
      {i18n.t('button.sign')}
    </Button>
  )
}

interface ViewDirectiveProps {
  patient: Patient
  currentUser?: UserProfile
  guideComplete: boolean
}

export const hasViewedCompletedPlanPdf = 'hasViewedCompletedPlanPdf'

export default function ViewDirective({ patient, guideComplete }: ViewDirectiveProps) {
  const [showReadinessModal, setShowReadinessModal] = useState<boolean>(false)
  const [readinessCallback, setReadinessCallback] = useState<Function | undefined>(undefined)
  const history = useHistory()
  const { onSavePatient } = useSavePatient()
  const windowWidth = useWindowWidth()

  useEffect(() => {
    if (!patient?.hasPlanViewed) {
      onSavePatient({
        ...patient,
        hasPlanViewed: true,
      })
    }
  }, [patient, onSavePatient])

  const onShare = () => {
    history.push('/app?mode=emailDirective')
  }

  const onEdit = () => {
    logEvent(AnalyticEventTypeEnums.EDIT_PLAN_CLICKED)
    history.push(`/app`)
  }

  const onSign = () => {
    history.push('/app?mode=esignAdditionalInformation')
  }

  const onButtonClick = (callback) => {
    const patientHasClosedModal = getActionItemHistoryItem(
      patient,
      PatientActionHistoryItemEnum.ClosedReadinessSurveyModal
    )
    const isWF = isWakeForest(patient)
    const hasBeenAskedReadiness = patient?.readiness?.[0] // if they have at least one result, it means we asked this question
    /** if the user hasn't been asked the readiness question yet and they don't have an offical plan, let's ask them the readiness question */
    if (!isWF && guideComplete && !patientHasClosedModal && !hasBeenAskedReadiness && !patient?.hasOfficialPlan) {
      setShowReadinessModal(true)
      setReadinessCallback(() => () => callback()) // store the call back so we can call it when the user answers the readiness question => https://stackoverflow.com/a/67336640/5627036
    } else {
      // if we don't need to show them the modal, then we just run whatever function is passed in
      callback()
    }
  }

  return (
    <>
      <ReadinessSurveyModal
        patient={patient}
        visible={showReadinessModal}
        onClose={() => {
          setShowReadinessModal(false)
          if (readinessCallback) {
            readinessCallback()
          }
        }}
        onSubmitComplete={async () => {
          try {
            setShowReadinessModal(false) // close the modal
            if (readinessCallback) {
              readinessCallback()
            }
          } catch (err) {}
        }}
      />
      <div
        style={{
          minHeight: '85vh',
          height: '100%',
          flex: 1,
          width: 900,
          maxWidth: '100%',
          margin: 'auto',
        }}
      >
        <PostSurveyAlert guideComplete={guideComplete} clinicId={patient?.clinic?.id} patient={patient} />
        <div style={{ display: 'flex', marginBottom: 48 }}>
          <ElephantChat text={getElephantText(patient)} />
        </div>{' '}
        {windowWidth > parseInt(theme.breakpoints.medium) && (
          <ButtonContainer>
            <EditButton onClick={() => onButtonClick(onEdit)} style={{ marginRight: '8px' }} />
            {!patient.hasOfficialPlan && patient?.stateVersion?.hasESign && (
              <SignButton onClick={() => onButtonClick(onSign)} />
            )}
            <PrimaryButton patient={patient} onClick={() => onButtonClick(onShare)} />
          </ButtonContainer>
        )}
        {/* */}
        <div style={{ height: '80vh' }}>{patient?.clinic && <PlanDoc data={patient} clinic={patient?.clinic} />}</div>
        {windowWidth <= parseInt(theme.breakpoints.medium) && (
          <BottomBar style={{ justifyContent: 'space-between' }}>
            <EditSignButtonsWrapper>
              <ButtonWrapper>
                <EditButton onClick={() => onButtonClick(onEdit)} />
              </ButtonWrapper>
              {!patient.hasOfficialPlan && patient?.stateVersion?.hasESign && (
                <ButtonWrapper>
                  <SignButton onClick={() => onButtonClick(onSign)} />
                </ButtonWrapper>
              )}
            </EditSignButtonsWrapper>
            <PrimaryButtonWrapper>
              <PrimaryButton patient={patient} onClick={() => onButtonClick(onShare)} />
            </PrimaryButtonWrapper>
          </BottomBar>
        )}
      </div>
    </>
  )
}
