import { AdPartEnum, PlanSetting } from '../types'
import en from 'lib/helpers/locales/en/translation.json'

export default function getFL(t, defaultPartSettings): PlanSetting {
  const fl: PlanSetting = {
    state: 'Florida',
    disclaimer: t('FL.disclaimer'),
    parts: [
      // Part 1
      {
        ...defaultPartSettings?.[AdPartEnum.health_care_choices],
      },
      // Part 2
      {
        ...defaultPartSettings?.[AdPartEnum.medical_decision_maker],
        description: t('FL.medical_decision_maker.description'),
      },
      // Part 3
      {
        ...defaultPartSettings?.[AdPartEnum.sign_the_form],
        description: t('FL.sign_the_form.description'),
        beforeYouSign: {
          requirements: [
            t(
              'planDoc.sign_the_form.beforeYouSignRequirements_age',
              en.planDoc.sign_the_form.beforeYouSignRequirements_age
            ),
            t('planDoc.sign_the_form.beforeYouSignRequirements_witnesses'),
          ],
          finalClause: t(
            'planDoc.sign_the_form.beforeYouSignFinalClause',
            'This Advanced Directive accurately expresses my wishes.'
          ),
        },
        witnessRequirements: {
          title: t('FL.sign_the_form.witnessRequirements_title'), // witnessRequirements_title
          numberOfWitnessesRequired: 2,
          numberOfWitnessesThatMustMeetRequirements: 1,
          validityRequirements: [
            t('planDoc.sign_the_form.validityRequirements_esignInThePresence'),
            t('planDoc.sign_the_form.beforeYouSignRequirements_witnesses'),
          ],
          requirements: [
            {
              title: t('planDoc.sign_the_form.witnessRequirements_yourWitnessMust'), // witnessRequirements_yourWitnessMust
              phrases: [
                t('planDoc.sign_the_form.witnessRequirements_be18'),
                t('planDoc.sign_the_form.witnessRequirements_seeYouSign', 'see you sign the form'),
                t('planDoc.sign_the_form.witnessRequirements_bePresent'),
              ],
            },
            {
              title: t('planDoc.sign_the_form.witnessRequirements_bothWitnessesCannot'), //
              phrases: [t('FL.sign_the_form.witnessRequirements_beMDM')], //
            },
            {
              title: t('planDoc.sign_the_form.witnessRequirements_atLeastOneWitnessCannot'), //
              phrases: [t('planDoc.sign_the_form.witnessRequirements_beSouseOrBlood')], //
            },
          ],
        },
      },
    ],
  }

  return fl
}
