import { get } from 'lodash'
import * as Sentry from '@sentry/browser'
import { LocalStorageKeyEnum } from 'lib/types/LocalStorageKeyEnum'
import setLocalStorage from './setLocalStorage'

/**
 * Given a user ID, this will identify a user in mixpanel analyltics and fullstory
 */
export default function identifyUser(userId: string, clinicId: string) {
  const mixpanel = get(window, 'mixpanel')
  const FS = get(window, 'FS')

  if (mixpanel && userId) {
    mixpanel?.identify(userId)
    mixpanel.people.set({ $name: userId, $clinicId: clinicId })
  }
  if (FS) {
    FS?.identify(userId)
  }
  if (Sentry) {
    Sentry?.setUser({ id: userId })
  }

  setLocalStorage(LocalStorageKeyEnum.UserId, userId)
}
