import theme from 'lib/theme';
import {useWindowWidth} from '@react-hook/window-size';

export default function useGetVideoDimensions() {
  const screenWidth = useWindowWidth();

  const getHeight = () => {
    if (screenWidth <= parseInt(theme.breakpoints.xSmall)) {
      return '240';
    }
    if (screenWidth <= parseInt(theme.breakpoints.small)) {
      return '275';
    }
    if (screenWidth >= parseInt(theme.breakpoints.medium)) {
      return '315';
    }

    return '315';
  };

  const getWidth = () => {
    if (screenWidth <= parseInt(theme.breakpoints.xxSmall)) {
      return '300';
    }
    if (screenWidth <= parseInt(theme.breakpoints.xSmall)) {
      return '340';
    }
    if (screenWidth <= parseInt(theme.breakpoints.small)) {
      return '370';
    }
    if (screenWidth <= parseInt(theme.breakpoints.mediumSmall)) {
      return '414';
    }
    if (screenWidth >= parseInt(theme.breakpoints.medium)) {
      return '600';
    }
    return '600';
  };

  return {
    width: getWidth(),
    height: getHeight(),
  };
}
