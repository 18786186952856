import { Page, Text, View } from '@react-pdf/renderer'
// helpers
import s from '../styles'
// LIB
import theme from 'lib/theme'
import i18n from 'lib/helpers/i18n'

interface TopPageBlockProps {
  name?: string
  text?: string
}

const TopPageBlock = ({ name, text }: TopPageBlockProps) => {
  return (
    <View
      style={{
        paddingTop: 8,
        paddingLeft: 32,
        backgroundColor: theme.colors.primary1,
        height: 32,
        position: 'relative',
        marginBottom: 15
      }}
    >
      <Text style={{ fontFamily: 'Roboto Bold', fontSize: 14, color: '#fff' }}>{text}</Text>
      {name && (
        <Text
          style={{
            fontFamily: 'Roboto Bold',
            fontSize: 14,
            color: '#fff',
            position: 'absolute',
            right: 16,
            top: 8,
          }}
        >
          {name}
        </Text>
      )}
    </View>
  )
}

interface DocPageProps {
  content: JSX.Element
  /** the full name of the patient which will show in the top title area */
  name: string
  pageTitle?: string
  /** hide or show page numbers at the bottom of a page*/
  createdStamp?: string
  /** hide or show page numbers at the bottom of a page*/
  showPageNumbers?: boolean

  wrap?: boolean
}

const DocPage = ({ content, name, pageTitle, createdStamp, showPageNumbers, wrap }: DocPageProps) => {
  return (
    <Page wrap>
      <TopPageBlock text={pageTitle} name={name} />
      <View style={s.body}>{content}</View>
      {showPageNumbers && (
        <>
          <Text style={s.pageNumber} render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`} fixed />
          <Text style={s.created}>{`${i18n.t('DocPage.lastUpdated', 'Last updated: ')} ${createdStamp}`}</Text>
        </>
      )}
    </Page>
  )
}

export default DocPage
