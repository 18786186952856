import { StyleSheet } from '@react-pdf/renderer'
// LIB
import theme from 'lib/theme'
import { PlanFontFamily } from '../PlanFontFamily'

const TEXT = StyleSheet.create({
  text: {
    margin: 8,
    marginLeft: 0,
    color: theme.colors.neutral1,
    fontSize: 12,
    textAlign: 'justify',
    fontFamily: PlanFontFamily.regular,
  },
})

const s = StyleSheet.create({
  body: {
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  textLeftMargin: {},
  title: {
    fontSize: 24,
    textAlign: 'center',
    margin: 0,
    color: theme.colors.neutral1,
    fontFamily: PlanFontFamily.bold,
  },
  author: {
    fontSize: 12,
    textAlign: 'left',
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 16,
    marginTop: 16,
    color: theme.colors.neutral1,
    marginLeft: 0,
    fontFamily: PlanFontFamily.bold,
  },
  text: TEXT.text,
  textNoMargin: {
    ...TEXT.text,
    margin: 0,
  },
  textBlue: {
    ...TEXT.text,
    color: theme.colors.primary3,
  },
  textBold: {
    ...TEXT.text,
    fontFamily: PlanFontFamily.bold,
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 32,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: theme.colors.neutral5,
  },
  created: {
    position: 'absolute',
    fontSize: 12,
    bottom: 32,
    left: 0,
    right: 32,
    textAlign: 'right',
    color: theme.colors.neutral5,
  },
  blueText: {
    margin: 8,
    marginLeft: 0,
    color: theme.colors.primary1,
    fontSize: 14,
    textAlign: 'justify',
    fontFamily: PlanFontFamily.bold,
  },
  sectionContainer: {
    borderWidth: 2,
    borderColor: theme.colors.neutral9,
    borderRadius: 5,
    padding: 4,
    marginTop: 4,
  },
  sectionTitle: {
    fontSize: 16,
    margin: 0,
    textAlign: 'center',
    color: theme.colors.primary1,
    marginBottom: 6,
    fontFamily: PlanFontFamily.bold,
  },
  formLabel: {
    margin: 2,
    marginLeft: 0,
    color: theme.colors.neutral1,
    fontSize: 11,
    textAlign: 'justify',
    fontFamily: PlanFontFamily.regular,
  },
})

export default s
