import styled from 'styled-components';

export const SurveyFormLabel = styled.label`
  font-size: 24px;
  line-height: 150%;
  display: block;
  font-weight: 400;
  margin-bottom: 32px;

  font-family: ${(p) => p.theme.fontFamily};
  color: ${(p) => p.theme.colors.neutral2};
  @media only screen and (max-width: ${({theme}) => theme.breakpoints.medium}) {
    font-size: 16px;
    margin-bottom: 8px;
    line-height: 125%;
  }

  a {
    font-size: 24px;
    line-height: 32px;
    font-weight: 400;
    margin-bottom: 32px;
    font-family: ${(p) => p.theme.fontFamily};
    @media only screen and (max-width: ${({theme}) =>
        theme.breakpoints.medium}) {
      font-size: 16px;
      margin-bottom: 8px;
      line-height: 125%;
    }
  }
`;

export default SurveyFormLabel;
