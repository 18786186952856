import CloseOutlined from 'components/icons/CloseOutlined'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Patient } from 'generated/graphql'
import { useState } from 'react'
import { AnalyticEventTypeEnums } from 'lib/types/AnalyticEventTypeEnums'
import PlanConfiguratorHelper from 'routes/app-home/PlanConfigurator/PlanConfiguratorHelper'
import { Trans } from 'react-i18next'
import { LocalStorageKeyEnum } from 'lib/types/LocalStorageKeyEnum'
import getLocalStorage from 'lib/helpers/getLocalStorage'
import logEvent from 'lib/helpers/logEvent'
import setLocalStorage from 'lib/helpers/setLocalStorage'

const AlertContainer = styled.div`
  padding: 24px 16px;
  background: ${(p) => p.theme.colors.success10};
  color: ${(p) => p.theme.colors.success1};
  margin-bottom: 24px;
  border-radius: 5px;
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  border: 1px solid ${(p) => p.theme.colors.success1};
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    font-size: 16px;
  }
  a {
    color: ${(p) => p.theme.colors.success1} !important;
    font-weight: 600;
    text-decoration: underline;
    &:hover {
      text-decoration: underline;
    }
  }
`

const Button = styled.button`
  background: transparent;
  border: 2px solid ${(p) => p.theme.colors.success1};
  color: ${(p) => p.theme.colors.success1};
  border-radius: 5px;
  width: 24px;
  height: 24px;
  font-size: 18px;
  display: flex;
  padding: 0px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

const postSurveyAlertDismissedValue = 'YES'

// const postSurveyAlertDismissed = "postSurveyAlertDismissed";

const planHelpers = new PlanConfiguratorHelper()

export default function PostSurveyAlert({
  guideComplete,
  clinicId,
  patient,
}: {
  guideComplete: boolean
  clinicId: string
  patient: Patient
}) {
  const [dismissed, setDismissed] = useState(
    getLocalStorage(LocalStorageKeyEnum.PostSurveyAlertDismissed) === postSurveyAlertDismissedValue
  )

  const isPostSurveyComplete = planHelpers.getIsPostAcpSurveyCompleted(patient)

  // if they're not part of a specific clinic, don't show the alert
  if (patient?.clinic?.slug !== 'wfpath1') return null

  // if survey is complete, do not need to show alert
  if (isPostSurveyComplete) return null

  // if steps are not complete, then don't show this alert
  if (!guideComplete) return null

  // if it's been dismissed, don't show it
  if (dismissed) {
    return null
  }

  // if they patient has not veiwed their plan yet, do not show the alert
  if (!patient?.hasPlanViewed) {
    return null
  }

  const handleDismiss = () => {
    // log event
    logEvent(AnalyticEventTypeEnums.POST_SURVEY_ALERT_DISMISSED, {
      clinicId,
      patientId: patient?.id,
    })

    setLocalStorage(LocalStorageKeyEnum.PostSurveyAlertDismissed, postSurveyAlertDismissedValue)
    setDismissed(true)
  }

  const handleSurveyLinkClicked = () => {
    // log event
    logEvent(AnalyticEventTypeEnums.POST_SURVEY_ALERT_LINK_CLICK, {
      clinicId,
      patientId: patient?.id,
    })
  }

  return (
    <AlertContainer>
      <div>
        <Trans i18nKey="PostSurveyAlert.text">
          Don't forget to complete the
          <Link to="/app/post-survey" onClick={() => handleSurveyLinkClicked()}>
            Post-ACP Survey
          </Link>{' '}
          to recieve your $25 gift card.
        </Trans>
      </div>
      <Button>
        <CloseOutlined onClick={handleDismiss} />
      </Button>
    </AlertContainer>
  )
}
