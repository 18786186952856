import styled from 'styled-components'

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0;
  z-index: 10000;
  height: 100vh;
  overflow-y: hidden;
  background: rgba(0, 0, 0, 0.45);
`

export const Modal = styled.div`
  background-color: #ffffff;
  max-width: 100%;
  width: 480px;
  border-radius: 5px;
  position: relative;
  max-height: 100vh;
  overflow-y: scroll;
  min-height: 400px;
  border-radius: 5px;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.mediumSmall}) {
    max-width: 100%;
    position: fixed;
    top: 0px;
    bottom: 0px;
  }
`

export const ModalBody = styled.div`
  padding: 48px 32px;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.mediumSmall}) {
    padding: 32px 16px 112px 16px;
  }
`

export const ButtonArea = styled.div`
  background: ${({ theme }) => theme.colors.neutral11};
  height: 10%;
  bottom: 0px;
  right: 0px;
  left: 0px;
  height: 80px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: ${({ style }) => style?.justifyContent || 'space-between'};
  padding: 8px 32px;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.mediumSmall}) {
    position: fixed;
    padding: 8px;
  }
`
