import type { ReactNode } from 'react'
import styled, { CSSProperties } from 'styled-components'
import FormLabel from './FormLabel'
import HintText from './HintText'
import Popover from 'components/common/Popover'
import QuestionCircleFilled from 'components/icons/QuestionCircleFilled'
import theme from 'lib/theme'
import { createRef, useEffect } from 'react'
import ErrorText from './ErrorText'

const Container = styled.div`
  margin-top: 22px;
  position: relative;
`

const Required = styled.span`
  color: ${(p) => p.theme.colors.red4};
  font-size: 18px;
  position: relative;
  top: 4px;
  right: -3px;
  line-height: 10px;
`

interface FormItemContainerProps {
  style: CSSProperties
}

interface FormItemProps {
  label: string
  colon?: string
  hint?: string
  error?: string | null
  children?: ReactNode
  containerProps?: FormItemContainerProps
  required?: boolean
  htmlFor?: string
  tooltip?: string | JSX.Element
}

const FormItem = ({
  label,
  colon,
  hint,
  error,
  children,
  containerProps,
  required,
  htmlFor,
  tooltip,
}: FormItemProps) => {
  const input = createRef<HTMLDivElement>()

  useEffect(() => {
    if (error?.[0]) {
      input?.current?.scrollIntoView({
        behavior: 'smooth',
        inline: 'center',
      })
    }
  }, [error, input])

  return (
    <Container ref={input} {...containerProps}>
      {label && (
        <FormLabel htmlFor={htmlFor}>
          {label}
          {colon ? ':' : null}
          {required ? <Required>*</Required> : null}
          {tooltip ? (
            <Popover trigger={['click']} content={<div style={{ width: 350, maxWidth: '100%' }}>{tooltip}</div>}>
              <QuestionCircleFilled
                style={{
                  fontSize: 14,
                  color: theme.colors.primary3,
                  marginLeft: 6,
                  cursor: 'pointer',
                  position: 'relative',
                  top: -2,
                }}
              />
            </Popover>
          ) : null}
        </FormLabel>
      )}

      {children}
      {error && <ErrorText data-testid={`error-text-for-${htmlFor}`}>{error}</ErrorText>}
      {hint && <HintText>{hint}</HintText>}
    </Container>
  )
}

FormItem.defaultProps = {
  label: null,
  containerProps: {},
  colon: false,
  required: false,
}

export default FormItem
