import styled from 'styled-components';

export default styled.p`
  max-width: 100%;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${(p) => p.theme.colors.neutral5};
  margin: 0px;
  letter-spacing: 1px;
`;
