import { AdPartEnum, PlanSetting } from '../types'

export default function getVA(t, defaultPartSettings): PlanSetting {
  const va: PlanSetting = {
    state: 'Virginia',
    disclaimer: t('VA.disclaimer', 'Disclaimer: This document needs to be signed and have two witnesses sign as well.'),
    parts: [
      // Part 1
      {
        ...defaultPartSettings?.[AdPartEnum.health_care_choices],
      },
      // Part 2
      {
        ...defaultPartSettings?.[AdPartEnum.medical_decision_maker],
      },
      // Part 3
      {
        ...defaultPartSettings?.[AdPartEnum.sign_the_form],
        beforeYouSign: {
          requirements: [
            t('planDoc.sign_the_form.beforeYouSignRequirements_age'),
            t('planDoc.sign_the_form.beforeYouSignRequirements_witnesses'),
          ],
          finalClause: t('planDoc.sign_the_form.beforeYouSignFinalClause'),
        },
        witnessRequirements: {
          title: t('VA.sign_the_form.witnessRequirements_title'),
          requirements: [
            {
              title: t('planDoc.sign_the_form.witnessRequirements_bothWitnesses'),
              phrases: [
                t('planDoc.sign_the_form.witnessRequirements_be18'),
                t('planDoc.sign_the_form.witnessRequirements_seeYouSign', 'see you sign the form'),
                t('planDoc.sign_the_form.witnessRequirements_bePresent'),
              ],
            },
          ],
          validityRequirements: [
            t('planDoc.sign_the_form.validityRequirements_esignInThePresence'),
            t('planDoc.sign_the_form.validityRequirements_printAndSign'),
          ],
        },
      },
    ],
  }

  return va
}
