import React from 'react';
import {
  Title,
  Caption,
  TitleIcon,
  Content,
  StepText,
  StepContainer,
  StepIcon,
  TitleContainer,
} from '../index';
// LIB
import i18n from 'lib/helpers/i18n';

import personQuestionSVG from '../images/person-question.svg';
import shareSVG from '../images/share.svg';
import checklistSVG from '../images/checklist.svg';
import contractSVG from '../images/contract.svg';

export default function Step2() {
  return (
    <div>
      <TitleIcon
        src={personQuestionSVG}
        alt="First Onboarding Step"
        style={{ margin: 'auto', display: 'block' }}
      />
      <TitleContainer style={{ justifyContent: 'center' }}>
        <Title data-testid="step2-title">
          {i18n.t("medicalCarePlansFaq.createKodaAdvance")}
        </Title>
      </TitleContainer>
      <Caption>
        {i18n.t("medicalCarePlansFaq.easyStepsCreating")}
      </Caption>
      <Content style={{ paddingLeft: '10%' }}>
        <StepContainer>
          <StepIcon src={checklistSVG} alt="Step 1: Create your Care Plan" />
          <StepText>{i18n.t("medicalCarePlansFaq.createCarePlan")}</StepText>
        </StepContainer>
        <StepContainer>
          <StepIcon src={shareSVG} alt="Step 2: Share your Care Plan" />
          <StepText>{i18n.t("medicalCarePlansFaq.shareCarePlan")}</StepText>
        </StepContainer>
        <StepContainer>
          <StepIcon
            src={contractSVG}
            alt="Step 3: Make your Care Plan Official"
          />
          <StepText>{i18n.t("medicalCarePlansFaq.carePlanOfficial")}</StepText>
        </StepContainer>
      </Content>
    </div>
  );
}
