import moment from 'moment'
import { toNumber } from 'lodash'
import i18n from 'lib/helpers/i18n'
import en from 'lib/helpers/locales/en/translation.json'

const isBetween = (n, a, b) => {
  return (n - a) * (n - b) <= 0
}

export default function useCheckBirthdayErrors() {
  const checkBirthdayErrors = (birthday: string): string | null => {
    if (!birthday) return i18n.t('BirthdayError.provideDOB', en.BirthdayError.provideDOB)

    // check if they're 18
    let now = moment().valueOf()
    let birthdayPlus18 = moment(birthday, 'MM/DD/YYYY').add(18, 'years').valueOf()

    let birthdayPlus150 = moment(birthday, 'MM/DD/YYYY').add(150, 'years').valueOf()

    const month = moment(birthday, 'MM/DD/YYYY').format('MM')
    const day = moment(birthday, 'MM/DD/YYYY').format('DD')

    if (isNaN(birthdayPlus18)) return i18n.t('BirthdayError.invalidDate', en.BirthdayError.invalidDate)

    if (!isBetween(toNumber(day), 1, 31)) return i18n.t('BirthdayError.invalidDay', en.BirthdayError.invalidDay)

    if (!isBetween(toNumber(month), 1, 12)) return i18n.t('BirthdayError.invalidMonth', en.BirthdayError.invalidMonth)

    if (now < birthdayPlus18) return i18n.t('BirthdayError.mustBe18', en.BirthdayError.mustBe18)

    if (now > birthdayPlus150) return i18n.t('BirthdayError.validBirthday', en.BirthdayError.validBirthday)

    return null
  }

  return [checkBirthdayErrors] as const
}
