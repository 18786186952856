// TOP LEVEL IMPORTS
import React, { ChangeEvent } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
// LIB
import i18n from 'lib/helpers/i18n'

// ANTD
import Input from '../TextInput'

const StyledInput = styled(Input)`
  ::placeholder {
    color: ${(p) => p.theme.colors.neutral7};
  }
`

const HideButton = styled.button`
  border: 0px;
  background: transparent;
  position: absolute;
  right: 16px;
  top: 35px;
  color: ${(p) => p.theme.colors.supportB2};
  cursor: pointer;
  &:focus {
    outline: 0;
  }
`

interface PasswordInputProps {
  onChange: Function
  onToggleShowPassword?: () => void
  showPassword?: boolean
  placeholder?: string
  value?: string
  onBlur?: (e?: React.FocusEvent) => void
  id?: string
}

const PasswordInput = (props: PasswordInputProps) => {
  const { onChange, onToggleShowPassword, value, showPassword } = props
  return (
    <>
      <StyledInput
        {...props}
        value={value}
        onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e.target.value?.trim())}
      />
      {onToggleShowPassword && (
        <HideButton type="button" onClick={onToggleShowPassword}>
          {showPassword ? i18n.t('signupPage.hide') : i18n.t('signupPage.show')} {i18n.t('signupPage.password')}
        </HideButton>
      )}
    </>
  )
}

PasswordInput.propTypes = {
  size: PropTypes.string,
  error: PropTypes.bool,
}

// Specifies the default values for props:
PasswordInput.defaultProps = {
  size: 'large',
  error: null,
  type: 'password',
  onChange: () => {},
  style: {
    height: 50,
  },
  placeholder: 'Password',
}

export default PasswordInput
