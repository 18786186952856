import React from 'react'
import { Title, Caption, ContentImageContainer, TitleIcon, Content, TitleContainer } from '../index'
// LIB
import i18n from 'lib/helpers/i18n'

import shareSVG from '../images/share.svg'
import shareYourPlanSVG from '../images/share-your-plan.svg'
import Image from 'components/common/Image'

export default function Step4() {
  return (
    <div style={{ width: 650, maxWidth: '100%' }}>
      <TitleContainer>
        <TitleIcon src={shareSVG} alt="Step 2: Share your Care Plan" />
        <Title data-testid="step4-title">{i18n.t('medicalCarePlansFaq.shareCarePlan')}</Title>
      </TitleContainer>
      <Caption>{i18n.t('medicalCarePlansFaq.sharedWithYourself')}</Caption>
      <Content>
        <ContentImageContainer>
          <Image
            src={shareYourPlanSVG}
            alt="Create your plan"
            width="70%"
            height="70%"
            style={{ display: 'block', margin: 'autod' }}
          />
        </ContentImageContainer>
      </Content>
    </div>
  )
}
