import moment from 'moment'
import 'moment-duration-format'

export default function getTimeDuration({ startTime, endTime }) {
  const convertMinutes = (timeInMinutes, format = 'd[d] h[h] m[m] s[s]') => {
    if (timeInMinutes === 0) {
      return null
    }
    if (!timeInMinutes) {
      return null
    }
    return `${moment.duration(timeInMinutes, 'minutes').format(format)}`
  }

  return convertMinutes((endTime - startTime) / 60000)
}
