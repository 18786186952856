import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
// COMPONENTS
import FormItem from 'components/common/FormItem'
import ActionBar from 'components/common/ActionBar'
import TextInput from 'components/inputs/TextInput'
import TextAreaInput from 'components/inputs/TextAreaInput'
import Header from 'components/text/Header'
import Caption from 'components/text/Caption'
import Button from 'components/common/Button'
import messageNotif from 'components/common/message'
// LIB
import constants from 'lib/config'
import { AnalyticEventTypeEnums } from 'lib/types/AnalyticEventTypeEnums'
import i18n from 'lib/helpers/i18n'
// APOLLO
import { ActivityLogKeyEnum, Patient, useSubmitHelpFormMutation } from 'generated/graphql'
import useLogActivityItemMutation from 'lib/hooks/useLogActivityItemMutation'
import logEvent from 'lib/helpers/logEvent'
import translateApolloError from 'lib/helpers/translateApolloError'

const HelpForm = styled.div`
  background: #fff;
  border-radius: 5px;
  padding: 16px;
  /* width: 500px; */
  max-width: 100%;
  margin: auto;
  border: 1px solid ${(p) => p.theme.colors.neutral9};
`

const OtherText = styled.h3`
  margin-top: 16px;
  font-weight: 400;
  color: ${(p) => p.theme.colors.neutral5};
`

interface AppHelpProps {
  patient: Patient
}

export default function AppHelp({ patient }: AppHelpProps) {
  const [subject, setSubject] = useState('')
  const [message, setMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [messageError, setMessageError] = useState('')
  const [submitHelpForm] = useSubmitHelpFormMutation()
  const history = useHistory()
  const [logActivityItemMutation] = useLogActivityItemMutation()

  useEffect(() => {
    logEvent(AnalyticEventTypeEnums.VISITED_SUPPORT_PAGE)
  }, [])

  const onSubmit = async () => {
    try {
      if (!message) {
        return setMessageError(i18n.t('getHelpPage.provideMessageError', 'Please provide a message'))
      }
      setLoading(true)
      let res = await submitHelpForm({
        variables: {
          subject,
          message,
        },
      })

      await logActivityItemMutation(
        {
          variables: {
            key: ActivityLogKeyEnum.PatientSubmittedHelpForm,
            userId: patient?.userId,
            patientId: patient?.id,
            clinicId: patient?.clinicId,
            content: `User submitted the help form`,
          },
        },
        AnalyticEventTypeEnums.PATIENT_SUBMITTED_HELP_FORM
      )
      if (res?.data?.submitHelpForm?.success) {
        messageNotif.success(
          i18n.t('getHelpPage.messageReceived', 'We have received your message and will be in touch within 24 hours!')
        )
        setLoading(false)
        history.push(`/app`)
      }
    } catch (err: any) {
      setLoading(false)
      messageNotif.error(translateApolloError(err))
    }
  }
  return (
    <>
      <>
        <div
          style={{
            position: 'relative',
            width: 700,
            maxWidth: '100%',
            margin: 'auto',
            // marginTop: 16,
          }}
        >
          <ActionBar patient={patient} />
          {/* <BackButton /> */}
          <HelpForm>
            <Header>{i18n.t('getHelpPage.title')}</Header>
            <Caption>{i18n.t('getHelpPage.tellUs')}</Caption>
            <form onSubmit={onSubmit}>
              <FormItem label={i18n.t('getHelpPage.subject')} htmlFor="subject">
                <TextInput value={subject} onChange={(e) => setSubject(e.target.value)} id="subject" />
              </FormItem>
              <FormItem label={i18n.t('getHelpPage.message')} required error={messageError} htmlFor="message">
                <TextAreaInput rows="8" value={message} onChange={(message) => setMessage(message)} id="message" />
              </FormItem>
              <FormItem>
                <Button disabled={loading} onClick={onSubmit} loading={loading}>
                  {i18n.t('getHelpPage.submit')}
                </Button>
              </FormItem>
            </form>
          </HelpForm>
          <OtherText>
            {i18n.t('getHelpPage.canEmailUs')}
            <a style={{ textDecoration: 'underline' }} href={`mailto:${constants.supportEmail}`}>
              {constants.supportEmail}
            </a>
          </OtherText>
        </div>
      </>
    </>
  )
}
