import { Patient } from 'generated/graphql'

/** Returns true if the plan is for Wake Forest */

export default function isWakeForest(plan: Patient): boolean {
  const isWFTpath1 = plan?.guideTemplate?.name === 'WF_Path1'
  const isWFTpath2 = plan?.guideTemplate?.name === 'WF_Path2'
  const isWFpath2Part2 = plan?.guideTemplate?.name === 'WF_Path2_Part2'
  const isWF = isWFTpath1 || isWFTpath2 || isWFpath2Part2
  return isWF
}
