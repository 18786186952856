import { AdPartEnum, PlanSetting } from '../types'
import en from 'lib/helpers/locales/en/translation.json'
import { Text } from '@react-pdf/renderer'
import s from '../../../KodaContent/styles'
import { Trans } from 'react-i18next'
import { Patient, PatientFragmentFragment } from 'generated/graphql'
import { DeepPartial } from 'utility-types'

export default function getMN(
  t,
  defaultPartSettings,
  patient: Patient | PatientFragmentFragment | DeepPartial<Patient>
): PlanSetting {
  const patientFullName = `${patient?.firstName || ''} ${patient?.lastName || ''}`

  const mn: PlanSetting = {
    state: 'Minnesota',
    disclaimer: t(
      'MN.disclaimer',
      'Disclaimer: This document needs to be signed and have two witnesses sign as well or be notarized in order to be a legal document'
    ),
    parts: [
      // Part 1
      {
        ...defaultPartSettings?.[AdPartEnum.health_care_choices],
      },
      // Part 2
      {
        ...defaultPartSettings?.[AdPartEnum.medical_decision_maker],
        description: t(
          'MN.medical_decision_maker.description',
          `This part is also known as an Appointment of Health Agent. It designates a person to make decisions for you when you are unable to speak for yourself. Your medical decision maker cannot be a health care provider or employee of a health care provider unless related to you by blood, marriage, registered domestic partnership, adoption, or unless otherwise specified by you in the Advance Directive.
            `
        ),
        pageTitle: t(
          'MN.medical_decision_maker.pageTitle',
          'Information for Medical Decision Maker (Health Care Agent)'
        ),
      },
      // Part 3
      {
        ...defaultPartSettings?.[AdPartEnum.sign_the_form],
        description: t(
          'MN.sign_the_form.description',
          'You can fill out Part 1, Part 2, or both, but you need to sign the form in Part 3. You may authorize another person to sign the document on your behalf. To be legal, two witnesses also need to sign or a notary.'
        ),
        beforeYouSign: {
          requirements: [
            t(
              'planDoc.sign_the_form.beforeYouSignRequirements_age',
              en.planDoc.sign_the_form.beforeYouSignRequirements_age
            ),
            t(
              'planDoc.sign_the_form.beforeYouSignRequirements_notary',
              en.planDoc.sign_the_form.beforeYouSignRequirements_notary
            ),
            t(
              'planDoc.sign_the_form.beforeYouSignRequirements_witnesses',
              en.planDoc.sign_the_form.beforeYouSignRequirements_witnesses
            ),
          ],
          finalClause: t('MN.sign_the_form.beforeYouSignFinalClause', en.MN.sign_the_form.beforeYouSignFinalClause),
        },
        witnessSignatures: {
          preSignaturesContent: (
            <Text style={{ ...s.text, fontSize: 11 }}>
              <Trans i18nKey="MN.sign_the_form.witnessSignatures.preSignaturesContent">
                I certify that {{ patientFullName: patientFullName || '____________________ ' }} (the declarant)
                voluntarily signed this living will in my presence and that the declarant is personally known to me. I
                am not named as a medical decision maker by the living will, and to the best of my knowledge, I am not
                entitled to any part of the estate of the declarant under a will or by operation of law. I also promise
                that I meet the witness requirements listed above.
              </Trans>
            </Text>
          ),
        },
        witnessRequirements: {
          title: t('planDoc.sign_the_form.witnessRequirements_title', 'Witnesses or Notary Requirements'), // OR "Witness and Notary Requirements"
          requirements: [
            {
              title: t(
                'planDoc.sign_the_form.witnessRequirements_yourWitnessMust',
                en.planDoc.sign_the_form.witnessRequirements_yourWitnessMust
              ),
              width: '40%',
              phrases: [
                t('planDoc.sign_the_form.witnessRequirements_be18', en.planDoc.sign_the_form.witnessRequirements_be18),
                t(
                  'planDoc.sign_the_form.witnessRequirements_seeYouSign',
                  en.planDoc.sign_the_form.witnessRequirements_seeYouSign
                ),
              ],
            },
            {
              title: t(
                'planDoc.sign_the_form.witnessRequirements_yourWitnessCannot',
                en.planDoc.sign_the_form.witnessRequirements_yourWitnessCannot
              ),
              width: '60%',
              phrases: [
                t(
                  'planDoc.sign_the_form.witnessRequirements_beYourMdm',
                  en.planDoc.sign_the_form.witnessRequirements_beYourMdm
                ),
                t(
                  'planDoc.sign_the_form.witnessRequirements_entitledToAnyPartOfEstateOrWill',
                  en.planDoc.sign_the_form.witnessRequirements_entitledToAnyPartOfEstateOrWill
                ),

                //
              ],
            },
            {
              title: t(
                'planDoc.sign_the_form.witnessRequirements_oneWitnessCannot',
                en.planDoc.sign_the_form.witnessRequirements_oneWitnessCannot
              ),
              width: '100%',
              phrases: [
                t(
                  'planDoc.sign_the_form.witnessRequirements_beYourProvider',
                  en.planDoc.sign_the_form.witnessRequirements_beYourProvider
                ),
                t(
                  'planDoc.sign_the_form.witnessRequirements_beEmployeeOfProvider',
                  en.planDoc.sign_the_form.witnessRequirements_beEmployeeOfProvider
                ),
              ],
            },
          ],
          validityRequirements: [
            t(
              'planDoc.sign_the_form.validityRequirements_prinatAndSignBeforeTwoWitnesses',
              en.planDoc.sign_the_form.validityRequirements_prinatAndSignBeforeTwoWitnesses
            ),
            t(
              'planDoc.sign_the_form.validityRequirements_prinatAndSignBeforeNotary',
              en.planDoc.sign_the_form.validityRequirements_prinatAndSignBeforeNotary
            ),
          ],
        },
        notary: {},
      },
    ],
  }

  return mn
}
