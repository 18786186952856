import { Page, Text, StyleSheet, Image } from '@react-pdf/renderer'
// LIB
import theme from 'lib/theme'

const s = StyleSheet.create({
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 32,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: theme.colors.neutral5,
  },
  created: {
    position: 'absolute',
    fontSize: 12,
    bottom: 32,
    left: 0,
    right: 32,
    textAlign: 'right',
    color: theme.colors.neutral5,
  },
})

interface ExtrapageProps {
  extraPage?: string
  lastUpdated?: string
}

export default function Extrapage({ extraPage, lastUpdated }: ExtrapageProps) {
  return (
    <Page key={extraPage}>
      <Image src={extraPage} />
      <Text fixed style={s.pageNumber} render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`} />
      <Text style={s.created}>{lastUpdated}</Text>
    </Page>
  )
}
