// TOP LEVEL IMPORTS
import LoadingOutlined from 'components/icons/LoadingOutlined';
import theme from 'lib/theme';

interface LoadingProps {
  height?: number;
}

export default function Loading({height}: LoadingProps) {
  return (
    <div
      style={{
        height: height || 500,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <LoadingOutlined style={{fontSize: 25, color: theme.colors.primary5}} />
    </div>
  );
}
