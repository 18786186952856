import styled from 'styled-components'

// COMPONENTS

import { getQualityOfLifeText } from 'components/common/SurveyForm/QualityOfLife'

import { useWindowWidth } from '@react-hook/window-size'
import theme from 'lib/theme'

export const Card = styled.div`
  background-color: #fff;
  border-radius: ${({ theme }) => theme.borderRadius}px;
  margin-top: 16px;
  border: 1px solid ${({ theme }) => theme.colors.neutral9};
`

type QualityOfLifeAnswerProps = {
  answer: string | string[] | number
}

export default function QualityOfLifeAnswer({ answer }: QualityOfLifeAnswerProps) {
  const windowWidth = useWindowWidth()
  const isMobile = windowWidth < parseInt(theme.breakpoints.medium)

  return (
    <div
      style={{
        minHeight: 125,
        width: 800,
        maxWidth: '90%',
        position: 'relative',
        top: 16,
      }}
    >
      <div
        style={{
          position: 'relative',
          height: 50,
          marginTop: 24,
        }}
      >
        <div
          style={{
            width: '100%',
            borderRadius: 4,
            height: 3,
            background: theme.colors.primary9,
            right: 0,
            left: 0,
            top: -10,
            position: 'absolute',
          }}
        />
        <div
          style={{
            borderRadius: '50%',
            width: 16,
            height: 16,
            position: 'absolute',
            left: `${answer}%`,
            top: -17,
            background: theme.colors.primary5,
          }}
        />
        <div
          style={{
            position: 'absolute',
            left: !isMobile ? 0 : -8,
            lineHeight: '130%',
            maxWidth: '40%',
          }}
        >
          {getQualityOfLifeText('left', isMobile)}
        </div>
        <div style={{ position: 'absolute', left: '45%', lineHeight: '130%', maxWidth: '33%' }}>
          {!isMobile && getQualityOfLifeText('middle')}
        </div>
        <div
          style={{
            position: 'absolute',
            right: !isMobile ? 0 : -32,
            lineHeight: '130%',
            maxWidth: '40%',
          }}
        >
          {getQualityOfLifeText('right', isMobile)}
        </div>
      </div>
    </div>
  )
}
