import { AdPartEnum, PlanSetting } from '../types'
import en from 'lib/helpers/locales/en/translation.json'

export default function getNC(t, defaultPartSettings): PlanSetting {
  const nc: PlanSetting = {
    state: 'North Carolina',
    disclaimer: t('NC.disclaimer'),
    parts: [
      // Part 1
      {
        ...defaultPartSettings?.[AdPartEnum.health_care_choices],
      },
      // Part 2
      {
        ...defaultPartSettings?.[AdPartEnum.medical_decision_maker],
      },
      // Part 3
      {
        ...defaultPartSettings?.[AdPartEnum.sign_the_form],
        description: t('NC.sign_the_form.description'),
        beforeYouSign: {
          requirements: [
            t(
              'planDoc.sign_the_form.beforeYouSignRequirements_age',
              en.planDoc.sign_the_form.beforeYouSignRequirements_age
            ),
            t('planDoc.sign_the_form.beforeYouSignRequirements_twoWitnessesAndNotary'),
          ],
          finalClause: t(
            'planDoc.sign_the_form.beforeYouSignFinalClause',
            'This Advanced Directive accurately expresses my wishes.'
          ),
        },
        witnessRequirements: {
          title: t('NC.sign_the_form.witnessRequirements_title'),
          numberOfWitnessesRequired: 2,
          numberOfWitnessesThatMustMeetRequirements: 2,
          requirements: [
            {
              title: t('planDoc.sign_the_form.witnessRequirements_bothWitnesses'),
              phrases: [
                t('planDoc.sign_the_form.witnessRequirements_be18'),
                t('planDoc.sign_the_form.witnessRequirements_seeYouSign', 'see you sign the form'),
              ],
            },
            {
              title: t('planDoc.sign_the_form.witnessRequirements_twoWitnessesOfSoundMind'),
              phrases: [
                t('planDoc.sign_the_form.witnessRequirements_areNotRelatedThirdDegree'),
                t('planDoc.sign_the_form.witnessRequirements_areNotEntitledToEstate'),
                t('NC.sign_the_form.witnessRequirements_areNotPhysician'),
                t('planDoc.sign_the_form.witnessRequirements_doNotHaveClaimAgainstEstate'),
              ],
            },
          ],
          validityRequirements: [t('planDoc.sign_the_form.beforeYouSignRequirements_twoWitnessesAndNotary')],
        },
        notary: {
          disclaimer: t('NC.notary.disclaimer'),
        },
      },
    ],
  }

  return nc
}
