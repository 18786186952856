import React from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { UserProfile } from 'generated/graphql'
// COMPONENTS
import Header from './Header'
import AppNav from './AppNav'

const Container = styled.div`
  margin: auto;
  width: 1150px;
  max-width: 90%;
  padding-top: 16px;
  padding-bottom: 56px;
  min-height: calc(100vh);
`

// Background

const Background = styled.div`
  background: ${(p) => p.theme.colors.neutral10};
`

interface ClinicLayoutProps {
  currentUser: UserProfile
  children?: string | JSX.Element
}

export default function ClinicLayout({ currentUser, children }: ClinicLayoutProps) {
  const location = useLocation()
  return (
    <Background>
      <Header currentUser={currentUser} />
      <AppNav pathname={location.pathname} clinicName={currentUser?.clinic?.title} />
      <Container className="fs-exclude">{children}</Container>
    </Background>
  )
}
