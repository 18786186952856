// TOP LEVEL IMPORTS
import { useState } from 'react'
import styled from 'styled-components'
// COMPONENTS
import FormItem from 'components/common/FormItem'
import message from 'components/common/message'
import Button from 'components/common/Button'
import ErrorBlock from 'components/common/ErrorBlock'
import TextInput from 'components/inputs/TextInput'
import { SmileOutlined, CloseOutlined } from '@ant-design/icons'
import SectionBreak from 'components/common/SectionBreak'
import Row from 'components/common/Row'
import Col from 'components/common/Col'
import Header from 'components/text/PageHeader'
// LIB
import theme from 'lib/theme'
// APOLLO
import {
  CurrentUserDocument,
  GetOnePatientDocument,
  useCreateAccountFromGuestMutation,
  UserProfile,
} from 'generated/graphql'
import i18n from 'lib/helpers/i18n'
import translateApolloError from 'lib/helpers/translateApolloError'

const CreateAccountBtn = styled.button`
  border: 0px;
  background: transparent;
  font-weight: 600;
  font-size: 16px;
  margin-top: 0px;
  padding: 0px;
  color: ${(p) => p.theme.colors.primary4};
  cursor: pointer;
  &:hover {
    color: ${(p) => p.theme.colors.primary2};
  }
  &:focus {
    outline: 0;
  }
`

const Modal = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  padding: 32px;
  overflow-y: scroll;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    padding: 16px;
  }
`

const ModalContent = styled.div`
  width: 900px;
  max-width: 100%;
  margin: auto;
`

const PageHeader = styled(Header)`
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    margin: 0px;
  }
`

interface CreateAccountProps {
  defaultEmail?: string
  currentUser?: UserProfile
  defaultFirst?: string
  defaultLast?: string
}

export default function CreateAccount({ defaultEmail, currentUser, defaultFirst, defaultLast }: CreateAccountProps) {
  const [visible, setVisible] = useState(false)
  const [email, setEmail] = useState(defaultEmail || '')
  const [firstName, setFirstName] = useState(defaultFirst || '')
  const [lastName, setLastName] = useState(defaultLast || '')
  const [errors, setErrors] = useState<string[] | []>([])
  const [loading, setLoading] = useState(false)
  const [password, setPassword] = useState('')
  const [createAccountFromGuest] = useCreateAccountFromGuestMutation()

  const onCancel = () => {
    setErrors([])
    setVisible(false)
  }

  const onSubmit = async () => {
    try {
      setLoading(true)
      let res = await createAccountFromGuest({
        variables: {
          email,
          password,
          firstName,
          lastName,
        },
        refetchQueries: [
          {
            query: CurrentUserDocument,
          },
          {
            query: GetOnePatientDocument,
            variables: {
              userId: currentUser?.id,
            },
          },
        ],
      })

      if (!res?.data?.createAccountFromGuest?.success && res?.data?.createAccountFromGuest?.errors?.[0]) {
        setLoading(false)
        return setErrors([res?.data?.createAccountFromGuest?.errors?.[0]])
      }

      if (res?.data?.createAccountFromGuest?.success) {
        setVisible(false)
        setLoading(false)
        return message.success(i18n.t('CreateAccount.success', `Your account was successfully created!`))
      }
    } catch (err: any) {
      message.error(translateApolloError(err))
    }
  }

  return (
    <>
      <CreateAccountBtn
        onClick={() => {
          setVisible(true)
        }}
      >
        <SmileOutlined
          style={{
            marginRight: 6,
            color: theme.colors.primary5,
            position: 'relative',
            top: 1,
          }}
        />
        {i18n.t('CreateAccount.menuText', `Create an account`)}
      </CreateAccountBtn>
      {visible && (
        <Modal>
          <CloseOutlined role="button" onClick={onCancel} style={{ position: 'absolute', right: 24, top: 24 }} />
          <ModalContent>
            <PageHeader> {i18n.t('CreateAccount.title', `Create your Koda Account`)}</PageHeader>
            <SectionBreak header="General" body="Provide general information about this yourself">
              <Row gutter={20}>
                <Col xs={12}></Col>
                <Col xs={12} />
                <Col xs={12}>
                  <FormItem label="First Name" htmlFor="firstName">
                    <TextInput value={firstName} id="firstName" onChange={(e) => setFirstName(e.target.value)} />
                  </FormItem>
                </Col>
                <Col xs={12}>
                  <FormItem label="Last Name" htmlFor="lastName">
                    <TextInput
                      value={lastName}
                      id="lastName"
                      className="fs-exclude"
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </FormItem>
                </Col>
                <Col xs={24}>
                  <FormItem label="Email" htmlFor="email">
                    <TextInput
                      value={email}
                      id="email"
                      className="fs-exclude"
                      type="email"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </FormItem>
                </Col>
              </Row>
            </SectionBreak>
            <SectionBreak header="Security" body="Please choose a password">
              <Row gutter={20}>
                <Col xs={24}>
                  <FormItem label="Password" htmlFor="password">
                    <TextInput
                      type="password"
                      value={password}
                      id="password"
                      onChange={(e) => setPassword(e.target.value?.trim())}
                    />
                  </FormItem>
                </Col>{' '}
                {/* <Col xs={6} /> */}
              </Row>
            </SectionBreak>
            {errors && errors.length > 0 && errors.map((error) => <ErrorBlock error={error} key={error} />)}
            <div>
              <div
                style={{
                  width: 310,
                  marginLeft: 'auto',
                }}
              >
                <FormItem>
                  <Button grey style={{ width: 100, marginRight: 8 }} onClick={onCancel} disabled={loading}>
                    {i18n.t('button.cancel', `Cancel`)}
                  </Button>
                  <Button disabled={loading} loading={loading} style={{ width: 200 }} type="submit" onClick={onSubmit}>
                    {i18n.t('button.submitBtn', `Create Account`)}
                  </Button>
                </FormItem>
              </div>
            </div>
          </ModalContent>
        </Modal>
      )}
    </>
  )
}
