import capitalize from 'lib/helpers/capitalize'
import getAltDecisionMakerName from 'lib/helpers/getAltDecisionMakerName'
import i18n from 'lib/helpers/i18n'

const getDecisionMakerName = (data) => {
  if (data.decisionMakerName) {
    return data.decisionMakerName
  }
  if (data.decisionMakerFirstName && data.decisionMakerLastName) {
    return `${data.decisionMakerFirstName} ${data.decisionMakerLastName}`
  }
  if (data.decisionMakerFirstName && !data.decisionMakerLastName) {
    return `${data.decisionMakerFirstName}`
  }
  return null
}

const getThirdDecisionMakerName = (data) => {
  if (data.thirdDecisionMakerFirstName && data.thirdDecisionMakerLastName) {
    return `${data.thirdDecisionMakerFirstName} ${data.thirdDecisionMakerLastName}`
  }
  if (data.thirdDecisionMakerFirstName && !data.thirdDecisionMakerLastName) {
    return `${data.altDecisionMakerFirstName}`
  }
  return null
}

// @TODO: cleanup this messy function..
export const getImportantToMeItem = (data, i, item) => {
  const translatedItem = i18n.t(`importantToMe.${item}`, item)
  // if only one OR if second to last item in array
  if (data.importantToMe.length === 1 || data.importantToMe.length - 1 === i + 1) {
    if (item === 'other' && data.importantToMeOther) {
      return `${capitalize(data.importantToMeOther)}`
    }

    return `${capitalize(translatedItem)} `
  }
  // if last item in array, use and "&"
  if (data.importantToMe.length === i + 1) {
    // // if the item we're mapping over is == other and the user applied their own custom importantToMeOther, then show that value instead of "other"
    if (item === 'other' && data.importantToMeOther) {
      return `& ${capitalize(data.importantToMeOther)}`
    }
    return `& ${capitalize(translatedItem)}`
  }

  if (item === 'other' && data.importantToMeOther) {
    return `${capitalize(data.importantToMeOther)}`
  }
  // for all else in the list, use a ", " to separate
  return `${capitalize(translatedItem)}, `
}

const docHelpers = {
  getAltDecisionMakerName,
  getDecisionMakerName,
  getThirdDecisionMakerName,
  getImportantToMeItem,
}

export default docHelpers
