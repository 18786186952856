import { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import { validate } from 'email-validator';
import i18n from "lib/helpers/i18n";
import ButtonsContainer from '../ButtonsContainer';
import QuestionText from 'components/text/QuestionText';
import SurveyFormLabel from 'components/text/SurveyFormLabel';
import { ErrorText, QuestionsContainer, StepContainer } from '../index';
import Animated from 'components/common/Animated';
import Button from 'components/common/Button';
import { Patient } from 'generated/graphql';
import { useTranslation } from 'react-i18next';

const Input = styled.input<{ inputMode: any; ref?: any }>`
  border: 0px;
  height: 64px;
  font-family: ${(p) => p.theme.fontFamily};
  font-size: 30px;
  font-weight: 400;
  letter-spacing: 1px;
  border: 2px solid ${(p) => p.theme.colors.neutral9};
  border-radius: 5px;
  width: 100%;
  padding: 16px;
  ::placeholder {
    color: ${(p) => p.theme.colors.neutral8};
  }

  @media only screen and (max-width: 1024px) {
    ::placeholder {
      font-size: 22px;
    }
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    font-size: 24px;
    ::placeholder {
      font-size: 13px;
      color: ${(p) => p.theme.colors.neutral7};
    }
  }
  &:focus {
    outline: 0;
    border: 3px solid ${(p) => p.theme.colors.primary4};
  }

  @media only screen and (max-width: 1024px) {
    &:focus {
      border: 3px solid ${(p) => p.theme.colors.primary4};
    }
  }
`;

const EmailEndingBtn = styled.button`
  margin-right: 16px;
  margin-bottom: 8px;
  color: ${(p) => p.theme.colors.primary1};
  background: ${(p) => p.theme.colors.primary10};
  border-radius: 5px;
  border: 0px;
  padding: 4px 8px;
`;

const EmailEnding = ({ value, onClick }) => {
  return (
    <EmailEndingBtn onClick={() => onClick(value)}>{value}</EmailEndingBtn>
  );
};

const EmailEndingRow = styled.div`
  margin-top: 16px;
  display: flex;
  width: 100%;
  /* overflow-x: scroll; */
  flex-wrap: wrap;
  padding-top: 4px;
  padding-bottom: 8px;
`;

interface TwoStringInputsProps {
  text?: JSX.Element | string;
  required?: boolean;
  defaultValues?: any[];
  onNext: (plan?: Patient | React.MouseEvent<HTMLButtonElement>) => void;
  onBack?: () => void;
  nextButton?: string;
  fieldName?: string;
  onChange: (e: { [x: string]: string }) => void;
  placeholder?: string;
  hideButtons?: boolean;
  editMode?: boolean;
  isMobile?: boolean;
  inputs?: any[];
  showSkipButton?: boolean;
}

export default function TwoStringInputs({
  text,
  required,
  defaultValues = [],
  onNext,
  onBack,
  nextButton,
  fieldName,
  onChange,
  placeholder = i18n.t('typeHere'),
  hideButtons,
  editMode,
  isMobile,
  inputs,
  showSkipButton,
}: TwoStringInputsProps) {
  const textInput1 = useRef<HTMLInputElement | undefined>(null);
  const [errors, setErrors] = useState<string[] | any[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    textInput1?.current?.focus();
  }, []);

  const hideNext = required && (!defaultValues[0] || !defaultValues[1]);

  const handleOnNext = () => {
    // just in case, don't let them submit if the field is required and not filled in
    if (required && !defaultValues[0]) {
      return setErrors(['Must be a valid email']);
    }
    if (required && !defaultValues[1]) {
      return setErrors([null, 'Must be a valid email']);
    }

    // if the type of input is email, then we need to validate that it's an email
    if (
      inputs?.[0]?.inputType &&
      inputs[0].inputType === 'email' &&
      defaultValues[0] &&
      defaultValues.length > 0
    ) {
      if (!validate(defaultValues[0])) {
        return setErrors(['Must be a valid email']);
      }
    }

    if (
      inputs?.[1]?.inputType &&
      inputs[1].inputType === 'email' &&
      defaultValues[1] &&
      defaultValues.length > 0
    ) {
      if (!validate(defaultValues[1])) {
        return setErrors([null, 'Must be a valid email']);
      }
    }

    // if all is good, we can call onNext to move to the next question
    onNext();
  };

  return (
    <StepContainer key={fieldName} editMode={editMode} isMobile={isMobile}>
      <div style={{ maxWidth: '100%', position: 'relative' }}>
        <Animated>
          {!hideNext && !isMobile && (
            <div
              style={{
                position: 'absolute',
                right: 0,
                top: -70,
              }}
            >
              <Button
                onClick={handleOnNext}
                pulse
                style={{
                  minWidth: 112,
                }}
              >
                {t('button.continue')}
              </Button>
            </div>
          )}
          <QuestionText data-testid="text-input-overall-question-text">
            {text}
          </QuestionText>
          <QuestionsContainer
            style={{ width: !isMobile ? 620 : 400, maxWidth: '100%' }}
          >
            {inputs?.map((input, i) => {
              return (
                <div
                  style={{ marginTop: i === 1 ? 48 : 0 }}
                  key={input.fieldName}
                >
                  <SurveyFormLabel
                    data-testid={`text-input-${input.fieldName}-question-text`}
                  >
                    {input.text}
                  </SurveyFormLabel>
                  <Input
                    data-testid={`text-input-${input.fieldName}-input-text`}
                    placeholder={input.placeholder || placeholder}
                    {...(i === 0 && { ref: textInput1 })} // add the ref only to the first input // https://stackoverflow.com/a/46010968/5627036
                    value={defaultValues[i]}
                    className="fs-exclude"
                    inputMode={input.inputMode}
                    onChange={(e) => {
                      setErrors([]);
                      // for all other inputs, just save the values
                      onChange({ [input.fieldName]: e.target.value });
                    }}
                  />
                  {errors[i] && (
                    <ErrorText data-testid="two-strings-errorText">
                      {errors[i]}
                    </ErrorText>
                  )}
                  {input.inputMode === 'email' && (
                    <EmailEndingRow>
                      <EmailEnding
                        value="@"
                        onClick={(ending) =>
                          onChange({
                            [input.fieldName]: `${defaultValues[i]}${ending}`,
                          })
                        }
                      />
                      <EmailEnding
                        value="@gmail.com"
                        onClick={(ending) =>
                          onChange({
                            [input.fieldName]: `${defaultValues[i]}${ending}`,
                          })
                        }
                      />
                      <EmailEnding
                        value="@outlook.com"
                        onClick={(ending) =>
                          onChange({
                            [input.fieldName]: `${defaultValues[i]}${ending}`,
                          })
                        }
                      />
                      <EmailEnding
                        value="@yahoo.com"
                        onClick={(ending) =>
                          onChange({
                            [input.fieldName]: `${defaultValues[i]}${ending}`,
                          })
                        }
                      />
                      <EmailEnding
                        value="@hotmail.com"
                        onClick={(ending) =>
                          onChange({
                            [input.fieldName]: `${defaultValues[i]}${ending}`,
                          })
                        }
                      />
                      <EmailEnding
                        value="@sbcglobal.net"
                        onClick={(ending) =>
                          onChange({
                            [input.fieldName]: `${defaultValues[i]}${ending}`,
                          })
                        }
                      />
                    </EmailEndingRow>
                  )}
                </div>
              );
            })}
          </QuestionsContainer>
        </Animated>
        <ButtonsContainer
          onNext={handleOnNext}
          onBack={onBack}
          onSkip={onNext}
          hideButtons={hideButtons}
          nextButton={nextButton}
          hideNext={hideNext}
          showSkipButton={showSkipButton}
        />
      </div>
    </StepContainer>
  );
}
