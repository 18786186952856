// COMPONENTS
import ButtonsContainer from '../ButtonsContainer';
import VideoPlayer from 'components/common/VideoPlayer';
import {StepContainer} from '../index';
import Animated from 'components/common/Animated';
import QuestionText from 'components/text/QuestionText';
import { Patient } from 'generated/graphql';

interface VideoProps {
  onNext: (plan?: Patient) => void;
  progress: string;
  stepName?: string;
  required?: boolean;
  text?: JSX.Element | string;
  onBack?: () => void;
  buttonAlign?: string;
  nextButton?: string;
  videoId?: string;
}

export default function Video({
  onNext,
  progress,
  stepName,
  required,
  text,
  onBack,
  buttonAlign,
  nextButton,
  videoId = 'LgRE0urrB7c',
}: VideoProps) {
  return (
    <StepContainer>
      <div style={{maxWidth: '93%', margin: 'auto'}}>
        <Animated>
          {text && <QuestionText data-testid="video-text">{text}</QuestionText>}
          <VideoPlayer
            videoId={videoId}
            onNext={onNext}
            required={required}
            showButtons={!required}
            stepName={stepName}
            skipOnEnd
          />
        </Animated>
        <ButtonsContainer
          onNext={onNext}
          onBack={onBack}
          hideNext
          progress={progress}
          buttonAlign={buttonAlign}
          nextButton={nextButton}
        />
      </div>
    </StepContainer>
  );
}
