import { useGetOneClinicQuery, Clinic } from 'generated/graphql'

interface UseCheckSlugExistsHookRes {
  slugCheckLoading?: boolean
  slugExists?: boolean
  clinic?: Clinic
}

export default function useCheckSlugExists({ slug }): UseCheckSlugExistsHookRes {
  const { loading, data } = useGetOneClinicQuery({
    variables: {
      slug,
    },
    skip: !slug,
  })

  const clinic = data?.getOneClinic || undefined

  return {
    slugCheckLoading: loading,
    slugExists: clinic?.id ? true : false,
    clinic,
  }
}
