import { useState, useEffect, FC } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

import {
  ActivityLogKeyEnum,
  MedicalAttorneyLivingWillAdvanceDirectiveEnum,
  Patient,
  StatementMostAdvanceCarePlanningEnum,
  User,
} from "generated/graphql";

import i18n from "lib/helpers/i18n";

import Button from "components/common/Button";
import SectionFooter from "../SectionFooter";
import SectionHeader from "../SectionHeader";
import SelectBlock from "../step1/SelectBlock";
import useSavePatient from "lib/hooks/useSavePatient";
import usePrevious from "lib/hooks/usePrevious";
import { AfterSaveAction, Step1Continue } from "../step1";
import Loading from "components/common/Loading";
import useLogActivityItemMutation from "lib/hooks/useLogActivityItemMutation";
import PlanConfiguratorHelper from "routes/app-home/PlanConfigurator/PlanConfiguratorHelper";

const Step3Styled = styled.div`
  background-color: white;
  flex: 1;
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints.medium}) {
    padding-bottom: 100px;
  }
`;

const Step3Body = styled.div`
  margin: auto;
  max-width: 600px;
  padding: 48px 10px;
`;

const Step3Title = styled.h1`
  font-weight: 500;
  font-size: 18px;
  color: #323f4b;
`;

const COMFORT_LEVEL_BECOME_ILL_TEXT_HASH = {
  "1": `1 - ${i18n.t(
    "survey.iRatherNotThinkAboutItAtAll",
    "I’d rather not think about it at all."
  )}`,
  "2": `2 - ${i18n.t(
    "survey.imOpenToExploringThisMore",
    "I’m open to exploring this more."
  )}`,
  "3": `3 - ${i18n.t(
    "survey.imOpenToExploringThisMoreAnd",
    "I’m open to exploring this more and making plans to staying in control of my future care."
  )}`,
  "4": `4 - ${i18n.t(
    "survey.iveAlreadyThoughtAboutThis",
    "I’ve already thought about this and made my plans."
  )}`,
};

const WORRIED_FUTURE_ILLNESSES_TEXT_HASH = {
  "1": `1 - ${i18n.t("survey.notWorriedAtAll", "Not worried at all")}`,
  "2": `2`,
  "3": `3 - ${i18n.t("survey.neutral", "Neutral")}`,
  "4": `4`,
  "5": `5 - ${i18n.t("survey.veryWorried", "Very worried")}`,
};

const DECISION_MAKER_KIND_OF_MEDICAL_YOU_WOULD_WANT_TEXT_HASH = {
  "1": `1 - ${i18n.t(
    "survey.iHaveNeverThoughtAboutIt",
    "I have never thought about it."
  )}`,
  "2": `2 - ${i18n.t(
    "survey.iHaveThoughtAboutIt",
    "I have thought about it, but I am not ready to do it."
  )}`,
  "3": `3 - ${i18n.t(
    "survey.iamThinkingAboutDoingIt",
    "I am thinking about doing it in the next 6 months."
  )}`,
  "4": `4 - ${i18n.t(
    "survey.iamDefinitelyPlanningToDo",
    "I am definitely planning to do it in the next 30 days."
  )}`,
  "5": `5 - ${i18n.t("survey.iHaveAlreadyDoneIt", "I have already done it.")}`,
};

const MEDICAL_ATTORNEY_LIVING_WILL_ADVANCE_DIRECTIVE_TEXT_HASH = {
  [MedicalAttorneyLivingWillAdvanceDirectiveEnum.Mpoa]: "Yes, only MPOA.",
  [MedicalAttorneyLivingWillAdvanceDirectiveEnum.LivingWill]:
    "Yes, only living will.",
  [MedicalAttorneyLivingWillAdvanceDirectiveEnum.AdvanceDirective]:
    "Yes, only advance directive.",
  [MedicalAttorneyLivingWillAdvanceDirectiveEnum.FilledOutDocuments]:
    "Yes, I’ve filled out more than 2 of these documents.",
  [MedicalAttorneyLivingWillAdvanceDirectiveEnum.HaventDone]:
    "No, I haven’t done this but have been meaning to.",
  [MedicalAttorneyLivingWillAdvanceDirectiveEnum.DontKnow]:
    "No, I don’t know what these are.",
};

const STATEMENT_MOST_ADVANCE_CARE_PLANNING_TEXT_HASH = {
  [StatementMostAdvanceCarePlanningEnum.StayInControl]: `I want to do this so I can stay in control. We can't plan for everything but going through this allows me to be as prepared as possible.`,
  [StatementMostAdvanceCarePlanningEnum.LiveEasier1]: `I want to do this because I know it'll make the lives of
  those closest to me easier. It's a gift I can give to 
  those who matter most.`,
  [StatementMostAdvanceCarePlanningEnum.MettersToMe]: `I want to do this because I demand to have the care
  that matters to me. Going through this allows me to 
  get the care I deserve.`,
  [StatementMostAdvanceCarePlanningEnum.LiveEasier2]: `I want to do this so I can have peace of mind. Once I complete this, there's no need to wonder what will happen.`,
  [StatementMostAdvanceCarePlanningEnum.SeriousIllness]: `I want to do this because I've had a previous 
  experience with serious illness. I know this will help 
  me if I'm ever in that situation.`,
  [StatementMostAdvanceCarePlanningEnum.Caregiver]: `I want to do this because if I were a caregiver, I'd want
  to know what matters to my loved ones so I can 
  advocate for them.`,
  [StatementMostAdvanceCarePlanningEnum.None]: `None of these statements resonate with me because
  of another reason.`,
};

interface Step1Props {
  patient?: Patient | null;
  currentUser?: User;
}

const Step3: FC<Step1Props> = ({ patient, currentUser }) => {
  const [logActivityItem] = useLogActivityItemMutation();
  const { t } = useTranslation();

  const [afterSaveAction, setAfterSaveAction] = useState<AfterSaveAction>();
  const [comfortLevelBecomeIll, setComfortLevelBecomeIll] = useState<
    number | null
  >(null);
  const [worriedFutureIllnesses, setWorriedFutureIllnesses] = useState<
    number | null
  >(null);
  const [
    decisionMakerKindOfMedicalYouWouldWant,
    setDecisionMakerKindOfMedicalYouWouldWant,
  ] = useState<number | null>(null);
  const [
    healthcareProviderKindOfMedicalYouWouldWant,
    setHealthcareProviderKindOfMedicalYouWouldWant,
  ] = useState<number | null>(null);
  const [
    medicalAttorneyLivingWillAdvanceDirective,
    setMedicalAttorneyLivingWillAdvanceDirective,
  ] = useState<MedicalAttorneyLivingWillAdvanceDirectiveEnum>();
  const [
    statementMostAdvanceCarePlanning,
    setStatementMostAdvanceCarePlanning,
  ] = useState<StatementMostAdvanceCarePlanningEnum>();
  const [
    statementMostAdvanceCarePlanningNoneText,
    setStatementMostAdvanceCarePlanningNoneText,
  ] = useState<string>("");

  const { onSavePatient, saving } = useSavePatient();

  const history = useHistory();

  const savingPrev = usePrevious(saving);

  const save = async () => {
    if (patient) {
      const newPatient = {
        ...patient,
        comfortLevelBecomeIll,
        worriedFutureIllnesses,
        decisionMakerKindOfMedicalYouWouldWant,
        healthcareProviderKindOfMedicalYouWouldWant,
        medicalAttorneyLivingWillAdvanceDirective,
        statementMostAdvanceCarePlanning,
        statementMostAdvanceCarePlanningNoneText,
      };
      const planHelpers = new PlanConfiguratorHelper();
      if (planHelpers.getIsPreAcpSurveyCompleted(newPatient)) {
        await logActivityItem({
          variables: {
            key: ActivityLogKeyEnum.CompletedPreassesmentSurvey,
            userId: currentUser?.id || "",
            patientId: patient?.id || "",
            content: `Patient completed pre-assessment survey`,
          },
        });
      }
      await onSavePatient(newPatient);
    }
  };

  const goNext = () => {
    setAfterSaveAction(AfterSaveAction.NEXT);
    save();
  };

  useEffect(() => {
    // set patient values every patient change
    if (patient) {
      setComfortLevelBecomeIll(
        +(patient.comfortLevelBecomeIll as number) || null
      );
      setWorriedFutureIllnesses(
        +(patient.worriedFutureIllnesses as number) || null
      );
      setDecisionMakerKindOfMedicalYouWouldWant(
        +(patient.decisionMakerKindOfMedicalYouWouldWant as number) || null
      );
      setHealthcareProviderKindOfMedicalYouWouldWant(
        +(patient.healthcareProviderKindOfMedicalYouWouldWant as number) || null
      );
      setMedicalAttorneyLivingWillAdvanceDirective(
        patient.medicalAttorneyLivingWillAdvanceDirective as MedicalAttorneyLivingWillAdvanceDirectiveEnum
      );
      setStatementMostAdvanceCarePlanning(
        patient.statementMostAdvanceCarePlanning as StatementMostAdvanceCarePlanningEnum
      );
      setStatementMostAdvanceCarePlanningNoneText(
        (patient.statementMostAdvanceCarePlanningNoneText as string) || ""
      );
    }
  }, [patient]);

  useEffect(() => {
    if (
      statementMostAdvanceCarePlanning &&
      statementMostAdvanceCarePlanning !==
        StatementMostAdvanceCarePlanningEnum.None
    ) {
      setStatementMostAdvanceCarePlanningNoneText("");
    }
  }, [statementMostAdvanceCarePlanning]);

  useEffect(() => {
    // Go to step 2 on saving end and if patient is truthy
    if (savingPrev && !saving && patient && afterSaveAction) {
      switch (afterSaveAction) {
        case AfterSaveAction.HOME: {
          history.push("/app/home");
          break;
        }
        case AfterSaveAction.NEXT: {
          history.push("/app/home");
          break;
        }
        case AfterSaveAction.BACK: {
          history.push("/app/survey?step=2");
          break;
        }
        default: {
          return;
        }
      }
    }
  }, [history, savingPrev, saving, patient, afterSaveAction]);

  const disabled =
    !comfortLevelBecomeIll ||
    !worriedFutureIllnesses ||
    !decisionMakerKindOfMedicalYouWouldWant ||
    !healthcareProviderKindOfMedicalYouWouldWant ||
    !medicalAttorneyLivingWillAdvanceDirective ||
    !statementMostAdvanceCarePlanning;

  return (
    <>
      <Step3Styled>
        <SectionHeader
          sectionName={t("planConfigurator.PRE_SURVEY")}
          progress={"66"}
          onClick={() => {
            setAfterSaveAction(AfterSaveAction.HOME);
            save();
          }}
        />
        {saving && <Loading height={80} />}
        <Step3Body>
          <Step3Title>
            Advance Care Planning Readiness and Previous Experiences Assessment
          </Step3Title>
          <SelectBlock
            title="10. On a scale of 1-4, what describes you best when it comes 
            to your comfort level in thinking about your care if you become
            seriously ill?"
            subtitle="Please mark the box that best describes you"
            items={["1", "2", "3", "4"]}
            hash={COMFORT_LEVEL_BECOME_ILL_TEXT_HASH}
            active={comfortLevelBecomeIll?.toString()}
            onSelect={(item: string) => setComfortLevelBecomeIll(+item)}
            noCapitalize
          />
          <SelectBlock
            title="11. On a scale of 1-5, how worried are you when you think about
            your future illnesses?"
            subtitle="Please mark the box that best describes you"
            items={["1", "2", "3", "4", "5"]}
            hash={WORRIED_FUTURE_ILLNESSES_TEXT_HASH}
            active={worriedFutureIllnesses?.toString()}
            onSelect={(item: string) => setWorriedFutureIllnesses(+item)}
            noCapitalize
          />
          <SelectBlock
            title="12. On a scale of 1-5, how ready are you to talk to your decision
            maker about the kind of medical care you would want if you 
            were very sick or near the end of life?"
            subtitle="Please mark the box that best describes you"
            items={["1", "2", "3", "4", "5"]}
            hash={DECISION_MAKER_KIND_OF_MEDICAL_YOU_WOULD_WANT_TEXT_HASH}
            active={decisionMakerKindOfMedicalYouWouldWant?.toString()}
            onSelect={(item: string) =>
              setDecisionMakerKindOfMedicalYouWouldWant(+item)
            }
            noCapitalize
          />
          <SelectBlock
            title="13. On a scale of 1-5, how ready are you to talk to your healthcare
            provider about the kind of medical care you would want if you were
            very sick or near the end of life?"
            subtitle="Please mark the box that best describes you"
            items={["1", "2", "3", "4", "5"]}
            hash={DECISION_MAKER_KIND_OF_MEDICAL_YOU_WOULD_WANT_TEXT_HASH}
            active={healthcareProviderKindOfMedicalYouWouldWant?.toString()}
            onSelect={(item: string) =>
              setHealthcareProviderKindOfMedicalYouWouldWant(+item)
            }
            noCapitalize
          />
          <SelectBlock
            title="14. Have you filled out a medical power of attorney, living will, 
            or advance directive before?"
            subtitle="Please mark the box that best describes you"
            items={Object.keys(
              MEDICAL_ATTORNEY_LIVING_WILL_ADVANCE_DIRECTIVE_TEXT_HASH
            )}
            hash={MEDICAL_ATTORNEY_LIVING_WILL_ADVANCE_DIRECTIVE_TEXT_HASH}
            active={medicalAttorneyLivingWillAdvanceDirective as string}
            onSelect={(item: string) =>
              setMedicalAttorneyLivingWillAdvanceDirective(
                item as MedicalAttorneyLivingWillAdvanceDirectiveEnum
              )
            }
            noCapitalize
          />
          <SelectBlock
            title="15. Which statement do you identify with the most when thinking about advance care planning?"
            subtitle="Please mark the box that best describes you"
            description={`Advance care planning is a process that supports adults at any
            age or stage of health in understanding and sharing their personal values,
            life goals, and preferences regarding future medical care. The goal of
            advance care planning is to help ensure that people receive medical care
            that is consistent with their values, goals and preferences during
            serious and chronic illness.`}
            items={Object.keys(STATEMENT_MOST_ADVANCE_CARE_PLANNING_TEXT_HASH)}
            hash={STATEMENT_MOST_ADVANCE_CARE_PLANNING_TEXT_HASH}
            active={statementMostAdvanceCarePlanning}
            onSelect={(item: string) =>
              setStatementMostAdvanceCarePlanning(
                item as StatementMostAdvanceCarePlanningEnum
              )
            }
            otherField={StatementMostAdvanceCarePlanningEnum.None as string}
            otherValue={statementMostAdvanceCarePlanningNoneText}
            onChange={(value) =>
              setStatementMostAdvanceCarePlanningNoneText(
                value[StatementMostAdvanceCarePlanningEnum.None]
              )
            }
            noCapitalize
          />
          {!disabled && (
            <Step1Continue>
              <Button loading={saving} disabled={saving} onClick={goNext} pulse>
                Continue
              </Button>
            </Step1Continue>
          )}
        </Step3Body>
      </Step3Styled>
      <SectionFooter
        goBack={() => {
          setAfterSaveAction(AfterSaveAction.BACK);
          save();
        }}
        goNext={goNext}
        loading={saving}
        disabled={disabled}
      />
    </>
  );
};

export default Step3;
