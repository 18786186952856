import { LocalStorageKeyEnum } from 'lib/types/LocalStorageKeyEnum'
import storage from 'local-storage-fallback'
import iOS from './iOS'

export default function setLocalStorage(valueName: LocalStorageKeyEnum | string, valueToSet) {
  try {
    if (!iOS()) {
      return window.localStorage.setItem(valueName, valueToSet)
    } else {
      return storage.setItem(valueName, valueToSet)
    }
  } catch (err) {
    throw new Error((err as Error).message)
  }
}
