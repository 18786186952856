import { AdPartEnum, PlanSetting } from '../types'
import en from 'lib/helpers/locales/en/translation.json'
import s from '../../../KodaContent/styles'
import { Trans } from 'react-i18next'
import { Patient, PatientFragmentFragment } from 'generated/graphql'
import { DeepPartial } from 'utility-types'
import { Text, View } from '@react-pdf/renderer'


export default function getDE(t, defaultPartSettings,
  patient: Patient | PatientFragmentFragment | DeepPartial<Patient>
): PlanSetting {
  const patientFullName = `${patient?.firstName || ''} ${patient?.lastName || ''}`

  const de: PlanSetting = {
    state: 'Delaware',
    disclaimer: t(
      'planDoc.defaultDisclaimer',
      'Disclaimer: This document needs to be signed and have two witnesses sign as well in order to be a legal document.'
    ),
    parts: [
      // Part 1
      {
        ...defaultPartSettings?.[AdPartEnum.health_care_choices],
      },
      // Part 2
      {
        ...defaultPartSettings?.[AdPartEnum.medical_decision_maker],
        name: t('planDoc.medical_decision_maker_choose_your_mdm.title', 'Part 2: Choose your medical decision maker'),
        description: t('DE.medical_decision_maker.description', 'This part is also known as a Health Care Power of Attorney. It designates a person to make decisions for you when you are unable to speak for yourself.'),
        pageTitle: t(
          'DE.medical_decision_maker.pageTitle',
          'Information for Medical Decision Maker (Health Care Power of Attorney)'
        ),
      },
      // Part 3
      {
        ...defaultPartSettings?.[AdPartEnum.sign_the_form],
        beforeYouSign: {
          requirements: [
            t(
              'planDoc.sign_the_form.beforeYouSignRequirements_age',
              "sign this form if you are 18 years of age or older; and"
            ),
            t(
              'planDoc.sign_the_form.beforeYouSignRequirements_witnesses',
              "sign in the presence of two competent adult witnesses."
            ),
          ],
          finalClause: t(
            'DE.sign_the_form.beforeYouSignFinalClause',
            'By signing, I confirm that I am emotionally and mentally capable of making this document. I am thinking clearly, I have made this document willingly, and this Advance Directive accurately expresses my wishes.'
          ),
        },
        witnessSignatures: {
          preSignaturesContent: (
            <Text style={{ ...s.text, fontSize: 11 }}>
              <Trans i18nKey="DE.sign_the_form.witnessSignatures.preSignaturesContent">
                By signing, I confirm that I watched {{ patientFullName: patientFullName || '____________________ ' }}{' '}
                sign the document. I confirm that I know{' '}
                {{ patientFullName: patientFullName || '____________________ ' }}.{' '}
                {{ patientFullName: patientFullName || '____________________ ' }} appears to be emotionally competent,
                of sound mind, and under no duress, fraud, or undue influence in signing the document. I promise that I
                meet the witness requirements listed in the document.
              </Trans>
            </Text>
          ),
        },
        witnessRequirements: {
          title: t('DE.sign_the_form.witnessRequirements_title',
            'Witnesses Requirements'), // OR "Witness and Notary Requirements"
          requirements: [
            {

              title: t('planDoc.sign_the_form.witnessRequirements_bothWitnesses',
                    'Both witnesses must:'
                  ),
              width: '40%',
              phrases: [
                t('planDoc.sign_the_form.witnessRequirements_be18',
                  'be 18 years of age or older'),
                t(
                  'planDoc.sign_the_form.witnessRequirements_seeYouSign',
                  'see you sign the form'
                ),
                t(
                  'planDoc.sign_the_form.witnessRequirements_bePresent',
                  'be physically present with you when you sign'
                ),
              ],
            },
            {
              title: t('planDoc.sign_the_form.witnessRequirements_yourWitnessCannot',
                    'Witnesses cannot:'
                  ),
              width: '60%',
              phrases: [
                t(
                  'planDoc.sign_the_form.witnessRequirements_beRelatedBloodMarriageAdoption',
                  'be related to you by blood, marriage, or adoption'
                ),
                t(
                  'planDoc.sign_the_form.witnessRequirements_beAPersonEntitledToEstate',
                  'be a person entitled to any portion of your estate upon your death'
                ),
                t(
                  'planDoc.sign_the_form.witnessRequirements_bePersonWithClaimAgainstEstate',
                  'be a person with a claim against your estate'
                ),
                t(
                  'DE.sign_the_form.witnessRequirements_bePersonHasDirectFinancialResponsibilityForCare',
                  'be a person who has direct financial responsibility for your medical care'
                ),
                t('DE.sign_the_form.witnessRequirements_bePersonWithControllingInterestEmployedOrOperatePersonalHealthCareFacility',
                  'be a person with controlling interest in, be employed by, or operate the health care facility where you are a patient or resident')
              ],
            },
          ],
          validityRequirements: [
            t(
              'planDoc.sign_the_form.validityRequirements_esignInThePresence',
              'eSign in the presence of two competent adult witnesses; or'
            ),
            t(
              'planDoc.sign_the_form.validityRequirements_prinatAndSignBeforeTwoWitnesses',
              'print and sign the document in the presence of two competent adult witnesses'
            )
          ],
        },
      },
      // Part 4
      {
        ...defaultPartSettings?.[AdPartEnum.faqs_and_legal],
        sections: [
          <View style={s.sectionContainer} key="Pregnancy Legal Disclaimer">
            <Text style={s.sectionTitle}>
              {t('planDoc.faqs_and_legal.pregnancyTitle', 'Pregnancy Legal Disclaimer')}
            </Text>
            <Text style={{ ...s.text }}>
              {t('DE.faqs_and_legal.pregnancyClause', 'Life-sustaining procedures may not be withheld or withdrawn from a patient known to be pregnant, so long as it is probable that the fetus will develop to be viable outside the uterus with the continued application of a life-sustaining procedure.')}
            </Text>
          </View>,
        ],
      },
    ],
  }

  return de
}
