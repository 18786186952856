import { FC } from 'react'
import Confetti from 'react-confetti'
import { useWindowSize } from '@react-hook/window-size'
import styled from 'styled-components'

import { ModalOverlay } from 'components/common/KodaModal'
import CloseOutlined from 'components/icons/CloseOutlined'

import { Patient } from 'generated/graphql'
import PostSurveyCongratsWfpath2 from './PostSurveyCongratsWfpath2'
import PostSurveyCongratsWfpath1No from './PostSurveyCongratsWfpath1No'
import PostSurveyCongratsWfpath1Yes from './PostSurveyCongratsWfpath1Yes'

const ModalContent = styled.div`
  background: #fff;
  padding: 24px 40px;
  border-radius: 5px;
  width: 650px;
  max-width: 100%;
  position: relative;
  color: ${(p) => p.theme.colors.neutral2};
  max-height: 96vh;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    padding-bottom: 90px;
    max-height: 78vh;
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.mediumSmall}) {
    width: 90vw;
    padding: 20px 20px 90px 20px;
  }
`

const CloseButton = styled.button`
  position: absolute;
  top: 16px;
  right: 16px;
  background: transparent;
  border: 1px solid #e4e7eb;
  box-sizing: border-box;
  border-radius: 4px;
`

interface PostSurveyCongratsProps {
  closeClick: () => void
  patient: Patient
}

const PostSurveyCongrats: FC<PostSurveyCongratsProps> = ({ closeClick, patient }) => {
  const [width, height] = useWindowSize()
  console.log(patient.postAcpDigitalAdvanceCarePlanning === 'yes' && patient?.guideTemplate?.name === 'WF_Path1')
  return (
    <ModalOverlay>
      <Confetti width={width} height={height} recycle={false} numberOfPieces={800} tweenDuration={10000} />
      <ModalContent>
        <CloseButton onClick={closeClick}>
          <CloseOutlined />
        </CloseButton>
        {patient.postAcpDigitalAdvanceCarePlanning === 'no' && patient?.guideTemplate?.name === 'WF_Path1' && (
          <PostSurveyCongratsWfpath1No closeClick={closeClick} />
        )}
        {patient.postAcpDigitalAdvanceCarePlanning === 'yes' && patient?.guideTemplate?.name === 'WF_Path1' && (
          <PostSurveyCongratsWfpath1Yes closeClick={closeClick} />
        )}
        {patient?.guideTemplate?.name !== 'WF_Path1' && <PostSurveyCongratsWfpath2 closeClick={closeClick} />}
      </ModalContent>
    </ModalOverlay>
  )
}

export default PostSurveyCongrats
