import { Trans } from 'react-i18next'

import { ElephantChatText } from 'components/common/ElephantChat'
//
import getQuestions from 'lib/helpers/questions'
import 'lib/helpers/i18n'
import i18n from 'lib/helpers/i18n'

import { Patient, GuideTemplateSectionIdEnum, GuideTemplateSection } from 'generated/graphql'
import { LocalStorageKeyEnum } from 'lib/types/LocalStorageKeyEnum'
import getLocalStorage from 'lib/helpers/getLocalStorage'

interface GetElephantTextArgs {
  /** holds the current module that needs to be done, or null if all modules are done */
  activeStep?: GuideTemplateSectionIdEnum
  plan: Patient
  guideComplete: boolean
}

interface GetguideCompleteArgs {
  patient: Patient
  modules: GuideTemplateSection[]
}

export default class PlanConfiguratorHelper {
  getListOfCompleteSections = (patient: Patient): string[] => {
    const completed: string[] = []
    patient?.guideTemplate?.sections?.forEach((section) => {
      const isComplete = this.isComplete(section, patient)
      if (isComplete) {
        completed.push(section?.sectionName)
      }
    })
    return completed
  }
  getguideComplete({ modules, patient }: GetguideCompleteArgs): boolean {
    let guideComplete = true
    if (modules?.length === 0) throw new Error('Modules are required')
    const requiredSections = modules?.filter((mod) => {
      if (patient?.guideTemplate?.name === 'WF_Path1') {
        return (
          mod.required &&
          mod.sectionId !== GuideTemplateSectionIdEnum.PreSurvey &&
          mod.sectionId !== GuideTemplateSectionIdEnum.PostAcpSurvey
        )
      }
      return mod.required
    })
    requiredSections?.forEach((section) => {
      if (!section) return
      const isComplete = this.isComplete(section, patient)
      if (!isComplete) {
        guideComplete = false
      }
    })

    return guideComplete
  }
  getIsPreAcpSurveyCompleted(patient: Patient): boolean {
    if (
      patient.healthIs &&
      patient.doOnYourOwn &&
      patient.doOnYourOwnWithoutHelp &&
      patient.personalLossOrMisfortune &&
      patient.takeCareSomeoneWasSeriouslyIll &&
      patient.statementMostAdvanceCarePlanning &&
      patient.knowWhatAdvanceCarePlanningIs &&
      patient.aboutWhoCanBeDecisionMaker &&
      patient.aboutWhatMakesGoodDecisionMaker &&
      patient.informedAboutFlexibilityDecisionMaker &&
      patient.comfortLevelBecomeIll &&
      patient.worriedFutureIllnesses &&
      patient.decisionMakerKindOfMedicalYouWouldWant &&
      patient.healthcareProviderKindOfMedicalYouWouldWant &&
      patient.medicalAttorneyLivingWillAdvanceDirective
    ) {
      return true
    }
    return false
  }
  getIsPostAcpSurveyCompleted(patient: Patient): boolean {
    const isCommonPostSurveyCompleted =
      patient.postAcpDigitalAdvanceCarePlanning &&
      patient.postAcpKnowWhatAdvanceCarePlanningIs &&
      patient.postAcpAboutWhoCanBeDecisionMaker &&
      patient.postAcpAboutWhatMakesGoodDecisionMaker &&
      patient.postAcpInformedAboutFlexibilityDecisionMaker &&
      patient.postAcpComfortLevelBecomeIll &&
      patient.postAcpWorriedFutureIllnesses &&
      patient.postAcpDecisionMakerKindOfMedicalYouWouldWant &&
      patient.postAcpHealthcareProviderKindOfMedicalYouWouldWant &&
      patient.postAcpHealthcareSystemTrust &&
      patient.postAcpOnlineFormat &&
      patient.postAcpMedicalEducationPrefer &&
      patient.postAcpInfoAboutCPR &&
      patient.postAcpStatementMostAdvanceCarePlanning
    const isWfpath1PostSurveyCompleted =
      patient.postAcpUseSystemFrequently &&
      patient.postAcpSystemUnnecessarilyComplex &&
      patient.postAcpSystemWasEasyUse &&
      patient.postAcpSupportTechnicalPerson &&
      patient.postAcpVariousFunctionsWellIntegrated &&
      patient.postAcpTooMuchInconsistency &&
      patient.postAcpMostPeopleWouldLearn &&
      patient.postAcpSystemVeryCumbersomeToUse &&
      patient.postAcpConfidentUsingTheSystem &&
      patient.postAcpLearnLotPfThingsBefore
    if (patient?.guideTemplate?.name === 'WF_Path1') {
      return !!(isCommonPostSurveyCompleted && isWfpath1PostSurveyCompleted)
    }
    return !!isCommonPostSurveyCompleted
  }
  getElephantText({ activeStep, plan, guideComplete }: GetElephantTextArgs): string | JSX.Element {
    const isPostSurveyComplete = this.getIsPostAcpSurveyCompleted(plan)

    if (
      plan?.guideTemplate?.name === 'WF_Path1' &&
      guideComplete &&
      isPostSurveyComplete &&
      plan.hasPlanViewed &&
      plan.postAcpDigitalAdvanceCarePlanning &&
      plan.schedulingACPinformation &&
      plan.postAcpDigitalAdvanceCarePlanning !== 'yes'
    ) {
      return i18n.t(
        'explainer.completedGreatJob',
        "Great job! You've completed your Koda Care Plan. Click View to see your official plan. If you edit your plan, you'll need to re-sign the document."
      )
    }

    if (
      plan?.guideTemplate?.name === 'WF_Path1' &&
      guideComplete &&
      isPostSurveyComplete &&
      plan.postAcpDigitalAdvanceCarePlanning &&
      plan.postAcpDigitalAdvanceCarePlanning !== 'yes' &&
      plan.hasPlanViewed &&
      plan.schedulingACPinformation &&
      !plan.sharedPlan
    ) {
      return i18n.t(
        'explainer.ifYouAreHappyWithPlan',
        'If you’re happy with your plan, click Share Plan to email it to yourself and loved ones.'
      )
    }

    if (
      plan?.guideTemplate?.name === 'WF_Path1' &&
      guideComplete &&
      plan.hasPlanViewed &&
      isPostSurveyComplete &&
      plan.postAcpDigitalAdvanceCarePlanning === 'no' &&
      !plan.sharedPlan
    ) {
      return i18n.t(
        'explainer.nextStepIsMakePlanOfficial',
        'The next step is to make your plan official. To learn how to do this, click Next.'
      )
    }

    if (
      plan?.guideTemplate?.name === 'WF_Path1' &&
      guideComplete &&
      // plan.hasPlanViewed &&
      isPostSurveyComplete &&
      plan.postAcpDigitalAdvanceCarePlanning === 'yes'
    ) {
      return i18n.t('explainer.thankYouForCompleting')
    }

    if (plan?.guideTemplate?.name === 'WF_Path1' && guideComplete && plan.hasPlanViewed && !isPostSurveyComplete) {
      return i18n.t('explainer.clickPostSurveyToAnswer')
    }

    if (plan?.guideTemplate?.name === 'WF_Path1' && guideComplete && !plan.hasOfficialPlan && !plan.hasPlanViewed) {
      return i18n.t('explainer.yourCarePlanCreated')
    }

    if (plan?.guideTemplate?.name === 'WF_Path2_Part2' && guideComplete && !plan.hasOfficialPlan && !plan.sharedPlan) {
      return i18n.t('explainer.yourCarePlanCreated')
    }

    if (plan?.guideTemplate?.name === 'WF_Path2_Part2' && guideComplete && !plan.hasOfficialPlan && plan.sharedPlan) {
      return i18n.t('explainer.nextStepIsMakePlanOfficial')
    }

    if (guideComplete && plan.hasOfficialPlan) {
      return i18n.t('explainer.completedGreatJob')
    }

    const hasViewedNextStepsLearnMore = getLocalStorage(
      `${plan?.userId}-${LocalStorageKeyEnum.HasViewedNextStepsLearnMore}`
    )

    // after sharing unofficial plan
    if (guideComplete && !hasViewedNextStepsLearnMore && !plan.hasOfficialPlan) {
      return i18n.t(
        'explainer.seeNextSteps',
        'Great Job! Your Care Plan has been created. To find out more about your next steps, click Learn.'
      )
    }

    // after sharing unofficial plan
    if (guideComplete && plan.sharedPlan && !plan.hasOfficialPlan) {
      return i18n.t('explainer.nextStepIsMakePlanOfficial')
    }

    // prior to sharing unofficial plan (both eSign and non-eSign States)
    if (guideComplete && !plan.hasOfficialPlan && !plan.sharedPlan && !plan.hasPlanViewed) {
      return i18n.t('explainer.yourCarePlanCreated')
    }

    if (guideComplete && !plan.sharedPlan && plan.hasPlanViewed && hasViewedNextStepsLearnMore) {
      return i18n.t('explainer.yourCarePlanCreated')
    }

    if (activeStep) {
      const ELEPHANT_TEXT_HASH = {
        [GuideTemplateSectionIdEnum.PreSurvey]: (
          <ElephantChatText>
            <Trans>{i18n.t('explainer.toGetStartedClickPreACP')}</Trans>
          </ElephantChatText>
        ),
        [GuideTemplateSectionIdEnum.PostAcpSurvey]: (
          <ElephantChatText>
            <Trans>{i18n.t('explainer.clickPostACPToAnswer')}</Trans>
          </ElephantChatText>
        ),
        [GuideTemplateSectionIdEnum.SchedulingAcp]: (
          <ElephantChatText>
            <Trans>{i18n.t('explainer.thankYouForCompletingSurvey')}</Trans>
          </ElephantChatText>
        ),
        [GuideTemplateSectionIdEnum.Values]: (
          <ElephantChatText>
            <Trans>{i18n.t('explainer.letsGetYourPlanCreated')}</Trans>
          </ElephantChatText>
        ),
        [GuideTemplateSectionIdEnum.MedicalCare]: i18n.t('explainer.greatWorkIfYouAreReady'),
        [GuideTemplateSectionIdEnum.MedicalDecisionMaker]: (
          <ElephantChatText>
            {i18n.t(
              'medicalDecisionMakerPageText',
              'Thanks for sharing your values. Now you will learn about selecting a medical decision maker.'
            )}
          </ElephantChatText>
        ),
        [GuideTemplateSectionIdEnum.QualityOfLife]: i18n.t('explainer.nowLetsTalkAboutQualityOfLife'),
        [GuideTemplateSectionIdEnum.SupportiveCare]: i18n.t('explainer.youAreDoingGreatNowWeHaveEducational'),
      }

      return ELEPHANT_TEXT_HASH[activeStep]
    }
    return ''
  }
  /**
   *
   *
   */
  isCompleteCheckForDecisionMaker(section: GuideTemplateSectionIdEnum, plan: Patient): boolean {
    const REQUIRED_DECISION_MAKER_QS = [
      'decisionMakerFirstName',
      'decisionMakerLastName',
      'decisionMakerRelationship',
      'decisionMakerStrictness',
    ]

    const REQUIRED_ALT_DECISION_MAKER_QS = ['altDecisionMakerFirstName', 'altDecisionMakerLastName']

    // If doYouKnowDecisionMaker === no, then we consider decision maker done
    if (section === GuideTemplateSectionIdEnum.MedicalDecisionMaker && plan.doYouKnowDecisionMaker === 'no') {
      return true
    }

    // If doYouKnowDecisionMaker === yes, then we need to complete REQUIRED_DECISION_MAKER_QS
    if (section === GuideTemplateSectionIdEnum.MedicalDecisionMaker && plan.doYouKnowDecisionMaker === 'yes') {
      REQUIRED_DECISION_MAKER_QS.forEach((fieldName) => {
        if (!plan[fieldName] && plan[fieldName] !== 0) {
          return false
        }
      })
    }
    /**
     * If wantsAltDecisionMaker === 'no'
     * Then we need or original decision maker stuff complete... ie complete decisionMakerName, decisionMakerRelationship, decisionMakerStrictness
     */
    if (section === GuideTemplateSectionIdEnum.MedicalDecisionMaker && plan.wantsAltDecisionMaker === 'no') {
      REQUIRED_DECISION_MAKER_QS.forEach((fieldName) => {
        if (!plan[fieldName] && plan[fieldName] !== 0) {
          return false
        }
      })
      // if we make it through REQUIRED_DECISION_MAKER_QS without returning false, then we can return true becuase it's complete
      return true
    }
    /**
     * If wantsAltDecisionMaker === 'yes'
     * Then we need to complete decisionMakerName, decisionMakerRelationship, decisionMakerStrictness, altDecisionMakerFirstName, altDecisionMakerLastName
     */
    if (section === GuideTemplateSectionIdEnum.MedicalDecisionMaker && plan.wantsAltDecisionMaker === 'yes') {
      REQUIRED_DECISION_MAKER_QS.forEach((fieldName) => {
        if (!plan[fieldName] && plan[fieldName] !== 0) {
          return false
        }
      })
      REQUIRED_ALT_DECISION_MAKER_QS.forEach((fieldName) => {
        if (!plan[fieldName] && plan[fieldName] !== 0) {
          return false
        }
      })
      // if we make it through REQUIRED_DECISION_MAKER_QS without returning false, then we can return true becuase it's complete
      return true
    }

    return false
  }
  /**
   *
   *
   *
   *
   *
   */
  isComplete(section: GuideTemplateSection, plan: Patient): boolean {
    let questions = getQuestions(plan, section?.questionIds || [])
    let isComplete = true
    let requiredQs = questions.filter((q) => q.required)
    if (plan.schedulingACPinformation && section.sectionId === GuideTemplateSectionIdEnum.SchedulingAcp) {
      return false
    }
    const requiredFields: string[] = []
    requiredQs.forEach((item) => {
      // we want to add any fields to the requiredFields array that are 1. required and 2. not decision maker questions
      if (item.required && item.fieldName) {
        if (item.fieldNames && item.fieldNames.length > 0) {
          item.fieldNames.forEach((fieldName) => {
            requiredFields.push(fieldName)
          })
        } else if (item.fieldName) {
          requiredFields.push(item.fieldName)
        }
      }
      // for questions of type two-strings, we are collecting two different fieldNames in one questions, so it requires us to break up each field
      if (item.type === 'two-strings' && item?.inputs) {
        item.inputs.forEach((input) => {
          requiredFields.push(input.fieldName)
        })
      }
    })

    if (section?.sectionId === GuideTemplateSectionIdEnum.PreSurvey) {
      if (requiredFields.length > 0) {
        requiredFields.forEach((fieldName) => {
          if (!plan[fieldName]) {
            isComplete = false
          }
        })
      } else {
        isComplete = false
      }
    }

    if (section?.sectionId === GuideTemplateSectionIdEnum.MedicalDecisionMaker) {
      isComplete = this.isCompleteCheckForDecisionMaker(section?.sectionId, plan)
    }

    if (section?.sectionId !== GuideTemplateSectionIdEnum.MedicalDecisionMaker) {
      // for each over each required field and redirect the user if needed
      requiredFields.forEach((fieldName) => {
        /**
         * some questions/answers can be the number zero... so we have to test that it
         * both doesn't exist and also isn't a 0 (because zero is falsly, but a legitimate answer)
         * also: ONLY RUN THIS CHECK IF SECTION WE'RE CHECKING is not MEDICAL_DECISION_MAKER... that has it's own checks done above
         */
        if ((!plan[fieldName] && plan[fieldName] !== 0) || plan[fieldName].length === 0) {
          // console.log(`did not complete: ${fieldName}`);
          isComplete = false
        }
      })
    }

    return isComplete
  }
}
