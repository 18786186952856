import { useEffect, useState } from 'react'
import styled from 'styled-components'
import i18n from 'lib/helpers/i18n'
import FormItem from 'components/common/FormItem'
import TextInput from 'components/inputs/TextInput'

export const EmailEndingBtn = styled.button`
  margin-right: 16px;
  margin-bottom: 8px;
  color: ${(p) => p.theme.colors.primary1};
  background: ${(p) => p.theme.colors.primary10};
  border-radius: 5px;
  border: 0px;
  padding: 4px 8px;
`

export const EmailEndingRow = styled.div`
  margin-top: 16px;
  display: flex;
  width: 100%;
  /* overflow-x: scroll; */
  flex-wrap: wrap;
  padding-top: 4px;
  padding-bottom: 8px;
`
export const HintLink = styled.span`
  font-family: ${(p) => p.theme.fontFamily};
  color: ${(p) => p.theme.colors.supportB3};
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  padding: 0px;
  margin: 0px;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0px;
  border: 0px;
  cursor: pointer;
  outline: 0;
  display: inline;
  background: transparent;
  &:hover {
    color: ${(p) => p.theme.colors.supportB3};
  }
`
interface SignupFormProps {
  email: string
  onBlurInput: () => void
  handleUseAsGuest: () => void
  onChange: (email: string) => void
  onBeforeSubmit: (e: any) => void
  error?: string
  emailInput: any
}

const domains = ['@', '@gmail.com', '@outlook.com', '@yahoo.com', '@hotmail.com', '@sbcglobal.net']

export default function EmailDomainAdder({ email, onBlurInput, handleUseAsGuest, onChange, error, emailInput }: SignupFormProps) {
  const EmailEnding = ({ value, onClick }) => {
    if (!email) {
      return (
        <EmailEndingBtn disabled onClick={() => onClick(value)}>
          {value}
        </EmailEndingBtn>
      )
    } else {
      return <EmailEndingBtn onClick={() => onClick(value)}>{value}</EmailEndingBtn>
    }
  }

  const handleAppendDomain = (appendedValue) => {
    onChange(`${email}${appendedValue}`)
  }

  return (
    <>
      <FormItem
        label={i18n.t('signupPage.email')}
        required
        htmlFor="email"
        error={error}
        tooltip={
          <>
            {i18n.t('signupPage.dontHaveEmail', 'Don\'t have an email? You can ')}
            <HintLink onClick={handleUseAsGuest}>{i18n.t('signupPage.signinAsGuest', 'sign in as a guest')}</HintLink>.
          </>
        }
      >
        <TextInput
          type="email"
          id="email"
          className="fs-exclude"
          inputMode="email"
          data-testid="signup-email"
          onChange={(e) => onChange(e.target.value.toLowerCase())}
          onBlur={() => onBlurInput()}
          value={email}
          required
        />
      </FormItem>
      <EmailEndingRow 
          ref={emailInput}
          >
        {domains.map((domain) => (
          <EmailEnding
            value={domain}
            onClick={(ending) => {
              handleAppendDomain(ending)
            }}
          />
        ))}
      </EmailEndingRow>
    </>
  )
}