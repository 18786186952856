import React from 'react'
import { useHistory } from 'react-router-dom'
import { useWindowWidth } from '@react-hook/window-size'
// COMPONENTS
import Button from 'components/common/Button'
import elephantSVG from './grey-elephant.svg'
import { useTranslation } from 'react-i18next'

type DashboardButtonProps = {
  style?: React.CSSProperties
  onClick?: () => void
}

export default function DashboardButton({ style, onClick }: DashboardButtonProps) {
  const history = useHistory()
  const screenWidth = useWindowWidth()
  const { t } = useTranslation()

  const isMobile = screenWidth <= 768
  return (
    <Button
      grey
      style={{ width: !isMobile ? 135 : 90, ...style }}
      onClick={onClick ? onClick : () => history.push('/app')}
      data-testid="dashboard-home-button"
    >
      {!isMobile && <img src={elephantSVG} alt="Elephant" height="18" style={{ marginRight: 8 }} />}
      {t('home', 'Home')}
    </Button>
  )
}
