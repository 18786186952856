import { useState, useEffect, useCallback } from 'react'
import Select from 'antd/lib/select'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import config from 'lib/config'
import { LangTypeEnum } from 'generated/graphql'
import { LocalStorageKeyEnum } from 'lib/types/LocalStorageKeyEnum'
import setLocalStorage from 'lib/helpers/setLocalStorage'

const { Option } = Select

const LanguageSwitchContainer = styled.div`
  position: absolute;
  top: 16px;
  right: 24px;
  z-index: 999;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    top: 12px;
    right: 14px;
  }
  .ant-select-selector {
    border-color: transparent !important;
    background-color: transparent !important;
    box-shadow: none !important;
  }
  .ant-select-selection-item {
    font-weight: 500;
    color: ${(p) => p.theme.colors.primary2};
  }
  .ant-select-arrow {
    top: 50%;
    svg {
      width: 12px;
      height: 12px;
      font-weight: 500;
      color: ${(p) => p.theme.colors.primary2};
    }
  }
`

const label = {
  [LangTypeEnum.En]: 'English',
  [LangTypeEnum.Es]: 'Español',
}

interface ExitButtonProps {
  styles?: React.CSSProperties
}

const SwitchLanguage = ({ styles }: ExitButtonProps) => {
  const [open, setOpen] = useState(false)
  const [selectedLang, setSelectedLang] = useState<LangTypeEnum>()
  const { i18n } = useTranslation()

  const LANGUAGUES = [
    {
      value: LangTypeEnum.En,
      label: label[LangTypeEnum.En],
    },
    {
      value: LangTypeEnum.Es,
      label: label[LangTypeEnum.Es],
    },
  ]

  const handleChange = useCallback(
    (lang) => {
      if (lang !== selectedLang) {
        setLocalStorage(LocalStorageKeyEnum.Language, lang)
        setSelectedLang(lang)
        i18n.changeLanguage(lang)
      }
    },
    [i18n, selectedLang]
  )

  useEffect(() => {
    const defaultLang = localStorage.getItem('language') as string
    if (defaultLang) {
      handleChange(defaultLang)
    } else {
      setSelectedLang(LangTypeEnum.En)
    }
  }, [handleChange])

  return config.NODE_ENV !== 'production' ? (
    <LanguageSwitchContainer style={styles}>
      {selectedLang && (
        <Select onDropdownVisibleChange={setOpen} defaultValue={selectedLang} onChange={(val) => handleChange(val)}>
          {LANGUAGUES.map((lang) => (
            <Option disabled={lang.value === selectedLang} value={lang.value} key={lang.value}>
              {lang.label}
            </Option>
          ))}
        </Select>
      )}
    </LanguageSwitchContainer>
  ) : (
    <span />
  )
}

export default SwitchLanguage
