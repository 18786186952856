// TOP LEVEL IMPORTS
import theme, { GlobalStyle } from 'lib/theme'
import { ThemeProvider } from 'styled-components'
import AppRoutes from './routes/index'
import { ApolloProvider } from '@apollo/client'
import client from 'ApolloClient/index'
import PreloadImages from 'components/common/PreloadImages'
import ErrorBoundary from 'components/common/ErrorBoundary'
import { BrowserRouter } from 'react-router-dom'
import 'lib/helpers/i18n'
import useSentry from 'lib/hooks/useSentry'
import useRegisterPdfFonts from 'lib/hooks/useRegisterPdfFonts'

export default function App() {
  useRegisterPdfFonts()
  useSentry()

  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <ErrorBoundary>
          <GlobalStyle />
          <BrowserRouter>
            <AppRoutes />
          </BrowserRouter>
          {/* Uncomment this and comment out AppRoutes if you want to show a maintenance screen to users instead of the app <DownForMaintenance /> */}
          <PreloadImages />
        </ErrorBoundary>
      </ThemeProvider>
    </ApolloProvider>
  )
}
