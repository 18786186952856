import { useTranslation } from 'react-i18next';
import s from "../styles";
import { Text, Image, View } from "@react-pdf/renderer";
import { GuideQuestionIdEnum, Patient } from "generated/graphql";
import logoPNG from "components/common/PlanDoc/horizontal-logo.png";
import theme from "lib/theme";
import moment from "moment";
import PlanConfiguratorHelper from "routes/app-home/PlanConfigurator/PlanConfiguratorHelper";
//LIB
import i18n from 'lib/helpers/i18n';

interface SessionRowProps {
  data: Patient;
}

const SessionRow = ({ data }: SessionRowProps) => {
  const { t } = useTranslation();

  const planConfiguratorHelper = new PlanConfiguratorHelper();
  const completedSteps = planConfiguratorHelper.getListOfCompleteSections(data);

  return (
    <View
      style={{
        paddingTop: 8,
        paddingBottom: 8,
        marginTop: 8,
        display: "flex",
        flexDirection: "row",
        width: "100%",
        justifyContent: "flex-start",
        alignItems: "flex-start",
      }}
    >
      <View style={{ flex: 1 }}>
        <Text style={{ ...s.text, margin: 0, height: 16 }}>
          {data?.createdAt &&
            moment(parseInt(data?.createdAt))?.format("MM/DD/YYYY")}
        </Text>
      </View>
      <View style={{ flex: 1 }}>
        <Text style={{ ...s.text, margin: 0, height: 16 }}>
          {data?.[GuideQuestionIdEnum.AffordMedications]
            ? data?.[GuideQuestionIdEnum.AffordMedications]
            : "N/A"}
        </Text>
      </View>
      <View style={{ flex: 3 }}>
        <Text style={{ ...s.text, margin: 0 }}>
          {t("sessionSummary.coveredFollowingTopics")}
          {completedSteps?.map((sectionName, index) =>
            index === completedSteps.length - 1
              ? `${completedSteps.length !== 1 ? "and" : ""} ${sectionName}`
              : `${sectionName}${index === completedSteps.length - 2 ? " " : ", "
              }`
          )}
        </Text>
      </View>
    </View>
  );
};

interface SessionSummaryProps {
  data: Patient;
}

export default function SessionSummary({ data }: SessionSummaryProps) {
  return (
    <View>
      <View
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: 16,
        }}
      >
        <View>
          <Image src={logoPNG} style={{ width: 200, margin: "auto" }} />
          <Text style={{ ...s.title, marginTop: 16 }}>{i18n.t("sessionSummary.title")}</Text>
        </View>
      </View>
      <View style={{ marginTop: 16 }}>
        <Text style={{ ...s.text, marginBottom: 0 }}>
          {i18n.t("sessionSummary.patientName")} {data?.firstName} {data?.lastName}
        </Text>
        <Text style={{ ...s.text, marginBottom: 0 }}>
          {i18n.t("sessionSummary.accountCreation")}
          {data?.createdAt &&
            moment(parseInt(data?.createdAt))?.format("MMM DD, YYYY")}
        </Text>
        <Text style={{ ...s.text, marginBottom: 0 }}>
          {i18n.t("sessionSummary.lastUpdated")}
          {data?.updatedAt &&
            moment(parseInt(data?.updatedAt))?.format("MMM DD, YYYY")}
        </Text>
      </View>
      <View
        style={{
          marginTop: 16,
          borderTopWidth: 1,
          borderBottomWidth: 1,
          borderColor: theme.colors.neutral8,
          paddingTop: 8,
          paddingBottom: 8,
          display: "flex",
          flexDirection: "row",
          width: "100%",
        }}
      >
        <View style={{ flex: 1 }}>
          <Text
            style={{
              ...s.textBold,
              marginBottom: 0,
            }}
          >
            {i18n.t("sessionSummary.sessionDate")}
          </Text>
        </View>
        <View style={{ flex: 1 }}>
          <Text
            style={{
              ...s.textBold,
              margin: 0,
              height: 16,
            }}
          >
            {i18n.t("sessionSummary.ableAfford")}
          </Text>
          <Text
            style={{
              ...s.textBold,
              margin: 0,
              height: 16,
            }}
          >
            {i18n.t("sessionSummary.medications")}
          </Text>
        </View>
        <View style={{ flex: 3 }}>
          <Text
            style={{
              ...s.textBold,
              marginBottom: 0,
            }}
          >
            {i18n.t("sessionSummary.summary")}
          </Text>
        </View>
      </View>
      <SessionRow data={data} />
    </View>
  );
}
