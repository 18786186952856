export enum AnalyticEventTypeEnums {
  /** Fires when any page in the app has been viewed */
  PAGE_VIEW = 'Page View',
  /** Fires when somebody lands on the login page */
  LOGIN_PAGE = 'Login Page',
  /** Fires when the video player autoplays */
  VIDEO_START_AUTOPLAY = 'Video Start Autoplay',
  /** Fires when somebody clicks "Play" for a video */
  VIDEO_START_CLICKED = 'Video Start Clicked',
  /** Fires when somebody clicks "Skip" for a video */
  VIDEO_SKIPPED = `Video Skipped`,
  /** Fires when somebody pauses the video player */
  VIDEO_PAUSED = 'Video Paused',
  /** Fires when somebody visits the support page */
  VISITED_SUPPORT_PAGE = 'Visited Support Page',
  /** Fires when an activity item gets submitted */
  ACTIVITY_ITEM = 'Activity Item',
  /** Fires when somebody clicks "schedule a nortary" */
  SCHEDULE_NOTARY = 'Schedule Notary Clicked',
  /** Fires when somebody uploads a completed plan */
  UPLOAD_COMPLETED_PLAN = 'Uploaded Completed Plan',
  /** Fires when somebody expands an explainer */
  CLICK_EXPLAINER_SECTION = 'Clicked Explainer Section',
  /** Fires when somebody emails their plan via share plan page */
  PLAN_SHARED = 'Plan Shared',
  /** Fires when somebody submits the signup form (but doesn't guarentee the account was created, since it fires before sending the request to the server) */
  SIGNUP_CLICKED = 'Signup Clicked - User',
  /** Fires when somebody submits the guest signup form (but doesn't guarentee the account was created, since it fires before sending the request to the server) */
  SIGNUP_GUEST_CLICKED = 'Signup Clicked - Guest User',
  /** Fires when somebody clicks the back button inside of the QuestionView NOT DURING edit mode */
  SURVEY_BACK_BUTTON_INITIAL = 'Back Clicked - Initial',
  /** Fires when somebody clicks the back button inside of the QuestionView DURING edit mode */
  SURVEY_BACK_BUTTON_EDITING = 'Back Clicked - Editing',
  /** Fires when somebody clicks the Edit button (the edit shows up on at least 2 pages as of June 29, 2022) */
  EDIT_PLAN_CLICKED = 'Edit Plan Clicked',
  /** Fires when survey link is clicked (only for WF) */
  POST_SURVEY_ALERT_LINK_CLICK = 'Post Survey Alert Link Clicked',
  /** Fires when survey link is clicked (only for WF) */
  POST_SURVEY_ALERT_DISMISSED = 'Post Survey Alert Dismissed',
  /** Fires when somebody sends a MDM email with a personal message */
  SENT_MDM_EMAIL_WITH_PERSONAL_MESSAGE = 'Sent MDM Email With Personal Message',
  /** Fires when patient closes the readiness survey */
  PATIENT_CLOSED_MPO_READINESS_SURVEY = 'Patient closed MPO Readiness Survey',
  /** Fires when patient submits the help form */
  PATIENT_SUBMITTED_HELP_FORM = 'Patient submitted help form',
  /** Fires when the NPS score is submitted */
  PATIENT_SUBMITTED_NPS = 'Patient Submitted NPS',
  /** Fires when a user refresh token expired (and they are kicked out of the app) */
  USER_REFRESH_TOKEN_EXPIRED = 'USER_REFRESH_TOKEN_EXPIRED',
  /** An error occured */
  ERROR_CAPTURED = 'ERROR_CAPTURED',
  SIGNOUT_AND_CONTINUE_LATER_CLICKED = 'Patient clicked sign out and continue later',
  PATIENT_OPTED_PASSWORD_BY_EMAIL = 'Patient opted to create password by email',
  PATIENT_CREATED_PASSWORD_AT_SIGNUP = 'Patient created password during signup'
}
