import styled from 'styled-components';

export default styled.label`
  max-width: 100%;
  font-weight: unset;
  font-size: 18px;
  line-height: 28px;
  margin: 0px;
  line-height: 135%;
  hyphens: auto;
  @media only screen and (max-width: ${({ theme }) =>
    theme?.breakpoints?.medium}) {
    font-size: 18px;

  }
  @media only screen and (max-width: ${({ theme }) =>
    theme?.breakpoints?.small}) {
    font-size: 16px;
  }
`;
