// TOP LEVEL IMPORTS
import { FC } from 'react'
import { Route } from 'react-router-dom'
import { UserProfile } from 'generated/graphql'
import useRerouteUserOnLogin from 'lib/hooks/useRerouteUserOnLogin'

interface PublicRouteProps {
  currentUser?: UserProfile
  redirectOnAuth?: boolean
  showHeader?: boolean
  showFooter?: boolean
  component?: any
  path?: string
  backgroundColor?: string
  layout?: any
  exact?: boolean
}
// EXPORTED COMPONENT
// ==============================
const PublicRoute: FC<PublicRouteProps> = (props) => {
  const {
    currentUser,
    redirectOnAuth,
    showHeader,
    showFooter,
    component: Component,
    path,
    backgroundColor,
    layout: Layout,
    ...rest
  } = props

  useRerouteUserOnLogin({
    currentUser,
    redirectOnAuth,
  })

  return (
    <Route
      {...rest}
      path={path}
      render={(args) => (
        <Layout
          currentUser={currentUser}
          backgroundColor={backgroundColor}
          showHeader={showHeader}
          showFooter={showFooter}
        >
          <Component currentUser={currentUser} {...args} />
        </Layout>
      )}
    />
  )
}

// PROPS
// ==============================
PublicRoute.defaultProps = {
  redirectOnAuth: true,
}

// EXPORT
// ==============================
export default PublicRoute
