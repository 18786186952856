import { FC, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import {
  DoOnYourOwnEnum,
  DoOnYourOwnWithoutHelpEnum,
  HealthIsEnum,
  Patient,
  PersonalLossOrMisfortuneEnum,
  TakeCareSomeoneWasSeriouslyIllEnum,
} from "generated/graphql";

import Button from "components/common/Button";
import SectionFooter from "../SectionFooter";
import SectionHeader from "../SectionHeader";
import SelectBlock from "./SelectBlock";
import useSavePatient from "lib/hooks/useSavePatient";
import usePrevious from "lib/hooks/usePrevious";
import Loading from "components/common/Loading";
import { useTranslation } from "react-i18next";

const Step1Styled = styled.div`
  background-color: white;
  flex: 1;
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints.medium}) {
    padding-bottom: 100px;
  }
`;

const Step1Body = styled.div`
  max-width: 600px;
  margin: auto;
  padding: 48px 10px;
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints.mediumSmall}) {
    padding: 24px;
  }
`;

const Step1Title = styled.h1`
  font-weight: 500;
  font-size: 18px;
  color: #323f4b;
`;

export const Step1Continue = styled.div`
  display: none;
  margin-top: 20px;
  @media only screen and (min-width: 1025px) {
    display: block;
  }
`;

const HEALTH_IS_TEXT_HASH = {
  [HealthIsEnum.Poor]: "Poor",
  [HealthIsEnum.Fair]: "Fair",
  [HealthIsEnum.Good]: "Good",
  [HealthIsEnum.VeryGood]: "Very Good",
  [HealthIsEnum.Excellent]: "Excellent",
};

const DO_ON_YOUR_OWN_TEXT_HASH = {
  [DoOnYourOwnEnum.ShopForGroceries]: "Shop for groceries",
  [DoOnYourOwnEnum.UseTheThelephone]: "Use the telephone",
  [DoOnYourOwnEnum.Housework]: "Housework",
  [DoOnYourOwnEnum.HandleFinances]: "Handle finances",
  [DoOnYourOwnEnum.DriveUsePublicTransportation]:
    "Drive/use public transportation",
  [DoOnYourOwnEnum.MakeMeals]: "Make meals",
  [DoOnYourOwnEnum.None]: "None",
};

const DO_ON_YOUR_OWN_WITHOUT_HELP_TEXT_HASH = {
  [DoOnYourOwnWithoutHelpEnum.Bath]: "Bath",
  [DoOnYourOwnWithoutHelpEnum.Walk]: "Walk",
  [DoOnYourOwnWithoutHelpEnum.Dress]: "Dress",
  [DoOnYourOwnWithoutHelpEnum.Eat]: "Eat",
  [DoOnYourOwnWithoutHelpEnum.TransferInOutOfChair]:
    "Transfer in/out of chair, etc",
  [DoOnYourOwnWithoutHelpEnum.UseTheRestroom]: "Use the restroom",
  [DoOnYourOwnWithoutHelpEnum.None]: "None",
};

const PERSONAL_LOSS_OR_MISFORTUNE_TEXT_HASH = {
  [PersonalLossOrMisfortuneEnum.No]: "No",
  [PersonalLossOrMisfortuneEnum.YesOneSeriousLoss]: "Yes, one serious loss",
  [PersonalLossOrMisfortuneEnum.YesMoreSeriousLoss]:
    "Yes, two or more serious losses",
};

const TAKE_CARE_SOMEONE_WAS_SERIOUSLY_ILL_TEXT_HASH = {
  [TakeCareSomeoneWasSeriouslyIllEnum.Yes]: "Yes",
  [TakeCareSomeoneWasSeriouslyIllEnum.No]: "No",
};

interface Step1Props {
  patient?: Patient | null;
}

export enum AfterSaveAction {
  NEXT = "NEXT",
  BACK = "BACK",
  HOME = "HOME",
}

const Step1: FC<Step1Props> = ({ patient }) => {
  const [afterSaveAction, setAfterSaveAction] = useState<AfterSaveAction>();
  const [healthIs, setHealthIs] = useState<HealthIsEnum>();
  const [doOnYourOwn, setDoOnYourOwn] = useState<DoOnYourOwnEnum[]>([]);
  const [doOnYourOwnWithoutHelp, setDoOnYourOwnWithoutHelp] = useState<
    DoOnYourOwnWithoutHelpEnum[]
  >([]);
  const [personalLossOrMisfortune, setPersonalLossOrMisfortune] =
    useState<PersonalLossOrMisfortuneEnum>();
  const [takeCareSomeoneWasSeriouslyIll, setTakeCareSomeoneWasSeriouslyIll] =
    useState<TakeCareSomeoneWasSeriouslyIllEnum>();

  const { onSavePatient, saving } = useSavePatient();
  const { t } = useTranslation();

  const history = useHistory();

  const savingPrev = usePrevious(saving);

  const changeDoOnYourOwn = (item: string): void => {
    if (item === "none" && !doOnYourOwn.includes("none" as DoOnYourOwnEnum)) {
      setDoOnYourOwn(["none" as DoOnYourOwnEnum]);
      return;
    }
    if (doOnYourOwn.includes(item as DoOnYourOwnEnum)) {
      setDoOnYourOwn(doOnYourOwn.filter((stateItem) => stateItem !== item));
    } else {
      setDoOnYourOwn([
        ...doOnYourOwn.filter(
          (stateItem) => stateItem !== ("none" as DoOnYourOwnEnum)
        ),
        item as DoOnYourOwnEnum,
      ]);
    }
  };

  const changeDoOnYourOwnWithoutHelp = (item: string): void => {
    if (
      item === "none" &&
      !doOnYourOwnWithoutHelp.includes("none" as DoOnYourOwnWithoutHelpEnum)
    ) {
      setDoOnYourOwnWithoutHelp(["none" as DoOnYourOwnWithoutHelpEnum]);
      return;
    }
    if (doOnYourOwnWithoutHelp.includes(item as DoOnYourOwnWithoutHelpEnum)) {
      setDoOnYourOwnWithoutHelp(
        doOnYourOwnWithoutHelp.filter((stateItem) => stateItem !== item)
      );
    } else {
      setDoOnYourOwnWithoutHelp([
        ...doOnYourOwnWithoutHelp.filter(
          (stateItem) => stateItem !== ("none" as DoOnYourOwnWithoutHelpEnum)
        ),
        item as DoOnYourOwnWithoutHelpEnum,
      ]);
    }
  };

  const save = async () => {
    if (patient) {
      await onSavePatient({
        ...patient,
        healthIs,
        doOnYourOwn,
        doOnYourOwnWithoutHelp,
        personalLossOrMisfortune,
        takeCareSomeoneWasSeriouslyIll,
      });
    }
  };

  const goNext = () => {
    setAfterSaveAction(AfterSaveAction.NEXT);
    save();
  };

  useEffect(() => {
    // set patient values every patient change
    if (patient) {
      setHealthIs(patient.healthIs as HealthIsEnum);
      setDoOnYourOwn(patient.doOnYourOwn as DoOnYourOwnEnum[]);
      setDoOnYourOwnWithoutHelp(
        patient.doOnYourOwnWithoutHelp as DoOnYourOwnWithoutHelpEnum[]
      );
      setPersonalLossOrMisfortune(
        patient.personalLossOrMisfortune as PersonalLossOrMisfortuneEnum
      );
      setTakeCareSomeoneWasSeriouslyIll(
        patient.takeCareSomeoneWasSeriouslyIll as TakeCareSomeoneWasSeriouslyIllEnum
      );
    }
  }, [patient]);

  useEffect(() => {
    // Go to step 2 on saving end and if patient is truthy
    if (savingPrev && !saving && patient && afterSaveAction) {
      switch (afterSaveAction) {
        case AfterSaveAction.NEXT: {
          history.push("/app/survey?step=2");
          break;
        }
        case AfterSaveAction.HOME: {
          history.push("/app/home");
          break;
        }
        case AfterSaveAction.BACK: {
          history.push("/");
          break;
        }
        default: {
          return;
        }
      }
    }
  }, [history, savingPrev, saving, patient, afterSaveAction]);

  const disabled =
    !healthIs ||
    doOnYourOwn.length === 0 ||
    doOnYourOwnWithoutHelp.length === 0 ||
    !personalLossOrMisfortune ||
    !takeCareSomeoneWasSeriouslyIll;

  return (
    <>
      <Step1Styled>
        <SectionHeader
          sectionName={t("planConfigurator.PRE_SURVEY")}
          progress={"0"}
          onClick={() => {
            setAfterSaveAction(AfterSaveAction.HOME);
            save();
          }}
        />
        {saving && <Loading height={80} />}
        <Step1Body>
          <Step1Title>Health Risk Assessment</Step1Title>
          <SelectBlock
            title="1. In general, would you say your health is:"
            subtitle="Please mark the box that best describes you"
            items={Object.keys(HEALTH_IS_TEXT_HASH)}
            hash={HEALTH_IS_TEXT_HASH}
            active={healthIs as string}
            onSelect={(item: string) => setHealthIs(item as HealthIsEnum)}
          />
          <SelectBlock
            title="2. Which of the following can you do on your own?"
            subtitle={t("chooseAsMany")}
            items={Object.keys(DO_ON_YOUR_OWN_TEXT_HASH)}
            hash={DO_ON_YOUR_OWN_TEXT_HASH}
            active={doOnYourOwn}
            onSelect={changeDoOnYourOwn}
          />
          <SelectBlock
            title="3. Which of the following can you do on your own without help?"
            subtitle={t("chooseAsMany")}
            items={Object.keys(DO_ON_YOUR_OWN_WITHOUT_HELP_TEXT_HASH)}
            hash={DO_ON_YOUR_OWN_WITHOUT_HELP_TEXT_HASH}
            active={doOnYourOwnWithoutHelp}
            onSelect={changeDoOnYourOwnWithoutHelp}
          />
          <SelectBlock
            title="4. Have you suffered a personal loss or misfortune in the last year?"
            subtitle="Please mark the box that best describes you"
            description="For example: a job loss, disability, divorce, separation, jail term, or the death of someone close to you?"
            items={Object.keys(PERSONAL_LOSS_OR_MISFORTUNE_TEXT_HASH)}
            hash={PERSONAL_LOSS_OR_MISFORTUNE_TEXT_HASH}
            active={personalLossOrMisfortune}
            onSelect={(item: string) =>
              setPersonalLossOrMisfortune(item as PersonalLossOrMisfortuneEnum)
            }
          />
          <SelectBlock
            title="5. Have you had to take care of someone that was seriously ill before?"
            subtitle="Please mark the box that best describes you"
            items={Object.keys(TAKE_CARE_SOMEONE_WAS_SERIOUSLY_ILL_TEXT_HASH)}
            hash={TAKE_CARE_SOMEONE_WAS_SERIOUSLY_ILL_TEXT_HASH}
            active={takeCareSomeoneWasSeriouslyIll}
            onSelect={(item: string) =>
              setTakeCareSomeoneWasSeriouslyIll(
                item as TakeCareSomeoneWasSeriouslyIllEnum
              )
            }
          />
          {!disabled && (
            <Step1Continue>
              <Button loading={saving} disabled={saving} onClick={goNext} pulse>
                Continue
              </Button>
            </Step1Continue>
          )}
        </Step1Body>
      </Step1Styled>
      <SectionFooter
        goBack={() => {
          setAfterSaveAction(AfterSaveAction.BACK);
          save();
        }}
        goNext={goNext}
        loading={saving}
        disabled={disabled}
      />
    </>
  );
};

export default Step1;
