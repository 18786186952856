import { getPlanSettings } from 'components/common/PlanDoc/MasterPlanDoc/plan-settings'
import { Patient, PatientFragmentFragment } from 'generated/graphql'
import { DeepPartial } from 'utility-types'

export default function useGetPlanSettings() {
  const getSettings = (patient: Patient | PatientFragmentFragment | DeepPartial<Patient>) => {
    const version = patient?.stateVersion?.abbreviation || ''
    const planSettings = getPlanSettings(patient)
    const settings = planSettings[version]
    return settings
  }
  return getSettings
}
