/**
 * This is a helper function that removes any HTML characters from a string
 */

export default function cleanHtmlFromString(html: string) {
  if (typeof html !== 'string') {
    throw new Error('Must pass in an string')
  }
  return html.replace(/<[^>]+>/g, '')
}
