import { useState } from 'react'
import styled from 'styled-components'
import Confetti from 'react-confetti'
import { useWindowSize } from '@react-hook/window-size'
// COMPONENTS
import { ModalOverlay } from 'components/common/KodaModal'
import Button from 'components/common/Button'
import Header from 'components/text/Header'
import Text from 'components/text/Text'
// LIB
import theme from 'lib/theme'
// APOLLO
import CURRENT_USER from 'ApolloClient/Queries/currentUser'
import CheckCircleFilled from 'components/icons/CheckCircleFilled'
import { Patient, useSubmitNetScoreMutation } from 'generated/graphql'
import i18n from 'lib/helpers/i18n'
import ExitButton from 'components/common/ExitButton'
import { AnalyticEventTypeEnums } from 'lib/types/AnalyticEventTypeEnums'
import logEvent from 'lib/helpers/logEvent'
import translateApolloError from 'lib/helpers/translateApolloError'
import message from 'components/common/message'

const ModalContent = styled.div`
  background: #fff;
  padding: 16px;
  border-radius: 5px;
  width: 600px;
  display: flex;
  align-items: center;
  max-width: 100%;
  position: relative;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    padding-bottom: 80px;
    top: 0px;
    position: fixed;
    bottom: 0px;
    overflow-y: scroll;
  }
`

const TopRow = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.neutral8};
  padding: 16px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 32px;
  text-align: center;
  width: 100%;
`

const BottomRow = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.neutral8};
  padding: 16px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 32px;
  text-align: center;
`

const BoldText = styled(Text)`
  font-size: 17px;
  font-weight: 700;
  width: 400px;
  max-width: 100%;
  margin: auto;
`

const ButtonContainer = styled.div`
  margin-top: 32px;
  display: flex;
  justify-content: center;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background: ${({ theme }) => theme.colors.neutral9};
    height: 72px;
    display: flex;
    align-items: center;
  }
`

const ChoicesContainer = styled.div`
  margin-top: 32px;
  display: flex;
  justify-content: center;
`

const OptionBtn = styled.button<{ active?: boolean; color: string }>`
  width: 40px;
  height: 40px;
  font-size: 18px;
  font-weight: 700;
  padding: 0px;
  border-radius: 5px;
  /* margin-right: 6px; */
  background: #fff;
  border: 0px;
  transition: all 0.1s ease;
  color: #000; /* https://kodahealth.atlassian.net/browse/KODA-271 */
  border: 2px solid ${({ color }) => color};
  background: ${({ color, active }) => {
    if (active) {
      return '#fff'
    }
    return color
  }};
  &:hover {
    transform: scale(1.1);
    cursor: pointer;
  }
  &:focus {
    outline: 0;
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    width: 100%;
    height: 48px;
    margin-bottom: 16px;
    &:hover {
      transform: none;
      cursor: pointer;
    }
  }
`

const LegendTextLeft = styled.h1`
  font-size: 14px;
  color: ${(p) => p.theme.colors.neutral3};
  flex: 1;
  font-weight: 400;
  text-align: left;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    display: none;
  }
`

const LegendTextRight = styled(LegendTextLeft)`
  text-align: right;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    text-align: center;
    display: block;
  }
`

const MobileUnlikelyText = styled(LegendTextLeft)`
  display: none;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    text-align: center;
    display: block;
  }
`

const OPTIONS = [
  {
    id: 0,
    label: '0',
    color: '#DC4955',
  },
  {
    id: 1,
    label: '1',
    color: '#DC4955',
  },
  {
    id: 2,
    label: '2',
    color: '#DC4955',
  },
  {
    id: 3,
    label: '3',
    color: '#DC4955',
  },
  {
    id: 4,
    label: '4',
    color: '#DC4955',
  },
  {
    id: 5,
    label: '5',
    color: '#DC4955',
  },
  {
    id: 6,
    label: '6',
    color: '#DC4955',
  },
  {
    id: 7,
    label: '7',
    color: '#FFA87A',
  },
  {
    id: 8,
    label: '8',
    color: '#FFA87A',
  },
  {
    id: 9,
    label: '9',
    color: '#8CC951',
  },
  {
    id: 10,
    label: '10',
    color: '#8CC951',
  },
]

const OptionsRow = styled.div`
  min-height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    padding-top: 16px;
    flex-direction: column-reverse;
  }
`

interface ShareSuccessModalProps {
  onClose: () => void
  decisionMakerEmail?: string
  email?: string
  otherEmails: string[]
  visible: boolean
  /** Should we show the feedback/NPS survey? */
  askForFeedback?: boolean
  patient: Patient
}

const EmailText = styled(Text)`
  hyphens: auto;
  word-break: break-all;
`

export default function ShareSuccessModal({
  onClose,
  decisionMakerEmail,
  email,
  otherEmails,
  visible,
  askForFeedback,
  patient,
}: ShareSuccessModalProps) {
  const [submitNetScore, { loading }] = useSubmitNetScoreMutation()
  const [score, setScore] = useState<number | undefined>(undefined)
  const [width, height] = useWindowSize()

  const onSubmitScore = async () => {
    try {
      if (loading) return null
      if (!score) return null
      //   setLoading(true);
      let res = await submitNetScore({
        variables: {
          score,
        },
        refetchQueries: [
          {
            query: CURRENT_USER,
          },
        ],
      })

      logEvent(AnalyticEventTypeEnums.PATIENT_SUBMITTED_NPS, {
        clinicId: patient?.clinicId,
        patientId: patient?.id,
        content: `${score}`,
      })

      if (res?.data?.submitNetScore?.success) {
        onClose()
      }
    } catch (err: any) {
      message.error(translateApolloError(err))
    }
  }

  if (!visible) return null

  return (
    <>
      <ModalOverlay>
        <Confetti width={width} height={height} recycle={false} numberOfPieces={400} />
        <ModalContent>
          <ExitButton onClick={onClose} />
          <div style={{ margin: 'auto', width: '100%', marginTop: 16 }}>
            <TopRow>
              <div>
                <Header
                  style={{
                    fontSize: 24,
                    position: 'relative',
                    display: 'inline-block',
                  }}
                >
                  <CheckCircleFilled
                    style={{
                      color: theme.colors.supportB5,
                      fontSize: 24,
                      position: 'absolute',
                      left: -32,
                      top: 5,
                    }}
                  />
                  {i18n.t('sharePlanModal.congrats')}
                </Header>
                <Text style={{ marginBottom: 8, marginTop: 8 }}>{i18n.t('sharePlanModal.shareText')}</Text>
                {email && <EmailText>{email}</EmailText>}
                {decisionMakerEmail && <EmailText>{decisionMakerEmail}</EmailText>}
                {otherEmails?.[0] &&
                  otherEmails.map((email) => {
                    return <EmailText key={email}>{email}</EmailText>
                  })}
              </div>
            </TopRow>
            {!askForFeedback && (
              <Button
                onClick={onClose}
                style={{
                  margin: '16px auto 0px auto',
                  width: 100,
                  display: 'block',
                }}
              >
                {i18n.t('sharePlanModal.btnClose')}
              </Button>
            )}
            {askForFeedback && (
              <BottomRow>
                <div style={{ width: '100%', margin: 'auto' }}>
                  <Text style={{ marginBottom: 16, marginTop: 8 }}>{i18n.t('sharePlanModal.feedbackText')}</Text>
                  <BoldText>{i18n.t('sharePlanModal.feedbackContent')}</BoldText>

                  <ChoicesContainer>
                    <div style={{ width: '100%', margin: 'auto' }}>
                      <div
                        style={{
                          display: 'flex',
                          height: 24,
                          justifyContent: 'space-between',
                        }}
                      >
                        <LegendTextLeft>{i18n.t('sharePlanModal.notAllLikely')}</LegendTextLeft>
                        <LegendTextRight>{i18n.t('sharePlanModal.likely')}</LegendTextRight>
                      </div>
                      <OptionsRow>
                        {OPTIONS.map((option) => (
                          <OptionBtn
                            key={option.id}
                            active={score === option.id}
                            color={option.color}
                            onClick={() => setScore(option.id)}
                          >
                            {option.label}
                          </OptionBtn>
                        ))}
                      </OptionsRow>
                      <MobileUnlikelyText>{i18n.t('sharePlanModal.notAllLikely')}</MobileUnlikelyText>
                    </div>
                  </ChoicesContainer>
                  <ButtonContainer>
                    <Button grey style={{ width: 120, marginRight: 24 }} onClick={onClose} disabled={loading}>
                      {i18n.t('sharePlanModal.btnClose')}
                    </Button>
                    <Button
                      style={{ width: 120 }}
                      onClick={onSubmitScore}
                      loading={loading}
                      disabled={loading || score === undefined}
                    >
                      {i18n.t('sharePlanModal.btnSubmit')}
                    </Button>
                  </ButtonContainer>
                </div>
              </BottomRow>
            )}
          </div>
        </ModalContent>
      </ModalOverlay>
    </>
  )
}
