// THIS FILE IS GENERATED, DO NOT EDIT!
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
const defaultOptions = {} as const
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  AccountNumber: any
  BigInt: any
  Byte: any
  CountryCode: any
  Currency: any
  DID: any
  Date: any
  DateTime: any
  Duration: any
  EmailAddress: any
  GUID: any
  HSL: any
  HSLA: any
  HexColorCode: any
  Hexadecimal: any
  IBAN: any
  IPv4: any
  IPv6: any
  ISBN: any
  ISO8601Duration: any
  JSON: any
  JSONObject: any
  JWT: any
  Latitude: any
  LocalDate: any
  LocalEndTime: any
  LocalTime: any
  Locale: any
  Long: any
  Longitude: any
  MAC: any
  NegativeFloat: any
  NegativeInt: any
  NonEmptyString: any
  NonNegativeFloat: any
  NonNegativeInt: any
  NonPositiveFloat: any
  NonPositiveInt: any
  ObjectID: any
  PhoneNumber: any
  Port: any
  PositiveFloat: any
  PositiveInt: any
  PostalCode: any
  RGB: any
  RGBA: any
  RoutingNumber: any
  SafeInt: any
  Time: any
  TimeZone: any
  Timestamp: any
  URL: any
  USCurrency: any
  UUID: any
  UnsignedFloat: any
  UnsignedInt: any
  UtcOffset: any
  Void: any
}

export type Access = {
  __typename?: 'Access'
  active?: Maybe<Scalars['Boolean']>
  id: Scalars['String']
  title: AccessTypeEnum
}

export enum AccessTypeEnum {
  CanCreateAdminUser = 'can_create_admin_user',
  CanCreateClinic = 'can_create_clinic',
  CanCreateClinicUser = 'can_create_clinic_user',
  CanCreatePatient = 'can_create_patient',
  CanCreatePermissions = 'can_create_permissions',
  CanDeleteAdminUser = 'can_delete_admin_user',
  CanDeletePatient = 'can_delete_patient',
  CanDeletePatientAttachment = 'can_delete_patient_attachment',
  CanDownloadClinicPatientData = 'can_download_clinic_patient_data',
  CanFaxClinic = 'can_fax_clinic',
  CanReadAllClinics = 'can_read_all_clinics',
  CanReadClinics = 'can_read_clinics',
  CanReadHipaalogs = 'can_read_hipaalogs',
  CanReadPatients = 'can_read_patients',
  CanReadPlanAttachments = 'can_read_plan_attachments',
  CanReadReports = 'can_read_reports',
  CanReadUsers = 'can_read_users',
  /** can update a clinics settings */
  CanUpdateClinicDetails = 'can_update_clinic_details',
  /** user can update other users clinic level permissions */
  CanUpdateOtherUserClinicPermissions = 'can_update_other_user_clinic_permissions',
  /** user can update other users permisions group */
  CanUpdateOtherUserPermissions = 'can_update_other_user_permissions',
  CanUpdatePatientProfile = 'can_update_patient_profile',
  /** user can update a permission groups settings */
  CanUpdatePermissions = 'can_update_permissions',
  /** can update a state's details */
  CanUpdateStateDetails = 'can_update_state_details',
  CanUploadPlans = 'can_upload_plans',
}

export type ActivityLog = {
  __typename?: 'ActivityLog'
  clinicId?: Maybe<Scalars['String']>
  content?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  importance?: Maybe<Scalars['Int']>
  key?: Maybe<ActivityLogTypeEnum>
  patientId?: Maybe<Scalars['String']>
  userId?: Maybe<Scalars['String']>
}

export enum ActivityLogKeyEnum {
  AdminCreatedAccount = 'ADMIN_CREATED_ACCOUNT',
  AdminDeletedDocument = 'ADMIN_DELETED_DOCUMENT',
  AdminUploadedDocument = 'ADMIN_UPLOADED_DOCUMENT',
  ClickedNoAgeSpecificStat = 'CLICKED_NO_AGE_SPECIFIC_STAT',
  ClickedScheduleInterview = 'CLICKED_SCHEDULE_INTERVIEW',
  ClickedYesAgeSpecificStat = 'CLICKED_YES_AGE_SPECIFIC_STAT',
  ClosedSaveAndExit = 'CLOSED_SAVE_AND_EXIT',
  CompletedPostassesmentSurvey = 'COMPLETED_POSTASSESMENT_SURVEY',
  CompletedPreassesmentSurvey = 'COMPLETED_PREASSESMENT_SURVEY',
  CompletedSurvey = 'COMPLETED_SURVEY',
  EmailDirective = 'EMAIL_DIRECTIVE',
  EsignComplete = 'ESIGN_COMPLETE',
  FirstLogin = 'FIRST_LOGIN',
  GeneratedAd = 'GENERATED_AD',
  GuestSignupEmailSent = 'GUEST_SIGNUP_EMAIL_SENT',
  InactiveLogout = 'INACTIVE_LOGOUT',
  Login = 'LOGIN',
  LoginThroughEmail = 'LOGIN_THROUGH_EMAIL',
  OpenedSaveAndExit = 'OPENED_SAVE_AND_EXIT',
  PatientClickedAgeSpecificData = 'PATIENT_CLICKED_AGE_SPECIFIC_DATA',
  PatientClickedOtherAgeRangesData = 'PATIENT_CLICKED_OTHER_AGE_RANGES_DATA',
  PatientDeletedDocument = 'PATIENT_DELETED_DOCUMENT',
  PatientSubmittedHelpForm = 'PATIENT_SUBMITTED_HELP_FORM',
  PatientSubmittedMpoReadinessSurvey = 'PATIENT_SUBMITTED_MPO_READINESS_SURVEY',
  PatientUploadedDocument = 'PATIENT_UPLOADED_DOCUMENT',
  ReadyForEsign = 'READY_FOR_ESIGN',
  SaveAndExit = 'SAVE_AND_EXIT',
  SendFax = 'SEND_FAX',
  SentMdmEmailWithPersonalMessage = 'SENT_MDM_EMAIL_WITH_PERSONAL_MESSAGE',
  Signup = 'SIGNUP',
  SurveyNextStep = 'SURVEY_NEXT_STEP',
  ViewedAcpSchedulingPage = 'VIEWED_ACP_SCHEDULING_PAGE',
  WelcomeEmailResent = 'WELCOME_EMAIL_RESENT',
}

export enum ActivityLogTypeEnum {
  ClosedSaveAndExit = 'CLOSED_SAVE_AND_EXIT',
  CompletedSurvey = 'COMPLETED_SURVEY',
  EmailDirective = 'EMAIL_DIRECTIVE',
  GeneratedAd = 'GENERATED_AD',
  GuestSignupEmailSent = 'GUEST_SIGNUP_EMAIL_SENT',
  InactiveLogout = 'INACTIVE_LOGOUT',
  OpenedSaveAndExit = 'OPENED_SAVE_AND_EXIT',
  SaveAndExit = 'SAVE_AND_EXIT',
  SurveyNextStep = 'SURVEY_NEXT_STEP',
}

export type AuthenticateParamsInput = {
  access_token?: InputMaybe<Scalars['String']>
  access_token_secret?: InputMaybe<Scalars['String']>
  code?: InputMaybe<Scalars['String']>
  password?: InputMaybe<Scalars['String']>
  provider?: InputMaybe<Scalars['String']>
  token?: InputMaybe<Scalars['String']>
  user?: InputMaybe<UserInput>
}

export type Clinic = {
  __typename?: 'Clinic'
  city?: Maybe<Scalars['String']>
  contact?: Maybe<Contact>
  createdAt?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  faxNumber?: Maybe<Scalars['String']>
  guideTemplate?: Maybe<GuideTemplate>
  guideTemplateId?: Maybe<Scalars['ID']>
  id: Scalars['ID']
  integrations?: Maybe<ClinicIntegrations>
  logo?: Maybe<Scalars['String']>
  postal?: Maybe<Scalars['String']>
  slug?: Maybe<Scalars['String']>
  startVideo?: Maybe<Scalars['String']>
  state?: Maybe<State>
  stateId: Scalars['String']
  street?: Maybe<Scalars['String']>
  street2?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['String']>
  v2Signup?: Maybe<Scalars['Boolean']>
}

export type ClinicDataCsv = {
  __typename?: 'ClinicDataCSV'
  altDecisionMakerFirstName?: Maybe<Scalars['String']>
  altDecisionMakerLastName?: Maybe<Scalars['String']>
  birthday?: Maybe<Scalars['String']>
  cprAffectPeopleAgeGroup?: Maybe<Scalars['Boolean']>
  cprAgeGroupVideo?: Maybe<Scalars['Boolean']>
  cprPreference?: Maybe<Scalars['String']>
  cprPreferenceToday?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['String']>
  decisionMakerEmail?: Maybe<Scalars['String']>
  decisionMakerFirstName?: Maybe<Scalars['String']>
  decisionMakerLastName?: Maybe<Scalars['String']>
  decisionMakerName?: Maybe<Scalars['String']>
  decisionMakerPhone?: Maybe<Scalars['String']>
  decisionMakerRelationship?: Maybe<Scalars['String']>
  decisionMakerStrictness?: Maybe<Scalars['String']>
  dialysisTreatment?: Maybe<Scalars['String']>
  dialysisTreatmentToday?: Maybe<Scalars['String']>
  doYouKnowDecisionMaker?: Maybe<Scalars['String']>
  eSignStarted?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  endTime?: Maybe<Scalars['String']>
  firstLogin?: Maybe<Scalars['String']>
  firstName?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  importantToMe?: Maybe<Array<Maybe<Scalars['String']>>>
  lastLocation?: Maybe<Array<Maybe<Scalars['String']>>>
  lastLogin?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  likeToKnowCprAgeGroup?: Maybe<Scalars['Boolean']>
  livingSituation?: Maybe<Scalars['String']>
  longTermFeeding?: Maybe<Scalars['String']>
  longTermFeedingToday?: Maybe<Scalars['String']>
  meaningOfLife?: Maybe<Scalars['Int']>
  notWorthLiving?: Maybe<Array<Maybe<Scalars['String']>>>
  notWorthLiving2?: Maybe<Array<Maybe<Scalars['String']>>>
  notWorthLiving3?: Maybe<Array<Maybe<Scalars['String']>>>
  notaryInfo?: Maybe<Scalars['String']>
  personaHealthcareExperience?: Maybe<Scalars['Int']>
  personaSocialTies?: Maybe<Scalars['Int']>
  personaVacation?: Maybe<Scalars['Int']>
  sharedWithDecisionMaker?: Maybe<Scalars['String']>
  startTime?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['String']>
  userId?: Maybe<Scalars['String']>
  ventilationPreference?: Maybe<Scalars['String']>
  ventilationPreferenceToday?: Maybe<Scalars['String']>
  wantsAltDecisionMaker?: Maybe<Scalars['String']>
  whenCanDecisionMakerDecide?: Maybe<Scalars['String']>
}

export type ClinicIntegrations = {
  __typename?: 'ClinicIntegrations'
  freshworksCrm?: Maybe<Scalars['Boolean']>
  freshworksCrmId?: Maybe<Scalars['String']>
  freshworksSalesAccountId?: Maybe<Scalars['String']>
}

export type ClinicIntegrationsInput = {
  freshworksCrm?: InputMaybe<Scalars['Boolean']>
  freshworksCrmId?: InputMaybe<Scalars['String']>
  freshworksSalesAccountId?: InputMaybe<Scalars['String']>
}

export enum ClinicModuleEnum {
  MedicalCare = 'MEDICAL_CARE',
  MedicalDecisionMaker = 'MEDICAL_DECISION_MAKER',
  PostApcSurvey = 'POST_APC_SURVEY',
  PreSurvey = 'PRE_SURVEY',
  QualityOfLife = 'QUALITY_OF_LIFE',
  SchedulingAcp = 'SCHEDULING_ACP',
  SupportiveCare = 'SUPPORTIVE_CARE',
  Values = 'VALUES',
}

export type ClinicParams = {
  city?: InputMaybe<Scalars['String']>
  contact?: InputMaybe<ContactInput>
  createdAt?: InputMaybe<Scalars['String']>
  description?: InputMaybe<Scalars['String']>
  faxNumber?: InputMaybe<Scalars['String']>
  guideTemplateId?: InputMaybe<Scalars['ID']>
  integrations?: InputMaybe<ClinicIntegrationsInput>
  logo?: InputMaybe<Scalars['String']>
  postal?: InputMaybe<Scalars['String']>
  slug?: InputMaybe<Scalars['String']>
  startVideo?: InputMaybe<Scalars['String']>
  state?: InputMaybe<Scalars['String']>
  stateId?: InputMaybe<Scalars['String']>
  street?: InputMaybe<Scalars['String']>
  street2?: InputMaybe<Scalars['String']>
  title?: InputMaybe<Scalars['String']>
  updatedAt?: InputMaybe<Scalars['String']>
}

export type ClinicStats = {
  __typename?: 'ClinicStats'
  accountsCreated?: Maybe<Scalars['String']>
  avgTimeSpent?: Maybe<Scalars['String']>
  mdmSelected?: Maybe<PercentAbsolute>
  mdmShared?: Maybe<PercentAbsolute>
  nps?: Maybe<Scalars['String']>
}

export type ClinicsResponse = {
  __typename?: 'ClinicsResponse'
  /** An array of Clinic objects */
  clinics?: Maybe<Array<Maybe<Clinic>>>
  /** The total number of results your query returned. Used for pagination. */
  count?: Maybe<Scalars['Int']>
}

export type Contact = {
  __typename?: 'Contact'
  email?: Maybe<Scalars['String']>
  firstName?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
}

export type ContactInput = {
  email?: InputMaybe<Scalars['String']>
  firstName?: InputMaybe<Scalars['String']>
  lastName?: InputMaybe<Scalars['String']>
  phone?: InputMaybe<Scalars['String']>
}

export type CreateUserInput = {
  email?: InputMaybe<Scalars['String']>
  password?: InputMaybe<Scalars['String']>
  username?: InputMaybe<Scalars['String']>
}

export type CreateUserResult = {
  __typename?: 'CreateUserResult'
  loginResult?: Maybe<LoginResult>
  userId?: Maybe<Scalars['ID']>
}

export enum DecisionMakerRelationshipEnum {
  BrotherOrSister = 'brotherOrSister',
  Child = 'child',
  Friend = 'friend',
  Other = 'other',
  OtherFamily = 'otherFamily',
  Spouse = 'spouse',
}

export enum DecisionMakerStrictnessEnum {
  Flexibility = 'flexibility',
  NoFlexibility = 'noFlexibility',
}

export enum DialysisTreatmentEnum {
  AnyLengthOfTime = 'anyLengthOfTime',
  NoneAtAll = 'noneAtAll',
  NotSure = 'notSure',
  Trial = 'trial',
}

export enum DialysisTreatmentTodayEnum {
  AnyLengthOfTime = 'anyLengthOfTime',
  NoneAtAll = 'noneAtAll',
  NotSure = 'notSure',
  OnlyIf = 'onlyIf',
}

export enum DoOnYourOwnEnum {
  DriveUsePublicTransportation = 'driveUsePublicTransportation',
  HandleFinances = 'handleFinances',
  Housework = 'housework',
  MakeMeals = 'makeMeals',
  None = 'none',
  ShopForGroceries = 'shopForGroceries',
  UseTheThelephone = 'useTheThelephone',
}

export enum DoOnYourOwnWithoutHelpEnum {
  Bath = 'bath',
  Dress = 'dress',
  Eat = 'eat',
  None = 'none',
  TransferInOutOfChair = 'transferInOutOfChair',
  UseTheRestroom = 'useTheRestroom',
  Walk = 'walk',
}

export type EmailRecord = {
  __typename?: 'EmailRecord'
  address?: Maybe<Scalars['String']>
  verified?: Maybe<Scalars['Boolean']>
}

export type ExistsCheckResponse = {
  __typename?: 'ExistsCheckResponse'
  errors?: Maybe<Array<Maybe<Scalars['String']>>>
  exists?: Maybe<Scalars['Boolean']>
}

export type FaxSent = {
  __typename?: 'FaxSent'
  dateSent?: Maybe<Scalars['String']>
  faxToNumber?: Maybe<Scalars['String']>
  sentByUserId?: Maybe<Scalars['String']>
}

export type FindResetResponse = {
  __typename?: 'FindResetResponse'
  agreeToTerms?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
}

export enum GuideQuestionIdEnum {
  ThirdDecisionMakerName = 'ThirdDecisionMakerName',
  AdditionalCarePreferences = 'additionalCarePreferences',
  AdditionalMdmDirections = 'additionalMdmDirections',
  AdvanceCarePlanningKnowledge = 'advanceCarePlanningKnowledge',
  AdvanceCarePlanningReadiness = 'advanceCarePlanningReadiness',
  AffordMedications = 'affordMedications',
  AltDecisionMakerAddress = 'altDecisionMakerAddress',
  AltDecisionMakerAddressCity = 'altDecisionMakerAddressCity',
  AltDecisionMakerAddressState = 'altDecisionMakerAddressState',
  AltDecisionMakerAddressStreet = 'altDecisionMakerAddressStreet',
  AltDecisionMakerAddressZip = 'altDecisionMakerAddressZip',
  AltDecisionMakerContact = 'altDecisionMakerContact',
  AltDecisionMakerEmail = 'altDecisionMakerEmail',
  AltDecisionMakerFirstName = 'altDecisionMakerFirstName',
  AltDecisionMakerLastName = 'altDecisionMakerLastName',
  AltDecisionMakerName = 'altDecisionMakerName',
  AltDecisionMakerPhone = 'altDecisionMakerPhone',
  AltDecisionMakerRelationship = 'altDecisionMakerRelationship',
  AltDecisionMakerRelationshipOther = 'altDecisionMakerRelationshipOther',
  BreakEncourageQol = 'breakEncourageQOL',
  CprAffectPeopleAgeGroup = 'cprAffectPeopleAgeGroup',
  CprAgeGroupVideo = 'cprAgeGroupVideo',
  CprPreference = 'cprPreference',
  CprPreferenceToday = 'cprPreferenceToday',
  DecisionMakerAddress = 'decisionMakerAddress',
  DecisionMakerAddressCity = 'decisionMakerAddressCity',
  DecisionMakerAddressState = 'decisionMakerAddressState',
  DecisionMakerAddressStreet = 'decisionMakerAddressStreet',
  DecisionMakerAddressZip = 'decisionMakerAddressZip',
  DecisionMakerContact = 'decisionMakerContact',
  DecisionMakerEmail = 'decisionMakerEmail',
  DecisionMakerFirstName = 'decisionMakerFirstName',
  DecisionMakerLastName = 'decisionMakerLastName',
  DecisionMakerName = 'decisionMakerName',
  DecisionMakerPhone = 'decisionMakerPhone',
  DecisionMakerRelationship = 'decisionMakerRelationship',
  DecisionMakerRelationshipOther = 'decisionMakerRelationshipOther',
  DecisionMakerStrictness = 'decisionMakerStrictness',
  DialysisTreatment = 'dialysisTreatment',
  DialysisTreatmentToday = 'dialysisTreatmentToday',
  DoYouKnowDecisionMaker = 'doYouKnowDecisionMaker',
  HasPlanViewed = 'hasPlanViewed',
  HealthRisk = 'healthRisk',
  HospiceExplainer = 'hospiceExplainer',
  ImportantToMe = 'importantToMe',
  ImportantToMeOther = 'importantToMeOther',
  InterestedInHospiceCare = 'interestedInHospiceCare',
  InterestedInPalliativeCare = 'interestedInPalliativeCare',
  LastLocation = 'lastLocation',
  LearnAboutArtificialNutrition = 'learnAboutArtificialNutrition',
  LearnAboutCpr = 'learnAboutCPR',
  LearnAboutDialysis = 'learnAboutDialysis',
  LearnAboutMechanicalVent = 'learnAboutMechanicalVent',
  LifeAndValues = 'lifeAndValues',
  LifeSustainingProcedures = 'lifeSustainingProcedures',
  LifeSustainingProceduresOther = 'lifeSustainingProceduresOther',
  LikeToKnowCprAgeGroup = 'likeToKnowCprAgeGroup',
  LivingSituation = 'livingSituation',
  LivingSituationOther = 'livingSituationOther',
  LongTermFeeding = 'longTermFeeding',
  LongTermFeedingToday = 'longTermFeedingToday',
  MeaningOfLife = 'meaningOfLife',
  MedicalCareScenario1 = 'medicalCareScenario1',
  MedicalCareScenario2 = 'medicalCareScenario2',
  MedicalCareScenario3 = 'medicalCareScenario3',
  MedicalCareScenariosIntro = 'medicalCareScenariosIntro',
  MedicalCareScenariosVideo = 'medicalCareScenariosVideo',
  NotWorthLiving = 'notWorthLiving',
  NotWorthLiving2 = 'notWorthLiving2',
  NotWorthLiving3 = 'notWorthLiving3',
  PalliativeExplainer = 'palliativeExplainer',
  PersonaSocialTies = 'personaSocialTies',
  PersonaVacation = 'personaVacation',
  PostAcpAdvanceCarePlanningKnowledge = 'postAcpAdvanceCarePlanningKnowledge',
  PostAcpAdvanceCarePlanningReadiness = 'postAcpAdvanceCarePlanningReadiness',
  PostAcpContentPreferencesAssessment = 'postAcpContentPreferencesAssessment',
  PostAcpDigitalAdvanceCarePlanning = 'postAcpDigitalAdvanceCarePlanning',
  PostAcpSystemUsabilityScale = 'postAcpSystemUsabilityScale',
  ReceiveCareOther = 'receiveCareOther',
  SchedulingAcPinformation = 'schedulingACPinformation',
  SpiritualAndReligiousBeliefs = 'spiritualAndReligiousBeliefs',
  ThirdDecisionMakerAddress = 'thirdDecisionMakerAddress',
  ThirdDecisionMakerAddressCity = 'thirdDecisionMakerAddressCity',
  ThirdDecisionMakerAddressState = 'thirdDecisionMakerAddressState',
  ThirdDecisionMakerAddressStreet = 'thirdDecisionMakerAddressStreet',
  ThirdDecisionMakerAddressZip = 'thirdDecisionMakerAddressZip',
  ThirdDecisionMakerContact = 'thirdDecisionMakerContact',
  ThirdDecisionMakerEmail = 'thirdDecisionMakerEmail',
  ThirdDecisionMakerFirstName = 'thirdDecisionMakerFirstName',
  ThirdDecisionMakerLastName = 'thirdDecisionMakerLastName',
  ThirdDecisionMakerPhone = 'thirdDecisionMakerPhone',
  ThirdDecisionMakerRelationship = 'thirdDecisionMakerRelationship',
  ThirdDecisionMakerRelationshipOther = 'thirdDecisionMakerRelationshipOther',
  VentilationPreference = 'ventilationPreference',
  VentilationPreferenceToday = 'ventilationPreferenceToday',
  VideoAd = 'videoAD',
  VideoCpr = 'videoCPR',
  VideoDialysis = 'videoDialysis',
  VideoFeedingTube = 'videoFeedingTube',
  VideoHospice = 'videoHospice',
  VideoLifeSupport = 'videoLifeSupport',
  VideoMedicalDecisionMaker = 'videoMedicalDecisionMaker',
  VideoPalliative = 'videoPalliative',
  VideoQol = 'videoQOL',
  VideoVentilation = 'videoVentilation',
  WantsAltDecisionMaker = 'wantsAltDecisionMaker',
  WantsThirdDecisionMaker = 'wantsThirdDecisionMaker',
  WhenCanDecisionMakerDecide = 'whenCanDecisionMakerDecide',
}

export type GuideTemplate = {
  __typename?: 'GuideTemplate'
  id?: Maybe<Scalars['String']>
  isPlatformDefault?: Maybe<Scalars['Boolean']>
  name?: Maybe<Scalars['String']>
  sections: Array<GuideTemplateSection>
}

export type GuideTemplateSection = {
  __typename?: 'GuideTemplateSection'
  activeStepText?: Maybe<Scalars['String']>
  questionIds: Array<GuideQuestionIdEnum>
  required: Scalars['Boolean']
  sectionIcon?: Maybe<Scalars['String']>
  sectionId: GuideTemplateSectionIdEnum
  sectionName: Scalars['String']
}

export enum GuideTemplateSectionIdEnum {
  MedicalCare = 'MEDICAL_CARE',
  MedicalDecisionMaker = 'MEDICAL_DECISION_MAKER',
  PostAcpSurvey = 'POST_ACP_SURVEY',
  PreSurvey = 'PRE_SURVEY',
  QualityOfLife = 'QUALITY_OF_LIFE',
  SchedulingAcp = 'SCHEDULING_ACP',
  SupportiveCare = 'SUPPORTIVE_CARE',
  Values = 'VALUES',
}

export enum HealthIsEnum {
  Excellent = 'excellent',
  Fair = 'fair',
  Good = 'good',
  Poor = 'poor',
  VeryGood = 'veryGood',
}

export type HipaaLog = {
  __typename?: 'HipaaLog'
  collectionName?: Maybe<HipaaLogCollectionTypesEnum>
  createdAt?: Maybe<Scalars['String']>
  eventType?: Maybe<HipaaLogEventTypesEnum>
  id: Scalars['String']
  note?: Maybe<Scalars['String']>
  patientName?: Maybe<Scalars['String']>
  user?: Maybe<UserProfile>
  userFullname?: Maybe<Scalars['String']>
  userName?: Maybe<Scalars['String']>
}

export enum HipaaLogCollectionTypesEnum {
  ActivityLogs = 'ActivityLogs',
  Clinics = 'Clinics',
  CronJobLogs = 'CronJobLogs',
  CronJobs = 'CronJobs',
  HipaaLogs = 'HipaaLogs',
  NetScores = 'NetScores',
  Patients = 'Patients',
  Permissions = 'Permissions',
  PlanAttachments = 'PlanAttachments',
  Users = 'Users',
}

export enum HipaaLogEventTypesEnum {
  Create = 'create',
  Delete = 'delete',
  Denied = 'denied',
  Failed = 'failed',
  FailedLogin = 'failedLogin',
  Init = 'init',
  Read = 'read',
  System = 'system',
  Update = 'update',
}

export type HippaaLogsResponse = {
  __typename?: 'HippaaLogsResponse'
  count?: Maybe<Scalars['Int']>
  logs?: Maybe<Array<HipaaLog>>
}

export type ImpersonateReturn = {
  __typename?: 'ImpersonateReturn'
  authorized?: Maybe<Scalars['Boolean']>
  tokens?: Maybe<Tokens>
  user?: Maybe<User>
}

export type ImpersonationUserIdentityInput = {
  email?: InputMaybe<Scalars['String']>
  userId?: InputMaybe<Scalars['String']>
  username?: InputMaybe<Scalars['String']>
}

export enum ImportantToMeEnum {
  Career = 'career',
  Family = 'family',
  Friends = 'friends',
  Hobbies = 'hobbies',
  Independence = 'independence',
  Other = 'other',
  Pets = 'pets',
  Religion = 'religion',
}

export type InvitePatientInput = {
  birthday: Scalars['String']
  clinicId: Scalars['String']
  email: Scalars['String']
  externalId?: InputMaybe<Scalars['String']>
  firstName: Scalars['String']
  /** optional to add a freshworkdId field */
  freshworksId?: InputMaybe<Scalars['String']>
  lastName: Scalars['String']
  /** MM/DD/YYYY format */
  referralDate: Scalars['String']
  stateId?: InputMaybe<Scalars['String']>
}

export enum LangTypeEnum {
  En = 'en',
  Es = 'es',
}

export enum LastLocationEnum {
  CareFacility = 'careFacility',
  DoesntMatter = 'doesntMatter',
  Home = 'home',
  Hospital = 'hospital',
  NotSure = 'notSure',
}

export enum LivingSituationEnum {
  Alone = 'alone',
  AssistedLiving = 'assistedLiving',
  IndependentLiving = 'independentLiving',
  NursingHome = 'nursingHome',
  Other = 'other',
  WithOthers = 'withOthers',
}

/** Overwrite accountsjs login result to do our own custom login */
export type LoginResponse = {
  __typename?: 'LoginResponse'
  mfaRequired?: Maybe<Scalars['Boolean']>
  sessionId?: Maybe<Scalars['String']>
  tokens?: Maybe<Tokens>
  user?: Maybe<User>
}

export type LoginResult = {
  __typename?: 'LoginResult'
  sessionId?: Maybe<Scalars['String']>
  tokens?: Maybe<Tokens>
  user?: Maybe<User>
}

export enum LongTermFeedingEnum {
  AlwaysWant = 'alwaysWant',
  NoneAtAll = 'noneAtAll',
  NotSure = 'notSure',
  OnlyIf = 'onlyIf',
}

export enum LongTermFeedingTodayEnum {
  AlwaysWant = 'alwaysWant',
  NoneAtAll = 'noneAtAll',
  NotSure = 'notSure',
  OnlyIf = 'onlyIf',
}

export enum MedicalAttorneyLivingWillAdvanceDirectiveEnum {
  AdvanceDirective = 'advanceDirective',
  DontKnow = 'dontKnow',
  FilledOutDocuments = 'filledOutDocuments',
  HaventDone = 'haventDone',
  LivingWill = 'livingWill',
  Mpoa = 'mpoa',
}

export type MonthYear = {
  __typename?: 'MonthYear'
  week?: Maybe<Scalars['String']>
  year?: Maybe<Scalars['String']>
}

export enum MultipartUploadCompleteEnum {
  Abort = 'abort',
  Complete = 'complete',
}

export type MultipartUploadPartInput = {
  ETag: Scalars['String']
  PartNumber: Scalars['Int']
}

export type Mutation = {
  __typename?: 'Mutation'
  addEmail?: Maybe<Scalars['Boolean']>
  /** Allows a logged in patient to agree to terms */
  agreeToKodaTerms?: Maybe<MutationResponse>
  authenticate?: Maybe<LoginResult>
  changePassword?: Maybe<Scalars['Boolean']>
  /** Called when completing an esign session */
  completeESign?: Maybe<Patient>
  completeMultiUpload?: Maybe<MutationResponse>
  /** Add an email and password to a guest account (must already be logged in as a guest) */
  createAccountFromGuest?: Maybe<MutationResponse>
  /** Create an admin user */
  createAdminUser?: Maybe<UserProfile>
  createClinic?: Maybe<Clinic>
  createClinicUser?: Maybe<UserProfile>
  createOneActionHistoryItem?: Maybe<Patient>
  createOneReadinessMPOResponse?: Maybe<Patient>
  createPermission?: Maybe<Permission>
  createUser?: Maybe<CreateUserResult>
  deleteAdminUser?: Maybe<MutationResponse>
  /** Will delete a patient record. Super admin only. */
  deletePatientUser?: Maybe<MutationResponse>
  /** Pass in a file ID to delete it from the DB */
  deletePlanAttachment?: Maybe<MutationResponse>
  emailDirective?: Maybe<MutationResponse>
  faxClinic?: Maybe<MutationResponse>
  /** admins only */
  generatePatientDownloadUrl?: Maybe<Scalars['String']>
  getPresignedUploadMultiPart?: Maybe<PresignedMultipartResult>
  getPresignedUploadUrl?: Maybe<PresignedUploadResponse>
  impersonate?: Maybe<ImpersonateReturn>
  inviteManyPatients?: Maybe<MutationResponse>
  inviteOnePatient?: Maybe<Patient>
  logActivityItem?: Maybe<MutationResponse>
  logEvent?: Maybe<MutationResponse>
  logWakeForestEvent?: Maybe<WakeForestEvent>
  login?: Maybe<LoginResponse>
  logout?: Maybe<Scalars['Boolean']>
  /** Used to create a new survey/patient and a new user AND will log them in */
  newPatientSignup?: Maybe<NewPatientResponse>
  refreshTokens?: Maybe<LoginResult>
  resendMfa?: Maybe<MutationResponse>
  resetPassword?: Maybe<LoginResult>
  revertEsign?: Maybe<Patient>
  saveClinic?: Maybe<Clinic>
  saveGuestEmail?: Maybe<MutationResponse>
  /** Save data on a user account. Pass in the id of user you want to update. */
  savePatientProfile?: Maybe<UserProfile>
  saveState?: Maybe<State>
  /** Will send the email for a guest to complete signup */
  sendGuestSignupEmail?: Maybe<MutationResponse>
  sendResetPasswordEmail?: Maybe<Scalars['Boolean']>
  sendSMS?: Maybe<MutationResponse>
  sendVerificationEmail?: Maybe<Scalars['Boolean']>
  sendWelcomeEmail?: Maybe<MutationResponse>
  submitHelpForm?: Maybe<MutationResponse>
  /** Used to submit a Net Promoter Score. User must be logged in. */
  submitNetScore?: Maybe<MutationResponse>
  twoFactorSet?: Maybe<Scalars['Boolean']>
  twoFactorUnset?: Maybe<Scalars['Boolean']>
  updateOnePatientGuideTemplate?: Maybe<Patient>
  updatePatient?: Maybe<Patient>
  updatePermission?: Maybe<Permission>
  updateUserClinicPermissions?: Maybe<UserProfile>
  updateUserPermission?: Maybe<UserProfile>
  /** Upload a file to save it to the DB */
  uploadPlanAttachment?: Maybe<MutationResponse>
  verifyAuthentication?: Maybe<Scalars['Boolean']>
  verifyEmail?: Maybe<Scalars['Boolean']>
  verifyMfa?: Maybe<LoginResponse>
}

export type MutationAddEmailArgs = {
  newEmail: Scalars['String']
}

export type MutationAuthenticateArgs = {
  params: AuthenticateParamsInput
  serviceName: Scalars['String']
}

export type MutationChangePasswordArgs = {
  newPassword: Scalars['String']
  oldPassword: Scalars['String']
}

export type MutationCompleteESignArgs = {
  params: PlanSignaturesInput
  patientId: Scalars['ID']
  s3Key: Scalars['String']
}

export type MutationCompleteMultiUploadArgs = {
  key: Scalars['String']
  parts?: InputMaybe<Array<MultipartUploadPartInput>>
  type: MultipartUploadCompleteEnum
  uploadId: Scalars['String']
}

export type MutationCreateAccountFromGuestArgs = {
  email: Scalars['String']
  firstName: Scalars['String']
  lastName: Scalars['String']
  password: Scalars['String']
}

export type MutationCreateAdminUserArgs = {
  clinicIds: Array<InputMaybe<Scalars['String']>>
  email: Scalars['String']
  firstName?: InputMaybe<Scalars['String']>
  lastName?: InputMaybe<Scalars['String']>
  permissionId: Scalars['ID']
}

export type MutationCreateClinicArgs = {
  params?: InputMaybe<ClinicParams>
}

export type MutationCreateClinicUserArgs = {
  clinicId: Scalars['String']
  email: Scalars['String']
  firstName?: InputMaybe<Scalars['String']>
  lastName?: InputMaybe<Scalars['String']>
  permissionId: Scalars['ID']
}

export type MutationCreateOneActionHistoryItemArgs = {
  name: PatientActionHistoryItemEnum
  value?: InputMaybe<Scalars['String']>
}

export type MutationCreateOneReadinessMpoResponseArgs = {
  answer: SubmitReadinessMpoResponseEnum
  otherAnswer?: InputMaybe<Scalars['String']>
}

export type MutationCreatePermissionArgs = {
  accesses: Array<InputMaybe<Scalars['String']>>
  description: Scalars['String']
  title: Scalars['String']
}

export type MutationCreateUserArgs = {
  user: CreateUserInput
}

export type MutationDeleteAdminUserArgs = {
  id: Scalars['ID']
}

export type MutationDeletePatientUserArgs = {
  patientId: Scalars['ID']
}

export type MutationDeletePlanAttachmentArgs = {
  id: Scalars['ID']
}

export type MutationEmailDirectiveArgs = {
  decisionMakerEmail?: InputMaybe<Scalars['String']>
  email?: InputMaybe<Scalars['String']>
  otherEmails?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  personalNote?: InputMaybe<Scalars['String']>
}

export type MutationFaxClinicArgs = {
  base64String: Scalars['String']
  clinicId: Scalars['ID']
  documentType: Scalars['String']
  faxToNumber: Scalars['String']
  patientId: Scalars['ID']
}

export type MutationGeneratePatientDownloadUrlArgs = {
  patientId: Scalars['ID']
}

export type MutationGetPresignedUploadMultiPartArgs = {
  contentType: Scalars['String']
  parts: Scalars['Int']
}

export type MutationGetPresignedUploadUrlArgs = {
  contentType: Scalars['String']
}

export type MutationImpersonateArgs = {
  accessToken: Scalars['String']
  impersonated: ImpersonationUserIdentityInput
}

export type MutationInviteManyPatientsArgs = {
  clinicId: Scalars['ID']
  patients: Array<InvitePatientInput>
}

export type MutationInviteOnePatientArgs = {
  birthday: Scalars['String']
  clinicId: Scalars['String']
  email: Scalars['String']
  firstName: Scalars['String']
  freshworksId?: InputMaybe<Scalars['String']>
  lastName: Scalars['String']
  referralDate: Scalars['String']
  stateId?: InputMaybe<Scalars['String']>
}

export type MutationLogActivityItemArgs = {
  clinicId?: InputMaybe<Scalars['String']>
  content?: InputMaybe<Scalars['String']>
  importance?: InputMaybe<Scalars['Int']>
  key: ActivityLogKeyEnum
  meta?: InputMaybe<Scalars['String']>
  patientId?: InputMaybe<Scalars['String']>
  userId: Scalars['String']
}

export type MutationLogEventArgs = {
  eventName: Scalars['String']
  metaData?: InputMaybe<Scalars['String']>
  mixpanelDistinctId?: InputMaybe<Scalars['String']>
}

export type MutationLogWakeForestEventArgs = {
  clinicSlug?: InputMaybe<Scalars['String']>
  content?: InputMaybe<Scalars['String']>
  externalId?: InputMaybe<Scalars['String']>
  key?: InputMaybe<Scalars['String']>
  patientId?: InputMaybe<Scalars['String']>
  userId?: InputMaybe<Scalars['String']>
}

export type MutationLoginArgs = {
  params: AuthenticateParamsInput
}

export type MutationNewPatientSignupArgs = {
  birthday?: InputMaybe<Scalars['String']>
  email?: InputMaybe<Scalars['String']>
  externalId?: InputMaybe<Scalars['String']>
  firstName?: InputMaybe<Scalars['String']>
  lastName?: InputMaybe<Scalars['String']>
  password?: InputMaybe<Scalars['String']>
  phoneNumber?: InputMaybe<Scalars['String']>
  slug: Scalars['String']
  stateId?: InputMaybe<Scalars['String']>
}

export type MutationRefreshTokensArgs = {
  accessToken: Scalars['String']
  refreshToken: Scalars['String']
}

export type MutationResendMfaArgs = {
  email: Scalars['String']
}

export type MutationResetPasswordArgs = {
  newPassword: Scalars['String']
  token: Scalars['String']
}

export type MutationRevertEsignArgs = {
  params: PatientInput
}

export type MutationSaveClinicArgs = {
  id?: InputMaybe<Scalars['ID']>
  params?: InputMaybe<ClinicParams>
}

export type MutationSaveGuestEmailArgs = {
  email: Scalars['String']
}

export type MutationSavePatientProfileArgs = {
  params?: InputMaybe<UserParams>
  userId: Scalars['ID']
}

export type MutationSaveStateArgs = {
  id: Scalars['ID']
  params?: InputMaybe<StateInput>
}

export type MutationSendGuestSignupEmailArgs = {
  email: Scalars['String']
  userId: Scalars['ID']
}

export type MutationSendResetPasswordEmailArgs = {
  email: Scalars['String']
}

export type MutationSendSmsArgs = {
  body: Scalars['String']
  to: Scalars['String']
}

export type MutationSendVerificationEmailArgs = {
  email: Scalars['String']
}

export type MutationSendWelcomeEmailArgs = {
  patientId: Scalars['String']
}

export type MutationSubmitHelpFormArgs = {
  message: Scalars['String']
  subject?: InputMaybe<Scalars['String']>
}

export type MutationSubmitNetScoreArgs = {
  score: Scalars['Int']
}

export type MutationTwoFactorSetArgs = {
  code: Scalars['String']
  secret: TwoFactorSecretKeyInput
}

export type MutationTwoFactorUnsetArgs = {
  code: Scalars['String']
}

export type MutationUpdateOnePatientGuideTemplateArgs = {
  guideTemplateId: Scalars['ID']
  patientId: Scalars['ID']
}

export type MutationUpdatePatientArgs = {
  params: PatientInput
  patientId: Scalars['ID']
}

export type MutationUpdatePermissionArgs = {
  accessIds: Array<InputMaybe<Scalars['ID']>>
  permissionId: Scalars['ID']
}

export type MutationUpdateUserClinicPermissionsArgs = {
  clinicIds: Array<InputMaybe<Scalars['ID']>>
  userId: Scalars['ID']
}

export type MutationUpdateUserPermissionArgs = {
  permissionId: Scalars['ID']
  userId: Scalars['ID']
}

export type MutationUploadPlanAttachmentArgs = {
  mimetype: Scalars['String']
  name?: InputMaybe<Scalars['String']>
  patientId?: InputMaybe<Scalars['ID']>
  s3Key?: InputMaybe<Scalars['String']>
}

export type MutationVerifyAuthenticationArgs = {
  params: AuthenticateParamsInput
  serviceName: Scalars['String']
}

export type MutationVerifyEmailArgs = {
  token: Scalars['String']
}

export type MutationVerifyMfaArgs = {
  email: Scalars['String']
  pin: Scalars['String']
}

export type MutationResponse = {
  __typename?: 'MutationResponse'
  errors?: Maybe<Array<Scalars['String']>>
  message?: Maybe<Scalars['String']>
  success?: Maybe<Scalars['Boolean']>
}

export type NetScore = {
  __typename?: 'NetScore'
  clinic?: Maybe<Clinic>
  createdAt?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  score?: Maybe<Scalars['Int']>
  updatedAt?: Maybe<Scalars['String']>
  userId?: Maybe<Scalars['String']>
  userName?: Maybe<Scalars['String']>
}

export type NetScoreResult = {
  __typename?: 'NetScoreResult'
  count?: Maybe<Scalars['Int']>
  scores?: Maybe<Array<Maybe<NetScore>>>
}

export type NewPatientResponse = {
  __typename?: 'NewPatientResponse'
  accessToken?: Maybe<Scalars['String']>
  patientId: Scalars['String']
  refreshToken?: Maybe<Scalars['String']>
  userId: Scalars['String']
}

export enum NotWorthLivingEnum {
  LivingInANursingHome = 'livingInANursingHome',
  LivingInConstantPain = 'livingInConstantPain',
  LivingWithDiscomfort = 'livingWithDiscomfort',
  None = 'none',
  NotAbleToDrive = 'notAbleToDrive',
  NotAbleToLeaveBed = 'notAbleToLeaveBed',
  NotAbleToLiveWithoutMachine = 'notAbleToLiveWithoutMachine',
  NotAbleToMove = 'notAbleToMove',
  NotAbleToTalk = 'notAbleToTalk',
  NotAbleToThink = 'notAbleToThink',
  NotAbleToWalk = 'notAbleToWalk',
  NotBeingAbleToBathroom = 'notBeingAbleToBathroom',
  NotBeingAbleToFeed = 'notBeingAbleToFeed',
  NotBeingAbleToLiveAlone = 'notBeingAbleToLiveAlone',
  NotGoOutside = 'notGoOutside',
  NotHavingRelationships = 'notHavingRelationships',
  SevereFinancialBurden = 'severeFinancialBurden',
}

export type Patient = {
  __typename?: 'Patient'
  aboutWhatMakesGoodDecisionMaker?: Maybe<Scalars['Int']>
  aboutWhoCanBeDecisionMaker?: Maybe<Scalars['Int']>
  actionHistory?: Maybe<Array<PatientActionHistoryItem>>
  additionalCarePreferences?: Maybe<Scalars['String']>
  additionalMdmDirections?: Maybe<Scalars['String']>
  address?: Maybe<Scalars['String']>
  affordMedications?: Maybe<Scalars['String']>
  age?: Maybe<Scalars['Int']>
  agreeToTerms?: Maybe<Scalars['String']>
  altDecisionMakerAddressCity?: Maybe<Scalars['String']>
  altDecisionMakerAddressState?: Maybe<Scalars['String']>
  altDecisionMakerAddressStreet?: Maybe<Scalars['String']>
  altDecisionMakerAddressZip?: Maybe<Scalars['String']>
  altDecisionMakerEmail?: Maybe<Scalars['String']>
  altDecisionMakerFirstName?: Maybe<Scalars['String']>
  altDecisionMakerLastName?: Maybe<Scalars['String']>
  altDecisionMakerPhone?: Maybe<Scalars['String']>
  altDecisionMakerRelationship?: Maybe<DecisionMakerRelationshipEnum>
  altDecisionMakerRelationshipOther?: Maybe<Scalars['String']>
  birthday?: Maybe<Scalars['String']>
  city?: Maybe<Scalars['String']>
  clinic: Clinic
  clinicId: Scalars['String']
  comfortLevelBecomeIll?: Maybe<Scalars['Int']>
  completedAt?: Maybe<Scalars['String']>
  cprAffectPeopleAgeGroup?: Maybe<Scalars['Boolean']>
  cprAgeGroupVideo?: Maybe<Scalars['Boolean']>
  cprPreference?: Maybe<Scalars['String']>
  cprPreferenceToday?: Maybe<CprPreferenceTodayEnum>
  createdAt?: Maybe<Scalars['String']>
  decisionMakerAddressCity?: Maybe<Scalars['String']>
  decisionMakerAddressState?: Maybe<Scalars['String']>
  decisionMakerAddressStreet?: Maybe<Scalars['String']>
  decisionMakerAddressZip?: Maybe<Scalars['String']>
  decisionMakerEmail?: Maybe<Scalars['String']>
  decisionMakerFirstName?: Maybe<Scalars['String']>
  decisionMakerKindOfMedicalYouWouldWant?: Maybe<Scalars['Int']>
  decisionMakerLastName?: Maybe<Scalars['String']>
  decisionMakerName?: Maybe<Scalars['String']>
  decisionMakerPhone?: Maybe<Scalars['String']>
  decisionMakerRelationship?: Maybe<DecisionMakerRelationshipEnum>
  decisionMakerRelationshipOther?: Maybe<Scalars['String']>
  decisionMakerStrictness?: Maybe<DecisionMakerStrictnessEnum>
  dialysisTreatment?: Maybe<DialysisTreatmentEnum>
  dialysisTreatmentToday?: Maybe<DialysisTreatmentTodayEnum>
  doOnYourOwn?: Maybe<Array<Maybe<DoOnYourOwnEnum>>>
  doOnYourOwnWithoutHelp?: Maybe<Array<Maybe<DoOnYourOwnWithoutHelpEnum>>>
  doYouKnowDecisionMaker?: Maybe<Scalars['String']>
  eSignStarted?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  endTime?: Maybe<Scalars['String']>
  faxes?: Maybe<Array<Maybe<FaxSent>>>
  firstLogin?: Maybe<Scalars['String']>
  firstName?: Maybe<Scalars['String']>
  guideTemplate?: Maybe<GuideTemplate>
  hasOfficialPlan?: Maybe<Scalars['Boolean']>
  hasPlanViewed?: Maybe<Scalars['Boolean']>
  healthIs?: Maybe<HealthIsEnum>
  healthcareProviderKindOfMedicalYouWouldWant?: Maybe<Scalars['Int']>
  id: Scalars['String']
  importantToMe?: Maybe<Array<Maybe<ImportantToMeEnum>>>
  importantToMeOther?: Maybe<Scalars['String']>
  informedAboutFlexibilityDecisionMaker?: Maybe<Scalars['Int']>
  interestedInHospiceCare?: Maybe<YesNoNotSureEnum>
  interestedInPalliativeCare?: Maybe<YesNoNotSureEnum>
  knowWhatAdvanceCarePlanningIs?: Maybe<Scalars['Int']>
  lastLocation?: Maybe<Array<Maybe<LastLocationEnum>>>
  lastLogin?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  lifeAndValues?: Maybe<Scalars['String']>
  lifeSustainingProcedures?: Maybe<Array<Maybe<Scalars['String']>>>
  lifeSustainingProceduresOther?: Maybe<Scalars['String']>
  likeToKnowCprAgeGroup?: Maybe<Scalars['Boolean']>
  livingSituation?: Maybe<LivingSituationEnum>
  livingSituationOther?: Maybe<Scalars['String']>
  longTermFeeding?: Maybe<LongTermFeedingEnum>
  longTermFeedingToday?: Maybe<LongTermFeedingTodayEnum>
  meaningOfLife?: Maybe<Scalars['Int']>
  medicalAttorneyLivingWillAdvanceDirective?: Maybe<MedicalAttorneyLivingWillAdvanceDirectiveEnum>
  medicalCareScenario1?: Maybe<TerminConditionScenarioEnum>
  medicalCareScenario2?: Maybe<TerminConditionScenarioEnum>
  medicalCareScenario3?: Maybe<TerminConditionScenarioEnum>
  notWorthLiving?: Maybe<Array<Maybe<NotWorthLivingEnum>>>
  notWorthLiving2?: Maybe<Array<Maybe<NotWorthLivingEnum>>>
  notWorthLiving3?: Maybe<Array<Maybe<NotWorthLivingEnum>>>
  notaryInfo?: Maybe<Scalars['Boolean']>
  patientSignatureDate?: Maybe<Scalars['String']>
  patientSignatureFirstName?: Maybe<Scalars['String']>
  patientSignatureLastName?: Maybe<Scalars['String']>
  personaHealthcareExperience?: Maybe<Scalars['Int']>
  personaSocialTies?: Maybe<Scalars['Int']>
  personaVacation?: Maybe<Scalars['Int']>
  personalLossOrMisfortune?: Maybe<PersonalLossOrMisfortuneEnum>
  phoneNumber?: Maybe<Scalars['String']>
  planShares?: Maybe<Array<Maybe<PlanShare>>>
  postAcpAboutWhatMakesGoodDecisionMaker?: Maybe<Scalars['Int']>
  postAcpAboutWhoCanBeDecisionMaker?: Maybe<Scalars['Int']>
  postAcpComfortLevelBecomeIll?: Maybe<Scalars['Int']>
  postAcpConfidentUsingTheSystem?: Maybe<PostAcpSystemUsabilityScale>
  postAcpDecisionMakerKindOfMedicalYouWouldWant?: Maybe<Scalars['Int']>
  postAcpDigitalAdvanceCarePlanning?: Maybe<PostAcpDigitalAdvanceCarePlanningEnum>
  postAcpHealthcareProviderKindOfMedicalYouWouldWant?: Maybe<Scalars['Int']>
  postAcpHealthcareSystemTrust?: Maybe<Scalars['Int']>
  postAcpInfoAboutCPR?: Maybe<PostAcpInfoAboutCprEnum>
  postAcpInfoAboutCPROther?: Maybe<Scalars['String']>
  postAcpInformedAboutFlexibilityDecisionMaker?: Maybe<Scalars['Int']>
  postAcpKnowWhatAdvanceCarePlanningIs?: Maybe<Scalars['Int']>
  postAcpLearnLotPfThingsBefore?: Maybe<PostAcpSystemUsabilityScale>
  postAcpMedicalEducationPrefer?: Maybe<PostAcpMedicalEducationPreferEnum>
  postAcpMedicalEducationPreferOther?: Maybe<Scalars['String']>
  postAcpMostPeopleWouldLearn?: Maybe<PostAcpSystemUsabilityScale>
  postAcpOnlineFormat?: Maybe<PostAcpOnlineFormatEnum>
  postAcpOnlineFormatOther?: Maybe<Scalars['String']>
  postAcpStatementMostAdvanceCarePlanning?: Maybe<StatementMostAdvanceCarePlanningEnum>
  postAcpStatementMostAdvanceCarePlanningNoneText?: Maybe<Scalars['String']>
  postAcpSupportTechnicalPerson?: Maybe<PostAcpSystemUsabilityScale>
  postAcpSystemUnnecessarilyComplex?: Maybe<PostAcpSystemUsabilityScale>
  postAcpSystemVeryCumbersomeToUse?: Maybe<PostAcpSystemUsabilityScale>
  postAcpSystemWasEasyUse?: Maybe<PostAcpSystemUsabilityScale>
  postAcpTooMuchInconsistency?: Maybe<PostAcpSystemUsabilityScale>
  postAcpUseSystemFrequently?: Maybe<PostAcpSystemUsabilityScale>
  postAcpVariousFunctionsWellIntegrated?: Maybe<PostAcpSystemUsabilityScale>
  postAcpWorriedFutureIllnesses?: Maybe<Scalars['Int']>
  postal?: Maybe<Scalars['String']>
  readiness?: Maybe<Array<PatientReadinessSurveyResponse>>
  receiveCareOther?: Maybe<Scalars['String']>
  schedulingACPinformation?: Maybe<Scalars['String']>
  /** has the user shared his plan with either himself (ie email him or herself) OR have they shared it with their decision maker via email? */
  sharedPlan?: Maybe<Scalars['Boolean']>
  slug?: Maybe<Scalars['String']>
  spiritualAndReligiousBeliefs?: Maybe<Scalars['String']>
  startTime?: Maybe<Scalars['String']>
  /** If this user has a specific state selected, this field will return the given settings to adjust the survey and/or PDF */
  stateVersion?: Maybe<State>
  statementMostAdvanceCarePlanning?: Maybe<StatementMostAdvanceCarePlanningEnum>
  statementMostAdvanceCarePlanningNoneText?: Maybe<Scalars['String']>
  takeCareSomeoneWasSeriouslyIll?: Maybe<TakeCareSomeoneWasSeriouslyIllEnum>
  thirdDecisionMakerAddressCity?: Maybe<Scalars['String']>
  thirdDecisionMakerAddressState?: Maybe<Scalars['String']>
  thirdDecisionMakerAddressStreet?: Maybe<Scalars['String']>
  thirdDecisionMakerAddressZip?: Maybe<Scalars['String']>
  thirdDecisionMakerEmail?: Maybe<Scalars['String']>
  thirdDecisionMakerFirstName?: Maybe<Scalars['String']>
  thirdDecisionMakerLastName?: Maybe<Scalars['String']>
  thirdDecisionMakerName?: Maybe<Scalars['String']>
  thirdDecisionMakerPhone?: Maybe<Scalars['String']>
  thirdDecisionMakerRelationship?: Maybe<DecisionMakerRelationshipEnum>
  thirdDecisionMakerRelationshipOther?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['String']>
  userId: Scalars['String']
  ventilationPreference?: Maybe<VentilationPreferenceEnum>
  ventilationPreferenceToday?: Maybe<VentilationPreferenceTodayEnum>
  wantsAltDecisionMaker?: Maybe<Scalars['String']>
  wantsThirdDecisionMaker?: Maybe<Scalars['String']>
  whenCanDecisionMakerDecide?: Maybe<WhenCanDecisionMakerDecideEnum>
  witness1LocationType?: Maybe<WitnessSignatureLocationEnum>
  witness1SignatureDate?: Maybe<Scalars['String']>
  witness1SignatureEmail?: Maybe<Scalars['String']>
  witness1SignatureFirstName?: Maybe<Scalars['String']>
  witness1SignatureLastName?: Maybe<Scalars['String']>
  witness2LocationType?: Maybe<WitnessSignatureLocationEnum>
  witness2SignatureDate?: Maybe<Scalars['String']>
  witness2SignatureEmail?: Maybe<Scalars['String']>
  witness2SignatureFirstName?: Maybe<Scalars['String']>
  witness2SignatureLastName?: Maybe<Scalars['String']>
  worriedFutureIllnesses?: Maybe<Scalars['Int']>
}

export type PatientActionHistoryItem = {
  __typename?: 'PatientActionHistoryItem'
  createdAt?: Maybe<Scalars['Date']>
  name: PatientActionHistoryItemEnum
  value?: Maybe<Scalars['String']>
}

export enum PatientActionHistoryItemEnum {
  /** If the user has closed their readiness survey, we don't want to show it to them again */
  ClosedReadinessSurveyModal = 'ClosedReadinessSurveyModal',
  /** User has viewed the patient onboarding */
  HasViewedOnboarding = 'HasViewedOnboarding',
}

export type PatientByMonth = {
  __typename?: 'PatientByMonth'
  _id?: Maybe<MonthYear>
  id?: Maybe<Scalars['ID']>
  values?: Maybe<Array<Maybe<PatientByMonthValue>>>
}

export type PatientByMonthValue = {
  __typename?: 'PatientByMonthValue'
  clinicName?: Maybe<Scalars['String']>
  total?: Maybe<Scalars['Int']>
}

export type PatientInput = {
  aboutWhatMakesGoodDecisionMaker?: InputMaybe<Scalars['Int']>
  aboutWhoCanBeDecisionMaker?: InputMaybe<Scalars['Int']>
  additionalCarePreferences?: InputMaybe<Scalars['String']>
  additionalMdmDirections?: InputMaybe<Scalars['String']>
  affordMedications?: InputMaybe<Scalars['String']>
  age?: InputMaybe<Scalars['Int']>
  agreeToTerms?: InputMaybe<Scalars['String']>
  altDecisionMakerAddressCity?: InputMaybe<Scalars['String']>
  altDecisionMakerAddressState?: InputMaybe<Scalars['String']>
  altDecisionMakerAddressStreet?: InputMaybe<Scalars['String']>
  altDecisionMakerAddressZip?: InputMaybe<Scalars['String']>
  altDecisionMakerEmail?: InputMaybe<Scalars['String']>
  altDecisionMakerFirstName?: InputMaybe<Scalars['String']>
  altDecisionMakerLastName?: InputMaybe<Scalars['String']>
  altDecisionMakerName?: InputMaybe<Scalars['String']>
  altDecisionMakerPhone?: InputMaybe<Scalars['String']>
  altDecisionMakerRelationship?: InputMaybe<DecisionMakerRelationshipEnum>
  altDecisionMakerRelationshipOther?: InputMaybe<Scalars['String']>
  /** The strings birthday in format MM/DD/YYYY */
  birthday?: InputMaybe<Scalars['String']>
  comfortLevelBecomeIll?: InputMaybe<Scalars['Int']>
  /** The timestamp at which the user completes all required survey questions */
  completedAt?: InputMaybe<Scalars['String']>
  cprAffectPeopleAgeGroup?: InputMaybe<Scalars['Boolean']>
  cprAgeGroupVideo?: InputMaybe<Scalars['Boolean']>
  cprPreference?: InputMaybe<Scalars['String']>
  cprPreferenceToday?: InputMaybe<CprPreferenceTodayEnum>
  decisionMakerAddressCity?: InputMaybe<Scalars['String']>
  decisionMakerAddressState?: InputMaybe<Scalars['String']>
  decisionMakerAddressStreet?: InputMaybe<Scalars['String']>
  decisionMakerAddressZip?: InputMaybe<Scalars['String']>
  decisionMakerEmail?: InputMaybe<Scalars['String']>
  decisionMakerFirstName?: InputMaybe<Scalars['String']>
  decisionMakerKindOfMedicalYouWouldWant?: InputMaybe<Scalars['Int']>
  decisionMakerLastName?: InputMaybe<Scalars['String']>
  decisionMakerPhone?: InputMaybe<Scalars['String']>
  decisionMakerRelationship?: InputMaybe<DecisionMakerRelationshipEnum>
  decisionMakerRelationshipOther?: InputMaybe<Scalars['String']>
  decisionMakerStrictness?: InputMaybe<DecisionMakerStrictnessEnum>
  dialysisTreatment?: InputMaybe<DialysisTreatmentEnum>
  dialysisTreatmentToday?: InputMaybe<DialysisTreatmentTodayEnum>
  doOnYourOwn?: InputMaybe<Array<DoOnYourOwnEnum>>
  doOnYourOwnWithoutHelp?: InputMaybe<Array<InputMaybe<DoOnYourOwnWithoutHelpEnum>>>
  doYouKnowDecisionMaker?: InputMaybe<Scalars['String']>
  email?: InputMaybe<Scalars['String']>
  firstName?: InputMaybe<Scalars['String']>
  hasPlanViewed?: InputMaybe<Scalars['Boolean']>
  healthIs?: InputMaybe<HealthIsEnum>
  healthcareProviderKindOfMedicalYouWouldWant?: InputMaybe<Scalars['Int']>
  importantToMe?: InputMaybe<Array<InputMaybe<ImportantToMeEnum>>>
  importantToMeOther?: InputMaybe<Scalars['String']>
  informedAboutFlexibilityDecisionMaker?: InputMaybe<Scalars['Int']>
  interestedInHospiceCare?: InputMaybe<YesNoNotSureEnum>
  interestedInPalliativeCare?: InputMaybe<YesNoNotSureEnum>
  knowWhatAdvanceCarePlanningIs?: InputMaybe<Scalars['Int']>
  lastLocation?: InputMaybe<Array<InputMaybe<LastLocationEnum>>>
  lastName?: InputMaybe<Scalars['String']>
  lifeAndValues?: InputMaybe<Scalars['String']>
  lifeSustainingProcedures?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  lifeSustainingProceduresOther?: InputMaybe<Scalars['String']>
  likeToKnowCprAgeGroup?: InputMaybe<Scalars['Boolean']>
  livingSituation?: InputMaybe<LivingSituationEnum>
  livingSituationOther?: InputMaybe<Scalars['String']>
  longTermFeeding?: InputMaybe<LongTermFeedingEnum>
  longTermFeedingToday?: InputMaybe<LongTermFeedingTodayEnum>
  meaningOfLife?: InputMaybe<Scalars['Int']>
  medicalAttorneyLivingWillAdvanceDirective?: InputMaybe<MedicalAttorneyLivingWillAdvanceDirectiveEnum>
  medicalCareScenario1?: InputMaybe<TerminConditionScenarioEnum>
  medicalCareScenario2?: InputMaybe<TerminConditionScenarioEnum>
  medicalCareScenario3?: InputMaybe<TerminConditionScenarioEnum>
  notWorthLiving?: InputMaybe<Array<InputMaybe<NotWorthLivingEnum>>>
  notWorthLiving2?: InputMaybe<Array<InputMaybe<NotWorthLivingEnum>>>
  notWorthLiving3?: InputMaybe<Array<InputMaybe<NotWorthLivingEnum>>>
  /** Does the user want to be contacted about potential remote notary services? */
  notaryInfo?: InputMaybe<Scalars['Boolean']>
  personaHealthcareExperience?: InputMaybe<Scalars['Int']>
  personaSocialTies?: InputMaybe<Scalars['Int']>
  personaVacation?: InputMaybe<Scalars['Int']>
  personalLossOrMisfortune?: InputMaybe<PersonalLossOrMisfortuneEnum>
  phoneNumber?: InputMaybe<Scalars['String']>
  postAcpAboutWhatMakesGoodDecisionMaker?: InputMaybe<Scalars['Int']>
  postAcpAboutWhoCanBeDecisionMaker?: InputMaybe<Scalars['Int']>
  postAcpComfortLevelBecomeIll?: InputMaybe<Scalars['Int']>
  postAcpConfidentUsingTheSystem?: InputMaybe<PostAcpSystemUsabilityScale>
  postAcpDecisionMakerKindOfMedicalYouWouldWant?: InputMaybe<Scalars['Int']>
  postAcpDigitalAdvanceCarePlanning?: InputMaybe<PostAcpDigitalAdvanceCarePlanningEnum>
  postAcpHealthcareProviderKindOfMedicalYouWouldWant?: InputMaybe<Scalars['Int']>
  postAcpHealthcareSystemTrust?: InputMaybe<Scalars['Int']>
  postAcpInfoAboutCPR?: InputMaybe<PostAcpInfoAboutCprEnum>
  postAcpInfoAboutCPROther?: InputMaybe<Scalars['String']>
  postAcpInformedAboutFlexibilityDecisionMaker?: InputMaybe<Scalars['Int']>
  postAcpKnowWhatAdvanceCarePlanningIs?: InputMaybe<Scalars['Int']>
  postAcpLearnLotPfThingsBefore?: InputMaybe<PostAcpSystemUsabilityScale>
  postAcpMedicalEducationPrefer?: InputMaybe<PostAcpMedicalEducationPreferEnum>
  postAcpMedicalEducationPreferOther?: InputMaybe<Scalars['String']>
  postAcpMostPeopleWouldLearn?: InputMaybe<PostAcpSystemUsabilityScale>
  postAcpOnlineFormat?: InputMaybe<PostAcpOnlineFormatEnum>
  postAcpOnlineFormatOther?: InputMaybe<Scalars['String']>
  postAcpStatementMostAdvanceCarePlanning?: InputMaybe<StatementMostAdvanceCarePlanningEnum>
  postAcpStatementMostAdvanceCarePlanningNoneText?: InputMaybe<Scalars['String']>
  postAcpSupportTechnicalPerson?: InputMaybe<PostAcpSystemUsabilityScale>
  postAcpSystemUnnecessarilyComplex?: InputMaybe<PostAcpSystemUsabilityScale>
  postAcpSystemVeryCumbersomeToUse?: InputMaybe<PostAcpSystemUsabilityScale>
  postAcpSystemWasEasyUse?: InputMaybe<PostAcpSystemUsabilityScale>
  postAcpTooMuchInconsistency?: InputMaybe<PostAcpSystemUsabilityScale>
  postAcpUseSystemFrequently?: InputMaybe<PostAcpSystemUsabilityScale>
  postAcpVariousFunctionsWellIntegrated?: InputMaybe<PostAcpSystemUsabilityScale>
  postAcpWorriedFutureIllnesses?: InputMaybe<Scalars['Int']>
  receiveCareOther?: InputMaybe<Scalars['String']>
  schedulingACPinformation?: InputMaybe<Scalars['String']>
  spiritualAndReligiousBeliefs?: InputMaybe<Scalars['String']>
  startTime?: InputMaybe<Scalars['String']>
  stateId?: InputMaybe<Scalars['String']>
  statementMostAdvanceCarePlanning?: InputMaybe<StatementMostAdvanceCarePlanningEnum>
  statementMostAdvanceCarePlanningNoneText?: InputMaybe<Scalars['String']>
  takeCareSomeoneWasSeriouslyIll?: InputMaybe<TakeCareSomeoneWasSeriouslyIllEnum>
  thirdDecisionMakerAddressCity?: InputMaybe<Scalars['String']>
  thirdDecisionMakerAddressState?: InputMaybe<Scalars['String']>
  thirdDecisionMakerAddressStreet?: InputMaybe<Scalars['String']>
  thirdDecisionMakerAddressZip?: InputMaybe<Scalars['String']>
  thirdDecisionMakerEmail?: InputMaybe<Scalars['String']>
  thirdDecisionMakerFirstName?: InputMaybe<Scalars['String']>
  thirdDecisionMakerLastName?: InputMaybe<Scalars['String']>
  thirdDecisionMakerName?: InputMaybe<Scalars['String']>
  thirdDecisionMakerPhone?: InputMaybe<Scalars['String']>
  thirdDecisionMakerRelationship?: InputMaybe<DecisionMakerRelationshipEnum>
  thirdDecisionMakerRelationshipOther?: InputMaybe<Scalars['String']>
  ventilationPreference?: InputMaybe<VentilationPreferenceEnum>
  ventilationPreferenceToday?: InputMaybe<VentilationPreferenceTodayEnum>
  wantsAltDecisionMaker?: InputMaybe<Scalars['String']>
  wantsThirdDecisionMaker?: InputMaybe<Scalars['String']>
  whenCanDecisionMakerDecide?: InputMaybe<WhenCanDecisionMakerDecideEnum>
  worriedFutureIllnesses?: InputMaybe<Scalars['Int']>
}

export type PatientReadinessSurveyResponse = {
  __typename?: 'PatientReadinessSurveyResponse'
  answer: SubmitReadinessMpoResponseEnum
  createdAt?: Maybe<Scalars['String']>
  otherAnswer?: Maybe<Scalars['String']>
}

export type PercentAbsolute = {
  __typename?: 'PercentAbsolute'
  absolute?: Maybe<Scalars['String']>
  percent?: Maybe<Scalars['String']>
}

export type Permission = {
  __typename?: 'Permission'
  accesses: Array<Access>
  description?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  title?: Maybe<Scalars['String']>
}

export enum PersonalLossOrMisfortuneEnum {
  No = 'no',
  YesMoreSeriousLoss = 'yesMoreSeriousLoss',
  YesOneSeriousLoss = 'yesOneSeriousLoss',
}

export type PlanAttachment = {
  __typename?: 'PlanAttachment'
  base64String?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  mimetype?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  patientFirstName?: Maybe<Scalars['String']>
  patientLastName?: Maybe<Scalars['String']>
  planAttachmentType?: Maybe<PlanAttachmentTypeEnum>
  updatedAt?: Maybe<Scalars['String']>
  url?: Maybe<Scalars['String']>
}

export enum PlanAttachmentTypeEnum {
  OfficialKodaPlan = 'official_koda_plan',
  Other = 'other',
  OutOfHospitalDnr = 'out_of_hospital_dnr',
  UnofficialKodaPlan = 'unofficial_koda_plan',
}

export type PlanShare = {
  __typename?: 'PlanShare'
  dateSent?: Maybe<Scalars['String']>
  sendToEmail?: Maybe<Scalars['String']>
}

export type PlanSignaturesInput = {
  address?: InputMaybe<Scalars['String']>
  city?: InputMaybe<Scalars['String']>
  patientSignatureDate?: InputMaybe<Scalars['String']>
  patientSignatureFirstName?: InputMaybe<Scalars['String']>
  patientSignatureLastName?: InputMaybe<Scalars['String']>
  postal?: InputMaybe<Scalars['String']>
  witness1LocationType?: InputMaybe<WitnessSignatureLocationEnum>
  witness1SignatureDate?: InputMaybe<Scalars['String']>
  witness1SignatureEmail?: InputMaybe<Scalars['String']>
  witness1SignatureFirstName?: InputMaybe<Scalars['String']>
  witness1SignatureLastName?: InputMaybe<Scalars['String']>
  witness2LocationType?: InputMaybe<WitnessSignatureLocationEnum>
  witness2SignatureDate?: InputMaybe<Scalars['String']>
  witness2SignatureEmail?: InputMaybe<Scalars['String']>
  witness2SignatureFirstName?: InputMaybe<Scalars['String']>
  witness2SignatureLastName?: InputMaybe<Scalars['String']>
}

export type PlansResponse = {
  __typename?: 'PlansResponse'
  count?: Maybe<Scalars['Int']>
  plans?: Maybe<Array<Maybe<Patient>>>
}

export type PlansSortByInput = {
  createdAt?: InputMaybe<SortEnum>
  dob?: InputMaybe<SortEnum>
  firstLogin?: InputMaybe<SortEnum>
  lastLogin?: InputMaybe<SortEnum>
  lastName?: InputMaybe<SortEnum>
  updatedAt?: InputMaybe<SortEnum>
}

export enum PostAcpDigitalAdvanceCarePlanningEnum {
  No = 'no',
  Yes = 'yes',
}

export enum PostAcpInfoAboutCprEnum {
  DidntReceiveAgeSpecific = 'didntReceiveAgeSpecific',
  No = 'no',
  Other = 'other',
  Yes = 'yes',
}

export enum PostAcpMedicalEducationPreferEnum {
  Educational = 'educational',
  Friendly = 'friendly',
  Humorous = 'humorous',
  Informative = 'informative',
  Other = 'other',
  Serious = 'serious',
}

export enum PostAcpOnlineFormatEnum {
  Audio = 'audio',
  LongerArticles = 'longerArticles',
  Other = 'other',
  PhotosInfographicsIllustrations = 'photosInfographicsIllustrations',
  Presentations = 'presentations',
  ShortArticles = 'shortArticles',
  Videos = 'videos',
}

export enum PostAcpSystemUsabilityScale {
  Agree = 'agree',
  Disagree = 'disagree',
  Neither = 'neither',
  StronglyAgree = 'stronglyAgree',
  StronglyDisagree = 'stronglyDisagree',
}

export type PresignedMultipartResult = {
  __typename?: 'PresignedMultipartResult'
  key?: Maybe<Scalars['String']>
  uploadId?: Maybe<Scalars['String']>
  urls?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type PresignedUploadResponse = {
  __typename?: 'PresignedUploadResponse'
  /** Stringified object */
  fields?: Maybe<Scalars['String']>
  url?: Maybe<Scalars['String']>
}

export type Query = {
  __typename?: 'Query'
  accesses: Array<Access>
  /** Provide a patient ID, get back a list of actvity items */
  activityLogsByPatientId?: Maybe<Array<Maybe<ActivityLog>>>
  allNetScores?: Maybe<Array<Maybe<NetScore>>>
  clinicCSV?: Maybe<Array<Maybe<ClinicDataCsv>>>
  clinics?: Maybe<ClinicsResponse>
  /** Returns the currently signed in user or null if user is not signed in */
  currentUser?: Maybe<UserProfile>
  /** Provide an email string and this query will tell you if it already exists in our database (for user accounts). Returns true if it exists and false if it does not exist yet. */
  emailAlreadyExists?: Maybe<ExistsCheckResponse>
  findReset?: Maybe<FindResetResponse>
  /** Returns hipaa logs */
  getHipaaLogs?: Maybe<HippaaLogsResponse>
  getManyGuideTemplates?: Maybe<Array<Maybe<GuideTemplate>>>
  /** Returns a list of plans/patients for a clinic. Must he admin to view. */
  getManyPatients?: Maybe<PlansResponse>
  getOneClinic?: Maybe<Clinic>
  getOnePatient?: Maybe<Patient>
  getUser?: Maybe<User>
  npsData?: Maybe<NetScoreResult>
  permissions?: Maybe<Array<Maybe<Permission>>>
  planAttachmentById?: Maybe<PlanAttachment>
  planAttachments?: Maybe<Array<Maybe<PlanAttachment>>>
  recentlyActivePatients?: Maybe<Array<Maybe<ShortPatient>>>
  reports_accountsPerWeek?: Maybe<Array<Maybe<PatientByMonth>>>
  reports_clinicStats?: Maybe<ClinicStats>
  /** Full text fuzzy search for patients. Admins and Clinics only. */
  searchPatients?: Maybe<Array<Maybe<Patient>>>
  /** Returns a list of states and their given settings */
  states?: Maybe<Array<Maybe<State>>>
  twoFactorSecret?: Maybe<TwoFactorSecretKey>
  /** Returns a list of users */
  users?: Maybe<UsersResponse>
}

export type QueryActivityLogsByPatientIdArgs = {
  patientId: Scalars['ID']
}

export type QueryClinicCsvArgs = {
  clinicId: Scalars['ID']
}

export type QueryClinicsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  searchText?: InputMaybe<Scalars['String']>
  skip?: InputMaybe<Scalars['Int']>
}

export type QueryEmailAlreadyExistsArgs = {
  email: Scalars['String']
}

export type QueryFindResetArgs = {
  token: Scalars['String']
}

export type QueryGetHipaaLogsArgs = {
  endTime?: InputMaybe<Scalars['Float']>
  limit?: InputMaybe<Scalars['Int']>
  patientId?: InputMaybe<Scalars['ID']>
  skip?: InputMaybe<Scalars['Int']>
  startTime?: InputMaybe<Scalars['Float']>
}

export type QueryGetManyPatientsArgs = {
  clinicId: Scalars['ID']
  endTime?: InputMaybe<Scalars['Float']>
  limit?: InputMaybe<Scalars['Int']>
  searchText?: InputMaybe<Scalars['String']>
  skip?: InputMaybe<Scalars['Int']>
  sortBy?: InputMaybe<PlansSortByInput>
  startTime?: InputMaybe<Scalars['Float']>
}

export type QueryGetOneClinicArgs = {
  id?: InputMaybe<Scalars['ID']>
  slug?: InputMaybe<Scalars['String']>
}

export type QueryGetOnePatientArgs = {
  patientId?: InputMaybe<Scalars['ID']>
  token?: InputMaybe<Scalars['String']>
  userId?: InputMaybe<Scalars['ID']>
}

export type QueryNpsDataArgs = {
  clinicIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
  endTime?: InputMaybe<Scalars['String']>
  startTime?: InputMaybe<Scalars['String']>
}

export type QueryPlanAttachmentByIdArgs = {
  downloadToken: Scalars['String']
  fileId: Scalars['ID']
}

export type QueryPlanAttachmentsArgs = {
  patientId?: InputMaybe<Scalars['ID']>
}

export type QueryRecentlyActivePatientsArgs = {
  clinicId: Scalars['ID']
}

export type QueryReports_AccountsPerWeekArgs = {
  clinicIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
  endDate?: InputMaybe<Scalars['Float']>
  startDate?: InputMaybe<Scalars['Float']>
}

export type QueryReports_ClinicStatsArgs = {
  clinicIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
  endDate?: InputMaybe<Scalars['Float']>
  startDate?: InputMaybe<Scalars['Float']>
}

export type QuerySearchPatientsArgs = {
  clinicId?: InputMaybe<Scalars['ID']>
  searchText: Scalars['String']
}

export type QueryUsersArgs = {
  clinicId?: InputMaybe<Scalars['String']>
  roles?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  searchText?: InputMaybe<Scalars['String']>
}

export enum RoleTypeEnum {
  ClinicUser = 'clinicUser',
  Patient = 'patient',
  SuperAdmin = 'superAdmin',
}

export type ShortPatient = {
  __typename?: 'ShortPatient'
  email?: Maybe<Scalars['String']>
  firstName?: Maybe<Scalars['String']>
  id: Scalars['ID']
  lastLogin?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
}

export enum SortEnum {
  Asc = 'asc',
  Desc = 'desc',
}

export type State = {
  __typename?: 'State'
  abbreviation?: Maybe<Scalars['String']>
  advanceDirectiveVideo?: Maybe<Scalars['String']>
  hasESign?: Maybe<Scalars['Boolean']>
  hasNotary?: Maybe<Scalars['Boolean']>
  hasPaperCopy?: Maybe<Scalars['Boolean']>
  id?: Maybe<Scalars['String']>
  linkToSigningRequirements?: Maybe<Scalars['String']>
  medicalCareScenariosVideo?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
}

export type StateInput = {
  abbreviation?: InputMaybe<Scalars['String']>
  advanceDirectiveVideo?: InputMaybe<Scalars['String']>
  hasESign?: InputMaybe<Scalars['Boolean']>
  hasNotary?: InputMaybe<Scalars['Boolean']>
  hasPaperCopy?: InputMaybe<Scalars['Boolean']>
  id?: InputMaybe<Scalars['String']>
  linkToSigningRequirements?: InputMaybe<Scalars['String']>
  medicalCareScenariosVideo?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
}

export enum StatementMostAdvanceCarePlanningEnum {
  Caregiver = 'caregiver',
  LiveEasier1 = 'liveEasier1',
  LiveEasier2 = 'liveEasier2',
  MettersToMe = 'mettersToMe',
  None = 'none',
  SeriousIllness = 'seriousIllness',
  StayInControl = 'stayInControl',
}

export enum SubmitReadinessMpoResponseEnum {
  ImReady = 'imReady',
  ImReadyButNeedMore = 'imReadyButNeedMore',
  NeedMoreTime = 'needMoreTime',
  NeedToTalkToMdm = 'needToTalkToMDM',
  NotReadyBecause = 'notReadyBecause',
}

export enum TakeCareSomeoneWasSeriouslyIllEnum {
  No = 'no',
  Yes = 'yes',
}

export enum TerminConditionScenarioEnum {
  A = 'a',
  B = 'b',
  C = 'c',
  D = 'd',
  E = 'e',
}

export type Tokens = {
  __typename?: 'Tokens'
  accessToken?: Maybe<Scalars['String']>
  refreshToken?: Maybe<Scalars['String']>
}

export type TwoFactorSecretKey = {
  __typename?: 'TwoFactorSecretKey'
  ascii?: Maybe<Scalars['String']>
  base32?: Maybe<Scalars['String']>
  google_auth_qr?: Maybe<Scalars['String']>
  hex?: Maybe<Scalars['String']>
  otpauth_url?: Maybe<Scalars['String']>
  qr_code_ascii?: Maybe<Scalars['String']>
  qr_code_base32?: Maybe<Scalars['String']>
  qr_code_hex?: Maybe<Scalars['String']>
}

export type TwoFactorSecretKeyInput = {
  ascii?: InputMaybe<Scalars['String']>
  base32?: InputMaybe<Scalars['String']>
  google_auth_qr?: InputMaybe<Scalars['String']>
  hex?: InputMaybe<Scalars['String']>
  otpauth_url?: InputMaybe<Scalars['String']>
  qr_code_ascii?: InputMaybe<Scalars['String']>
  qr_code_base32?: InputMaybe<Scalars['String']>
  qr_code_hex?: InputMaybe<Scalars['String']>
}

export type User = {
  __typename?: 'User'
  emails?: Maybe<Array<EmailRecord>>
  id: Scalars['ID']
  username?: Maybe<Scalars['String']>
}

export type UserInput = {
  email?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['ID']>
  username?: InputMaybe<Scalars['String']>
}

export type UserParams = {
  email?: InputMaybe<Scalars['String']>
  firstName?: InputMaybe<Scalars['String']>
  lang?: InputMaybe<LangTypeEnum>
  lastName?: InputMaybe<Scalars['String']>
  phoneNumber?: InputMaybe<Scalars['String']>
  title?: InputMaybe<Scalars['String']>
}

/** The main User object used for authentication etc */
export type UserProfile = {
  __typename?: 'UserProfile'
  clinic?: Maybe<Clinic>
  clinicPermissions?: Maybe<Array<Maybe<Clinic>>>
  createdAt?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  firstName?: Maybe<Scalars['String']>
  /** Will tell use if user has provided a net promotor score */
  hasNetScore?: Maybe<Scalars['Boolean']>
  id?: Maybe<Scalars['String']>
  /** Does this user have a password set? If not, they're a guest user */
  isGuest?: Maybe<Scalars['Boolean']>
  lang?: Maybe<LangTypeEnum>
  lastName?: Maybe<Scalars['String']>
  patient?: Maybe<Patient>
  permission?: Maybe<Permission>
  phoneNumber?: Maybe<Scalars['String']>
  roles?: Maybe<Array<Maybe<RoleTypeEnum>>>
}

export type UsersResponse = {
  __typename?: 'UsersResponse'
  /** The total number of results your query returned. Used for pagination. */
  count?: Maybe<Scalars['Int']>
  /** An array of UserProfile objects */
  users?: Maybe<Array<Maybe<UserProfile>>>
}

export enum VentilationPreferenceEnum {
  AnyLengthOfTime = 'anyLengthOfTime',
  NoneAtAll = 'noneAtAll',
  NotSure = 'notSure',
  Trial = 'trial',
}

export enum VentilationPreferenceTodayEnum {
  AnyLengthOfTime = 'anyLengthOfTime',
  NoneAtAll = 'noneAtAll',
  NotSure = 'notSure',
  Trial = 'trial',
}

export type WakeForestEvent = {
  __typename?: 'WakeForestEvent'
  clinicId?: Maybe<Scalars['String']>
  content?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['String']>
  id: Scalars['ID']
  key?: Maybe<Scalars['String']>
  patientId?: Maybe<Scalars['String']>
  userId?: Maybe<Scalars['String']>
}

export type WakeForestEventInput = {
  clinicSlug?: InputMaybe<Scalars['String']>
  content?: InputMaybe<Scalars['String']>
  externalId: Scalars['String']
  key?: InputMaybe<Scalars['String']>
  patientId?: InputMaybe<Scalars['String']>
  userId?: InputMaybe<Scalars['String']>
}

export enum WhenCanDecisionMakerDecideEnum {
  After = 'after',
  Now = 'now',
}

export enum WitnessSignatureLocationEnum {
  InPerson = 'inPerson',
}

export enum YesNoNotSureEnum {
  No = 'no',
  NotSure = 'notSure',
  Yes = 'yes',
}

export enum CprPreferenceTodayEnum {
  AlwaysWant = 'alwaysWant',
  NoneAtAll = 'noneAtAll',
  NotSure = 'notSure',
  OnlyIf = 'onlyIf',
}

export type ClinicFragmentFragment = {
  __typename?: 'Clinic'
  id: string
  title?: string | null
  description?: string | null
  createdAt?: string | null
  startVideo?: string | null
  updatedAt?: string | null
  logo?: string | null
  street?: string | null
  street2?: string | null
  stateId: string
  v2Signup?: boolean | null
  postal?: string | null
  city?: string | null
  faxNumber?: string | null
  slug?: string | null
  guideTemplateId?: string | null
  state?: { __typename?: 'State'; id?: string | null; name?: string | null; abbreviation?: string | null } | null
  guideTemplate?: {
    __typename?: 'GuideTemplate'
    id?: string | null
    name?: string | null
    isPlatformDefault?: boolean | null
    sections: Array<{
      __typename?: 'GuideTemplateSection'
      sectionId: GuideTemplateSectionIdEnum
      sectionName: string
      questionIds: Array<GuideQuestionIdEnum>
      required: boolean
      sectionIcon?: string | null
      activeStepText?: string | null
    }>
  } | null
}

export type CurrentUserFragmentFragment = {
  __typename?: 'UserProfile'
  isGuest?: boolean | null
  hasNetScore?: boolean | null
  lang?: LangTypeEnum | null
  id?: string | null
  email?: string | null
  roles?: Array<RoleTypeEnum | null> | null
  firstName?: string | null
  lastName?: string | null
  phoneNumber?: string | null
  createdAt?: string | null
  patient?: {
    __typename?: 'Patient'
    actionHistory?: Array<{
      __typename?: 'PatientActionHistoryItem'
      name: PatientActionHistoryItemEnum
      value?: string | null
    }> | null
  } | null
  clinic?: {
    __typename?: 'Clinic'
    id: string
    slug?: string | null
    title?: string | null
    logo?: string | null
  } | null
  permission?: {
    __typename?: 'Permission'
    id?: string | null
    title?: string | null
    accesses: Array<{ __typename?: 'Access'; id: string; title: AccessTypeEnum }>
  } | null
}

export type GuideTemplateFragmentFragment = {
  __typename?: 'GuideTemplate'
  id?: string | null
  name?: string | null
  isPlatformDefault?: boolean | null
  sections: Array<{
    __typename?: 'GuideTemplateSection'
    sectionId: GuideTemplateSectionIdEnum
    sectionName: string
    questionIds: Array<GuideQuestionIdEnum>
    required: boolean
    sectionIcon?: string | null
    activeStepText?: string | null
  }>
}

export type PatientFragmentFragment = {
  __typename?: 'Patient'
  id: string
  firstName?: string | null
  lastName?: string | null
  email?: string | null
  phoneNumber?: string | null
  startTime?: string | null
  hasOfficialPlan?: boolean | null
  agreeToTerms?: string | null
  userId: string
  decisionMakerEmail?: string | null
  importantToMe?: Array<ImportantToMeEnum | null> | null
  livingSituation?: LivingSituationEnum | null
  likeToKnowCprAgeGroup?: boolean | null
  cprAgeGroupVideo?: boolean | null
  cprAffectPeopleAgeGroup?: boolean | null
  birthday?: string | null
  additionalCarePreferences?: string | null
  additionalMdmDirections?: string | null
  meaningOfLife?: number | null
  notaryInfo?: boolean | null
  clinicId: string
  spiritualAndReligiousBeliefs?: string | null
  lifeAndValues?: string | null
  receiveCareOther?: string | null
  createdAt?: string | null
  medicalCareScenario1?: TerminConditionScenarioEnum | null
  medicalCareScenario2?: TerminConditionScenarioEnum | null
  medicalCareScenario3?: TerminConditionScenarioEnum | null
  lifeSustainingProcedures?: Array<string | null> | null
  lifeSustainingProceduresOther?: string | null
  notWorthLiving?: Array<NotWorthLivingEnum | null> | null
  notWorthLiving2?: Array<NotWorthLivingEnum | null> | null
  notWorthLiving3?: Array<NotWorthLivingEnum | null> | null
  lastLocation?: Array<LastLocationEnum | null> | null
  cprPreference?: string | null
  personaVacation?: number | null
  personaSocialTies?: number | null
  personaHealthcareExperience?: number | null
  ventilationPreference?: VentilationPreferenceEnum | null
  longTermFeeding?: LongTermFeedingEnum | null
  dialysisTreatment?: DialysisTreatmentEnum | null
  doYouKnowDecisionMaker?: string | null
  decisionMakerFirstName?: string | null
  decisionMakerLastName?: string | null
  decisionMakerName?: string | null
  decisionMakerPhone?: string | null
  decisionMakerRelationship?: DecisionMakerRelationshipEnum | null
  decisionMakerRelationshipOther?: string | null
  decisionMakerStrictness?: DecisionMakerStrictnessEnum | null
  decisionMakerAddressStreet?: string | null
  decisionMakerAddressCity?: string | null
  decisionMakerAddressState?: string | null
  decisionMakerAddressZip?: string | null
  wantsAltDecisionMaker?: string | null
  whenCanDecisionMakerDecide?: WhenCanDecisionMakerDecideEnum | null
  altDecisionMakerFirstName?: string | null
  altDecisionMakerLastName?: string | null
  altDecisionMakerEmail?: string | null
  altDecisionMakerPhone?: string | null
  altDecisionMakerAddressStreet?: string | null
  altDecisionMakerAddressCity?: string | null
  altDecisionMakerAddressState?: string | null
  altDecisionMakerAddressZip?: string | null
  updatedAt?: string | null
  cprPreferenceToday?: CprPreferenceTodayEnum | null
  ventilationPreferenceToday?: VentilationPreferenceTodayEnum | null
  longTermFeedingToday?: LongTermFeedingTodayEnum | null
  livingSituationOther?: string | null
  dialysisTreatmentToday?: DialysisTreatmentTodayEnum | null
  completedAt?: string | null
  sharedPlan?: boolean | null
  wantsThirdDecisionMaker?: string | null
  thirdDecisionMakerFirstName?: string | null
  thirdDecisionMakerLastName?: string | null
  thirdDecisionMakerEmail?: string | null
  thirdDecisionMakerPhone?: string | null
  thirdDecisionMakerAddressStreet?: string | null
  thirdDecisionMakerAddressCity?: string | null
  thirdDecisionMakerAddressState?: string | null
  thirdDecisionMakerAddressZip?: string | null
  importantToMeOther?: string | null
  thirdDecisionMakerRelationship?: DecisionMakerRelationshipEnum | null
  altDecisionMakerRelationship?: DecisionMakerRelationshipEnum | null
  altDecisionMakerRelationshipOther?: string | null
  thirdDecisionMakerRelationshipOther?: string | null
  interestedInPalliativeCare?: YesNoNotSureEnum | null
  interestedInHospiceCare?: YesNoNotSureEnum | null
  age?: number | null
  slug?: string | null
  affordMedications?: string | null
  hasPlanViewed?: boolean | null
  healthIs?: HealthIsEnum | null
  doOnYourOwn?: Array<DoOnYourOwnEnum | null> | null
  doOnYourOwnWithoutHelp?: Array<DoOnYourOwnWithoutHelpEnum | null> | null
  personalLossOrMisfortune?: PersonalLossOrMisfortuneEnum | null
  takeCareSomeoneWasSeriouslyIll?: TakeCareSomeoneWasSeriouslyIllEnum | null
  knowWhatAdvanceCarePlanningIs?: number | null
  aboutWhoCanBeDecisionMaker?: number | null
  aboutWhatMakesGoodDecisionMaker?: number | null
  informedAboutFlexibilityDecisionMaker?: number | null
  comfortLevelBecomeIll?: number | null
  worriedFutureIllnesses?: number | null
  decisionMakerKindOfMedicalYouWouldWant?: number | null
  healthcareProviderKindOfMedicalYouWouldWant?: number | null
  medicalAttorneyLivingWillAdvanceDirective?: MedicalAttorneyLivingWillAdvanceDirectiveEnum | null
  statementMostAdvanceCarePlanning?: StatementMostAdvanceCarePlanningEnum | null
  statementMostAdvanceCarePlanningNoneText?: string | null
  postAcpDigitalAdvanceCarePlanning?: PostAcpDigitalAdvanceCarePlanningEnum | null
  postAcpKnowWhatAdvanceCarePlanningIs?: number | null
  postAcpAboutWhoCanBeDecisionMaker?: number | null
  postAcpAboutWhatMakesGoodDecisionMaker?: number | null
  postAcpInformedAboutFlexibilityDecisionMaker?: number | null
  postAcpComfortLevelBecomeIll?: number | null
  postAcpWorriedFutureIllnesses?: number | null
  postAcpDecisionMakerKindOfMedicalYouWouldWant?: number | null
  postAcpHealthcareProviderKindOfMedicalYouWouldWant?: number | null
  postAcpHealthcareSystemTrust?: number | null
  postAcpOnlineFormat?: PostAcpOnlineFormatEnum | null
  postAcpOnlineFormatOther?: string | null
  postAcpMedicalEducationPrefer?: PostAcpMedicalEducationPreferEnum | null
  postAcpMedicalEducationPreferOther?: string | null
  postAcpInfoAboutCPR?: PostAcpInfoAboutCprEnum | null
  postAcpInfoAboutCPROther?: string | null
  postAcpStatementMostAdvanceCarePlanning?: StatementMostAdvanceCarePlanningEnum | null
  postAcpStatementMostAdvanceCarePlanningNoneText?: string | null
  postAcpUseSystemFrequently?: PostAcpSystemUsabilityScale | null
  postAcpSystemUnnecessarilyComplex?: PostAcpSystemUsabilityScale | null
  postAcpSystemWasEasyUse?: PostAcpSystemUsabilityScale | null
  postAcpSupportTechnicalPerson?: PostAcpSystemUsabilityScale | null
  postAcpVariousFunctionsWellIntegrated?: PostAcpSystemUsabilityScale | null
  postAcpTooMuchInconsistency?: PostAcpSystemUsabilityScale | null
  postAcpMostPeopleWouldLearn?: PostAcpSystemUsabilityScale | null
  postAcpSystemVeryCumbersomeToUse?: PostAcpSystemUsabilityScale | null
  postAcpConfidentUsingTheSystem?: PostAcpSystemUsabilityScale | null
  postAcpLearnLotPfThingsBefore?: PostAcpSystemUsabilityScale | null
  schedulingACPinformation?: string | null
  patientSignatureFirstName?: string | null
  patientSignatureLastName?: string | null
  patientSignatureDate?: string | null
  address?: string | null
  city?: string | null
  postal?: string | null
  witness1SignatureFirstName?: string | null
  witness1SignatureLastName?: string | null
  witness1SignatureEmail?: string | null
  witness1SignatureDate?: string | null
  witness1LocationType?: WitnessSignatureLocationEnum | null
  witness2SignatureFirstName?: string | null
  witness2SignatureLastName?: string | null
  witness2SignatureEmail?: string | null
  witness2SignatureDate?: string | null
  witness2LocationType?: WitnessSignatureLocationEnum | null
  guideTemplate?: {
    __typename?: 'GuideTemplate'
    id?: string | null
    name?: string | null
    isPlatformDefault?: boolean | null
    sections: Array<{
      __typename?: 'GuideTemplateSection'
      sectionId: GuideTemplateSectionIdEnum
      sectionName: string
      questionIds: Array<GuideQuestionIdEnum>
      required: boolean
      sectionIcon?: string | null
      activeStepText?: string | null
    }>
  } | null
  clinic: { __typename?: 'Clinic'; id: string; slug?: string | null; stateId: string }
  planShares?: Array<{ __typename?: 'PlanShare'; sendToEmail?: string | null; dateSent?: string | null } | null> | null
  stateVersion?: {
    __typename?: 'State'
    id?: string | null
    abbreviation?: string | null
    name?: string | null
    advanceDirectiveVideo?: string | null
    medicalCareScenariosVideo?: string | null
    hasESign?: boolean | null
    hasPaperCopy?: boolean | null
    hasNotary?: boolean | null
    linkToSigningRequirements?: string | null
  } | null
  actionHistory?: Array<{
    __typename?: 'PatientActionHistoryItem'
    name: PatientActionHistoryItemEnum
    value?: string | null
    createdAt?: any | null
  }> | null
  readiness?: Array<{
    __typename?: 'PatientReadinessSurveyResponse'
    answer: SubmitReadinessMpoResponseEnum
    otherAnswer?: string | null
    createdAt?: string | null
  }> | null
}

export type StateFragmentFragment = {
  __typename?: 'State'
  id?: string | null
  abbreviation?: string | null
  name?: string | null
  advanceDirectiveVideo?: string | null
  medicalCareScenariosVideo?: string | null
  hasESign?: boolean | null
  hasPaperCopy?: boolean | null
  hasNotary?: boolean | null
  linkToSigningRequirements?: string | null
}

export type UserFragmentFragment = {
  __typename?: 'UserProfile'
  id?: string | null
  email?: string | null
  roles?: Array<RoleTypeEnum | null> | null
  lang?: LangTypeEnum | null
  firstName?: string | null
  lastName?: string | null
  phoneNumber?: string | null
  createdAt?: string | null
  permission?: {
    __typename?: 'Permission'
    id?: string | null
    title?: string | null
    accesses: Array<{ __typename?: 'Access'; id: string; title: AccessTypeEnum }>
  } | null
}

export type AgreeToKodaTermsMutationVariables = Exact<{ [key: string]: never }>

export type AgreeToKodaTermsMutation = {
  __typename?: 'Mutation'
  agreeToKodaTerms?: { __typename?: 'MutationResponse'; success?: boolean | null } | null
}

export type ChangePasswordMutationVariables = Exact<{
  oldPassword: Scalars['String']
  newPassword: Scalars['String']
}>

export type ChangePasswordMutation = { __typename?: 'Mutation'; changePassword?: boolean | null }

export type CompleteESignMutationVariables = Exact<{
  patientId: Scalars['ID']
  params: PlanSignaturesInput
  s3Key: Scalars['String']
}>

export type CompleteESignMutation = {
  __typename?: 'Mutation'
  completeESign?: {
    __typename?: 'Patient'
    id: string
    firstName?: string | null
    lastName?: string | null
    email?: string | null
    phoneNumber?: string | null
    startTime?: string | null
    hasOfficialPlan?: boolean | null
    agreeToTerms?: string | null
    userId: string
    decisionMakerEmail?: string | null
    importantToMe?: Array<ImportantToMeEnum | null> | null
    livingSituation?: LivingSituationEnum | null
    likeToKnowCprAgeGroup?: boolean | null
    cprAgeGroupVideo?: boolean | null
    cprAffectPeopleAgeGroup?: boolean | null
    birthday?: string | null
    additionalCarePreferences?: string | null
    additionalMdmDirections?: string | null
    meaningOfLife?: number | null
    notaryInfo?: boolean | null
    clinicId: string
    spiritualAndReligiousBeliefs?: string | null
    lifeAndValues?: string | null
    receiveCareOther?: string | null
    createdAt?: string | null
    medicalCareScenario1?: TerminConditionScenarioEnum | null
    medicalCareScenario2?: TerminConditionScenarioEnum | null
    medicalCareScenario3?: TerminConditionScenarioEnum | null
    lifeSustainingProcedures?: Array<string | null> | null
    lifeSustainingProceduresOther?: string | null
    notWorthLiving?: Array<NotWorthLivingEnum | null> | null
    notWorthLiving2?: Array<NotWorthLivingEnum | null> | null
    notWorthLiving3?: Array<NotWorthLivingEnum | null> | null
    lastLocation?: Array<LastLocationEnum | null> | null
    cprPreference?: string | null
    personaVacation?: number | null
    personaSocialTies?: number | null
    personaHealthcareExperience?: number | null
    ventilationPreference?: VentilationPreferenceEnum | null
    longTermFeeding?: LongTermFeedingEnum | null
    dialysisTreatment?: DialysisTreatmentEnum | null
    doYouKnowDecisionMaker?: string | null
    decisionMakerFirstName?: string | null
    decisionMakerLastName?: string | null
    decisionMakerName?: string | null
    decisionMakerPhone?: string | null
    decisionMakerRelationship?: DecisionMakerRelationshipEnum | null
    decisionMakerRelationshipOther?: string | null
    decisionMakerStrictness?: DecisionMakerStrictnessEnum | null
    decisionMakerAddressStreet?: string | null
    decisionMakerAddressCity?: string | null
    decisionMakerAddressState?: string | null
    decisionMakerAddressZip?: string | null
    wantsAltDecisionMaker?: string | null
    whenCanDecisionMakerDecide?: WhenCanDecisionMakerDecideEnum | null
    altDecisionMakerFirstName?: string | null
    altDecisionMakerLastName?: string | null
    altDecisionMakerEmail?: string | null
    altDecisionMakerPhone?: string | null
    altDecisionMakerAddressStreet?: string | null
    altDecisionMakerAddressCity?: string | null
    altDecisionMakerAddressState?: string | null
    altDecisionMakerAddressZip?: string | null
    updatedAt?: string | null
    cprPreferenceToday?: CprPreferenceTodayEnum | null
    ventilationPreferenceToday?: VentilationPreferenceTodayEnum | null
    longTermFeedingToday?: LongTermFeedingTodayEnum | null
    livingSituationOther?: string | null
    dialysisTreatmentToday?: DialysisTreatmentTodayEnum | null
    completedAt?: string | null
    sharedPlan?: boolean | null
    wantsThirdDecisionMaker?: string | null
    thirdDecisionMakerFirstName?: string | null
    thirdDecisionMakerLastName?: string | null
    thirdDecisionMakerEmail?: string | null
    thirdDecisionMakerPhone?: string | null
    thirdDecisionMakerAddressStreet?: string | null
    thirdDecisionMakerAddressCity?: string | null
    thirdDecisionMakerAddressState?: string | null
    thirdDecisionMakerAddressZip?: string | null
    importantToMeOther?: string | null
    thirdDecisionMakerRelationship?: DecisionMakerRelationshipEnum | null
    altDecisionMakerRelationship?: DecisionMakerRelationshipEnum | null
    altDecisionMakerRelationshipOther?: string | null
    thirdDecisionMakerRelationshipOther?: string | null
    interestedInPalliativeCare?: YesNoNotSureEnum | null
    interestedInHospiceCare?: YesNoNotSureEnum | null
    age?: number | null
    slug?: string | null
    affordMedications?: string | null
    hasPlanViewed?: boolean | null
    healthIs?: HealthIsEnum | null
    doOnYourOwn?: Array<DoOnYourOwnEnum | null> | null
    doOnYourOwnWithoutHelp?: Array<DoOnYourOwnWithoutHelpEnum | null> | null
    personalLossOrMisfortune?: PersonalLossOrMisfortuneEnum | null
    takeCareSomeoneWasSeriouslyIll?: TakeCareSomeoneWasSeriouslyIllEnum | null
    knowWhatAdvanceCarePlanningIs?: number | null
    aboutWhoCanBeDecisionMaker?: number | null
    aboutWhatMakesGoodDecisionMaker?: number | null
    informedAboutFlexibilityDecisionMaker?: number | null
    comfortLevelBecomeIll?: number | null
    worriedFutureIllnesses?: number | null
    decisionMakerKindOfMedicalYouWouldWant?: number | null
    healthcareProviderKindOfMedicalYouWouldWant?: number | null
    medicalAttorneyLivingWillAdvanceDirective?: MedicalAttorneyLivingWillAdvanceDirectiveEnum | null
    statementMostAdvanceCarePlanning?: StatementMostAdvanceCarePlanningEnum | null
    statementMostAdvanceCarePlanningNoneText?: string | null
    postAcpDigitalAdvanceCarePlanning?: PostAcpDigitalAdvanceCarePlanningEnum | null
    postAcpKnowWhatAdvanceCarePlanningIs?: number | null
    postAcpAboutWhoCanBeDecisionMaker?: number | null
    postAcpAboutWhatMakesGoodDecisionMaker?: number | null
    postAcpInformedAboutFlexibilityDecisionMaker?: number | null
    postAcpComfortLevelBecomeIll?: number | null
    postAcpWorriedFutureIllnesses?: number | null
    postAcpDecisionMakerKindOfMedicalYouWouldWant?: number | null
    postAcpHealthcareProviderKindOfMedicalYouWouldWant?: number | null
    postAcpHealthcareSystemTrust?: number | null
    postAcpOnlineFormat?: PostAcpOnlineFormatEnum | null
    postAcpOnlineFormatOther?: string | null
    postAcpMedicalEducationPrefer?: PostAcpMedicalEducationPreferEnum | null
    postAcpMedicalEducationPreferOther?: string | null
    postAcpInfoAboutCPR?: PostAcpInfoAboutCprEnum | null
    postAcpInfoAboutCPROther?: string | null
    postAcpStatementMostAdvanceCarePlanning?: StatementMostAdvanceCarePlanningEnum | null
    postAcpStatementMostAdvanceCarePlanningNoneText?: string | null
    postAcpUseSystemFrequently?: PostAcpSystemUsabilityScale | null
    postAcpSystemUnnecessarilyComplex?: PostAcpSystemUsabilityScale | null
    postAcpSystemWasEasyUse?: PostAcpSystemUsabilityScale | null
    postAcpSupportTechnicalPerson?: PostAcpSystemUsabilityScale | null
    postAcpVariousFunctionsWellIntegrated?: PostAcpSystemUsabilityScale | null
    postAcpTooMuchInconsistency?: PostAcpSystemUsabilityScale | null
    postAcpMostPeopleWouldLearn?: PostAcpSystemUsabilityScale | null
    postAcpSystemVeryCumbersomeToUse?: PostAcpSystemUsabilityScale | null
    postAcpConfidentUsingTheSystem?: PostAcpSystemUsabilityScale | null
    postAcpLearnLotPfThingsBefore?: PostAcpSystemUsabilityScale | null
    schedulingACPinformation?: string | null
    patientSignatureFirstName?: string | null
    patientSignatureLastName?: string | null
    patientSignatureDate?: string | null
    address?: string | null
    city?: string | null
    postal?: string | null
    witness1SignatureFirstName?: string | null
    witness1SignatureLastName?: string | null
    witness1SignatureEmail?: string | null
    witness1SignatureDate?: string | null
    witness1LocationType?: WitnessSignatureLocationEnum | null
    witness2SignatureFirstName?: string | null
    witness2SignatureLastName?: string | null
    witness2SignatureEmail?: string | null
    witness2SignatureDate?: string | null
    witness2LocationType?: WitnessSignatureLocationEnum | null
    guideTemplate?: {
      __typename?: 'GuideTemplate'
      id?: string | null
      name?: string | null
      isPlatformDefault?: boolean | null
      sections: Array<{
        __typename?: 'GuideTemplateSection'
        sectionId: GuideTemplateSectionIdEnum
        sectionName: string
        questionIds: Array<GuideQuestionIdEnum>
        required: boolean
        sectionIcon?: string | null
        activeStepText?: string | null
      }>
    } | null
    clinic: { __typename?: 'Clinic'; id: string; slug?: string | null; stateId: string }
    planShares?: Array<{
      __typename?: 'PlanShare'
      sendToEmail?: string | null
      dateSent?: string | null
    } | null> | null
    stateVersion?: {
      __typename?: 'State'
      id?: string | null
      abbreviation?: string | null
      name?: string | null
      advanceDirectiveVideo?: string | null
      medicalCareScenariosVideo?: string | null
      hasESign?: boolean | null
      hasPaperCopy?: boolean | null
      hasNotary?: boolean | null
      linkToSigningRequirements?: string | null
    } | null
    actionHistory?: Array<{
      __typename?: 'PatientActionHistoryItem'
      name: PatientActionHistoryItemEnum
      value?: string | null
      createdAt?: any | null
    }> | null
    readiness?: Array<{
      __typename?: 'PatientReadinessSurveyResponse'
      answer: SubmitReadinessMpoResponseEnum
      otherAnswer?: string | null
      createdAt?: string | null
    }> | null
  } | null
}

export type CompleteMultiUploadMutationVariables = Exact<{
  type: MultipartUploadCompleteEnum
  uploadId: Scalars['String']
  key: Scalars['String']
  parts?: InputMaybe<Array<MultipartUploadPartInput> | MultipartUploadPartInput>
}>

export type CompleteMultiUploadMutation = {
  __typename?: 'Mutation'
  completeMultiUpload?: { __typename?: 'MutationResponse'; success?: boolean | null } | null
}

export type CreateAccountFromGuestMutationVariables = Exact<{
  email: Scalars['String']
  password: Scalars['String']
  firstName: Scalars['String']
  lastName: Scalars['String']
}>

export type CreateAccountFromGuestMutation = {
  __typename?: 'Mutation'
  createAccountFromGuest?: {
    __typename?: 'MutationResponse'
    success?: boolean | null
    errors?: Array<string> | null
  } | null
}

export type CreateAdminUserMutationVariables = Exact<{
  firstName: Scalars['String']
  lastName: Scalars['String']
  email: Scalars['String']
  permissionId: Scalars['ID']
  clinicIds: Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
}>

export type CreateAdminUserMutation = {
  __typename?: 'Mutation'
  createAdminUser?: {
    __typename?: 'UserProfile'
    id?: string | null
    email?: string | null
    roles?: Array<RoleTypeEnum | null> | null
    lang?: LangTypeEnum | null
    firstName?: string | null
    lastName?: string | null
    phoneNumber?: string | null
    createdAt?: string | null
    permission?: {
      __typename?: 'Permission'
      id?: string | null
      title?: string | null
      accesses: Array<{ __typename?: 'Access'; id: string; title: AccessTypeEnum }>
    } | null
  } | null
}

export type CreateClinicMutationVariables = Exact<{
  params?: InputMaybe<ClinicParams>
}>

export type CreateClinicMutation = {
  __typename?: 'Mutation'
  createClinic?: {
    __typename?: 'Clinic'
    id: string
    title?: string | null
    description?: string | null
    createdAt?: string | null
    startVideo?: string | null
    updatedAt?: string | null
    logo?: string | null
    street?: string | null
    street2?: string | null
    stateId: string
    v2Signup?: boolean | null
    postal?: string | null
    city?: string | null
    faxNumber?: string | null
    slug?: string | null
    guideTemplateId?: string | null
    state?: { __typename?: 'State'; id?: string | null; name?: string | null; abbreviation?: string | null } | null
    guideTemplate?: {
      __typename?: 'GuideTemplate'
      id?: string | null
      name?: string | null
      isPlatformDefault?: boolean | null
      sections: Array<{
        __typename?: 'GuideTemplateSection'
        sectionId: GuideTemplateSectionIdEnum
        sectionName: string
        questionIds: Array<GuideQuestionIdEnum>
        required: boolean
        sectionIcon?: string | null
        activeStepText?: string | null
      }>
    } | null
  } | null
}

export type CreateClinicUserMutationVariables = Exact<{
  firstName: Scalars['String']
  lastName: Scalars['String']
  email: Scalars['String']
  clinicId: Scalars['String']
  permissionId: Scalars['ID']
}>

export type CreateClinicUserMutation = {
  __typename?: 'Mutation'
  createClinicUser?: {
    __typename?: 'UserProfile'
    id?: string | null
    email?: string | null
    roles?: Array<RoleTypeEnum | null> | null
    lang?: LangTypeEnum | null
    firstName?: string | null
    lastName?: string | null
    phoneNumber?: string | null
    createdAt?: string | null
    permission?: {
      __typename?: 'Permission'
      id?: string | null
      title?: string | null
      accesses: Array<{ __typename?: 'Access'; id: string; title: AccessTypeEnum }>
    } | null
  } | null
}

export type CreateOneActionHistoryItemMutationVariables = Exact<{
  name: PatientActionHistoryItemEnum
  value?: InputMaybe<Scalars['String']>
}>

export type CreateOneActionHistoryItemMutation = {
  __typename?: 'Mutation'
  createOneActionHistoryItem?: {
    __typename?: 'Patient'
    id: string
    firstName?: string | null
    lastName?: string | null
    email?: string | null
    phoneNumber?: string | null
    startTime?: string | null
    hasOfficialPlan?: boolean | null
    agreeToTerms?: string | null
    userId: string
    decisionMakerEmail?: string | null
    importantToMe?: Array<ImportantToMeEnum | null> | null
    livingSituation?: LivingSituationEnum | null
    likeToKnowCprAgeGroup?: boolean | null
    cprAgeGroupVideo?: boolean | null
    cprAffectPeopleAgeGroup?: boolean | null
    birthday?: string | null
    additionalCarePreferences?: string | null
    additionalMdmDirections?: string | null
    meaningOfLife?: number | null
    notaryInfo?: boolean | null
    clinicId: string
    spiritualAndReligiousBeliefs?: string | null
    lifeAndValues?: string | null
    receiveCareOther?: string | null
    createdAt?: string | null
    medicalCareScenario1?: TerminConditionScenarioEnum | null
    medicalCareScenario2?: TerminConditionScenarioEnum | null
    medicalCareScenario3?: TerminConditionScenarioEnum | null
    lifeSustainingProcedures?: Array<string | null> | null
    lifeSustainingProceduresOther?: string | null
    notWorthLiving?: Array<NotWorthLivingEnum | null> | null
    notWorthLiving2?: Array<NotWorthLivingEnum | null> | null
    notWorthLiving3?: Array<NotWorthLivingEnum | null> | null
    lastLocation?: Array<LastLocationEnum | null> | null
    cprPreference?: string | null
    personaVacation?: number | null
    personaSocialTies?: number | null
    personaHealthcareExperience?: number | null
    ventilationPreference?: VentilationPreferenceEnum | null
    longTermFeeding?: LongTermFeedingEnum | null
    dialysisTreatment?: DialysisTreatmentEnum | null
    doYouKnowDecisionMaker?: string | null
    decisionMakerFirstName?: string | null
    decisionMakerLastName?: string | null
    decisionMakerName?: string | null
    decisionMakerPhone?: string | null
    decisionMakerRelationship?: DecisionMakerRelationshipEnum | null
    decisionMakerRelationshipOther?: string | null
    decisionMakerStrictness?: DecisionMakerStrictnessEnum | null
    decisionMakerAddressStreet?: string | null
    decisionMakerAddressCity?: string | null
    decisionMakerAddressState?: string | null
    decisionMakerAddressZip?: string | null
    wantsAltDecisionMaker?: string | null
    whenCanDecisionMakerDecide?: WhenCanDecisionMakerDecideEnum | null
    altDecisionMakerFirstName?: string | null
    altDecisionMakerLastName?: string | null
    altDecisionMakerEmail?: string | null
    altDecisionMakerPhone?: string | null
    altDecisionMakerAddressStreet?: string | null
    altDecisionMakerAddressCity?: string | null
    altDecisionMakerAddressState?: string | null
    altDecisionMakerAddressZip?: string | null
    updatedAt?: string | null
    cprPreferenceToday?: CprPreferenceTodayEnum | null
    ventilationPreferenceToday?: VentilationPreferenceTodayEnum | null
    longTermFeedingToday?: LongTermFeedingTodayEnum | null
    livingSituationOther?: string | null
    dialysisTreatmentToday?: DialysisTreatmentTodayEnum | null
    completedAt?: string | null
    sharedPlan?: boolean | null
    wantsThirdDecisionMaker?: string | null
    thirdDecisionMakerFirstName?: string | null
    thirdDecisionMakerLastName?: string | null
    thirdDecisionMakerEmail?: string | null
    thirdDecisionMakerPhone?: string | null
    thirdDecisionMakerAddressStreet?: string | null
    thirdDecisionMakerAddressCity?: string | null
    thirdDecisionMakerAddressState?: string | null
    thirdDecisionMakerAddressZip?: string | null
    importantToMeOther?: string | null
    thirdDecisionMakerRelationship?: DecisionMakerRelationshipEnum | null
    altDecisionMakerRelationship?: DecisionMakerRelationshipEnum | null
    altDecisionMakerRelationshipOther?: string | null
    thirdDecisionMakerRelationshipOther?: string | null
    interestedInPalliativeCare?: YesNoNotSureEnum | null
    interestedInHospiceCare?: YesNoNotSureEnum | null
    age?: number | null
    slug?: string | null
    affordMedications?: string | null
    hasPlanViewed?: boolean | null
    healthIs?: HealthIsEnum | null
    doOnYourOwn?: Array<DoOnYourOwnEnum | null> | null
    doOnYourOwnWithoutHelp?: Array<DoOnYourOwnWithoutHelpEnum | null> | null
    personalLossOrMisfortune?: PersonalLossOrMisfortuneEnum | null
    takeCareSomeoneWasSeriouslyIll?: TakeCareSomeoneWasSeriouslyIllEnum | null
    knowWhatAdvanceCarePlanningIs?: number | null
    aboutWhoCanBeDecisionMaker?: number | null
    aboutWhatMakesGoodDecisionMaker?: number | null
    informedAboutFlexibilityDecisionMaker?: number | null
    comfortLevelBecomeIll?: number | null
    worriedFutureIllnesses?: number | null
    decisionMakerKindOfMedicalYouWouldWant?: number | null
    healthcareProviderKindOfMedicalYouWouldWant?: number | null
    medicalAttorneyLivingWillAdvanceDirective?: MedicalAttorneyLivingWillAdvanceDirectiveEnum | null
    statementMostAdvanceCarePlanning?: StatementMostAdvanceCarePlanningEnum | null
    statementMostAdvanceCarePlanningNoneText?: string | null
    postAcpDigitalAdvanceCarePlanning?: PostAcpDigitalAdvanceCarePlanningEnum | null
    postAcpKnowWhatAdvanceCarePlanningIs?: number | null
    postAcpAboutWhoCanBeDecisionMaker?: number | null
    postAcpAboutWhatMakesGoodDecisionMaker?: number | null
    postAcpInformedAboutFlexibilityDecisionMaker?: number | null
    postAcpComfortLevelBecomeIll?: number | null
    postAcpWorriedFutureIllnesses?: number | null
    postAcpDecisionMakerKindOfMedicalYouWouldWant?: number | null
    postAcpHealthcareProviderKindOfMedicalYouWouldWant?: number | null
    postAcpHealthcareSystemTrust?: number | null
    postAcpOnlineFormat?: PostAcpOnlineFormatEnum | null
    postAcpOnlineFormatOther?: string | null
    postAcpMedicalEducationPrefer?: PostAcpMedicalEducationPreferEnum | null
    postAcpMedicalEducationPreferOther?: string | null
    postAcpInfoAboutCPR?: PostAcpInfoAboutCprEnum | null
    postAcpInfoAboutCPROther?: string | null
    postAcpStatementMostAdvanceCarePlanning?: StatementMostAdvanceCarePlanningEnum | null
    postAcpStatementMostAdvanceCarePlanningNoneText?: string | null
    postAcpUseSystemFrequently?: PostAcpSystemUsabilityScale | null
    postAcpSystemUnnecessarilyComplex?: PostAcpSystemUsabilityScale | null
    postAcpSystemWasEasyUse?: PostAcpSystemUsabilityScale | null
    postAcpSupportTechnicalPerson?: PostAcpSystemUsabilityScale | null
    postAcpVariousFunctionsWellIntegrated?: PostAcpSystemUsabilityScale | null
    postAcpTooMuchInconsistency?: PostAcpSystemUsabilityScale | null
    postAcpMostPeopleWouldLearn?: PostAcpSystemUsabilityScale | null
    postAcpSystemVeryCumbersomeToUse?: PostAcpSystemUsabilityScale | null
    postAcpConfidentUsingTheSystem?: PostAcpSystemUsabilityScale | null
    postAcpLearnLotPfThingsBefore?: PostAcpSystemUsabilityScale | null
    schedulingACPinformation?: string | null
    patientSignatureFirstName?: string | null
    patientSignatureLastName?: string | null
    patientSignatureDate?: string | null
    address?: string | null
    city?: string | null
    postal?: string | null
    witness1SignatureFirstName?: string | null
    witness1SignatureLastName?: string | null
    witness1SignatureEmail?: string | null
    witness1SignatureDate?: string | null
    witness1LocationType?: WitnessSignatureLocationEnum | null
    witness2SignatureFirstName?: string | null
    witness2SignatureLastName?: string | null
    witness2SignatureEmail?: string | null
    witness2SignatureDate?: string | null
    witness2LocationType?: WitnessSignatureLocationEnum | null
    guideTemplate?: {
      __typename?: 'GuideTemplate'
      id?: string | null
      name?: string | null
      isPlatformDefault?: boolean | null
      sections: Array<{
        __typename?: 'GuideTemplateSection'
        sectionId: GuideTemplateSectionIdEnum
        sectionName: string
        questionIds: Array<GuideQuestionIdEnum>
        required: boolean
        sectionIcon?: string | null
        activeStepText?: string | null
      }>
    } | null
    clinic: { __typename?: 'Clinic'; id: string; slug?: string | null; stateId: string }
    planShares?: Array<{
      __typename?: 'PlanShare'
      sendToEmail?: string | null
      dateSent?: string | null
    } | null> | null
    stateVersion?: {
      __typename?: 'State'
      id?: string | null
      abbreviation?: string | null
      name?: string | null
      advanceDirectiveVideo?: string | null
      medicalCareScenariosVideo?: string | null
      hasESign?: boolean | null
      hasPaperCopy?: boolean | null
      hasNotary?: boolean | null
      linkToSigningRequirements?: string | null
    } | null
    actionHistory?: Array<{
      __typename?: 'PatientActionHistoryItem'
      name: PatientActionHistoryItemEnum
      value?: string | null
      createdAt?: any | null
    }> | null
    readiness?: Array<{
      __typename?: 'PatientReadinessSurveyResponse'
      answer: SubmitReadinessMpoResponseEnum
      otherAnswer?: string | null
      createdAt?: string | null
    }> | null
  } | null
}

export type CreateOneReadinessMpoResponseMutationVariables = Exact<{
  answer: SubmitReadinessMpoResponseEnum
  otherAnswer?: InputMaybe<Scalars['String']>
}>

export type CreateOneReadinessMpoResponseMutation = {
  __typename?: 'Mutation'
  createOneReadinessMPOResponse?: {
    __typename?: 'Patient'
    id: string
    firstName?: string | null
    lastName?: string | null
    email?: string | null
    phoneNumber?: string | null
    startTime?: string | null
    hasOfficialPlan?: boolean | null
    agreeToTerms?: string | null
    userId: string
    decisionMakerEmail?: string | null
    importantToMe?: Array<ImportantToMeEnum | null> | null
    livingSituation?: LivingSituationEnum | null
    likeToKnowCprAgeGroup?: boolean | null
    cprAgeGroupVideo?: boolean | null
    cprAffectPeopleAgeGroup?: boolean | null
    birthday?: string | null
    additionalCarePreferences?: string | null
    additionalMdmDirections?: string | null
    meaningOfLife?: number | null
    notaryInfo?: boolean | null
    clinicId: string
    spiritualAndReligiousBeliefs?: string | null
    lifeAndValues?: string | null
    receiveCareOther?: string | null
    createdAt?: string | null
    medicalCareScenario1?: TerminConditionScenarioEnum | null
    medicalCareScenario2?: TerminConditionScenarioEnum | null
    medicalCareScenario3?: TerminConditionScenarioEnum | null
    lifeSustainingProcedures?: Array<string | null> | null
    lifeSustainingProceduresOther?: string | null
    notWorthLiving?: Array<NotWorthLivingEnum | null> | null
    notWorthLiving2?: Array<NotWorthLivingEnum | null> | null
    notWorthLiving3?: Array<NotWorthLivingEnum | null> | null
    lastLocation?: Array<LastLocationEnum | null> | null
    cprPreference?: string | null
    personaVacation?: number | null
    personaSocialTies?: number | null
    personaHealthcareExperience?: number | null
    ventilationPreference?: VentilationPreferenceEnum | null
    longTermFeeding?: LongTermFeedingEnum | null
    dialysisTreatment?: DialysisTreatmentEnum | null
    doYouKnowDecisionMaker?: string | null
    decisionMakerFirstName?: string | null
    decisionMakerLastName?: string | null
    decisionMakerName?: string | null
    decisionMakerPhone?: string | null
    decisionMakerRelationship?: DecisionMakerRelationshipEnum | null
    decisionMakerRelationshipOther?: string | null
    decisionMakerStrictness?: DecisionMakerStrictnessEnum | null
    decisionMakerAddressStreet?: string | null
    decisionMakerAddressCity?: string | null
    decisionMakerAddressState?: string | null
    decisionMakerAddressZip?: string | null
    wantsAltDecisionMaker?: string | null
    whenCanDecisionMakerDecide?: WhenCanDecisionMakerDecideEnum | null
    altDecisionMakerFirstName?: string | null
    altDecisionMakerLastName?: string | null
    altDecisionMakerEmail?: string | null
    altDecisionMakerPhone?: string | null
    altDecisionMakerAddressStreet?: string | null
    altDecisionMakerAddressCity?: string | null
    altDecisionMakerAddressState?: string | null
    altDecisionMakerAddressZip?: string | null
    updatedAt?: string | null
    cprPreferenceToday?: CprPreferenceTodayEnum | null
    ventilationPreferenceToday?: VentilationPreferenceTodayEnum | null
    longTermFeedingToday?: LongTermFeedingTodayEnum | null
    livingSituationOther?: string | null
    dialysisTreatmentToday?: DialysisTreatmentTodayEnum | null
    completedAt?: string | null
    sharedPlan?: boolean | null
    wantsThirdDecisionMaker?: string | null
    thirdDecisionMakerFirstName?: string | null
    thirdDecisionMakerLastName?: string | null
    thirdDecisionMakerEmail?: string | null
    thirdDecisionMakerPhone?: string | null
    thirdDecisionMakerAddressStreet?: string | null
    thirdDecisionMakerAddressCity?: string | null
    thirdDecisionMakerAddressState?: string | null
    thirdDecisionMakerAddressZip?: string | null
    importantToMeOther?: string | null
    thirdDecisionMakerRelationship?: DecisionMakerRelationshipEnum | null
    altDecisionMakerRelationship?: DecisionMakerRelationshipEnum | null
    altDecisionMakerRelationshipOther?: string | null
    thirdDecisionMakerRelationshipOther?: string | null
    interestedInPalliativeCare?: YesNoNotSureEnum | null
    interestedInHospiceCare?: YesNoNotSureEnum | null
    age?: number | null
    slug?: string | null
    affordMedications?: string | null
    hasPlanViewed?: boolean | null
    healthIs?: HealthIsEnum | null
    doOnYourOwn?: Array<DoOnYourOwnEnum | null> | null
    doOnYourOwnWithoutHelp?: Array<DoOnYourOwnWithoutHelpEnum | null> | null
    personalLossOrMisfortune?: PersonalLossOrMisfortuneEnum | null
    takeCareSomeoneWasSeriouslyIll?: TakeCareSomeoneWasSeriouslyIllEnum | null
    knowWhatAdvanceCarePlanningIs?: number | null
    aboutWhoCanBeDecisionMaker?: number | null
    aboutWhatMakesGoodDecisionMaker?: number | null
    informedAboutFlexibilityDecisionMaker?: number | null
    comfortLevelBecomeIll?: number | null
    worriedFutureIllnesses?: number | null
    decisionMakerKindOfMedicalYouWouldWant?: number | null
    healthcareProviderKindOfMedicalYouWouldWant?: number | null
    medicalAttorneyLivingWillAdvanceDirective?: MedicalAttorneyLivingWillAdvanceDirectiveEnum | null
    statementMostAdvanceCarePlanning?: StatementMostAdvanceCarePlanningEnum | null
    statementMostAdvanceCarePlanningNoneText?: string | null
    postAcpDigitalAdvanceCarePlanning?: PostAcpDigitalAdvanceCarePlanningEnum | null
    postAcpKnowWhatAdvanceCarePlanningIs?: number | null
    postAcpAboutWhoCanBeDecisionMaker?: number | null
    postAcpAboutWhatMakesGoodDecisionMaker?: number | null
    postAcpInformedAboutFlexibilityDecisionMaker?: number | null
    postAcpComfortLevelBecomeIll?: number | null
    postAcpWorriedFutureIllnesses?: number | null
    postAcpDecisionMakerKindOfMedicalYouWouldWant?: number | null
    postAcpHealthcareProviderKindOfMedicalYouWouldWant?: number | null
    postAcpHealthcareSystemTrust?: number | null
    postAcpOnlineFormat?: PostAcpOnlineFormatEnum | null
    postAcpOnlineFormatOther?: string | null
    postAcpMedicalEducationPrefer?: PostAcpMedicalEducationPreferEnum | null
    postAcpMedicalEducationPreferOther?: string | null
    postAcpInfoAboutCPR?: PostAcpInfoAboutCprEnum | null
    postAcpInfoAboutCPROther?: string | null
    postAcpStatementMostAdvanceCarePlanning?: StatementMostAdvanceCarePlanningEnum | null
    postAcpStatementMostAdvanceCarePlanningNoneText?: string | null
    postAcpUseSystemFrequently?: PostAcpSystemUsabilityScale | null
    postAcpSystemUnnecessarilyComplex?: PostAcpSystemUsabilityScale | null
    postAcpSystemWasEasyUse?: PostAcpSystemUsabilityScale | null
    postAcpSupportTechnicalPerson?: PostAcpSystemUsabilityScale | null
    postAcpVariousFunctionsWellIntegrated?: PostAcpSystemUsabilityScale | null
    postAcpTooMuchInconsistency?: PostAcpSystemUsabilityScale | null
    postAcpMostPeopleWouldLearn?: PostAcpSystemUsabilityScale | null
    postAcpSystemVeryCumbersomeToUse?: PostAcpSystemUsabilityScale | null
    postAcpConfidentUsingTheSystem?: PostAcpSystemUsabilityScale | null
    postAcpLearnLotPfThingsBefore?: PostAcpSystemUsabilityScale | null
    schedulingACPinformation?: string | null
    patientSignatureFirstName?: string | null
    patientSignatureLastName?: string | null
    patientSignatureDate?: string | null
    address?: string | null
    city?: string | null
    postal?: string | null
    witness1SignatureFirstName?: string | null
    witness1SignatureLastName?: string | null
    witness1SignatureEmail?: string | null
    witness1SignatureDate?: string | null
    witness1LocationType?: WitnessSignatureLocationEnum | null
    witness2SignatureFirstName?: string | null
    witness2SignatureLastName?: string | null
    witness2SignatureEmail?: string | null
    witness2SignatureDate?: string | null
    witness2LocationType?: WitnessSignatureLocationEnum | null
    guideTemplate?: {
      __typename?: 'GuideTemplate'
      id?: string | null
      name?: string | null
      isPlatformDefault?: boolean | null
      sections: Array<{
        __typename?: 'GuideTemplateSection'
        sectionId: GuideTemplateSectionIdEnum
        sectionName: string
        questionIds: Array<GuideQuestionIdEnum>
        required: boolean
        sectionIcon?: string | null
        activeStepText?: string | null
      }>
    } | null
    clinic: { __typename?: 'Clinic'; id: string; slug?: string | null; stateId: string }
    planShares?: Array<{
      __typename?: 'PlanShare'
      sendToEmail?: string | null
      dateSent?: string | null
    } | null> | null
    stateVersion?: {
      __typename?: 'State'
      id?: string | null
      abbreviation?: string | null
      name?: string | null
      advanceDirectiveVideo?: string | null
      medicalCareScenariosVideo?: string | null
      hasESign?: boolean | null
      hasPaperCopy?: boolean | null
      hasNotary?: boolean | null
      linkToSigningRequirements?: string | null
    } | null
    actionHistory?: Array<{
      __typename?: 'PatientActionHistoryItem'
      name: PatientActionHistoryItemEnum
      value?: string | null
      createdAt?: any | null
    }> | null
    readiness?: Array<{
      __typename?: 'PatientReadinessSurveyResponse'
      answer: SubmitReadinessMpoResponseEnum
      otherAnswer?: string | null
      createdAt?: string | null
    }> | null
  } | null
}

export type CreatePermissionMutationVariables = Exact<{
  title: Scalars['String']
  description: Scalars['String']
  accesses: Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
}>

export type CreatePermissionMutation = {
  __typename?: 'Mutation'
  createPermission?: { __typename?: 'Permission'; id?: string | null } | null
}

export type DeleteAdminUserMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type DeleteAdminUserMutation = {
  __typename?: 'Mutation'
  deleteAdminUser?: { __typename?: 'MutationResponse'; success?: boolean | null; errors?: Array<string> | null } | null
}

export type DeletePatientUserMutationVariables = Exact<{
  patientId: Scalars['ID']
}>

export type DeletePatientUserMutation = {
  __typename?: 'Mutation'
  deletePatientUser?: {
    __typename?: 'MutationResponse'
    success?: boolean | null
    errors?: Array<string> | null
  } | null
}

export type DeletePlanAttachmentMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type DeletePlanAttachmentMutation = {
  __typename?: 'Mutation'
  deletePlanAttachment?: {
    __typename?: 'MutationResponse'
    success?: boolean | null
    errors?: Array<string> | null
  } | null
}

export type EmailDirectiveMutationVariables = Exact<{
  email?: InputMaybe<Scalars['String']>
  decisionMakerEmail?: InputMaybe<Scalars['String']>
  otherEmails?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>
  personalNote?: InputMaybe<Scalars['String']>
}>

export type EmailDirectiveMutation = {
  __typename?: 'Mutation'
  emailDirective?: { __typename?: 'MutationResponse'; success?: boolean | null; errors?: Array<string> | null } | null
}

export type FaxClinicMutationVariables = Exact<{
  base64String: Scalars['String']
  faxToNumber: Scalars['String']
  patientId: Scalars['ID']
  clinicId: Scalars['ID']
  documentType: Scalars['String']
}>

export type FaxClinicMutation = {
  __typename?: 'Mutation'
  faxClinic?: { __typename?: 'MutationResponse'; success?: boolean | null; errors?: Array<string> | null } | null
}

export type GeneratePatientDownloadUrlMutationVariables = Exact<{
  patientId: Scalars['ID']
}>

export type GeneratePatientDownloadUrlMutation = { __typename?: 'Mutation'; generatePatientDownloadUrl?: string | null }

export type GetPresignedUploadMultiPartMutationVariables = Exact<{
  contentType: Scalars['String']
  parts: Scalars['Int']
}>

export type GetPresignedUploadMultiPartMutation = {
  __typename?: 'Mutation'
  getPresignedUploadMultiPart?: {
    __typename?: 'PresignedMultipartResult'
    key?: string | null
    uploadId?: string | null
    urls?: Array<string | null> | null
  } | null
}

export type GetPresignedUploadUrlMutationVariables = Exact<{
  contentType: Scalars['String']
}>

export type GetPresignedUploadUrlMutation = {
  __typename?: 'Mutation'
  getPresignedUploadUrl?: { __typename?: 'PresignedUploadResponse'; url?: string | null; fields?: string | null } | null
}

export type InviteManyPatientsMutationVariables = Exact<{
  clinicId: Scalars['ID']
  patients: Array<InvitePatientInput> | InvitePatientInput
}>

export type InviteManyPatientsMutation = {
  __typename?: 'Mutation'
  inviteManyPatients?: {
    __typename?: 'MutationResponse'
    success?: boolean | null
    message?: string | null
    errors?: Array<string> | null
  } | null
}

export type InviteOnePatientMutationVariables = Exact<{
  email: Scalars['String']
  stateId?: InputMaybe<Scalars['String']>
  clinicId: Scalars['String']
  birthday: Scalars['String']
  firstName: Scalars['String']
  lastName: Scalars['String']
  referralDate: Scalars['String']
  freshworksId?: InputMaybe<Scalars['String']>
}>

export type InviteOnePatientMutation = {
  __typename?: 'Mutation'
  inviteOnePatient?: { __typename?: 'Patient'; id: string } | null
}

export type LogActivityItemMutationVariables = Exact<{
  importance?: InputMaybe<Scalars['Int']>
  userId: Scalars['String']
  patientId?: InputMaybe<Scalars['String']>
  clinicId?: InputMaybe<Scalars['String']>
  content?: InputMaybe<Scalars['String']>
  key: ActivityLogKeyEnum
}>

export type LogActivityItemMutation = {
  __typename?: 'Mutation'
  logActivityItem?: { __typename?: 'MutationResponse'; success?: boolean | null; errors?: Array<string> | null } | null
}

export type LogEventMutationVariables = Exact<{
  eventName: Scalars['String']
  metaData?: InputMaybe<Scalars['String']>
  mixpanelDistinctId?: InputMaybe<Scalars['String']>
}>

export type LogEventMutation = {
  __typename?: 'Mutation'
  logEvent?: { __typename?: 'MutationResponse'; success?: boolean | null; errors?: Array<string> | null } | null
}

export type LogWakeForestEventMutationVariables = Exact<{
  userId?: InputMaybe<Scalars['String']>
  content?: InputMaybe<Scalars['String']>
  key?: InputMaybe<Scalars['String']>
  patientId?: InputMaybe<Scalars['String']>
  clinicSlug?: InputMaybe<Scalars['String']>
  externalId?: InputMaybe<Scalars['String']>
}>

export type LogWakeForestEventMutation = {
  __typename?: 'Mutation'
  logWakeForestEvent?: { __typename?: 'WakeForestEvent'; id: string } | null
}

export type LoginMutationVariables = Exact<{
  params: AuthenticateParamsInput
}>

export type LoginMutation = {
  __typename?: 'Mutation'
  login?: {
    __typename?: 'LoginResponse'
    mfaRequired?: boolean | null
    sessionId?: string | null
    tokens?: { __typename?: 'Tokens'; refreshToken?: string | null; accessToken?: string | null } | null
  } | null
}

export type LogoutMutationVariables = Exact<{ [key: string]: never }>

export type LogoutMutation = { __typename?: 'Mutation'; logout?: boolean | null }

export type NewPatientSignupMutationVariables = Exact<{
  email?: InputMaybe<Scalars['String']>
  password?: InputMaybe<Scalars['String']>
  slug: Scalars['String']
  firstName?: InputMaybe<Scalars['String']>
  lastName?: InputMaybe<Scalars['String']>
  birthday?: InputMaybe<Scalars['String']>
  stateId?: InputMaybe<Scalars['String']>
  externalId?: InputMaybe<Scalars['String']>
  phoneNumber?: InputMaybe<Scalars['String']>
}>

export type NewPatientSignupMutation = {
  __typename?: 'Mutation'
  newPatientSignup?: {
    __typename?: 'NewPatientResponse'
    userId: string
    patientId: string
    accessToken?: string | null
    refreshToken?: string | null
  } | null
}

export type ResendMfaMutationVariables = Exact<{
  email: Scalars['String']
}>

export type ResendMfaMutation = {
  __typename?: 'Mutation'
  resendMfa?: { __typename?: 'MutationResponse'; success?: boolean | null } | null
}

export type ResetPasswordMutationVariables = Exact<{
  newPassword: Scalars['String']
  token: Scalars['String']
}>

export type ResetPasswordMutation = {
  __typename?: 'Mutation'
  resetPassword?: {
    __typename?: 'LoginResult'
    sessionId?: string | null
    tokens?: { __typename?: 'Tokens'; refreshToken?: string | null; accessToken?: string | null } | null
  } | null
}

export type RevertEsignMutationVariables = Exact<{
  params: PatientInput
}>

export type RevertEsignMutation = {
  __typename?: 'Mutation'
  revertEsign?: {
    __typename?: 'Patient'
    id: string
    firstName?: string | null
    lastName?: string | null
    email?: string | null
    phoneNumber?: string | null
    startTime?: string | null
    hasOfficialPlan?: boolean | null
    agreeToTerms?: string | null
    userId: string
    decisionMakerEmail?: string | null
    importantToMe?: Array<ImportantToMeEnum | null> | null
    livingSituation?: LivingSituationEnum | null
    likeToKnowCprAgeGroup?: boolean | null
    cprAgeGroupVideo?: boolean | null
    cprAffectPeopleAgeGroup?: boolean | null
    birthday?: string | null
    additionalCarePreferences?: string | null
    additionalMdmDirections?: string | null
    meaningOfLife?: number | null
    notaryInfo?: boolean | null
    clinicId: string
    spiritualAndReligiousBeliefs?: string | null
    lifeAndValues?: string | null
    receiveCareOther?: string | null
    createdAt?: string | null
    medicalCareScenario1?: TerminConditionScenarioEnum | null
    medicalCareScenario2?: TerminConditionScenarioEnum | null
    medicalCareScenario3?: TerminConditionScenarioEnum | null
    lifeSustainingProcedures?: Array<string | null> | null
    lifeSustainingProceduresOther?: string | null
    notWorthLiving?: Array<NotWorthLivingEnum | null> | null
    notWorthLiving2?: Array<NotWorthLivingEnum | null> | null
    notWorthLiving3?: Array<NotWorthLivingEnum | null> | null
    lastLocation?: Array<LastLocationEnum | null> | null
    cprPreference?: string | null
    personaVacation?: number | null
    personaSocialTies?: number | null
    personaHealthcareExperience?: number | null
    ventilationPreference?: VentilationPreferenceEnum | null
    longTermFeeding?: LongTermFeedingEnum | null
    dialysisTreatment?: DialysisTreatmentEnum | null
    doYouKnowDecisionMaker?: string | null
    decisionMakerFirstName?: string | null
    decisionMakerLastName?: string | null
    decisionMakerName?: string | null
    decisionMakerPhone?: string | null
    decisionMakerRelationship?: DecisionMakerRelationshipEnum | null
    decisionMakerRelationshipOther?: string | null
    decisionMakerStrictness?: DecisionMakerStrictnessEnum | null
    decisionMakerAddressStreet?: string | null
    decisionMakerAddressCity?: string | null
    decisionMakerAddressState?: string | null
    decisionMakerAddressZip?: string | null
    wantsAltDecisionMaker?: string | null
    whenCanDecisionMakerDecide?: WhenCanDecisionMakerDecideEnum | null
    altDecisionMakerFirstName?: string | null
    altDecisionMakerLastName?: string | null
    altDecisionMakerEmail?: string | null
    altDecisionMakerPhone?: string | null
    altDecisionMakerAddressStreet?: string | null
    altDecisionMakerAddressCity?: string | null
    altDecisionMakerAddressState?: string | null
    altDecisionMakerAddressZip?: string | null
    updatedAt?: string | null
    cprPreferenceToday?: CprPreferenceTodayEnum | null
    ventilationPreferenceToday?: VentilationPreferenceTodayEnum | null
    longTermFeedingToday?: LongTermFeedingTodayEnum | null
    livingSituationOther?: string | null
    dialysisTreatmentToday?: DialysisTreatmentTodayEnum | null
    completedAt?: string | null
    sharedPlan?: boolean | null
    wantsThirdDecisionMaker?: string | null
    thirdDecisionMakerFirstName?: string | null
    thirdDecisionMakerLastName?: string | null
    thirdDecisionMakerEmail?: string | null
    thirdDecisionMakerPhone?: string | null
    thirdDecisionMakerAddressStreet?: string | null
    thirdDecisionMakerAddressCity?: string | null
    thirdDecisionMakerAddressState?: string | null
    thirdDecisionMakerAddressZip?: string | null
    importantToMeOther?: string | null
    thirdDecisionMakerRelationship?: DecisionMakerRelationshipEnum | null
    altDecisionMakerRelationship?: DecisionMakerRelationshipEnum | null
    altDecisionMakerRelationshipOther?: string | null
    thirdDecisionMakerRelationshipOther?: string | null
    interestedInPalliativeCare?: YesNoNotSureEnum | null
    interestedInHospiceCare?: YesNoNotSureEnum | null
    age?: number | null
    slug?: string | null
    affordMedications?: string | null
    hasPlanViewed?: boolean | null
    healthIs?: HealthIsEnum | null
    doOnYourOwn?: Array<DoOnYourOwnEnum | null> | null
    doOnYourOwnWithoutHelp?: Array<DoOnYourOwnWithoutHelpEnum | null> | null
    personalLossOrMisfortune?: PersonalLossOrMisfortuneEnum | null
    takeCareSomeoneWasSeriouslyIll?: TakeCareSomeoneWasSeriouslyIllEnum | null
    knowWhatAdvanceCarePlanningIs?: number | null
    aboutWhoCanBeDecisionMaker?: number | null
    aboutWhatMakesGoodDecisionMaker?: number | null
    informedAboutFlexibilityDecisionMaker?: number | null
    comfortLevelBecomeIll?: number | null
    worriedFutureIllnesses?: number | null
    decisionMakerKindOfMedicalYouWouldWant?: number | null
    healthcareProviderKindOfMedicalYouWouldWant?: number | null
    medicalAttorneyLivingWillAdvanceDirective?: MedicalAttorneyLivingWillAdvanceDirectiveEnum | null
    statementMostAdvanceCarePlanning?: StatementMostAdvanceCarePlanningEnum | null
    statementMostAdvanceCarePlanningNoneText?: string | null
    postAcpDigitalAdvanceCarePlanning?: PostAcpDigitalAdvanceCarePlanningEnum | null
    postAcpKnowWhatAdvanceCarePlanningIs?: number | null
    postAcpAboutWhoCanBeDecisionMaker?: number | null
    postAcpAboutWhatMakesGoodDecisionMaker?: number | null
    postAcpInformedAboutFlexibilityDecisionMaker?: number | null
    postAcpComfortLevelBecomeIll?: number | null
    postAcpWorriedFutureIllnesses?: number | null
    postAcpDecisionMakerKindOfMedicalYouWouldWant?: number | null
    postAcpHealthcareProviderKindOfMedicalYouWouldWant?: number | null
    postAcpHealthcareSystemTrust?: number | null
    postAcpOnlineFormat?: PostAcpOnlineFormatEnum | null
    postAcpOnlineFormatOther?: string | null
    postAcpMedicalEducationPrefer?: PostAcpMedicalEducationPreferEnum | null
    postAcpMedicalEducationPreferOther?: string | null
    postAcpInfoAboutCPR?: PostAcpInfoAboutCprEnum | null
    postAcpInfoAboutCPROther?: string | null
    postAcpStatementMostAdvanceCarePlanning?: StatementMostAdvanceCarePlanningEnum | null
    postAcpStatementMostAdvanceCarePlanningNoneText?: string | null
    postAcpUseSystemFrequently?: PostAcpSystemUsabilityScale | null
    postAcpSystemUnnecessarilyComplex?: PostAcpSystemUsabilityScale | null
    postAcpSystemWasEasyUse?: PostAcpSystemUsabilityScale | null
    postAcpSupportTechnicalPerson?: PostAcpSystemUsabilityScale | null
    postAcpVariousFunctionsWellIntegrated?: PostAcpSystemUsabilityScale | null
    postAcpTooMuchInconsistency?: PostAcpSystemUsabilityScale | null
    postAcpMostPeopleWouldLearn?: PostAcpSystemUsabilityScale | null
    postAcpSystemVeryCumbersomeToUse?: PostAcpSystemUsabilityScale | null
    postAcpConfidentUsingTheSystem?: PostAcpSystemUsabilityScale | null
    postAcpLearnLotPfThingsBefore?: PostAcpSystemUsabilityScale | null
    schedulingACPinformation?: string | null
    patientSignatureFirstName?: string | null
    patientSignatureLastName?: string | null
    patientSignatureDate?: string | null
    address?: string | null
    city?: string | null
    postal?: string | null
    witness1SignatureFirstName?: string | null
    witness1SignatureLastName?: string | null
    witness1SignatureEmail?: string | null
    witness1SignatureDate?: string | null
    witness1LocationType?: WitnessSignatureLocationEnum | null
    witness2SignatureFirstName?: string | null
    witness2SignatureLastName?: string | null
    witness2SignatureEmail?: string | null
    witness2SignatureDate?: string | null
    witness2LocationType?: WitnessSignatureLocationEnum | null
    guideTemplate?: {
      __typename?: 'GuideTemplate'
      id?: string | null
      name?: string | null
      isPlatformDefault?: boolean | null
      sections: Array<{
        __typename?: 'GuideTemplateSection'
        sectionId: GuideTemplateSectionIdEnum
        sectionName: string
        questionIds: Array<GuideQuestionIdEnum>
        required: boolean
        sectionIcon?: string | null
        activeStepText?: string | null
      }>
    } | null
    clinic: { __typename?: 'Clinic'; id: string; slug?: string | null; stateId: string }
    planShares?: Array<{
      __typename?: 'PlanShare'
      sendToEmail?: string | null
      dateSent?: string | null
    } | null> | null
    stateVersion?: {
      __typename?: 'State'
      id?: string | null
      abbreviation?: string | null
      name?: string | null
      advanceDirectiveVideo?: string | null
      medicalCareScenariosVideo?: string | null
      hasESign?: boolean | null
      hasPaperCopy?: boolean | null
      hasNotary?: boolean | null
      linkToSigningRequirements?: string | null
    } | null
    actionHistory?: Array<{
      __typename?: 'PatientActionHistoryItem'
      name: PatientActionHistoryItemEnum
      value?: string | null
      createdAt?: any | null
    }> | null
    readiness?: Array<{
      __typename?: 'PatientReadinessSurveyResponse'
      answer: SubmitReadinessMpoResponseEnum
      otherAnswer?: string | null
      createdAt?: string | null
    }> | null
  } | null
}

export type SaveClinicMutationVariables = Exact<{
  id: Scalars['ID']
  params?: InputMaybe<ClinicParams>
}>

export type SaveClinicMutation = {
  __typename?: 'Mutation'
  saveClinic?: {
    __typename?: 'Clinic'
    id: string
    title?: string | null
    description?: string | null
    createdAt?: string | null
    startVideo?: string | null
    updatedAt?: string | null
    logo?: string | null
    street?: string | null
    street2?: string | null
    stateId: string
    v2Signup?: boolean | null
    postal?: string | null
    city?: string | null
    faxNumber?: string | null
    slug?: string | null
    guideTemplateId?: string | null
    integrations?: {
      __typename?: 'ClinicIntegrations'
      freshworksCrm?: boolean | null
      freshworksCrmId?: string | null
    } | null
    state?: { __typename?: 'State'; id?: string | null; name?: string | null; abbreviation?: string | null } | null
    guideTemplate?: {
      __typename?: 'GuideTemplate'
      id?: string | null
      name?: string | null
      isPlatformDefault?: boolean | null
      sections: Array<{
        __typename?: 'GuideTemplateSection'
        sectionId: GuideTemplateSectionIdEnum
        sectionName: string
        questionIds: Array<GuideQuestionIdEnum>
        required: boolean
        sectionIcon?: string | null
        activeStepText?: string | null
      }>
    } | null
  } | null
}

export type SaveGuestEmailMutationVariables = Exact<{
  email: Scalars['String']
}>

export type SaveGuestEmailMutation = {
  __typename?: 'Mutation'
  saveGuestEmail?: { __typename?: 'MutationResponse'; success?: boolean | null; errors?: Array<string> | null } | null
}

export type SavePatientProfileMutationVariables = Exact<{
  userId: Scalars['ID']
  params?: InputMaybe<UserParams>
}>

export type SavePatientProfileMutation = {
  __typename?: 'Mutation'
  savePatientProfile?: {
    __typename?: 'UserProfile'
    id?: string | null
    email?: string | null
    roles?: Array<RoleTypeEnum | null> | null
    lang?: LangTypeEnum | null
    firstName?: string | null
    lastName?: string | null
    phoneNumber?: string | null
    createdAt?: string | null
    permission?: {
      __typename?: 'Permission'
      id?: string | null
      title?: string | null
      accesses: Array<{ __typename?: 'Access'; id: string; title: AccessTypeEnum }>
    } | null
  } | null
}

export type SaveStateMutationVariables = Exact<{
  id: Scalars['ID']
  params?: InputMaybe<StateInput>
}>

export type SaveStateMutation = {
  __typename?: 'Mutation'
  saveState?: {
    __typename?: 'State'
    id?: string | null
    abbreviation?: string | null
    name?: string | null
    advanceDirectiveVideo?: string | null
    medicalCareScenariosVideo?: string | null
    hasESign?: boolean | null
    hasPaperCopy?: boolean | null
    hasNotary?: boolean | null
    linkToSigningRequirements?: string | null
  } | null
}

export type SendResetPasswordEmailMutationVariables = Exact<{
  email: Scalars['String']
}>

export type SendResetPasswordEmailMutation = { __typename?: 'Mutation'; sendResetPasswordEmail?: boolean | null }

export type SendSmsMutationVariables = Exact<{
  to: Scalars['String']
  body: Scalars['String']
}>

export type SendSmsMutation = {
  __typename?: 'Mutation'
  sendSMS?: { __typename?: 'MutationResponse'; success?: boolean | null } | null
}

export type SendWelcomeEmailMutationVariables = Exact<{
  patientId: Scalars['String']
}>

export type SendWelcomeEmailMutation = {
  __typename?: 'Mutation'
  sendWelcomeEmail?: { __typename?: 'MutationResponse'; success?: boolean | null; errors?: Array<string> | null } | null
}

export type SubmitHelpFormMutationVariables = Exact<{
  subject?: InputMaybe<Scalars['String']>
  message: Scalars['String']
}>

export type SubmitHelpFormMutation = {
  __typename?: 'Mutation'
  submitHelpForm?: { __typename?: 'MutationResponse'; success?: boolean | null } | null
}

export type SubmitNetScoreMutationVariables = Exact<{
  score: Scalars['Int']
}>

export type SubmitNetScoreMutation = {
  __typename?: 'Mutation'
  submitNetScore?: { __typename?: 'MutationResponse'; success?: boolean | null } | null
}

export type UpdateOnePatientGuideTemplateMutationVariables = Exact<{
  patientId: Scalars['ID']
  guideTemplateId: Scalars['ID']
}>

export type UpdateOnePatientGuideTemplateMutation = {
  __typename?: 'Mutation'
  updateOnePatientGuideTemplate?: {
    __typename?: 'Patient'
    id: string
    guideTemplate?: { __typename?: 'GuideTemplate'; id?: string | null; name?: string | null } | null
  } | null
}

export type UpdatePatientMutationVariables = Exact<{
  patientId: Scalars['ID']
  params: PatientInput
}>

export type UpdatePatientMutation = {
  __typename?: 'Mutation'
  updatePatient?: {
    __typename?: 'Patient'
    id: string
    firstName?: string | null
    lastName?: string | null
    email?: string | null
    phoneNumber?: string | null
    startTime?: string | null
    hasOfficialPlan?: boolean | null
    agreeToTerms?: string | null
    userId: string
    decisionMakerEmail?: string | null
    importantToMe?: Array<ImportantToMeEnum | null> | null
    livingSituation?: LivingSituationEnum | null
    likeToKnowCprAgeGroup?: boolean | null
    cprAgeGroupVideo?: boolean | null
    cprAffectPeopleAgeGroup?: boolean | null
    birthday?: string | null
    additionalCarePreferences?: string | null
    additionalMdmDirections?: string | null
    meaningOfLife?: number | null
    notaryInfo?: boolean | null
    clinicId: string
    spiritualAndReligiousBeliefs?: string | null
    lifeAndValues?: string | null
    receiveCareOther?: string | null
    createdAt?: string | null
    medicalCareScenario1?: TerminConditionScenarioEnum | null
    medicalCareScenario2?: TerminConditionScenarioEnum | null
    medicalCareScenario3?: TerminConditionScenarioEnum | null
    lifeSustainingProcedures?: Array<string | null> | null
    lifeSustainingProceduresOther?: string | null
    notWorthLiving?: Array<NotWorthLivingEnum | null> | null
    notWorthLiving2?: Array<NotWorthLivingEnum | null> | null
    notWorthLiving3?: Array<NotWorthLivingEnum | null> | null
    lastLocation?: Array<LastLocationEnum | null> | null
    cprPreference?: string | null
    personaVacation?: number | null
    personaSocialTies?: number | null
    personaHealthcareExperience?: number | null
    ventilationPreference?: VentilationPreferenceEnum | null
    longTermFeeding?: LongTermFeedingEnum | null
    dialysisTreatment?: DialysisTreatmentEnum | null
    doYouKnowDecisionMaker?: string | null
    decisionMakerFirstName?: string | null
    decisionMakerLastName?: string | null
    decisionMakerName?: string | null
    decisionMakerPhone?: string | null
    decisionMakerRelationship?: DecisionMakerRelationshipEnum | null
    decisionMakerRelationshipOther?: string | null
    decisionMakerStrictness?: DecisionMakerStrictnessEnum | null
    decisionMakerAddressStreet?: string | null
    decisionMakerAddressCity?: string | null
    decisionMakerAddressState?: string | null
    decisionMakerAddressZip?: string | null
    wantsAltDecisionMaker?: string | null
    whenCanDecisionMakerDecide?: WhenCanDecisionMakerDecideEnum | null
    altDecisionMakerFirstName?: string | null
    altDecisionMakerLastName?: string | null
    altDecisionMakerEmail?: string | null
    altDecisionMakerPhone?: string | null
    altDecisionMakerAddressStreet?: string | null
    altDecisionMakerAddressCity?: string | null
    altDecisionMakerAddressState?: string | null
    altDecisionMakerAddressZip?: string | null
    updatedAt?: string | null
    cprPreferenceToday?: CprPreferenceTodayEnum | null
    ventilationPreferenceToday?: VentilationPreferenceTodayEnum | null
    longTermFeedingToday?: LongTermFeedingTodayEnum | null
    livingSituationOther?: string | null
    dialysisTreatmentToday?: DialysisTreatmentTodayEnum | null
    completedAt?: string | null
    sharedPlan?: boolean | null
    wantsThirdDecisionMaker?: string | null
    thirdDecisionMakerFirstName?: string | null
    thirdDecisionMakerLastName?: string | null
    thirdDecisionMakerEmail?: string | null
    thirdDecisionMakerPhone?: string | null
    thirdDecisionMakerAddressStreet?: string | null
    thirdDecisionMakerAddressCity?: string | null
    thirdDecisionMakerAddressState?: string | null
    thirdDecisionMakerAddressZip?: string | null
    importantToMeOther?: string | null
    thirdDecisionMakerRelationship?: DecisionMakerRelationshipEnum | null
    altDecisionMakerRelationship?: DecisionMakerRelationshipEnum | null
    altDecisionMakerRelationshipOther?: string | null
    thirdDecisionMakerRelationshipOther?: string | null
    interestedInPalliativeCare?: YesNoNotSureEnum | null
    interestedInHospiceCare?: YesNoNotSureEnum | null
    age?: number | null
    slug?: string | null
    affordMedications?: string | null
    hasPlanViewed?: boolean | null
    healthIs?: HealthIsEnum | null
    doOnYourOwn?: Array<DoOnYourOwnEnum | null> | null
    doOnYourOwnWithoutHelp?: Array<DoOnYourOwnWithoutHelpEnum | null> | null
    personalLossOrMisfortune?: PersonalLossOrMisfortuneEnum | null
    takeCareSomeoneWasSeriouslyIll?: TakeCareSomeoneWasSeriouslyIllEnum | null
    knowWhatAdvanceCarePlanningIs?: number | null
    aboutWhoCanBeDecisionMaker?: number | null
    aboutWhatMakesGoodDecisionMaker?: number | null
    informedAboutFlexibilityDecisionMaker?: number | null
    comfortLevelBecomeIll?: number | null
    worriedFutureIllnesses?: number | null
    decisionMakerKindOfMedicalYouWouldWant?: number | null
    healthcareProviderKindOfMedicalYouWouldWant?: number | null
    medicalAttorneyLivingWillAdvanceDirective?: MedicalAttorneyLivingWillAdvanceDirectiveEnum | null
    statementMostAdvanceCarePlanning?: StatementMostAdvanceCarePlanningEnum | null
    statementMostAdvanceCarePlanningNoneText?: string | null
    postAcpDigitalAdvanceCarePlanning?: PostAcpDigitalAdvanceCarePlanningEnum | null
    postAcpKnowWhatAdvanceCarePlanningIs?: number | null
    postAcpAboutWhoCanBeDecisionMaker?: number | null
    postAcpAboutWhatMakesGoodDecisionMaker?: number | null
    postAcpInformedAboutFlexibilityDecisionMaker?: number | null
    postAcpComfortLevelBecomeIll?: number | null
    postAcpWorriedFutureIllnesses?: number | null
    postAcpDecisionMakerKindOfMedicalYouWouldWant?: number | null
    postAcpHealthcareProviderKindOfMedicalYouWouldWant?: number | null
    postAcpHealthcareSystemTrust?: number | null
    postAcpOnlineFormat?: PostAcpOnlineFormatEnum | null
    postAcpOnlineFormatOther?: string | null
    postAcpMedicalEducationPrefer?: PostAcpMedicalEducationPreferEnum | null
    postAcpMedicalEducationPreferOther?: string | null
    postAcpInfoAboutCPR?: PostAcpInfoAboutCprEnum | null
    postAcpInfoAboutCPROther?: string | null
    postAcpStatementMostAdvanceCarePlanning?: StatementMostAdvanceCarePlanningEnum | null
    postAcpStatementMostAdvanceCarePlanningNoneText?: string | null
    postAcpUseSystemFrequently?: PostAcpSystemUsabilityScale | null
    postAcpSystemUnnecessarilyComplex?: PostAcpSystemUsabilityScale | null
    postAcpSystemWasEasyUse?: PostAcpSystemUsabilityScale | null
    postAcpSupportTechnicalPerson?: PostAcpSystemUsabilityScale | null
    postAcpVariousFunctionsWellIntegrated?: PostAcpSystemUsabilityScale | null
    postAcpTooMuchInconsistency?: PostAcpSystemUsabilityScale | null
    postAcpMostPeopleWouldLearn?: PostAcpSystemUsabilityScale | null
    postAcpSystemVeryCumbersomeToUse?: PostAcpSystemUsabilityScale | null
    postAcpConfidentUsingTheSystem?: PostAcpSystemUsabilityScale | null
    postAcpLearnLotPfThingsBefore?: PostAcpSystemUsabilityScale | null
    schedulingACPinformation?: string | null
    patientSignatureFirstName?: string | null
    patientSignatureLastName?: string | null
    patientSignatureDate?: string | null
    address?: string | null
    city?: string | null
    postal?: string | null
    witness1SignatureFirstName?: string | null
    witness1SignatureLastName?: string | null
    witness1SignatureEmail?: string | null
    witness1SignatureDate?: string | null
    witness1LocationType?: WitnessSignatureLocationEnum | null
    witness2SignatureFirstName?: string | null
    witness2SignatureLastName?: string | null
    witness2SignatureEmail?: string | null
    witness2SignatureDate?: string | null
    witness2LocationType?: WitnessSignatureLocationEnum | null
    guideTemplate?: {
      __typename?: 'GuideTemplate'
      id?: string | null
      name?: string | null
      isPlatformDefault?: boolean | null
      sections: Array<{
        __typename?: 'GuideTemplateSection'
        sectionId: GuideTemplateSectionIdEnum
        sectionName: string
        questionIds: Array<GuideQuestionIdEnum>
        required: boolean
        sectionIcon?: string | null
        activeStepText?: string | null
      }>
    } | null
    clinic: { __typename?: 'Clinic'; id: string; slug?: string | null; stateId: string }
    planShares?: Array<{
      __typename?: 'PlanShare'
      sendToEmail?: string | null
      dateSent?: string | null
    } | null> | null
    stateVersion?: {
      __typename?: 'State'
      id?: string | null
      abbreviation?: string | null
      name?: string | null
      advanceDirectiveVideo?: string | null
      medicalCareScenariosVideo?: string | null
      hasESign?: boolean | null
      hasPaperCopy?: boolean | null
      hasNotary?: boolean | null
      linkToSigningRequirements?: string | null
    } | null
    actionHistory?: Array<{
      __typename?: 'PatientActionHistoryItem'
      name: PatientActionHistoryItemEnum
      value?: string | null
      createdAt?: any | null
    }> | null
    readiness?: Array<{
      __typename?: 'PatientReadinessSurveyResponse'
      answer: SubmitReadinessMpoResponseEnum
      otherAnswer?: string | null
      createdAt?: string | null
    }> | null
  } | null
}

export type UpdatePermissionMutationVariables = Exact<{
  permissionId: Scalars['ID']
  accessIds: Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>
}>

export type UpdatePermissionMutation = {
  __typename?: 'Mutation'
  updatePermission?: { __typename?: 'Permission'; id?: string | null } | null
}

export type UpdateUserClinicPermissionsMutationVariables = Exact<{
  userId: Scalars['ID']
  clinicIds: Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>
}>

export type UpdateUserClinicPermissionsMutation = {
  __typename?: 'Mutation'
  updateUserClinicPermissions?: {
    __typename?: 'UserProfile'
    id?: string | null
    email?: string | null
    roles?: Array<RoleTypeEnum | null> | null
    lang?: LangTypeEnum | null
    firstName?: string | null
    lastName?: string | null
    phoneNumber?: string | null
    createdAt?: string | null
    clinicPermissions?: Array<{ __typename?: 'Clinic'; id: string; title?: string | null } | null> | null
    permission?: {
      __typename?: 'Permission'
      id?: string | null
      title?: string | null
      accesses: Array<{ __typename?: 'Access'; id: string; title: AccessTypeEnum }>
    } | null
  } | null
}

export type UpdateUserPermissionMutationVariables = Exact<{
  userId: Scalars['ID']
  permissionId: Scalars['ID']
}>

export type UpdateUserPermissionMutation = {
  __typename?: 'Mutation'
  updateUserPermission?: {
    __typename?: 'UserProfile'
    id?: string | null
    email?: string | null
    roles?: Array<RoleTypeEnum | null> | null
    lang?: LangTypeEnum | null
    firstName?: string | null
    lastName?: string | null
    phoneNumber?: string | null
    createdAt?: string | null
    permission?: {
      __typename?: 'Permission'
      id?: string | null
      title?: string | null
      accesses: Array<{ __typename?: 'Access'; id: string; title: AccessTypeEnum }>
    } | null
  } | null
}

export type UploadPlanAttachmentMutationVariables = Exact<{
  name?: InputMaybe<Scalars['String']>
  mimetype: Scalars['String']
  s3Key?: InputMaybe<Scalars['String']>
  patientId?: InputMaybe<Scalars['ID']>
}>

export type UploadPlanAttachmentMutation = {
  __typename?: 'Mutation'
  uploadPlanAttachment?: {
    __typename?: 'MutationResponse'
    success?: boolean | null
    errors?: Array<string> | null
  } | null
}

export type VerifyMfaMutationVariables = Exact<{
  pin: Scalars['String']
  email: Scalars['String']
}>

export type VerifyMfaMutation = {
  __typename?: 'Mutation'
  verifyMfa?: {
    __typename?: 'LoginResponse'
    mfaRequired?: boolean | null
    sessionId?: string | null
    tokens?: { __typename?: 'Tokens'; refreshToken?: string | null; accessToken?: string | null } | null
  } | null
}

export type AccessesQueryVariables = Exact<{ [key: string]: never }>

export type AccessesQuery = {
  __typename?: 'Query'
  accesses: Array<{ __typename?: 'Access'; id: string; title: AccessTypeEnum; active?: boolean | null }>
}

export type ActivityLogsByPatientIdQueryVariables = Exact<{
  patientId: Scalars['ID']
}>

export type ActivityLogsByPatientIdQuery = {
  __typename?: 'Query'
  activityLogsByPatientId?: Array<{
    __typename?: 'ActivityLog'
    id?: string | null
    importance?: number | null
    userId?: string | null
    patientId?: string | null
    createdAt?: string | null
    clinicId?: string | null
    content?: string | null
  } | null> | null
}

export type AllNetScoresQueryVariables = Exact<{ [key: string]: never }>

export type AllNetScoresQuery = {
  __typename?: 'Query'
  allNetScores?: Array<{
    __typename?: 'NetScore'
    id?: string | null
    score?: number | null
    userId?: string | null
    userName?: string | null
    email?: string | null
    createdAt?: string | null
    updatedAt?: string | null
    clinic?: { __typename?: 'Clinic'; id: string; title?: string | null } | null
  } | null> | null
}

export type ClinicByIdQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type ClinicByIdQuery = {
  __typename?: 'Query'
  getOneClinic?: {
    __typename?: 'Clinic'
    id: string
    title?: string | null
    description?: string | null
    createdAt?: string | null
    startVideo?: string | null
    updatedAt?: string | null
    logo?: string | null
    street?: string | null
    street2?: string | null
    stateId: string
    v2Signup?: boolean | null
    postal?: string | null
    city?: string | null
    faxNumber?: string | null
    slug?: string | null
    guideTemplateId?: string | null
    contact?: {
      __typename?: 'Contact'
      firstName?: string | null
      lastName?: string | null
      phone?: string | null
      email?: string | null
    } | null
    integrations?: {
      __typename?: 'ClinicIntegrations'
      freshworksCrm?: boolean | null
      freshworksCrmId?: string | null
      freshworksSalesAccountId?: string | null
    } | null
    state?: { __typename?: 'State'; id?: string | null; name?: string | null; abbreviation?: string | null } | null
    guideTemplate?: {
      __typename?: 'GuideTemplate'
      id?: string | null
      name?: string | null
      isPlatformDefault?: boolean | null
      sections: Array<{
        __typename?: 'GuideTemplateSection'
        sectionId: GuideTemplateSectionIdEnum
        sectionName: string
        questionIds: Array<GuideQuestionIdEnum>
        required: boolean
        sectionIcon?: string | null
        activeStepText?: string | null
      }>
    } | null
  } | null
}

export type ClinicCsvQueryVariables = Exact<{
  clinicId: Scalars['ID']
}>

export type ClinicCsvQuery = {
  __typename?: 'Query'
  clinicCSV?: Array<{
    __typename?: 'ClinicDataCSV'
    id?: string | null
    userId?: string | null
    firstName?: string | null
    lastName?: string | null
    email?: string | null
    startTime?: string | null
    endTime?: string | null
    createdAt?: string | null
    birthday?: string | null
    cprPreferenceToday?: string | null
    ventilationPreferenceToday?: string | null
    longTermFeedingToday?: string | null
    dialysisTreatmentToday?: string | null
    updatedAt?: string | null
    notaryInfo?: string | null
    eSignStarted?: string | null
    decisionMakerPhone?: string | null
    decisionMakerEmail?: string | null
    importantToMe?: Array<string | null> | null
    livingSituation?: string | null
    meaningOfLife?: number | null
    notWorthLiving?: Array<string | null> | null
    notWorthLiving2?: Array<string | null> | null
    notWorthLiving3?: Array<string | null> | null
    whenCanDecisionMakerDecide?: string | null
    lastLocation?: Array<string | null> | null
    cprPreference?: string | null
    ventilationPreference?: string | null
    longTermFeeding?: string | null
    dialysisTreatment?: string | null
    doYouKnowDecisionMaker?: string | null
    decisionMakerName?: string | null
    decisionMakerFirstName?: string | null
    decisionMakerLastName?: string | null
    decisionMakerRelationship?: string | null
    decisionMakerStrictness?: string | null
    wantsAltDecisionMaker?: string | null
    altDecisionMakerFirstName?: string | null
    altDecisionMakerLastName?: string | null
    personaVacation?: number | null
    personaSocialTies?: number | null
    personaHealthcareExperience?: number | null
    lastLogin?: string | null
    firstLogin?: string | null
    sharedWithDecisionMaker?: string | null
  } | null> | null
}

export type ClinicsQueryVariables = Exact<{
  searchText?: InputMaybe<Scalars['String']>
  skip?: InputMaybe<Scalars['Int']>
  limit?: InputMaybe<Scalars['Int']>
}>

export type ClinicsQuery = {
  __typename?: 'Query'
  clinics?: {
    __typename?: 'ClinicsResponse'
    count?: number | null
    clinics?: Array<{
      __typename?: 'Clinic'
      id: string
      title?: string | null
      description?: string | null
      createdAt?: string | null
      startVideo?: string | null
      updatedAt?: string | null
      logo?: string | null
      street?: string | null
      street2?: string | null
      stateId: string
      v2Signup?: boolean | null
      postal?: string | null
      city?: string | null
      faxNumber?: string | null
      slug?: string | null
      guideTemplateId?: string | null
      state?: { __typename?: 'State'; id?: string | null; name?: string | null; abbreviation?: string | null } | null
      guideTemplate?: {
        __typename?: 'GuideTemplate'
        id?: string | null
        name?: string | null
        isPlatformDefault?: boolean | null
        sections: Array<{
          __typename?: 'GuideTemplateSection'
          sectionId: GuideTemplateSectionIdEnum
          sectionName: string
          questionIds: Array<GuideQuestionIdEnum>
          required: boolean
          sectionIcon?: string | null
          activeStepText?: string | null
        }>
      } | null
    } | null> | null
  } | null
}

export type CurrentUserQueryVariables = Exact<{ [key: string]: never }>

export type CurrentUserQuery = {
  __typename?: 'Query'
  currentUser?: {
    __typename?: 'UserProfile'
    isGuest?: boolean | null
    hasNetScore?: boolean | null
    lang?: LangTypeEnum | null
    id?: string | null
    email?: string | null
    roles?: Array<RoleTypeEnum | null> | null
    firstName?: string | null
    lastName?: string | null
    phoneNumber?: string | null
    createdAt?: string | null
    patient?: {
      __typename?: 'Patient'
      actionHistory?: Array<{
        __typename?: 'PatientActionHistoryItem'
        name: PatientActionHistoryItemEnum
        value?: string | null
      }> | null
    } | null
    clinic?: {
      __typename?: 'Clinic'
      id: string
      slug?: string | null
      title?: string | null
      logo?: string | null
    } | null
    permission?: {
      __typename?: 'Permission'
      id?: string | null
      title?: string | null
      accesses: Array<{ __typename?: 'Access'; id: string; title: AccessTypeEnum }>
    } | null
  } | null
}

export type FindResetQueryVariables = Exact<{
  token: Scalars['String']
}>

export type FindResetQuery = {
  __typename?: 'Query'
  findReset?: {
    __typename?: 'FindResetResponse'
    email?: string | null
    name?: string | null
    agreeToTerms?: string | null
  } | null
}

export type GetHipaaLogsQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']>
  limit?: InputMaybe<Scalars['Int']>
  startTime?: InputMaybe<Scalars['Float']>
  endTime?: InputMaybe<Scalars['Float']>
  patientId?: InputMaybe<Scalars['ID']>
}>

export type GetHipaaLogsQuery = {
  __typename?: 'Query'
  getHipaaLogs?: {
    __typename?: 'HippaaLogsResponse'
    count?: number | null
    logs?: Array<{
      __typename?: 'HipaaLog'
      id: string
      eventType?: HipaaLogEventTypesEnum | null
      note?: string | null
      createdAt?: string | null
      patientName?: string | null
      userFullname?: string | null
      collectionName?: HipaaLogCollectionTypesEnum | null
    }> | null
  } | null
}

export type GetManyGuideTemplatesQueryVariables = Exact<{ [key: string]: never }>

export type GetManyGuideTemplatesQuery = {
  __typename?: 'Query'
  getManyGuideTemplates?: Array<{
    __typename?: 'GuideTemplate'
    id?: string | null
    name?: string | null
    isPlatformDefault?: boolean | null
    sections: Array<{
      __typename?: 'GuideTemplateSection'
      sectionId: GuideTemplateSectionIdEnum
      sectionName: string
      questionIds: Array<GuideQuestionIdEnum>
      required: boolean
      sectionIcon?: string | null
      activeStepText?: string | null
    }>
  } | null> | null
}

export type GetManyPatientsQueryVariables = Exact<{
  clinicId: Scalars['ID']
  skip?: InputMaybe<Scalars['Int']>
  limit?: InputMaybe<Scalars['Int']>
  searchText?: InputMaybe<Scalars['String']>
  sortBy?: InputMaybe<PlansSortByInput>
}>

export type GetManyPatientsQuery = {
  __typename?: 'Query'
  getManyPatients?: {
    __typename?: 'PlansResponse'
    count?: number | null
    plans?: Array<{
      __typename?: 'Patient'
      firstName?: string | null
      email?: string | null
      lastName?: string | null
      birthday?: string | null
      startTime?: string | null
      endTime?: string | null
      createdAt?: string | null
      updatedAt?: string | null
      userId: string
      lastLogin?: string | null
      firstLogin?: string | null
      notaryInfo?: boolean | null
      eSignStarted?: string | null
      id: string
      phoneNumber?: string | null
      hasOfficialPlan?: boolean | null
      agreeToTerms?: string | null
      decisionMakerEmail?: string | null
      importantToMe?: Array<ImportantToMeEnum | null> | null
      livingSituation?: LivingSituationEnum | null
      likeToKnowCprAgeGroup?: boolean | null
      cprAgeGroupVideo?: boolean | null
      cprAffectPeopleAgeGroup?: boolean | null
      additionalCarePreferences?: string | null
      additionalMdmDirections?: string | null
      meaningOfLife?: number | null
      clinicId: string
      spiritualAndReligiousBeliefs?: string | null
      lifeAndValues?: string | null
      receiveCareOther?: string | null
      medicalCareScenario1?: TerminConditionScenarioEnum | null
      medicalCareScenario2?: TerminConditionScenarioEnum | null
      medicalCareScenario3?: TerminConditionScenarioEnum | null
      lifeSustainingProcedures?: Array<string | null> | null
      lifeSustainingProceduresOther?: string | null
      notWorthLiving?: Array<NotWorthLivingEnum | null> | null
      notWorthLiving2?: Array<NotWorthLivingEnum | null> | null
      notWorthLiving3?: Array<NotWorthLivingEnum | null> | null
      lastLocation?: Array<LastLocationEnum | null> | null
      cprPreference?: string | null
      personaVacation?: number | null
      personaSocialTies?: number | null
      personaHealthcareExperience?: number | null
      ventilationPreference?: VentilationPreferenceEnum | null
      longTermFeeding?: LongTermFeedingEnum | null
      dialysisTreatment?: DialysisTreatmentEnum | null
      doYouKnowDecisionMaker?: string | null
      decisionMakerFirstName?: string | null
      decisionMakerLastName?: string | null
      decisionMakerName?: string | null
      decisionMakerPhone?: string | null
      decisionMakerRelationship?: DecisionMakerRelationshipEnum | null
      decisionMakerRelationshipOther?: string | null
      decisionMakerStrictness?: DecisionMakerStrictnessEnum | null
      decisionMakerAddressStreet?: string | null
      decisionMakerAddressCity?: string | null
      decisionMakerAddressState?: string | null
      decisionMakerAddressZip?: string | null
      wantsAltDecisionMaker?: string | null
      whenCanDecisionMakerDecide?: WhenCanDecisionMakerDecideEnum | null
      altDecisionMakerFirstName?: string | null
      altDecisionMakerLastName?: string | null
      altDecisionMakerEmail?: string | null
      altDecisionMakerPhone?: string | null
      altDecisionMakerAddressStreet?: string | null
      altDecisionMakerAddressCity?: string | null
      altDecisionMakerAddressState?: string | null
      altDecisionMakerAddressZip?: string | null
      cprPreferenceToday?: CprPreferenceTodayEnum | null
      ventilationPreferenceToday?: VentilationPreferenceTodayEnum | null
      longTermFeedingToday?: LongTermFeedingTodayEnum | null
      livingSituationOther?: string | null
      dialysisTreatmentToday?: DialysisTreatmentTodayEnum | null
      completedAt?: string | null
      sharedPlan?: boolean | null
      wantsThirdDecisionMaker?: string | null
      thirdDecisionMakerFirstName?: string | null
      thirdDecisionMakerLastName?: string | null
      thirdDecisionMakerEmail?: string | null
      thirdDecisionMakerPhone?: string | null
      thirdDecisionMakerAddressStreet?: string | null
      thirdDecisionMakerAddressCity?: string | null
      thirdDecisionMakerAddressState?: string | null
      thirdDecisionMakerAddressZip?: string | null
      importantToMeOther?: string | null
      thirdDecisionMakerRelationship?: DecisionMakerRelationshipEnum | null
      altDecisionMakerRelationship?: DecisionMakerRelationshipEnum | null
      altDecisionMakerRelationshipOther?: string | null
      thirdDecisionMakerRelationshipOther?: string | null
      interestedInPalliativeCare?: YesNoNotSureEnum | null
      interestedInHospiceCare?: YesNoNotSureEnum | null
      age?: number | null
      slug?: string | null
      affordMedications?: string | null
      hasPlanViewed?: boolean | null
      healthIs?: HealthIsEnum | null
      doOnYourOwn?: Array<DoOnYourOwnEnum | null> | null
      doOnYourOwnWithoutHelp?: Array<DoOnYourOwnWithoutHelpEnum | null> | null
      personalLossOrMisfortune?: PersonalLossOrMisfortuneEnum | null
      takeCareSomeoneWasSeriouslyIll?: TakeCareSomeoneWasSeriouslyIllEnum | null
      knowWhatAdvanceCarePlanningIs?: number | null
      aboutWhoCanBeDecisionMaker?: number | null
      aboutWhatMakesGoodDecisionMaker?: number | null
      informedAboutFlexibilityDecisionMaker?: number | null
      comfortLevelBecomeIll?: number | null
      worriedFutureIllnesses?: number | null
      decisionMakerKindOfMedicalYouWouldWant?: number | null
      healthcareProviderKindOfMedicalYouWouldWant?: number | null
      medicalAttorneyLivingWillAdvanceDirective?: MedicalAttorneyLivingWillAdvanceDirectiveEnum | null
      statementMostAdvanceCarePlanning?: StatementMostAdvanceCarePlanningEnum | null
      statementMostAdvanceCarePlanningNoneText?: string | null
      postAcpDigitalAdvanceCarePlanning?: PostAcpDigitalAdvanceCarePlanningEnum | null
      postAcpKnowWhatAdvanceCarePlanningIs?: number | null
      postAcpAboutWhoCanBeDecisionMaker?: number | null
      postAcpAboutWhatMakesGoodDecisionMaker?: number | null
      postAcpInformedAboutFlexibilityDecisionMaker?: number | null
      postAcpComfortLevelBecomeIll?: number | null
      postAcpWorriedFutureIllnesses?: number | null
      postAcpDecisionMakerKindOfMedicalYouWouldWant?: number | null
      postAcpHealthcareProviderKindOfMedicalYouWouldWant?: number | null
      postAcpHealthcareSystemTrust?: number | null
      postAcpOnlineFormat?: PostAcpOnlineFormatEnum | null
      postAcpOnlineFormatOther?: string | null
      postAcpMedicalEducationPrefer?: PostAcpMedicalEducationPreferEnum | null
      postAcpMedicalEducationPreferOther?: string | null
      postAcpInfoAboutCPR?: PostAcpInfoAboutCprEnum | null
      postAcpInfoAboutCPROther?: string | null
      postAcpStatementMostAdvanceCarePlanning?: StatementMostAdvanceCarePlanningEnum | null
      postAcpStatementMostAdvanceCarePlanningNoneText?: string | null
      postAcpUseSystemFrequently?: PostAcpSystemUsabilityScale | null
      postAcpSystemUnnecessarilyComplex?: PostAcpSystemUsabilityScale | null
      postAcpSystemWasEasyUse?: PostAcpSystemUsabilityScale | null
      postAcpSupportTechnicalPerson?: PostAcpSystemUsabilityScale | null
      postAcpVariousFunctionsWellIntegrated?: PostAcpSystemUsabilityScale | null
      postAcpTooMuchInconsistency?: PostAcpSystemUsabilityScale | null
      postAcpMostPeopleWouldLearn?: PostAcpSystemUsabilityScale | null
      postAcpSystemVeryCumbersomeToUse?: PostAcpSystemUsabilityScale | null
      postAcpConfidentUsingTheSystem?: PostAcpSystemUsabilityScale | null
      postAcpLearnLotPfThingsBefore?: PostAcpSystemUsabilityScale | null
      schedulingACPinformation?: string | null
      patientSignatureFirstName?: string | null
      patientSignatureLastName?: string | null
      patientSignatureDate?: string | null
      address?: string | null
      city?: string | null
      postal?: string | null
      witness1SignatureFirstName?: string | null
      witness1SignatureLastName?: string | null
      witness1SignatureEmail?: string | null
      witness1SignatureDate?: string | null
      witness1LocationType?: WitnessSignatureLocationEnum | null
      witness2SignatureFirstName?: string | null
      witness2SignatureLastName?: string | null
      witness2SignatureEmail?: string | null
      witness2SignatureDate?: string | null
      witness2LocationType?: WitnessSignatureLocationEnum | null
      faxes?: Array<{
        __typename?: 'FaxSent'
        faxToNumber?: string | null
        dateSent?: string | null
        sentByUserId?: string | null
      } | null> | null
      guideTemplate?: {
        __typename?: 'GuideTemplate'
        id?: string | null
        name?: string | null
        isPlatformDefault?: boolean | null
        sections: Array<{
          __typename?: 'GuideTemplateSection'
          sectionId: GuideTemplateSectionIdEnum
          sectionName: string
          questionIds: Array<GuideQuestionIdEnum>
          required: boolean
          sectionIcon?: string | null
          activeStepText?: string | null
        }>
      } | null
      clinic: { __typename?: 'Clinic'; id: string; slug?: string | null; stateId: string }
      planShares?: Array<{
        __typename?: 'PlanShare'
        sendToEmail?: string | null
        dateSent?: string | null
      } | null> | null
      stateVersion?: {
        __typename?: 'State'
        id?: string | null
        abbreviation?: string | null
        name?: string | null
        advanceDirectiveVideo?: string | null
        medicalCareScenariosVideo?: string | null
        hasESign?: boolean | null
        hasPaperCopy?: boolean | null
        hasNotary?: boolean | null
        linkToSigningRequirements?: string | null
      } | null
      actionHistory?: Array<{
        __typename?: 'PatientActionHistoryItem'
        name: PatientActionHistoryItemEnum
        value?: string | null
        createdAt?: any | null
      }> | null
      readiness?: Array<{
        __typename?: 'PatientReadinessSurveyResponse'
        answer: SubmitReadinessMpoResponseEnum
        otherAnswer?: string | null
        createdAt?: string | null
      }> | null
    } | null> | null
  } | null
}

export type GetOneClinicQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>
  slug?: InputMaybe<Scalars['String']>
}>

export type GetOneClinicQuery = {
  __typename?: 'Query'
  getOneClinic?: {
    __typename?: 'Clinic'
    id: string
    title?: string | null
    description?: string | null
    createdAt?: string | null
    startVideo?: string | null
    updatedAt?: string | null
    logo?: string | null
    street?: string | null
    street2?: string | null
    stateId: string
    v2Signup?: boolean | null
    postal?: string | null
    city?: string | null
    faxNumber?: string | null
    slug?: string | null
    guideTemplateId?: string | null
    state?: { __typename?: 'State'; id?: string | null; name?: string | null; abbreviation?: string | null } | null
    guideTemplate?: {
      __typename?: 'GuideTemplate'
      id?: string | null
      name?: string | null
      isPlatformDefault?: boolean | null
      sections: Array<{
        __typename?: 'GuideTemplateSection'
        sectionId: GuideTemplateSectionIdEnum
        sectionName: string
        questionIds: Array<GuideQuestionIdEnum>
        required: boolean
        sectionIcon?: string | null
        activeStepText?: string | null
      }>
    } | null
  } | null
}

export type GetOnePatientQueryVariables = Exact<{
  patientId?: InputMaybe<Scalars['ID']>
  token?: InputMaybe<Scalars['String']>
  userId?: InputMaybe<Scalars['ID']>
}>

export type GetOnePatientQuery = {
  __typename?: 'Query'
  getOnePatient?: {
    __typename?: 'Patient'
    id: string
    firstName?: string | null
    lastName?: string | null
    email?: string | null
    phoneNumber?: string | null
    startTime?: string | null
    hasOfficialPlan?: boolean | null
    agreeToTerms?: string | null
    userId: string
    decisionMakerEmail?: string | null
    importantToMe?: Array<ImportantToMeEnum | null> | null
    livingSituation?: LivingSituationEnum | null
    likeToKnowCprAgeGroup?: boolean | null
    cprAgeGroupVideo?: boolean | null
    cprAffectPeopleAgeGroup?: boolean | null
    birthday?: string | null
    additionalCarePreferences?: string | null
    additionalMdmDirections?: string | null
    meaningOfLife?: number | null
    notaryInfo?: boolean | null
    clinicId: string
    spiritualAndReligiousBeliefs?: string | null
    lifeAndValues?: string | null
    receiveCareOther?: string | null
    createdAt?: string | null
    medicalCareScenario1?: TerminConditionScenarioEnum | null
    medicalCareScenario2?: TerminConditionScenarioEnum | null
    medicalCareScenario3?: TerminConditionScenarioEnum | null
    lifeSustainingProcedures?: Array<string | null> | null
    lifeSustainingProceduresOther?: string | null
    notWorthLiving?: Array<NotWorthLivingEnum | null> | null
    notWorthLiving2?: Array<NotWorthLivingEnum | null> | null
    notWorthLiving3?: Array<NotWorthLivingEnum | null> | null
    lastLocation?: Array<LastLocationEnum | null> | null
    cprPreference?: string | null
    personaVacation?: number | null
    personaSocialTies?: number | null
    personaHealthcareExperience?: number | null
    ventilationPreference?: VentilationPreferenceEnum | null
    longTermFeeding?: LongTermFeedingEnum | null
    dialysisTreatment?: DialysisTreatmentEnum | null
    doYouKnowDecisionMaker?: string | null
    decisionMakerFirstName?: string | null
    decisionMakerLastName?: string | null
    decisionMakerName?: string | null
    decisionMakerPhone?: string | null
    decisionMakerRelationship?: DecisionMakerRelationshipEnum | null
    decisionMakerRelationshipOther?: string | null
    decisionMakerStrictness?: DecisionMakerStrictnessEnum | null
    decisionMakerAddressStreet?: string | null
    decisionMakerAddressCity?: string | null
    decisionMakerAddressState?: string | null
    decisionMakerAddressZip?: string | null
    wantsAltDecisionMaker?: string | null
    whenCanDecisionMakerDecide?: WhenCanDecisionMakerDecideEnum | null
    altDecisionMakerFirstName?: string | null
    altDecisionMakerLastName?: string | null
    altDecisionMakerEmail?: string | null
    altDecisionMakerPhone?: string | null
    altDecisionMakerAddressStreet?: string | null
    altDecisionMakerAddressCity?: string | null
    altDecisionMakerAddressState?: string | null
    altDecisionMakerAddressZip?: string | null
    updatedAt?: string | null
    cprPreferenceToday?: CprPreferenceTodayEnum | null
    ventilationPreferenceToday?: VentilationPreferenceTodayEnum | null
    longTermFeedingToday?: LongTermFeedingTodayEnum | null
    livingSituationOther?: string | null
    dialysisTreatmentToday?: DialysisTreatmentTodayEnum | null
    completedAt?: string | null
    sharedPlan?: boolean | null
    wantsThirdDecisionMaker?: string | null
    thirdDecisionMakerFirstName?: string | null
    thirdDecisionMakerLastName?: string | null
    thirdDecisionMakerEmail?: string | null
    thirdDecisionMakerPhone?: string | null
    thirdDecisionMakerAddressStreet?: string | null
    thirdDecisionMakerAddressCity?: string | null
    thirdDecisionMakerAddressState?: string | null
    thirdDecisionMakerAddressZip?: string | null
    importantToMeOther?: string | null
    thirdDecisionMakerRelationship?: DecisionMakerRelationshipEnum | null
    altDecisionMakerRelationship?: DecisionMakerRelationshipEnum | null
    altDecisionMakerRelationshipOther?: string | null
    thirdDecisionMakerRelationshipOther?: string | null
    interestedInPalliativeCare?: YesNoNotSureEnum | null
    interestedInHospiceCare?: YesNoNotSureEnum | null
    age?: number | null
    slug?: string | null
    affordMedications?: string | null
    hasPlanViewed?: boolean | null
    healthIs?: HealthIsEnum | null
    doOnYourOwn?: Array<DoOnYourOwnEnum | null> | null
    doOnYourOwnWithoutHelp?: Array<DoOnYourOwnWithoutHelpEnum | null> | null
    personalLossOrMisfortune?: PersonalLossOrMisfortuneEnum | null
    takeCareSomeoneWasSeriouslyIll?: TakeCareSomeoneWasSeriouslyIllEnum | null
    knowWhatAdvanceCarePlanningIs?: number | null
    aboutWhoCanBeDecisionMaker?: number | null
    aboutWhatMakesGoodDecisionMaker?: number | null
    informedAboutFlexibilityDecisionMaker?: number | null
    comfortLevelBecomeIll?: number | null
    worriedFutureIllnesses?: number | null
    decisionMakerKindOfMedicalYouWouldWant?: number | null
    healthcareProviderKindOfMedicalYouWouldWant?: number | null
    medicalAttorneyLivingWillAdvanceDirective?: MedicalAttorneyLivingWillAdvanceDirectiveEnum | null
    statementMostAdvanceCarePlanning?: StatementMostAdvanceCarePlanningEnum | null
    statementMostAdvanceCarePlanningNoneText?: string | null
    postAcpDigitalAdvanceCarePlanning?: PostAcpDigitalAdvanceCarePlanningEnum | null
    postAcpKnowWhatAdvanceCarePlanningIs?: number | null
    postAcpAboutWhoCanBeDecisionMaker?: number | null
    postAcpAboutWhatMakesGoodDecisionMaker?: number | null
    postAcpInformedAboutFlexibilityDecisionMaker?: number | null
    postAcpComfortLevelBecomeIll?: number | null
    postAcpWorriedFutureIllnesses?: number | null
    postAcpDecisionMakerKindOfMedicalYouWouldWant?: number | null
    postAcpHealthcareProviderKindOfMedicalYouWouldWant?: number | null
    postAcpHealthcareSystemTrust?: number | null
    postAcpOnlineFormat?: PostAcpOnlineFormatEnum | null
    postAcpOnlineFormatOther?: string | null
    postAcpMedicalEducationPrefer?: PostAcpMedicalEducationPreferEnum | null
    postAcpMedicalEducationPreferOther?: string | null
    postAcpInfoAboutCPR?: PostAcpInfoAboutCprEnum | null
    postAcpInfoAboutCPROther?: string | null
    postAcpStatementMostAdvanceCarePlanning?: StatementMostAdvanceCarePlanningEnum | null
    postAcpStatementMostAdvanceCarePlanningNoneText?: string | null
    postAcpUseSystemFrequently?: PostAcpSystemUsabilityScale | null
    postAcpSystemUnnecessarilyComplex?: PostAcpSystemUsabilityScale | null
    postAcpSystemWasEasyUse?: PostAcpSystemUsabilityScale | null
    postAcpSupportTechnicalPerson?: PostAcpSystemUsabilityScale | null
    postAcpVariousFunctionsWellIntegrated?: PostAcpSystemUsabilityScale | null
    postAcpTooMuchInconsistency?: PostAcpSystemUsabilityScale | null
    postAcpMostPeopleWouldLearn?: PostAcpSystemUsabilityScale | null
    postAcpSystemVeryCumbersomeToUse?: PostAcpSystemUsabilityScale | null
    postAcpConfidentUsingTheSystem?: PostAcpSystemUsabilityScale | null
    postAcpLearnLotPfThingsBefore?: PostAcpSystemUsabilityScale | null
    schedulingACPinformation?: string | null
    patientSignatureFirstName?: string | null
    patientSignatureLastName?: string | null
    patientSignatureDate?: string | null
    address?: string | null
    city?: string | null
    postal?: string | null
    witness1SignatureFirstName?: string | null
    witness1SignatureLastName?: string | null
    witness1SignatureEmail?: string | null
    witness1SignatureDate?: string | null
    witness1LocationType?: WitnessSignatureLocationEnum | null
    witness2SignatureFirstName?: string | null
    witness2SignatureLastName?: string | null
    witness2SignatureEmail?: string | null
    witness2SignatureDate?: string | null
    witness2LocationType?: WitnessSignatureLocationEnum | null
    guideTemplate?: {
      __typename?: 'GuideTemplate'
      id?: string | null
      name?: string | null
      isPlatformDefault?: boolean | null
      sections: Array<{
        __typename?: 'GuideTemplateSection'
        sectionId: GuideTemplateSectionIdEnum
        sectionName: string
        questionIds: Array<GuideQuestionIdEnum>
        required: boolean
        sectionIcon?: string | null
        activeStepText?: string | null
      }>
    } | null
    clinic: { __typename?: 'Clinic'; id: string; slug?: string | null; stateId: string }
    planShares?: Array<{
      __typename?: 'PlanShare'
      sendToEmail?: string | null
      dateSent?: string | null
    } | null> | null
    stateVersion?: {
      __typename?: 'State'
      id?: string | null
      abbreviation?: string | null
      name?: string | null
      advanceDirectiveVideo?: string | null
      medicalCareScenariosVideo?: string | null
      hasESign?: boolean | null
      hasPaperCopy?: boolean | null
      hasNotary?: boolean | null
      linkToSigningRequirements?: string | null
    } | null
    actionHistory?: Array<{
      __typename?: 'PatientActionHistoryItem'
      name: PatientActionHistoryItemEnum
      value?: string | null
      createdAt?: any | null
    }> | null
    readiness?: Array<{
      __typename?: 'PatientReadinessSurveyResponse'
      answer: SubmitReadinessMpoResponseEnum
      otherAnswer?: string | null
      createdAt?: string | null
    }> | null
  } | null
}

export type NpsDataQueryVariables = Exact<{
  clinicIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>>
  startTime?: InputMaybe<Scalars['String']>
  endTime?: InputMaybe<Scalars['String']>
}>

export type NpsDataQuery = {
  __typename?: 'Query'
  npsData?: {
    __typename?: 'NetScoreResult'
    count?: number | null
    scores?: Array<{
      __typename?: 'NetScore'
      id?: string | null
      score?: number | null
      userId?: string | null
      email?: string | null
      createdAt?: string | null
      clinic?: { __typename?: 'Clinic'; id: string; title?: string | null } | null
    } | null> | null
  } | null
}

export type PermissionsQueryVariables = Exact<{ [key: string]: never }>

export type PermissionsQuery = {
  __typename?: 'Query'
  permissions?: Array<{
    __typename?: 'Permission'
    id?: string | null
    title?: string | null
    description?: string | null
    accesses: Array<{ __typename?: 'Access'; id: string; title: AccessTypeEnum }>
  } | null> | null
}

export type PlanAttachmentByIdQueryVariables = Exact<{
  fileId: Scalars['ID']
  downloadToken: Scalars['String']
}>

export type PlanAttachmentByIdQuery = {
  __typename?: 'Query'
  planAttachmentById?: {
    __typename?: 'PlanAttachment'
    id?: string | null
    base64String?: string | null
    name?: string | null
    updatedAt?: string | null
    mimetype?: string | null
    patientFirstName?: string | null
    patientLastName?: string | null
    planAttachmentType?: PlanAttachmentTypeEnum | null
    url?: string | null
  } | null
}

export type PlanAttachmentsQueryVariables = Exact<{
  patientId?: InputMaybe<Scalars['ID']>
}>

export type PlanAttachmentsQuery = {
  __typename?: 'Query'
  planAttachments?: Array<{
    __typename?: 'PlanAttachment'
    id?: string | null
    base64String?: string | null
    name?: string | null
    updatedAt?: string | null
    mimetype?: string | null
    planAttachmentType?: PlanAttachmentTypeEnum | null
    url?: string | null
  } | null> | null
}

export type RecentlyActivePatientsQueryVariables = Exact<{
  clinicId: Scalars['ID']
}>

export type RecentlyActivePatientsQuery = {
  __typename?: 'Query'
  recentlyActivePatients?: Array<{
    __typename?: 'ShortPatient'
    id: string
    firstName?: string | null
    email?: string | null
    lastName?: string | null
    lastLogin?: string | null
  } | null> | null
}

export type Reports_AccountsPerWeekQueryVariables = Exact<{
  startDate?: InputMaybe<Scalars['Float']>
  endDate?: InputMaybe<Scalars['Float']>
  clinicIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>>
}>

export type Reports_AccountsPerWeekQuery = {
  __typename?: 'Query'
  reports_accountsPerWeek?: Array<{
    __typename?: 'PatientByMonth'
    id?: string | null
    _id?: { __typename?: 'MonthYear'; week?: string | null; year?: string | null } | null
    values?: Array<{
      __typename?: 'PatientByMonthValue'
      clinicName?: string | null
      total?: number | null
    } | null> | null
  } | null> | null
}

export type Reports_ClinicStatsQueryVariables = Exact<{
  startDate?: InputMaybe<Scalars['Float']>
  endDate?: InputMaybe<Scalars['Float']>
  clinicIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>>
}>

export type Reports_ClinicStatsQuery = {
  __typename?: 'Query'
  reports_clinicStats?: {
    __typename?: 'ClinicStats'
    accountsCreated?: string | null
    avgTimeSpent?: string | null
    nps?: string | null
    mdmSelected?: { __typename?: 'PercentAbsolute'; percent?: string | null; absolute?: string | null } | null
    mdmShared?: { __typename?: 'PercentAbsolute'; percent?: string | null; absolute?: string | null } | null
  } | null
}

export type SearchPatientsQueryVariables = Exact<{
  searchText: Scalars['String']
  clinicId?: InputMaybe<Scalars['ID']>
}>

export type SearchPatientsQuery = {
  __typename?: 'Query'
  searchPatients?: Array<{
    __typename?: 'Patient'
    id: string
    email?: string | null
    firstName?: string | null
    lastName?: string | null
    birthday?: string | null
    clinicId: string
  } | null> | null
}

export type StatesQueryVariables = Exact<{ [key: string]: never }>

export type StatesQuery = {
  __typename?: 'Query'
  states?: Array<{
    __typename?: 'State'
    id?: string | null
    abbreviation?: string | null
    name?: string | null
    advanceDirectiveVideo?: string | null
    medicalCareScenariosVideo?: string | null
    hasESign?: boolean | null
    hasPaperCopy?: boolean | null
    hasNotary?: boolean | null
    linkToSigningRequirements?: string | null
  } | null> | null
}

export type UsersQueryVariables = Exact<{
  searchText?: InputMaybe<Scalars['String']>
  roles?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>
  clinicId?: InputMaybe<Scalars['String']>
}>

export type UsersQuery = {
  __typename?: 'Query'
  users?: {
    __typename?: 'UsersResponse'
    count?: number | null
    users?: Array<{
      __typename?: 'UserProfile'
      id?: string | null
      email?: string | null
      roles?: Array<RoleTypeEnum | null> | null
      lang?: LangTypeEnum | null
      firstName?: string | null
      lastName?: string | null
      phoneNumber?: string | null
      createdAt?: string | null
      clinicPermissions?: Array<{ __typename?: 'Clinic'; id: string; title?: string | null } | null> | null
      permission?: {
        __typename?: 'Permission'
        id?: string | null
        title?: string | null
        accesses: Array<{ __typename?: 'Access'; id: string; title: AccessTypeEnum }>
      } | null
    } | null> | null
  } | null
}

export type RefreshTokensMutationVariables = Exact<{
  accessToken: Scalars['String']
  refreshToken: Scalars['String']
}>

export type RefreshTokensMutation = {
  __typename?: 'Mutation'
  refreshTokens?: {
    __typename?: 'LoginResult'
    sessionId?: string | null
    tokens?: { __typename?: 'Tokens'; refreshToken?: string | null; accessToken?: string | null } | null
  } | null
}

export const GuideTemplateFragmentFragmentDoc = gql`
  fragment guideTemplateFragment on GuideTemplate {
    id
    name
    isPlatformDefault
    sections {
      sectionId
      sectionName
      questionIds
      required
      sectionIcon
      activeStepText
    }
  }
`
export const ClinicFragmentFragmentDoc = gql`
  fragment clinicFragment on Clinic {
    id
    title
    description
    createdAt
    startVideo
    updatedAt
    logo
    street
    street2
    stateId
    v2Signup
    state {
      id
      name
      abbreviation
    }
    postal
    city
    faxNumber
    slug
    guideTemplateId
    guideTemplate {
      ...guideTemplateFragment
    }
  }
  ${GuideTemplateFragmentFragmentDoc}
`
export const UserFragmentFragmentDoc = gql`
  fragment userFragment on UserProfile {
    id
    email
    roles
    lang
    firstName
    lastName
    phoneNumber
    createdAt
    permission {
      id
      title
      accesses {
        id
        title
      }
    }
  }
`
export const CurrentUserFragmentFragmentDoc = gql`
  fragment currentUserFragment on UserProfile {
    ...userFragment
    isGuest
    hasNetScore
    lang
    patient {
      actionHistory {
        name
        value
      }
    }
    clinic {
      id
      slug
      title
      logo
    }
  }
  ${UserFragmentFragmentDoc}
`
export const StateFragmentFragmentDoc = gql`
  fragment stateFragment on State {
    id
    abbreviation
    name
    advanceDirectiveVideo
    medicalCareScenariosVideo
    hasESign
    hasPaperCopy
    hasNotary
    linkToSigningRequirements
  }
`
export const PatientFragmentFragmentDoc = gql`
  fragment patientFragment on Patient {
    id
    guideTemplate {
      ...guideTemplateFragment
    }
    firstName
    lastName
    email
    phoneNumber
    startTime
    hasOfficialPlan
    agreeToTerms
    userId
    decisionMakerEmail
    importantToMe
    livingSituation
    likeToKnowCprAgeGroup
    cprAgeGroupVideo
    cprAffectPeopleAgeGroup
    birthday
    additionalCarePreferences
    additionalMdmDirections
    meaningOfLife
    notaryInfo
    clinicId
    spiritualAndReligiousBeliefs
    lifeAndValues
    receiveCareOther
    createdAt
    medicalCareScenario1
    medicalCareScenario2
    medicalCareScenario3
    lifeSustainingProcedures
    lifeSustainingProceduresOther
    notWorthLiving
    notWorthLiving2
    notWorthLiving3
    lastLocation
    cprPreference
    personaVacation
    personaSocialTies
    personaHealthcareExperience
    ventilationPreference
    longTermFeeding
    dialysisTreatment
    doYouKnowDecisionMaker
    decisionMakerFirstName
    decisionMakerLastName
    decisionMakerName
    decisionMakerPhone
    decisionMakerRelationship
    decisionMakerRelationshipOther
    decisionMakerStrictness
    decisionMakerAddressStreet
    decisionMakerAddressCity
    decisionMakerAddressState
    decisionMakerAddressZip
    wantsAltDecisionMaker
    whenCanDecisionMakerDecide
    altDecisionMakerFirstName
    altDecisionMakerLastName
    altDecisionMakerEmail
    altDecisionMakerPhone
    altDecisionMakerAddressStreet
    altDecisionMakerAddressCity
    altDecisionMakerAddressState
    altDecisionMakerAddressZip
    updatedAt
    cprPreferenceToday
    ventilationPreferenceToday
    longTermFeedingToday
    livingSituationOther
    dialysisTreatmentToday
    completedAt
    sharedPlan
    wantsThirdDecisionMaker
    thirdDecisionMakerFirstName
    thirdDecisionMakerLastName
    thirdDecisionMakerEmail
    thirdDecisionMakerPhone
    thirdDecisionMakerAddressStreet
    thirdDecisionMakerAddressCity
    thirdDecisionMakerAddressState
    thirdDecisionMakerAddressZip
    importantToMeOther
    thirdDecisionMakerRelationship
    altDecisionMakerRelationship
    altDecisionMakerRelationshipOther
    thirdDecisionMakerRelationshipOther
    interestedInPalliativeCare
    interestedInHospiceCare
    age
    slug
    completedAt
    additionalMdmDirections
    userId
    affordMedications
    clinic {
      id
      slug
      stateId
    }
    hasPlanViewed
    planShares {
      sendToEmail
      dateSent
    }
    stateVersion {
      ...stateFragment
    }
    healthIs
    doOnYourOwn
    doOnYourOwnWithoutHelp
    personalLossOrMisfortune
    takeCareSomeoneWasSeriouslyIll
    knowWhatAdvanceCarePlanningIs
    aboutWhoCanBeDecisionMaker
    aboutWhatMakesGoodDecisionMaker
    informedAboutFlexibilityDecisionMaker
    comfortLevelBecomeIll
    worriedFutureIllnesses
    decisionMakerKindOfMedicalYouWouldWant
    healthcareProviderKindOfMedicalYouWouldWant
    medicalAttorneyLivingWillAdvanceDirective
    statementMostAdvanceCarePlanning
    statementMostAdvanceCarePlanningNoneText
    postAcpDigitalAdvanceCarePlanning
    postAcpKnowWhatAdvanceCarePlanningIs
    postAcpAboutWhoCanBeDecisionMaker
    postAcpAboutWhatMakesGoodDecisionMaker
    postAcpInformedAboutFlexibilityDecisionMaker
    postAcpComfortLevelBecomeIll
    postAcpWorriedFutureIllnesses
    postAcpDecisionMakerKindOfMedicalYouWouldWant
    postAcpHealthcareProviderKindOfMedicalYouWouldWant
    postAcpHealthcareSystemTrust
    postAcpOnlineFormat
    postAcpOnlineFormatOther
    postAcpMedicalEducationPrefer
    postAcpMedicalEducationPreferOther
    postAcpInfoAboutCPR
    postAcpInfoAboutCPROther
    postAcpStatementMostAdvanceCarePlanning
    postAcpStatementMostAdvanceCarePlanningNoneText
    postAcpUseSystemFrequently
    postAcpSystemUnnecessarilyComplex
    postAcpSystemWasEasyUse
    postAcpSupportTechnicalPerson
    postAcpVariousFunctionsWellIntegrated
    postAcpTooMuchInconsistency
    postAcpMostPeopleWouldLearn
    postAcpSystemVeryCumbersomeToUse
    postAcpConfidentUsingTheSystem
    postAcpLearnLotPfThingsBefore
    schedulingACPinformation
    patientSignatureFirstName
    patientSignatureLastName
    patientSignatureDate
    address
    city
    postal
    witness1SignatureFirstName
    witness1SignatureLastName
    witness1SignatureEmail
    witness1SignatureDate
    witness1LocationType
    witness2SignatureFirstName
    witness2SignatureLastName
    witness2SignatureEmail
    witness2SignatureDate
    witness2LocationType
    actionHistory {
      name
      value
      createdAt
    }
    readiness {
      answer
      otherAnswer
      createdAt
    }
  }
  ${GuideTemplateFragmentFragmentDoc}
  ${StateFragmentFragmentDoc}
`
export const AgreeToKodaTermsDocument = gql`
  mutation agreeToKodaTerms {
    agreeToKodaTerms {
      success
    }
  }
`
export type AgreeToKodaTermsMutationFn = Apollo.MutationFunction<
  AgreeToKodaTermsMutation,
  AgreeToKodaTermsMutationVariables
>

/**
 * __useAgreeToKodaTermsMutation__
 *
 * To run a mutation, you first call `useAgreeToKodaTermsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAgreeToKodaTermsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [agreeToKodaTermsMutation, { data, loading, error }] = useAgreeToKodaTermsMutation({
 *   variables: {
 *   },
 * });
 */
export function useAgreeToKodaTermsMutation(
  baseOptions?: Apollo.MutationHookOptions<AgreeToKodaTermsMutation, AgreeToKodaTermsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<AgreeToKodaTermsMutation, AgreeToKodaTermsMutationVariables>(
    AgreeToKodaTermsDocument,
    options
  )
}
export type AgreeToKodaTermsMutationHookResult = ReturnType<typeof useAgreeToKodaTermsMutation>
export type AgreeToKodaTermsMutationResult = Apollo.MutationResult<AgreeToKodaTermsMutation>
export type AgreeToKodaTermsMutationOptions = Apollo.BaseMutationOptions<
  AgreeToKodaTermsMutation,
  AgreeToKodaTermsMutationVariables
>
export const ChangePasswordDocument = gql`
  mutation changePassword($oldPassword: String!, $newPassword: String!) {
    changePassword(oldPassword: $oldPassword, newPassword: $newPassword)
  }
`
export type ChangePasswordMutationFn = Apollo.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      oldPassword: // value for 'oldPassword'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useChangePasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, options)
}
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>
export type ChangePasswordMutationResult = Apollo.MutationResult<ChangePasswordMutation>
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<
  ChangePasswordMutation,
  ChangePasswordMutationVariables
>
export const CompleteESignDocument = gql`
  mutation completeESign($patientId: ID!, $params: PlanSignaturesInput!, $s3Key: String!) {
    completeESign(patientId: $patientId, params: $params, s3Key: $s3Key) {
      ...patientFragment
    }
  }
  ${PatientFragmentFragmentDoc}
`
export type CompleteESignMutationFn = Apollo.MutationFunction<CompleteESignMutation, CompleteESignMutationVariables>

/**
 * __useCompleteESignMutation__
 *
 * To run a mutation, you first call `useCompleteESignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteESignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeESignMutation, { data, loading, error }] = useCompleteESignMutation({
 *   variables: {
 *      patientId: // value for 'patientId'
 *      params: // value for 'params'
 *      s3Key: // value for 's3Key'
 *   },
 * });
 */
export function useCompleteESignMutation(
  baseOptions?: Apollo.MutationHookOptions<CompleteESignMutation, CompleteESignMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CompleteESignMutation, CompleteESignMutationVariables>(CompleteESignDocument, options)
}
export type CompleteESignMutationHookResult = ReturnType<typeof useCompleteESignMutation>
export type CompleteESignMutationResult = Apollo.MutationResult<CompleteESignMutation>
export type CompleteESignMutationOptions = Apollo.BaseMutationOptions<
  CompleteESignMutation,
  CompleteESignMutationVariables
>
export const CompleteMultiUploadDocument = gql`
  mutation completeMultiUpload(
    $type: MultipartUploadCompleteEnum!
    $uploadId: String!
    $key: String!
    $parts: [MultipartUploadPartInput!]
  ) {
    completeMultiUpload(type: $type, uploadId: $uploadId, key: $key, parts: $parts) {
      success
    }
  }
`
export type CompleteMultiUploadMutationFn = Apollo.MutationFunction<
  CompleteMultiUploadMutation,
  CompleteMultiUploadMutationVariables
>

/**
 * __useCompleteMultiUploadMutation__
 *
 * To run a mutation, you first call `useCompleteMultiUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteMultiUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeMultiUploadMutation, { data, loading, error }] = useCompleteMultiUploadMutation({
 *   variables: {
 *      type: // value for 'type'
 *      uploadId: // value for 'uploadId'
 *      key: // value for 'key'
 *      parts: // value for 'parts'
 *   },
 * });
 */
export function useCompleteMultiUploadMutation(
  baseOptions?: Apollo.MutationHookOptions<CompleteMultiUploadMutation, CompleteMultiUploadMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CompleteMultiUploadMutation, CompleteMultiUploadMutationVariables>(
    CompleteMultiUploadDocument,
    options
  )
}
export type CompleteMultiUploadMutationHookResult = ReturnType<typeof useCompleteMultiUploadMutation>
export type CompleteMultiUploadMutationResult = Apollo.MutationResult<CompleteMultiUploadMutation>
export type CompleteMultiUploadMutationOptions = Apollo.BaseMutationOptions<
  CompleteMultiUploadMutation,
  CompleteMultiUploadMutationVariables
>
export const CreateAccountFromGuestDocument = gql`
  mutation createAccountFromGuest($email: String!, $password: String!, $firstName: String!, $lastName: String!) {
    createAccountFromGuest(email: $email, password: $password, firstName: $firstName, lastName: $lastName) {
      success
      errors
    }
  }
`
export type CreateAccountFromGuestMutationFn = Apollo.MutationFunction<
  CreateAccountFromGuestMutation,
  CreateAccountFromGuestMutationVariables
>

/**
 * __useCreateAccountFromGuestMutation__
 *
 * To run a mutation, you first call `useCreateAccountFromGuestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccountFromGuestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccountFromGuestMutation, { data, loading, error }] = useCreateAccountFromGuestMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *   },
 * });
 */
export function useCreateAccountFromGuestMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateAccountFromGuestMutation, CreateAccountFromGuestMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateAccountFromGuestMutation, CreateAccountFromGuestMutationVariables>(
    CreateAccountFromGuestDocument,
    options
  )
}
export type CreateAccountFromGuestMutationHookResult = ReturnType<typeof useCreateAccountFromGuestMutation>
export type CreateAccountFromGuestMutationResult = Apollo.MutationResult<CreateAccountFromGuestMutation>
export type CreateAccountFromGuestMutationOptions = Apollo.BaseMutationOptions<
  CreateAccountFromGuestMutation,
  CreateAccountFromGuestMutationVariables
>
export const CreateAdminUserDocument = gql`
  mutation createAdminUser(
    $firstName: String!
    $lastName: String!
    $email: String!
    $permissionId: ID!
    $clinicIds: [String]!
  ) {
    createAdminUser(
      firstName: $firstName
      lastName: $lastName
      email: $email
      permissionId: $permissionId
      clinicIds: $clinicIds
    ) {
      ...userFragment
    }
  }
  ${UserFragmentFragmentDoc}
`
export type CreateAdminUserMutationFn = Apollo.MutationFunction<
  CreateAdminUserMutation,
  CreateAdminUserMutationVariables
>

/**
 * __useCreateAdminUserMutation__
 *
 * To run a mutation, you first call `useCreateAdminUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAdminUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAdminUserMutation, { data, loading, error }] = useCreateAdminUserMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      email: // value for 'email'
 *      permissionId: // value for 'permissionId'
 *      clinicIds: // value for 'clinicIds'
 *   },
 * });
 */
export function useCreateAdminUserMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateAdminUserMutation, CreateAdminUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateAdminUserMutation, CreateAdminUserMutationVariables>(CreateAdminUserDocument, options)
}
export type CreateAdminUserMutationHookResult = ReturnType<typeof useCreateAdminUserMutation>
export type CreateAdminUserMutationResult = Apollo.MutationResult<CreateAdminUserMutation>
export type CreateAdminUserMutationOptions = Apollo.BaseMutationOptions<
  CreateAdminUserMutation,
  CreateAdminUserMutationVariables
>
export const CreateClinicDocument = gql`
  mutation createClinic($params: ClinicParams) {
    createClinic(params: $params) {
      ...clinicFragment
    }
  }
  ${ClinicFragmentFragmentDoc}
`
export type CreateClinicMutationFn = Apollo.MutationFunction<CreateClinicMutation, CreateClinicMutationVariables>

/**
 * __useCreateClinicMutation__
 *
 * To run a mutation, you first call `useCreateClinicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClinicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClinicMutation, { data, loading, error }] = useCreateClinicMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useCreateClinicMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateClinicMutation, CreateClinicMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateClinicMutation, CreateClinicMutationVariables>(CreateClinicDocument, options)
}
export type CreateClinicMutationHookResult = ReturnType<typeof useCreateClinicMutation>
export type CreateClinicMutationResult = Apollo.MutationResult<CreateClinicMutation>
export type CreateClinicMutationOptions = Apollo.BaseMutationOptions<
  CreateClinicMutation,
  CreateClinicMutationVariables
>
export const CreateClinicUserDocument = gql`
  mutation createClinicUser(
    $firstName: String!
    $lastName: String!
    $email: String!
    $clinicId: String!
    $permissionId: ID!
  ) {
    createClinicUser(
      firstName: $firstName
      lastName: $lastName
      email: $email
      clinicId: $clinicId
      permissionId: $permissionId
    ) {
      ...userFragment
    }
  }
  ${UserFragmentFragmentDoc}
`
export type CreateClinicUserMutationFn = Apollo.MutationFunction<
  CreateClinicUserMutation,
  CreateClinicUserMutationVariables
>

/**
 * __useCreateClinicUserMutation__
 *
 * To run a mutation, you first call `useCreateClinicUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClinicUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClinicUserMutation, { data, loading, error }] = useCreateClinicUserMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      email: // value for 'email'
 *      clinicId: // value for 'clinicId'
 *      permissionId: // value for 'permissionId'
 *   },
 * });
 */
export function useCreateClinicUserMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateClinicUserMutation, CreateClinicUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateClinicUserMutation, CreateClinicUserMutationVariables>(
    CreateClinicUserDocument,
    options
  )
}
export type CreateClinicUserMutationHookResult = ReturnType<typeof useCreateClinicUserMutation>
export type CreateClinicUserMutationResult = Apollo.MutationResult<CreateClinicUserMutation>
export type CreateClinicUserMutationOptions = Apollo.BaseMutationOptions<
  CreateClinicUserMutation,
  CreateClinicUserMutationVariables
>
export const CreateOneActionHistoryItemDocument = gql`
  mutation createOneActionHistoryItem($name: PatientActionHistoryItemEnum!, $value: String) {
    createOneActionHistoryItem(name: $name, value: $value) {
      ...patientFragment
    }
  }
  ${PatientFragmentFragmentDoc}
`
export type CreateOneActionHistoryItemMutationFn = Apollo.MutationFunction<
  CreateOneActionHistoryItemMutation,
  CreateOneActionHistoryItemMutationVariables
>

/**
 * __useCreateOneActionHistoryItemMutation__
 *
 * To run a mutation, you first call `useCreateOneActionHistoryItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneActionHistoryItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneActionHistoryItemMutation, { data, loading, error }] = useCreateOneActionHistoryItemMutation({
 *   variables: {
 *      name: // value for 'name'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useCreateOneActionHistoryItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOneActionHistoryItemMutation,
    CreateOneActionHistoryItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateOneActionHistoryItemMutation, CreateOneActionHistoryItemMutationVariables>(
    CreateOneActionHistoryItemDocument,
    options
  )
}
export type CreateOneActionHistoryItemMutationHookResult = ReturnType<typeof useCreateOneActionHistoryItemMutation>
export type CreateOneActionHistoryItemMutationResult = Apollo.MutationResult<CreateOneActionHistoryItemMutation>
export type CreateOneActionHistoryItemMutationOptions = Apollo.BaseMutationOptions<
  CreateOneActionHistoryItemMutation,
  CreateOneActionHistoryItemMutationVariables
>
export const CreateOneReadinessMpoResponseDocument = gql`
  mutation createOneReadinessMPOResponse($answer: SubmitReadinessMpoResponseEnum!, $otherAnswer: String) {
    createOneReadinessMPOResponse(answer: $answer, otherAnswer: $otherAnswer) {
      ...patientFragment
    }
  }
  ${PatientFragmentFragmentDoc}
`
export type CreateOneReadinessMpoResponseMutationFn = Apollo.MutationFunction<
  CreateOneReadinessMpoResponseMutation,
  CreateOneReadinessMpoResponseMutationVariables
>

/**
 * __useCreateOneReadinessMpoResponseMutation__
 *
 * To run a mutation, you first call `useCreateOneReadinessMpoResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneReadinessMpoResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneReadinessMpoResponseMutation, { data, loading, error }] = useCreateOneReadinessMpoResponseMutation({
 *   variables: {
 *      answer: // value for 'answer'
 *      otherAnswer: // value for 'otherAnswer'
 *   },
 * });
 */
export function useCreateOneReadinessMpoResponseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOneReadinessMpoResponseMutation,
    CreateOneReadinessMpoResponseMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateOneReadinessMpoResponseMutation, CreateOneReadinessMpoResponseMutationVariables>(
    CreateOneReadinessMpoResponseDocument,
    options
  )
}
export type CreateOneReadinessMpoResponseMutationHookResult = ReturnType<
  typeof useCreateOneReadinessMpoResponseMutation
>
export type CreateOneReadinessMpoResponseMutationResult = Apollo.MutationResult<CreateOneReadinessMpoResponseMutation>
export type CreateOneReadinessMpoResponseMutationOptions = Apollo.BaseMutationOptions<
  CreateOneReadinessMpoResponseMutation,
  CreateOneReadinessMpoResponseMutationVariables
>
export const CreatePermissionDocument = gql`
  mutation createPermission($title: String!, $description: String!, $accesses: [String]!) {
    createPermission(title: $title, description: $description, accesses: $accesses) {
      id
    }
  }
`
export type CreatePermissionMutationFn = Apollo.MutationFunction<
  CreatePermissionMutation,
  CreatePermissionMutationVariables
>

/**
 * __useCreatePermissionMutation__
 *
 * To run a mutation, you first call `useCreatePermissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePermissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPermissionMutation, { data, loading, error }] = useCreatePermissionMutation({
 *   variables: {
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      accesses: // value for 'accesses'
 *   },
 * });
 */
export function useCreatePermissionMutation(
  baseOptions?: Apollo.MutationHookOptions<CreatePermissionMutation, CreatePermissionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreatePermissionMutation, CreatePermissionMutationVariables>(
    CreatePermissionDocument,
    options
  )
}
export type CreatePermissionMutationHookResult = ReturnType<typeof useCreatePermissionMutation>
export type CreatePermissionMutationResult = Apollo.MutationResult<CreatePermissionMutation>
export type CreatePermissionMutationOptions = Apollo.BaseMutationOptions<
  CreatePermissionMutation,
  CreatePermissionMutationVariables
>
export const DeleteAdminUserDocument = gql`
  mutation deleteAdminUser($id: ID!) {
    deleteAdminUser(id: $id) {
      success
      errors
    }
  }
`
export type DeleteAdminUserMutationFn = Apollo.MutationFunction<
  DeleteAdminUserMutation,
  DeleteAdminUserMutationVariables
>

/**
 * __useDeleteAdminUserMutation__
 *
 * To run a mutation, you first call `useDeleteAdminUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAdminUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAdminUserMutation, { data, loading, error }] = useDeleteAdminUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAdminUserMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteAdminUserMutation, DeleteAdminUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteAdminUserMutation, DeleteAdminUserMutationVariables>(DeleteAdminUserDocument, options)
}
export type DeleteAdminUserMutationHookResult = ReturnType<typeof useDeleteAdminUserMutation>
export type DeleteAdminUserMutationResult = Apollo.MutationResult<DeleteAdminUserMutation>
export type DeleteAdminUserMutationOptions = Apollo.BaseMutationOptions<
  DeleteAdminUserMutation,
  DeleteAdminUserMutationVariables
>
export const DeletePatientUserDocument = gql`
  mutation deletePatientUser($patientId: ID!) {
    deletePatientUser(patientId: $patientId) {
      success
      errors
    }
  }
`
export type DeletePatientUserMutationFn = Apollo.MutationFunction<
  DeletePatientUserMutation,
  DeletePatientUserMutationVariables
>

/**
 * __useDeletePatientUserMutation__
 *
 * To run a mutation, you first call `useDeletePatientUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePatientUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePatientUserMutation, { data, loading, error }] = useDeletePatientUserMutation({
 *   variables: {
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function useDeletePatientUserMutation(
  baseOptions?: Apollo.MutationHookOptions<DeletePatientUserMutation, DeletePatientUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeletePatientUserMutation, DeletePatientUserMutationVariables>(
    DeletePatientUserDocument,
    options
  )
}
export type DeletePatientUserMutationHookResult = ReturnType<typeof useDeletePatientUserMutation>
export type DeletePatientUserMutationResult = Apollo.MutationResult<DeletePatientUserMutation>
export type DeletePatientUserMutationOptions = Apollo.BaseMutationOptions<
  DeletePatientUserMutation,
  DeletePatientUserMutationVariables
>
export const DeletePlanAttachmentDocument = gql`
  mutation deletePlanAttachment($id: ID!) {
    deletePlanAttachment(id: $id) {
      success
      errors
    }
  }
`
export type DeletePlanAttachmentMutationFn = Apollo.MutationFunction<
  DeletePlanAttachmentMutation,
  DeletePlanAttachmentMutationVariables
>

/**
 * __useDeletePlanAttachmentMutation__
 *
 * To run a mutation, you first call `useDeletePlanAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePlanAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePlanAttachmentMutation, { data, loading, error }] = useDeletePlanAttachmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePlanAttachmentMutation(
  baseOptions?: Apollo.MutationHookOptions<DeletePlanAttachmentMutation, DeletePlanAttachmentMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeletePlanAttachmentMutation, DeletePlanAttachmentMutationVariables>(
    DeletePlanAttachmentDocument,
    options
  )
}
export type DeletePlanAttachmentMutationHookResult = ReturnType<typeof useDeletePlanAttachmentMutation>
export type DeletePlanAttachmentMutationResult = Apollo.MutationResult<DeletePlanAttachmentMutation>
export type DeletePlanAttachmentMutationOptions = Apollo.BaseMutationOptions<
  DeletePlanAttachmentMutation,
  DeletePlanAttachmentMutationVariables
>
export const EmailDirectiveDocument = gql`
  mutation emailDirective($email: String, $decisionMakerEmail: String, $otherEmails: [String], $personalNote: String) {
    emailDirective(
      email: $email
      decisionMakerEmail: $decisionMakerEmail
      otherEmails: $otherEmails
      personalNote: $personalNote
    ) {
      success
      errors
    }
  }
`
export type EmailDirectiveMutationFn = Apollo.MutationFunction<EmailDirectiveMutation, EmailDirectiveMutationVariables>

/**
 * __useEmailDirectiveMutation__
 *
 * To run a mutation, you first call `useEmailDirectiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmailDirectiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [emailDirectiveMutation, { data, loading, error }] = useEmailDirectiveMutation({
 *   variables: {
 *      email: // value for 'email'
 *      decisionMakerEmail: // value for 'decisionMakerEmail'
 *      otherEmails: // value for 'otherEmails'
 *      personalNote: // value for 'personalNote'
 *   },
 * });
 */
export function useEmailDirectiveMutation(
  baseOptions?: Apollo.MutationHookOptions<EmailDirectiveMutation, EmailDirectiveMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<EmailDirectiveMutation, EmailDirectiveMutationVariables>(EmailDirectiveDocument, options)
}
export type EmailDirectiveMutationHookResult = ReturnType<typeof useEmailDirectiveMutation>
export type EmailDirectiveMutationResult = Apollo.MutationResult<EmailDirectiveMutation>
export type EmailDirectiveMutationOptions = Apollo.BaseMutationOptions<
  EmailDirectiveMutation,
  EmailDirectiveMutationVariables
>
export const FaxClinicDocument = gql`
  mutation faxClinic(
    $base64String: String!
    $faxToNumber: String!
    $patientId: ID!
    $clinicId: ID!
    $documentType: String!
  ) {
    faxClinic(
      base64String: $base64String
      faxToNumber: $faxToNumber
      patientId: $patientId
      clinicId: $clinicId
      documentType: $documentType
    ) {
      success
      errors
    }
  }
`
export type FaxClinicMutationFn = Apollo.MutationFunction<FaxClinicMutation, FaxClinicMutationVariables>

/**
 * __useFaxClinicMutation__
 *
 * To run a mutation, you first call `useFaxClinicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFaxClinicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [faxClinicMutation, { data, loading, error }] = useFaxClinicMutation({
 *   variables: {
 *      base64String: // value for 'base64String'
 *      faxToNumber: // value for 'faxToNumber'
 *      patientId: // value for 'patientId'
 *      clinicId: // value for 'clinicId'
 *      documentType: // value for 'documentType'
 *   },
 * });
 */
export function useFaxClinicMutation(
  baseOptions?: Apollo.MutationHookOptions<FaxClinicMutation, FaxClinicMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<FaxClinicMutation, FaxClinicMutationVariables>(FaxClinicDocument, options)
}
export type FaxClinicMutationHookResult = ReturnType<typeof useFaxClinicMutation>
export type FaxClinicMutationResult = Apollo.MutationResult<FaxClinicMutation>
export type FaxClinicMutationOptions = Apollo.BaseMutationOptions<FaxClinicMutation, FaxClinicMutationVariables>
export const GeneratePatientDownloadUrlDocument = gql`
  mutation generatePatientDownloadUrl($patientId: ID!) {
    generatePatientDownloadUrl(patientId: $patientId)
  }
`
export type GeneratePatientDownloadUrlMutationFn = Apollo.MutationFunction<
  GeneratePatientDownloadUrlMutation,
  GeneratePatientDownloadUrlMutationVariables
>

/**
 * __useGeneratePatientDownloadUrlMutation__
 *
 * To run a mutation, you first call `useGeneratePatientDownloadUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGeneratePatientDownloadUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generatePatientDownloadUrlMutation, { data, loading, error }] = useGeneratePatientDownloadUrlMutation({
 *   variables: {
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function useGeneratePatientDownloadUrlMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GeneratePatientDownloadUrlMutation,
    GeneratePatientDownloadUrlMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<GeneratePatientDownloadUrlMutation, GeneratePatientDownloadUrlMutationVariables>(
    GeneratePatientDownloadUrlDocument,
    options
  )
}
export type GeneratePatientDownloadUrlMutationHookResult = ReturnType<typeof useGeneratePatientDownloadUrlMutation>
export type GeneratePatientDownloadUrlMutationResult = Apollo.MutationResult<GeneratePatientDownloadUrlMutation>
export type GeneratePatientDownloadUrlMutationOptions = Apollo.BaseMutationOptions<
  GeneratePatientDownloadUrlMutation,
  GeneratePatientDownloadUrlMutationVariables
>
export const GetPresignedUploadMultiPartDocument = gql`
  mutation getPresignedUploadMultiPart($contentType: String!, $parts: Int!) {
    getPresignedUploadMultiPart(contentType: $contentType, parts: $parts) {
      key
      uploadId
      urls
    }
  }
`
export type GetPresignedUploadMultiPartMutationFn = Apollo.MutationFunction<
  GetPresignedUploadMultiPartMutation,
  GetPresignedUploadMultiPartMutationVariables
>

/**
 * __useGetPresignedUploadMultiPartMutation__
 *
 * To run a mutation, you first call `useGetPresignedUploadMultiPartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetPresignedUploadMultiPartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getPresignedUploadMultiPartMutation, { data, loading, error }] = useGetPresignedUploadMultiPartMutation({
 *   variables: {
 *      contentType: // value for 'contentType'
 *      parts: // value for 'parts'
 *   },
 * });
 */
export function useGetPresignedUploadMultiPartMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GetPresignedUploadMultiPartMutation,
    GetPresignedUploadMultiPartMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<GetPresignedUploadMultiPartMutation, GetPresignedUploadMultiPartMutationVariables>(
    GetPresignedUploadMultiPartDocument,
    options
  )
}
export type GetPresignedUploadMultiPartMutationHookResult = ReturnType<typeof useGetPresignedUploadMultiPartMutation>
export type GetPresignedUploadMultiPartMutationResult = Apollo.MutationResult<GetPresignedUploadMultiPartMutation>
export type GetPresignedUploadMultiPartMutationOptions = Apollo.BaseMutationOptions<
  GetPresignedUploadMultiPartMutation,
  GetPresignedUploadMultiPartMutationVariables
>
export const GetPresignedUploadUrlDocument = gql`
  mutation getPresignedUploadUrl($contentType: String!) {
    getPresignedUploadUrl(contentType: $contentType) {
      url
      fields
    }
  }
`
export type GetPresignedUploadUrlMutationFn = Apollo.MutationFunction<
  GetPresignedUploadUrlMutation,
  GetPresignedUploadUrlMutationVariables
>

/**
 * __useGetPresignedUploadUrlMutation__
 *
 * To run a mutation, you first call `useGetPresignedUploadUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetPresignedUploadUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getPresignedUploadUrlMutation, { data, loading, error }] = useGetPresignedUploadUrlMutation({
 *   variables: {
 *      contentType: // value for 'contentType'
 *   },
 * });
 */
export function useGetPresignedUploadUrlMutation(
  baseOptions?: Apollo.MutationHookOptions<GetPresignedUploadUrlMutation, GetPresignedUploadUrlMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<GetPresignedUploadUrlMutation, GetPresignedUploadUrlMutationVariables>(
    GetPresignedUploadUrlDocument,
    options
  )
}
export type GetPresignedUploadUrlMutationHookResult = ReturnType<typeof useGetPresignedUploadUrlMutation>
export type GetPresignedUploadUrlMutationResult = Apollo.MutationResult<GetPresignedUploadUrlMutation>
export type GetPresignedUploadUrlMutationOptions = Apollo.BaseMutationOptions<
  GetPresignedUploadUrlMutation,
  GetPresignedUploadUrlMutationVariables
>
export const InviteManyPatientsDocument = gql`
  mutation inviteManyPatients($clinicId: ID!, $patients: [InvitePatientInput!]!) {
    inviteManyPatients(clinicId: $clinicId, patients: $patients) {
      success
      message
      errors
    }
  }
`
export type InviteManyPatientsMutationFn = Apollo.MutationFunction<
  InviteManyPatientsMutation,
  InviteManyPatientsMutationVariables
>

/**
 * __useInviteManyPatientsMutation__
 *
 * To run a mutation, you first call `useInviteManyPatientsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteManyPatientsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteManyPatientsMutation, { data, loading, error }] = useInviteManyPatientsMutation({
 *   variables: {
 *      clinicId: // value for 'clinicId'
 *      patients: // value for 'patients'
 *   },
 * });
 */
export function useInviteManyPatientsMutation(
  baseOptions?: Apollo.MutationHookOptions<InviteManyPatientsMutation, InviteManyPatientsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<InviteManyPatientsMutation, InviteManyPatientsMutationVariables>(
    InviteManyPatientsDocument,
    options
  )
}
export type InviteManyPatientsMutationHookResult = ReturnType<typeof useInviteManyPatientsMutation>
export type InviteManyPatientsMutationResult = Apollo.MutationResult<InviteManyPatientsMutation>
export type InviteManyPatientsMutationOptions = Apollo.BaseMutationOptions<
  InviteManyPatientsMutation,
  InviteManyPatientsMutationVariables
>
export const InviteOnePatientDocument = gql`
  mutation inviteOnePatient(
    $email: String!
    $stateId: String
    $clinicId: String!
    $birthday: String!
    $firstName: String!
    $lastName: String!
    $referralDate: String!
    $freshworksId: String
  ) {
    inviteOnePatient(
      email: $email
      stateId: $stateId
      birthday: $birthday
      clinicId: $clinicId
      firstName: $firstName
      lastName: $lastName
      referralDate: $referralDate
      freshworksId: $freshworksId
    ) {
      id
    }
  }
`
export type InviteOnePatientMutationFn = Apollo.MutationFunction<
  InviteOnePatientMutation,
  InviteOnePatientMutationVariables
>

/**
 * __useInviteOnePatientMutation__
 *
 * To run a mutation, you first call `useInviteOnePatientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteOnePatientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteOnePatientMutation, { data, loading, error }] = useInviteOnePatientMutation({
 *   variables: {
 *      email: // value for 'email'
 *      stateId: // value for 'stateId'
 *      clinicId: // value for 'clinicId'
 *      birthday: // value for 'birthday'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      referralDate: // value for 'referralDate'
 *      freshworksId: // value for 'freshworksId'
 *   },
 * });
 */
export function useInviteOnePatientMutation(
  baseOptions?: Apollo.MutationHookOptions<InviteOnePatientMutation, InviteOnePatientMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<InviteOnePatientMutation, InviteOnePatientMutationVariables>(
    InviteOnePatientDocument,
    options
  )
}
export type InviteOnePatientMutationHookResult = ReturnType<typeof useInviteOnePatientMutation>
export type InviteOnePatientMutationResult = Apollo.MutationResult<InviteOnePatientMutation>
export type InviteOnePatientMutationOptions = Apollo.BaseMutationOptions<
  InviteOnePatientMutation,
  InviteOnePatientMutationVariables
>
export const LogActivityItemDocument = gql`
  mutation logActivityItem(
    $importance: Int
    $userId: String!
    $patientId: String
    $clinicId: String
    $content: String
    $key: ActivityLogKeyEnum!
  ) {
    logActivityItem(
      importance: $importance
      userId: $userId
      patientId: $patientId
      clinicId: $clinicId
      content: $content
      key: $key
    ) {
      success
      errors
    }
  }
`
export type LogActivityItemMutationFn = Apollo.MutationFunction<
  LogActivityItemMutation,
  LogActivityItemMutationVariables
>

/**
 * __useLogActivityItemMutation__
 *
 * To run a mutation, you first call `useLogActivityItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogActivityItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logActivityItemMutation, { data, loading, error }] = useLogActivityItemMutation({
 *   variables: {
 *      importance: // value for 'importance'
 *      userId: // value for 'userId'
 *      patientId: // value for 'patientId'
 *      clinicId: // value for 'clinicId'
 *      content: // value for 'content'
 *      key: // value for 'key'
 *   },
 * });
 */
export function useLogActivityItemMutation(
  baseOptions?: Apollo.MutationHookOptions<LogActivityItemMutation, LogActivityItemMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<LogActivityItemMutation, LogActivityItemMutationVariables>(LogActivityItemDocument, options)
}
export type LogActivityItemMutationHookResult = ReturnType<typeof useLogActivityItemMutation>
export type LogActivityItemMutationResult = Apollo.MutationResult<LogActivityItemMutation>
export type LogActivityItemMutationOptions = Apollo.BaseMutationOptions<
  LogActivityItemMutation,
  LogActivityItemMutationVariables
>
export const LogEventDocument = gql`
  mutation logEvent($eventName: String!, $metaData: String, $mixpanelDistinctId: String) {
    logEvent(eventName: $eventName, metaData: $metaData, mixpanelDistinctId: $mixpanelDistinctId) {
      success
      errors
    }
  }
`
export type LogEventMutationFn = Apollo.MutationFunction<LogEventMutation, LogEventMutationVariables>

/**
 * __useLogEventMutation__
 *
 * To run a mutation, you first call `useLogEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logEventMutation, { data, loading, error }] = useLogEventMutation({
 *   variables: {
 *      eventName: // value for 'eventName'
 *      metaData: // value for 'metaData'
 *      mixpanelDistinctId: // value for 'mixpanelDistinctId'
 *   },
 * });
 */
export function useLogEventMutation(
  baseOptions?: Apollo.MutationHookOptions<LogEventMutation, LogEventMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<LogEventMutation, LogEventMutationVariables>(LogEventDocument, options)
}
export type LogEventMutationHookResult = ReturnType<typeof useLogEventMutation>
export type LogEventMutationResult = Apollo.MutationResult<LogEventMutation>
export type LogEventMutationOptions = Apollo.BaseMutationOptions<LogEventMutation, LogEventMutationVariables>
export const LogWakeForestEventDocument = gql`
  mutation logWakeForestEvent(
    $userId: String
    $content: String
    $key: String
    $patientId: String
    $clinicSlug: String
    $externalId: String
  ) {
    logWakeForestEvent(
      userId: $userId
      content: $content
      key: $key
      patientId: $patientId
      clinicSlug: $clinicSlug
      externalId: $externalId
    ) {
      id
    }
  }
`
export type LogWakeForestEventMutationFn = Apollo.MutationFunction<
  LogWakeForestEventMutation,
  LogWakeForestEventMutationVariables
>

/**
 * __useLogWakeForestEventMutation__
 *
 * To run a mutation, you first call `useLogWakeForestEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogWakeForestEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logWakeForestEventMutation, { data, loading, error }] = useLogWakeForestEventMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      content: // value for 'content'
 *      key: // value for 'key'
 *      patientId: // value for 'patientId'
 *      clinicSlug: // value for 'clinicSlug'
 *      externalId: // value for 'externalId'
 *   },
 * });
 */
export function useLogWakeForestEventMutation(
  baseOptions?: Apollo.MutationHookOptions<LogWakeForestEventMutation, LogWakeForestEventMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<LogWakeForestEventMutation, LogWakeForestEventMutationVariables>(
    LogWakeForestEventDocument,
    options
  )
}
export type LogWakeForestEventMutationHookResult = ReturnType<typeof useLogWakeForestEventMutation>
export type LogWakeForestEventMutationResult = Apollo.MutationResult<LogWakeForestEventMutation>
export type LogWakeForestEventMutationOptions = Apollo.BaseMutationOptions<
  LogWakeForestEventMutation,
  LogWakeForestEventMutationVariables
>
export const LoginDocument = gql`
  mutation login($params: AuthenticateParamsInput!) {
    login(params: $params) {
      mfaRequired
      sessionId
      tokens {
        refreshToken
        accessToken
      }
    }
  }
`
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options)
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>
export const LogoutDocument = gql`
  mutation logout {
    logout
  }
`
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options)
}
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>
export const NewPatientSignupDocument = gql`
  mutation newPatientSignup(
    $email: String
    $password: String
    $slug: String!
    $firstName: String
    $lastName: String
    $birthday: String
    $stateId: String
    $externalId: String
    $phoneNumber: String
  ) {
    newPatientSignup(
      email: $email
      password: $password
      slug: $slug
      firstName: $firstName
      lastName: $lastName
      birthday: $birthday
      stateId: $stateId
      externalId: $externalId
      phoneNumber: $phoneNumber
    ) {
      userId
      patientId
      accessToken
      refreshToken
    }
  }
`
export type NewPatientSignupMutationFn = Apollo.MutationFunction<
  NewPatientSignupMutation,
  NewPatientSignupMutationVariables
>

/**
 * __useNewPatientSignupMutation__
 *
 * To run a mutation, you first call `useNewPatientSignupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewPatientSignupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newPatientSignupMutation, { data, loading, error }] = useNewPatientSignupMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      slug: // value for 'slug'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      birthday: // value for 'birthday'
 *      stateId: // value for 'stateId'
 *      externalId: // value for 'externalId'
 *      phoneNumber: // value for 'phoneNumber'
 *   },
 * });
 */
export function useNewPatientSignupMutation(
  baseOptions?: Apollo.MutationHookOptions<NewPatientSignupMutation, NewPatientSignupMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<NewPatientSignupMutation, NewPatientSignupMutationVariables>(
    NewPatientSignupDocument,
    options
  )
}
export type NewPatientSignupMutationHookResult = ReturnType<typeof useNewPatientSignupMutation>
export type NewPatientSignupMutationResult = Apollo.MutationResult<NewPatientSignupMutation>
export type NewPatientSignupMutationOptions = Apollo.BaseMutationOptions<
  NewPatientSignupMutation,
  NewPatientSignupMutationVariables
>
export const ResendMfaDocument = gql`
  mutation resendMfa($email: String!) {
    resendMfa(email: $email) {
      success
    }
  }
`
export type ResendMfaMutationFn = Apollo.MutationFunction<ResendMfaMutation, ResendMfaMutationVariables>

/**
 * __useResendMfaMutation__
 *
 * To run a mutation, you first call `useResendMfaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendMfaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendMfaMutation, { data, loading, error }] = useResendMfaMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useResendMfaMutation(
  baseOptions?: Apollo.MutationHookOptions<ResendMfaMutation, ResendMfaMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ResendMfaMutation, ResendMfaMutationVariables>(ResendMfaDocument, options)
}
export type ResendMfaMutationHookResult = ReturnType<typeof useResendMfaMutation>
export type ResendMfaMutationResult = Apollo.MutationResult<ResendMfaMutation>
export type ResendMfaMutationOptions = Apollo.BaseMutationOptions<ResendMfaMutation, ResendMfaMutationVariables>
export const ResetPasswordDocument = gql`
  mutation resetPassword($newPassword: String!, $token: String!) {
    resetPassword(newPassword: $newPassword, token: $token) {
      sessionId
      tokens {
        refreshToken
        accessToken
      }
    }
  }
`
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      newPassword: // value for 'newPassword'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useResetPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options)
}
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>
export const RevertEsignDocument = gql`
  mutation revertEsign($params: PatientInput!) {
    revertEsign(params: $params) {
      ...patientFragment
    }
  }
  ${PatientFragmentFragmentDoc}
`
export type RevertEsignMutationFn = Apollo.MutationFunction<RevertEsignMutation, RevertEsignMutationVariables>

/**
 * __useRevertEsignMutation__
 *
 * To run a mutation, you first call `useRevertEsignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevertEsignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revertEsignMutation, { data, loading, error }] = useRevertEsignMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useRevertEsignMutation(
  baseOptions?: Apollo.MutationHookOptions<RevertEsignMutation, RevertEsignMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RevertEsignMutation, RevertEsignMutationVariables>(RevertEsignDocument, options)
}
export type RevertEsignMutationHookResult = ReturnType<typeof useRevertEsignMutation>
export type RevertEsignMutationResult = Apollo.MutationResult<RevertEsignMutation>
export type RevertEsignMutationOptions = Apollo.BaseMutationOptions<RevertEsignMutation, RevertEsignMutationVariables>
export const SaveClinicDocument = gql`
  mutation saveClinic($id: ID!, $params: ClinicParams) {
    saveClinic(id: $id, params: $params) {
      ...clinicFragment
      integrations {
        freshworksCrm
        freshworksCrmId
      }
    }
  }
  ${ClinicFragmentFragmentDoc}
`
export type SaveClinicMutationFn = Apollo.MutationFunction<SaveClinicMutation, SaveClinicMutationVariables>

/**
 * __useSaveClinicMutation__
 *
 * To run a mutation, you first call `useSaveClinicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveClinicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveClinicMutation, { data, loading, error }] = useSaveClinicMutation({
 *   variables: {
 *      id: // value for 'id'
 *      params: // value for 'params'
 *   },
 * });
 */
export function useSaveClinicMutation(
  baseOptions?: Apollo.MutationHookOptions<SaveClinicMutation, SaveClinicMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SaveClinicMutation, SaveClinicMutationVariables>(SaveClinicDocument, options)
}
export type SaveClinicMutationHookResult = ReturnType<typeof useSaveClinicMutation>
export type SaveClinicMutationResult = Apollo.MutationResult<SaveClinicMutation>
export type SaveClinicMutationOptions = Apollo.BaseMutationOptions<SaveClinicMutation, SaveClinicMutationVariables>
export const SaveGuestEmailDocument = gql`
  mutation saveGuestEmail($email: String!) {
    saveGuestEmail(email: $email) {
      success
      errors
    }
  }
`
export type SaveGuestEmailMutationFn = Apollo.MutationFunction<SaveGuestEmailMutation, SaveGuestEmailMutationVariables>

/**
 * __useSaveGuestEmailMutation__
 *
 * To run a mutation, you first call `useSaveGuestEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveGuestEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveGuestEmailMutation, { data, loading, error }] = useSaveGuestEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSaveGuestEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<SaveGuestEmailMutation, SaveGuestEmailMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SaveGuestEmailMutation, SaveGuestEmailMutationVariables>(SaveGuestEmailDocument, options)
}
export type SaveGuestEmailMutationHookResult = ReturnType<typeof useSaveGuestEmailMutation>
export type SaveGuestEmailMutationResult = Apollo.MutationResult<SaveGuestEmailMutation>
export type SaveGuestEmailMutationOptions = Apollo.BaseMutationOptions<
  SaveGuestEmailMutation,
  SaveGuestEmailMutationVariables
>
export const SavePatientProfileDocument = gql`
  mutation savePatientProfile($userId: ID!, $params: UserParams) {
    savePatientProfile(userId: $userId, params: $params) {
      ...userFragment
    }
  }
  ${UserFragmentFragmentDoc}
`
export type SavePatientProfileMutationFn = Apollo.MutationFunction<
  SavePatientProfileMutation,
  SavePatientProfileMutationVariables
>

/**
 * __useSavePatientProfileMutation__
 *
 * To run a mutation, you first call `useSavePatientProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSavePatientProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [savePatientProfileMutation, { data, loading, error }] = useSavePatientProfileMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      params: // value for 'params'
 *   },
 * });
 */
export function useSavePatientProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<SavePatientProfileMutation, SavePatientProfileMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SavePatientProfileMutation, SavePatientProfileMutationVariables>(
    SavePatientProfileDocument,
    options
  )
}
export type SavePatientProfileMutationHookResult = ReturnType<typeof useSavePatientProfileMutation>
export type SavePatientProfileMutationResult = Apollo.MutationResult<SavePatientProfileMutation>
export type SavePatientProfileMutationOptions = Apollo.BaseMutationOptions<
  SavePatientProfileMutation,
  SavePatientProfileMutationVariables
>
export const SaveStateDocument = gql`
  mutation saveState($id: ID!, $params: StateInput) {
    saveState(id: $id, params: $params) {
      ...stateFragment
    }
  }
  ${StateFragmentFragmentDoc}
`
export type SaveStateMutationFn = Apollo.MutationFunction<SaveStateMutation, SaveStateMutationVariables>

/**
 * __useSaveStateMutation__
 *
 * To run a mutation, you first call `useSaveStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveStateMutation, { data, loading, error }] = useSaveStateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      params: // value for 'params'
 *   },
 * });
 */
export function useSaveStateMutation(
  baseOptions?: Apollo.MutationHookOptions<SaveStateMutation, SaveStateMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SaveStateMutation, SaveStateMutationVariables>(SaveStateDocument, options)
}
export type SaveStateMutationHookResult = ReturnType<typeof useSaveStateMutation>
export type SaveStateMutationResult = Apollo.MutationResult<SaveStateMutation>
export type SaveStateMutationOptions = Apollo.BaseMutationOptions<SaveStateMutation, SaveStateMutationVariables>
export const SendResetPasswordEmailDocument = gql`
  mutation sendResetPasswordEmail($email: String!) {
    sendResetPasswordEmail(email: $email)
  }
`
export type SendResetPasswordEmailMutationFn = Apollo.MutationFunction<
  SendResetPasswordEmailMutation,
  SendResetPasswordEmailMutationVariables
>

/**
 * __useSendResetPasswordEmailMutation__
 *
 * To run a mutation, you first call `useSendResetPasswordEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendResetPasswordEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendResetPasswordEmailMutation, { data, loading, error }] = useSendResetPasswordEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSendResetPasswordEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<SendResetPasswordEmailMutation, SendResetPasswordEmailMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SendResetPasswordEmailMutation, SendResetPasswordEmailMutationVariables>(
    SendResetPasswordEmailDocument,
    options
  )
}
export type SendResetPasswordEmailMutationHookResult = ReturnType<typeof useSendResetPasswordEmailMutation>
export type SendResetPasswordEmailMutationResult = Apollo.MutationResult<SendResetPasswordEmailMutation>
export type SendResetPasswordEmailMutationOptions = Apollo.BaseMutationOptions<
  SendResetPasswordEmailMutation,
  SendResetPasswordEmailMutationVariables
>
export const SendSmsDocument = gql`
  mutation sendSMS($to: String!, $body: String!) {
    sendSMS(to: $to, body: $body) {
      success
    }
  }
`
export type SendSmsMutationFn = Apollo.MutationFunction<SendSmsMutation, SendSmsMutationVariables>

/**
 * __useSendSmsMutation__
 *
 * To run a mutation, you first call `useSendSmsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendSmsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendSmsMutation, { data, loading, error }] = useSendSmsMutation({
 *   variables: {
 *      to: // value for 'to'
 *      body: // value for 'body'
 *   },
 * });
 */
export function useSendSmsMutation(
  baseOptions?: Apollo.MutationHookOptions<SendSmsMutation, SendSmsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SendSmsMutation, SendSmsMutationVariables>(SendSmsDocument, options)
}
export type SendSmsMutationHookResult = ReturnType<typeof useSendSmsMutation>
export type SendSmsMutationResult = Apollo.MutationResult<SendSmsMutation>
export type SendSmsMutationOptions = Apollo.BaseMutationOptions<SendSmsMutation, SendSmsMutationVariables>
export const SendWelcomeEmailDocument = gql`
  mutation sendWelcomeEmail($patientId: String!) {
    sendWelcomeEmail(patientId: $patientId) {
      success
      errors
    }
  }
`
export type SendWelcomeEmailMutationFn = Apollo.MutationFunction<
  SendWelcomeEmailMutation,
  SendWelcomeEmailMutationVariables
>

/**
 * __useSendWelcomeEmailMutation__
 *
 * To run a mutation, you first call `useSendWelcomeEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendWelcomeEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendWelcomeEmailMutation, { data, loading, error }] = useSendWelcomeEmailMutation({
 *   variables: {
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function useSendWelcomeEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<SendWelcomeEmailMutation, SendWelcomeEmailMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SendWelcomeEmailMutation, SendWelcomeEmailMutationVariables>(
    SendWelcomeEmailDocument,
    options
  )
}
export type SendWelcomeEmailMutationHookResult = ReturnType<typeof useSendWelcomeEmailMutation>
export type SendWelcomeEmailMutationResult = Apollo.MutationResult<SendWelcomeEmailMutation>
export type SendWelcomeEmailMutationOptions = Apollo.BaseMutationOptions<
  SendWelcomeEmailMutation,
  SendWelcomeEmailMutationVariables
>
export const SubmitHelpFormDocument = gql`
  mutation submitHelpForm($subject: String, $message: String!) {
    submitHelpForm(subject: $subject, message: $message) {
      success
    }
  }
`
export type SubmitHelpFormMutationFn = Apollo.MutationFunction<SubmitHelpFormMutation, SubmitHelpFormMutationVariables>

/**
 * __useSubmitHelpFormMutation__
 *
 * To run a mutation, you first call `useSubmitHelpFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitHelpFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitHelpFormMutation, { data, loading, error }] = useSubmitHelpFormMutation({
 *   variables: {
 *      subject: // value for 'subject'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useSubmitHelpFormMutation(
  baseOptions?: Apollo.MutationHookOptions<SubmitHelpFormMutation, SubmitHelpFormMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SubmitHelpFormMutation, SubmitHelpFormMutationVariables>(SubmitHelpFormDocument, options)
}
export type SubmitHelpFormMutationHookResult = ReturnType<typeof useSubmitHelpFormMutation>
export type SubmitHelpFormMutationResult = Apollo.MutationResult<SubmitHelpFormMutation>
export type SubmitHelpFormMutationOptions = Apollo.BaseMutationOptions<
  SubmitHelpFormMutation,
  SubmitHelpFormMutationVariables
>
export const SubmitNetScoreDocument = gql`
  mutation submitNetScore($score: Int!) {
    submitNetScore(score: $score) {
      success
    }
  }
`
export type SubmitNetScoreMutationFn = Apollo.MutationFunction<SubmitNetScoreMutation, SubmitNetScoreMutationVariables>

/**
 * __useSubmitNetScoreMutation__
 *
 * To run a mutation, you first call `useSubmitNetScoreMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitNetScoreMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitNetScoreMutation, { data, loading, error }] = useSubmitNetScoreMutation({
 *   variables: {
 *      score: // value for 'score'
 *   },
 * });
 */
export function useSubmitNetScoreMutation(
  baseOptions?: Apollo.MutationHookOptions<SubmitNetScoreMutation, SubmitNetScoreMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SubmitNetScoreMutation, SubmitNetScoreMutationVariables>(SubmitNetScoreDocument, options)
}
export type SubmitNetScoreMutationHookResult = ReturnType<typeof useSubmitNetScoreMutation>
export type SubmitNetScoreMutationResult = Apollo.MutationResult<SubmitNetScoreMutation>
export type SubmitNetScoreMutationOptions = Apollo.BaseMutationOptions<
  SubmitNetScoreMutation,
  SubmitNetScoreMutationVariables
>
export const UpdateOnePatientGuideTemplateDocument = gql`
  mutation updateOnePatientGuideTemplate($patientId: ID!, $guideTemplateId: ID!) {
    updateOnePatientGuideTemplate(patientId: $patientId, guideTemplateId: $guideTemplateId) {
      id
      guideTemplate {
        id
        name
      }
    }
  }
`
export type UpdateOnePatientGuideTemplateMutationFn = Apollo.MutationFunction<
  UpdateOnePatientGuideTemplateMutation,
  UpdateOnePatientGuideTemplateMutationVariables
>

/**
 * __useUpdateOnePatientGuideTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateOnePatientGuideTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOnePatientGuideTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOnePatientGuideTemplateMutation, { data, loading, error }] = useUpdateOnePatientGuideTemplateMutation({
 *   variables: {
 *      patientId: // value for 'patientId'
 *      guideTemplateId: // value for 'guideTemplateId'
 *   },
 * });
 */
export function useUpdateOnePatientGuideTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOnePatientGuideTemplateMutation,
    UpdateOnePatientGuideTemplateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateOnePatientGuideTemplateMutation, UpdateOnePatientGuideTemplateMutationVariables>(
    UpdateOnePatientGuideTemplateDocument,
    options
  )
}
export type UpdateOnePatientGuideTemplateMutationHookResult = ReturnType<
  typeof useUpdateOnePatientGuideTemplateMutation
>
export type UpdateOnePatientGuideTemplateMutationResult = Apollo.MutationResult<UpdateOnePatientGuideTemplateMutation>
export type UpdateOnePatientGuideTemplateMutationOptions = Apollo.BaseMutationOptions<
  UpdateOnePatientGuideTemplateMutation,
  UpdateOnePatientGuideTemplateMutationVariables
>
export const UpdatePatientDocument = gql`
  mutation updatePatient($patientId: ID!, $params: PatientInput!) {
    updatePatient(patientId: $patientId, params: $params) {
      ...patientFragment
    }
  }
  ${PatientFragmentFragmentDoc}
`
export type UpdatePatientMutationFn = Apollo.MutationFunction<UpdatePatientMutation, UpdatePatientMutationVariables>

/**
 * __useUpdatePatientMutation__
 *
 * To run a mutation, you first call `useUpdatePatientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePatientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePatientMutation, { data, loading, error }] = useUpdatePatientMutation({
 *   variables: {
 *      patientId: // value for 'patientId'
 *      params: // value for 'params'
 *   },
 * });
 */
export function useUpdatePatientMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdatePatientMutation, UpdatePatientMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdatePatientMutation, UpdatePatientMutationVariables>(UpdatePatientDocument, options)
}
export type UpdatePatientMutationHookResult = ReturnType<typeof useUpdatePatientMutation>
export type UpdatePatientMutationResult = Apollo.MutationResult<UpdatePatientMutation>
export type UpdatePatientMutationOptions = Apollo.BaseMutationOptions<
  UpdatePatientMutation,
  UpdatePatientMutationVariables
>
export const UpdatePermissionDocument = gql`
  mutation updatePermission($permissionId: ID!, $accessIds: [ID]!) {
    updatePermission(permissionId: $permissionId, accessIds: $accessIds) {
      id
    }
  }
`
export type UpdatePermissionMutationFn = Apollo.MutationFunction<
  UpdatePermissionMutation,
  UpdatePermissionMutationVariables
>

/**
 * __useUpdatePermissionMutation__
 *
 * To run a mutation, you first call `useUpdatePermissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePermissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePermissionMutation, { data, loading, error }] = useUpdatePermissionMutation({
 *   variables: {
 *      permissionId: // value for 'permissionId'
 *      accessIds: // value for 'accessIds'
 *   },
 * });
 */
export function useUpdatePermissionMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdatePermissionMutation, UpdatePermissionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdatePermissionMutation, UpdatePermissionMutationVariables>(
    UpdatePermissionDocument,
    options
  )
}
export type UpdatePermissionMutationHookResult = ReturnType<typeof useUpdatePermissionMutation>
export type UpdatePermissionMutationResult = Apollo.MutationResult<UpdatePermissionMutation>
export type UpdatePermissionMutationOptions = Apollo.BaseMutationOptions<
  UpdatePermissionMutation,
  UpdatePermissionMutationVariables
>
export const UpdateUserClinicPermissionsDocument = gql`
  mutation updateUserClinicPermissions($userId: ID!, $clinicIds: [ID]!) {
    updateUserClinicPermissions(userId: $userId, clinicIds: $clinicIds) {
      ...userFragment
      clinicPermissions {
        id
        title
      }
    }
  }
  ${UserFragmentFragmentDoc}
`
export type UpdateUserClinicPermissionsMutationFn = Apollo.MutationFunction<
  UpdateUserClinicPermissionsMutation,
  UpdateUserClinicPermissionsMutationVariables
>

/**
 * __useUpdateUserClinicPermissionsMutation__
 *
 * To run a mutation, you first call `useUpdateUserClinicPermissionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserClinicPermissionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserClinicPermissionsMutation, { data, loading, error }] = useUpdateUserClinicPermissionsMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      clinicIds: // value for 'clinicIds'
 *   },
 * });
 */
export function useUpdateUserClinicPermissionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserClinicPermissionsMutation,
    UpdateUserClinicPermissionsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateUserClinicPermissionsMutation, UpdateUserClinicPermissionsMutationVariables>(
    UpdateUserClinicPermissionsDocument,
    options
  )
}
export type UpdateUserClinicPermissionsMutationHookResult = ReturnType<typeof useUpdateUserClinicPermissionsMutation>
export type UpdateUserClinicPermissionsMutationResult = Apollo.MutationResult<UpdateUserClinicPermissionsMutation>
export type UpdateUserClinicPermissionsMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserClinicPermissionsMutation,
  UpdateUserClinicPermissionsMutationVariables
>
export const UpdateUserPermissionDocument = gql`
  mutation updateUserPermission($userId: ID!, $permissionId: ID!) {
    updateUserPermission(userId: $userId, permissionId: $permissionId) {
      ...userFragment
    }
  }
  ${UserFragmentFragmentDoc}
`
export type UpdateUserPermissionMutationFn = Apollo.MutationFunction<
  UpdateUserPermissionMutation,
  UpdateUserPermissionMutationVariables
>

/**
 * __useUpdateUserPermissionMutation__
 *
 * To run a mutation, you first call `useUpdateUserPermissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserPermissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserPermissionMutation, { data, loading, error }] = useUpdateUserPermissionMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      permissionId: // value for 'permissionId'
 *   },
 * });
 */
export function useUpdateUserPermissionMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateUserPermissionMutation, UpdateUserPermissionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateUserPermissionMutation, UpdateUserPermissionMutationVariables>(
    UpdateUserPermissionDocument,
    options
  )
}
export type UpdateUserPermissionMutationHookResult = ReturnType<typeof useUpdateUserPermissionMutation>
export type UpdateUserPermissionMutationResult = Apollo.MutationResult<UpdateUserPermissionMutation>
export type UpdateUserPermissionMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserPermissionMutation,
  UpdateUserPermissionMutationVariables
>
export const UploadPlanAttachmentDocument = gql`
  mutation uploadPlanAttachment($name: String, $mimetype: String!, $s3Key: String, $patientId: ID) {
    uploadPlanAttachment(name: $name, mimetype: $mimetype, s3Key: $s3Key, patientId: $patientId) {
      success
      errors
    }
  }
`
export type UploadPlanAttachmentMutationFn = Apollo.MutationFunction<
  UploadPlanAttachmentMutation,
  UploadPlanAttachmentMutationVariables
>

/**
 * __useUploadPlanAttachmentMutation__
 *
 * To run a mutation, you first call `useUploadPlanAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadPlanAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadPlanAttachmentMutation, { data, loading, error }] = useUploadPlanAttachmentMutation({
 *   variables: {
 *      name: // value for 'name'
 *      mimetype: // value for 'mimetype'
 *      s3Key: // value for 's3Key'
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function useUploadPlanAttachmentMutation(
  baseOptions?: Apollo.MutationHookOptions<UploadPlanAttachmentMutation, UploadPlanAttachmentMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UploadPlanAttachmentMutation, UploadPlanAttachmentMutationVariables>(
    UploadPlanAttachmentDocument,
    options
  )
}
export type UploadPlanAttachmentMutationHookResult = ReturnType<typeof useUploadPlanAttachmentMutation>
export type UploadPlanAttachmentMutationResult = Apollo.MutationResult<UploadPlanAttachmentMutation>
export type UploadPlanAttachmentMutationOptions = Apollo.BaseMutationOptions<
  UploadPlanAttachmentMutation,
  UploadPlanAttachmentMutationVariables
>
export const VerifyMfaDocument = gql`
  mutation verifyMfa($pin: String!, $email: String!) {
    verifyMfa(pin: $pin, email: $email) {
      mfaRequired
      sessionId
      tokens {
        refreshToken
        accessToken
      }
    }
  }
`
export type VerifyMfaMutationFn = Apollo.MutationFunction<VerifyMfaMutation, VerifyMfaMutationVariables>

/**
 * __useVerifyMfaMutation__
 *
 * To run a mutation, you first call `useVerifyMfaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyMfaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyMfaMutation, { data, loading, error }] = useVerifyMfaMutation({
 *   variables: {
 *      pin: // value for 'pin'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useVerifyMfaMutation(
  baseOptions?: Apollo.MutationHookOptions<VerifyMfaMutation, VerifyMfaMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<VerifyMfaMutation, VerifyMfaMutationVariables>(VerifyMfaDocument, options)
}
export type VerifyMfaMutationHookResult = ReturnType<typeof useVerifyMfaMutation>
export type VerifyMfaMutationResult = Apollo.MutationResult<VerifyMfaMutation>
export type VerifyMfaMutationOptions = Apollo.BaseMutationOptions<VerifyMfaMutation, VerifyMfaMutationVariables>
export const AccessesDocument = gql`
  query accesses {
    accesses {
      id
      title
      active
    }
  }
`

/**
 * __useAccessesQuery__
 *
 * To run a query within a React component, call `useAccessesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccessesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccessesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccessesQuery(baseOptions?: Apollo.QueryHookOptions<AccessesQuery, AccessesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AccessesQuery, AccessesQueryVariables>(AccessesDocument, options)
}
export function useAccessesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccessesQuery, AccessesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<AccessesQuery, AccessesQueryVariables>(AccessesDocument, options)
}
export type AccessesQueryHookResult = ReturnType<typeof useAccessesQuery>
export type AccessesLazyQueryHookResult = ReturnType<typeof useAccessesLazyQuery>
export type AccessesQueryResult = Apollo.QueryResult<AccessesQuery, AccessesQueryVariables>
export const ActivityLogsByPatientIdDocument = gql`
  query activityLogsByPatientId($patientId: ID!) {
    activityLogsByPatientId(patientId: $patientId) {
      id
      importance
      userId
      patientId
      createdAt
      clinicId
      content
    }
  }
`

/**
 * __useActivityLogsByPatientIdQuery__
 *
 * To run a query within a React component, call `useActivityLogsByPatientIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useActivityLogsByPatientIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivityLogsByPatientIdQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function useActivityLogsByPatientIdQuery(
  baseOptions: Apollo.QueryHookOptions<ActivityLogsByPatientIdQuery, ActivityLogsByPatientIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ActivityLogsByPatientIdQuery, ActivityLogsByPatientIdQueryVariables>(
    ActivityLogsByPatientIdDocument,
    options
  )
}
export function useActivityLogsByPatientIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ActivityLogsByPatientIdQuery, ActivityLogsByPatientIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ActivityLogsByPatientIdQuery, ActivityLogsByPatientIdQueryVariables>(
    ActivityLogsByPatientIdDocument,
    options
  )
}
export type ActivityLogsByPatientIdQueryHookResult = ReturnType<typeof useActivityLogsByPatientIdQuery>
export type ActivityLogsByPatientIdLazyQueryHookResult = ReturnType<typeof useActivityLogsByPatientIdLazyQuery>
export type ActivityLogsByPatientIdQueryResult = Apollo.QueryResult<
  ActivityLogsByPatientIdQuery,
  ActivityLogsByPatientIdQueryVariables
>
export const AllNetScoresDocument = gql`
  query allNetScores {
    allNetScores {
      id
      score
      userId
      userName
      email
      createdAt
      updatedAt
      clinic {
        id
        title
      }
    }
  }
`

/**
 * __useAllNetScoresQuery__
 *
 * To run a query within a React component, call `useAllNetScoresQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllNetScoresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllNetScoresQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllNetScoresQuery(
  baseOptions?: Apollo.QueryHookOptions<AllNetScoresQuery, AllNetScoresQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AllNetScoresQuery, AllNetScoresQueryVariables>(AllNetScoresDocument, options)
}
export function useAllNetScoresLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AllNetScoresQuery, AllNetScoresQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<AllNetScoresQuery, AllNetScoresQueryVariables>(AllNetScoresDocument, options)
}
export type AllNetScoresQueryHookResult = ReturnType<typeof useAllNetScoresQuery>
export type AllNetScoresLazyQueryHookResult = ReturnType<typeof useAllNetScoresLazyQuery>
export type AllNetScoresQueryResult = Apollo.QueryResult<AllNetScoresQuery, AllNetScoresQueryVariables>
export const ClinicByIdDocument = gql`
  query clinicById($id: ID!) {
    getOneClinic(id: $id) {
      ...clinicFragment
      contact {
        firstName
        lastName
        phone
        email
      }
      integrations {
        freshworksCrm
        freshworksCrmId
        freshworksSalesAccountId
      }
    }
  }
  ${ClinicFragmentFragmentDoc}
`

/**
 * __useClinicByIdQuery__
 *
 * To run a query within a React component, call `useClinicByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useClinicByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClinicByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useClinicByIdQuery(baseOptions: Apollo.QueryHookOptions<ClinicByIdQuery, ClinicByIdQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ClinicByIdQuery, ClinicByIdQueryVariables>(ClinicByIdDocument, options)
}
export function useClinicByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ClinicByIdQuery, ClinicByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ClinicByIdQuery, ClinicByIdQueryVariables>(ClinicByIdDocument, options)
}
export type ClinicByIdQueryHookResult = ReturnType<typeof useClinicByIdQuery>
export type ClinicByIdLazyQueryHookResult = ReturnType<typeof useClinicByIdLazyQuery>
export type ClinicByIdQueryResult = Apollo.QueryResult<ClinicByIdQuery, ClinicByIdQueryVariables>
export const ClinicCsvDocument = gql`
  query clinicCSV($clinicId: ID!) {
    clinicCSV(clinicId: $clinicId) {
      id
      userId
      firstName
      lastName
      email
      startTime
      endTime
      createdAt
      birthday
      cprPreferenceToday
      ventilationPreferenceToday
      longTermFeedingToday
      dialysisTreatmentToday
      updatedAt
      notaryInfo
      eSignStarted
      decisionMakerPhone
      decisionMakerEmail
      importantToMe
      livingSituation
      meaningOfLife
      notWorthLiving
      notWorthLiving2
      notWorthLiving3
      whenCanDecisionMakerDecide
      lastLocation
      cprPreference
      ventilationPreference
      longTermFeeding
      dialysisTreatment
      doYouKnowDecisionMaker
      decisionMakerName
      decisionMakerFirstName
      decisionMakerLastName
      decisionMakerRelationship
      decisionMakerStrictness
      wantsAltDecisionMaker
      altDecisionMakerFirstName
      altDecisionMakerLastName
      personaVacation
      personaSocialTies
      personaHealthcareExperience
      lastLogin
      firstLogin
      sharedWithDecisionMaker
    }
  }
`

/**
 * __useClinicCsvQuery__
 *
 * To run a query within a React component, call `useClinicCsvQuery` and pass it any options that fit your needs.
 * When your component renders, `useClinicCsvQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClinicCsvQuery({
 *   variables: {
 *      clinicId: // value for 'clinicId'
 *   },
 * });
 */
export function useClinicCsvQuery(baseOptions: Apollo.QueryHookOptions<ClinicCsvQuery, ClinicCsvQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ClinicCsvQuery, ClinicCsvQueryVariables>(ClinicCsvDocument, options)
}
export function useClinicCsvLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ClinicCsvQuery, ClinicCsvQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ClinicCsvQuery, ClinicCsvQueryVariables>(ClinicCsvDocument, options)
}
export type ClinicCsvQueryHookResult = ReturnType<typeof useClinicCsvQuery>
export type ClinicCsvLazyQueryHookResult = ReturnType<typeof useClinicCsvLazyQuery>
export type ClinicCsvQueryResult = Apollo.QueryResult<ClinicCsvQuery, ClinicCsvQueryVariables>
export const ClinicsDocument = gql`
  query clinics($searchText: String, $skip: Int, $limit: Int) {
    clinics(searchText: $searchText, skip: $skip, limit: $limit) {
      count
      clinics {
        ...clinicFragment
      }
    }
  }
  ${ClinicFragmentFragmentDoc}
`

/**
 * __useClinicsQuery__
 *
 * To run a query within a React component, call `useClinicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useClinicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClinicsQuery({
 *   variables: {
 *      searchText: // value for 'searchText'
 *      skip: // value for 'skip'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useClinicsQuery(baseOptions?: Apollo.QueryHookOptions<ClinicsQuery, ClinicsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ClinicsQuery, ClinicsQueryVariables>(ClinicsDocument, options)
}
export function useClinicsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClinicsQuery, ClinicsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ClinicsQuery, ClinicsQueryVariables>(ClinicsDocument, options)
}
export type ClinicsQueryHookResult = ReturnType<typeof useClinicsQuery>
export type ClinicsLazyQueryHookResult = ReturnType<typeof useClinicsLazyQuery>
export type ClinicsQueryResult = Apollo.QueryResult<ClinicsQuery, ClinicsQueryVariables>
export const CurrentUserDocument = gql`
  query currentUser {
    currentUser {
      ...currentUserFragment
    }
  }
  ${CurrentUserFragmentFragmentDoc}
`

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(
  baseOptions?: Apollo.QueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options)
}
export function useCurrentUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options)
}
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>
export type CurrentUserLazyQueryHookResult = ReturnType<typeof useCurrentUserLazyQuery>
export type CurrentUserQueryResult = Apollo.QueryResult<CurrentUserQuery, CurrentUserQueryVariables>
export const FindResetDocument = gql`
  query findReset($token: String!) {
    findReset(token: $token) {
      email
      name
      agreeToTerms
    }
  }
`

/**
 * __useFindResetQuery__
 *
 * To run a query within a React component, call `useFindResetQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindResetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindResetQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useFindResetQuery(baseOptions: Apollo.QueryHookOptions<FindResetQuery, FindResetQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FindResetQuery, FindResetQueryVariables>(FindResetDocument, options)
}
export function useFindResetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FindResetQuery, FindResetQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FindResetQuery, FindResetQueryVariables>(FindResetDocument, options)
}
export type FindResetQueryHookResult = ReturnType<typeof useFindResetQuery>
export type FindResetLazyQueryHookResult = ReturnType<typeof useFindResetLazyQuery>
export type FindResetQueryResult = Apollo.QueryResult<FindResetQuery, FindResetQueryVariables>
export const GetHipaaLogsDocument = gql`
  query getHipaaLogs($skip: Int, $limit: Int, $startTime: Float, $endTime: Float, $patientId: ID) {
    getHipaaLogs(skip: $skip, limit: $limit, startTime: $startTime, endTime: $endTime, patientId: $patientId) {
      count
      logs {
        id
        eventType
        note
        createdAt
        patientName
        userFullname
        collectionName
      }
    }
  }
`

/**
 * __useGetHipaaLogsQuery__
 *
 * To run a query within a React component, call `useGetHipaaLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHipaaLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHipaaLogsQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      limit: // value for 'limit'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function useGetHipaaLogsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetHipaaLogsQuery, GetHipaaLogsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetHipaaLogsQuery, GetHipaaLogsQueryVariables>(GetHipaaLogsDocument, options)
}
export function useGetHipaaLogsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetHipaaLogsQuery, GetHipaaLogsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetHipaaLogsQuery, GetHipaaLogsQueryVariables>(GetHipaaLogsDocument, options)
}
export type GetHipaaLogsQueryHookResult = ReturnType<typeof useGetHipaaLogsQuery>
export type GetHipaaLogsLazyQueryHookResult = ReturnType<typeof useGetHipaaLogsLazyQuery>
export type GetHipaaLogsQueryResult = Apollo.QueryResult<GetHipaaLogsQuery, GetHipaaLogsQueryVariables>
export const GetManyGuideTemplatesDocument = gql`
  query getManyGuideTemplates {
    getManyGuideTemplates {
      ...guideTemplateFragment
    }
  }
  ${GuideTemplateFragmentFragmentDoc}
`

/**
 * __useGetManyGuideTemplatesQuery__
 *
 * To run a query within a React component, call `useGetManyGuideTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyGuideTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManyGuideTemplatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetManyGuideTemplatesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetManyGuideTemplatesQuery, GetManyGuideTemplatesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetManyGuideTemplatesQuery, GetManyGuideTemplatesQueryVariables>(
    GetManyGuideTemplatesDocument,
    options
  )
}
export function useGetManyGuideTemplatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetManyGuideTemplatesQuery, GetManyGuideTemplatesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetManyGuideTemplatesQuery, GetManyGuideTemplatesQueryVariables>(
    GetManyGuideTemplatesDocument,
    options
  )
}
export type GetManyGuideTemplatesQueryHookResult = ReturnType<typeof useGetManyGuideTemplatesQuery>
export type GetManyGuideTemplatesLazyQueryHookResult = ReturnType<typeof useGetManyGuideTemplatesLazyQuery>
export type GetManyGuideTemplatesQueryResult = Apollo.QueryResult<
  GetManyGuideTemplatesQuery,
  GetManyGuideTemplatesQueryVariables
>
export const GetManyPatientsDocument = gql`
  query getManyPatients($clinicId: ID!, $skip: Int, $limit: Int, $searchText: String, $sortBy: PlansSortByInput) {
    getManyPatients(clinicId: $clinicId, skip: $skip, limit: $limit, searchText: $searchText, sortBy: $sortBy) {
      count
      plans {
        ...patientFragment
        firstName
        email
        lastName
        birthday
        startTime
        endTime
        createdAt
        updatedAt
        userId
        lastLogin
        firstLogin
        notaryInfo
        faxes {
          faxToNumber
          dateSent
          sentByUserId
        }
        eSignStarted
      }
    }
  }
  ${PatientFragmentFragmentDoc}
`

/**
 * __useGetManyPatientsQuery__
 *
 * To run a query within a React component, call `useGetManyPatientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyPatientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManyPatientsQuery({
 *   variables: {
 *      clinicId: // value for 'clinicId'
 *      skip: // value for 'skip'
 *      limit: // value for 'limit'
 *      searchText: // value for 'searchText'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useGetManyPatientsQuery(
  baseOptions: Apollo.QueryHookOptions<GetManyPatientsQuery, GetManyPatientsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetManyPatientsQuery, GetManyPatientsQueryVariables>(GetManyPatientsDocument, options)
}
export function useGetManyPatientsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetManyPatientsQuery, GetManyPatientsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetManyPatientsQuery, GetManyPatientsQueryVariables>(GetManyPatientsDocument, options)
}
export type GetManyPatientsQueryHookResult = ReturnType<typeof useGetManyPatientsQuery>
export type GetManyPatientsLazyQueryHookResult = ReturnType<typeof useGetManyPatientsLazyQuery>
export type GetManyPatientsQueryResult = Apollo.QueryResult<GetManyPatientsQuery, GetManyPatientsQueryVariables>
export const GetOneClinicDocument = gql`
  query getOneClinic($id: ID, $slug: String) {
    getOneClinic(id: $id, slug: $slug) {
      ...clinicFragment
    }
  }
  ${ClinicFragmentFragmentDoc}
`

/**
 * __useGetOneClinicQuery__
 *
 * To run a query within a React component, call `useGetOneClinicQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOneClinicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOneClinicQuery({
 *   variables: {
 *      id: // value for 'id'
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetOneClinicQuery(
  baseOptions?: Apollo.QueryHookOptions<GetOneClinicQuery, GetOneClinicQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetOneClinicQuery, GetOneClinicQueryVariables>(GetOneClinicDocument, options)
}
export function useGetOneClinicLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetOneClinicQuery, GetOneClinicQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetOneClinicQuery, GetOneClinicQueryVariables>(GetOneClinicDocument, options)
}
export type GetOneClinicQueryHookResult = ReturnType<typeof useGetOneClinicQuery>
export type GetOneClinicLazyQueryHookResult = ReturnType<typeof useGetOneClinicLazyQuery>
export type GetOneClinicQueryResult = Apollo.QueryResult<GetOneClinicQuery, GetOneClinicQueryVariables>
export const GetOnePatientDocument = gql`
  query getOnePatient($patientId: ID, $token: String, $userId: ID) {
    getOnePatient(patientId: $patientId, token: $token, userId: $userId) {
      ...patientFragment
    }
  }
  ${PatientFragmentFragmentDoc}
`

/**
 * __useGetOnePatientQuery__
 *
 * To run a query within a React component, call `useGetOnePatientQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOnePatientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOnePatientQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *      token: // value for 'token'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetOnePatientQuery(
  baseOptions?: Apollo.QueryHookOptions<GetOnePatientQuery, GetOnePatientQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetOnePatientQuery, GetOnePatientQueryVariables>(GetOnePatientDocument, options)
}
export function useGetOnePatientLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetOnePatientQuery, GetOnePatientQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetOnePatientQuery, GetOnePatientQueryVariables>(GetOnePatientDocument, options)
}
export type GetOnePatientQueryHookResult = ReturnType<typeof useGetOnePatientQuery>
export type GetOnePatientLazyQueryHookResult = ReturnType<typeof useGetOnePatientLazyQuery>
export type GetOnePatientQueryResult = Apollo.QueryResult<GetOnePatientQuery, GetOnePatientQueryVariables>
export const NpsDataDocument = gql`
  query npsData($clinicIds: [ID], $startTime: String, $endTime: String) {
    npsData(clinicIds: $clinicIds, startTime: $startTime, endTime: $endTime) {
      count
      scores {
        id
        score
        userId
        email
        createdAt
        clinic {
          id
          title
        }
      }
    }
  }
`

/**
 * __useNpsDataQuery__
 *
 * To run a query within a React component, call `useNpsDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useNpsDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNpsDataQuery({
 *   variables: {
 *      clinicIds: // value for 'clinicIds'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *   },
 * });
 */
export function useNpsDataQuery(baseOptions?: Apollo.QueryHookOptions<NpsDataQuery, NpsDataQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<NpsDataQuery, NpsDataQueryVariables>(NpsDataDocument, options)
}
export function useNpsDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NpsDataQuery, NpsDataQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<NpsDataQuery, NpsDataQueryVariables>(NpsDataDocument, options)
}
export type NpsDataQueryHookResult = ReturnType<typeof useNpsDataQuery>
export type NpsDataLazyQueryHookResult = ReturnType<typeof useNpsDataLazyQuery>
export type NpsDataQueryResult = Apollo.QueryResult<NpsDataQuery, NpsDataQueryVariables>
export const PermissionsDocument = gql`
  query permissions {
    permissions {
      id
      title
      description
      accesses {
        id
        title
      }
    }
  }
`

/**
 * __usePermissionsQuery__
 *
 * To run a query within a React component, call `usePermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePermissionsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePermissionsQuery(
  baseOptions?: Apollo.QueryHookOptions<PermissionsQuery, PermissionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<PermissionsQuery, PermissionsQueryVariables>(PermissionsDocument, options)
}
export function usePermissionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PermissionsQuery, PermissionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<PermissionsQuery, PermissionsQueryVariables>(PermissionsDocument, options)
}
export type PermissionsQueryHookResult = ReturnType<typeof usePermissionsQuery>
export type PermissionsLazyQueryHookResult = ReturnType<typeof usePermissionsLazyQuery>
export type PermissionsQueryResult = Apollo.QueryResult<PermissionsQuery, PermissionsQueryVariables>
export const PlanAttachmentByIdDocument = gql`
  query planAttachmentById($fileId: ID!, $downloadToken: String!) {
    planAttachmentById(fileId: $fileId, downloadToken: $downloadToken) {
      id
      base64String
      name
      updatedAt
      mimetype
      patientFirstName
      patientLastName
      planAttachmentType
      url
    }
  }
`

/**
 * __usePlanAttachmentByIdQuery__
 *
 * To run a query within a React component, call `usePlanAttachmentByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlanAttachmentByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlanAttachmentByIdQuery({
 *   variables: {
 *      fileId: // value for 'fileId'
 *      downloadToken: // value for 'downloadToken'
 *   },
 * });
 */
export function usePlanAttachmentByIdQuery(
  baseOptions: Apollo.QueryHookOptions<PlanAttachmentByIdQuery, PlanAttachmentByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<PlanAttachmentByIdQuery, PlanAttachmentByIdQueryVariables>(PlanAttachmentByIdDocument, options)
}
export function usePlanAttachmentByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PlanAttachmentByIdQuery, PlanAttachmentByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<PlanAttachmentByIdQuery, PlanAttachmentByIdQueryVariables>(
    PlanAttachmentByIdDocument,
    options
  )
}
export type PlanAttachmentByIdQueryHookResult = ReturnType<typeof usePlanAttachmentByIdQuery>
export type PlanAttachmentByIdLazyQueryHookResult = ReturnType<typeof usePlanAttachmentByIdLazyQuery>
export type PlanAttachmentByIdQueryResult = Apollo.QueryResult<
  PlanAttachmentByIdQuery,
  PlanAttachmentByIdQueryVariables
>
export const PlanAttachmentsDocument = gql`
  query planAttachments($patientId: ID) {
    planAttachments(patientId: $patientId) {
      id
      base64String
      name
      updatedAt
      mimetype
      planAttachmentType
      url
    }
  }
`

/**
 * __usePlanAttachmentsQuery__
 *
 * To run a query within a React component, call `usePlanAttachmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlanAttachmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlanAttachmentsQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function usePlanAttachmentsQuery(
  baseOptions?: Apollo.QueryHookOptions<PlanAttachmentsQuery, PlanAttachmentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<PlanAttachmentsQuery, PlanAttachmentsQueryVariables>(PlanAttachmentsDocument, options)
}
export function usePlanAttachmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PlanAttachmentsQuery, PlanAttachmentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<PlanAttachmentsQuery, PlanAttachmentsQueryVariables>(PlanAttachmentsDocument, options)
}
export type PlanAttachmentsQueryHookResult = ReturnType<typeof usePlanAttachmentsQuery>
export type PlanAttachmentsLazyQueryHookResult = ReturnType<typeof usePlanAttachmentsLazyQuery>
export type PlanAttachmentsQueryResult = Apollo.QueryResult<PlanAttachmentsQuery, PlanAttachmentsQueryVariables>
export const RecentlyActivePatientsDocument = gql`
  query recentlyActivePatients($clinicId: ID!) {
    recentlyActivePatients(clinicId: $clinicId) {
      id
      firstName
      email
      lastName
      lastLogin
    }
  }
`

/**
 * __useRecentlyActivePatientsQuery__
 *
 * To run a query within a React component, call `useRecentlyActivePatientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecentlyActivePatientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecentlyActivePatientsQuery({
 *   variables: {
 *      clinicId: // value for 'clinicId'
 *   },
 * });
 */
export function useRecentlyActivePatientsQuery(
  baseOptions: Apollo.QueryHookOptions<RecentlyActivePatientsQuery, RecentlyActivePatientsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<RecentlyActivePatientsQuery, RecentlyActivePatientsQueryVariables>(
    RecentlyActivePatientsDocument,
    options
  )
}
export function useRecentlyActivePatientsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<RecentlyActivePatientsQuery, RecentlyActivePatientsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<RecentlyActivePatientsQuery, RecentlyActivePatientsQueryVariables>(
    RecentlyActivePatientsDocument,
    options
  )
}
export type RecentlyActivePatientsQueryHookResult = ReturnType<typeof useRecentlyActivePatientsQuery>
export type RecentlyActivePatientsLazyQueryHookResult = ReturnType<typeof useRecentlyActivePatientsLazyQuery>
export type RecentlyActivePatientsQueryResult = Apollo.QueryResult<
  RecentlyActivePatientsQuery,
  RecentlyActivePatientsQueryVariables
>
export const Reports_AccountsPerWeekDocument = gql`
  query reports_accountsPerWeek($startDate: Float, $endDate: Float, $clinicIds: [ID]) {
    reports_accountsPerWeek(startDate: $startDate, endDate: $endDate, clinicIds: $clinicIds) {
      id
      _id {
        week
        year
      }
      values {
        clinicName
        total
      }
    }
  }
`

/**
 * __useReports_AccountsPerWeekQuery__
 *
 * To run a query within a React component, call `useReports_AccountsPerWeekQuery` and pass it any options that fit your needs.
 * When your component renders, `useReports_AccountsPerWeekQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReports_AccountsPerWeekQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      clinicIds: // value for 'clinicIds'
 *   },
 * });
 */
export function useReports_AccountsPerWeekQuery(
  baseOptions?: Apollo.QueryHookOptions<Reports_AccountsPerWeekQuery, Reports_AccountsPerWeekQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Reports_AccountsPerWeekQuery, Reports_AccountsPerWeekQueryVariables>(
    Reports_AccountsPerWeekDocument,
    options
  )
}
export function useReports_AccountsPerWeekLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Reports_AccountsPerWeekQuery, Reports_AccountsPerWeekQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Reports_AccountsPerWeekQuery, Reports_AccountsPerWeekQueryVariables>(
    Reports_AccountsPerWeekDocument,
    options
  )
}
export type Reports_AccountsPerWeekQueryHookResult = ReturnType<typeof useReports_AccountsPerWeekQuery>
export type Reports_AccountsPerWeekLazyQueryHookResult = ReturnType<typeof useReports_AccountsPerWeekLazyQuery>
export type Reports_AccountsPerWeekQueryResult = Apollo.QueryResult<
  Reports_AccountsPerWeekQuery,
  Reports_AccountsPerWeekQueryVariables
>
export const Reports_ClinicStatsDocument = gql`
  query reports_clinicStats($startDate: Float, $endDate: Float, $clinicIds: [ID]) {
    reports_clinicStats(startDate: $startDate, endDate: $endDate, clinicIds: $clinicIds) {
      accountsCreated
      mdmSelected {
        percent
        absolute
      }
      mdmShared {
        percent
        absolute
      }
      avgTimeSpent
      nps
    }
  }
`

/**
 * __useReports_ClinicStatsQuery__
 *
 * To run a query within a React component, call `useReports_ClinicStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReports_ClinicStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReports_ClinicStatsQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      clinicIds: // value for 'clinicIds'
 *   },
 * });
 */
export function useReports_ClinicStatsQuery(
  baseOptions?: Apollo.QueryHookOptions<Reports_ClinicStatsQuery, Reports_ClinicStatsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Reports_ClinicStatsQuery, Reports_ClinicStatsQueryVariables>(
    Reports_ClinicStatsDocument,
    options
  )
}
export function useReports_ClinicStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Reports_ClinicStatsQuery, Reports_ClinicStatsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Reports_ClinicStatsQuery, Reports_ClinicStatsQueryVariables>(
    Reports_ClinicStatsDocument,
    options
  )
}
export type Reports_ClinicStatsQueryHookResult = ReturnType<typeof useReports_ClinicStatsQuery>
export type Reports_ClinicStatsLazyQueryHookResult = ReturnType<typeof useReports_ClinicStatsLazyQuery>
export type Reports_ClinicStatsQueryResult = Apollo.QueryResult<
  Reports_ClinicStatsQuery,
  Reports_ClinicStatsQueryVariables
>
export const SearchPatientsDocument = gql`
  query searchPatients($searchText: String!, $clinicId: ID) {
    searchPatients(searchText: $searchText, clinicId: $clinicId) {
      id
      email
      firstName
      lastName
      birthday
      clinicId
    }
  }
`

/**
 * __useSearchPatientsQuery__
 *
 * To run a query within a React component, call `useSearchPatientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchPatientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchPatientsQuery({
 *   variables: {
 *      searchText: // value for 'searchText'
 *      clinicId: // value for 'clinicId'
 *   },
 * });
 */
export function useSearchPatientsQuery(
  baseOptions: Apollo.QueryHookOptions<SearchPatientsQuery, SearchPatientsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SearchPatientsQuery, SearchPatientsQueryVariables>(SearchPatientsDocument, options)
}
export function useSearchPatientsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SearchPatientsQuery, SearchPatientsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SearchPatientsQuery, SearchPatientsQueryVariables>(SearchPatientsDocument, options)
}
export type SearchPatientsQueryHookResult = ReturnType<typeof useSearchPatientsQuery>
export type SearchPatientsLazyQueryHookResult = ReturnType<typeof useSearchPatientsLazyQuery>
export type SearchPatientsQueryResult = Apollo.QueryResult<SearchPatientsQuery, SearchPatientsQueryVariables>
export const StatesDocument = gql`
  query states {
    states {
      ...stateFragment
    }
  }
  ${StateFragmentFragmentDoc}
`

/**
 * __useStatesQuery__
 *
 * To run a query within a React component, call `useStatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useStatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useStatesQuery(baseOptions?: Apollo.QueryHookOptions<StatesQuery, StatesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<StatesQuery, StatesQueryVariables>(StatesDocument, options)
}
export function useStatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StatesQuery, StatesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<StatesQuery, StatesQueryVariables>(StatesDocument, options)
}
export type StatesQueryHookResult = ReturnType<typeof useStatesQuery>
export type StatesLazyQueryHookResult = ReturnType<typeof useStatesLazyQuery>
export type StatesQueryResult = Apollo.QueryResult<StatesQuery, StatesQueryVariables>
export const UsersDocument = gql`
  query users($searchText: String, $roles: [String], $clinicId: String) {
    users(searchText: $searchText, roles: $roles, clinicId: $clinicId) {
      count
      users {
        ...userFragment
        clinicPermissions {
          id
          title
        }
      }
    }
  }
  ${UserFragmentFragmentDoc}
`

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      searchText: // value for 'searchText'
 *      roles: // value for 'roles'
 *      clinicId: // value for 'clinicId'
 *   },
 * });
 */
export function useUsersQuery(baseOptions?: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options)
}
export function useUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options)
}
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>
export type UsersQueryResult = Apollo.QueryResult<UsersQuery, UsersQueryVariables>
export const RefreshTokensDocument = gql`
  mutation refreshTokens($accessToken: String!, $refreshToken: String!) {
    refreshTokens(accessToken: $accessToken, refreshToken: $refreshToken) {
      sessionId
      tokens {
        refreshToken
        accessToken
      }
    }
  }
`
export type RefreshTokensMutationFn = Apollo.MutationFunction<RefreshTokensMutation, RefreshTokensMutationVariables>

/**
 * __useRefreshTokensMutation__
 *
 * To run a mutation, you first call `useRefreshTokensMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshTokensMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshTokensMutation, { data, loading, error }] = useRefreshTokensMutation({
 *   variables: {
 *      accessToken: // value for 'accessToken'
 *      refreshToken: // value for 'refreshToken'
 *   },
 * });
 */
export function useRefreshTokensMutation(
  baseOptions?: Apollo.MutationHookOptions<RefreshTokensMutation, RefreshTokensMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RefreshTokensMutation, RefreshTokensMutationVariables>(RefreshTokensDocument, options)
}
export type RefreshTokensMutationHookResult = ReturnType<typeof useRefreshTokensMutation>
export type RefreshTokensMutationResult = Apollo.MutationResult<RefreshTokensMutation>
export type RefreshTokensMutationOptions = Apollo.BaseMutationOptions<
  RefreshTokensMutation,
  RefreshTokensMutationVariables
>
