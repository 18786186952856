import { useWindowWidth } from '@react-hook/window-size'
import { isNil } from 'lodash'
import { Trans } from 'react-i18next'
import styled from 'styled-components'
import en from 'lib/helpers/locales/en/translation.json'
import i18n from 'lib/helpers/i18n'

import ButtonsContainer from '../ButtonsContainer'
import { StepContainer } from '../index'
import Animated from 'components/common/Animated'
import QuestionText from 'components/text/QuestionText'
import checkSVG from './check.svg'
import { GuideQuestionIdEnum, Patient } from 'generated/graphql'

export const getQualityOfLifeText = (type: string, isMobile?: boolean) => {
  if (type === 'left') {
    return <Trans>{i18n.t('meaningOfLife.livingThroughAnything')}</Trans>
  }

  if (type === 'middle') {
    return (
      <>
        <Trans>{i18n.t('meaningOfLife.balanceOfTheTwo', 'Balance of <br /> the two')}</Trans>
      </>
    )
  }

  if (type === 'right') {
    return (
      <>
        <Trans>{i18n.t('meaningOfLife.beingComfortable', en.meaningOfLife.beingComfortable)}</Trans>
      </>
    )
  }
}
// "meaningOfLife": {
//   "text": "Quality of Life is different for each person. What does quality of life mean to you?",
//   "livingThroughAnything": "Living through anything <br /> no matter what",
//   "balanceOfTheTwo": "Balance of <br /> the Two",
//   "beingComfortable": "Being comfortable, <br /> even if it means <br /> not living as long"
// },
const Option = styled.div<{ active: boolean; size?: number }>`
  border-radius: 50%;
  height: ${(p) => p.size}px;
  width: ${(p) => p.size}px;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    width: 48px;
    height: 48px;
  }
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease, width 0.3s ease, opacity 0.3s ease;
  background: ${(p) => (p.active ? p.theme.colors.primary7 : p.theme.colors.primary9)};
  &:hover {
    background: ${(p) => (p.active ? p.theme.colors.primary7 : p.theme.colors.primary8)};
  }
`

const ChoiceList = styled.div`
  display: inline-flex;
  width: 100%;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  margin-right: -8px;
  position: relative;
  height: 160px;
  margin-bottom: 16px;
  padding-bottom: 16px;
`

const OptionCol = styled.div`
  width: calc(20%);
`

const BackgroundLine = styled.div`
  background: ${(p) => p.theme.colors.neutral9};
  height: 6px;
  width: 80%;
  z-index: -1;
  top: 45px;
  position: absolute;
`

const LabelContainer = styled.div`
  height: 24px;
  position: absolute;
  bottom: 48px;
  right: 0;
  left: 0;
  margin: auto;
  width: 95%;
`

type OptionType = {
  label?: string
  value: string | number
}

interface OptionItemProps {
  option: OptionType
  fieldName?: GuideQuestionIdEnum.MeaningOfLife | string
  active: boolean
  size: number
  onChange: (e: { [x: string]: string | number }) => void
}

export const OptionItem = ({ option, fieldName, active, size, onChange }: OptionItemProps) => {
  return (
    <OptionCol>
      <div style={{ textAlign: 'center' }}>
        <div
          style={{
            height: 90,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Option
            data-testid={`slider-option-${option.value}`}
            active={active}
            size={size}
            onClick={() =>
              onChange({
                [fieldName as string]: option.value,
              })
            }
          >
            {active && <img src={checkSVG} alt="Check" width="24" />}
          </Option>
        </div>
        <label>{option.label}</label>
      </div>
    </OptionCol>
  )
}

const options: OptionType[] = [
  {
    value: 0,
  },
  {
    value: 25,
  },
  {
    value: 50,
  },
  {
    value: 75,
  },
  {
    value: 100,
  },
]

const LeftLabel = styled.div`
  width: 33%;
`

const RightLabel = styled(LeftLabel)`
  text-align: right;
  width: 33%;
`

const CenterLabel = styled(LeftLabel)`
  text-align: center;
  width: 33%;
`

interface QualityOfLifeProps {
  onNext?: (plan?: Patient) => void
  required?: boolean
  defaultValue?: number
  text?: string | JSX.Element
  onChange: (e: { [x: string]: string | number }) => void
  onBack?: () => void
  buttonAlign?: string
  nextButton?: string
  hideButtons?: boolean
  fieldName?: string
}

export default function QualityOfLife({
  onNext,
  required,
  defaultValue,
  text,
  onChange,
  onBack,
  buttonAlign,
  nextButton,
  hideButtons,
  fieldName,
}: QualityOfLifeProps) {
  return (
    <StepContainer>
      <div>
        <Animated>
          <>
            {text && <QuestionText data-testid="slider-text">{text}</QuestionText>}
            {/* <Directions>
              Move the slider based on which statements you agree most with
            </Directions> */}
            <ChoiceList>
              {options?.map((option) => {
                const size = 56
                const active = !isNil(defaultValue) && option.value === defaultValue
                return (
                  <OptionItem
                    key={option.value}
                    onChange={onChange}
                    active={active}
                    size={size}
                    fieldName={fieldName}
                    option={option}
                  />
                )
              })}
              <BackgroundLine />
              <LabelContainer>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                  }}
                >
                  <LeftLabel>
                    <Trans>{i18n.t('meaningOfLife.livingThroughAnything')}</Trans>
                  </LeftLabel>
                  <CenterLabel>
                    <Trans>{i18n.t('meaningOfLife.balanceOfTheTwo')}</Trans>
                  </CenterLabel>
                  <RightLabel>
                    <Trans>{i18n.t('meaningOfLife.beingComfortable')}</Trans>
                  </RightLabel>
                </div>
              </LabelContainer>
            </ChoiceList>
          </>
        </Animated>
        <ButtonsContainer
          onNext={onNext}
          onBack={onBack}
          hideButtons={hideButtons}
          buttonAlign={buttonAlign}
          hideNext={required && (defaultValue === undefined || defaultValue === null)}
          nextButton={nextButton}
        />
      </div>
    </StepContainer>
  )
}

// const LeftText = styled.div`
//   width: ${(p) => (!p.isMobile ? '194px' : '154px')};
//   font-size: ${(p) => (!p.isMobile ? '18px' : '12px')} !important;
//   color: ${(p) => p.theme.colors.neutral1};
//   position: absolute;
//   left: ${(p) => (!p.isMobile ? '-56px' : '-24px')};
//   line-height: 20px;
//   font-weight: 500;
// `;

// const RightText = styled(LeftText)`
//   right: ${(p) => (!p.isMobile ? '-56px' : '-24px')};
//   position: absolute;
//   left: inherit;
//   text-align: right;
//   width: 212px;
// `;

// const CenterText = styled(LeftText)`
//   left: 50%;
//   text-align: center;
//   color: ${(p) => p.theme.colors.neutral5};
// `;

// const TextArea = ({isMobile}) => {
//   return (
//     <>
//       <LeftText isMobile={isMobile}>
//         {getQualityOfLifeText('left', isMobile)}
//       </LeftText>
//       <CenterText isMobile={isMobile}>
//         <span
//           style={{
//             position: 'relative',
//             left: '-50%',
//             fontSize: isMobile ? '13px' : '18px',
//           }}
//         >
//           {getQualityOfLifeText('middle', isMobile)}
//         </span>
//       </CenterText>
//       <RightText isMobile={isMobile}>
//         {getQualityOfLifeText('right', isMobile)}
//       </RightText>
//     </>
//   );
// };

// export default ({
//   onNext,
//   required,
//   defaultValue,
//   text,
//   onChange,
//   onBack,
//   buttonAlign,
//   nextButton,
//   hideButtons,
//   progress,
//   animationIn,
//   fieldName,
// }) => {
//   const windowWidth = useWindowWidth();
//   const [clean, setClean] = useState(true);
//   const isMobile = windowWidth < 415;
//   return (
//     <StepContainer>
//       <div>
//         {/* Don't animate on mobile */}
//         {!isMobile ? (
//           <Animated animationIn={animationIn}>
//             {text && <QuestionText>{text}</QuestionText>}
//             <Directions>
//               Move the slider based on which statements you agree most with
//             </Directions>
//             <SliderContainer>
//               <Slider
//                 defaultValue={defaultValue || DEFAULT}
//                 disabled={false}
//                 tooltipVisible={!defaultValue && clean}
//                 tipFormatter={() => 'You can move me'}
//                 onChange={(value) => {
//                   setClean(false);
//                   onChange({[fieldName]: value});
//                 }}
//               />
//               <TextArea isMobile={isMobile} />
//             </SliderContainer>
//           </Animated>
//         ) : (
//           <>
//             {text && <QuestionText>{text}</QuestionText>}
//             <Directions>
//               Move the slider based on which statements you agree most with
//             </Directions>
//             <SliderContainer>
//               <Slider
//                 defaultValue={defaultValue || DEFAULT}
//                 disabled={false}
//                 tooltipVisible={!defaultValue && clean}
//                 tipFormatter={() => 'You can move me'}
//                 onChange={(value) => {
//                   setClean(false);
//                   onChange({[fieldName]: value});
//                 }}
//               />
//               <TextArea isMobile={isMobile} />
//             </SliderContainer>
//           </>
//         )}
//         <ButtonsContainer
//           onNext={onNext}
//           onBack={onBack}
//           progress={progress}
//           hideButtons={hideButtons}
//           buttonAlign={buttonAlign}
//           hideNext={required && !defaultValue}
//           nextButton={nextButton}
//         />
//       </div>
//     </StepContainer>
//   );
// };
