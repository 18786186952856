import { useState, useEffect, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { Patient } from 'generated/graphql';

import Button from 'components/common/Button';
import useSavePatient from 'lib/hooks/useSavePatient';
import usePrevious from 'lib/hooks/usePrevious';
import { Step1Continue } from '../step1';
import Loading from 'components/common/Loading';
import { AfterSaveAction } from 'routes/app-survey/step1';
import SectionHeader from 'routes/app-survey/SectionHeader';
import SelectBlock from 'routes/app-survey/step1/SelectBlock';
import SectionFooter from 'routes/app-survey/SectionFooter';
import getSurveyHashes from 'lib/helpers/getSurveyHashes';

const Step3Styled = styled.div`
  background-color: white;
  flex: 1;
  @media only screen and (max-width: ${({ theme }) =>
  theme.breakpoints.medium}) {
    padding-bottom: 100px;
  }
`;

const Step3Body = styled.div`
  margin: auto;
  max-width: 600px;
  padding: 48px 10px;
  @media only screen and (max-width: ${({ theme }) =>
    theme.breakpoints.mediumSmall}) {
    padding: 24px;
  }
`;

const Step3Title = styled.h1`
  font-weight: 500;
  font-size: 18px;
  color: #323F4B;
`;

interface Step1Props {
  patient?: Patient | null;
};

const Step3: FC<Step1Props> = ({ patient }) => {
  const [afterSaveAction, setAfterSaveAction] = useState<AfterSaveAction>();
  const [postAcpComfortLevelBecomeIll, setPostAcpComfortLevelBecomeIll] = useState<number | null>(null);
  const [postAcpWorriedFutureIllnesses, setPostAcpWorriedFutureIllnesses] = useState<number | null>(null);
  const [postAcpDecisionMakerKindOfMedicalYouWouldWant, setPostAcpDecisionMakerKindOfMedicalYouWouldWant] = useState<number | null>(null);
  const [postAcpHealthcareProviderKindOfMedicalYouWouldWant, setPostAcpHealthcareProviderKindOfMedicalYouWouldWant] = useState<number | null>(null);
  const [postAcpHealthcareSystemTrust, setPostAcpHealthcareSystemTrust] = useState<number | null>(null);

  const { onSavePatient, saving } = useSavePatient();

  const history = useHistory();
  const { t } = useTranslation();

  const savingPrev = usePrevious(saving);

  const save = async () => {
    if (patient) {
      await onSavePatient({
        ...patient,
        postAcpComfortLevelBecomeIll,
        postAcpWorriedFutureIllnesses,
        postAcpDecisionMakerKindOfMedicalYouWouldWant,
        postAcpHealthcareProviderKindOfMedicalYouWouldWant,
        postAcpHealthcareSystemTrust,
      });
    }
  };

  const goNext = () => {
    setAfterSaveAction(AfterSaveAction.NEXT);
    save();
  };
  
  useEffect(() => {
    // set patient values every patient change
    if (patient) {
      setPostAcpComfortLevelBecomeIll(+(patient.postAcpComfortLevelBecomeIll as number) || null);
      setPostAcpWorriedFutureIllnesses(+(patient.postAcpWorriedFutureIllnesses as number) || null);
      setPostAcpDecisionMakerKindOfMedicalYouWouldWant(+(patient.postAcpDecisionMakerKindOfMedicalYouWouldWant as number) || null);
      setPostAcpHealthcareProviderKindOfMedicalYouWouldWant(+(patient.postAcpHealthcareProviderKindOfMedicalYouWouldWant as number) || null);
      setPostAcpHealthcareSystemTrust(+(patient.postAcpHealthcareSystemTrust as number) || null);
    }
  }, [patient]);

  useEffect(() => {
    // Go to step 2 on saving end and if patient is truthy
    if (savingPrev && !saving && patient && afterSaveAction) {
      switch (afterSaveAction) {
        case AfterSaveAction.HOME: {
          history.push('/app/home');
          break;
        }
        case AfterSaveAction.NEXT: {
          history.push('/app/post-survey?step=4');
          break;
        }
        case AfterSaveAction.BACK: {
          history.push('/app/post-survey?step=2');
          break;
        }
        default: {
          return;
        }
      }
    }
  }, [
    history,
    savingPrev,
    saving,
    patient,
    afterSaveAction,
  ]);
  
  const hashes = getSurveyHashes();

  const disabled =
    !postAcpComfortLevelBecomeIll ||
    !postAcpWorriedFutureIllnesses ||
    !postAcpDecisionMakerKindOfMedicalYouWouldWant ||
    !postAcpHealthcareProviderKindOfMedicalYouWouldWant ||
    !postAcpHealthcareSystemTrust;

  return (
    <>
      <Step3Styled>
        <SectionHeader
          sectionName={t("planConfigurator.POST_SURVEY", "Post-ACP Survey")}
          progress={patient?.guideTemplate?.name === "WF_Path1" ? "40" : "50"}
          onClick={() => {
            setAfterSaveAction(AfterSaveAction.HOME);
            save();
          }}
        />
        {saving && <Loading height={80} />}
        <Step3Body>
          <Step3Title>
            {t(
              "survey.advanceCarePlanningReadiness",
              "Advance Care Planning Readiness and Previous Experiences Assessment"
            )}
          </Step3Title>
          <SelectBlock
            title={`5. ${t(
              "survey.whatDescribesYouBestYourComfort",
              `On a scale of 1-4, what describes you best when it comes 
              to your comfort level in thinking about your care if you become
              seriously ill?`
            )}`}
            subtitle={t("survey.markTheBoxBestDescribesYou", "Please mark the box that best describes you")}
            items={["1", "2", "3", "4"]}
            hash={hashes.COMFORT_LEVEL_BECOME_ILL_TEXT_HASH}
            active={postAcpComfortLevelBecomeIll?.toString()}
            onSelect={(item: string) => setPostAcpComfortLevelBecomeIll(+item)}
            noCapitalize
          />
          <SelectBlock
            title={`6. ${t(
              "survey.howWorriedAreYouWhenYouThink",
              `On a scale of 1-5, how worried are you when you think about
              your future illnesses?`
            )}`}
            subtitle={t("survey.markTheBoxBestDescribesYou", "Please mark the box that best describes you")}
            items={["1", "2", "3", "4", "5"]}
            hash={hashes.WORRIED_FUTURE_ILLNESSES_TEXT_HASH}
            active={postAcpWorriedFutureIllnesses?.toString()}
            onSelect={(item: string) => setPostAcpWorriedFutureIllnesses(+item)}
            noCapitalize
          />
          <SelectBlock
            title={`7. ${t(
              "survey.howReadyAreYouToTalkDecision",
              `On a scale of 1-5, how ready are you to talk to your decision
              maker about the kind of medical care you would want if you 
              were very sick or near the end of life?`
            )}`}
            subtitle={t("survey.markTheBoxBestDescribesYou", "Please mark the box that best describes you")}
            items={["1", "2", "3", "4", "5"]}
            hash={hashes.DECISION_MAKER_KIND_OF_MEDICAL_YOU_WOULD_WANT_TEXT_HASH}
            active={postAcpDecisionMakerKindOfMedicalYouWouldWant?.toString()}
            onSelect={(item: string) => setPostAcpDecisionMakerKindOfMedicalYouWouldWant(+item)}
            noCapitalize
          />
          <SelectBlock
            title={`8. ${t(
              "survey.howReadyAreYouToTalkHealthcare",
              `On a scale of 1-5, how ready are you to talk to your healthcare
              provider about the kind of medical care you would want if you were
              very sick or near the end of life?`
            )}`}
            subtitle={t("survey.markTheBoxBestDescribesYou", "Please mark the box that best describes you")}
            items={["1", "2", "3", "4", "5"]}
            hash={hashes.DECISION_MAKER_KIND_OF_MEDICAL_YOU_WOULD_WANT_TEXT_HASH}
            active={postAcpHealthcareProviderKindOfMedicalYouWouldWant?.toString()}
            onSelect={(item: string) => setPostAcpHealthcareProviderKindOfMedicalYouWouldWant(+item)}
            noCapitalize
          />
          <SelectBlock
            title={`9. ${t(
              "survey.inGeneralHowMuchDoYouTrustHealthcare",
              `In general, how much do you trust the healthcare system as
              a whole?`
            )}`}
            subtitle={t("survey.markTheBoxBestDescribesYou", "Please mark the box that best describes you")}
            items={["1", "2", "3", "4", "5"]}
            hash={hashes.POST_ACP_HEALTH_CARE_SYSTEM_TRUST_TEXT_HASH}
            active={postAcpHealthcareSystemTrust?.toString()}
            onSelect={(item: string) => setPostAcpHealthcareSystemTrust(+item)}
            noCapitalize
          />
          {!disabled && (
            <Step1Continue>
              <Button
                loading={saving}
                disabled={saving}
                onClick={goNext}
                pulse
              >
                {t("button.continue", "Continue")}
              </Button>
            </Step1Continue>
          )}
        </Step3Body>
      </Step3Styled>
      <SectionFooter
        goBack={() => {
          setAfterSaveAction(AfterSaveAction.BACK);
          save();
        }}
        goNext={goNext}
        loading={saving}
        disabled={disabled}
      />
    </>
  );
}

export default Step3;
