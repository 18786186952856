import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { get, debounce } from 'lodash'
// COMPONENTS
import LoadingOutlined from 'components/icons/LoadingOutlined'
// APOLLO
import { Patient, useSearchPatientsQuery } from 'generated/graphql'

const Input = styled.input`
  width: 100%;
  border: 2px solid ${(p) => p.theme.colors.neutral9};
  border-radius: 5px;
  padding-left: 8px;
  z-index: 5;
  position: relative;
  height: 44px;
`

const Container = styled.div`
  position: relative;
`

const ResultContainer = styled.div`
  height: 180px;
  background: #fff;
  position: absolute;
  z-index: 100;
  border: 1px solid ${(p) => p.theme.colors.neutral8};
  border-top: 0px;
  width: 100%;
  overflow-y: scroll;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  z-index: 200;
`

const ResultItemContainer = styled.div`
  padding: 8px;
  display: flex;
  border-bottom: 1px solid ${(p) => p.theme.colors.neutral9};
  cursor: pointer;
  align-items: center;
  &:hover {
    background: ${(p) => p.theme.colors.primary10};
  }
`

const Name = styled.h3`
  font-weight: 600;
  margin: 0px;
  color: ${(p) => p.theme.colors.neutral1};
`

const Email = styled.h3`
  font-weight: 400;
  font-size: 14px;
  margin: 0px;
  color: ${(p) => p.theme.colors.neutral2};
`

const Birthday = styled.span`
  font-weight: 400;
  margin: 0px;
  font-size: 14px;
  color: ${(p) => p.theme.colors.neutral5};
`

const Loading = styled(LoadingOutlined)`
  position: absolute;
  right: 16px;
  top: 16px;
  color: ${(p) => p.theme.colors.neutral7};
`

interface ResultItemProps {
  firstName?: string
  lastName?: string
  birthday?: string
  email?: string
  onClick?: () => void
}

const ResultItem = ({ firstName, lastName, birthday, email, onClick }: ResultItemProps) => {
  return (
    <ResultItemContainer onClick={onClick}>
      <div>
        <Name>
          {firstName} {lastName} {birthday && <Birthday>{birthday}</Birthday>}
        </Name>
        <Email>{email}</Email>
      </div>
    </ResultItemContainer>
  )
}

const Overlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.025);
  z-index: 1;
`

interface PatientSearchInputProps {
  onClick?: (patient: Patient) => void
  clinicId?: string
}

export default function PatientSearchInput({ onClick, clinicId }: PatientSearchInputProps) {
  const [searchText, setSearchText] = useState<string | undefined>(undefined)
  const [open, setOpen] = useState(false)
  const { data, loading } = useSearchPatientsQuery({
    variables: {
      searchText: searchText || '',
      clinicId: clinicId,
    },
    skip: !searchText?.[2], // they should need to type in 3 characters before searching
    fetchPolicy: 'cache-and-network',
  })

  const patients = get(data, 'searchPatients')

  const handleFilter = debounce((finalSearchText) => {
    setSearchText(finalSearchText)
  }, 250)

  useEffect(() => {
    if (searchText && searchText.length > 0) {
      setOpen(true)
    }
  }, [searchText])

  return (
    <Container>
      <Input
        placeholder="Search by first name, last name, email, or userId"
        onChange={(e) => handleFilter(e.target.value)}
        onFocus={() => setOpen(true)}
      />
      {loading && <Loading type="loading" />}
      {open && patients && patients.length > 0 && (
        <ResultContainer>
          {patients &&
            patients.map((item) => (
              <ResultItem
                key={item.id}
                firstName={item.firstName}
                lastName={item.lastName}
                email={item.email}
                birthday={item.birthday}
                onClick={() => {
                  if (onClick) {
                    onClick(item)
                  }
                }}
              />
            ))}
        </ResultContainer>
      )}
      {open && patients && patients.length > 0 && <Overlay onClick={() => setOpen(false)} />}
    </Container>
  )
}
