import { useState } from 'react'
import queryString from 'query-string'
import { useHistory, useLocation } from 'react-router'
import styled from 'styled-components'
// COMPONENTS
import ElephantChat from 'components/common/ElephantChat'
import MedicalDecisionMaker from './MedicalDecisionMaker'
import QualityOfLifeAnswer from './QualityOfLifeAnswer'
// IMAGES
import valuesSVG from 'lib/media/images/values_section.svg'
import qualitySVG from 'lib/media/images/quality_of_life_section.svg'
import qualityOfLife2Svg from 'lib/media/images/quality_of_life_2.svg'
// IMAGES
import cprSVG from './imgs/CPR_PREFERENCE.svg'
import breathingSVG from './imgs/BREATHING_PREFERENCE.svg'
import feedingSVG from './imgs/FEEDING_PREFERENCES.svg'
import dialysisSVG from './imgs/DIALYSIS_PREFERENCES.svg'
import supportiveCareSVG from 'lib/media/icons/Supportive_Care.svg'
import medicalCareSvg from './imgs/MEDICAL_CARE.svg'
// LIB
import { QuestionItemOption, QuestionType } from 'lib/types/QuestionItem'
import getAnswerTextFromValue from 'lib/helpers/getAnswerTextFromValue'
import getQuestsions from 'lib/helpers/questions'
import i18n from 'lib/helpers/i18n'
import en from 'lib/helpers/locales/en/translation.json'
// GRAPQHL
import {
  GuideQuestionIdEnum,
  DecisionMakerRelationshipEnum,
  ImportantToMeEnum,
  LivingSituationEnum,
  Patient,
  GuideTemplateSectionIdEnum,
} from 'generated/graphql'
import { QuestionItem } from 'lib/types/QuestionItem'

import { DownOutlined, RightOutlined } from '@ant-design/icons'
import { isNil } from 'lodash'
import getAnswer from 'lib/helpers/getAnswer'

const Containter = styled.div`
  width: 700px;
  max-width: 98%;
  margin: auto;
`

const LeftIcon = styled.div`
  width: 48px;
  height: 48px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
`

export const Card = styled.div`
  background-color: #fff;
  border-radius: ${({ theme }) => theme.borderRadius}px;
  margin-top: 16px;
  border: 1px solid ${({ theme }) => theme.colors.neutral9};
`

const Padding = styled.div`
  padding: 16px;
`

const CardTitle = styled.h1`
  font-size: 24px;
  color: ${(p) => p.theme.colors.neutral1};
  font-weight: 500;
  text-align: left;
  margin: 0px;
  width: 90%;
`

const CardHeaderContainer = styled(Padding)`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral8};
  cursor: pointer;
`

const CardRowContainer = styled.div<{ borderless?: boolean }>`
  cursor: pointer;
  padding: 16px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid
    ${({ theme, borderless }) => {
      if (borderless) return 'transparent'
      return theme.colors.neutral8
    }};
  transition: color 0.3s ease, background-color 0.3s ease, opacity 0.3s ease;
  &:hover {
    background: ${({ theme }) => theme.colors.primary10};
  }
`

const Label = styled.p`
  margin: 0px;
  color: ${({ theme }) => theme.colors.neutral4};
  /* We need to overwrite some of labels that are not strings but rather 
  are react components that include <labels> and <strong> html elements */
  label,
  strong {
    margin: 0;
    color: ${({ theme }) => theme.colors.neutral4};
    font-weight: 400;
    font-size: 16px !important;
    margin: 0px;
  }
`

const StepIcon = styled.img`
  height: 48px;
  width: 48px;
`

type CardHeaderProps = {
  title: string
  icon: string
  expanded: boolean
  setExpanded?: Function
}

export const CardHeader = ({ title, icon, expanded, setExpanded, ...args }: CardHeaderProps) => {
  return (
    <CardHeaderContainer {...args} onClick={() => (setExpanded ? setExpanded((prevState) => !prevState) : null)}>
      <LeftIcon>
        <StepIcon src={icon} alt="Step Icon" />
      </LeftIcon>
      <CardTitle>{title}</CardTitle>
      <div>{expanded ? <DownOutlined /> : <RightOutlined />}</div>
    </CardHeaderContainer>
  )
}

type ParseValuesToStringArgs = {
  questions: QuestionItem[]
  fieldName: string
  value: string | string[] | number
  plan: Patient
}

type GetArrayAnswerTextArgs = {
  questions: QuestionItem[]
  fieldName: string
  value: string[] | number[]
  plan: Patient
}

const getArrayAnswerText = ({ questions, fieldName, value, plan }: GetArrayAnswerTextArgs) => {
  return value.map((val, i) => {
    let parsedValue = getAnswer({
      questions,
      fieldName,
      value: val,
    })
    //re-write the string in the special circumstance it is the "other" value, and the user provided a text filled in answer
    if (
      fieldName === GuideQuestionIdEnum.ImportantToMe &&
      val === ImportantToMeEnum.Other &&
      plan[GuideQuestionIdEnum.ImportantToMeOther]
    ) {
      parsedValue = `${parsedValue} (${plan[GuideQuestionIdEnum.ImportantToMeOther]})`
    }
    // if onlly one answer,
    if (value.length === 1) {
      return parsedValue
    }
    // if this is the last answer in a list of answers, add an "and" to it
    if (i === value.length - 1) {
      return ` and ${parsedValue}`
    }
    // otherwise, return the answer and, potentially, a comma where it makes sense
    return `${parsedValue}${i === value.length - 2 ? '' : ', '}`
  })
}

const parseValuesToString = ({ questions, fieldName, value, plan }: ParseValuesToStringArgs) => {
  if (fieldName === GuideQuestionIdEnum.AdditionalCarePreferences) {
    return value || i18n.t('editSurveyPage.noAnswer', 'No answer')
  }

  /**
   * if the field we're mapping over is the decisionMakerRelationship field and the
   * user selected "other", we want to show the text that they input, if it exists
   */

  if (fieldName === GuideQuestionIdEnum.LifeSustainingProcedures && plan) {
    return (
      <>
        <ul>
          {Array.isArray(value) &&
            value?.map((item) => {
              const options: QuestionItemOption[] =
                questions?.find((item) => item?.fieldName === GuideQuestionIdEnum.LifeSustainingProcedures)?.options ||
                []
              const option = [...options]?.find((opt: any) => opt?.id === item)

              if (option?.id === 'other') {
                return (
                  <li key={item}>
                    {option?.label}
                    {plan?.lifeSustainingProceduresOther && ` ${plan?.lifeSustainingProceduresOther}`}
                  </li>
                )
              }

              return <li key={item}>{option?.label}</li>
            })}
        </ul>
      </>
    )
  }

  if (
    fieldName === GuideQuestionIdEnum.DecisionMakerRelationship &&
    value === DecisionMakerRelationshipEnum.Other &&
    plan
  ) {
    return plan[GuideQuestionIdEnum.DecisionMakerRelationshipOther] || i18n.t('editSurveyPage.other', 'Other')
  }

  if (
    fieldName === GuideQuestionIdEnum.AltDecisionMakerRelationship &&
    value === DecisionMakerRelationshipEnum.Other &&
    plan
  ) {
    return plan[GuideQuestionIdEnum.AltDecisionMakerRelationshipOther] || i18n.t('editSurveyPage.other', 'Other')
  }

  if (
    fieldName === GuideQuestionIdEnum.ThirdDecisionMakerRelationship &&
    value === DecisionMakerRelationshipEnum.Other &&
    plan
  ) {
    return plan[GuideQuestionIdEnum.ThirdDecisionMakerRelationshipOther] || i18n.t('editSurveyPage.other', 'Other')
  }

  /** We need a special circumstance where the user chose "other" living situation and  */
  if (
    fieldName === GuideQuestionIdEnum.LivingSituation &&
    value === LivingSituationEnum.Other &&
    plan[GuideQuestionIdEnum.LivingSituationOther]
  ) {
    return plan[GuideQuestionIdEnum.LivingSituationOther]
  }

  if (isNil(value)) {
    return i18n.t('editSurveyPage.noneSelected', 'None selected')
  }

  if (Array.isArray(value) && value.length > 0) {
    return getArrayAnswerText({
      questions,
      fieldName,
      value,
      plan,
    })
  }

  if (fieldName === GuideQuestionIdEnum.MeaningOfLife) {
    return <QualityOfLifeAnswer answer={value} />
  }

  return getAnswer({
    questions,
    fieldName,
    value,
  })
}

const AnswerText = styled.p`
  margin: 0px;
  color: ${({ theme }) => theme.colors.neutral1};
  font-weight: 600;
  font-size: 16px !important;
  div,
  p,
  label {
    font-size: 16px !important;
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    div,
    p,
    label {
      font-size: 16px !important;
    }
  }
`

// const Label = ({children}) => {
//   if (typeof children === 'string') return <LabelText>{children}</LabelText>;
//   console.log(children);
//   if (isReact.element(children))
//     return <LabelText id="text">{children}</LabelText>;
//   return <LabelText id="text">{children}</LabelText>;
// };

type CardRowProps = {
  question: QuestionItem
  label?: string
  questions: QuestionItem[]
  editSection: string
  borderless?: boolean
  answerText?: string | JSX.Element
  plan?: Patient
}

export const CardRow = ({ question, label, questions, editSection, borderless, answerText, plan }: CardRowProps) => {
  const history = useHistory()

  return (
    <CardRowContainer
      borderless={borderless}
      onClick={() =>
        history.push(
          `/app/questions?${queryString.stringify({
            editSection,
            step: question.stepName,
            mode: 'editing', // will tell us if the user is "editing" answers or going through the questions initially
          })}`
        )
      }
      data-testid={'question-' + label + question?.stepName}
    >
      <div style={{ width: '98%' }}>
        <Label>{label || question.shortText || question.text}</Label>
        <AnswerText id="answer-text">
          {answerText ||
            parseValuesToString({
              questions,
              fieldName: question?.fieldName as string,
              value: question?.defaultValue,
              plan: plan as Patient,
            })}
        </AnswerText>
      </div>
      <RightOutlined />
    </CardRowContainer>
  )
}

type EditBlockProps = {
  questions: QuestionItem[]
  title: string
  icon: string
  // should be one of the section constants
  editSection: GuideTemplateSectionIdEnum
  // defaultExpanded: will determine if the section is expanded by default
  defaultExpanded?: boolean
  labels?: string[]
  plan: Patient
  className?: string
}

const EditBlock = ({
  questions,
  title = 'Values',
  icon,
  editSection = GuideTemplateSectionIdEnum.Values,
  defaultExpanded = true,
  labels = [],
  plan,
  className,
}: EditBlockProps) => {
  const [expanded, setExpanded] = useState(defaultExpanded)
  console.log({ questions })
  return (
    <Card className={className}>
      <CardHeader title={title} icon={icon} setExpanded={setExpanded} expanded={expanded} />
      {expanded && (
        <>
          {questions.map((question, i) => {
            return (
              <CardRow
                key={question.fieldName}
                label={labels?.[i]}
                question={question}
                questions={questions}
                editSection={editSection}
                plan={plan}
              />
            )
          })}
        </>
      )}
    </Card>
  )
}

const ListItem = styled.li`
  color: ${({ theme }) => theme.colors.primary2};
  font-weight: 400;
`

interface QualityOfLifeProps {
  questions: QuestionItem[]
}

const QualityOfLife = ({ questions }: QualityOfLifeProps) => {
  const [expanded, setExpanded] = useState(true)
  const meaningOfLife = questions.find((item) => item.fieldName === GuideQuestionIdEnum.MeaningOfLife)
  const notWorthLiving = questions.find((item) => item.fieldName === GuideQuestionIdEnum.NotWorthLiving)
  const notWorthLiving2 = questions.find((item) => item.fieldName === GuideQuestionIdEnum.NotWorthLiving2)
  const notWorthLiving3 = questions.find((item) => item.fieldName === GuideQuestionIdEnum.NotWorthLiving3)
  const lastLocation = questions.find((item) => item.fieldName === GuideQuestionIdEnum.LastLocation)
  const receiveCareOther = questions.find((item) => item.fieldName === GuideQuestionIdEnum.ReceiveCareOther)

  return (
    <Card>
      <CardHeader
        title={i18n.t('editSurveyPage.qualityOfLife')}
        icon={qualitySVG}
        expanded={expanded}
        setExpanded={setExpanded}
      />
      {expanded && (
        <>
          <CardRow
            key={GuideQuestionIdEnum.MeaningOfLife}
            question={meaningOfLife as QuestionItem}
            questions={questions}
            editSection={GuideTemplateSectionIdEnum.QualityOfLife}
          />
          <CardRowContainer>
            <div>
              {i18n.t('editSurveyPage.followingAreScenarios')}
              <div style={{ paddingLeft: 16 }}>
                <CardRow
                  key={GuideQuestionIdEnum.NotWorthLiving}
                  label={i18n.t('editSurveyPage.abilityCommunicate', 'Ability to communicate')}
                  question={notWorthLiving as QuestionItem}
                  questions={questions}
                  editSection={GuideTemplateSectionIdEnum.QualityOfLife}
                  answerText={
                    <>
                      <ul>
                        {console.log(notWorthLiving)}
                        {notWorthLiving?.defaultValue?.map((value) => {
                          return (
                            <ListItem key={value}>
                              {getAnswerTextFromValue({
                                questions,
                                fieldName: GuideQuestionIdEnum.NotWorthLiving,
                                value,
                              })}
                            </ListItem>
                          )
                        })}
                      </ul>
                    </>
                  }
                />
                <CardRow
                  key="notWorthLiving2"
                  label={i18n.t('editSurveyPage.mobility')}
                  question={notWorthLiving2 as QuestionItem}
                  questions={questions}
                  editSection={GuideTemplateSectionIdEnum.QualityOfLife}
                  answerText={
                    <>
                      <ul>
                        {notWorthLiving2?.defaultValue?.map((value) => {
                          return (
                            <ListItem key={value}>
                              {getAnswerTextFromValue({
                                questions,
                                fieldName: GuideQuestionIdEnum.NotWorthLiving2,
                                value,
                              })}
                            </ListItem>
                          )
                        })}
                      </ul>
                    </>
                  }
                />
                <CardRow
                  key="notWorthLiving3"
                  label={i18n.t('editSurveyPage.independence', en.editSurveyPage.independence)}
                  question={notWorthLiving3 as QuestionItem}
                  questions={questions}
                  editSection={GuideTemplateSectionIdEnum.QualityOfLife}
                  borderless
                  answerText={
                    <>
                      <ul>
                        {notWorthLiving3?.defaultValue?.map((value) => {
                          return (
                            <ListItem key={value}>
                              {getAnswerTextFromValue({
                                questions,
                                fieldName: GuideQuestionIdEnum.NotWorthLiving3,
                                value,
                              })}
                            </ListItem>
                          )
                        })}
                      </ul>
                    </>
                  }
                />
              </div>
            </div>
          </CardRowContainer>
          <CardRow
            key="lastLocation"
            label={i18n.t('editSurveyPage.iBecomeIll', en.editSurveyPage.iBecomeIll)}
            question={lastLocation as QuestionItem}
            questions={questions}
            editSection={GuideTemplateSectionIdEnum.QualityOfLife}
          />
          {receiveCareOther && (
            <CardRow
              key="receiveCareOther"
              label={i18n.t('receiveCareOther', en.receiveCareOther)}
              question={receiveCareOther as QuestionItem}
              questions={questions}
              answerText={receiveCareOther?.defaultValue}
              editSection={GuideTemplateSectionIdEnum.QualityOfLife}
            />
          )}
        </>
      )}
    </Card>
  )
}

interface MedicalCareProps {
  questions: QuestionItem[]
  plan: Patient
}

const MedicalCare = ({ questions, plan }: MedicalCareProps) => {
  const CPR_QUESTIONS = [GuideQuestionIdEnum.CprPreferenceToday, GuideQuestionIdEnum.CprPreference]
  const VENT_QUESTIONS = [GuideQuestionIdEnum.VentilationPreferenceToday, GuideQuestionIdEnum.VentilationPreference]
  const LONG_TERM_FEEDING_QUESTIONS = [GuideQuestionIdEnum.LongTermFeedingToday, GuideQuestionIdEnum.LongTermFeeding]

  const DIALYSIS_QUESTIONS = [GuideQuestionIdEnum.DialysisTreatmentToday, GuideQuestionIdEnum.DialysisTreatment]

  const OREGON_QUESTIONS = [
    GuideQuestionIdEnum.MedicalCareScenario1,
    GuideQuestionIdEnum.MedicalCareScenario2,
    GuideQuestionIdEnum.MedicalCareScenario3,
    GuideQuestionIdEnum.LifeSustainingProcedures,
  ]

  return (
    <>
      {/* CPR: Cardiopulmonary Resuscitation */}
      {questions?.filter((q) => CPR_QUESTIONS.includes(q.fieldName as GuideQuestionIdEnum))?.[0] && (
        <EditBlock
          questions={questions?.filter((q) => CPR_QUESTIONS.includes(q.fieldName as GuideQuestionIdEnum))}
          title={i18n.t('editSurveyPage.cardiopulmonaryResuscitation')}
          icon={cprSVG}
          plan={plan}
          editSection={GuideTemplateSectionIdEnum.MedicalCare}
          labels={[i18n.t('editSurveyPage.currentStateHealth'), i18n.t('editSurveyPage.qualityUnacceptable')]}
        />
      )}
      {questions?.filter((q) => VENT_QUESTIONS.includes(q.fieldName as GuideQuestionIdEnum))?.[0] && (
        <EditBlock
          questions={questions?.filter((q) => VENT_QUESTIONS.includes(q.fieldName as GuideQuestionIdEnum))}
          plan={plan}
          title={i18n.t('editSurveyPage.mechanicalVentilation')}
          icon={breathingSVG}
          editSection={GuideTemplateSectionIdEnum.MedicalCare}
          defaultExpanded={false}
          labels={[i18n.t('editSurveyPage.currentStateHealth'), i18n.t('editSurveyPage.qualityUnacceptable')]}
        />
      )}
      {questions.filter((q) => LONG_TERM_FEEDING_QUESTIONS.includes(q.fieldName as GuideQuestionIdEnum))?.[0] && (
        <EditBlock
          questions={questions.filter((q) => LONG_TERM_FEEDING_QUESTIONS.includes(q.fieldName as GuideQuestionIdEnum))}
          plan={plan}
          title={i18n.t('editSurveyPage.artificialNutrition')}
          icon={feedingSVG}
          editSection={GuideTemplateSectionIdEnum.MedicalCare}
          defaultExpanded={false}
          labels={[i18n.t('editSurveyPage.currentStateHealth'), i18n.t('editSurveyPage.qualityUnacceptable')]}
        />
      )}
      {questions.filter((q) => DIALYSIS_QUESTIONS.includes(q.fieldName as GuideQuestionIdEnum))?.[0] && (
        <EditBlock
          questions={questions.filter((q) => DIALYSIS_QUESTIONS.includes(q.fieldName as GuideQuestionIdEnum))}
          plan={plan}
          title={i18n.t('editSurveyPage.dialysis')}
          icon={dialysisSVG}
          editSection={GuideTemplateSectionIdEnum.MedicalCare}
          defaultExpanded={false}
          labels={[i18n.t('editSurveyPage.currentStateHealth'), i18n.t('editSurveyPage.qualityUnacceptable')]}
        />
      )}
      {/*  */}
      {questions?.filter((q) => OREGON_QUESTIONS.includes(q.fieldName as GuideQuestionIdEnum))?.[0] && (
        <EditBlock
          questions={questions?.filter((q) => OREGON_QUESTIONS.includes(q.fieldName as GuideQuestionIdEnum))}
          plan={plan}
          className="fs-exclude"
          title={i18n.t('planConfigurator.MEDICAL_CARE')}
          icon={medicalCareSvg}
          editSection={GuideTemplateSectionIdEnum.MedicalCare}
          labels={[
            i18n.t('medicalCareScenario1.title'),
            i18n.t('medicalCareScenario2.title'),
            i18n.t('medicalCareScenario3.title'),
            // 4. we can use the label from the actual question, we don't need to overwrite it here like the previous 3 questions
          ]}
        />
      )}
      {/*  */}
      {questions?.filter((q) => q.fieldName === GuideQuestionIdEnum.AdditionalCarePreferences)?.[0] && (
        <EditBlock
          questions={questions?.filter((q) => q.fieldName === GuideQuestionIdEnum.AdditionalCarePreferences)}
          plan={plan}
          className="fs-exclude"
          title={i18n.t('editSurveyPage.medicalCarePreferences')}
          icon={qualityOfLife2Svg}
          editSection={GuideTemplateSectionIdEnum.MedicalCare}
          defaultExpanded={false}
          labels={[i18n.t('editSurveyPage.medicalProviders')]}
        />
      )}
    </>
  )
}

type EditSurveyProps = {
  plan: Patient
}

export default function EditSurvey({ plan }: EditSurveyProps) {
  const locaton = useLocation()
  const { editSection } = queryString.parse(locaton.search)
  const section = plan?.guideTemplate?.sections?.find((section) => section?.sectionId === editSection)
  const questions = getQuestsions(plan, section?.questionIds || [])?.filter(
    // remove any questions we don't want showing up like videos, section breaks, etc
    (q) => ![QuestionType.video, QuestionType['section-break'], QuestionType.explainer].includes(q.type as QuestionType)
  )

  const sharedProps = {
    questions,
    plan,
  }

  return (
    <Containter>
      <ElephantChat text={i18n.t('editSurveyPage.title')} />{' '}
      {(() => {
        switch (editSection) {
          case GuideTemplateSectionIdEnum.Values:
            return (
              <EditBlock
                {...sharedProps}
                icon={valuesSVG}
                title={i18n.t('editSurveyPage.values')}
                editSection={GuideTemplateSectionIdEnum.Values}
              />
            )
          case GuideTemplateSectionIdEnum.QualityOfLife:
            return <QualityOfLife {...sharedProps} />
          case GuideTemplateSectionIdEnum.MedicalCare:
            return <MedicalCare {...sharedProps} />
          case GuideTemplateSectionIdEnum.MedicalDecisionMaker:
            return <MedicalDecisionMaker {...sharedProps} />
          case GuideTemplateSectionIdEnum.SupportiveCare:
            return (
              <EditBlock
                {...sharedProps}
                icon={supportiveCareSVG}
                title={i18n.t('editSurveyPage.supportiveCare')}
                editSection={GuideTemplateSectionIdEnum.SupportiveCare}
              />
            )
          default:
            return null
        }
      })()}
    </Containter>
  )
}
