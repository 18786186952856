import type { ReactNode, CSSProperties } from 'react';
// LIB
import theme from 'lib/theme';

interface BottomBarProps {
  children?: ReactNode;
  style?: CSSProperties;
}

export default function BottomBar({ children, style }: BottomBarProps) {
  return (
    <div
      style={{
        height: 88,
        zIndex: 4000,
        width: '100%',
        background: '#fff',
        position: 'fixed',
        borderTop: `1px solid ${theme.colors.neutral9}`,
        bottom: 0,
        left: 0,
        right: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        boxShadow:
          '0 13px 27px -5px rgba(50,50,93,.25), 0 8px 16px -8px rgba(0,0,0,.3), 0 -6px 16px -6px rgba(0,0,0,.025)',
      }}
    >
      <div
        style={{
          width: 1100,
          maxWidth: '95%',
          margin: 'auto',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          ...style,
        }}
      >
        {children}
      </div>
    </div>
  );
}
