import styled from 'styled-components'
import { capitalize } from 'lodash'
import checkSVG from '../check.svg'
import SelectLabel from 'components/text/SelectLabel'
import isReact from 'lib/helpers/isReact'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface ColumnProp {
  column?: boolean
}

const TextOptionContainer = styled.div<{ active: boolean }>`
  height: 100%;
  background: ${(p) => (p.active ? p.theme.colors.primary9 : p.theme.colors.neutral10)};
  border: 1px solid ${(p) => (p.active ? p.theme.colors.primary7 : p.theme.colors.neutral9)};
  margin-top: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease, width 0.3s ease, opacity 0.3s ease;
  &:hover {
    background: ${(p) => (p.active ? p.theme.colors.primary9 : p.theme.colors.neutral9)};
    border: 1px solid ${(p) => (p.active ? p.theme.colors.primary6 : p.theme.colors.neutral7)};
  }
`

const Input = styled.input<ColumnProp>`
  border: 0px;
  padding-left: 8px;
  width: 100%;
`

const SelectionContainer = styled.div`
  border-radius: 3px;
  width: 24px;
  height: 24px;
  display: flex;
  margin-right: 8px;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  align-items: center;
  justify-content: center;
  background: #fff;
  border: 1px solid ${(p) => p.theme.colors.neutral8};
`

export const Label = styled.div<ColumnProp>`
  max-width: 100%;
  font-weight: unset;
  font-size: 20px;
  line-height: 28px;
  /* margin: ${(props) => (props.column ? '16px 0 0 0' : '0px')};
  width: ${(props) => (props.column ? '100%' : 'auto')}; */
  margin: 8px;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    font-size: 16px;
    line-height: 125%;
  }
`

const LabelContainer = styled.div<ColumnProp>`
  flex-grow: 1;
  width: 100%;
  min-width: 75px;
  min-height: 40px;
  display: flex;
  align-items: ${(props) => (props.column ? 'flex-start' : 'center')};
  flex-direction: ${(props) => (props.column ? 'column' : 'row')};
  padding: 4px 12px 4px 0px;
`

const Col = styled.div`
  display: flex;
  align-self: flex-start;
  box-shadow: transparent 0px 0px 0px 1px;
  margin: 8px;
`

const Row = styled.div`
  display: inline-flex;
  align-items: center;
  width: 100%;
`

interface TextOptionProps {
  id: string | number
  item: {
    id: string | number
    label: string
  }
  isOther?: boolean
  isOtherNewLine?: boolean
  otherFieldName?: string
  otherFieldValue?: string
  active: boolean
  onClick: (e: React.MouseEvent) => void
  onChange: Function
  disabled: boolean
  noCapitalize?: boolean
}

export default function TextOption({
  id,
  item,
  isOther,
  isOtherNewLine,
  otherFieldName,
  otherFieldValue,
  active,
  onClick,
  onChange,
  disabled,
  noCapitalize,
}: TextOptionProps) {
  const { t } = useTranslation()
  const showTextInput = (isOther || isOtherNewLine) && otherFieldName && active

  return (
    <TextOptionContainer
      data-testid={`select-multiple-select-text-${item.id}`}
      active={active}
      onClick={(e: any) => {
        if (e?.target?.id === 'text-select-other-input') {
          return
        }
        onClick(e)
      }}
    >
      <Row>
        <Col>
          <SelectionContainer>{active && <img height="12" src={checkSVG} alt="Check" />}</SelectionContainer>
        </Col>
        <LabelContainer column={isOtherNewLine}>
          <div style={{ width: '100%' }}>
            {/* If the label exists and it's a react element, just return the element. If it's a string, wrap it in <SelectLabel> */}
            {!showTextInput && item.label && isReact.element(item.label) ? (
              item.label
            ) : (
              <SelectLabel>{noCapitalize ? item.label : capitalize(item.label)}</SelectLabel>
            )}
            {(showTextInput || (otherFieldName && id === 'other')) && (
              <Label>
                <Input
                  placeholder={t('describeOtherHere', 'Describe here')}
                  value={otherFieldValue}
                  column={isOtherNewLine}
                  id="text-select-other-input"
                  onChange={(e) => {
                    if (disabled) return
                    onChange({ [otherFieldName as string]: e.target.value })
                  }}
                />
              </Label>
            )}
          </div>
        </LabelContainer>
      </Row>
    </TextOptionContainer>
  )
}
