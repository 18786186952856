import { ModalOverlay, Modal, ModalBody, ButtonArea } from 'components/common/KodaModal'
import styled from 'styled-components'
import Button from 'components/common/Button'
import ModalTitle from 'components/text/ModalTitle'
import ExitButton from 'components/common/ExitButton'
import useLogActivityItemMutation from 'lib/hooks/useLogActivityItemMutation'
import { ActivityLogKeyEnum, Patient, UserProfile } from 'generated/graphql'
import { DeepPartial } from 'utility-types'
import { useState, useRef } from 'react'
import CheckboxText from 'components/common/CheckboxText'
import { AdPartEnum, getPartByPartId } from 'components/common/PlanDoc/MasterPlanDoc/plan-settings'
import { useTranslation } from 'react-i18next'
import ErrorText from 'components/common/FormItem/ErrorText'
import useGetPlanSettings from 'lib/hooks/useGetPlanSettings'

const Text = styled.p`
  font-size: 16px;
  font-family: ${({ theme }) => theme.fontFamily};
  color: ${({ theme }) => theme.colors.neutral1};
  margin: 0px;
  line-height: 125%;
`

const ErrorContent = styled(ErrorText)`
  padding: 5px 0;
  margin: 0;
  font-size: 16px;
`

interface ReadyToSignModalProps {
  visible: boolean
  onCancel: () => void
  onReady: () => void
  currentUser: UserProfile
  patient: DeepPartial<Patient>
  onCloseText?: string
  showExtraText?: boolean
}

export default function ReadyToSignModal({
  visible,
  onCancel,
  onReady,
  currentUser,
  patient,
  onCloseText = 'Sign Later',
  showExtraText = true,
}: ReadyToSignModalProps) {
  const { t } = useTranslation()
  const [witnessCheck1, setWitnessCheck1] = useState<boolean>(false)
  const [witnessCheck2, setWitnessCheck2] = useState<boolean>(false)
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false)
  const secondCheckbox = useRef<HTMLInputElement>(null)
  const getPlanSettings = useGetPlanSettings()
  const setting = getPlanSettings(patient)

  const [logActivityItem] = useLogActivityItemMutation()

  if (!visible) return null

  const handleCancel = () => {
    onCancel()
    setIsSubmitted(false)
    setWitnessCheck1(false)
    setWitnessCheck2(false)
  }

  const handleFirstCheckbox = (checked) => {
    setIsSubmitted(true)
    setWitnessCheck1(checked)
  }

  const witnessSettings = getPartByPartId(setting?.parts, AdPartEnum.sign_the_form)

  return (
    <ModalOverlay>
      <Modal>
        <ExitButton onClick={handleCancel} />
        <ModalBody>
          {/* <Padding> */}
          <ModalTitle style={{ marginBottom: 24 }}>{t('readyToSignModal.title', 'Ready to Sign?')}</ModalTitle>
          <Text style={{ marginBottom: 24 }}>
            {t(
              'readyToSignModal.beforeYouSign',
              'Before you sign your document, make sure your witnesses meet the following requirements'
            )}
            :
          </Text>
          {witnessSettings?.witnessRequirements?.requirements?.map((req) => {
            return (
              <>
                <Text style={{ marginBottom: 8, fontWeight: 600 }}>{req.title}</Text>
                <ul>
                  {req.phrases?.map((item) => {
                    return (
                      <li key={item}>
                        <Text>{item}</Text>
                      </li>
                    )
                  })}
                </ul>
              </>
            )
          })}
          <Text style={{ fontWeight: 600, marginBottom: 16 }}>
            {t('readyToSignModal.confirmRequirements', 'Confirm you meet these requirements by checking the boxes')}:
          </Text>
          <CheckboxText
            dataTestId="esign-modal-checkbox1"
            checked={witnessCheck1}
            onChange={handleFirstCheckbox}
            isError={!witnessCheck1 && isSubmitted}
            style={{ fontSize: 16 }}
          >
            {witnessSettings?.witnessRequirements?.numberOfWitnessesRequired === 1
              ? t('readyToSignModal.singleWitness', 'Yes, my witness meets these requirements')
              : t('readyToSignModal.multipleWitnesses', 'Yes, my witnesses meet these requirements')}
          </CheckboxText>
          {isSubmitted && !witnessCheck1 && (
            <ErrorContent>{t('readyToSignModal.checkToProceed', `Please check the box to proceed.`)}</ErrorContent>
          )}
          <div ref={secondCheckbox}>
            <CheckboxText
              dataTestId="esign-modal-checkbox2"
              checked={witnessCheck2}
              onChange={setWitnessCheck2}
              isError={!witnessCheck2 && isSubmitted}
              style={{ fontSize: 16 }}
            >
              {t('readyToSignModal.inPhysicalPresence', `Yes, my witnesses are in my physical presence`)}
            </CheckboxText>
          </div>
          {isSubmitted && witnessCheck1 && !witnessCheck2 && (
            <ErrorContent>{t('readyToSignModal.checkToProceed', `Please check the box to proceed.`)}</ErrorContent>
          )}
          {showExtraText && (
            <Text style={{ fontWeight: 600, marginTop: 24 }}>
              {t(
                'readyToSignModal.notReadyToSign?',
                `Not ready to sign? You can share your document by selecting the Sign Later button.`
              )}
            </Text>
          )}
          {/* </Padding> */}
        </ModalBody>
        <ButtonArea>
          <Button onClick={handleCancel} grey style={{ width: 140 }}>
            {onCloseText}
          </Button>
          <Button
            onClick={() => {
              if (!witnessCheck1 || !witnessCheck2) {
                setIsSubmitted(true)
                return
              }
              // log event
              logActivityItem({
                variables: {
                  key: ActivityLogKeyEnum.ReadyForEsign,
                  userId: currentUser?.id || '',
                  patientId: patient?.id || '',
                  content: `Patient has clicked "I'm Ready" for eSign`,
                },
              })
              onReady()
            }}
            id="ready-to-sign-modal"
            data-testid="ready-to-sign-modal"
            style={{ minWidth: 140 }}
          >
            {t('readyToSignModal.submitButton', `I'm ready`)}
          </Button>
        </ButtonArea>
      </Modal>
    </ModalOverlay>
  )
}
