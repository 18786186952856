import { Trans } from 'react-i18next'
import styled from 'styled-components'

import personFilled from 'lib/media/icons/person-filled.svg'
import personUnfilled from 'lib/media/icons/person-unfilled.svg'
import QuestionText from 'components/text/QuestionText'
import 'lib/helpers/i18n'
import i18n from 'lib/helpers/i18n'

const AnswerText = styled(QuestionText)`
  font-size: 18px;
  line-height: 125%;
  margin-bottom: 16px;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    font-size: 16px;
  }
`

export default function getAgeRange(age: number) {
  if (age < 60) {
    return i18n.t('cprAgeGroupVideo.getAgeRangeUnder60', 'under 60')
  }
  if (age >= 60 && age <= 69) {
    return '60-69'
  }
  if (age >= 70 && age <= 79) {
    return '70-79'
  }
  if (age >= 80 && age <= 89) {
    return '80-89'
  }

  if (age >= 90) {
    return '90+'
  }
}

const UserIcon = ({ filled }: { filled?: boolean }) => (
  <img height="32" alt="User" src={filled ? personFilled : personUnfilled} />
)

const CprUsersContainer = styled.div<{ width?: number }>`
  display: flex;
  width: ${({ width }) => width || 380}px;
  margin: auto;
  justify-content: space-between;
  max-width: 100%;
  margin-bottom: 24px;
`

export const getCprTextByAgeRange = () => {
  return {
    [i18n.t('cprAgeGroupVideo.getAgeRangeUnder60', 'under 60')]: (
      <>
        <AnswerText>
          <Trans>{i18n.t('cprAgeGroupVideo.forPersonUnder60part1')}</Trans>
        </AnswerText>
        <AnswerText>
          <Trans>{i18n.t('cprAgeGroupVideo.forPersonUnder60part2')}</Trans>
        </AnswerText>
      </>
    ),
    '60-69': (
      <>
        <AnswerText>
          <Trans>{i18n.t('cprAgeGroupVideo.forPerson60_69part1')}</Trans>
        </AnswerText>
        <AnswerText>
          <Trans>{i18n.t('cprAgeGroupVideo.forPerson60_69part2')}</Trans>
        </AnswerText>
      </>
    ),
    '70-79': (
      <>
        <AnswerText>
          <Trans>{i18n.t('cprAgeGroupVideo.forPerson70_79part1')}</Trans>
        </AnswerText>
        <AnswerText>
          <Trans>{i18n.t('cprAgeGroupVideo.forPerson70_79part2')}</Trans>
        </AnswerText>
      </>
    ),
    '80-89': (
      <>
        <AnswerText>
          <Trans>{i18n.t('cprAgeGroupVideo.forPerson80_89part1')}</Trans>
        </AnswerText>
        <AnswerText>
          <Trans>{i18n.t('cprAgeGroupVideo.forPerson80_89part2')}</Trans>
        </AnswerText>
      </>
    ),
    '90+': (
      <>
        <AnswerText>
          <Trans>{i18n.t('cprAgeGroupVideo.forPersonAbove90part1')}</Trans>
        </AnswerText>
        <AnswerText>
          <Trans>{i18n.t('cprAgeGroupVideo.forPersonAbove90part2')}</Trans>
        </AnswerText>
      </>
    ),
  }
}

export const getCprUsers = () => {
  return {
    [i18n.t('cprAgeGroupVideo.getAgeRangeUnder60', 'under 60')]: (
      <CprUsersContainer width={230}>
        <UserIcon filled />
        <UserIcon />
        <UserIcon />
        <UserIcon />
      </CprUsersContainer>
    ),
    '60-69': (
      <CprUsersContainer width={280}>
        <UserIcon filled />
        <UserIcon />
        <UserIcon />
        <UserIcon />
        <UserIcon />
      </CprUsersContainer>
    ),
    '70-79': (
      <CprUsersContainer>
        <UserIcon filled />
        <UserIcon />
        <UserIcon />
        <UserIcon />
        <UserIcon />
      </CprUsersContainer>
    ),
    '80-89': (
      <CprUsersContainer>
        <UserIcon filled />
        <UserIcon />
        <UserIcon />
        <UserIcon />
        <UserIcon />
        <UserIcon />
        <UserIcon />
      </CprUsersContainer>
    ),
    '90+': (
      <CprUsersContainer>
        <UserIcon filled />
        <UserIcon />
        <UserIcon />
        <UserIcon />
        <UserIcon />
        <UserIcon />
        <UserIcon />
        <UserIcon />
        <UserIcon />
      </CprUsersContainer>
    ),
  }
}

export const getCprSurvivalRates = () => {
  return {
    [i18n.t('cprAgeGroupVideo.getAgeRangeUnder60', 'under 60')]: '28%',
    '60-69': '22%',
    '70-79': '19%',
    '80-89': '15%',
    '90+': '12%',
  }
}

export const getCprStatsByAgeRange = () => {
  return {
    [i18n.t('cprAgeGroupVideo.getAgeRangeUnder60', 'under 60')]: (
      <span>
        <Trans>{i18n.t('cprAgeGroupVideo.forPersonUnder60')}</Trans>
      </span>
    ),
    '60-69': (
      <span>
        <Trans>{i18n.t('cprAgeGroupVideo.forPersonSurvive60_69')}</Trans>
      </span>
    ),
    '70-79': (
      <span>
        <Trans>{i18n.t('cprAgeGroupVideo.forPersonSurvive70_79')}</Trans>
      </span>
    ),
    '80-89': (
      <span>
        <Trans>{i18n.t('cprAgeGroupVideo.forPersonSurvive80_89')} </Trans>
      </span>
    ),
    '90+': (
      <span>
        <Trans>{i18n.t('cprAgeGroupVideo.forPersonSurvive90')}</Trans>
      </span>
    ),
  }
}
