import { useEffect, useState } from 'react'
import * as FullStory from '@fullstory/browser'
import config from 'lib/config'

export default function useFullStory() {
  const [loaded, setLoaded] = useState(false)
  useEffect(() => {
    if (!loaded) {
      setLoaded(true)
      FullStory.init({
        orgId: config.FULLSTORY_ORD_ID,
        debug: config.NODE_ENV !== 'production',
      })
    }
  }, [loaded])
}
