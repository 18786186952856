import styled from 'styled-components';

// STYLE-COMPONENTS
// ===================================
const Container = styled.div<{ alignItems?: string }>`
  min-height: 100%;
  display: flex;
  align-items: ${(p) => p.alignItems || 'center'};
  justify-content: center;
  width: 100%;
  max-width: 100%;
  position: relative;
`;

// EXPORT
// ===================================
interface PublicLayoutProps {
  children: string | JSX.Element;
  alignItems?: string;
}

export default function PublicLayout({
  children,
  alignItems,
}: PublicLayoutProps) {
  return (
    <Container id="public-layout" alignItems={alignItems}>
      {children}
    </Container>
  );
}
