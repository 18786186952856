import FaxCoverLetter from '../KodaContent/FaxCoverLetter'
import { Page, Document } from '@react-pdf/renderer'
import { GuideQuestionIdEnum } from 'generated/graphql'
import CoverPage from './CoverPage'
import SessionSummary from '../KodaContent/SessionSummary'
import { AdPartEnum } from './plan-settings'
import { PlanSettingPart } from './plan-settings/types'
import MedicalDecisionMaker from './MedicalDecisionMaker'
import ESignCertificate from '../KodaContent/ESignCertificate'
import SignTheForm from './SignTheForm'
import FaqsAndLegal from './FaqsAndLegal'
import HealthCareChoices from './HealthCareChoices'
import i18n from 'lib/helpers/i18n'
import useGetPlanSettings from 'lib/hooks/useGetPlanSettings'
import PlanSectionDocument from '../PlanSectionDocument'
import flatten from 'lib/helpers/flatten'

// will return true if there is another part after this one
const hasNextPart = (partIds: string[], part: AdPartEnum, patientSignatureDate?: number): boolean => {
  if (patientSignatureDate) return true
  const index = partIds?.findIndex((val) => val === part)
  return index !== -1 && index < partIds.length - 1
}

const getPartTitle = (parts: PlanSettingPart[], partId: AdPartEnum): string | undefined => {
  return parts?.find((part) => part.id === partId?.toString())?.name
}

export default function MasterPlanDoc({
  data,
  title,
  questions,
  createdStamp,
  includeCoverLetter = false,
  clinicName,
  faxNumber,
  hasSupportiveCare,
  version, // the state abbrev e.g. "NH" or "TX"
}) {
  const getPlanSettings = useGetPlanSettings()
  const settings = getPlanSettings(data)
  const questionIds = flatten(data?.guideTemplate?.sections?.map((section) => section.questionIds))

  const partIds = settings?.parts?.map(({ id }) => id)
  const name = `${i18n.t('MasterPlanDoc.name')} ${data.firstName} ${data.lastName}`
  const pageFooterRight = `${i18n.t('DocPage.lastUpdated', 'Last updated: ')} ${createdStamp}`
  const fileName = `${data?.hasOfficialPlan ? 'Official' : 'Unofficial'}-KodaCarePlan.pdf`

  return (
    <Document title={title}>
      {includeCoverLetter && (
        <Page>
          <FaxCoverLetter
            faxNumber={faxNumber}
            clinicName={clinicName}
            patientName={`${data.firstName} ${data.lastName}`}
            birthday={data.birthday as string}
            patientCreatedAt={data.createdAt as string}
            filename={fileName}
          />
        </Page>
      )}
      {questionIds?.includes(GuideQuestionIdEnum.AffordMedications) && (
        <PlanSectionDocument
          pageTitleLeft={i18n.t('MasterPlanDoc.copyForProvider', 'Copy for Provider')}
          pageTitleRight={name}
          content={<SessionSummary data={data} />}
          showFooter
          pageFooterRight={createdStamp}
        />
      )}
      <PlanSectionDocument
        pageTitleRight={name}
        content={<CoverPage settings={settings} />}
        showFooter
        pageFooterRight={pageFooterRight}
      />
      {partIds?.includes(AdPartEnum.health_care_choices) && (
        <HealthCareChoices
          data={data}
          questions={questions}
          name={name}
          hasSupportiveCare={hasSupportiveCare}
          createdStamp={createdStamp}
          pageTitle={getPartTitle(settings?.parts, AdPartEnum.health_care_choices)}
        />
      )}
      {partIds?.includes(AdPartEnum.medical_decision_maker) && (
        <MedicalDecisionMaker
          settings={settings}
          data={data}
          questions={questions}
          createdStamp={createdStamp}
          pageTitle={getPartTitle(settings?.parts, AdPartEnum.medical_decision_maker)}
        />
      )}
      {partIds?.includes(AdPartEnum.sign_the_form) && (
        <SignTheForm
          settings={settings}
          data={data}
          name={name}
          hasNextPart={hasNextPart(partIds, AdPartEnum.sign_the_form, data?.patientSignatureDate)}
          pageTitle={getPartTitle(settings?.parts, AdPartEnum.sign_the_form)}
          pageFooterRight={pageFooterRight}
        />
      )}
      {partIds?.includes(AdPartEnum.faqs_and_legal) && (
        <FaqsAndLegal
          data={data}
          name={name}
          version={version}
          createdStamp={createdStamp}
          settings={settings}
          pageTitle={getPartTitle(settings?.parts, AdPartEnum.faqs_and_legal)}
        />
      )}
      {data?.patientSignatureDate && (
        <PlanSectionDocument
          pageTitleLeft={`${i18n.t('MasterPlanDoc.part', 'Part ')} ${partIds?.length + 1}`}
          pageTitleRight={name}
          content={<ESignCertificate data={data} />}
          showFooter
          pageFooterRight={createdStamp}
        />
      )}
    </Document>
  )
}
