import { FC, ReactElement } from "react";
import { useTranslation } from 'react-i18next';
import styled from "styled-components";

import Button from 'components/common/Button';
import ArrowLeftOutlined from 'components/icons/ArrowLeftOutlined';

const AppSurveyFooter = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 86px;
  background-color: #F5F7FA;
  padding: 0 15px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  @media only screen and (min-width: 1025px) {
    background-color: transparent;
    justify-content: flex-start;
    position: relative;
  }
`;

const AppSurveyFooterBackButton = styled.button`
  border: none;
  background: transparent;
  cursor: pointer;
  @media only screen and (min-width: 1025px) {
    display: none;
  }
`;

const AppSurveyFooterBackButtonContainer = styled.div`
  svg {
    margin-right: 10px;
  }
  @media only screen and (max-width: 1025px) {
    display: none;
  }
`;

const AppSurveyFooterNextButtonContainer = styled.div`
  @media only screen and (min-width: 1025px) {
    display: none;
  }
`;
  

interface SectionFooterProps {
  goBack: () => void;
  goNext: () => void;
  loading?: boolean;
  disabled?: boolean;
}

const SectionFooter: FC<SectionFooterProps> = ({ goBack, goNext, loading, disabled }): ReactElement => {
  const { t } = useTranslation();

  return (
    <AppSurveyFooter>
      <AppSurveyFooterBackButton onClick={goBack}>
        <ArrowLeftOutlined />
      </AppSurveyFooterBackButton>
      <AppSurveyFooterBackButtonContainer>
        <Button
          disabled={loading}
          onClick={goBack}
          grey
          secondary
        >
          <ArrowLeftOutlined />
          <span>{t("button.back", "Back")}</span>
        </Button>
      </AppSurveyFooterBackButtonContainer>
      <AppSurveyFooterNextButtonContainer>
        {!disabled && (
          <Button
            loading={loading}
            disabled={loading}
            onClick={goNext}
            pulse
          >
            {t("button.continue", "Continue")}
          </Button>
        )}
      </AppSurveyFooterNextButtonContainer>
      
    </AppSurveyFooter>
  );
}

export default SectionFooter;
