// TOP LEVEL IMPORTS
import { UserProfile } from 'generated/graphql'
import { Route, Redirect } from 'react-router-dom'

interface ProtectedRouteProps {
  currentUser?: UserProfile
  path?: string[] | string
  noNav?: boolean
  noFooter?: boolean
  location?: string
  alignItems?: string
  isAllowed?: boolean
  component?: any
  layout?: any
  exact?: boolean
}

export default function ProtectedRoute(props: ProtectedRouteProps) {
  const {
    currentUser,
    path,
    noNav,
    noFooter,
    location,
    isAllowed,
    component: Component,
    layout: Layout,
    ...rest
  } = props

  // if the user is not logged in, send them to the login page
  if (!currentUser?.id) {
    return <Redirect to="/login" />
  }

  return (
    <Route
      {...rest}
      path={path}
      render={(args) => (
        <div style={{ height: '100%' }}>
          <Layout {...args} {...props}>
            <Component {...args} {...props} />
          </Layout>
        </div>
      )}
    />
  )
}
