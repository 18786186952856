import s from '../../KodaContent/styles'
import { Text, View } from '@react-pdf/renderer'
import TextField from '../../KodaContent/TextField'
import { AdPartEnum, PlanSetting } from '../plan-settings'
import i18n from 'lib/helpers/i18n'

// // patient
// patientSignatureFirstName?: string;
// patientSignatureLastName?: string;
// patientSignatureDate?: string;
// // witness 1
// witness1SignatureFirstName?: string;
// witness1SignatureLastName?: string;
// witness1SignatureEmail?: string;
// witness1SignatureDate?: string;
// // witness 2
// witness2SignatureFirstName?: string;
// witness2SignatureLastName?: string;
// witness2SignatureEmail?: string;
// witness2SignatureDate?: string;

export default function NotaryPage({ settings }: { settings: PlanSetting }) {
  const part = settings?.parts?.find(({ id }) => id === AdPartEnum.sign_the_form)

  return (
    <View break>
      <View style={s.sectionContainer}>
        <Text style={s.sectionTitle}>{i18n.t('MasterPlanDocNotaryPage.notary', 'Notary')}</Text>
        <Text style={s.textBold}>{part?.notary?.disclaimer}</Text>
        <Text style={s.text}>{i18n.t('MasterPlanDocNotaryPage.county', 'County of ________________________')}</Text>
        <Text style={s.text}>
          {i18n.t(
            'MasterPlanDocNotaryPage.notaryText',
            `
          Before me, a Notary Public, on this day personally appeared _______________________________ (name of person
          making acknowledgement), known to me [or proved to me on the oath of __________________________(name of
          identifying witness), or through ___________________ (description of identity card or other document)], to be
          the person whose name is subscribed to the forgoing instrument and who acknowledged to me that he/she executed
          the same for the purpose and consideration expressed herein.
        `
          )}
        </Text>
        <Text style={s.text}>
          {i18n.t(
            'MasterPlanDocNotaryPage.notaryDate',
            'Given under my hand and seal of office this ______day of ___________20_______'
          )}
        </Text>
        <View style={{ display: 'flex', flexDirection: 'row' }}>
          <TextField label={i18n.t('MasterPlanDocNotaryPage.notaryPublicSignature', 'Notary Public’s Signature')} />
          <View style={{ flex: 3 }} />
        </View>
        <View style={{ display: 'flex', flexDirection: 'row' }}>
          <View style={{ flex: 3 }} />
          <TextField label={i18n.t('MasterPlanDocNotaryPage.myTypePrint', 'My Type Print')} />
        </View>
        <View style={{ display: 'flex', flexDirection: 'row' }}>
          <View style={{ flex: 3 }} />
          <TextField label={i18n.t('MasterPlanDocNotaryPage.myCommissionExpires', 'My commission expires')} />
        </View>
      </View>
    </View>
  )
}
