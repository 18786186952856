import { FC, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { Patient, PostAcpDigitalAdvanceCarePlanningEnum } from 'generated/graphql'

import Button from 'components/common/Button'
import useSavePatient from 'lib/hooks/useSavePatient'
import usePrevious from 'lib/hooks/usePrevious'
import Loading from 'components/common/Loading'
import SectionFooter from 'routes/app-survey/SectionFooter'
import SectionHeader from 'routes/app-survey/SectionHeader'
import SelectBlock from 'routes/app-survey/step1/SelectBlock'
import { AfterSaveAction } from 'routes/app-survey/step1'
import getPostSurveyQuestions from 'lib/helpers/getPostSurveyQuestions'

const Step1Styled = styled.div`
  background-color: white;
  flex: 1;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    padding-bottom: 100px;
  }
`

const Step1Body = styled.div`
  max-width: 600px;
  margin: auto;
  padding: 48px 10px;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.mediumSmall}) {
    padding: 24px;
  }
`

const Step1Title = styled.h1`
  font-weight: 400;
  font-size: 18px;
  color: #1f2933;
`

export const Step1Continue = styled.div`
  display: none;
  margin-top: 20px;
  @media only screen and (min-width: 1025px) {
    display: block;
  }
`

interface Step1Props {
  patient?: Patient | null
}

interface Plan {
  postAcpDigitalAdvanceCarePlanning: PostAcpDigitalAdvanceCarePlanningEnum | null
}

const Step1: FC<Step1Props> = ({ patient }) => {
  const [afterSaveAction, setAfterSaveAction] = useState<AfterSaveAction>()
  const [plan, setPlan] = useState<Plan>({
    postAcpDigitalAdvanceCarePlanning: null,
  })

  const { onSavePatient, saving } = useSavePatient()

  const { t } = useTranslation()
  const history = useHistory()

  const savingPrev = usePrevious(saving)

  const questions = patient ? getPostSurveyQuestions(patient) : undefined

  const save = async () => {
    if (patient) {
      await onSavePatient({
        ...patient,
        postAcpDigitalAdvanceCarePlanning:
          plan.postAcpDigitalAdvanceCarePlanning as PostAcpDigitalAdvanceCarePlanningEnum,
      })
    }
  }

  const goNext = () => {
    setAfterSaveAction(AfterSaveAction.NEXT)
    save()
  }

  const onSelect = (value, fieldName) => {
    setPlan({
      ...plan,
      [fieldName]: value,
    })
  }

  useEffect(() => {
    // set patient values every patient change
    if (patient) {
      setPlan({
        postAcpDigitalAdvanceCarePlanning: patient?.postAcpDigitalAdvanceCarePlanning || null,
      })
    }
  }, [patient])

  useEffect(() => {
    // Go to step 2 on saving end and if patient is truthy
    if (savingPrev && !saving && patient && afterSaveAction) {
      switch (afterSaveAction) {
        case AfterSaveAction.NEXT: {
          history.push('/app/post-survey?step=2')
          break
        }
        case AfterSaveAction.HOME: {
          history.push('/app/home')
          break
        }
        case AfterSaveAction.BACK: {
          history.push('/app/home')
          break
        }
        default: {
          return
        }
      }
    }
  }, [history, savingPrev, saving, patient, afterSaveAction])

  const disabled = !plan?.postAcpDigitalAdvanceCarePlanning

  return (
    <>
      <Step1Styled>
        <SectionHeader
          sectionName={t('planConfigurator.POST_SURVEY', 'Post-ACP Survey')}
          progress={'0'}
          onClick={() => {
            setAfterSaveAction(AfterSaveAction.HOME)
            save()
          }}
        />
        {saving && <Loading height={80} />}
        <Step1Body>
          <Step1Title>{questions?.step1?.title || ''}</Step1Title>
          {questions?.step1?.questions.map((question, index) => (
            <SelectBlock
              key={index}
              title=""
              subtitle=""
              items={Object.keys(question.enum)}
              hash={question.answer}
              active={plan[question.field]}
              onSelect={(item: string) => onSelect(item, question.field)}
            />
          ))}
          {!disabled && (
            <Step1Continue>
              <Button loading={saving} disabled={saving} onClick={goNext} pulse>
                {t('button.continue')}
              </Button>
            </Step1Continue>
          )}
        </Step1Body>
      </Step1Styled>
      <SectionFooter
        goBack={() => {
          setAfterSaveAction(AfterSaveAction.BACK)
          save()
        }}
        goNext={goNext}
        loading={saving}
        disabled={disabled}
      />
    </>
  )
}

export default Step1
