import { AdPartEnum, PlanSetting } from '../types'
import en from 'lib/helpers/locales/en/translation.json'
import { Text } from '@react-pdf/renderer'
import s from '../../../KodaContent/styles'
import { Trans } from 'react-i18next'

export default function getAR(t, defaultPartSettings): PlanSetting {
  const ar: PlanSetting = {
    state: 'Arkansas',
    disclaimer: t(
      'AR.disclaimer',
      'Disclaimer: This document needs to be signed and have two witnesses sign as well or be notarized in order to be a legal document.'
    ),
    parts: [
      // Part 1
      {
        ...defaultPartSettings?.[AdPartEnum.health_care_choices],
      },
      // Part 2
      {
        ...defaultPartSettings?.[AdPartEnum.medical_decision_maker],
      },
      // Part 3
      {
        ...defaultPartSettings?.[AdPartEnum.sign_the_form],
        description: t(
          'AR.sign_the_form.description',
          'You can fill out Part 1, Part 2, or both, but you need to sign the form in Part 3. To be legal, two witnesses also need to sign or a notary.'
        ),
        beforeYouSign: {
          requirements: [
            t(
              'planDoc.sign_the_form.beforeYouSignRequirements_age',
              en.planDoc.sign_the_form.beforeYouSignRequirements_age
            ),
            t(
              'planDoc.sign_the_form.beforeYouSignRequirements_notary',
              en.planDoc.sign_the_form.beforeYouSignRequirements_notary
            ),
            t(
              'planDoc.sign_the_form.beforeYouSignRequirements_witnesses',
              en.planDoc.sign_the_form.beforeYouSignRequirements_witnesses
            ),
          ],
          finalClause: t(
            'planDoc.sign_the_form.beforeYouSignFinalClause',
            'This Advanced Directive accurately expresses my wishes.'
          ),
        },
        witness1SignatureText: (
          <Text style={s.formLabel}>
            <Trans i18nKey="AR.sign_the_form.witness1SignatureText">
              By signing this document, I attest that I meet the witness requirements under Arkansas law.
              <Text style={{ ...s.textBold, fontSize: 11 }}>I am a competent adult who is not named as the agent.</Text>
              I witnessed the patient’s signature on this form.
            </Trans>
          </Text>
        ),
        witness2SignatureText: (
          <Text style={s.formLabel}>
            <Trans i18nKey="AR.sign_the_form.witness2SignatureText">
              By signing this document, I attest that I meet the witness requirements under Arkansas law.
              <Text style={{ ...s.textBold, fontSize: 11 }}>
                I am a competent adult who is not named as the agent. I am not related to the patient by blood,
                marriage, or adoption and I would not be entitled to any portion of the patient’s estate upon his or her
                death under any existing will or codicil or by operation of law.
              </Text>
              I witnessed the patient’s signature on this form.
            </Trans>
          </Text>
        ),

        // t(
        //   'AR.sign_the_form.witness1SignatureText',
        //   en.AR.sign_the_form.witness1SignatureText
        // ),
        // witness2SignatureText: t(
        //   'AR.sign_the_form.witness2SignatureText',
        //   en.AR.sign_the_form.witness2SignatureText
        // ),
        witnessRequirements: {
          title: t('planDoc.sign_the_form.witnessRequirements_title', 'Witnesses or Notary Requirements'), // OR "Witness and Notary Requirements"
          requirements: [
            {
              title: t(
                'planDoc.sign_the_form.witnessRequirements_bothWitnesses',
                en.planDoc.sign_the_form.witnessRequirements_bothWitnesses
              ),
              width: '50%',
              phrases: [
                t('planDoc.sign_the_form.witnessRequirements_be18', en.planDoc.sign_the_form.witnessRequirements_be18),
                t(
                  'planDoc.sign_the_form.witnessRequirements_seeYouSign',
                  en.planDoc.sign_the_form.witnessRequirements_seeYouSign
                ),
              ],
            },
            {
              title: t(
                'planDoc.sign_the_form.witnessRequirements_yourWitnessCannot',
                en.planDoc.sign_the_form.witnessRequirements_yourWitnessCannot
              ),
              width: '50%',
              phrases: [
                t(
                  'planDoc.sign_the_form.witnessRequirements_beYourMdm',
                  en.planDoc.sign_the_form.witnessRequirements_beYourMdm
                ),
              ],
            },
            {
              title: t(
                'planDoc.sign_the_form.witnessRequirements_atLeastOneWitnessCannot',
                en.planDoc.sign_the_form.witnessRequirements_atLeastOneWitnessCannot
              ),
              width: '100%',
              phrases: [
                t(
                  'planDoc.sign_the_form.witnessRequirements_beRelated',
                  en.planDoc.sign_the_form.witnessRequirements_beRelated
                ),
                t(
                  'planDoc.sign_the_form.witnessRequirements_beEntitledToEstate',
                  en.planDoc.sign_the_form.witnessRequirements_beEntitledToEstate
                ),
              ],
            },
          ],
          validityRequirements: [
            t(
              'planDoc.sign_the_form.validityRequirements_esignInThePresence',
              en.planDoc.sign_the_form.validityRequirements_esignInThePresence
            ),
            t(
              'planDoc.sign_the_form.validityRequirements_prinatAndSignBeforeTwoWitnessesOr',
              en.planDoc.sign_the_form.validityRequirements_prinatAndSignBeforeTwoWitnessesOr
            ),
            t(
              'planDoc.sign_the_form.validityRequirements_prinatAndSignBeforeNotary',
              en.planDoc.sign_the_form.validityRequirements_prinatAndSignBeforeNotary
            ),
          ],
        },
        notary: {},
      },
    ],
  }

  return ar
}
