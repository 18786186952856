import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { AnalyticEventTypeEnums } from 'lib/types/AnalyticEventTypeEnums'
import logEvent from 'lib/helpers/logEvent'

export default function useLogRouteChange() {
  let location = useLocation()

  useEffect(() => {
    // log page change
    logEvent(AnalyticEventTypeEnums.PAGE_VIEW, {
      url: location.pathname,
      query: window?.location?.search,
    } as {})
  }, [location])
}
