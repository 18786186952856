import styled from 'styled-components'
import { RouteComponentProps } from 'react-router-dom'
// HOOKS
import useDownloadPdf from 'lib/hooks/useDownloadPdf'
// COMPONENTS
import Plan from './Plan'
// LIB
import theme from 'lib/theme'
import disagreeSVG from 'lib/media/icons/disagree.svg'
import constants from 'lib/config'
import LoadingOutlined from 'components/icons/LoadingOutlined'
import Attachment from './Attachment'
import { ClockCircleOutlined } from '@ant-design/icons'
import queryString from 'query-string'
// GRAPHQL
import { useGetOnePatientQuery, UserProfile } from 'generated/graphql'
import i18n from 'lib/helpers/i18n'
import en from 'lib/helpers/locales/en/translation.json'

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
  max-width: 100%;
  width: 670px;
  margin: auto;
`

const ErrorImg = styled.img`
  height: 100px;
`

const Title = styled.h1`
  font-size: 24px;
  margin-top: 16px;
  margin-bottom: 0px;
  font-family: ${(p) => p.theme.fontFamily};
`

const Caption = styled.h2`
  font-size: 18px;
  color: ${(p) => p.theme.colors.neutral4};
  margin-top: 0px;
  font-weight: 400 !important;
  font-family: ${(p) => p.theme.fontFamily};
`

const Loading = () => (
  <Container>
    <div>
      <LoadingOutlined style={{ fontSize: 32, color: theme.colors.primary6, marginBottom: 24 }} />
    </div>
  </Container>
)

const ErrorBlock = ({
  title = i18n.t('PublicDownloadPdf.ErrorBlockTitle', `Something went wrong...`),
  img = <ErrorImg src={disagreeSVG} alt="error-icon" />,
  caption = (
    <Caption>
      {i18n.t('PublicDownloadPdf.expired', en.PublicDownloadPdf.expired)} <strong>{constants.supportEmail}</strong>
    </Caption>
  ),
}) => (
  <Container>
    <div>
      {img}
      <Title>{title}</Title>
      {caption}
    </div>
  </Container>
)

const CantFind = () => (
  <Container>
    <div>
      <ErrorImg src={disagreeSVG} alt="error-icon" />
      <Title>{i18n.t('PublicDownloadPdf.CantFindTitle', `We can't find your plan...`)}</Title>
      <Caption>
        {i18n.t('PublicDownloadPdf.CantFindSubtitle', en.PublicDownloadPdf.expired)}
        <strong>{constants.supportEmail}</strong>
      </Caption>
    </div>
  </Container>
)

export const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    position: fixed;
    bottom: 16px;
    right: 16px;
    z-index: 1000;
  }
`

export const ClickableText = styled.button`
  border: 0px;
  padding: 0px;
  background: transparent;
  cursor: pointer;
  text-decoration: underline;
  font-size: 18px;
  margin-top: 16px;
  color: ${({ theme }) => theme.colors.neutral5};
`

export const Text = styled.div`
  line-height: 140%;
  font-size: 18px;
  color: ${(p) => p.theme.colors.neutral1};
  a {
    font-size: 18px;
  }
`

interface PublicDownloadPdfProps {
  match: RouteComponentProps<{ token?: string }>
  location: {
    search: string
  }
  currentUser: UserProfile
}

export default function PublicDownloadPdf({ match, location, currentUser }: PublicDownloadPdfProps) {
  const { downloadPdf, downloading } = useDownloadPdf()
  const { fileId } = queryString.parse(location.search)
  const { loading, error, data } = useGetOnePatientQuery({
    variables: {
      token: match.params && match.params.token,
    },
    skip: fileId ? true : false,
  })

  if (fileId) {
    return <Attachment fileId={fileId as string} token={match.params.token} currentUser={currentUser} />
  }

  if (!match.params.token) {
    return <CantFind />
  }

  if (loading) return <Loading />

  if (error?.graphQLErrors?.[0]?.extensions?.code === 'EXPIRED_TOKEN') {
    return (
      <ErrorBlock
        img={<ClockCircleOutlined style={{ color: theme.colors.neutral7, fontSize: 56 }} />}
        title="This link has expired..."
        caption={
          <Caption>
            {i18n.t('PublicDownloadPdf.expired', en.PublicDownloadPdf.expired)}
            <strong>{constants.supportEmail}</strong>
          </Caption>
        }
      />
    )
  }

  if (error) return <ErrorBlock />

  if (!data?.getOnePatient) return <CantFind />

  return (
    <Plan
      data={data.getOnePatient}
      onDownload={() => {
        downloadPdf(data.getOnePatient)
      }}
      downloading={downloading}
    />
  )
}
