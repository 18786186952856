import { AdPartEnum, PlanSetting } from '../types'

export default function getPA(t, defaultPartSettings): PlanSetting {
  const pa: PlanSetting = {
    state: 'Pennsylvania',
    disclaimer: t(
      'PA.disclaimer',
      `Disclaimer: This document needs to be signed and have two witnesses sign as well in order to be a legal document.`
    ),
    parts: [
      // Part 1
      {
        ...defaultPartSettings?.[AdPartEnum.health_care_choices],
        description: t(
          'PA.health_care_choices.description',
          'This part is also known as a Living Will. It expresses the kind of treatment you would want in a medical crisis.'
        ),
      },
      // Part 2
      {
        ...defaultPartSettings?.[AdPartEnum.medical_decision_maker],
        description: t(
          'PA.medical_decision_maker.description',
          'This part is also known as a Health Care Power of Attorney. It designates a person to make decisions for you when you are unable to speak for yourself.'
        ),
        pageTitle: t(
          'PA.medical_decision_maker.pageTitle',
          'Information for Medical Decision Maker (Health Care Power of Attorney)'
        ),
        pageTitle2: t('PA.medical_decision_maker.pageTitle2', 'Medical Decision Maker (Health Care Power of Attorney)'),
      },
      {
        ...defaultPartSettings?.[AdPartEnum.sign_the_form],
        description: t(
          'PA.sign_the_form.description',
          'You can fill out Part 1, Part 2, or both, but you need to sign the form in Part 3 and read Part 4. To be legal, two witnesses also need to sign.'
        ),
        beforeYouSign: {
          requirements: [
            t(
              'PA.sign_the_form.witnessRequirements_PaRequirement',
              'sign this form if you are either (1) 18 years of age or older, (2) have graduated from high school, (3) are married, or (4) are an emancipated minor; and'
            ),
            t('planDoc.sign_the_form.beforeYouSignRequirements_witnesses'),
          ],
          finalClause: t('planDoc.sign_the_form.beforeYouSignFinalClause'),
        },
        witnessRequirements: {
          title: t('planDoc.sign_the_form.witnessRequirements_title', 'Witness Requirements'),
          requirements: [
            {
              title: t('planDoc.sign_the_form.witnessRequirements_yourWitnessMust'),
              phrases: [
                t('planDoc.sign_the_form.witnessRequirements_be18'),
                t('planDoc.sign_the_form.witnessRequirements_seeYouSign', 'see you sign the form'),
                t('planDoc.sign_the_form.witnessRequirements_bePresent'),
              ],
            },
            {
              title: t('planDoc.sign_the_form.witnessRequirements_yourWitnessCannot'),
              phrases: [
                t('PA.sign_the_form.witnessRequirements_atDirectionOfPrincipal'),
                t('PA.sign_the_form.witnessRequirements_provideHealthcareToPrincipal'),
              ],
            },
          ],
          validityRequirements: [
            t('planDoc.sign_the_form.validityRequirements_esignInThePresence'),
            t('planDoc.sign_the_form.validityRequirements_printAndSign'),
          ],
        },
      },
      {
        ...defaultPartSettings?.[AdPartEnum.faqs_and_legal],
      },
    ],
  }

  return pa
}
