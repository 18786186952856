import MdmInfo from './MdmInfo'
import s from '../styles'
import { PatientFragmentFragment, GuideQuestionIdEnum, Patient } from 'generated/graphql'
import { Text, View } from '@react-pdf/renderer'
import { QuestionItem } from 'lib/types/QuestionItem'
import AnswerLine from '../AnswerLine'
import Checkbox from '../Checkbox'
import docHelpers from '../helpers'
import getMdMRelationshipLabel from 'lib/helpers/getMdMRelationshipLabel'
import BulletText from '../BulletText'
import theme from 'lib/theme'
import TextField from '../TextField'
import i18n from 'lib/helpers/i18n'
import { Trans } from 'react-i18next'
import en from 'lib/helpers/locales/en/translation.json'
import { PlanSettingPart } from '../../MasterPlanDoc/plan-settings/types'

interface MedicalDecisionMakerContactFieldsProps {
  data?: Patient
  questions?: QuestionItem[]
  showSignatures?: boolean
  pageTitle?: string
  part: PlanSettingPart | undefined
}

const SignatureBlock = ({
  decisionMaker = i18n.t(
    'MasterPlanDocMedicalDecisionMakerContactFields.medicalDecisionMaker',
    'medical decision maker'
  ),
}) => {
  const STYLE = {
    marginTop: 2,
    borderTopWidth: 2,
  }

  return (
    <View
      wrap={false}
      style={{
        borderWidth: 1,
        borderColor: theme.colors.primary3,
        borderRadius: 5,
        padding: 0,
        paddingRight: 4,
        paddingLeft: 4,
        paddingBottom: 2,
        marginTop: 6,
        marginBottom: 12,
      }}
    >
      <Text style={{ ...s.textBold, fontSize: 10, marginBottom: 3, marginTop: 2 }}>
        <Trans i18nKey={'MasterPlanDocSignatureBlock.label1'}>
          Your {{ decisionMaker }} needs to accept this role by signing below.
        </Trans>
      </Text>
      <Text style={{ ...s.formLabel, marginBottom: 0 }}>
        {i18n.t('MasterPlanDocSignatureBlock.i', 'I, ')}
        <Text style={{ color: theme.colors.neutral8 }}>_____________________________________________</Text>
        {i18n.t('MasterPlanDocSignatureBlock.agree', ', agree to serve as ')}
        {decisionMaker}.
      </Text>
      <View style={{ display: 'flex', flexDirection: 'row' }}>
        <TextField flex={2} label={i18n.t('MasterPlanDocSignatureBlock.signYourName')} style={STYLE} />
        <TextField label={i18n.t('MasterPlanDocSignatureBlock.date')} style={STYLE} />
      </View>
    </View>
  )
}

export const MedicalDecisionMakerContactFields = ({
  data,
  questions,
  showSignatures,
  pageTitle,
  part,
}: MedicalDecisionMakerContactFieldsProps) => {
  return (
    <View style={s.sectionContainer} wrap={false}>
      <Text style={{ ...s.sectionTitle, marginBottom: 0 }}>
        {pageTitle ||
          i18n.t('MasterPlanDocMedicalDecisionMakerContactFields.defaultPageTitle', 'Medical Decision Maker')}
      </Text>
      {part?.preMdmContactFieldsContent}
      <Text style={{ ...s.textBold, marginBottom: 0 }}>
        {i18n.t(
          'MasterPlanDocMedicalDecisionMakerContactFields.pageSubtitle',
          'I want this person to make my medical decisions if I am not able to make my own:'
        )}
      </Text>
      <MdmInfo
        fullName={docHelpers.getDecisionMakerName(data)}
        email={data?.decisionMakerEmail || ''}
        phone={data?.decisionMakerPhone || ''}
        street={data?.decisionMakerAddressStreet || ''}
        city={data?.decisionMakerAddressCity || ''}
        state={data?.decisionMakerAddressState || ''}
        zip={data?.decisionMakerAddressZip || ''}
        relationship={
          data?.decisionMakerRelationshipOther ||
          getMdMRelationshipLabel({
            questions: questions || [],
            fieldName: GuideQuestionIdEnum.DecisionMakerRelationship,
            value: data?.decisionMakerRelationship as string,
          })
        }
      />

      {showSignatures && (
        <SignatureBlock
          decisionMaker={i18n.t(
            'MasterPlanDocMedicalDecisionMakerContactFields.medicalDecisionMaker',
            'medical decision maker'
          )}
        />
      )}
      <Text style={{ ...s.textBold, marginBottom: 0 }}>
        {i18n.t('MasterPlanDocMedicalDecisionMakerContactFields.ifUnable')}
      </Text>
      <Text style={{ ...s.textBold, marginBottom: 0 }}>
        {i18n.t('MasterPlanDocMedicalDecisionMakerContactFields.first')}
      </Text>
      <MdmInfo
        fullName={docHelpers.getAltDecisionMakerName(data) as string}
        email={data?.altDecisionMakerEmail || ''}
        phone={data?.altDecisionMakerPhone || ''}
        street={data?.altDecisionMakerAddressStreet || ''}
        city={data?.altDecisionMakerAddressCity || ''}
        state={data?.altDecisionMakerAddressState || ''}
        zip={data?.altDecisionMakerAddressZip || ''}
        relationship={
          data?.altDecisionMakerRelationshipOther ||
          getMdMRelationshipLabel({
            questions: questions || [],
            fieldName: GuideQuestionIdEnum.AltDecisionMakerRelationship,
            value: data?.altDecisionMakerRelationship as string,
          })
        }
      />
      {showSignatures && (
        <SignatureBlock
          decisionMaker={i18n.t(
            'MasterPlanDocMedicalDecisionMakerContactFields.firstMedicalDecisionMaker',
            'first alternative decision maker'
          )}
        />
      )}
      <Text style={{ ...s.textBold, marginBottom: 0 }}>
        {i18n.t('MasterPlanDocMedicalDecisionMakerContactFields.second')}
      </Text>
      <MdmInfo
        fullName={docHelpers.getThirdDecisionMakerName(data) as string}
        email={data?.thirdDecisionMakerEmail || ''}
        phone={data?.thirdDecisionMakerPhone || ''}
        street={data?.thirdDecisionMakerAddressStreet || ''}
        city={data?.thirdDecisionMakerAddressCity || ''}
        state={data?.thirdDecisionMakerAddressState || ''}
        zip={data?.thirdDecisionMakerAddressZip || ''}
        relationship={
          data?.thirdDecisionMakerRelationshipOther ||
          getMdMRelationshipLabel({
            questions: questions || [],
            fieldName: GuideQuestionIdEnum.ThirdDecisionMakerRelationship,
            value: data?.thirdDecisionMakerRelationship as string,
          })
        }
      />
      {showSignatures && (
        <SignatureBlock
          decisionMaker={i18n.t(
            'MasterPlanDocMedicalDecisionMakerContactFields.secondMedicalDecisionMaker',
            'second alternative decision maker'
          )}
        />
      )}
    </View>
  )
}

export default function MdmPage({
  data,
  additionalContent,
  pageTitle,
  part,
}: {
  data: PatientFragmentFragment
  questions: QuestionItem[]
  extraClauses?: boolean
  additionalContent?: any
  pageTitle?: string
  part: PlanSettingPart | undefined
}) {
  return (
    <>
      <View>
        <View style={s.sectionContainer}>
          <Text style={{ ...s.sectionTitle, marginBottom: 0 }}>
            {pageTitle ||
              i18n.t(
                'MedicalDecisionMdmPage.defaultPageTitle',
                'Information for Medical Decision Maker (Medical Power of Attorney)'
              )}
          </Text>
          <Text style={s.textBold}>
            {i18n.t(
              'MedicalDecisionMdmPage.bySigning',
              'By signing this form, you allow your medical decision maker to:'
            )}
          </Text>

          <BulletText
            fontSize={11}
            text={i18n.t(
              'MedicalDecisionMdmPage.bullet1',
              'Make any and all health care decisions for you, except to the extent you state otherwise in this document.'
            )}
          />
          <BulletText
            fontSize={11}
            text={i18n.t(
              'MedicalDecisionMdmPage.bullet2',
              'Take effect if you become unable to make your own health care decisions and this fact is certified in writing by your physician.'
            )}
          />

          {part?.addtionalMdmAllowances}

          <View>
            <Text style={{ ...s.textBold, marginBottom: 0 }}>
              {i18n.t('MedicalDecisionMdmPage.limitations', en.MedicalDecisionMdmPage.limitations)}
            </Text>
            {!data.additionalMdmDirections ? (
              <>
                <AnswerLine style={{ marginTop: 16 }} />
              </>
            ) : (
              <>
                <Text
                  style={{
                    ...s.formLabel,
                    lineHeight: 1.5,
                    fontSize: 12,
                    textDecoration: 'underline',
                  }}
                >
                  {data.additionalMdmDirections}
                </Text>
              </>
            )}
            <Text style={s.textBold}>{i18n.t('MedicalDecisionMdmPage.strictly')}</Text>
            <View style={{ paddingRight: 12 }}>
              <Checkbox
                checked={data.decisionMakerStrictness === 'flexibility'}
                label={i18n.t('MedicalDecisionMdmPage.fullFlexibility')}
              />
              <Checkbox
                checked={data.decisionMakerStrictness === 'noFlexibility'}
                label={i18n.t('MedicalDecisionMdmPage.noFlexibility')}
              />
            </View>
          </View>
          {additionalContent}
        </View>
        {part?.postFlexibilityContent}
      </View>
    </>
  )
}
