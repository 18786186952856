import styled from 'styled-components'
import { useWindowWidth } from '@react-hook/window-size'
// COMPONENTS
import Button from 'components/common/Button'
import ArrowRightOutlined from 'components/icons/ArrowRightOutlined'
// LIB
import theme from 'lib/theme'
import i18n from 'lib/helpers/i18n'
import replaceAllSpaces from 'lib/helpers/replaceAllSpaces'

const SectionBlockContainer = styled.div<{ activeStep: boolean }>`
  padding: 32px 40px;
  border-radius: 5px;
  margin-top: 16px;
  height: 100px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border: ${(p) => {
    if (p.activeStep) {
      return `1px solid ${p.theme.colors.primary8}`
    }
    return `1px solid ${p.theme.colors.neutral9}`
  }};
  &:hover {
    border: 1px solid ${(p) => p.theme.colors.primary7};
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    padding: 16px 8px;
  }
`

const LeftIcon = styled.div<{ complete: boolean }>`
  border-radius: 50%;
  width: 72px;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;

  /* background: ${(p) => {
    if (p.complete) {
      return p.theme.colors.primary10
    }
    return p.theme.colors.neutral9
  }}; */
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    width: 40px;
    height: 40px;
  }
`

const SectionTitle = styled.h2`
  font-size: 22px;
  font-weight: 600;
  margin: 0px;
  width: 480px;
  max-width: 100%;
  line-height: 110%;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    font-size: 18px;
  }
`

const Left = styled.div`
  margin-right: 16px;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    margin-right: 8px;
  }
`

const IconImg = styled.img`
  height: 56px;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    height: 32px;
  }
`

type SectionBlockProps = {
  title: string
  icon: string
  activeStep: boolean
  complete?: boolean
  onClick: () => void
  pulse?: boolean
  buttonType?: string
  buttonText?: string
  onEdit?: () => void
  showButton?: boolean
}

export default function SectionBlock({
  title,
  icon,
  activeStep,
  complete,
  onClick,
  pulse = true,
  buttonType = 'secondary',
  buttonText,
  onEdit,
  showButton = false,
}: SectionBlockProps) {
  const screenWidth = useWindowWidth()
  const isMobile = screenWidth <= parseInt(theme.breakpoints.small)

  let nextText = !buttonText ? i18n.t('medicalDecisionMakerPage.nextStep') : buttonText

  if (isMobile) {
    nextText = buttonText || i18n.t('button.next', 'Next')
  }

  return (
    <SectionBlockContainer
      data-testid={`${title}-clickable-block`}
      activeStep={activeStep}
      onClick={complete ? onEdit : onClick}
    >
      <Left>
        <LeftIcon complete={complete || activeStep}>
          <IconImg src={icon} alt="Step Icon" />
        </LeftIcon>
      </Left>
      <SectionTitle>{title}</SectionTitle>
      {(showButton || activeStep) && (
        <Button
          pulse={pulse}
          style={{ width: !isMobile ? 140 : 100 }}
          onClick={onClick}
          secondary={buttonType === 'secondary'}
          data-testid="plan-config-next-step-btn"
        >
          {nextText}
          <ArrowRightOutlined
            style={{
              fontSize: 14,
              marginLeft: 4,
            }}
          />
        </Button>
      )}
      {complete && (
        <Button
          data-testid={`home-edit-${replaceAllSpaces(title?.toLowerCase())}`}
          style={{ width: !isMobile ? 130 : 80 }}
          onClick={() => {}}
          grey
        >
          {i18n.t('button.edit', 'Edit')}
        </Button>
      )}
    </SectionBlockContainer>
  )
}
