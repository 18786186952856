/**
 * In theory, putting this comonent at the top of the app should force the browser to download and cache all of these images,
 * So that we don't get them flickering on screen later
 */

 import { FC } from "react";
// IMAGES
import homeSVG from 'lib/media/icons/home.svg';
import hospitalSVG from 'lib/media/icons/hospital.svg';
import yesSVG from 'lib/media/icons/yes.svg';
import notGoOutsidePNG from 'lib/media/icons/not-being-able-to-go-outside.png';
import notAbleToMovePNG from 'lib/media/icons/not-being-able-to-move.png';
import notAbleToTalkPNG from 'lib/media/icons/not-being-able-to-talk.png';
import notAbleToLIeWithoutMachinePNG from 'lib/media/icons/not-being-able-to-live-without-machines.png';
import notAbleToThinkPNG from 'lib/media/icons/not-being-able-to-think.png';
import notAbleToWalkPNG from 'lib/media/icons/not-being-able-to-walk.png';
import notHavingRelationshipsPNG from 'lib/media/icons/not-being-able-to-have-relationships.png';
import livingInANursingHomePNG from 'lib/media/icons/living-in-a-nursing-home.png';
import livingInConstantPainPNG from 'lib/media/icons/living-in-constant-pain.png';
import livingWithDiscomfortPNG from 'lib/media/icons/living-with-severe-discomfort.png';
import notBeingAbleToBathroomPNG from 'lib/media/icons/not-being-able-to-bathroom.png';
import notBeingAbleToFeedPNG from 'lib/media/icons/not-being-able-to-feed.png';
import notBeingAbleToLiveAlonePNG from 'lib/media/icons/not-being-able-to-live-alone.png';
import severeFinancialBurdenPNG from 'lib/media/icons/severe-financial-burden.png';
import hobbiesSVG from 'lib/media/icons/hobbies.svg';
import otherSVG from 'lib/media/icons/other.svg';
import agreeSVG from 'lib/media/icons/agree.svg';
import disagreeSVG from 'lib/media/icons/disagree.svg';
import religionSVG from 'lib/media/icons/religion.svg';
import friendshipSVG from 'lib/media/icons/friendship.svg';
import familySVG from 'lib/media/icons/family.svg';
import petsSVG from 'lib/media/icons/pets.svg';
import aloneSVG from 'lib/media/icons/alone.svg';
import assistedLivingSVG from 'lib/media/icons/assisted-living.svg';
import independentSVG from 'lib/media/icons/independent-living.svg';
import liveWithOthersSVG from 'lib/media/icons/live-with-others.svg';
import flexibilitySVG from 'lib/media/icons/flexible.svg';
import noFlexibilitySVG from 'lib/media/icons/no-flexibility.svg';
import notSureSVG from 'lib/media/icons/not-sure.svg';
import noSVG from 'lib/media/icons/no.svg';
import noneSVG from 'lib/media/icons/none.svg';
import doesntMatterSVG from 'lib/media/icons/doesnt-matter.svg';
import supportiveCareSVG from 'lib/media/icons/Supportive_Care.svg';

const IMAGES_TO_LOAD = [
  severeFinancialBurdenPNG,
  notBeingAbleToLiveAlonePNG,
  notBeingAbleToFeedPNG,
  notBeingAbleToBathroomPNG,
  livingWithDiscomfortPNG,
  livingInConstantPainPNG,
  livingInANursingHomePNG,
  notHavingRelationshipsPNG,
  notAbleToWalkPNG,
  notAbleToThinkPNG,
  notAbleToLIeWithoutMachinePNG,
  notAbleToTalkPNG,
  notAbleToMovePNG,
  notGoOutsidePNG,
  yesSVG,
  hospitalSVG,
  homeSVG,
  doesntMatterSVG,
  noneSVG,
  noSVG,
  notSureSVG,
  noFlexibilitySVG,
  flexibilitySVG,
  liveWithOthersSVG,
  independentSVG,
  assistedLivingSVG,
  aloneSVG,
  petsSVG,
  familySVG,
  friendshipSVG,
  religionSVG,
  disagreeSVG,
  agreeSVG,
  otherSVG,
  hobbiesSVG,
  supportiveCareSVG,
];

interface PreloadImagesProps {
  imagesToLoad?: string[];
}

const PreloadImages:FC<PreloadImagesProps> =({imagesToLoad = IMAGES_TO_LOAD}) => {
  return (
    <div style={{opacity: 0, display: 'none'}}>
      {imagesToLoad.map((src, i) => (
        <img key={src} src={src} alt="Koda Health" />
      ))}
    </div>
  );
}

export default PreloadImages