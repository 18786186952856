import React from 'react'
import styled from 'styled-components'
import errorState from './error-state.svg'
import constants from 'lib/config'
import logo from 'lib/media/logo-light-bg-horizontal.svg'
import theme from 'lib/theme'
import i18n from 'lib/helpers/i18n'

const Container = styled.div`
  min-height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  max-width: 100%;
  background: #fff;
`

const Content = styled.div`
  max-width: 100%;
`

const Title = styled.h1`
  color: ${(p) => p.theme.colors.neutral2};
  font-size: 24px;
  margin: 0px;
  margin-top: 16px;
`

const Subtitle = styled.h3`
  color: ${(p) => p.theme.colors.neutral5};
  font-size: 18px;
  margin: 0px;
  margin-top: 8px;
  font-weight: 400 !important;
  width: 450px;
  max-width: 100%;
`

const Header = () => {
  return (
    <div
      style={{
        height: 56,
        background: '#fff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderBottom: `1px solid ${theme.colors.neutral9}`,
      }}
    >
      <img src={logo} height="32" alt="Koda Health" />
    </div>
  )
}

interface ErrorStateProps {
  title?: string
  subtitle?: string
}

export default function ErrorState({
  title = i18n.t('ErrorState.title', 'An error occured...'),
  subtitle = ` ${i18n.t('ErrorState.title', 'Please try to refresh. If the error persists, contact support at')}
  ${constants.supportEmail}`,
}: ErrorStateProps) {
  return (
    <div style={{ maxWidth: '100%' }}>
      <Header />
      <Container>
        <Content>
          <img src={errorState} alt="Error" height="250" style={{ opacity: 0.5 }} />
          <Title>{title}</Title>
          <Subtitle>{subtitle}</Subtitle>
        </Content>
      </Container>
    </div>
  )
}
