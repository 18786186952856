import styled from 'styled-components'
import VideoPlayer from 'components/common/VideoPlayer'
import Collapse from 'components/common/Collapse'
import Link from 'components/common/Link'
import ElephantChat from 'components/common/ElephantChat'
import i18n from 'lib/helpers/i18n'
import { Trans } from 'react-i18next'
import config from 'lib/config'
import { useEffect } from 'react'
import { LocalStorageKeyEnum } from 'lib/types/LocalStorageKeyEnum'
import BottomBar from 'components/common/BottomBar'
import Button from 'components/common/Button'
import ArrowRightOutlined from 'components/icons/ArrowRightOutlined'
import setLocalStorage from 'lib/helpers/setLocalStorage'
import { PatientFragmentFragment } from 'generated/graphql'

const { Panel } = Collapse

const StepItemContainer = styled.div`
  display: flex;
  margin-bottom: 24px;
`

const NumberCircle = styled.div`
  background-color: ${({ theme }) => theme.colors.primary2};
  color: #fff;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 20px;
`

const StepItem = ({ number, text }: { number: string; text: string }) => {
  return (
    <StepItemContainer>
      <div style={{ marginRight: 16 }}>
        <NumberCircle>{number}</NumberCircle>
      </div>
      {text}
    </StepItemContainer>
  )
}

const Card = styled.div`
  max-width: 100%;
  background: #fff;
  padding: 24px;
  border-radius: 8px;
  margin: auto;
  padding-top: 32px;
  border: 1px solid ${(p) => p.theme.colors.neutral9};
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.06);
  margin-top: 16px;
`

const Container = styled.div`
  max-width: 100%;
  width: 700px;
  margin: auto;
`

const Section = styled.section`
  margin: 32px 0px;
`

const Title = styled.h2`
  font-size: 18px;
  margin-bottom: 24px;
  margin-top: 48px;
`

const NextStepsBtnContainer = styled.div`
  display: none;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`

const ContinueWebContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    display: none;
  }
`

const NextStepsBtnInner = styled.div`
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export default function NextStepsLearnMore({
  onContinue,
  userId,
  patient,
}: {
  onContinue: () => void
  userId: string
  patient: PatientFragmentFragment
}) {
  useEffect(() => {
    window.scrollTo(0, 0)
    // log that the user has been to this page
    setLocalStorage(`${userId}-${LocalStorageKeyEnum.HasViewedNextStepsLearnMore}`, 'TRUE')
  })

  const advanceDirectiveVideo =
    patient?.stateVersion?.advanceDirectiveVideo || i18n.t('videos.advanceDirectiveVideo', '441053475')

  return (
    <>
      <Container>
        {' '}
        <ElephantChat
          text={i18n.t(
            'NextStepsLearnMore.elephantChatText',
            'You’re almost done! Here is some helpful information about your next steps.'
          )}
        />
        <ContinueWebContainer>
          <Button onClick={onContinue}>
            {i18n.t('button.continue', 'Continue')}
            <ArrowRightOutlined style={{ marginLeft: 8 }} />
          </Button>
        </ContinueWebContainer>
        <Card>
          <Title style={{ marginTop: 0 }}>{i18n.t('NextStepsLearnMore.stepsTitle', 'What are the next steps?')}</Title>
          <StepItem
            number="1"
            text={i18n.t(
              'NextStepsLearnMore.step1',
              'Select the "Continue" button to review or edit your Koda Care Plan'
            )}
          />
          <StepItem
            number="2"
            text={i18n.t(
              'NextStepsLearnMore.step2',
              'Share your Koda Care Plan with the important people in your life so they know what care you want'
            )}
          />
          <StepItem
            number="3"
            text={i18n.t(
              'NextStepsLearnMore.step3',
              'When you’re ready, sign the document according to your state’s signing and witness requirements. If you don’t sign, your doctors won’t be able to use the form and you might not get the care you want.'
            )}
          />
          <Section>
            <VideoPlayer videoId={advanceDirectiveVideo as string} onNext={() => {}} skipOnEnd hideSkipButton />
          </Section>
          <Section>
            <Title>{i18n.t('NextStepsLearnMore.faqTitle', 'Frequently Asked Questions')}</Title>
            <Collapse expandIconPosition="right">
              <Panel
                key="1"
                header={i18n.t(
                  'NextStepsLearnMore.faq1.header',
                  'What is the difference between a Koda Advance Health Care Directive and other Advance Directives?'
                )}
              >
                {i18n.t(
                  'NextStepsLearnMore.faq1.content',
                  `A product of advance care planning is an Advance Directive, which includes the documents commonly known as
                a Living Will and a Medical Power of Attorney. Koda Health creates one single document that includes both
                of these - we call it an Advance Medical Care Plan. Think of this document as a better, more comprehensive
                Advance Directive that provides the necessary details and clarity for your loved ones and doctors.`
                )}
              </Panel>
              <Panel
                key="2"
                header={i18n.t(
                  'NextStepsLearnMore.faq2.header',
                  'What’s the difference between an unofficial and official Koda Advance Medical Care Plan?'
                )}
              >
                <Trans i18nKey={'NextStepsLearnMore.faq2.content'}>
                  <p>
                    An <strong>unofficial</strong> Koda Advance Medical Care Plan is an unsigned document that can’t be
                    used by your doctors. While it’s not legally valid, your unofficial plan is a great tool to start a
                    conversation about your choices with your medical decision makers.{' '}
                  </p>
                  <p>
                    An <strong>official</strong> Koda Advance Medical Care Plan is a signed document that meets your
                    state’s requirements. This is a legal document that can be used by doctors to provide the care you
                    want.
                  </p>
                </Trans>
              </Panel>
              <Panel
                key="3"
                header={i18n.t('NextStepsLearnMore.faq3.header', 'Where do I find my state’s signing requirements?')}
              >
                <Trans i18nKey={'NextStepsLearnMore.faq3.content'}>
                  Your state’s signing and witnesses requirements can be found on the
                  <Link to={`/app?mode=notarize`}>Make Plan Official</Link> page. If you have any questions or need
                  help, you can contact the Koda Support team at{' '}
                  <a href={`mailto:${config.supportEmail}`}>{{ supportEmail: config.supportEmail }}</a> or
                  <a href={`tel:+${config.supportPhone.replace(/-/g, '')}`}>{{ supportPhone: config.supportPhone }}</a>.
                </Trans>
              </Panel>
            </Collapse>
          </Section>
        </Card>
      </Container>
      <NextStepsBtnContainer>
        <BottomBar>
          <NextStepsBtnInner>
            <Button onClick={onContinue}>
              {i18n.t('button.continue', 'Continue')} <ArrowRightOutlined />
            </Button>
          </NextStepsBtnInner>
        </BottomBar>
      </NextStepsBtnContainer>
    </>
  )
}
