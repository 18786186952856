import { Link as ReactRouterLink } from 'react-router-dom'
import styled from 'styled-components'

const Link = styled(ReactRouterLink)`
  text-decoration: underline;
  &:hover {
    text-decoration: underline;
  }
`

export default Link
