import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useWindowWidth } from '@react-hook/window-size'
import { Patient } from 'generated/graphql'
// COMPONENTS
import Button from 'components/common/Button'
import Animated from 'components/common/Animated'
import ArrowLeftOutlined from 'components/icons/ArrowLeftOutlined'
import LeftOutlined from 'components/icons/LeftOutlined'
// LIB
import theme from 'lib/theme'
import { useTranslation } from 'react-i18next'

const BackButtonContainer = styled.div`
  width: 130px;
  margin: 8px;
  position: absolute;
  bottom: 2px;
  top: 0px;
  z-index: 1000;
  left: 16px;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    right: -48px;
  }
`

const BottomButtonContainer = styled.div`
  position: fixed;
  bottom: 12px;
  left: 0;
  height: 56px;
  background: rgba(255, 255, 255, 0.65);
`

const NextButtonContainer = styled.div<{ buttonAlign?: boolean | string }>`
  height: 100px;
  text-align: ${(p) => {
    if (p.buttonAlign) {
      return 'center'
    }
  }};
`

const Container = styled.div``

const MobileBtn = styled.button`
  border: 0px;
  background: ${(p) => p.theme.colors.neutral8};
  padding: 8px;
  border-radius: 5px;
  width: 56px;
`

const MobileContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 72px;
  background: ${(p) => p.theme.colors.neutral10};
  border: 1px solid ${(p) => p.theme.colors.neutral8};
  display: flex;
  padding: 0 8px;
  z-index: 10;
`

const MobileBlock = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`

interface ButtonsContainerProps {
  onNext?: (plan?: Patient | undefined) => void
  onBack?: () => void
  buttonAlign?: string
  progress?: string
  nextButton?: string
  disabled?: boolean
  hideNext?: boolean
  hideButtons?: boolean
  showSkipButton?: boolean
  onSkip?: (e: React.MouseEvent<HTMLButtonElement>) => void
}

export default function ButtonsContainer({
  onNext,
  onBack,
  buttonAlign,
  progress,
  nextButton,
  disabled,
  hideNext,
  hideButtons,
  showSkipButton,
  onSkip,
}: ButtonsContainerProps) {
  const screenWidth = useWindowWidth()
  const { t } = useTranslation()

  // Add event listeners to capture usage of keys
  useEffect(() => {
    const downHandler = (e): any => {
      console.log(e?.path?.[0]?.nodeName)
      // if the user is focused on an input, don't do anything
      if (e?.path?.[0]?.nodeName === 'INPUT' || e?.path?.[0]?.nodeName === 'TEXTAREA') return
      const { keyCode } = e
      // when pressing left
      if (keyCode === 37 && onBack) {
        onBack()
      }
      // when pressing right
      if (keyCode === 39 && onNext && !hideNext) {
        onNext()
      }
      // when pressing enter
      if (keyCode === 13 && onNext && !hideNext) {
        onNext()
      }
    }
    window.addEventListener('keydown', downHandler, false)
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('keydown', downHandler)
    }
  }, [hideNext, onBack, onNext]) // Empty array ensures that effect is only run on mount and unmount

  // If pressed key is our target key then set to true

  if (hideButtons) return null

  // return mobile version
  if (screenWidth <= 1024) {
    return (
      <MobileContainer>
        <MobileBlock>{onBack && <ArrowLeftOutlined onClick={onBack} style={{ width: 75 }} />}</MobileBlock>
        <MobileBlock>
          {showSkipButton && (
            <Button
              onClick={onSkip || onNext}
              grey
              disabled={disabled}
              style={{
                minWidth: 100,
                marginRight: 16,
              }}
            >
              {t('button.skip', 'Skip')}
            </Button>
          )}
          {!hideNext && (
            <Button onClick={onNext} pulse disabled={disabled}>
              {nextButton}
            </Button>
          )}
        </MobileBlock>
      </MobileContainer>
    )
  }

  return (
    <Container>
      <BottomButtonContainer>
        {onBack && (
          <BackButtonContainer>
            {screenWidth > parseInt(theme.breakpoints.medium) ? (
              <Button grey onClick={onBack} data-testid="back-btn">
                <ArrowLeftOutlined style={{ marginRight: 8 }} />
                {t('button.back')}
              </Button>
            ) : (
              <MobileBtn onClick={onBack}>
                <LeftOutlined />
              </MobileBtn>
            )}
          </BackButtonContainer>
        )}
      </BottomButtonContainer>
      <Animated key={progress}>
        <NextButtonContainer buttonAlign={buttonAlign}>
          {showSkipButton && (
            <Button
              onClick={onNext}
              grey
              disabled={disabled}
              data-testid="survey-form-skip-btn"
              style={{
                minWidth: 100,
                marginRight: 16,
              }}
            >
              {t('button.skip', 'Skip')}
            </Button>
          )}
          {!hideNext && (
            <Button onClick={onNext} pulse disabled={disabled} data-testid="survey-form-next-btn">
              {nextButton}
            </Button>
          )}
        </NextButtonContainer>
      </Animated>
    </Container>
  )
}
