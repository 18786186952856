import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { useState } from 'react'
// COMPONENTS
import ElephantChat from 'components/common/ElephantChat'
import StateRequirementsCard from './StateRequirementsCard'
import Button from 'components/common/Button'
import Collapse from 'components/common/Collapse'
import { Patient, UserProfile } from 'generated/graphql'
import paperCopySVG from './papercopy.svg'
import virtualnotarySVG from './virtualnotary.svg'
import config from 'lib/config'
import i18n from 'lib/helpers/i18n'
import ESignatureCard from './ESignatureCard'
import useDownloadPdf from 'lib/hooks/useDownloadPdf'

const { Panel } = Collapse

const Container = styled.div`
  border-radius: 5px;
  width: 600px;
  margin: auto;
  max-width: 100%;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.06);
`

const ContainerLarge = styled.div`
  width: 700px;
  max-width: 100%;
  margin: auto;
`

const Card = styled.div`
  background: #fff;
  padding: 16px;
  margin-top: 24px;
  max-width: 100%;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    padding: 4px;
  }
`

const CardTop = styled.div`
  display: flex;
  align-items: center;
`

const CardContent = styled.div`
  margin-top: 16px;
`

const CardActions = styled.div`
  margin-top: 16px;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    display: flex;
    justify-content: space-between;
  }
`

export const Image = styled.img`
  margin-right: 16px;
`

const CardHeader = styled.h3`
  margin: 0px;
  font-size: 24px;
  color: ${({ theme }) => theme.colors.neutral1};
  font-weight: 400;
  font-family: ${({ theme }) => theme.fontFamily};
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    font-size: 24px;
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    font-size: 18px;
  }
`

export const Subheader = styled.h3`
  font-size: 18px;
  color: ${({ theme }) => theme.colors.neutral4};
  font-weight: 500;
  font-family: ${({ theme }) => theme.fontFamily};
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    font-size: 18px;
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    font-size: 16px;
  }
`

export const Text = styled.p`
  color: ${({ theme }) => theme.colors.neutral4};
  font-weight: 400;
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 18px;
  margin-bottom: 2px;
  a {
    font-weight: 400;
    font-family: ${({ theme }) => theme.fontFamily};
    font-size: 18px;
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    font-size: 18px;
    a {
      font-size: 18px;
    }
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    font-size: 16px;
    a {
      font-size: 16px;
    }
  }
`

interface ActionCardProps {
  title?: string
  icon?: any | JSX.Element
  text?: JSX.Element | string
  actions?: JSX.Element[]
  expanded?: boolean
  expandedContent?: string | JSX.Element
}

export const ActionCard = ({
  title = 'Texas Requirements',
  icon,
  text,
  actions,
  expanded,
  expandedContent,
}: ActionCardProps) => {
  return (
    <Card>
      <CardTop>
        {icon}
        <CardHeader>{title}</CardHeader>
      </CardTop>
      <CardContent>{text}</CardContent>
      {expanded && expandedContent && <CardContent>{expandedContent}</CardContent>}
      <CardActions>{actions?.map((item) => item)}</CardActions>
    </Card>
  )
}

const NotaryCard = () => {
  const [showMore, setShowMore] = useState<boolean>(false)

  const NOTARY_FAQS = [
    {
      header: i18n.t('NotaryCard.faq1.header', `Why should I have this document notarized?`),
      content: i18n.t('NotaryCard.faq1.content'),
    },
    {
      header: i18n.t('NotaryCard.faq2.header', `How does a virtual notary work?`),
      content: i18n.t('NotaryCard.faq2.content'),
    },
    {
      header: i18n.t('NotaryCard.faq3.header'),
      content: i18n.t('NotaryCard.faq3.content'),
    },
    {
      header: i18n.t('NotaryCard.faq4.header'),
      content: i18n.t('NotaryCard.faq4.content'),
    },
    {
      header: i18n.t('NotaryCard.faq5.header'),
      content: i18n.t('NotaryCard.faq5.content'),
    },
  ]

  const BULLETS = [
    i18n.t('NotaryCard.bullet1'),
    i18n.t('NotaryCard.bullet2'),
    i18n.t('NotaryCard.bullet3'),
    i18n.t('NotaryCard.bullet4'),
    i18n.t('NotaryCard.bullet5'),
    i18n.t('NotaryCard.bullet6'),
  ]

  return (
    <ActionCard
      icon={<Image src={virtualnotarySVG} alt={i18n.t('NotaryCard.title', `Virtual Notary`)} />}
      title={i18n.t('NotaryCard.title', `Virtual Notary`)}
      text={
        <>
          <Text>
            {i18n.t('NotaryCard.text1')}{' '}
            <a style={{ textDecoration: 'underline' }} href={`tel:+${config.supportPhone.replace(/-/g, '')}`}>
              {config.supportPhone}
            </a>{' '}
            {i18n.t('NotaryCard.text2')}
          </Text>
        </>
      }
      expanded={showMore}
      expandedContent={
        <>
          <Text style={{ marginTop: 8 }}>
            {i18n.t(
              'NotaryCard.faqIntro',
              `In order to conduct your virtual notary, you must meet the following requirements. If you do not meet the
            requirements below, please reach out to the Koda Health team to discuss other options for notarizing your
            documents.`
            )}
          </Text>
          <ul style={{ marginTop: 16 }}>
            {BULLETS?.map((bullet) => (
              <li key={bullet}>
                <Text>{bullet}</Text>
              </li>
            ))}
          </ul>
          <Collapse expandIconPosition="right">
            {NOTARY_FAQS?.map((item) => {
              return (
                <Panel header={item.header} key={item.header}>
                  <p>{item.content}</p>
                </Panel>
              )
            })}
          </Collapse>
        </>
      }
      actions={[
        <Button grey key="1" style={{ marginRight: 8 }} onClick={() => setShowMore((currentState) => !currentState)}>
          {!showMore ? i18n.t('NotaryCard.learnMore', 'Learn More') : i18n.t('NotaryCard.seeLess', 'See Less')}
        </Button>,
        <Button
          key="2"
          onClick={() => {
            window.open(config.calendlyNotaryLink, '_newtab')
          }}
        >
          {i18n.t('NotaryCard.submitBtn', 'Schedule Notary')}
        </Button>,
      ]}
    />
  )
}

const PaperCopyCard = ({ plan }: { plan: Patient }) => {
  const history = useHistory()
  const { downloadPdf, downloading } = useDownloadPdf()
  return (
    <ActionCard
      icon={<Image src={paperCopySVG} alt="Paper Copy" />}
      title={i18n.t('paperCopyCard.title')}
      actions={[
        <Button key="1" grey onClick={() => history.push(`/app?mode=viewPlan`)} style={{ marginRight: 8 }}>
          {i18n.t('paperCopyCard.btnViewPlan')}
        </Button>,
        <Button key="2" onClick={() => downloadPdf(plan)} loading={downloading} disabled={downloading}>
          {i18n.t('paperCopyCard.btnDownloadPlan', 'Download')}
        </Button>,
      ]}
      text={<Text>{i18n.t('paperCopyCard.text')}</Text>}
    />
  )
}

const ContactBlock = styled.div`
  background: ${({ theme }) => theme.colors.primary3};
  padding: 24px;
  border-radius: 5px;
  color: #fff;
  margin-top: 24px;
  a {
    color: #fff !important;
    font-weight: 400;
  }
`

const ContactTitle = styled.p`
  font-size: 16px;
  color: #fff;
  font-weight: 500;
  margin-bottom: 4px;
`

interface MakeOfficialProps {
  patient: Patient
  currentUser: UserProfile
}

export default function MakeOfficial({ patient, currentUser }: MakeOfficialProps) {
  const { stateVersion } = patient
  return (
    <>
      <ContainerLarge>
        <div style={{ display: 'flex', marginBottom: 48 }}>
          <ElephantChat text={i18n.t('makeOfficialPlanTitle')} />
        </div>
        <Container>
          <StateRequirementsCard patient={patient} />
          {stateVersion?.hasESign && (
            <ESignatureCard
              patient={patient}
              currentUser={currentUser}
              hasOfficialPlan={patient?.hasOfficialPlan || false}
            />
          )}
          {stateVersion?.hasNotary && <NotaryCard />}
          {stateVersion?.hasPaperCopy && <PaperCopyCard plan={patient} />}
          <ContactBlock>
            <ContactTitle>{i18n.t('needHelp')}</ContactTitle>
            <p style={{ marginBottom: 4, fontWeight: 400 }}>{i18n.t('contactUs')}</p>
            <p style={{ marginBottom: 4, fontWeight: 400 }}>{config.supportPhone}</p>
            <a href={`mailto:${config.supportEmail}`}>{config.supportEmail}</a>
          </ContactBlock>
        </Container>
      </ContainerLarge>
    </>
  )
}
