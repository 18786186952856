import i18n from './i18n';

import { PostAcpInfoAboutCprEnum, PostAcpMedicalEducationPreferEnum, PostAcpOnlineFormatEnum, StatementMostAdvanceCarePlanningEnum } from 'generated/graphql';

const getSurveyHashes = () => {
  const COMFORT_LEVEL_BECOME_ILL_TEXT_HASH = {
    "1": `1 - ${i18n.t("survey.iRatherNotThinkAboutItAtAll", "I’d rather not think about it at all.")}`,
    "2": `2 - ${i18n.t("survey.imOpenToExploringThisMore", "I’m open to exploring this more.")}`,
    "3": `3 - ${i18n.t(
      "survey.imOpenToExploringThisMoreAnd",
      "I’m open to exploring this more and making plans to staying in control of my future care."
    )}`,
    "4": `4 - ${i18n.t("survey.iveAlreadyThoughtAboutThis", "I’ve already thought about this and made my plans.")}`,
  };
  
  const WORRIED_FUTURE_ILLNESSES_TEXT_HASH = {
    "1": `1 - ${i18n.t("survey.notWorriedAtAll", "Not worried at all")}`,
    "2": `2`,
    "3": `3 - ${i18n.t("survey.neutral", "Neutral")}`,
    "4": `4`,
    "5": `5 - ${i18n.t("survey.veryWorried", "Very worried")}`,
  };
  
  const DECISION_MAKER_KIND_OF_MEDICAL_YOU_WOULD_WANT_TEXT_HASH = {
    "1": `1 - ${i18n.t("medicalCareAnswers.neverThoughtAboutIt")}`,
    "2": `2 - ${i18n.t("medicalCareAnswers.haveThoughtAboutItButNotReady")}`,
    "3": `3 - ${i18n.t("medicalCareAnswers.talkingDoingItNext6Months")}`,
    "4": `4 - ${i18n.t("medicalCareAnswers.definitelyPlanningToDoNext30Days")}`,
    "5": `5 - ${i18n.t("medicalCareAnswers.haveAlreadyDoneIt")}`,
  };
  
  const POST_ACP_HEALTH_CARE_SYSTEM_TRUST_TEXT_HASH = {
    "1": `1 - ${i18n.t("healthCareTrustAnswers.completelyDistrust")}`,
    "2": `2 - ${i18n.t("healthCareTrustAnswers.somewhatDistrust")}`,
    "3": `3 - ${i18n.t("healthCareTrustAnswers.neitherOrDistrust")}`,
    "4": `4 - ${i18n.t("healthCareTrustAnswers.somewhatTrust")}`,
    "5": `5 - ${i18n.t("healthCareTrustAnswers.completelyTrust")}`,
  };

  const POST_ACP_ONLINE_FORMAT_TEXT_HASH = {
    [PostAcpOnlineFormatEnum.Videos]: i18n.t("acpOnlineFormat.videos", "Videos"),
    [PostAcpOnlineFormatEnum.Audio]: i18n.t("acpOnlineFormat.audio", "Audio"),
    [PostAcpOnlineFormatEnum.ShortArticles]: i18n.t("acpOnlineFormat.shortArticlesOfContent", "Short articles of content"),
    [PostAcpOnlineFormatEnum.LongerArticles]: i18n.t("acpOnlineFormat.longerArticlesOfContent", "Longer articles of content"),
    [PostAcpOnlineFormatEnum.PhotosInfographicsIllustrations]: i18n.t("acpOnlineFormat.photosInfographicsIllustrations", "Photos, infographics, illustrations"),
    [PostAcpOnlineFormatEnum.Presentations]: i18n.t("acpOnlineFormat.slidesOrPresentations", "Slides or presentations"),
    [PostAcpOnlineFormatEnum.Other]: i18n.t("acpOnlineFormat.other", "Other"),
  };
  
  const POST_ACP_MEDICAL_EDUCATION_PREFER_TEXT_HASH = {
    [PostAcpMedicalEducationPreferEnum.Serious]: i18n.t("medicalEducationToneAnswers.serious", "Serious"),
    [PostAcpMedicalEducationPreferEnum.Friendly]: i18n.t("medicalEducationToneAnswers.friendly", "Friendly"),
    [PostAcpMedicalEducationPreferEnum.Informative]: i18n.t("medicalEducationToneAnswers.informative", "Informative"),
    [PostAcpMedicalEducationPreferEnum.Humorous]: i18n.t("medicalEducationToneAnswers.humorous", "Humorous"),
    [PostAcpMedicalEducationPreferEnum.Educational]: i18n.t("medicalEducationToneAnswers.educational", "Educational"),
    [PostAcpMedicalEducationPreferEnum.Other]: i18n.t("medicalEducationToneAnswers.other", "Other"),
  };
  
  const POST_ACP_INFO_ABOUT_CPR_TEXT_HASH = {
    [PostAcpInfoAboutCprEnum.Yes]: i18n.t("ageSpecificInfoAnswers.yes", "Yes"),
    [PostAcpInfoAboutCprEnum.No]: i18n.t("ageSpecificInfoAnswers.no", "No"),
    [PostAcpInfoAboutCprEnum.DidntReceiveAgeSpecific]: i18n.t("ageSpecificInfoAnswers.notReceiveAgeSpecificInfo", "Did not receive age-specific information"),
    [PostAcpInfoAboutCprEnum.Other]: i18n.t("ageSpecificInfoAnswers.other", "Other"),
  };
  
  const STATEMENT_MOST_ADVANCE_CARE_PLANNING_TEXT_HASH = {
    [StatementMostAdvanceCarePlanningEnum.StayInControl]: i18n.t(
      "statementIdentifyMostThinkingCareOlanningAnswers.wantToDoThisSoICanStay",
      "I want to do this so I can stay in control. We can't plan for everything but going through this allows me to be as prepared as possible."
    ),
    [StatementMostAdvanceCarePlanningEnum.LiveEasier1]: i18n.t(
      "statementIdentifyMostThinkingCareOlanningAnswers.wantToDoThisBecauseIKnow",
      `I want to do this because I know it'll make the lives of
      those closest to me easier. It's a gift I can give to 
      those who matter most.`
    ),
    [StatementMostAdvanceCarePlanningEnum.MettersToMe]: i18n.t(
      "statementIdentifyMostThinkingCareOlanningAnswers.wantToDoThisBecauseIDemand",
      `I want to do this because I demand to have the care
      that matters to me. Going through this allows me to 
      get the care I deserve.`
    ),
    [StatementMostAdvanceCarePlanningEnum.LiveEasier2]: i18n.t(
      "statementIdentifyMostThinkingCareOlanningAnswers.wantToDoThisSoICanHavePeace",
      `I want to do this so I can have peace of mind.
      Once I complete this, there's no need to wonder what will happen.`
    ),
    [StatementMostAdvanceCarePlanningEnum.SeriousIllness]: i18n.t(
      "statementIdentifyMostThinkingCareOlanningAnswers.wantToDoThisBecauseIveHadAPrevious",
      `I want to do this because I've had a previous 
      experience with serious illness. I know this will help 
      me if I'm ever in that situation.`
    ),
    [StatementMostAdvanceCarePlanningEnum.Caregiver]: i18n.t(
      "statementIdentifyMostThinkingCareOlanningAnswers.wantToDoThisBecauseIfIWereCaregiver",
      `I want to do this because if I were a caregiver, I'd want
      to know what matters to my loved ones so I can 
      advocate for them.`
    ),
    [StatementMostAdvanceCarePlanningEnum.None]: i18n.t(
      "statementIdentifyMostThinkingCareOlanningAnswers.noneOfTheseStatements",
      `None of these statements resonate with me because
      of another reason.`
    ),
  };

  return {
    COMFORT_LEVEL_BECOME_ILL_TEXT_HASH,
    WORRIED_FUTURE_ILLNESSES_TEXT_HASH,
    DECISION_MAKER_KIND_OF_MEDICAL_YOU_WOULD_WANT_TEXT_HASH,
    POST_ACP_HEALTH_CARE_SYSTEM_TRUST_TEXT_HASH,
    POST_ACP_ONLINE_FORMAT_TEXT_HASH,
    POST_ACP_MEDICAL_EDUCATION_PREFER_TEXT_HASH,
    POST_ACP_INFO_ABOUT_CPR_TEXT_HASH,
    STATEMENT_MOST_ADVANCE_CARE_PLANNING_TEXT_HASH,
  }
}

export default getSurveyHashes;
