import { useState } from 'react'
import styled from 'styled-components'
import { AnimationString } from 'react-animated-css'
import QuestionText from 'components/text/QuestionText'
import ButtonsContainer from '../ButtonsContainer'
import TextOption from './TextOption'
import IconOption from './IconOption'
import IconBlock from './IconBlock'
import { StepContainer } from '../index'
import { useEffect } from 'react'
import Animated from 'components/common/Animated'
import Directions from 'components/text/Directions'
import { Patient } from 'generated/graphql'
import { useTranslation } from 'react-i18next'
import { QuestionItemOption } from 'lib/types/QuestionItem'
// import ElephantChat from 'components/common/ElephantChat';

const ChoiceList = styled.div`
  display: inline-flex;
  width: 100%;
  margin-bottom: -8px;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: stretch;
  margin-right: -8px;
`

const ChoiceListText = styled.div`
  display: inline-flex;
  max-width: 100%;
  margin-bottom: -8px;
  flex-wrap: wrap;
  flex-direction: column;
  -webkit-box-align: stretch;
  align-items: stretch;
  min-width: 278px;
`

const QuestionsContainer = styled.div`
  margin-top: 8px;
  margin-bottom: 24px;
  max-width: 100%;
`

const Choice = styled.div`
  margin-right: 8px;
  margin-bottom: 8px;
  max-width: 100%;
  width: calc(25% - 10px + 2px);
  @media only screen and (max-width: 1024px) {
    width: calc(33% - 10px + 2px);
  }
  @media only screen and (max-width: 600px) {
    width: calc(50% - 10px + 2px);
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    width: calc(50% - 10px + 2px);
  }
`

const getLetterFromIndex = (index) => {
  if (index === 0) return 'a'
  if (index === 1) return 'b'
  if (index === 2) return 'c'
  if (index === 3) return 'd'
  if (index === 4) return 'e'
  if (index === 5) return 'f'
  if (index === 6) return 'g'
  if (index === 7) return 'h'
  return ' '
}

const ScenarioImg = styled.img`
  margin: auto;
  height: 75px;
  padding-right: 10px;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    height: 70px;
  }
`

const ScenarioHeading = styled(QuestionText)`
  margin: 0px;
  margin-bottom: 10px;
`

const ScenarioText = styled(QuestionText)`
  margin: 10px;
  margin-left: 0px;
  text-align: 'left';
`

interface SelectMultipleProps {
  text?: string | JSX.Element
  options?: any[]
  onChange: (e: { [x: string]: string | number | string[] }) => void
  progress?: string
  onNext: (plan?: Patient) => void
  onBack?: () => void
  nextButton?: string
  required?: boolean
  fieldName?: string
  hideButtons?: boolean
  defaultValue?: any[] | any
  type?: string
  multiple?: boolean
  animationIn?: AnimationString
  scenario?: string | JSX.Element
  otherFieldName?: string
  otherFieldValue?: string
  scenarioImg?: string
  directions?: string | JSX.Element | undefined
  /** allows parent to decide if we would run the scroll to top function or not */
  scrollTop?: boolean
}

const Multiple = ({
  text,
  options,
  onChange,
  progress,
  onNext,
  onBack,
  nextButton,
  required,
  fieldName,
  hideButtons,
  defaultValue,
  type,
  multiple,
  animationIn,
  scenario,
  otherFieldName,
  otherFieldValue,
  scenarioImg,
  directions,
  scrollTop = true, // allows parent to decide if we would run the scroll to top function or not
}: SelectMultipleProps) => {
  const [selections, setSelections] = useState(defaultValue || (multiple ? [] : null))

  const [none, setNone] = useState(false)

  const { t } = useTranslation()

  useEffect(() => {
    if (scrollTop) {
      window.scrollTo(0, 0)
    }
  }, [scrollTop])

  useEffect(() => {
    setSelections(defaultValue || (multiple ? [] : null))
  }, [fieldName, defaultValue, multiple])

  const onMultiChange = (item) => {
    // set none false.. this lets the next button know that even though we didnt' select any options, they can go to the enxt question
    setNone(false)

    // if we had none selected, then select someting else, we want to remove none and add the new item to selections
    if (selections?.includes('none') && item.id !== 'none') {
      setSelections([item.id])
      return onChange({ [fieldName as string]: [item.id] })
    }

    if (item.id === 'none') {
      setNone(true) // will tell our next button that it can render
      onChange({ [fieldName as string]: [item.id] })
      return setSelections([item.id])
    }

    // if the current selections include the id we're receiving, than run this if statement
    if (selections?.includes(item.id)) {
      if (item.id === 'none') {
        setNone(true) // will tell our next button that it can render
        onChange({ [fieldName as string]: [] })
        return setSelections([])
      }

      let newSelections: string[] = []

      selections.forEach((selectionId) => {
        if (selectionId !== item.id) {
          newSelections.push(selectionId)
        }
      })

      setSelections(newSelections)
      return onChange({ [fieldName as string]: newSelections })
    }

    // if the current selections DOES NOT include the id we're receiving, than run this if statement
    if (!selections?.includes(item.id)) {
      if (item.id === 'none') {
        setNone(true) // will tell our next button that it can render
        return setSelections([item.id])
      }
      setSelections([...selections, item.id])
      onChange({ [fieldName as string]: [...selections, item.id] })
    }
  }

  return (
    <StepContainer key={fieldName} id={`${fieldName}`}>
      <div style={{ width: '100%' }}>
        <Animated key={fieldName} animationIn={animationIn}>
          {scenario && (
            <div>
              <ScenarioHeading>{t('scenarioTitle')}</ScenarioHeading>
              <div
                style={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  marginBottom: 10,
                }}
              >
                <ScenarioImg src={scenarioImg} />
                <ScenarioText>{scenario}</ScenarioText>
              </div>
            </div>
          )}
          <ScenarioText data-testid="select-multiple-question-text">{text}</ScenarioText>
          {directions && <Directions>{directions}</Directions>}
          {!directions && multiple && <Directions>{t('chooseAsMany')}</Directions>}
          <QuestionsContainer>
            {type === 'select-icons' && (
              <div>
                {options?.map((item, i) => {
                  //let disabled = multiple
                  //  ? selections.includes('none') && item.id !== 'none'
                  //  : selections === 'none' && item.id !== 'none';
                  return (
                    <>
                      <IconBlock
                        key={item.id}
                        item={item}
                        //disabled={disabled}
                        onClick={() => {
                          if (multiple) {
                            onMultiChange(item)
                          } else {
                            setSelections(item.id)
                            onChange({
                              [fieldName as string]: item.id,
                              ...(otherFieldName && {
                                [otherFieldName as string]: null, // clear other fieldname if it exists
                              }),
                            })
                          }
                        }}
                        active={multiple ? selections?.includes(item.id) : item.id === selections}
                      />
                    </>
                  )
                })}
              </div>
            )}
            {type === 'select-columns' && (
              <ChoiceList>
                {options?.map((item, i) => {
                  //let disabled = multiple
                  //  ? selections.includes('none') && item.id !== 'none'
                  //  : selections === 'none' && item.id !== 'none';
                  return (
                    <Choice key={item.id}>
                      <IconOption
                        isOther={item.id === 'other'}
                        data-testid={`select-multiple-select-columns-${item.id}`}
                        otherFieldName={otherFieldName}
                        otherFieldValue={otherFieldValue}
                        letter={getLetterFromIndex(i)}
                        item={item}
                        onChange={onChange}
                        onClick={() => {
                          if (multiple) {
                            onMultiChange(item)
                          } else {
                            setSelections(item.id)
                            onChange({
                              [fieldName as string]: item.id,
                              ...(otherFieldName && {
                                [otherFieldName as string]: null, // clear other fieldname if it exists
                              }),
                            })
                          }
                        }}
                        active={multiple ? selections?.includes(item.id) : item.id === selections}
                      />
                    </Choice>
                  )
                })}
              </ChoiceList>
            )}
            {type !== 'select-icons' && type !== 'select-columns' && (
              <ChoiceListText>
                {options?.map((item: QuestionItemOption, i) => {
                  return (
                    <TextOption
                      key={item.id}
                      id={item?.id || ''}
                      item={item as any}
                      isOther={item.id === 'other'}
                      otherFieldName={otherFieldName}
                      otherFieldValue={otherFieldValue}
                      onChange={(otherValue) => {
                        onChange(otherValue)
                        if (item.id === 'other' && !selections?.includes(item.id)) {
                          onMultiChange(item)
                        }
                      }}
                      noCapitalize
                      disabled={
                        multiple
                          ? selections?.includes('none') && item.id !== 'none'
                          : selections === 'none' && item.id !== 'none'
                      }
                      onClick={() => {
                        if (multiple) {
                          // if we are toggling the option off, we need to clear the otherField value as well
                          if (item.id === 'other' && selections?.includes(item.id)) {
                            return onMultiChange({
                              ...item,
                              [otherFieldName as string]: null,
                            })
                          }
                          return onMultiChange(item)
                        } else {
                          setSelections(item.id)
                          onChange({
                            [fieldName as string]: item.id,
                            ...(otherFieldName && {
                              [otherFieldName as string]: item.id === 'other' ? otherFieldValue : null, // on a single selelct (rather than a multi-select), if the user changes their answer from 'other' to something else, we want to clear out the otherFieldValue }),
                            }),
                          } as any)
                        }
                      }}
                      active={multiple ? selections?.includes(item.id) : item.id === selections}
                    />
                  )
                })}
              </ChoiceListText>
            )}
          </QuestionsContainer>
        </Animated>
        <ButtonsContainer
          onNext={() => {
            onChange({ [fieldName as string]: selections })
            onNext()
          }}
          hideNext={required && (!defaultValue || !defaultValue[0]) && !none} // if this is required AND the defaultValue is empty AND they haven't selected none
          hideButtons={hideButtons}
          onBack={onBack}
          progress={progress}
          nextButton={nextButton}
        />
      </div>
    </StepContainer>
  )
}

export default Multiple
