import React from 'react';
import styled from 'styled-components';
import {QuestionsContainer, StepContainer} from '../index';
import ButtonsContainer from '../ButtonsContainer';
import Row from 'components/common/Row';
import Col from 'components/common/Col';
import Button from 'components/common/Button';
import QuestionText from 'components/text/QuestionText';
import Label from 'components/text/SelectLabel';
import Animated from 'components/common/Animated';
import { Patient } from 'generated/graphql';

const OptionContainer = styled.div<{active: boolean}>`
  height: 204px;
  width: 169px;
  max-width: 100%;
  background: ${(p) =>
    p.active ? p.theme.colors.primary9 : p.theme.colors.neutral10};
  border: 1px solid
    ${(p) => (p.active ? p.theme.colors.primary7 : p.theme.colors.neutral8)};
  border-radius: 5px;
  padding: 8px;
  cursor: pointer;
  transition: color 0.3s ease, background-color 0.3s ease,
    border-color 0.3s ease, width 0.3s ease, opacity 0.3s ease;
  &:hover {
    background: ${(p) =>
      p.active ? p.theme.colors.primary9 : p.theme.colors.neutral9};
    border: 1px solid
      ${(p) => (p.active ? p.theme.colors.primary6 : p.theme.colors.neutral7)};
  }
`;

const SelectionContainer = styled.span`
  border-radius: 3px;
  margin-right: 6px;
  width: 24px;
  height: 24px;
  display: inline-flex;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  align-items: center;
  justify-content: center;
  background: #fff;
  border: 1px solid ${(p) => p.theme.colors.neutral8};
`;

interface OptionProps {
  item: {
    img: string;
    label: string;
  };
  onClick: (e: React.MouseEvent) => void;
  active: boolean;
  letter: string;
}

const Option = ({item, onClick, active, letter}: OptionProps) => (
  <OptionContainer onClick={onClick} active={active}>
    <div style={{height: 150, display: 'flex'}}>
      <img
        src={item.img}
        alt="Option"
        height="100"
        style={{ margin: 'auto' }}
      />
    </div>
    <div style={{ height: 40, display: 'flex', alignItems: 'center' }}>
      <Label>
        <SelectionContainer>{letter}</SelectionContainer> {item.label}
      </Label>
    </div>
  </OptionContainer>
);

interface AgreeToTermsProps {
  onNext: (plan?: Patient) => void;
  onBack: () => void;
  fieldName?: string;
  defaultValue: string;
  text?: JSX.Element | string;
  options: any;
  nextButton: string;
  progress: string;
  onChange: Function;
  hideButtons?: boolean;
  required?: boolean;
}

export default function AgreeToTerms({
  onNext,
  onBack,
  fieldName,
  defaultValue,
  text,
  options,
  nextButton,
  progress,
  onChange,
  hideButtons,
  required,
}: AgreeToTermsProps) {
  if (defaultValue === 'disagree') {
    return (
      <StepContainer>
        <div>
          <QuestionText>
            That’s okay, this can be a difficult conversation to go through. We
            encourage you to visit our website to learn more about preparing for
            your future care.
          </QuestionText>
          <Button
            style={{width: 225, margin: 'auto'}}
            onClick={() => window.open('https://www.kodahealthcare.com')}
          >
            Go to Koda Health
          </Button>
        </div>
        <ButtonsContainer
          onBack={() => onChange({[fieldName as string]: null})}
          hideNext={true}
          progress={progress}
        />
      </StepContainer>
    );
  }
  return (
    <StepContainer>
      <div>
        <Animated>
          {text && React.isValidElement(text) && text}
          {text && typeof text === 'string' && (
            <QuestionText>{text}</QuestionText>
          )}
          <QuestionsContainer>
            <Row gutter={8}>
              {options.map((item, i) => (
                <Col key={`${item.id}${i}`} xs={12} sm={6}>
                  <Option
                    item={item}
                    letter={i === 0 ? 'a' : 'b'}
                    active={defaultValue === item.value}
                    onClick={() =>
                      onChange({[fieldName as string]: item.value})
                    }
                  />
                </Col>
              ))}
            </Row>
          </QuestionsContainer>
        </Animated>
        <ButtonsContainer
          onNext={onNext}
          hideButtons={hideButtons}
          onBack={onBack}
          hideNext={required && !defaultValue}
          nextButton={nextButton}
          progress={progress}
        />
      </div>
    </StepContainer>
  );
}
