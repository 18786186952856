import { Text, StyleSheet, View } from '@react-pdf/renderer'
// helpers
import s from '../../../KodaContent/styles'
import theme from 'lib/theme'
import { GuideQuestionIdEnum, NotWorthLivingEnum, Patient } from 'generated/graphql'
import CheckRow from '../../../KodaContent/CheckRow'
import { useTranslation, Trans } from 'react-i18next'

const styles = StyleSheet.create({
  innerContent: {
    borderWidth: 1,
    borderRadius: 4,
    borderColor: theme.colors.neutral9,
  },
  checkRowStyle: {
    padding: 4,
    paddingTop: 1,
    paddingBottom: 1,
    paddingLeft: 8,
    borderRadius: 4,
  },
})

type RowsType = {
  label: string
  fieldName: string
  valueName: string
}

interface QualityOfLifeV2Props {
  data?: Patient
}

export default function QualityOfLifeV2({ data }: QualityOfLifeV2Props) {
  const { t } = useTranslation()
  const noneSelected =
    data?.[GuideQuestionIdEnum.NotWorthLiving]?.includes(NotWorthLivingEnum.None) &&
    data[GuideQuestionIdEnum.NotWorthLiving2]?.includes(NotWorthLivingEnum.None) &&
    data[GuideQuestionIdEnum.NotWorthLiving3]?.includes(NotWorthLivingEnum.None)

  const ROWS: RowsType[] = [
    {
      label: t('notWorthLiving.notAbleToMove'),
      fieldName: GuideQuestionIdEnum.NotWorthLiving,
      valueName: NotWorthLivingEnum.NotAbleToMove,
    },
    {
      label: t('notWorthLiving.notAbleToThink'),
      fieldName: GuideQuestionIdEnum.NotWorthLiving,
      valueName: NotWorthLivingEnum.NotAbleToThink,
    },
    {
      label: t('notWorthLiving.notHavingRelationships'),
      fieldName: GuideQuestionIdEnum.NotWorthLiving,
      valueName: NotWorthLivingEnum.NotHavingRelationships,
    },
    {
      label: t('notWorthLiving.notAbleToTalk'),
      fieldName: GuideQuestionIdEnum.NotWorthLiving,
      valueName: NotWorthLivingEnum.NotAbleToTalk,
    },
    {
      label: t('notWorthLiving2.notAbleToDrive'),
      fieldName: GuideQuestionIdEnum.NotWorthLiving2,
      valueName: NotWorthLivingEnum.NotAbleToDrive,
    },
    {
      label: t('notWorthLiving2.notAbleToLeaveBed'),
      fieldName: GuideQuestionIdEnum.NotWorthLiving2,
      valueName: NotWorthLivingEnum.NotAbleToLeaveBed,
    },
    {
      label: t('notWorthLiving2.notBeingAbleToLiveAlone'),
      fieldName: GuideQuestionIdEnum.NotWorthLiving2,
      valueName: NotWorthLivingEnum.NotBeingAbleToLiveAlone,
    },
    {
      label: t('notWorthLiving2.livingInANursingHome'),
      fieldName: GuideQuestionIdEnum.NotWorthLiving2,
      valueName: NotWorthLivingEnum.LivingInANursingHome,
    },
    {
      label: t('notWorthLiving3.severeFinancialBurden', 'Being a burden on my loved ones due to my cost of care'),
      fieldName: GuideQuestionIdEnum.NotWorthLiving3,
      valueName: NotWorthLivingEnum.SevereFinancialBurden,
    },
    {
      label: t('notWorthLiving3.notBeingAbleToFeed'),
      fieldName: GuideQuestionIdEnum.NotWorthLiving3,
      valueName: NotWorthLivingEnum.NotBeingAbleToFeed,
    },
    {
      label: t('notWorthLiving3.notBeingAbleToBathroom'),
      fieldName: GuideQuestionIdEnum.NotWorthLiving3,
      valueName: NotWorthLivingEnum.NotBeingAbleToBathroom,
    },
    {
      label: t('notWorthLiving3.livingInConstantPain'),
      fieldName: GuideQuestionIdEnum.NotWorthLiving3,
      valueName: NotWorthLivingEnum.LivingInConstantPain,
    },
    {
      label: t('notWorthLiving3.livingWithDiscomfort'),
      fieldName: GuideQuestionIdEnum.NotWorthLiving3,
      valueName: NotWorthLivingEnum.LivingWithDiscomfort,
    },
  ]

  return (
    <View style={s.sectionContainer}>
      <Text style={s.sectionTitle}>{t('MasterPlanDocQualityOfLife.qualityOfLife', 'Quality Of Life')}</Text>
      <Text style={s.text}>
        <Trans i18nKey="MasterPlanDocQualityOfLife.description">
          When you think about the following scenarios, what would be considered an
          <Text style={s.textBold}>unacceptable quality of life</Text>?
        </Trans>
      </Text>
      <View style={styles.innerContent}>
        <View style={{ backgroundColor: theme.colors.primary10, paddingLeft: 4 }}>
          <Text style={s.textBold}>{t('MasterPlanDocQualityOfLife.optionA', 'Option A: Select all that apply')}</Text>
        </View>
        {ROWS.map((row: RowsType, i: number) => {
          return (
            <CheckRow
              key={row.label}
              label={row.label}
              checked={data?.[row.fieldName].includes(row.valueName)}
              colored={i % 2 === 0}
              containerStyle={styles.checkRowStyle}
              textStyle={{ fontSize: 12 }}
            />
          )
        })}
      </View>
      <Text style={{ ...s.sectionTitle, marginTop: 4, marginBottom: 4 }}>
        {t('MasterPlanDocQualityOfLife.or', 'OR')}
      </Text>
      <View style={styles.innerContent}>
        <View style={{ backgroundColor: theme.colors.primary10, paddingLeft: 4 }}>
          <Text style={s.textBold}>{t('MasterPlanDocQualityOfLife.optionB', 'Option B: None of the above')}</Text>
        </View>
        <CheckRow
          key="none"
          label={t(
            'MasterPlanDocQualityOfLife.none',
            'None of the scenarios in Option A would be hard on my quality of life'
          )}
          checked={!!noneSelected}
          colored={false}
          containerStyle={styles.checkRowStyle}
          textStyle={{ fontSize: 12 }}
        />
      </View>
    </View>
  )
}
