// TOP LEVEL IMPORTS
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
// COMPONENTS
import { UserOutlined, LogoutOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import CreateAccount from './CreateAccount'
// LIB
import theme from 'lib/theme'
// HOOKS
import usePatient from 'lib/hooks/usePatient'
import useLogActivityItemMutation from 'lib/hooks/useLogActivityItemMutation'
import { ActivityLogKeyEnum, UserProfile } from 'generated/graphql'
import { useTranslation } from 'react-i18next'
import useSignOut from 'lib/hooks/useSignout'
import translateApolloError from 'lib/helpers/translateApolloError'
import message from 'components/common/message'

const HeaderLink = styled.button<{ active?: boolean }>`
  border: 0px;
  background: transparent;
  font-family: ${(p) => p.theme.fontFamily};
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 145.27%;
  color: ${(p) => (p.active ? p.theme.colors.neutral5 : p.theme.colors.neutral5)} !important;
  cursor: pointer;
  &:hover {
    color: ${(p) => (p.active ? '#0fb5ba' : '#0fb5ba')};
  }
`
const HeaderLinkLogout = styled.button<{ active?: boolean }>`
  border: 0px;
  background: transparent;
  font-family: ${(p) => p.theme.fontFamily};
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 145.27%;
  color: ${(p) => (p.active ? '#780A0A' : '#780A0A')} !important;
  cursor: pointer;
  &:hover {
    color: ${(p) => (p.active ? '#780A0A' : '#780A0A')};
  }
`

const ContentLink = styled.div`
  font-family: ${(p) => p.theme.fontFamily};
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 15px;
  color: ${(p) => p.theme.colors.neutral5};
  letter-spacing: 1.2px;
`

const ICON_STYLE = { marginRight: 6, zIndex: 0, color: theme.colors.neutral7 }

interface AccountDropdownProps {
  onClose?: () => void
  currentUser: UserProfile
}

export default function AccountDropdown({ currentUser }: AccountDropdownProps) {
  const history = useHistory()
  const [signOut] = useSignOut()
  const { t } = useTranslation()
  const patient = usePatient({ id: currentUser && currentUser.id })
  const [logActivityItem] = useLogActivityItemMutation()

  const onLogout = async () => {
    try {
      logActivityItem({
        variables: {
          key: ActivityLogKeyEnum.SaveAndExit,
          importance: 10,
          userId: currentUser?.id || '',
          patientId: (patient && patient.id) || '',
          content: `Patient has saved and exited`,
        },
      })
      await signOut(currentUser)
    } catch (err: any) {
      message.error(translateApolloError(err))
    }
  }

  return (
    <div style={{ width: 230 }}>
      <ContentLink>{t('accountPopover.account', 'Account')}</ContentLink>
      {!currentUser.isGuest && (
        <div style={{ marginTop: 12 }}>
          <HeaderLink onClick={() => history.push(`/app/profile`)}>
            <UserOutlined style={ICON_STYLE} />
            {t('accountPopover.myProfile', 'My Profile')}
          </HeaderLink>
        </div>
      )}
      {currentUser.isGuest && (
        <div style={{ marginTop: 12 }}>
          <CreateAccount
            // onClose={onClose}
            currentUser={currentUser}
            defaultFirst={currentUser.firstName as string}
            defaultEmail={currentUser.email as string}
            defaultLast={currentUser.lastName as string}
          />
        </div>
      )}
      <div
        style={{
          marginTop: 20,
          marginBottom: 8,
          borderTop: '2px solid #efefef',
        }}
      />
      <ContentLink>{t('accountPopover.support', 'SUPPORT')}</ContentLink>
      <div style={{ marginTop: 12 }}>
        <HeaderLink onClick={() => history.push(`/app?mode=help`)}>
          <QuestionCircleOutlined style={{ ...ICON_STYLE, marginTop: 2 }} />
          {t('accountPopover.getHelp', 'Get Help')}
        </HeaderLink>
      </div>
      <div
        style={{
          marginTop: 20,
          marginBottom: 8,
          borderTop: '2px solid #efefef',
        }}
      />
      <div style={{ marginTop: 12 }}>
        <HeaderLinkLogout onClick={onLogout} data-testid="account-logout-btn">
          <LogoutOutlined
            style={{
              marginRight: 10,
              color: '#780A0A',
              marginTop: 2,
            }}
          />
          {t('accountPopover.logout', 'Logout')}
        </HeaderLinkLogout>
      </div>
    </div>
  )
}
