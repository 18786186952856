import {Text, View} from '@react-pdf/renderer';
// helpers
import s from '../styles';
import theme from 'lib/theme';

interface BulletTextProps {
  text?: string | JSX.Element;
  fontSize?: number | string;
}

export default function BulletText({text, fontSize = 12}: BulletTextProps) {
  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        marginBottom: 2,
        marginRight: 10,
        marginLeft: 10
      }}
    >
      <View
        style={{
          flex: 1,
          display: 'flex',
          alignItems: 'flex-end',
          justifyContent: 'center',
          alignSelf: 'flex-start',
          position: 'relative',
          top: 5,
          right: 3,
        }}
      >
        <View
          style={{
            height: 4,
            width: 4,
            backgroundColor: theme.colors.neutral1,
            borderRadius: 4 / 2,
          }}
        ></View>
      </View>
      <View style={{flex: 19}}>
        <Text style={{...s.textNoMargin, fontSize}}>{text}</Text>
      </View>
    </View>
  );
}
