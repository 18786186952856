import React from 'react'
import styled from 'styled-components'
import queryString from 'query-string'
import filmSVG from './images/film.svg'
import unofficialPlanSvg from 'lib/media/images/unofficial_plan.svg'
import pencilSVG from './images/pencil.svg'
import emailSVG from './images/email.svg'
import officialPlanSvg from 'lib/media/images/official_plan.svg'
import makePlanOfficialSvg from 'lib/media/images/make_plan_official.svg'
import faqSVG from './images/faq.svg'
import { useHistory, useLocation } from 'react-router-dom'
import { Patient } from 'generated/graphql'
// LIB
import i18n from 'lib/helpers/i18n'

const Row = styled.div`
  display: flex;
  height: 150px;
  margin-bottom: 8px;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    height: 190px;
  }

  /* display: grid;
  grid-template-columns: 25% 25% 25% 25%; */
`

const ActionImg = styled.img`
  width: 48px;
`

const ActionText = styled.h2`
  font-weight: 400;
  margin: 0px;
  color: ${(p) => p.theme.colors.neutral1};
  font-size: 18px;
  line-height: 1.2;
`

const ActionContainer = styled.div`
  border: 1px solid transparent;
  flex: 1;
  border-radius: 5px;
  margin: auto;
  margin-bottom: 32px;
  background: #fff;
  padding: 16px;
  height: 125px;
  width: 125px;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.06);
  transition: border-color 0.3s ease;
  margin-right: 8px;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    height: 170px;
    margin-top: 4px;
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    margin-right: 8px;
    padding: 4px;
  }
  &:hover {
    border: 1px solid ${(p) => p.theme.colors.primary8};
    cursor: pointer;
  }
  &:active {
    transform: translatey(2px);
  }
`

interface ActionBlockProps {
  label?: string | JSX.Element
  src?: string
  onClick?: () => void
}

const ActionBlock = ({ label, src, onClick = () => {} }: ActionBlockProps) => {
  return (
    <ActionContainer onClick={onClick} role="button">
      <div style={{ height: 115 }}>
        <div
          style={{
            height: 75,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <ActionImg src={src} alt="" />
        </div>
        {label && React.isValidElement(label) ? label : <ActionText>{label}</ActionText>}
      </div>
    </ActionContainer>
  )
}

export default function ActionBar({ patient }: { patient?: Patient }) {
  const history = useHistory()
  const location = useLocation()
  const { mode } = queryString.parse(location.search)
  // TODO remove for the page you're on

  const GUIDE_LINK = {
    label: i18n.t('blocks.goToGuide', 'Go to Guide'),
    src: filmSVG,
    onClick: () => history.push(`/${patient?.slug}`),
  }
  const EDIT = {
    label: i18n.t('blocks.editPlan', 'Edit Plan'),
    src: pencilSVG,
    onClick: () => history.push(`/app`),
  }
  const SEE_PDF = {
    label: patient?.hasOfficialPlan
      ? i18n.t('blocks.viewOfficialPlan', 'View Official Plan')
      : i18n.t('blocks.viewUnofficialPlan', 'View Unofficial Plan'),
    src: patient?.hasOfficialPlan ? officialPlanSvg : unofficialPlanSvg,
    onClick: () => history.push(`/app?mode=viewPlan`),
  }
  const NOTARIZE = {
    label: i18n.t('blocks.makePlanOfficial', 'Make Plan Official'),
    src: makePlanOfficialSvg,
    onClick: () => history.push(`/app?mode=notarize`),
  }
  const EMAIL_PDF = {
    label: i18n.t('blocks.sharePlan', 'Share Plan'),
    src: emailSVG,
    onClick: () => history.push(`/app?mode=emailDirective`),
  }
  const REVIEW_STEPS = {
    label: i18n.t('blocks.faqs', 'FAQs'),
    src: faqSVG,
    onClick: () => history.push(`/app/onboarding?repeat=true`),
  }

  let BLOCKS: { label: string; src: string; onClick: () => void }[] = []

  if (mode === 'editPlan') {
    BLOCKS = [GUIDE_LINK, SEE_PDF, NOTARIZE, EMAIL_PDF]
  }

  if (mode === 'viewPlan') {
    BLOCKS = [EDIT, EMAIL_PDF, NOTARIZE]
  }

  if (mode === 'notarize') {
    BLOCKS = [GUIDE_LINK, SEE_PDF, EDIT, EMAIL_PDF]
  }

  if (mode === 'emailDirective') {
    BLOCKS = [GUIDE_LINK, SEE_PDF, EDIT, NOTARIZE]
  }

  if (!mode || mode === 'help') {
    BLOCKS = [REVIEW_STEPS, SEE_PDF, EDIT, NOTARIZE]
  }

  return (
    <Row>
      {BLOCKS.map((item) => {
        return <ActionBlock key={item.label} label={item.label} src={item.src} onClick={item.onClick} />
      })}
    </Row>
  )
}
