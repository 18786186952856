import { AdPartEnum, PlanSetting } from '../types'
import en from 'lib/helpers/locales/en/translation.json'
import { Text, View } from '@react-pdf/renderer'
import s from '../../../KodaContent/styles'
import { Trans } from 'react-i18next'
import TextField from 'components/common/PlanDoc/KodaContent/TextField'

const TEXT_FIELD_STYLE = {
  marginTop: 2,
  borderTopWidth: 2,
}

export default function getCA(t, defaultPartSettings): PlanSetting {
  const ca: PlanSetting = {
    state: 'California',
    disclaimer: t(
      'CA.disclaimer',
      'Disclaimer: This document needs to be signed and have two witnesses sign as well or be notarized in order to be a legal document.'
    ),
    parts: [
      // Part 1
      {
        ...defaultPartSettings?.[AdPartEnum.health_care_choices],
      },
      // Part 2
      {
        ...defaultPartSettings?.[AdPartEnum.medical_decision_maker],
      },
      // Part 3
      {
        ...defaultPartSettings?.[AdPartEnum.sign_the_form],
        pageTitle: t('CA.sign_the_form.pageTitle', en.CA.sign_the_form.pageTitle),
        notary: {},
        description: t(
          'CA.sign_the_form.description',
          'You can fill out Part 1, Part 2, or both, but you need to sign the form in Part 3. To be legal, two witnesses also need to sign or a notary. There is a special witness requirement for individuals living in a skilled nursing facility.'
        ),
        postSignTheFormContent: (
          <View style={s.sectionContainer}>
            <Text style={s.sectionTitle}>
              {t('CA.sign_the_form.postSignTheFormContent.title', en.CA.sign_the_form.postSignTheFormContent.title)}
            </Text>
            <Text style={{ ...s.formLabel, marginBottom: 16 }}>
              {t('CA.sign_the_form.postSignTheFormContent.text1', en.CA.sign_the_form.postSignTheFormContent.text1)}
            </Text>
            <Text style={{ ...s.formLabel, marginBottom: 16 }}>
              {t('CA.sign_the_form.postSignTheFormContent.text2', en.CA.sign_the_form.postSignTheFormContent.text2)}
            </Text>
            <Text style={s.textBold}>
              {t('CA.sign_the_form.postSignTheFormContent.text3', en.CA.sign_the_form.postSignTheFormContent.text3)}
            </Text>
            <Text style={{ ...s.formLabel, marginBottom: 16 }}>
              {t('CA.sign_the_form.postSignTheFormContent.text4', en.CA.sign_the_form.postSignTheFormContent.text4)}
            </Text>
            <View style={{ display: 'flex', flexDirection: 'row' }}>
              <TextField
                flex={2}
                label={t('MasterPlanDocSignaturePage.signYourName', en.MasterPlanDocSignatureBlock.signYourName)}
                style={TEXT_FIELD_STYLE}
              />
              <TextField
                label={t('MasterPlanDocSignaturePage.dateWithFormat', en.MasterPlanDocSignaturePage.dateWithFormat)}
                style={TEXT_FIELD_STYLE}
              />
            </View>
            <View style={{ display: 'flex', flexDirection: 'row' }}>
              <TextField
                flex={1}
                label={t('MasterPlanDocSignaturePage.printFirstName', en.MasterPlanDocSignaturePage.printFirstName)}
                style={TEXT_FIELD_STYLE}
              />
              <TextField
                flex={1}
                label={t('MasterPlanDocSignaturePage.printLastName', en.MasterPlanDocSignaturePage.printLastName)}
                style={TEXT_FIELD_STYLE}
              />
              {/* <TextField label="Phone #2" /> */}
            </View>
            <View style={{ display: 'flex', flexDirection: 'row' }}>
              <TextField flex={2} label={t('MasterPlanDocMdmInfo.street')} style={TEXT_FIELD_STYLE} />
              <TextField label={t('MasterPlanDocMdmInfo.city')} style={TEXT_FIELD_STYLE} />
              <TextField label={t('MasterPlanDocMdmInfo.state')} style={TEXT_FIELD_STYLE} />
              <TextField label={t('MasterPlanDocMdmInfo.zipCode')} style={TEXT_FIELD_STYLE} />
            </View>
          </View>
        ),
        witnessSignatures: {
          preSignaturesContent: (
            <View style={{ marginTop: 16, paddingBottom: 16 }}>
              <Trans i18nKey="CA.sign_the_form.witnessSignatures.preSignaturesContent">
                <Text style={s.textBold}>STATEMENT OF WITNESSES</Text>
                <Text style={{ ...s.formLabel, marginBottom: 16 }}>
                  I declare under penalty of perjury under the laws of California that: (i) the individual who signed or
                  acknowledged this advance health care directive is personally known to me or that the individual’s
                  identity was proven to me by convincing evidence; (ii) the individual signed or acknowledged this
                  Advance Directive in my presence; (iii) the individual appears to be of sound mind and under no
                  duress, fraud, or undue influence; (iv) I am not a person appointed as agent by this Advance
                  Directive; and (v) I am not the individual’s health care provider, an employee of the individual’s
                  health care provider, the operator of a community care facility, and employee of an operator of a
                  community care facility, the operator of a residential care facility for the elderly, nor an employee
                  of an operator of a residential care facility for the elderly.
                </Text>
              </Trans>
            </View>
          ),
          postSignaturesContent: (
            <View style={{ marginTop: 16, paddingBottom: 40 }}>
              <Trans i18nKey="CA.sign_the_form.witnessSignatures.postSignaturesContent">
                <Text style={s.textBold}>ADDITIONAL STATEMENT OF WITNESSES</Text>
                <Text style={s.formLabel}>
                  At least one of the witnesses listed above must also sign the following declaration:
                </Text>
                <Text style={s.formLabel}>
                  I further declare under penalty of perjury under the laws of California that I am not related to the
                  individual executing this advance health care directive by blood, marriage, or adoption, and to the
                  best of my knowledge, I am not entitled to any part of the individual’s estate upon his or her death
                  under a will now existing or by operation of law.
                </Text>
              </Trans>
              <View style={{ display: 'flex', flexDirection: 'row', width: '50%' }}>
                <TextField
                  label={t('MasterPlanDocSignaturePage.signYourName', en.MasterPlanDocSignatureBlock.signYourName)}
                  style={{
                    marginTop: 3,
                    borderWidth: 4,
                  }}
                  value=""
                />
              </View>
            </View>
          ),
        },
        witnessRequirements: {
          title: t('planDoc.sign_the_form.witnessRequirements_title', 'Witnesses or Notary Requirements'),
          requirements: [
            // ============================
            {
              title: t(
                'planDoc.sign_the_form.witnessRequirements_bothWitnesses',
                en.planDoc.sign_the_form.witnessRequirements_bothWitnesses
              ),
              phrases: [
                t('planDoc.sign_the_form.witnessRequirements_be18', en.planDoc.sign_the_form.witnessRequirements_be18),
                t(
                  'planDoc.sign_the_form.witnessRequirements_seeYouSign',
                  en.planDoc.sign_the_form.witnessRequirements_seeYouSign
                ),
                t(
                  'planDoc.sign_the_form.witnessRequirements_bePresent',
                  en.planDoc.sign_the_form.witnessRequirements_bePresent
                ),
              ],
            },
            // ============================
            {
              title: t(
                'planDoc.sign_the_form.witnessRequirements_yourWitnessCannot',
                en.planDoc.sign_the_form.witnessRequirements_yourWitnessCannot
              ),
              phrases: [
                t(
                  'planDoc.sign_the_form.witnessRequirements_beYourMdm',
                  en.planDoc.sign_the_form.witnessRequirements_beYourMdm
                ),
                t(
                  'planDoc.sign_the_form.witnessRequirements_beYourHealthcareProvider',
                  en.planDoc.sign_the_form.witnessRequirements_beYourHealthcareProvider
                ),
                t(
                  'planDoc.sign_the_form.witnessRequirements_beTheOperatorOfCCFacility',
                  en.planDoc.sign_the_form.witnessRequirements_beTheOperatorOfCCFacility
                ),
                t(
                  'planDoc.sign_the_form.witnessRequirements_beTheOperatorOfRCFacility',
                  en.planDoc.sign_the_form.witnessRequirements_beTheOperatorOfRCFacility
                ),
              ],
            },
            // ============================
            {
              title: t(
                'planDoc.sign_the_form.witnessRequirements_atLeastOneWitnessCannot',
                en.planDoc.sign_the_form.witnessRequirements_atLeastOneWitnessCannot
              ),
              phrases: [
                t(
                  'CA.sign_the_form.witnessRequirements_beRelatedByBloodMarriageOretc',
                  en.CA.sign_the_form.witnessRequirements_specialRequirementDescription
                ),
              ],
            },
            // ============================
            {
              title: t(
                'CA.sign_the_form.witnessRequirements_specialRequirement',
                en.CA.sign_the_form.witnessRequirements_specialRequirement
              ),
              phrases: [
                t(
                  'CA.sign_the_form.witnessRequirements_specialRequirementDescription',
                  en.CA.sign_the_form.witnessRequirements_specialRequirementDescription
                ),
              ],
            },
          ],
          validityRequirements: [
            t(
              'planDoc.sign_the_form.beforeYouSignRequirements_notary',
              en.planDoc.sign_the_form.beforeYouSignRequirements_notary
            ),
            t(
              'planDoc.sign_the_form.beforeYouSignRequirements_witnesses',
              en.planDoc.sign_the_form.beforeYouSignRequirements_witnesses
            ),
          ],
        },
        beforeYouSign: {
          requirements: [
            t(
              'planDoc.sign_the_form.beforeYouSignRequirements_age',
              en.planDoc.sign_the_form.beforeYouSignRequirements_age
            ),
            t(
              'planDoc.sign_the_form.beforeYouSignRequirements_notary',
              en.planDoc.sign_the_form.beforeYouSignRequirements_notary
            ),
            t(
              'planDoc.sign_the_form.beforeYouSignRequirements_witnesses',
              'sign in the presence of two competent adult witnesses.'
            ),
          ],
          finalClause: t(
            'planDoc.sign_the_form.beforeYouSignFinalClause',
            'This Advanced Directive accurately expresses my wishes.'
          ),
        },
      },
    ],
  }

  return ca
}
