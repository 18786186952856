import { useState } from 'react'
import styled from 'styled-components'
import ms from 'ms'
import client from 'ApolloClient/index'
import { useIdleTimer, workerTimers } from 'react-idle-timer'
import Button from 'components/common/Button'
// LIB
import usePatient from 'lib/hooks/usePatient'
import useCurrentUser from 'lib/hooks/useCurrentUser'
// HOOKs
import useLogActivityItemMutation from 'lib/hooks/useLogActivityItemMutation'
import { ActivityLogKeyEnum, CurrentUserDocument } from 'generated/graphql'
import i18n from 'lib/helpers/i18n'
import useSignout from 'lib/hooks/useSignout'
import config from 'lib/config'
import moment from 'moment'

const Title = styled.h2`
  font-size: 22px;
  margin: 0px;
`

const Subtitle = styled.h3`
  font-size: 18px;
  color: ${(p) => p.theme.colors.neutral5};
  font-weight: 400;
`

const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0;
  z-index: 10000;
  background: rgba(0, 0, 0, 0.45);
`

const Modal = styled.div`
  width: 450px;
  max-width: 100%;
  min-height: 180px;
  border-radius: 5px;
  background: #fff;
  padding: 16px;
`

const ButtonContainer = styled.div`
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    div,
    button {
      width: 160px !important;
    }
  }
`

export default function InactiveModal() {
  const [inactiveModal, setInactiveModal] = useState(false)
  const [signOut] = useSignout()
  const currentUser = useCurrentUser()
  const patient = usePatient({ id: currentUser && currentUser.id })
  const [logActivityItem] = useLogActivityItemMutation()

  const onLogout = async () => {
    // don't run if the user is not logged in
    if (!currentUser?.id) return
    await logActivityItem({
      variables: {
        key: ActivityLogKeyEnum.InactiveLogout,
        userId: currentUser?.id || '',
        patientId: patient?.id,
        content: 'Patient logged out from inactivity',
      },
    })
    // sign the user out
    await signOut(currentUser)
  }

  /** if idle for 15m minutes, open the modal */
  const { activate } = useIdleTimer({
    timeout: ms('15m'),
    name: 'logout-timer',
    promptTimeout: ms('5m'),
    onIdle: onLogout,
    timers: workerTimers,
    events: [
      // 'mousemove',
      'keydown',
      'wheel',
      'DOMMouseScroll',
      'mousewheel',
      'mousedown',
      'touchstart',
      'touchmove',
      'MSPointerDown',
      'MSPointerMove',
      // 'visibilitychange'
    ],
    onAction: (event) => {
      if (config.NODE_ENV !== 'production')
        return console.log(`[react-idle-timer-debug]: onAction called ${moment()?.format('hh:mm A')}`, event)
    },
    onActive: async () => {
      if (config.NODE_ENV !== 'production') console.log('[react-idle-timer-debug]: onActive called')
      // after the user has been inactive, and then they take an action, check to see if their tokens are still good
      await client.query({
        query: CurrentUserDocument,
      })
    },
    onPrompt: () => {
      setInactiveModal(true) // show the modal
    },
  })

  // only show the modal if inactiveModal = true
  if (!inactiveModal) return null

  return (
    <Overlay>
      <Modal>
        <div style={{ minHeight: 100 }}>
          <Title>{i18n.t('InactiveModal.title', 'Still there?')}</Title>
          <Subtitle>
            {i18n.t(
              'InactiveModal.subtitle',
              `Since you've been inactive for a few minutes, we're going to log you out to keep your account safe.`
            )}
          </Subtitle>
        </div>
        <ButtonContainer>
          <Button
            style={{ width: 150, marginRight: 8 }}
            onClick={() => {
              activate()
              setInactiveModal(false)
            }}
          >
            {i18n.t('InactiveModal.submitBtn', `I'm still here`)}
          </Button>
          <Button style={{ width: 150 }} grey onClick={onLogout}>
            {i18n.t('InactiveModal.cancelBtn', `Sign out`)}
          </Button>
        </ButtonContainer>
      </Modal>
    </Overlay>
  )
}
