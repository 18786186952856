import { View, Page, Text, StyleSheet } from '@react-pdf/renderer'
import theme from 'lib/theme'
import i18n from 'lib/helpers/i18n'

const s = StyleSheet.create({
  body: {
    paddingHorizontal: 35,
  },
  text: {
    marginTop: 16,
    fontSize: 12,
  },
  pageFooter: {
    position: 'absolute',
    fontSize: 12,
    bottom: 8,
    left: 0,
    right: 0,
    textAlign: 'center',
  },
  pageNumber: {
    fontSize: 12,
    textAlign: 'center',
    color: theme.colors.neutral5,
  },
  pageHeader: {
    position: 'absolute',
    fontSize: 12,
    top: 0,
    left: 0,
    right: 0,
  },
  page: {
    position: 'relative',
    paddingBottom: 16,
    paddingTop: 48,
  },
  pageFooterRight: {
    position: 'absolute',
    fontSize: 12,
    left: 0,
    right: 32,
    textAlign: 'right',
    color: theme.colors.neutral5,
  },
})

const wrap = true

interface TopPageBlockProps {
  pageTitleLeft?: string
  pageTitleRight?: string
}

const TopPageBlock = ({ pageTitleLeft, pageTitleRight }: TopPageBlockProps) => {
  const fontSize = i18n.language === 'en' ? 14 : 12
  return (
    <View
      style={{
        paddingTop: i18n.language === 'en' ? 8 : 10,
        paddingLeft: 32,
        backgroundColor: theme.colors.primary1,
        height: 32,
        position: 'relative',
      }}
    >
      {pageTitleLeft && <Text style={{ fontFamily: 'Roboto Bold', fontSize, color: '#fff' }}>{pageTitleLeft}</Text>}

      {pageTitleRight && (
        <Text
          style={{
            fontFamily: 'Roboto Bold',
            fontSize,
            color: '#fff',
            position: 'absolute',
            right: 16,
            top: i18n.language === 'en' ? 8 : 10,
          }}
        >
          {pageTitleRight}
        </Text>
      )}
    </View>
  )
}

interface PlanSectionDocumentProps {
  pageTitleRight?: string
  pageTitleLeft?: string
  content: JSX.Element
  pageFooterRight?: string
  showFooter: boolean
}

/**
 * Render multiple pages (e.g. an entire section of the guide PDF)
 * This will automatically wrap the content, add page headers and footers
 */
export default function PlanSectionDocument({
  pageTitleRight,
  pageTitleLeft,
  content,
  pageFooterRight,
  showFooter,
}: PlanSectionDocumentProps) {
  return (
    <Page wrap={wrap} size="A4" style={s.page}>
      {pageTitleRight || pageTitleLeft ? (
        <View fixed style={s.pageHeader}>
          <TopPageBlock pageTitleLeft={pageTitleLeft} pageTitleRight={pageTitleRight} />
        </View>
      ) : null}
      <View style={s.body} wrap={wrap}>
        {content}
      </View>
      {showFooter && (
        <View fixed style={s.pageFooter}>
          <Text style={s.pageNumber} render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`} fixed />
          {pageFooterRight && <Text style={s.pageFooterRight}>{pageFooterRight}</Text>}
        </View>
      )}
    </Page>
  )
}
