import { useCallback } from 'react'
import styled from 'styled-components'

import Collapse from '../Collapse'
import ElephantChat from '../ElephantChat'
import VideoPlayer from '../VideoPlayer'
import ButtonsContainer from '../SurveyForm/ButtonsContainer'

import getAgeRange, {
  getCprStatsByAgeRange,
  getCprSurvivalRates,
  getCprUsers,
  getCprTextByAgeRange,
} from 'lib/helpers/getAgeRange'
import theme from 'lib/theme'
import QuestionText from 'components/text/QuestionText'
import Caption from 'components/text/Caption'
import { ActivityLogKeyEnum, useLogActivityItemMutation, Patient } from 'generated/graphql'
import { useTranslation } from 'react-i18next'

const { Panel } = Collapse

const MedicalCareYesNoWrapper = styled.div`
  max-width: 650px;
  margin: 48px auto;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    padding: 0 30px;
  }
`

const MedicalCareYesNoButtons = styled.div<{ inactive?: boolean }>`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 40px 0;
  button {
    width: 120px;
    height: 56px;
    background: ${(props) => (props.inactive ? '#CBD2D9' : '#01337D')};
    border-radius: 4px;
    color: ${(props) => (props.inactive ? '#323F4B' : 'white')};
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    border: none;
    cursor: pointer;
  }
`

const MedicalCareCprWithVideoTitle = styled.div`
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 16px;
  color: #323f4b;
`

const AnswerText = styled(QuestionText)`
  font-size: 18px;
  line-height: 125%;
  margin-bottom: 16px;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    font-size: 16px;
  }
`

const CollapseTitle = styled.div`
  margin-top: 48px;
  margin-bottom: 13px;
  letter-spacing: -0.03em;
  color: #323f4b;
  font-size: 18px;
  line-height: 24px;
`

const Info = styled.div`
  padding-bottom: 80px;
`

interface MedicalCareCprWithVideoProps {
  patient?: Patient
  setPlan?: (patient: Patient) => void
  handleSavePatient: (plan: Patient, callback: () => void) => void
  nextButton?: string
  progress?: string
  onNext?: (plan: Patient) => void
  onBack?: () => void
}

const MedicalCareCprWithVideo = ({
  patient,
  setPlan,
  handleSavePatient,
  nextButton,
  progress,
  onNext,
  onBack,
}: MedicalCareCprWithVideoProps) => {
  const [logActivityItem] = useLogActivityItemMutation()
  const { t } = useTranslation()
  const cprUsers = getCprUsers()
  const goNext = useCallback(() => {
    const newPlan = {
      ...patient,
      cprAgeGroupVideo: true,
    }

    if (setPlan) {
      setPlan(newPlan as Patient)
    }

    handleSavePatient(newPlan as Patient, () => {
      if (onNext) {
        onNext(newPlan as Patient)
      }
    })
  }, [patient, setPlan, handleSavePatient, onNext])

  const getVideoId = useCallback(() => {
    const age = patient?.age as number
    if (age < 60) {
      return '662973335'
    } else if (age >= 60 && age <= 69) {
      return '662971724'
    } else if (age >= 70 && age <= 79) {
      return '662972537'
    } else if (age >= 80 && age <= 89) {
      return '662973058'
    }
    return '662973199'
  }, [patient?.age])

  const logClick = useCallback(
    (key) => {
      if (key === ActivityLogKeyEnum.PatientClickedAgeSpecificData) {
        logActivityItem({
          variables: {
            key: ActivityLogKeyEnum.PatientClickedAgeSpecificData,
            userId: patient?.userId || '',
            patientId: (patient && patient.id) || '',
            content: `User clicked age specific data on CPR Age Specific Page`,
          },
        })
      } else if (key === ActivityLogKeyEnum.PatientClickedOtherAgeRangesData) {
        logActivityItem({
          variables: {
            key: ActivityLogKeyEnum.PatientClickedOtherAgeRangesData,
            userId: patient?.userId || '',
            patientId: (patient && patient.id) || '',
            content: `User clicked other age ranges on CPR Age Specific Page`,
          },
        })
      }
    },
    [logActivityItem, patient]
  )

  const ageRange = getAgeRange(patient?.age as number)
  const cprSurvivalRates = getCprSurvivalRates()
  const cprTextByAgeRange = getCprTextByAgeRange()
  const cprStatsByAgeRange = getCprStatsByAgeRange()

  return (
    <MedicalCareYesNoWrapper>
      <ElephantChat
        text={t('cprAgeGroupVideo.text', {
          ageGroup: ageRange,
        })}
        bubleStyle={{ filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))' }}
      />
      <MedicalCareYesNoButtons inactive>
        <button>{t('cprAgeGroupVideo.yes')}</button>
      </MedicalCareYesNoButtons>
      <MedicalCareCprWithVideoTitle>{t('cprAgeGroupVideo.watchVideoBelow')}</MedicalCareCprWithVideoTitle>
      <VideoPlayer
        videoId={getVideoId()}
        onNext={goNext}
        required
        showButtons
        hideSkipButton={true}
        stepName="videoCPR"
      />

      <CollapseTitle>{t('cprAgeGroupVideo.learnMore')}</CollapseTitle>
      <Info>
        <Collapse expandIconPosition="right">
          <Panel
            key="CPR1"
            header={
              <div onClick={() => logClick(ActivityLogKeyEnum.PatientClickedAgeSpecificData)}>
                {t('cprAgeGroupVideo.howDoesCprAffect', {
                  ageGroup: ageRange,
                })}
              </div>
            }
          >
            <div>
              <AnswerText>{t('cprAgeGroupVideo.forPeopleInYourAge')}</AnswerText>
              <AnswerText
                style={{
                  margin: 'auto',
                  textAlign: 'center',
                  fontSize: 24,
                  fontWeight: 600,
                  color: theme.colors.primary3,
                  marginBottom: 24,
                  marginTop: 16,
                }}
              >
                {ageRange && cprSurvivalRates?.[ageRange]}
              </AnswerText>
              {ageRange && cprUsers?.[ageRange]}
              {ageRange && cprTextByAgeRange?.[ageRange]}
              <AnswerText>{t('cprAgeGroupVideo.healthierMoreIndependent')}</AnswerText>
              <Caption>{t('cprAgeGroupVideo.sources')}</Caption>
              <Caption>{t('cprAgeGroupVideo.resuscitation')}</Caption>
              <Caption>{t('cprAgeGroupVideo.archInternMed')}</Caption>
            </div>
          </Panel>
          <Panel
            key="CPR2"
            header={
              <div onClick={() => logClick(ActivityLogKeyEnum.PatientClickedOtherAgeRangesData)}>
                {t('cprAgeGroupVideo.howCprAffectOtherAges')}
              </div>
            }
          >
            <div>
              <ul>
                {Object.keys(cprStatsByAgeRange)?.map((key) => {
                  if (key !== ageRange) {
                    return (
                      <li key={key}>
                        <AnswerText>{cprStatsByAgeRange[key]}</AnswerText>
                      </li>
                    )
                  }
                  return null
                })}
              </ul>
              <AnswerText>{t('cprAgeGroupVideo.whoAreMoreFrail')}</AnswerText>

              <AnswerText>{t('cprAgeGroupVideo.healthierMoreIndependentPeople')}</AnswerText>

              <Caption>{t('cprAgeGroupVideo.sources')}</Caption>
              <Caption>{t('cprAgeGroupVideo.resuscitation')}</Caption>
              <Caption>{t('cprAgeGroupVideo.archInternMed')}</Caption>
            </div>
          </Panel>
        </Collapse>
      </Info>
      <ButtonsContainer onNext={goNext} onBack={onBack} nextButton={nextButton} progress={progress} />
    </MedicalCareYesNoWrapper>
  )
}

export default MedicalCareCprWithVideo
