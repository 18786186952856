import { AdPartEnum, PlanSetting } from '../types'
import en from 'lib/helpers/locales/en/translation.json'
import { Text } from '@react-pdf/renderer'
import s from '../../../KodaContent/styles'
import { Trans } from 'react-i18next'

export default function getIL(t, defaultPartSettings, patient): PlanSetting {
  const patientFullName = `${patient?.firstName || ''} ${patient?.lastName || ''}`
  const il: PlanSetting = {
    state: 'Illinois',
    disclaimer: t(
      'IL.disclaimer',
      'Disclaimer: This document needs to be signed and have two witnesses sign as well in order to be a legal document.'
    ),
    parts: [
      // Part 1
      {
        ...defaultPartSettings?.[AdPartEnum.health_care_choices],
      },
      // Part 2
      {
        ...defaultPartSettings?.[AdPartEnum.medical_decision_maker],
        pageTitle: t(
          'IL.medical_decision_maker.pageTitle',
          'Information for Medical Decision Maker (Health Care Power of Attorney)'
        ),
        description: t(
          'IL.medical_decision_maker.description',
          'This part is also known as a Health Care Power of Attorney. It designates a person to make decisions for you when you are unable to speak for yourself. Your medical decision maker cannot be your attending physician or any other health care professional providing care to you.'
        ),
      },
      // Part 3
      {
        ...defaultPartSettings?.[AdPartEnum.sign_the_form],
        description: t(
          'IL.sign_the_form.description',
          'You can fill out Part 1, Part 2, or both, but you need to sign the form in Part 3. To be legal, two witnesses also need to sign.'
        ),
        beforeYouSign: {
          requirements: [
            t(
              'planDoc.sign_the_form.beforeYouSignRequirements_age',
              en.planDoc.sign_the_form.beforeYouSignRequirements_age
            ),
            t(
              'planDoc.sign_the_form.beforeYouSignRequirements_witnesses',
              en.planDoc.sign_the_form.beforeYouSignRequirements_witnesses
            ),
          ],
          finalClause: t(
            'IL.sign_the_form.beforeYouSignFinalClause',
            'By signing, I confirm that I am emotionally and mentally capable of making this document. I am thinking clearly, I have made this document willingly, and this Advance Directive accurately expresses my wishes.'
          ),
        },
        witnessSignatures: {
          preSignaturesContent: (
            <Text style={{ ...s.text, fontSize: 11 }}>
              <Trans i18nKey="IL.sign_the_form.witnessSignatures.preSignaturesContent">
                By signing, I confirm that I watched {{ patientFullName: patientFullName || '____________________ ' }}
                sign the document. I confirm that I know
                {{ patientFullName: patientFullName || '____________________ ' }}.
                {{ patientFullName: patientFullName || '____________________ ' }} appears to be emotionally competent,
                of sound mind, and under no duress, fraud, or undue influence in signing the document. I promise that I
                meet the witness requirements listed in the document.
              </Trans>
            </Text>
          ),
          //
        },
        witnessRequirements: {
          title: t('planDoc.sign_the_form.witnessRequirementsTitle', 'Witnesses Requirements'), // OR "Witness and Notary Requirements"
          requirements: [
            {
              title: t(
                'planDoc.sign_the_form.witnessRequirements_bothWitnesses',
                en.planDoc.sign_the_form.witnessRequirements_bothWitnesses
              ),
              width: '30%',
              phrases: [
                t('planDoc.sign_the_form.witnessRequirements_be18', en.planDoc.sign_the_form.witnessRequirements_be18),
                t(
                  'planDoc.sign_the_form.witnessRequirements_seeYouSign',
                  en.planDoc.sign_the_form.witnessRequirements_seeYouSign
                ),
              ],
            },
            {
              title: t(
                'planDoc.sign_the_form.witnessRequirements_yourWitnessCannot',
                en.planDoc.sign_the_form.witnessRequirements_yourWitnessCannot
              ),
              width: '70%',
              phrases: [
                t(
                  'planDoc.sign_the_form.witnessRequirements_beYourMdm',
                  en.planDoc.sign_the_form.witnessRequirements_beYourMdm
                ),
                t(
                  'planDoc.sign_the_form.witnessRequirements_beRelatedBloodMarriageAdoption',
                  en.planDoc.sign_the_form.witnessRequirements_beRelatedBloodMarriageAdoption
                ),
                t(
                  'planDoc.sign_the_form.witnessRequirements_beRelatedMdmByBloodMarriageOrAdoption',
                  en.planDoc.sign_the_form.witnessRequirements_beRelatedMdmByBloodMarriageOrAdoption
                ),
                t(
                  'planDoc.sign_the_form.witnessRequirements_bePhysicianNurseDentistEtc',
                  en.planDoc.sign_the_form.witnessRequirements_bePhysicianNurseDentistEtc
                ),
                t(
                  'planDoc.sign_the_form.witnessRequirements_beOwnerOrOperatorOfHealthcareFacility',
                  en.planDoc.sign_the_form.witnessRequirements_beOwnerOrOperatorOfHealthcareFacility
                ),
              ],
            },
          ],
          validityRequirements: [
            t(
              'planDoc.sign_the_form.validityRequirements_esignInThePresence',
              en.planDoc.sign_the_form.validityRequirements_esignInThePresence
            ),
            t(
              'planDoc.sign_the_form.validityRequirements_prinatAndSignBeforeTwoWitnesses',
              en.planDoc.sign_the_form.validityRequirements_prinatAndSignBeforeTwoWitnesses
            ),
          ],
        },
      },
    ],
  }

  return il
}
