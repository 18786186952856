import { AdPartEnum, PlanSetting } from '../types'
import en from 'lib/helpers/locales/en/translation.json'
import { View, Text } from '@react-pdf/renderer'
import s from 'components/common/PlanDoc/KodaContent/styles'
import { Patient, PatientFragmentFragment } from 'generated/graphql'
import { DeepPartial } from 'utility-types'
import BulletText from 'components/common/PlanDoc/KodaContent/BulletText'
import i18n from 'lib/helpers/i18n'

export default function getMa(
  t,
  defaultPartSettings,
  patient: Patient | PatientFragmentFragment | DeepPartial<Patient>
): PlanSetting {
  const ma: PlanSetting = {
    state: 'Massachusetts',
    disclaimer: t('MA.disclaimer', en.MA.disclaimer),
    parts: [
      // Part 1
      {
        ...defaultPartSettings?.[AdPartEnum.health_care_choices],
        description: t('MA.sign_the_form.description', en.MA.sign_the_form.description),
      },
      // Part 2
      {
        ...defaultPartSettings?.[AdPartEnum.medical_decision_maker],
        pageTitle: t('MA.medical_decision_maker.pageTitle', en.MA.medical_decision_maker.pageTitle),
        description: t('MA.medical_decision_maker.description', en.MA.medical_decision_maker.description),
        additionalContent: (
          <>
            <Text style={{ ...s.textBold, marginBottom: 2 }}>
              {t('MA.medical_decision_maker.revocation', en.MA.medical_decision_maker.revocation)}
            </Text>
            <Text style={s.formLabel}>
              {t('MA.medical_decision_maker.revocationSubtitle', en.MA.medical_decision_maker.revocationSubtitle)}
            </Text>
            <BulletText
              fontSize={11}
              text={i18n.t(
                'MA.medical_decision_maker.revocation1',
                'execution by you of a subsequent medical decision maker; or'
              )}
            />
            <BulletText
              fontSize={11}
              text={i18n.t(
                'MA.medical_decision_maker.revocation2',
                'the divorce or legal separation of you and your spouse, if your spouse is your medical decision maker'
              )}
            />
          </>
        ),
      },
      // Part 3
      {
        ...defaultPartSettings?.[AdPartEnum.sign_the_form],
        beforeYouSign: {
          requirements: [
            t(
              'planDoc.sign_the_form.beforeYouSignRequirements_age',
              en.planDoc.sign_the_form.beforeYouSignRequirements_age
            ),
            t(
              'planDoc.sign_the_form.beforeYouSignRequirements_witnesses',
              en.planDoc.sign_the_form.beforeYouSignRequirements_witnesses
            ),
          ],
          finalClause: t(
            'MA.sign_the_form.beforeYouSignFinalClause',
            'By signing below, I state that I am emotionally and mentally capable of making this advance directive for health care and that I understand its purpose and effect.'
          ),
        },
        witnessSignatures: {
          preSignaturesContent: (
            <View style={{ marginBottom: 3 }}>
              <Text style={s.formLabel}>
                {t('MA.sign_the_form.witnessRequirements_title', en.MA.sign_the_form.witnessRequirements_title, {
                  patientFullName: `${patient.firstName} ${patient.lastName}`,
                })}
              </Text>
            </View>
          ),
        },
        witnessRequirements: {
          title: t('planDoc.sign_the_form.witnessRequirements_title', 'Witnesses or Notary Requirements'), // OR "Witness and Notary Requirements"
          requirements: [
            {
              title: t(
                'planDoc.sign_the_form.witnessRequirements_bothWitnesses',
                en.planDoc.sign_the_form.witnessRequirements_bothWitnesses
              ),
              width: '50%',
              phrases: [
                t('planDoc.sign_the_form.witnessRequirements_be18', en.planDoc.sign_the_form.witnessRequirements_be18),
                t(
                  'planDoc.sign_the_form.witnessRequirements_seeYouSign',
                  en.planDoc.sign_the_form.witnessRequirements_seeYouSign
                ),
              ],
            },
            {
              title: t(
                'planDoc.sign_the_form.witnessRequirements_yourWitnessCannot',
                en.planDoc.sign_the_form.witnessRequirements_yourWitnessCannot
              ),
              width: '50%',
              phrases: [
                t(
                  'planDoc.sign_the_form.witnessRequirements_beYourMdm',
                  en.planDoc.sign_the_form.witnessRequirements_beYourMdm
                ),
              ],
            },
          ],
          validityRequirements: [
            t(
              'planDoc.sign_the_form.validityRequirements_esignInThePresence',
              en.planDoc.sign_the_form.validityRequirements_esignInThePresence
            ),
            t(
              'planDoc.sign_the_form.validityRequirements_prinatAndSignBeforeTwoWitnesses',
              en.planDoc.sign_the_form.validityRequirements_prinatAndSignBeforeTwoWitnesses
            ),
          ],
        },
      },
    ],
  }

  return ma
}
