import { AdPartEnum, PlanSetting } from '../types'
import en from 'lib/helpers/locales/en/translation.json'
import { Text, View } from '@react-pdf/renderer'
import s from '../../../KodaContent/styles'
import { Trans } from 'react-i18next'

export default function getWA(t, defaultPartSettings, patient): PlanSetting {
  const patientFullName = `${patient?.firstName || ''} ${patient?.lastName || ''}`

  const wa: PlanSetting = {
    state: 'Washington',
    disclaimer: t(
      'WA.disclaimer',
      'Disclaimer: This document needs to be signed and have two witnesses sign as well or be notarized in order to be a legal document.'
    ),
    parts: [
      // Part 1
      {
        ...defaultPartSettings?.[AdPartEnum.health_care_choices],
      },
      // Part 2
      {
        ...defaultPartSettings?.[AdPartEnum.medical_decision_maker],
        pageTitle: t(
          'WA.medical_decision_maker.pageTitle',
          'Information for Medical Decision Maker (Health Care Power of Attorney)'
        ),
        description: t(
          'WA.medical_decision_maker.description',
          'This part is also known as a Health Care Power of Attorney. It designates a person to make decisions for you when you are unable to speak for yourself. Your medical decision maker cannot be your physician, an employee of your physician, or an owner, operator, or employee of the health care institution at which you are receiving care unless the individual is your spouse, state registered domestic partner, parent, adult child, or sibling.'
        ),
      },
      // Part 3
      {
        ...defaultPartSettings?.[AdPartEnum.sign_the_form],
        description: t(
          'WA.sign_the_form.description',
          'You can fill out Part 1, Part 2, or both, but you need to sign the form in Part 3. To be legal, two witnesses also need to sign or a notary.'
        ),
        beforeYouSign: {
          requirements: [
            t(
              'planDoc.sign_the_form.beforeYouSignRequirements_age',
              en.planDoc.sign_the_form.beforeYouSignRequirements_age
            ),
            t(
              'planDoc.sign_the_form.beforeYouSignRequirements_notary',
              en.planDoc.sign_the_form.beforeYouSignRequirements_notary
            ),
            t(
              'planDoc.sign_the_form.beforeYouSignRequirements_witnesses',
              en.planDoc.sign_the_form.beforeYouSignRequirements_witnesses
            ),
          ],
          finalClause: t(
            'WA.sign_the_form.beforeYouSignFinalClause',
            'By signing, I confirm that I am emotionally and mentally capable of making this document. I have made this Advance Directive willingly, I understand the purpose and effect of this document, and this document accurately expresses my wishes.'
          ),
        },
        witnessSignatures: {
          preSignaturesContent: (
            <Text style={s.formLabel}>
              <Trans i18nKey="WA.sign_the_form.witness1SignatureText">
                By signing, I confirm that I watched {{ patientFullName: patientFullName || '____________________ ' }}{' '}
                sign the document. I confirm that I know{' '}
                {{ patientFullName: patientFullName || '____________________ ' }}.{' '}
                {{ patientFullName: patientFullName || '____________________ ' }} appears to be emotionally competent,
                of sound mind, and under no duress, fraud, or undue influence in signing the document. I promise that I
                meet the witness requirements listed in the document.
              </Trans>
            </Text>
          ),
        },
        witnessRequirements: {
          title: t('planDoc.sign_the_form.witnessRequirements_title', 'Witnesses or Notary Requirements'), // OR "Witness and Notary Requirements"
          requirements: [
            {
              title: t(
                'planDoc.sign_the_form.witnessRequirements_bothWitnesses',
                en.planDoc.sign_the_form.witnessRequirements_bothWitnesses
              ),
              width: '35%',
              phrases: [
                t('planDoc.sign_the_form.witnessRequirements_be18', en.planDoc.sign_the_form.witnessRequirements_be18),
                t(
                  'planDoc.sign_the_form.witnessRequirements_seeYouSign',
                  en.planDoc.sign_the_form.witnessRequirements_seeYouSign
                ),
              ],
            },
            {
              title: t(
                'planDoc.sign_the_form.witnessRequirements_yourWitnessCannot',
                en.planDoc.sign_the_form.witnessRequirements_yourWitnessCannot
              ),
              width: '65%',
              phrases: [
                t(
                  'planDoc.sign_the_form.witnessRequirements_beBloodOrMarriage',
                  en.planDoc.sign_the_form.witnessRequirements_beBloodOrMarriage
                ),
                t(
                  'planDoc.sign_the_form.witnessRequirements_entitledToAnyPartOfEstateOrWill',
                  en.planDoc.sign_the_form.witnessRequirements_entitledToAnyPartOfEstateOrWill
                ),
                t(
                  'planDoc.sign_the_form.witnessRequirements_bePersonWithClaimAgainstEstate',
                  en.planDoc.sign_the_form.witnessRequirements_bePersonWithClaimAgainstEstate
                ),
                t(
                  'planDoc.sign_the_form.witnessRequirements_beYourPhysician',
                  en.planDoc.sign_the_form.witnessRequirements_beYourPhysician
                ),
                t(
                  'planDoc.sign_the_form.witnessRequirements_beEmployeePhysicianOrHealthcareFacility',
                  en.planDoc.sign_the_form.witnessRequirements_beEmployeePhysicianOrHealthcareFacility
                ),
              ],
            },
          ],
          validityRequirements: [
            t(
              'planDoc.sign_the_form.validityRequirements_esignInThePresence',
              en.planDoc.sign_the_form.validityRequirements_esignInThePresence
            ),
            t(
              'planDoc.sign_the_form.validityRequirements_prinatAndSignBeforeTwoWitnessesOr',
              en.planDoc.sign_the_form.validityRequirements_prinatAndSignBeforeTwoWitnessesOr
            ),
            t(
              'planDoc.sign_the_form.validityRequirements_prinatAndSignBeforeNotary',
              en.planDoc.sign_the_form.validityRequirements_prinatAndSignBeforeNotary
            ),
          ],
        },
        notary: {},
      },
      {
        ...defaultPartSettings?.[AdPartEnum.faqs_and_legal],
        sections: [
          <View style={s.sectionContainer} key="Pregnancy Legal Disclaimer">
            <Text style={s.sectionTitle}>
              {t('WA.faqs_and_legal.pregnancyTitle', en.WA.faqs_and_legal.pregnancyTitle)}
            </Text>
            <Text style={{ ...s.text }}>
              {t('WA.faqs_and_legal.pregnancyClause', en.WA.faqs_and_legal.pregnancyClause)}
            </Text>
          </View>,
        ],
      },
    ],
  }

  return wa
}
