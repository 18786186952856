import logo from 'lib/media/logo-light-bg-horizontal.svg'
import {
  ActivityLogKeyEnum,
  useCreateOneActionHistoryItemMutation,
  useGetOnePatientQuery,
  useLogActivityItemMutation,
  UserProfile,
} from 'generated/graphql'
import identifyUser from 'lib/helpers/identifyUser'
import { useEffect, useCallback } from 'react'
import Col from 'components/common/Col'
import Row from 'components/common/Row'
import RepAvatar from './images/RepAvatar.png'
import ExitButton from 'components/common/ExitButton'
import Button from 'components/common/Button'
import {
  BookACallText,
  BottomLink,
  ButtonContainer,
  Container,
  Header,
  HeaderContainer,
  Logo,
  SchedulerContainer,
  Title,
  TitleIcon,
} from './styledBookACall/book-a-call.styled'
import i18n from 'lib/helpers/i18n'
import useSignOut from 'lib/hooks/useSignout'
import usePatient from 'lib/hooks/usePatient'
import translateApolloError from 'lib/helpers/translateApolloError'
import message from 'components/common/message'
import { useHistory } from 'react-router-dom'
import logEvent from 'lib/helpers/logEvent'
import { AnalyticEventTypeEnums } from 'lib/types/AnalyticEventTypeEnums'
import userFragment from 'ApolloClient/Fragments/userFragment'
import currentUser from 'ApolloClient/Queries/currentUser'

interface BookACallProps {
  currentUser: UserProfile
}

export default function AppBookACall(props: BookACallProps) {
  const history = useHistory()
  const location = useLogActivityItemMutation()
  const [createOneActionHistoryItemMutation] = useCreateOneActionHistoryItemMutation()
  const [signOut] = useSignOut()
  const [logActivityItem] = useLogActivityItemMutation()
  const patient = usePatient({ id: props?.currentUser && props?.currentUser.id })

  const onLogout = async () => {
    try {
      logActivityItem({
        variables: {
          key: ActivityLogKeyEnum.SaveAndExit,
          importance: 10,
          userId: props?.currentUser?.id || '',
          patientId: (patient && patient.id) || '',
          content: `Patient has saved and exited`,
        },
      })
      await signOut(props?.currentUser)
      logEvent(AnalyticEventTypeEnums.SIGNOUT_AND_CONTINUE_LATER_CLICKED, {
        clinicId: props?.currentUser?.clinic?.id,
      })
    } catch (err: any) {
      message.error(translateApolloError(err))
    }
  }

  const { data } = useGetOnePatientQuery({
    variables: {
      userId: props?.currentUser?.id,
    },
  })

  useEffect(() => {
    if (props?.currentUser?.id && data?.getOnePatient?.clinicId) {
      // identify the user in mixpanel & fullstory
      identifyUser(props?.currentUser?.id, data?.getOnePatient?.clinicId)
    }
  }, [props?.currentUser?.id, data?.getOnePatient?.clinicId])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Container>
      <HeaderContainer>
        <Header>
          <Logo src={props.currentUser.clinic?.logo || logo} alt="Koda Health" width="270" />
          <ExitButton onClick={() => history.push('/app/home')} />
        </Header>
      </HeaderContainer>
      <div>
        <SchedulerContainer>
          
          <div>
          <Title>Hi {props?.currentUser.firstName}</Title>
            <BookACallText>My name is Jadzia, and I’m your personal Koda Support Representative.</BookACallText>
            <BookACallText>
              I’ll be reaching out by phone to answer any questions you have about advance care planning or the app.
            </BookACallText>
            {/* <Text>
                        If you prefer, you can schedule a call. Here are a few suggested times:
                    </Text> */}
          </div>
            <TitleIcon src={RepAvatar} style={{ display: 'block' }} />

        </SchedulerContainer>
        <ButtonContainer>
          <Button
            data-testid="book-a-call-continue"
            style={{ width: 165 }}
            onClick={() => history.push('/app/onboarding')}
          >
            {i18n.t('signupPageV2.continue', 'Continue')}
          </Button>
          <BottomLink onClick={onLogout}>
            {i18n.t('signupPage.signOutContinueLater', 'Sign Out and Continue Later ')}
          </BottomLink>
        </ButtonContainer>
      </div>
    </Container>
  )
}