import { View, Text } from '@react-pdf/renderer';
import theme from 'lib/theme';
import s from '../styles';

interface TextFieldProps {
  label: string;
  flex?: number;
  value?: string;
  style?: {};
  /** a style object that can overwrite the value tax */
  valueStyle?: {};
}

const TextField = ({
  label,
  flex = 1,
  value,
  style = {},
  valueStyle = {},
}: TextFieldProps) => (
  <View
    style={{
      borderWidth: 4,
      borderColor: '#fff',
      flex,
      marginTop: 4,
      borderBottomWidth: 0,
      ...(style || {}),
    }}
  >
    <Text style={{ ...s.textNoMargin, ...valueStyle }}>{value || ' '}</Text>
    <View>
      <View
        style={{
          height: 1,
          backgroundColor: theme.colors.neutral8,
        }}
      />
    </View>
    <Text style={{ ...s.formLabel, fontSize: 10 }}>{label}</Text>
  </View>
);

export default TextField;
