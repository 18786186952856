import { Clinic } from 'generated/graphql'
import { RouteComponentProps, useHistory, useLocation } from 'react-router-dom'
import logo from 'lib/media/logo-light-bg-horizontal.svg'
import { useCallback, useEffect, useRef, useState } from 'react'
import {
  BottomLink,
  BottomText,
  ButtonContainer,
  Caption,
  CaptionLink,
  Checkbox,
  Logo,
  RedTextWrapper,
  RequiredFieldsText,
  Title,
  UseAsGuestText,
  Container,
  HeaderContainer,
} from './styledSignup/signupFormV2.styled'
import queryString from 'query-string'
import i18n from 'lib/helpers/i18n'
import useCheckBirthdayErrors from 'lib/hooks/useCheckBirthdayErrors'
import Button from 'components/common/Button'
import TextInput from 'components/inputs/TextInput'
import BirthdayInput from 'components/inputs/BirthdayInput'
import StatesInput from 'components/inputs/StateInput'
import FormItem from 'components/common/FormItem'
import Row from 'components/common/Row'
import Col from 'components/common/Col'
import ErrorBlock from 'components/common/ErrorBlock'
import constants from 'lib/config'
import { validate } from 'email-validator'
import useCheckPhoneNumberErrors from 'lib/hooks/useCheckPhoneNumberErrors'
import Step2 from './Step2'
import useCheckPasswordErrors from 'lib/hooks/useCheckPasswordErrors'
import { LinkButton } from 'routes/app-esign'
import logEvent from 'lib/helpers/logEvent'
import { AnalyticEventTypeEnums } from 'lib/types/AnalyticEventTypeEnums'
import { message } from 'antd'
import ms from 'ms'

interface SignupFormProps {
  onChange: (e: { [x: string]: string | number | undefined }) => void
  user: any
  signupError: string
  onBack: () => void
  loading: boolean
  onSubmit: () => void
  onChangeForm: (v: string) => void
  clinic?: Clinic
  match: RouteComponentProps<{ slug?: string }>
}

function SignupFormV2({
  onChange,
  user,
  signupError,
  onBack,
  loading,
  onSubmit,
  onChangeForm,
  clinic,
}: SignupFormProps) {
  const history = useHistory()
  const location = useLocation()
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false)
  const firstNameInput = useRef<HTMLInputElement | undefined>()
  const emailInput = useRef<HTMLInputElement | undefined>()
  const [checkBirthdayErrors] = useCheckBirthdayErrors()
  const [checkPhoneNumberErrors] = useCheckPhoneNumberErrors()
  const { checkPasswordErrors } = useCheckPasswordErrors()
  const [errors, setErrors] = useState<{
    email?: string
    password?: string
    confirmPassword?: string
    stateId?: string
    firstName?: string
    lastName?: string
    birthday?: string
    agreeToTerms?: string
    phoneNumber?: string
  }>({
    email: undefined,
    password: undefined,
    stateId: undefined,
    firstName: undefined,
    lastName: undefined,
    birthday: undefined,
    agreeToTerms: undefined,
    phoneNumber: undefined,
  })

  useEffect(() => {
    if (!user.phoneNumber && step === '2') {
      message.info(i18n.t('signupPage.FormResetRedirect', 'Form was reset, redirecting...'), 1.5)
      setTimeout(() => {
        history.replace({ search: `?formType=signup` })
      }, ms('2s'))
    }

    if (step === '1') {
      window.scrollTo(0, 0)
      firstNameInput?.current?.focus()
    } else if (step === '2') {
      window.scrollTo(0, 0)
      emailInput?.current?.focus()
    }
  }, [history])

  const onBeforeChange = (newValues) => {
    setErrors({})
    onChange(newValues)
  }

  const { step = '1', repeat }: { step?: string; repeat?: string } = queryString.parse(location.search)

  const onNext = useCallback(() => {
    let newUrl = {
      step: parseInt(step) + 1,
      repeat,
    }
    history.push(`/${clinic?.slug}?formType=signup&${queryString.stringify(newUrl)}`)
  }, [history, repeat, step])

  const onBackHandler = useCallback(() => {
    let newUrl = {
      step: parseInt(step) - 1,
      repeat,
    }
    history.push(`/${clinic?.slug}?formType=signup&${queryString.stringify(newUrl)}`)
  }, [history, repeat, step])

  const onBeforeSubmit = (e) => {
    e.preventDefault()
    setIsSubmitted(true)

    if (step === '1') {
      if (!user.firstName || user.firstName.length === 0) {
        return setErrors({ firstName: i18n.t('signupPage.provideFirstName', 'Please provide your first name.') })
      }
      if (!user.lastName || user.lastName.length === 0) {
        return setErrors({ lastName: i18n.t('signupPage.provideLastName', 'Please provide your last name.') })
      }

      const phoneNumberError = checkPhoneNumberErrors(user.phoneNumber)

      if (phoneNumberError) return setErrors({ phoneNumber: phoneNumberError })

      const birthdayError = checkBirthdayErrors(user.birthday)
      if (birthdayError) return setErrors({ birthday: birthdayError })

      if (!user.stateId) {
        return setErrors({ stateId: i18n.t('signupPage.selectState', 'Please select a state.') })
      }
      if (!user.agreeToTerms) {
        return setErrors({
          agreeToTerms: i18n.t('signupPage.acceptTermsService', 'Please accept the terms of service'),
        })
      }

      if (!user.stateId) {
        return
      }
      onNext()
    }

    if (step === '2') {
      if (!user.email) {
        return setErrors({ email: i18n.t('signupPage.provideEmail', 'Please provide an email.') })
      }
      if (user.email && !validate(user.email)) {
        return setErrors({ email: i18n.t('signupPage.provideValidEmail', 'Please provide a valid email.') })
      }

      if (user?.selectedCreatePassword && !user.password) {
        return setErrors({ password: i18n.t('signupPage.confirmPassword', 'Please confirm your password.') })
      }
      const error = user?.selectedCreatePassword ? checkPasswordErrors(user?.password) : undefined // if they chose to create a password, we should verify it
      if (error) {
        return setErrors({ password: error }) // don't submit if there is an error
      } else {
        setErrors({ password: undefined })
      }

      return onSubmit()
    }

    logEvent(AnalyticEventTypeEnums.SIGNUP_CLICKED, {
      clinicId: clinic?.id,
    })

    console.log('hit')
  }

  const onBlurInput = () => {
    if (!isSubmitted) return
    if (step === '1') {
      if (!user.firstName) {
        return setErrors({ firstName: i18n.t('signupPage.provideFirstName', 'Please provide your first name.') })
      } else if (!user.lastName) {
        return setErrors({ lastName: i18n.t('signupPage.provideLastName', 'Please provide your last name.') })
      }

      const phoneNumberError = checkPhoneNumberErrors(user.phoneNumber)
      if (phoneNumberError) return setErrors({ phoneNumber: phoneNumberError })

      const birthdayError = checkBirthdayErrors(user.birthday)
      if (birthdayError) return setErrors({ birthday: birthdayError })

      if (!user.stateId) {
        return setErrors({ stateId: i18n.t('signupPage.selectState', 'Please select a state.') })
      } else if (!user.agreeToTerms) {
        return setErrors({
          agreeToTerms: i18n.t('signupPage.acceptTermsService', 'Please accept the terms of service'),
        })
      }
    }
  }

  const handleUseAsGuest = () => {
    onBeforeChange({
      email: null,
      password: null,
    })
    onChangeForm('guest')
  }

  const formatPhoneNumber = (e) => {
    const phone = e.target.value.replace(/[^\d]/g, '')
    const phoneNumberLength = phone.length
    if (phoneNumberLength < 4) {
      return phone
    }
    if (phoneNumberLength < 7) {
      return `(${phone.slice(0, 3)}) ${phone.slice(3)}`
    } else {
      return `(${phone.slice(0, 3)}) ${phone.slice(3, 6)}-${phone.slice(6, 10)}`
    }
  }

  return (
    <Container>
      {step === '1' && (
        <>
          <HeaderContainer>
            <Logo src={clinic?.logo || logo} alt="Koda Health" width="320" />
          </HeaderContainer>{' '}
          <Title>{i18n.t('signupPageV2.title', 'Your Information')}</Title>
          <Caption>
            {i18n.t('signupPage.alreadyHaveAccount')}{' '}
            <CaptionLink to="/login">{i18n.t('signupPage.signinHere', 'Sign in here')}</CaptionLink>
          </Caption>
          <RequiredFieldsText>
            <RedTextWrapper>*</RedTextWrapper> {i18n.t('signUpPage.requiredFields', 'These are required fields.')}{' '}
          </RequiredFieldsText>
          <form onSubmit={onBeforeSubmit}>
            <Row gutter={16}>
              <Col xs={24} sm={12}>
                <FormItem
                  label={i18n.t('signupPage.legalFirstName', 'Legal First Name')}
                  required
                  error={errors.firstName}
                  htmlFor="firstName"
                >
                  <TextInput
                    id="firstName"
                    onChange={(e) => onBeforeChange({ firstName: e.target.value })}
                    data-testid="signup-firstname"
                    value={user.firstName}
                    ref={firstNameInput}
                  />
                </FormItem>
              </Col>
              <Col xs={24} sm={12}>
                <FormItem
                  label={i18n.t('signupPage.legalLastName', 'Legal Last Name')}
                  required
                  error={errors.lastName}
                  htmlFor="lastName"
                >
                  <TextInput
                    onChange={(e) => onBeforeChange({ lastName: e.target.value })}
                    data-testid="signup-lastname"
                    id="lastName"
                    value={user.lastName}
                    className="fs-exclude"
                  />
                </FormItem>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={12}>
                <FormItem
                  label={i18n.t('signupPageV2.phoneNumber', 'Phone number')}
                  required
                  htmlFor="phone-number"
                  error={errors.phoneNumber}
                >
                  <TextInput
                    id="phone-number"
                    className="fs-exclude"
                    data-testid="signup-phone-number"
                    onChange={(e) => {
                      const formattedPhoneNumber = formatPhoneNumber(e)
                      onBeforeChange({ phoneNumber: formattedPhoneNumber })
                    }}
                    onBlur={() => onBlurInput()}
                    value={user.phoneNumber}
                  />{' '}
                </FormItem>{' '}
              </Col>
              <Col xs={24} sm={12} className="fs-exclude">
                <FormItem
                  label={i18n.t('signupPage.birthday', 'Birthday')}
                  htmlFor="birthday"
                  required
                  error={errors.birthday}
                  tooltip={i18n.t('signupPage.birthdayTooltip', 'We need to make sure you are 18 years or older')}
                >
                  <BirthdayInput
                    onChange={(birthday) => onBeforeChange({ birthday })}
                    value={user?.birthday}
                    id="birthday"
                  />
                </FormItem>
              </Col>
            </Row>
            <Row style={{ marginTop: '-16px' }} gutter={16}>
              <Col xs={24} sm={12}>
                <FormItem
                  label={i18n.t('signupPage.stateYouCurrentlyLiveIn', 'State you currently live in')}
                  htmlFor="state"
                  required
                  error={errors.stateId}
                  tooltip={i18n.t(
                    'signupPage.stateTooltip',
                    'Every state has different requirements for medical care plans. Please select the state you currently live in to ensure we provide you with the right information.'
                  )}
                >
                  <StatesInput
                    onChange={(stateId) => onBeforeChange({ stateId })}
                    onBlur={() => onBlurInput()}
                    value={user.stateId}
                    id="state"
                    className="fs-exclude"
                  />
                </FormItem>
              </Col>
            </Row>

            <FormItem error={errors.agreeToTerms}>
              <div style={{ display: 'flex' }}>
                <div style={{ marginRight: 8, paddingTop: 8 }}>
                  <Checkbox
                    type="checkbox"
                    data-testid="signup-checkbox"
                    onChange={(e) => onBeforeChange({ agreeToTerms: e.target.checked })}
                    onBlur={() => onBlurInput()}
                    checked={user.agreeToTerms ? true : false}
                    id="agreeText"
                  />
                </div>
                <div>
                  <BottomText htmlFor="agreeText">
                    {i18n.t(
                      'signupPage.agreeText',
                      'I agree that all of my responses are honest to the best of my ability. I agree to the'
                    )}{' '}
                    <BottomLink to="/terms?showBackBtn=true">{i18n.t('signupPage.terms', 'Terms')}</BottomLink> and{' '}
                    <BottomLink to="/privacy?showBackBtn=true">
                      {i18n.t('signupPage.privacyPolicy', 'Privacy Policy')}
                    </BottomLink>
                    .
                  </BottomText>
                </div>
              </div>
            </FormItem>
            {signupError && <ErrorBlock error={signupError} />}
          </form>
        </>
      )}
      {step === '2' && (
        <>
          <Step2
            user={user}
            onBeforeChange={onBeforeChange}
            onBlurInput={onBlurInput}
            handleUseAsGuest={handleUseAsGuest}
            onChange={onChange}
            onBeforeSubmit={onBeforeSubmit}
            signupError={signupError}
            passwordError={errors?.password}
            errors={errors}
            onErrorChange={(newErrors) => setErrors(newErrors)}
            emailInput={emailInput}
            clinic={clinic}
          />
        </>
      )}
      <ButtonContainer>
        <Button
          disabled={loading}
          grey
          onClick={step === '1' ? onBack : onBackHandler}
          style={{ width: 165, marginRight: 16 }}
        >
          {i18n.t('button.back', 'Back')}
        </Button>

        <Button
          disabled={loading}
          loading={loading}
          data-testid="signup-continue"
          onClick={onBeforeSubmit}
          style={{ width: 165 }}
        >
          {step === '1' ? i18n.t('signupPageV2.continue', 'Continue') : i18n.t('signupPage.signUp', 'Sign up')}
        </Button>
      </ButtonContainer>
      <Row>
        <Col xs={24}>
          {step === '2' && (
            <>
              <UseAsGuestText>
                {i18n.t('signUpPageV2.dontHaveEmail', 'Don’t have an email address?')}{' '}
                <LinkButton onClick={handleUseAsGuest}>{i18n.t('signUpPage.clickHere', 'Click here')}</LinkButton>
              </UseAsGuestText>
            </>
          )}
          <BottomText>
            {i18n.t('signupPage.needHelp', 'Need help? Call us at')}{' '}
            <a
              style={{ textDecoration: 'underline', color: '#01337D' }}
              href={`tel:+${constants.supportPhone.replace(/-/g, '')}`}
            >
              {constants.supportPhone}
            </a>
          </BottomText>
        </Col>
      </Row>
    </Container>
  )
}

export default SignupFormV2
