


interface getMdMRelationshipLabelArgs {
    questions: any[];
    fieldName: string;
    value: string;
}


export default function getMdMRelationshipLabel({
    questions = [],
    fieldName,
    value,
}: getMdMRelationshipLabelArgs): string {
    const question = questions?.find(q => q.fieldName === fieldName);
    const answer = question?.options?.find(q => q.id === value);
    return answer?.label
}