import { AdPartEnum, PlanSetting } from '../types'
import en from 'lib/helpers/locales/en/translation.json'
import { Text, View } from '@react-pdf/renderer'
import s from '../../../KodaContent/styles'

export default function getTX(t, defaultPartSettings, lang: string): PlanSetting {
  const tx: PlanSetting = {
    state: 'Texas',
    disclaimer: t('TX.disclaimer'),
    parts: [
      {
        ...defaultPartSettings?.[AdPartEnum.health_care_choices],
      },
      {
        ...defaultPartSettings?.[AdPartEnum.medical_decision_maker],
        additionalContent: (
          <>
            {lang === 'es' ? (
              <View>
                <Text style={{ ...s.textBold, marginBottom: 0 }}>
                  Designación del responsable alternativo para la toma de decisiones (agente)
                </Text>
                <Text style={{ ...s.text, marginBottom: 0 }}>
                  Aunque no está obligado a designar a un apoderado suplente, puede designar uno si lo desea. Si el
                  apoderado designado por usted no puede o no quiere actuar como tal, un apoderado suplente puede tomar
                  las mismas decisiones sobre la atención médica que el apoderado designado. Si el apoderado designado
                  es su cónyuge, la designación queda automáticamente revocada por ley en caso de que su matrimonio se
                  disuelva, se anule o se declare nulo, a menos que en este documento se estipule otra cosa.
                </Text>
                <Text style={{ ...s.textBold, marginBottom: 0 }}>Vigencia</Text>
                <Text style={{ ...s.text, marginBottom: 0 }}>
                  Estoy enterado de que este poder notarial tiene una vigencia indefinida a partir de la fecha en que
                  formalizo este documento, a menos que yo establezca un tiempo más corto o que revoque el poder
                  notarial. Si carezco de la capacidad necesaria para manifestar mi voluntad sobre asuntos médicos
                  cuando este poder notarial venza, la facultad que otorgo a mi apoderado continuará vigente hasta el
                  momento en que yo pueda por mí mismo tomar decisiones sobre mi salud.
                </Text>
                <Text style={{ ...s.text, marginBottom: 0 }}>
                  (SI ES APLICABLE) Este poder notarial vence el:______________________________________
                </Text>
                <Text style={{ ...s.textBold, marginBottom: 0 }}>Revocación de las designaciones anteriores</Text>
                <Text style={{ ...s.text, marginBottom: 0 }}>Revoco cualquier poder notarial médico anterior.</Text>
              </View>
            ) : (
              <View>
                <Text style={{ ...s.textBold, marginBottom: 0 }}>Designation of Alternate Decision Maker (Agent)</Text>
                <Text style={{ ...s.text, marginBottom: 0 }}>
                  You are not required to designate an alternate agent but you may do so. An alternate agent may make
                  the same health care decisions as the designated agent if the designated agent is unable or unwilling
                  to act as your agent. If the agent designated is your spouse, the designation is automatically revoked
                  by law if your marriage is dissolved, annulled, or declared void unless this document provides
                  otherwise.
                </Text>
                <Text style={{ ...s.textBold, marginBottom: 0 }}>Duration</Text>
                <Text style={{ ...s.text, marginBottom: 0 }}>
                  I understand that this power of attorney exists indefinitely from the date I execute this document
                  unless I establish a shorter time or revoke the power of attorney. If I am unable to make health care
                  decisions for myself when this power of attorney expires, the authority I have granted my agent
                  continues to exist until the time I become able to make health care decisions for myself. (IF
                  APPLICABLE) This power of attorney ends on the following date: ___________
                </Text>
                <Text style={{ ...s.textBold, marginBottom: 0 }}>Prior Designations Revoked</Text>
                <Text style={{ ...s.text, marginBottom: 0 }}>I revoke any prior medical power of attorney.</Text>
              </View>
            )}
          </>
        ),
      },
      {
        ...defaultPartSettings?.[AdPartEnum.sign_the_form],
        description: t('TX.sign_the_form.disclaimer'),
        notary: {
          disclaimer: t('TX.notary.disclaimer', en.TX.notary.disclaimer),
        },
        beforeYouSign: {
          requirements: [
            t(
              'planDoc.sign_the_form.beforeYouSignRequirements_age',
              en.planDoc.sign_the_form.beforeYouSignRequirements_age
            ),
            t(
              'planDoc.sign_the_form.beforeYouSignRequirements_notary',
              'have your signature acknowledged before a notary public; or'
            ),
            t(
              'planDoc.sign_the_form.beforeYouSignRequirements_witnesses',
              'sign in the presence of two competent adult witnesses.'
            ),
          ],
          //
          finalClause: t('TX.sign_the_form.beforeYouSignFinalClause'),
        },
        witnessRequirements: {
          title: t('planDoc.sign_the_form.witnessRequirements_title', 'Witnesses or Notary Requirements'),
          requirements: [
            {
              title: t('planDoc.sign_the_form.witnessRequirements_bothWitnesses'),
              phrases: [
                t('planDoc.sign_the_form.witnessRequirements_be18'),
                t('planDoc.sign_the_form.witnessRequirements_seeYouSign'),
              ],
            },
            {
              title: t('planDoc.sign_the_form.witnessRequirements_atLeastOneWitnessCannot'), //
              phrases: [
                t('planDoc.sign_the_form.witnessRequirements_beYourMdm'),
                t('TX.sign_the_form.witnessRequirements_beYourProvider'),
                t('TX.sign_the_form.witnessRequirements_workForProvider'),
                t('TX.sign_the_form.witnessRequirements_beYourCreditor'),
                t('TX.sign_the_form.witnessRequirements_beRelated'),
                t('TX.sign_the_form.witnessRequirements_benefitFinancially'),
              ],
            },
          ],
          numberOfWitnessesRequired: 2,
          numberOfWitnessesThatMustMeetRequirements: 1,
          validityRequirements: [
            t('planDoc.sign_the_form.validityRequirements_esignInThePresence'),
            t('planDoc.sign_the_form.validityRequirements_acknowledgeNotary'),
            t('planDoc.sign_the_form.validityRequirements_prinatAndSignBeforeTwoWitnessesOr'),
            t('planDoc.sign_the_form.validityRequirements_prinatAndSignBeforeNotary'),
          ],
        },
      },
      {
        ...defaultPartSettings?.[AdPartEnum.faqs_and_legal],
        description: t('TX.faqs_and_legal.description'), //
      },
    ],
  }

  return tx
}
