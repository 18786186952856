import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { useState } from 'react'
import { useWindowWidth } from '@react-hook/window-size'
// COMPONENTS
import ElephantChat from 'components/common/ElephantChat'
import Button from 'components/common/Button'
import BottomBar from 'components/common/BottomBar'
import ReadyToSignModal from 'components/common/ReadyToSignModal'
// LIB
import theme from 'lib/theme'
import { AnalyticEventTypeEnums } from 'lib/types/AnalyticEventTypeEnums'
import ArrowRightOutlined from 'components/icons/ArrowRightOutlined'
import { Patient, UserProfile } from 'generated/graphql'
import Collapse from 'components/common/Collapse'

import Header from 'components/text/Header'
import personIcon from './images/person_icon.svg'
import plusIcon from './images/plus_icon.svg'
import i18n from 'lib/helpers/i18n'
import { Trans } from 'react-i18next'
import logEvent from 'lib/helpers/logEvent'

const { Panel } = Collapse

const ContentContainer = styled.div`
  padding: 30px;
  background-color: white;
  margin-top: 20px;
`

const TextContainer = styled.div`
  padding: 10px 0 20px 0;
`
const TextContent = styled.p`
  font-size: 16px;
  font-family: ${(p) => p.theme.fontFamily};
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.56;
  letter-spacing: normal;
  color: ${(p) => p.theme.colors.neutral4};
`
const PersonContainer = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
`
const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
`
const UserIcon = styled.img`
  width: 40px;
  height: 48px;
  margin-bottom: 5px;
`
const PlusIcon = styled.img`
  width: 24px;
  height: 24px;
  margin: 13px 0;
`
const CollapseContainer = styled.div`
  margin: 10px 0 100px 0;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-bottom: 16px;
  padding-right: 16px;
`

interface PrimaryButtonProps {
  patient: Patient
  setShowReadyToSign: React.Dispatch<React.SetStateAction<boolean>>
}

const PrimaryButton = ({ patient, setShowReadyToSign }: PrimaryButtonProps) => {
  const history = useHistory()
  return patient?.stateVersion?.hasESign ? (
    <>
      {!patient.hasOfficialPlan && (
        <Button
          pulse={true}
          onClick={() => setShowReadyToSign(true)}
          data-testid="start-esigning-btn"
          style={{ minWidth: 160 }}
        >
          {i18n.t('ESignAdditionalInformation.startESigning', 'Start eSigning')}
        </Button>
      )}
      {patient.hasOfficialPlan && (
        <Button pulse={true} onClick={() => history.push('/app?mode=emailDirective')} style={{ minWidth: 160 }}>
          {i18n.t('ESignAdditionalInformation.sharePlan', ' Share Plan ')} <ArrowRightOutlined />
        </Button>
      )}
    </>
  ) : (
    <Button pulse={true} onClick={() => history.push('/app?mode=emailDirective')} style={{ minWidth: 160 }}>
      {i18n.t('ESignAdditionalInformation.sharePlan', ' Share Plan ')}
      <ArrowRightOutlined />
    </Button>
  )
}

const SecondaryButton = () => {
  const history = useHistory()
  return (
    <Button
      style={{ minWidth: 100, marginRight: 8 }}
      grey
      onClick={() => {
        logEvent(AnalyticEventTypeEnums.EDIT_PLAN_CLICKED)
        history.push(`/app?mode=emailDirective`)
      }}
    >
      {i18n.t('ESignAdditionalInformation.signLater', ' Sign Later')}
    </Button>
  )
}

interface ESignAdditionalInformationProps {
  patient: Patient
  currentUser: UserProfile
}

export const getEsignFAQs = () => {
  return [
    {
      header: i18n.t('ESignAdditionalInformation.faq1.header', `Why should I have this document signed?`),
      content: i18n.t(
        'ESignAdditionalInformation.faq1.content',
        `Signing your completed Koda Care Plan is required to make your medical care choices legally valid in your state. By signing this document with witnesses, you are protecting your wishes and ensuring that your loved ones and providers know exactly what medical care you want.`
      ),
    },
    {
      header: i18n.t('ESignAdditionalInformation.faq2.header', `Who can sign this document?`),
      content: i18n.t(
        'ESignAdditionalInformation.faq2.content',
        `Typically, you and two additional witnesses over the age of 18 must sign your document in order to make it legally valid. Some states have additional requirements for who is eligible to sign - if these apply to your state, you will see the requirements listed on your documents before signing.`
      ),
    },
    {
      header: i18n.t('ESignAdditionalInformation.faq3.header', `Where can the witnesses be located?`),
      content: i18n.t(
        'ESignAdditionalInformation.faq3.content',
        `You and your additional witness signers must all be physically present in the same location. Each witness will be required to confirm they are located in the same place before they sign your document. If your witnesses are not present in the same location as you, they may not sign the document and it will not be legally valid.`
      ),
    },
    {
      header: i18n.t('ESignAdditionalInformation.faq4.header', `How do we electronically sign this document?`),
      content: i18n.t(
        'ESignAdditionalInformation.faq4.content',
        `The Koda app will guide you through the entire process on the next page - you will complete all your signatures on this device. Signing this document within the Koda app only requires you to type your name and confirm you are willing to sign - no hand-written signature is needed.`
      ),
    },
    {
      header: i18n.t('ESignAdditionalInformation.faq5.header', `How do I get a copy of the signed document?`),
      content: i18n.t(
        'ESignAdditionalInformation.faq5.content',
        `You can log back into your Koda Health account at any time to see the signed version of your care plan. Additionally, you will receive an email with a PDF copy after you and your witnesses sign the document.`
      ),
    },
    {
      header: i18n.t('ESignAdditionalInformation.faq6.header', `Is this really legal?`),
      content: i18n.t(
        'ESignAdditionalInformation.faq6.content',
        `Yes, as long as your witnesses meet the requirements listed on the document and are all physically present to sign their own name.`
      ),
    },
  ]
}

export default function ESignAdditionalInformation({ patient, currentUser }: ESignAdditionalInformationProps) {
  const history = useHistory()
  const windowWidth = useWindowWidth()
  const [showReadyToSign, setShowReadyToSign] = useState<boolean>(false)
  const ESIGN_FAQS = getEsignFAQs()

  return (
    <>
      <ReadyToSignModal
        visible={showReadyToSign}
        currentUser={currentUser}
        patient={patient}
        onCancel={() => {
          setShowReadyToSign(false)
          history.push(`/app?mode=emailDirective`)
        }}
        onReady={() => history.push(`/app/esign`)}
      />
      <div
        style={{
          minHeight: '85vh',
          height: '100%',
          flex: 1,
          width: 900,
          maxWidth: '100%',
          margin: 'auto',
        }}
      >
        <div style={{ display: 'flex', marginBottom: 48 }}>
          <ElephantChat
            text={i18n.t('ESignAdditionalInformation.elephantText', 'Let’s learn more about witness eSigning.')}
          />
        </div>{' '}
        {windowWidth > parseInt(theme.breakpoints.medium) && (
          <ButtonContainer>
            <SecondaryButton />
            <PrimaryButton setShowReadyToSign={setShowReadyToSign} patient={patient} />
          </ButtonContainer>
        )}
        {/* */}
        <ContentContainer>
          <Header>{i18n.t('ESignAdditionalInformation.whatDoINeed', 'What do I need?')}</Header>
          <TextContainer>
            <TextContent>
              {i18n.t('ESignAdditionalInformation.needTwoWitnesses', 'You need two witnesses.')}
            </TextContent>
          </TextContainer>

          <PersonContainer>
            <IconContainer>
              <UserIcon src={personIcon} alt="koda-user-icon" />
              <TextContent> {i18n.t('ESignAdditionalInformation.you', 'You')}</TextContent>
            </IconContainer>
            <IconContainer>
              <PlusIcon src={plusIcon} alt="plus-icon" />
            </IconContainer>
            <IconContainer>
              <UserIcon src={personIcon} alt="koda-user-icon" />
              <TextContent> {i18n.t('ESignAdditionalInformation.witness1', 'Witness #1')}</TextContent>
            </IconContainer>
            <IconContainer>
              <UserIcon src={personIcon} alt="koda-user-icon" />
              <TextContent>{i18n.t('ESignAdditionalInformation.witness2', 'Witness #2')}</TextContent>
            </IconContainer>
          </PersonContainer>

          <Header>{i18n.t('ESignAdditionalInformation.howDoesThisWork', 'How does this work?')}</Header>
          <TextContainer>
            <TextContent>
              <Trans i18nKey={'ESignAdditionalInformation.howDoesThisWork1'}>
                <b>Step 1</b>: You sign your document using the Koda Health app.
              </Trans>
            </TextContent>
            <TextContent>
              <Trans i18nKey={'ESignAdditionalInformation.howDoesThisWork2'}>
                <b>Step 2</b>: On the same device, witness #1 signs the document.
              </Trans>
            </TextContent>
            <TextContent>
              <Trans i18nKey={'ESignAdditionalInformation.howDoesThisWork3'}>
                <b>Step 3</b>: On the same device, witness #2 signs the document.
              </Trans>
            </TextContent>
            <TextContent>
              <Trans i18nKey={'ESignAdditionalInformation.howDoesThisWork4'}>
                <b>Step 4</b>: Share your official plan with yourself, decision maker, and loved ones.
              </Trans>
            </TextContent>
          </TextContainer>

          <CollapseContainer>
            <Collapse expandIconPosition="right">
              <Panel
                header={<b>{i18n.t('ESignAdditionalInformation.faq', 'Frequently Asked Questions')}</b>}
                key={'Frequently-Asked-Questions-ESign-Additional-Information'}
              >
                <Collapse defaultActiveKey={[ESIGN_FAQS?.[0]?.header]} expandIconPosition="right">
                  {ESIGN_FAQS?.map((item, index) => {
                    return (
                      <Panel header={item.header} key={item.header + index}>
                        <TextContent>{item.content}</TextContent>
                      </Panel>
                    )
                  })}
                </Collapse>
              </Panel>
            </Collapse>
          </CollapseContainer>
        </ContentContainer>
        {windowWidth <= parseInt(theme.breakpoints.medium) && (
          <BottomBar style={{ justifyContent: 'space-between' }}>
            <div style={{ marginRight: 4, width: 300, maxWidth: '100%' }}>
              <SecondaryButton />
            </div>
            <div>
              <PrimaryButton setShowReadyToSign={setShowReadyToSign} patient={patient} />
            </div>
          </BottomBar>
        )}
      </div>
    </>
  )
}
