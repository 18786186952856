import { useState, useEffect } from 'react'
import styled from 'styled-components'
import Vimeo, { EndEvent } from '@u-wave/react-vimeo'
import getTimeDuration from 'lib/helpers/getTimeDuration'
import logEvent from 'lib/helpers/logEvent'
import useGetVideoDimensions from 'lib/hooks/useGetVideoDimensions'
import PlayCircleFilled from 'components/icons/PlayCircleFilled'
import ArrowRightOutlined from 'components/icons/ArrowRightOutlined'
import theme from 'lib/theme'
import Button from 'components/common/Button'
import moment from 'moment'
import { AnalyticEventTypeEnums } from 'lib/types/AnalyticEventTypeEnums'
import LoadingOutlined from 'components/icons/LoadingOutlined'
import { Patient } from 'generated/graphql'
import { useTranslation } from 'react-i18next'
import iOS from 'lib/helpers/iOS'

const VideoPlaceholder = styled.div`
  height: 315px;
  width: 600px;
  position: relative;
  border-radius: 5px;
  max-width: 100%;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    width: 400px;
    height: 250px;
    margin-bottom: 8px;
    position: relative;
    left: -13px;
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.xSmall}) {
    width: 340px;
    height: 220px;
    justify-content: flex-start;
    margin-bottom: 0px;
    position: relative;
    left: -13px;
  }
  #widget2 {
    max-width: 90%;
  }
`

const SkipVideo = styled.div`
  margin-bottom: 16px;
`

interface VideoPlayerProps {
  videoId: string
  stepName?: string
  onEnd?: (e: EndEvent) => void
  onPlay?: () => void
  onNext: (plan?: Patient) => void
  fieldName?: string
  showButtons?: boolean
  required?: boolean
  /** you can optinoally tell the component not to log the video skip to our backend */
  logVideoSkip?: boolean
  hideSkipButton?: boolean
  skipOnEnd?: boolean
}

export default function VideoPlayer({
  videoId,
  stepName,
  onEnd,
  onPlay = () => {},
  onNext,
  fieldName,
  showButtons,
  required = false,
  logVideoSkip = true, // you can optinoally tell the component not to log the video skip to our backend
  hideSkipButton = false,
  skipOnEnd = false,
}: VideoPlayerProps) {
  const { t } = useTranslation()
  const [ready, setReady] = useState(false)
  const [paused, setPaused] = useState(iOS() ? true : false)
  const { width: videoWidth, height: videoHeight } = useGetVideoDimensions()
  const [playPressed, setPlayPressed] = useState(false) // will track if the user has clicked the manual play button or not. Button should only pulse if were on a platform, like iOS, which will not autoplay
  const [timeVideoStarted, setTimeVideoStarted] = useState<number | null>(null) // will track the timestamp of when a user starts watching a video
  const videoName = stepName || fieldName || videoId

  // Add event listeners to capture usage of keys
  useEffect(() => {
    const downHandler = ({ keyCode }) => {
      // when pressing right
      if (keyCode === 39 && onNext && !required) {
        onNext()
      }
      // when pressing enter
      if (keyCode === 13 && onNext && !required) {
        onNext()
      }
    }
    window.addEventListener('keydown', downHandler)
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('keydown', downHandler)
    }
  }, [onNext, required]) // Empty array ensures that effect is only run on mount and unmount

  const handleLogSkip = () => {
    if (logVideoSkip) {
      // helpers.logEvent
      let endTime = moment().valueOf()
      logEvent(AnalyticEventTypeEnums.VIDEO_SKIPPED, {
        videoId,
        durationWatched: timeVideoStarted
          ? getTimeDuration({
              startTime: timeVideoStarted,
              endTime,
            })
          : 'unknown',
        timeVideoStarted,
        endTime,
        videoName,
        msWatched: timeVideoStarted ? endTime - (timeVideoStarted || 0) : 'unknown',
      })
    }
  }

  return (
    <>
      <VideoPlaceholder>
        <Vimeo
          video={videoId}
          autoplay
          height={videoHeight}
          width={videoWidth}
          onReady={() => setReady(true)}
          onEnd={(e: EndEvent) => {
            if (onEnd) {
              onEnd(e)
            }
            if (skipOnEnd) {
              onNext()
            }
          }}
          paused={paused}
          onPause={() => {
            setPaused(true)
            logEvent(AnalyticEventTypeEnums.VIDEO_PAUSED, {
              videoId,
              videoName,
            })
          }}
          onPlay={() => {
            setPaused(false)
            setPlayPressed(true)
            setTimeVideoStarted(moment().valueOf())
            onPlay()
            // only log VIDEO_START_AUTOPLAY if onPlay runs and the user hasn't clicked onPlay
            if (!playPressed) {
              logEvent(AnalyticEventTypeEnums.VIDEO_START_AUTOPLAY, {
                videoId,
                videoName,
              })
            }
          }}
        />
        {/* Show a loader to make the loading slightly less clunky... it'll be based on the ready variable using a hook from react-youtube */}
        {!ready && (
          <LoadingOutlined
            style={{
              position: 'absolute',
              right: 300,
              fontSize: 32,
              color: theme.colors.neutral8,
            }}
          />
        )}
      </VideoPlaceholder>
      {(!required || showButtons) && (
        <SkipVideo>
          <Button
            style={{ marginTop: 8 }}
            secondary
            pulse={!playPressed}
            onClick={() => {
              setPaused(false)
              setPlayPressed(true)
              logEvent(AnalyticEventTypeEnums.VIDEO_START_CLICKED, {
                videoId,
                videoName,
              })
            }}
          >
            {t('video.play', 'Play this video')}
            <PlayCircleFilled
              style={{
                fontSize: 18,
                marginLeft: 8,
                position: 'relative',
                bottom: -1,
              }}
            />
          </Button>
          <div style={{ width: 8, display: 'inline-block' }} />
          {!hideSkipButton && (
            <Button
              data-testid="video-skip"
              style={{ marginTop: 8 }}
              grey
              onClick={() => {
                handleLogSkip()
                onNext()
              }}
            >
              {t('video.skip', 'Skip this video')}{' '}
              <ArrowRightOutlined
                style={{
                  fontSize: 14,
                  marginLeft: 4,
                  position: 'relative',
                  bottom: 1,
                }}
              />
            </Button>
          )}
        </SkipVideo>
      )}
    </>
  )
}
