// TOP LEVEL IMPORTS
import type { ReactNode } from 'react'
import styled, { CSSProperties, keyframes } from 'styled-components'
import { LoadingOutlined } from '@ant-design/icons'
import { StyledTheme } from 'lib/theme/theme'

interface GetBackgroundColorProps {
  grey?: boolean
  disabled?: boolean
  secondary?: boolean
  theme?: StyledTheme
  danger?: boolean
  outline?: boolean
}

const getBackgroundColor = ({ grey, disabled, secondary, theme, danger, outline }: GetBackgroundColorProps) => {
  if (!theme) return
  if (disabled) {
    return theme.colors.neutral8
  }
  if (outline) {
    return '#fff'
  }
  if (danger) {
    return theme.colors.red9
  }
  if (grey) {
    return theme.colors.neutral8
  }
  if (secondary) {
    return theme.colors.primary9
  }

  return theme.colors.primary2
}

const getTextColor = ({ danger, grey, outline, disabled, secondary, theme }: GetBackgroundColorProps) => {
  if (!theme) return
  if (disabled) {
    return theme.colors.neutral5
  }
  if (outline) {
    return theme.colors.primary2
  }
  if (danger) {
    return theme.colors.red3
  }
  if (grey) {
    return theme.colors.neutral1
  }
  if (secondary) {
    return theme.colors.primary1
  }
  return '#fff'
}

const getHoverBackgroundColor = ({ grey, disabled, secondary, theme, danger, outline }: GetBackgroundColorProps) => {
  if (!theme) return
  if (disabled) {
    return theme.colors.neutral8
  }
  if (outline) {
    return theme.colors.primary10
  }
  if (danger) {
    return theme.colors.red7
  }
  if (grey) {
    return theme.colors.neutral7
  }
  if (secondary) {
    return theme.colors.primary8
  }
  return theme.colors.primary1
}

const BUTTON_HEIGHT = 56

const PrimaryButtonContainer = styled.button<{
  fullWidth?: boolean
  style?: CSSProperties
  secondary?: boolean
  danger?: boolean
  outline?: boolean
  disabled?: boolean
  grey?: boolean
  type?: string
}>`
  width: ${(p) => {
    if (p.fullWidth) return '100%'
    if (p.style?.width) return p.style.width
  }};
  padding-right: 16px;
  padding-left: 16px;
  border: ${({ outline, theme }) => {
    if (outline) return `2px solid ${theme.colors.primary3}`
    return `0px` //'transparent';
  }};
  height: ${BUTTON_HEIGHT}px;
  /* box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 12px 0px; */
  z-index: 2;
  border-radius: ${(p) => p.theme.borderRadius}px;
  background-color: ${(props) => getBackgroundColor(props)};
  position: relative;
  line-height: 40px;
  letter-spacing: 0.025em;
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease, width 0.3s ease, opacity 0.3s ease;
  &:hover {
    cursor: pointer;
    background-color: ${(props) => getHoverBackgroundColor(props)};
  }
  min-width: 100px;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    min-width: 60px;
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.xxSmall}) {
    padding: 0 5px;
  }
`

const ButtonText = styled.div<{
  disabled?: boolean
  secondary?: boolean
  danger?: boolean
  grey?: boolean
  outline?: boolean
}>`
  height: 20px;
  font-family: ${(p) => p.theme.fontFamily};
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 1px;
  font-stretch: normal;
  line-height: normal;
  text-align: center;
  color: ${(props) => getTextColor(props)};
  max-width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`

const pulse = keyframes`
 0% {
     transform: scale(0.9);
     box-shadow: 0 0 0 0 rgba(124, 196, 250, .7);
    }
 70% {
     transform: scale(1);
     box-shadow: 0 0 0 20px rgba(124, 196, 250, 0);
    }
 100% {
     transform:  scale(0.9);
    }
`

const Pulser = styled.div<{ fullWidth?: boolean; style?: CSSProperties }>`
  animation: ${pulse} 2s infinite;
  border-radius: 2px;
  position: absolute;
  /* display: inline-block; */
  /* top: -${BUTTON_HEIGHT}px; */
  height: ${BUTTON_HEIGHT}px;
  width: ${(p) => {
    if (p.fullWidth) return '100%'
    if (p.style && p.style.width) return `${p.style.width}px`
    return '100%'
  }};
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
`

// Wrapper in in place purely so we can position the pulse behind the button
const Wrapper = styled.div<{ fullWidth?: boolean; style?: CSSProperties }>`
  position: relative;
  display: inline-block;
  height: ${BUTTON_HEIGHT}px;
  width: ${({ fullWidth, style }) => {
    if (fullWidth) return '100%'
    if (style?.width) return `${style.width}px`
  }};
`

interface ButtonProps {
  style?: CSSProperties
  wrapperStyle?: CSSProperties
  buttonStyle?: CSSProperties
  secondary?: boolean
  danger?: boolean
  outline?: boolean
  disabled?: boolean
  grey?: boolean
  [' data-testid']?: string
  fullWidth?: boolean
  onClick?: (e: any) => void
  loading?: boolean
  children: ReactNode
  pulse?: boolean
  type?: 'button' | 'submit' | 'reset' | undefined
  id?: string
  target?: string
}

const PrimaryButton = (props: ButtonProps) => {
  return (
    <Wrapper style={{ ...props.style, ...props.wrapperStyle }} fullWidth={props?.fullWidth}>
      <PrimaryButtonContainer
        style={{ ...props.style, ...props.buttonStyle }}
        onClick={props.onClick}
        disabled={props.disabled}
        secondary={props.secondary}
        outline={props.outline}
        danger={props.danger}
        grey={props.grey}
        data-testid={props['data-testid']}
        type={props.type || 'button'}
        id={props?.id}
        fullWidth={props?.fullWidth}
      >
        <ButtonText
          disabled={props.disabled}
          secondary={props.secondary}
          danger={props.danger}
          grey={props.grey}
          outline={props.outline}
        >
          {/* {props.icon && <Icon style={{marginRight: 6}} type={props.icon} />} */}

          {!props.loading ? props.children : <LoadingOutlined />}
        </ButtonText>
      </PrimaryButtonContainer>
      {props.pulse && <Pulser style={props.style} />}
    </Wrapper>
  )
}

PrimaryButton.defaultProps = {
  disabled: false,
  type: 'button',
  style: {},
  wrapperStyle: {},
  buttonStyle: {},
}

export default PrimaryButton
