import { View, Text, Image } from '@react-pdf/renderer'
import s from 'components/common/PlanDoc/KodaContent/styles'
import CheckRow from 'components/common/PlanDoc/KodaContent/CheckRow'
import { GuideQuestionIdEnum, Patient } from 'generated/graphql'
import supportiveCarePNG from 'components/common/PlanDoc/imgs/supportive_care.png'
import { QuestionItem } from 'lib/types/QuestionItem'
import i18n from 'lib/helpers/i18n'
import { Trans } from 'react-i18next'

interface SupportiveCareProps {
  data?: Patient
  questions?: QuestionItem[]
}

export default function SupportiveCare({ data, questions }: SupportiveCareProps) {
  const interestedInPalliativeCare = questions?.find(
    (item) => item.fieldName === GuideQuestionIdEnum.InterestedInPalliativeCare
  )
  const interestedInHospiceCare = questions?.find(
    (item) => item.fieldName === GuideQuestionIdEnum.InterestedInHospiceCare
  )

  return (
    <View style={s.sectionContainer}>
      <View style={{ marginTop: 16 }}>
        <Text style={s.sectionTitle}>{i18n.t('SupportiveCarePage.title', 'Supportive Care')}</Text>
      </View>
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginTop: 16,
        }}
      >
        <View
          style={{
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            alignSelf: 'center',
            marginRight: 8,
          }}
        >
          <Image
            style={{
              height: 24,
              width: 24,
              borderRadius: 24 / 2,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              alignSelf: 'flex-end',
            }}
            src={supportiveCarePNG}
          />
        </View>
        <View style={{ flex: 18 }}>
          <Text style={{ ...s.textBold, marginTop: 8, fontFamily: 'Roboto Bold' }}>
            {i18n.t('SupportiveCarePage.whatIs', 'What is supportive care?')}
          </Text>
        </View>
      </View>
      <View>
        <Text style={s.text}>
          <Trans i18nKey="SupportiveCarePage.palliativeCare">
            <Text style={{ fontFamily: 'Roboto Bold' }}>Palliative Care</Text> is a type of supportive care to help
            relieve difficult symptoms of a serious illness. It can be helpful at any time during an illness and used
            alongside the active treatments of other physicians.{' '}
          </Trans>
        </Text>
        <Text style={s.text}>
          <Trans i18nKey="SupportiveCarePage.hospiceCare">
            <Text style={{ fontFamily: 'Roboto Bold' }}>Hospice Care</Text> is a type of supportive care that focuses
            solely on comfort rather than active treatment for people expected to have less than 6 months to live.
            Choosing hospice means that you focus on quality of life and stop other active treatments that don’t help
            with comfort.
          </Trans>
        </Text>
      </View>
      <View style={{ marginTop: 40 }}>
        <Text style={s.sectionTitle}>
          {i18n.t('SupportiveCarePage.supportiveCarePreferences', 'Supportive Care Preferences')}
        </Text>
        <Text style={s.textBold}>{i18n.t('SupportiveCarePage.palliativeCareTitle', 'Palliative Care')}</Text>
        <Text style={s.text}>
          {i18n.t(
            'SupportiveCarePage.ifYouDevelopSeriousConditionPalliative',
            `
              If you develop a serious medical condition with difficult to treat symptoms (pain, shortness of breath,
              nausea, etc), would you be interested in palliative care support to help improve your quality of life?
            `
          )}
        </Text>
        {interestedInPalliativeCare?.options?.map((option) => {
          return (
            <CheckRow
              key={option.id}
              label={option.label}
              checked={option.id === data?.[GuideQuestionIdEnum.InterestedInPalliativeCare]}
              colored={false}
            />
          )
        })}
      </View>
      <View style={{ marginTop: 32 }}>
        <Text style={s.textBold}>{i18n.t('SupportiveCarePage.hospiceCareTitle', ' Hospice Care')}</Text>
        <Text style={s.text}>
          {i18n.t(
            'SupportiveCarePage.ifYouDevelopSeriousConditionHospice',
            `
              If you develop a serious medical condition with difficult to treat symptoms (pain, shortness of breath,
              nausea, etc) and you might have less than 6 months to live, would you be interested in hospice care? This
              means choosing to focus on your comfort and quality of life, rather than continuing active treatment.
            `
          )}
        </Text>
      </View>
      {interestedInHospiceCare?.options?.map((option) => {
        return (
          <CheckRow
            key={option.id}
            label={option.label}
            checked={option.id === data?.[GuideQuestionIdEnum.InterestedInHospiceCare]}
            colored={false}
          />
        )
      })}
    </View>
  )
}
