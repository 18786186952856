import { useRef, useEffect } from 'react';
import styled from 'styled-components';
import { capitalize } from 'lodash';
import i18n from "lib/helpers/i18n";
import SelectLabel from 'components/text/SelectLabel';
import checkSVG from '../check.svg';

const TextOptionContainer = styled.div<{ active: boolean; disabled?: boolean }>`
  height: 100%;
  background: ${(p) => {
    if (p.active) {
      return p.theme.colors.primary9;
    }
    return p.theme.colors.neutral10;
  }};
  border: 1px solid
    ${(p) => {
    if (p.active) {
      return p.theme.colors.primary7;
    }
    return p.theme.colors.neutral9;
  }};
  margin-top: 16px;
  border-radius: 5px;
  padding: 8px;
  cursor: ${(p) => (p.disabled ? 'not-allowed' : 'pointer')};
  transition: color 0.3s ease, background-color 0.3s ease,
    border-color 0.3s ease, width 0.3s ease, opacity 0.3s ease;
  &:hover {
    background: ${(p) => {
    if (p.disabled) {
      return p.theme.colors.neutral10;
    }
    if (p.active) {
      return p.theme.colors.primary9;
    }
    return p.theme.colors.neutral9;
  }};
    border: 1px solid
      ${(p) => {
    if (p.disabled) {
      return p.theme.colors.neutral9;
    }
    if (p.active) {
      return p.theme.colors.primary6;
    }
    return p.theme.colors.neutral7;
  }};
  }
`;

const SelectionContainer = styled.div`
  border-radius: 3px;
  width: 24px;
  height: 24px;
  display: flex;
  margin-right: 8px;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  align-items: center;
  justify-content: center;
  background: #fff;
  border: 1px solid ${(p) => p.theme.colors.neutral8};
`;

const Icon = styled.img<{ rel: string }>`
  margin: auto;
  height: 100px;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    height: 70px;
  }
`;

const IconContainer = styled.div`
  height: 158px;
  display: flex;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    height: 100px;
  }
`;

const Input = styled.textarea<{ ref: any }>`
  background: #fff;
  border: 2px solid ${({ theme }) => theme.colors.neutral9};
`;

interface IconOptionProps {
  item: {
    label: string;
    img: string;
  };
  isOther?: boolean;
  otherFieldName?: string;
  otherFieldValue?: string;
  onChange: Function;
  disabled?: boolean;
  active: boolean;
  onClick: Function;
  letter?: string;
}

export default function IconOption(props: IconOptionProps) {
  const {
    item,
    isOther,
    otherFieldName,
    otherFieldValue,
    onChange,
    disabled,
    active,
    onClick,
  } = props;
  const showTextInput = isOther && otherFieldName && active;
  const textInput = useRef<HTMLInputElement | undefined>();

  useEffect(() => {
    if (showTextInput) {
      textInput?.current?.focus();
    }
  }, [showTextInput]);
  return (
    <TextOptionContainer
      active={active}
      data-testid={props['data-testid']}
      onClick={() => {
        if (showTextInput) return null;
        if (disabled) return null;
        onClick();
      }}
      disabled={disabled}
    >
      <IconContainer>
        {!showTextInput ? (
          <Icon src={item.img} rel="preload" alt={item.label} />
        ) : (
          <Input
            ref={textInput}
            placeholder={`${i18n.t('typeHere')}...`}
            value={otherFieldValue}
            onChange={(e) => onChange({ [otherFieldName as string]: e.target.value })}
          />
        )}
      </IconContainer>
      <div
        style={{
          minHeight: 32,
          display: 'flex',
          alignItems: 'center',
        }}
        onClick={() => {
          if (showTextInput) {
            onClick();
          }
        }}
      >
        <div style={{ width: 24, display: 'inline-block', marginRight: 8 }}>
          <SelectionContainer>
            {active && <img height="12" src={checkSVG} alt="Check" />}
          </SelectionContainer>
        </div>{' '}
        <SelectLabel>{capitalize(item.label)}</SelectLabel>
      </div>
    </TextOptionContainer>
  );
}
