import React from 'react';
// import {useImage} from 'react-image';
// import Loading from 'components/common/Loading';
// import errorSVG from './error.svg';

export default function Image(props) {
  return <img alt="Koda Health" {...props} />;
}

// export default (props) => {
//   const {src, error} = useImage({
//     srcList: [props.src],
//   });

//   if (error) return <img src={errorSVG} alt="Koda Health" />;

//   return (
//     <Suspense fallback={<Loading />}>
//       <img {...props} src={src} alt="Koda Health" />
//     </Suspense>
//   );
// };
